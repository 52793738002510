import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { io, Socket } from "socket.io-client";

import appConfig from "@appConfig";
import LogisticService from "@LogisticService";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const centerDefault = {
  lat: -23.15542449,
  lng: -49.9891445,
};

const GMAPS_API_KEY = appConfig.mapsKey;
const WS_KEY = appConfig.wsKey;

const RealTimeMap = () => {
  const [center, setCenter] = useState(centerDefault);
  const [zoom, setZoom] = useState(5);
  const [positionAngels, setPositionAngels] = useState({});
  const [infoAngels, setInfoAngels] = useState({});
  const [positionsList, setPositionsList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const conectarAoSocket = (channels) => {
    const socket = io(WS_KEY, {
      transports: ["websocket"],
      secure: true,
      withCredentials: true,
    });

    socket.connect();

    socket.on("connect", () => {
      console.log("Socket connected");
      channels.forEach((channel) => {
        socket.emit("join", channel);
      });

      socket.on("angelTracking", (data) => {
        console.log(data);
        const userId = data.userId;
        setPositionAngels((prevPositions) => ({
          ...prevPositions,
          [userId]: { lat: data.latitude, lng: data.longitude },
        }));
      });
    });
  };

  useEffect(() => {
    LogisticService.getTrackingAngels()
      .then((response) => {
        const data = response.data?.data;
        const channels = data.map((item) => item.user_id);
        const angelInfo = {};
        data.forEach((item) => {
          angelInfo[item.user_id] = item.angel_name;
        });
        setInfoAngels(angelInfo);
        conectarAoSocket(channels);
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status == 401) {
          window.location.href = "/login";
        }
      });
  }, []);

  useEffect(() => {
    setPositionsList(Object.keys(positionAngels));
  }, [positionAngels]);

  const onMarkerClick = (position, index) => {
    setZoom(5);
    setCenter(position);
    setZoom(17);
    setActiveIndex(index);
  };

  const onMarkerClose = () => {
    setCenter(centerDefault);
    setZoom(5);
    setActiveIndex(-1);
  };

  const personIconSvg = `
       <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
          <circle cx="24" cy="24" r="19" fill="white" stroke="#8bc34a" stroke-width="3" filter="url(#shadow)" />
          <circle cx="24" cy="24" r="12" fill="#8bc34a" />
          <animateMotion repeatCount="indefinite" dur="4s">
            <mpath href="#path" />
          </animateMotion>
          <defs>
            <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" />
              <feOffset in="blur" dx="2" dy="2" result="offsetBlur" />
              <feMerge>
                <feMergeNode in="offsetBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </svg>
    `;

  return (
    <LoadScript googleMapsApiKey={GMAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        {positionsList &&
          positionsList.map((id, index) => (
            <Marker
              key={index}
              position={positionAngels[id]}
              icon={{
                url: `data:image/svg+xml;base64,${btoa(personIconSvg)}`,
                scaledSize: new window.google.maps.Size(27, 27),
              }}
              onClick={() => onMarkerClick(positionAngels[id], index)}
            >
              {activeIndex == index && (
                <InfoWindow
                  position={positionAngels[id]}
                  onCloseClick={onMarkerClose}
                >
                  <p>{infoAngels[id]}</p>
                </InfoWindow>
              )}
            </Marker>
          ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default RealTimeMap;
