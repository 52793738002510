import React, { useEffect, useState } from "react";
import { ViewerLabel } from "@components/ViewerLabel";
import { UploadContainer } from "@components/UploadContainer";
import { useFormContext } from "react-hook-form";
import * as S from "../styles";

export const EvidenceStep = ({
  handleDeleteUploadContainer,
  setStep,
  serialImage,
  handleSubmit,
  handleBackAndCleanDrawer,
  selectedDefects,
  setSelectedDefects,
  loading,
  props,
}) => {
  const { register, setValue, getValues, watch, resetField } = useFormContext();

  const [stopDoubleRender, setStopDoubleRender] = useState(true);

  return (
    <S.AllDrawerContent>
      <S.ContentDrawer>
        <S.SelectCustomDrawer>
          <ViewerLabel
            title="E-mail corporativo"
            label="email_angel"
            content={getValues("email_angel")}
          />
          <ViewerLabel
            title="Serial"
            label="serial"
            prefix
            src={serialImage}
            content={getValues("serial")}
          />
        </S.SelectCustomDrawer>

        <S.TitleText
          style={{
            borderTop: "1px solid rgba(118, 127, 141, 0.1)",
            marginTop: 0,
            paddingTop: 24,
          }}
        >
          Anexos das evidências
        </S.TitleText>
        <S.SubtitleText first={true}>
          Anexe abaixo as fotos que evidenciem os defeitos identificados na
          maquininha (min de 1 e máx de 2 fotos por defeito).
        </S.SubtitleText>
        {selectedDefects.map((defect, index) => {
          return (
            <UploadContainer
              key={index}
              maxCount={2}
              nameUploadButton={"Anexar foto"}
              setImages={(value) => {
                const images = selectedDefects[index].defectImages;
                //Remove da lista
                if (images.length > value.length) {
                  selectedDefects[index].defectImages = value;
                } else {
                  var idx = images.findIndex((x) => x.uid === value.at(-1).uid);
                  if (idx === -1) {
                    images.push(value.at(-1));
                  }
                }
                setSelectedDefects(selectedDefects);
                setStopDoubleRender(!stopDoubleRender);
              }}
              images={selectedDefects[index].defectImages}
              defectName={defect.defectName}
              onClick={handleDeleteUploadContainer}
            />
          );
        })}
      </S.ContentDrawer>
      <S.BottomContentDrawer>
        <S.DrawerFooterButton
          firstbutton={true}
          onClick={handleSubmit}
          type="submit"
          disabled={
            selectedDefects.filter((val) => val.defectImages.length === 0)
              .length > 0
          }
          loading={loading}
        >
          Reportar
        </S.DrawerFooterButton>
        <S.DrawerFooterButton
          onClick={() => handleBackAndCleanDrawer(false)}
          loading={loading}
        >
          Voltar
        </S.DrawerFooterButton>
      </S.BottomContentDrawer>
    </S.AllDrawerContent>
  );
};
