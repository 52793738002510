import { Button } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const StyledButton = styled(Button)`
  padding: 4px 8px;
  height: auto;
  margin: 0 4px;
  font-size: 14px;
  border: 1px solid ${Colors.gray};
  border-radius: 24px;
  color: ${Colors.gray};
`;
