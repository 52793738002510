import React from "react";
import * as S from "./styles";

export const FooterButtons = ({
  nameFirstButton,
  nameSecondButton,
  onClickFirstButton,
  onClickSecondButton,
  customWidth,
  firstbuttonDisabled,
  prefixIcon,
  firstbuttonLoading,
}) => {
  return (
    <S.ContainerButtons customWidth={customWidth}>
      {nameFirstButton ? (
        <S.FooterButton
          firstbutton={true}
          onClick={onClickFirstButton}
          customWidth={customWidth}
          disabled={firstbuttonDisabled}
          loading={firstbuttonLoading}
        >
          {nameFirstButton}
        </S.FooterButton>
      ) : (
        <></>
      )}
      {nameSecondButton ? (
        <S.FooterButton
          onClick={onClickSecondButton}
          customWidth={customWidth}
          prefixIcon={prefixIcon ? true : false}
        >
          {prefixIcon}
          {nameSecondButton}
        </S.FooterButton>
      ) : (
        <></>
      )}
    </S.ContainerButtons>
  );
};
