import styled from "styled-components";
import { Upload } from "antd";
import defaultTheme from "@defaultTheme";

export const UploadB = styled(Upload)`
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    border: 1px solid #3037421a !important;
    border-radius: 8px !important;
  }
`;

export const ButtonUpload = styled.button`
  margin-top: 14px;
  border: none !important;
  display: flex;
  font-weight: 600;
  font-size: 15px;
  border-radius: 0.5rem;
  background-color: #767f8d1a;
  color: #303742;
  cursor: pointer;
  width: 142px;
  height: 44px;
  white-space: pre;
  padding: 13px 16px 13px 16px;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${defaultTheme.colors.grey20};
  }
`;
