import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter as Router } from "react-router-dom";
import routes from "./routes";
import InnerGlobalStyles from "./styles/global";
import { hotjar } from "react-hotjar";
import { GlobalStyle } from '@stonelog/stonelog-design-system';
import "./styles/custom-theme.css";

library.add(fab, fas);

const siteId = 3507870;
const hotjarVersion = 6;

export default function App() {

  hotjar.initialize({ id: Number(siteId), sv: hotjarVersion });
  hotjar.event('button-click')

  return (
    <Router>
      {routes}
      <InnerGlobalStyles
        noPadding={window.location.pathname == "/ferramentas/hunter"}
      />
      <GlobalStyle />
    </Router>
  );
}
