import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button, Form, Skeleton } from "antd";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2.34fr 1fr;
  grid-gap: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 0.75rem;
`;

export const FileContainer = styled.div`
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 1.5rem;
`;

export const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormItem = styled(Form.Item)`
  margin: 0;

  .ant-form-item-explain-error {
    margin-top: 1.25rem;
  }
  .ant-form-item-control-input-content {
    & > span {
      & > .ant-upload {
        background-color: ${defaultTheme.colors.grey50};
        width: 100%;
        height: 10.625rem;
        border: none;
        border-radius: 0.75rem !important;
        padding: 0 !important;

        .ant-upload-drag-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .ant-upload-drag-icon {
          display: none;
        }

        .ant-upload-btn {
          padding: 0 !important;
        }

        .ant-upload-text,
        .ant-upload-hint {
          font-size: ${defaultTheme.fontSize.s};
          line-height: 145%;
          letter-spacing: -0.01em;
          color: ${defaultTheme.colors.grey500};
          margin-bottom: 0;
        }

        .ant-upload-text {
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
      }

      & > .ant-upload-list .ant-upload-list-item-info {
        border: 1px solid #3037421a;
        border-radius: 0.5rem;
        width: fit-content;
        padding: 1.1rem 1rem 1rem 1rem;

        .ant-upload-text-icon svg {
          width: 19px;
          height: 19px;
          margin-top: 0.25rem;
          margin-left: -0.25rem;
        }

        .ant-upload-list-item-name {
          margin: 0 0 0 0.65rem;
        }

        .ant-upload-list-item-card-actions {
          display: block;
        }

        .ant-upload-list-item-card-actions-btn {
          opacity: 1 !important;
          margin-right: -0.32rem;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;

export const DropFileContainer = styled.div`
  margin-top: 1rem;
`;

export const DownloadContainer = styled(FileContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.013125rem 1.5rem;

  img {
    margin-bottom: 1rem;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.g};
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${defaultTheme.colors.grey700};
  margin-bottom: 0.25rem;
`;

export const Text = styled.div`
  font-size: ${defaultTheme.fontSize.s};
  line-height: 145%;
  letter-spacing: -0.01em;
  color: ${defaultTheme.colors.grey500};
`;

export const TextBold = styled(Text)`
  font-weight: 600;
  color: ${defaultTheme.colors.grey700};
  margin-bottom: 0;
  text-align: center;
`;

export const TextGrayBold = styled(Text)`
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const ButtonCustom = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  background-color: ${defaultTheme.colors.green600};
  border-radius: 0.5rem;
  color: ${defaultTheme.colors.white};
  font-size: ${defaultTheme.fontSize.m};
  font-weight: 600;
  border: none;

  span {
    margin-left: 0.5rem;
  }

  :hover,
  :focus {
    background-color: ${defaultTheme.colors.green600} !important;
    color: ${defaultTheme.colors.white} !important;
  }
`;

export const FormStyled = styled(Form)`
  height: 75%;

  .ant-skeleton {
    height: 100%;
  }
`;

export const UploadButton = styled(ButtonCustom)`
  font-size: ${defaultTheme.fontSize.s};
  margin-top: 1rem;
`;

export const DownloadButton = styled(ButtonCustom)`
  background-color: ${defaultTheme.colors.grey50};
  font-size: ${defaultTheme.fontSize.s};
  color: ${defaultTheme.colors.grey700};
  margin-top: 1rem;

  :disabled {
    background-color: ${defaultTheme.colors.grey50} !important;
    font-size: ${defaultTheme.fontSize.s} !important;
    color: ${defaultTheme.colors.grey700} !important;
  }
`;

export const CloseButton = styled.div`
  background-color: ${defaultTheme.colors.grey700};
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
  color: #42ec9a;
  font-size: ${defaultTheme.fontSize.s};
  letter-spacing: -0.01em;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 120%;
`;

export const SkeletonBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;

  .ant-skeleton {
    &:first-child {
      margin-bottom: 2rem;
    }
    &:nth-child(2) {
      margin-bottom: 0.5rem;
    }
  }
`;

export const SkeletonBox = styled(Skeleton.Input)`
  display: flex;
  align-items: center;
  border-radius: 3px;

  .ant-skeleton-input {
    width: ${(props) => (props.width ? `${props.width}` : "50%")} !important;
    min-width: ${(props) =>
      props.width ? `${props.width}` : "160px"} !important;
    height: ${(props) =>
      props.height ? `${props.height}` : "10px"} !important;
  }
`;
