/* global google */
import React, { useState } from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import appConfig from "@appConfig";
import Container from "@components/Container";
import Icon from "@components/Icon";
import Row from "@components/Row";
import Page from "@components/Page";
import LoadingMask from "@components/LoadingMask";
import LogisticService from "@LogisticService";
import { Input, notification } from "antd";
import { SearchButton } from "./styles";

const GMAPS_API_KEY = appConfig.mapsKey;

const EncontrarTerminal = () => {
  const [serialContent, setSerialContent] = useState(null);
  const [serial, setSerial] = useState(null);
  const [loading, setLoading] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Serial não encontrado",
      description: "",
    });
  };

  const search = async (serial) => {
    setLoading(true);
    try {
      const response = await LogisticService.getSerialData(serial);
      setSerialContent(response.data);
    } catch (e) {
      openNotificationWithIcon("error");
    }
    setLoading(false);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GMAPS_API_KEY,
  });

  const terminalContent = () => {
    if (!serialContent) {
      return (
        <>
          <Icon name={"search"} size="80px" />
          <br />
          <p>Procure por um terminal para exibir suas informações</p>
        </>
      );
    }
    const terminal_info = serialContent.info_serial;
    const fields = {
      serial_number: "Número de Série",
      model: "Modelo",
      type: "Tipo",
      status: "Status",
      logistic_operator: "OPL",
      provider: "Prestador",
      contractor: "Contratante",
      terminal_sak: "SAK",
      created_date: "Data de aquisição",
      invoice_code: "Nota Fiscal",
      customer: "Cliente",
      address: "Localização",
      zip_code: "CEP",
    };
    return (
      <div>
        <div style={{ textAlign: "left" }}>
          {Object.keys(terminal_info)
            .filter((r) => fields[r])
            .map((r) => (
              <p>
                <b>{fields[r]}</b>: {terminal_info[r]}
              </p>
            ))}
        </div>
        {/* <div>{serialContent.historico_oss}</div> */}
      </div>
    );
  };

  const renderPins = () => {
    const icon = {
      url: "/maquininha.png",
      scaledSize: new window.google.maps.Size(90, 82),
      labelOrigin: new window.google.maps.Point(13, 14),
    };
    const label = {
      color: "#fff",
      fontSize: "11px",
      fontWeight: "bold",
    };

    if (!serialContent) {
      return;
    }

    const terminal_info = serialContent.info_serial;
    const id = terminal_info.serial_number;
    const coords = terminal_info.address.split(",");

    const coordenadas = {
      lat: parseFloat(coords[0]),
      lng: parseFloat(coords[1]),
    };

    return (
      <Marker
        id={id}
        key={id}
        zIndex={id}
        noRedraw={true}
        optimized={true}
        position={coordenadas}
        icon={icon}
        label={label}
      ></Marker>
    );
  };

  if (loading) {
    return <LoadingMask />;
  }

  return isLoaded ? (
    <Page
      // fullScreen
      previous="Ferramentas"
      title="Localizar terminal"
      showSubTitle
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "30px",
          }}
        >
          <Input
            style={{ width: "200px", height: "30px", margin: "10px" }}
            placeholder="Serial"
            onChange={(e) => setSerial(e.target.value)}
          />
          <SearchButton
            style={{ width: "100px", height: "30px", margin: "10px" }}
            onClick={() => search(serial)}
          >
            Buscar
          </SearchButton>
        </div>
        <Row padding={"20px"}>
          <Container
            grid={1 / 3}
            overflowY={true}
            height={"65vh"}
            title={""}
            padding={"30px"}
            margin={"0px 10px"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                minHeight: "40vh",
                maxHeight: "80vh",
                textAlign: "center",
              }}
            >
              {terminalContent()}
            </div>
          </Container>
          <GoogleMap
            mapContainerClassName="App-map"
            center={{ lat: 22.52047739093263, lng: 13.36653284549709 }}
            mapContainerStyle={{
              width: "70vw",
              height: "65vh",
            }}
            zoom={2.5}
            on
          >
            {renderPins()}
          </GoogleMap>
        </Row>
      </div>
    </Page>
  ) : (
    <></>
  );
};

export default EncontrarTerminal;
