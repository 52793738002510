import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button, Skeleton } from "antd";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0.75rem;

  header {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;

    h3 {
      color: ${defaultTheme.colors.grey700};
      font-size: ${defaultTheme.fontSize["heading-s"]};
      font-weight: 600;
      letter-spacing: -0.02em;
      margin-bottom: 0.25rem;
    }

    p {
      font-size: ${defaultTheme.fontSize.xs};
      color: ${defaultTheme.colors.grey500};
      letter-spacing: -0.01em;
    }

    div:last-child {
      display: flex;

      .ant-input-affix-wrapper {
        height: 2.75rem;
      }
    }
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
`;

export const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${defaultTheme.colors.grey50};
  height: 2.75rem;
  width: 4.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  border: none;

  :hover,
  :focus {
    background-color: ${defaultTheme.colors.grey50} !important;
    color: ${defaultTheme.colors.white} !important;
  }
`;

export const DownloadButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
  padding: 0 1rem !important;
  background-color: ${defaultTheme.colors.green600};
  border-radius: 0.5rem;
  color: ${defaultTheme.colors.white};
  font-size: ${defaultTheme.fontSize.m};
  font-weight: 600;
  letter-spacing: -0.01em;
  border: none;

  span {
    margin: 0.1rem 0 0 0.59375rem;
  }

  :hover,
  :focus {
    background-color: ${defaultTheme.colors.green600} !important;
    color: ${defaultTheme.colors.white} !important;
  }
`;

export const SkeletonBox = styled(Skeleton.Input)`
  display: flex;
  align-items: center;
  border-radius: 2px;

  .ant-skeleton-input {
    width: ${(props) => (props.width ? `${props.width}` : "50%")} !important;
    min-width: ${(props) =>
      props.width ? `${props.width}` : "160px"} !important;
    height: ${(props) =>
      props.height ? `${props.height}` : "10px"} !important;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;

  span {
    color: ${defaultTheme.colors.grey700};
    font-size: ${defaultTheme.fontSize["heading-s"]};
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.02em;
    margin-top: 0.625rem;
  }

  img {
    width: 107.59px;
    height: 94px;
  }
`;
