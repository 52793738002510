import { GenIcon } from "react-icons";

function NeutralGroupIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 25",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M8.12701 6.01006C6.8425 6.01006 5.80121 7.05136 5.80121 8.33586C5.80121 9.62037 6.8425 10.6617 8.12701 10.6617C9.41151 10.6617 10.4528 9.62037 10.4528 8.33586C10.4528 7.05136 9.41151 6.01006 8.12701 6.01006ZM3.80121 8.33586C3.80121 5.94679 5.73793 4.01006 8.12701 4.01006C10.5161 4.01006 12.4528 5.94679 12.4528 8.33586C12.4528 10.7249 10.5161 12.6617 8.12701 12.6617C5.73793 12.6617 3.80121 10.7249 3.80121 8.33586Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M20.9989 9.91167C20.9989 11.9827 19.32 13.6617 17.2489 13.6617C15.1779 13.6617 13.4989 11.9827 13.4989 9.91167C13.4989 7.84061 15.1779 6.16167 17.2489 6.16167C19.32 6.16167 20.9989 7.84061 20.9989 9.91167ZM18.9989 9.91167C18.9989 10.8782 18.2154 11.6617 17.2489 11.6617C16.2824 11.6617 15.4989 10.8782 15.4989 9.91167C15.4989 8.94518 16.2824 8.16167 17.2489 8.16167C18.2154 8.16167 18.9989 8.94518 18.9989 9.91167Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M1.31555 18.1168C1.31555 15.856 3.1483 14.0232 5.4091 14.0232H10.8739C13.1347 14.0232 14.9675 15.856 14.9675 18.1168C14.9675 19.6942 13.6887 20.9729 12.1113 20.9729H4.17168C2.59428 20.9729 1.31555 19.6942 1.31555 18.1168ZM5.4091 16.0232C4.25287 16.0232 3.31555 16.9606 3.31555 18.1168C3.31555 18.5896 3.69885 18.9729 4.17168 18.9729H12.1113C12.5842 18.9729 12.9675 18.5896 12.9675 18.1168C12.9675 16.9606 12.0302 16.0232 10.8739 16.0232H5.4091Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M15.4113 15.3658C15.4113 14.8135 15.859 14.3658 16.4113 14.3658H18.7943C20.9428 14.3658 22.6844 16.1074 22.6844 18.2559C22.6844 19.7658 21.4604 20.9899 19.9504 20.9899H16.4113C15.859 20.9899 15.4113 20.5422 15.4113 19.9899C15.4113 19.4377 15.859 18.9899 16.4113 18.9899H19.9504C20.3558 18.9899 20.6844 18.6613 20.6844 18.2559C20.6844 17.212 19.8382 16.3658 18.7943 16.3658H16.4113C15.859 16.3658 15.4113 15.9181 15.4113 15.3658Z",
        },
      },
    ],
  })(props);
}

export default NeutralGroupIcon;
