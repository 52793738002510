import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import CustomModal from "@components/CustomModal";
import DetailButton from "@components/DetailButton";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { Link } from "../styles";
import DetalhesOS from "./DetalhesOS";

export default function VisitasPendentesRegiao(props) {
  const title = "Visitas Pendentes";
  const [visitasPendentesRegiao, setVisitasPendentesRegiao] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [visibleModal, setVisibleModal] = useState(undefined);
  const [visibleDetalhesOs, setVisibleDetalhesOs] = useState(false);
  const [oSSelecionada, setOSSelecionada] = useState(undefined);

  useEffect(() => {
    LogisticService.getPendentesRegiao(props.id_regiao).then((response) => {
      LogisticService.getDistritos(props.id_regiao).then((distrito) => {
        const distritos = {};
        const result = [];
        distrito.data.forEach((item) => {
          distritos[item.id] = item.name;
        });
        response.data.forEach((item) => {
          result.push({
            data: item.data,
            distrito: distritos[item.distrito_id],
            volume_visitas: item.volume_visitas,
            oss: item.oss,
          });
        });
        setVisitasPendentesRegiao(result);
        setLoaded(true);
      });
    });
  }, [props.id_regiao]);

  const dados = [];
  const colunas = [
    {
      label: "Distrito",
      key: "distrito",
      align: "start",
      sort: {
        compare: (a, b) => a.distrito.localeCompare(b.distrito),
      },
    },
  ];
  const distritos = [];
  const datas = [];
  const downloadLabels = ["Distrito"];
  const downloadData = [];
  const modalData = [];

  visitasPendentesRegiao.forEach((item) => {
    if (!distritos.includes(item.distrito)) {
      distritos.push(item.distrito);
    }
    if (!datas.includes(item.data)) {
      datas.push(item.data);
    }
  });

  distritos.forEach((distrito) => {
    const row = { distrito: distrito };

    visitasPendentesRegiao.forEach((item, id) => {
      if (item.distrito === distrito) {
        row[item.data] = (
          <>
            {item.volume_visitas}{" "}
            <DetailButton onClick={() => setVisibleModal(id)} />
          </>
        );
        row[item.data + "_num"] = item.volume_visitas;
        modalData.push(
          <CustomModal
            title={`OSs que vencem em ${item.data} ${item.distrito}`}
            key={id}
            visible={visibleModal === id}
            zIndex={1000}
            onCancel={() => setVisibleModal(undefined)}
            footer={null}
          >
            <Box maxHeight={"400px"} margin={"0"} padding={"0"} scroll>
              {item.oss.map((os) => (
                <Link
                  onClick={() => {
                    setOSSelecionada(os);
                    setVisibleDetalhesOs(true);
                  }}
                >
                  {os}
                </Link>
              ))}
            </Box>
          </CustomModal>
        );
      }
    });
    dados.push(row);
  });

  dados.forEach((item) => {
    const rowDownload = [item.distrito];
    datas.forEach((data) => {
      if (item[data + "_num"]) {
        rowDownload.push(item[data + "_num"]);
      } else {
        rowDownload.push("");
      }
    });
    downloadData.push(rowDownload);
  });

  datas.forEach((data) => {
    colunas.push({ label: data, key: data });
    downloadLabels.push(data);
  });

  const tableData = {
    data: dados,
    columns: colunas,
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title + " - " + props.name}
      info={[`Visitas Pendentes ${props.name}`]}
      downloadLabels={loaded ? downloadLabels : []}
      downloadData={loaded ? downloadData : []}
    >
      {loaded ? <InfoTable data={tableData} /> : <LoadingSpinner />}
      <CustomModal
        title={`Detalhes OS ${oSSelecionada}`}
        visible={visibleDetalhesOs}
        zIndex={1010}
        onCancel={() => setVisibleDetalhesOs(false)}
        footer={null}
      >
        <DetalhesOS os={oSSelecionada} />
      </CustomModal>
      {modalData}
    </Container>
  );
}
