import { AutoComplete } from "antd";
import React, { useState } from "react";
import SearchIcon from "@assets/icons/searchIcon";
import { CloseCircleIcon } from "@assets/icons/CloseCircleIcon";
import CircleCloseIcon from "@assets/icons/CircleCloseIcon";

import * as S from "./styles";

const SearchBox = (props) => {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  const onSearch = (searchText) => {
    setOptions(
      !searchText
        ? []
        : props.options
        ? props.options.filter((obj) => obj.value.includes(searchText))
        : []
    );
  };

  const onSelect = (data) => {
    props.onSelect(data);
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <S.AutoDivComplete iconOnRight={props.iconOnRight}>
      <S.IconSearchBox iconOnRight={props.iconOnRight}>
        {value.length > 0 ? (
          <CircleCloseIcon
            size={24}
            fill="rgba(118, 127, 141, 1)"
            onClick={() => {
              setValue("");
            }}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <SearchIcon size={24} fill="#767F8D" />
        )}
      </S.IconSearchBox>
      <AutoComplete
        value={value}
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        placeholder={props.placeholder}
        getPopupContainer={(node) => node.parentNode}
      />
    </S.AutoDivComplete>
  );
};

export default SearchBox;
