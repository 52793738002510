import { Divider } from "antd";
import React from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import Colors from "@colors";
import { Link } from "../styles";

export default function Links(props) {
  const renderTable = (list) => {
    const results = [];
    list.map((element) =>
      results.push(
        <Box margin={"8px"}>
          <Link
            href={element.link}
            target="_blank"
            style={{
              padding: "8px",
              color: Colors.lightGreen,
              fontWeight: "bold",
            }}
          >
            {element.nome}
          </Link>
          <Divider dashed style={{ margin: "8px 0 0" }} />
        </Box>
      )
    );
    return results;
  };

  const renderLinksPOS = (list) => {
    let rows = [
      {
        nome: "PRIMEIROS PASSOS S920",
        link: "https://stone.force.com/ajuda/s/article/Primeiros-Passos-S920",
      },
      {
        nome: "PRIMEIROS PASSOS D210N",
        link: "https://stone.force.com/ajuda/s/article/Primeiros-Passos-D210N-",
      },
      {
        nome: "FUNÇÕES D210N",
        link: "https://stone.force.com/ajuda/s/article/Funcoes-1483536836839",
      },
      {
        nome: "NFC",
        link: "https://stone.force.com/ajuda/s/article/TECNOLOGIA-NFC-NA-STONE",
      },
      {
        nome: "SIMULADOR DE VENDAS",
        link:
          "https://stone.force.com/ajuda/s/article/Simulador-de-vendas-na-S920",
      },
      {
        nome: "SENHA ADM",
        link:
          "https://stone.force.com/ajuda/s/article/Como-faco-para-alterar-a-senha-administrativa-1493409315434",
      },
      {
        nome: "RELATÓRIO",
        link:
          "https://stone.force.com/ajuda/s/article/O-que-e-a-funcao-Relatorios-1483536985900",
      },
      {
        nome: "CANCELAMENTO DE VENDA",
        link:
          "https://stone.force.com/ajuda/s/article/Cancelamento-de-venda-na-maquina",
      },
      {
        nome: "COMO TROCAR BOBINA",
        link: "https://stone.force.com/ajuda/s/article/Bobinas-1483536928401",
      },
    ];

    return renderTable(rows);
  };

  const renderLinksPortal = (list) => {
    let rows = [
      {
        nome: "PRIMEIRO ACESSO",
        link:
          "https://stone.force.com/ajuda/s/article/Como-faco-o-primeiro-acesso-ao-portal-Stone-1493409313837",
      },
      {
        nome: "ESQUECI A SENHA",
        link:
          "https://stone.force.com/ajuda/s/article/Como-altero-a-senha-do-meu-portal-Stone-1493409315435",
      },
      {
        nome: "TOUR DO PORTAL",
        link:
          "https://stone.force.com/ajuda/s/article/Conheca-o-portal-da-Stone-1493409313185",
      },
      {
        nome: "ABA RESUMO",
        link:
          "https://stone.force.com/ajuda/s/article/Nova-aba-resumo-no-Portal",
      },
      {
        nome: "ABA VENDAS",
        link:
          "https://stone.force.com/ajuda/s/article/Como-eu-vejo-as-vendas-que-eu-realizei",
      },
      {
        nome: "ABA PAGAMENTOS",
        link:
          "https://stone.force.com/ajuda/s/article/Como-eu-vejo-os-meus-pagamentos",
      },
      {
        nome: "VERIFICAR AS TAXAS",
        link:
          "https://stone.force.com/ajuda/s/article/Como-verificar-minhas-taxas-no-Portal",
      },
      {
        nome: "CANCELAMENTO DE VENDA",
        link:
          "https://stone.force.com/ajuda/s/article/Cancelamento-de-venda-no-portal-do-cliente-1493409314792",
      },
      {
        nome: "PEDIR BOBINA",
        link:
          "https://stone.force.com/ajuda/s/article/Como-pedir-bobina-no-portal-do-cliente-1493409307759",
      },
      {
        nome: "MANUAL DO APP",
        link:
          "https://stone.force.com/ajuda/s/article/Manual-do-Aplicativo-Portal-Stone",
      },
    ];

    return renderTable(rows);
  };

  const renderLinksRAV = (list) => {
    let rows = [
      {
        nome: "O QUE É RAV",
        link:
          "https://stone.force.com/ajuda/s/article/O-que-e-recebimento-antecipado-de-vendas-e-quais-os-beneficios",
      },
      {
        nome: "PONTUAL x AUTOMÁTICA",
        link: "https://stone.force.com/ajuda/s/article/Como-posso-antecipar",
      },
      {
        nome: "CÁLCULO RAV",
        link:
          "https://stone.force.com/ajuda/s/article/Como-e-o-calculo-do-recebimento-antecipado",
      },
      {
        nome: "RAV NA S920",
        link: "https://stone.force.com/ajuda/s/article/app-Antecipacao-na-S920",
      },
      {
        nome: "RAV NA D210N",
        link: "https://stone.force.com/ajuda/s/article/Antecipacao-na-maquina",
      },
      {
        nome: "RAV NO PORTAL",
        link:
          "https://stone.force.com/ajuda/s/article/Como-realizar-antecipacao-pelo-portal-e-conferir-minhas-taxas",
      },
      {
        nome: "RAV NO APP – ANDROID",
        link:
          "https://stone.force.com/ajuda/s/article/Aplicativo-Portal-Stone-Como-fazer-uma-antecipacao-Android",
      },
      {
        nome: "RAV NO APP – IOS",
        link:
          "https://stone.force.com/ajuda/s/article/Aplicativo-Portal-Stone-Como-fazer-uma-antecipacao-iOS",
      },
      {
        nome: "COMO FUNCIONA OUTROS PRODUTOS",
        link:
          "https://stone.force.com/ajuda/s/article/Raio-X-e-suas-principais-funcionalidades",
      },
      {
        nome: "APP RAIO X",
        link: "https://stone.force.com/ajuda/s/article/Autosservico-no-Raio-X",
      },
      {
        nome: "COLLACT",
        link:
          "https://stone.force.com/ajuda/s/article/Parceria-Stone-e-Collact",
      },
    ];

    return renderTable(rows);
  };

  return (
    <>
      <Container grid={1 / 3} title={`Links POS`}>
        {renderLinksPOS()}
      </Container>
      <Container grid={1 / 3} title={`Links Portal`}>
        {renderLinksPortal()}
      </Container>
      <Container grid={1 / 3} title={`Links RAV`}>
        {renderLinksRAV()}
      </Container>
    </>
  );
}
