import { GenIcon } from "react-icons";

function NavItemIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 25",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M5 4.5C3.34314 4.5 2 5.84315 2 7.5V15.1667C2 16.8235 3.34314 18.1667 5 18.1667H9.3C9.79419 18.1667 10.244 18.3376 10.5553 18.6067C10.8621 18.8718 11 19.1974 11 19.5C11 20.0523 11.4477 20.5 12 20.5C12.5523 20.5 13 20.0523 13 19.5C13 19.1974 13.1379 18.8718 13.4447 18.6067C13.756 18.3376 14.2058 18.1667 14.7 18.1667H19C20.6569 18.1667 22 16.8235 22 15.1667V7.5C22 5.84315 20.6569 4.5 19 4.5H15.6C14.4233 4.5 13.2707 4.90265 12.4006 5.65461C12.2574 5.77829 12.1237 5.91009 12 6.04904C11.8763 5.91009 11.7426 5.77829 11.5994 5.65461C10.7293 4.90265 9.57667 4.5 8.4 4.5H5ZM13 16.5379C13.5266 16.2934 14.1105 16.1667 14.7 16.1667H19C19.5523 16.1667 20 15.719 20 15.1667V7.5C20 6.94772 19.5523 6.5 19 6.5H15.6C14.8671 6.5 14.1884 6.7529 13.7083 7.16784C13.2327 7.57882 13 8.10223 13 8.61111V16.5379ZM11 16.5379V8.61111C11 8.10223 10.7673 7.57882 10.2917 7.16784C9.81158 6.7529 9.13289 6.5 8.4 6.5H5C4.44772 6.5 4 6.94772 4 7.5V15.1667C4 15.719 4.44771 16.1667 5 16.1667H9.3C9.88952 16.1667 10.4734 16.2934 11 16.5379Z",
        },
      },
    ],
  })(props);
}

export default NavItemIcon;
