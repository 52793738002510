import { GenIcon } from "react-icons";

export function CloseCircleIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 20 20",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M9.99996 1.66669C7.78982 1.66669 5.67021 2.54466 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 11.0944 1.88217 12.178 2.30096 13.189C2.71975 14.2001 3.33358 15.1188 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C11.0943 18.3334 12.1779 18.1178 13.189 17.699C14.2 17.2802 15.1187 16.6664 15.8925 15.8926C16.6663 15.1188 17.2802 14.2001 17.699 13.189C18.1177 12.178 18.3333 11.0944 18.3333 10C18.3333 7.78988 17.4553 5.67027 15.8925 4.10746C14.3297 2.54466 12.2101 1.66669 9.99996 1.66669ZM6.9107 6.91077C7.23614 6.58533 7.76378 6.58533 8.08922 6.91077L10.0025 8.82391L11.9128 6.91351C12.2383 6.58807 12.7659 6.58807 13.0914 6.91351C13.4168 7.23895 13.4168 7.76659 13.0914 8.09202L11.181 10.0024L13.0865 11.908C13.4119 12.2335 13.4119 12.7611 13.0865 13.0865C12.761 13.412 12.2334 13.412 11.908 13.0865L10.0025 11.1809L8.0941 13.0893C7.76866 13.4147 7.24102 13.4147 6.91559 13.0893C6.59015 12.7638 6.59015 12.2362 6.91559 11.9108L8.82395 10.0024L6.9107 8.08928C6.58527 7.76384 6.58527 7.2362 6.9107 6.91077Z",
        },
      },
    ],
  })(props);
}
