import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import BarChart from "@components/BarChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { getOneYearAgo } from "@helper";
import Colors from "@colors";

export default function ShareTon(props) {
  const title = "Share de Operações TON";
  const [shareTon, setShareTon] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getShareTon().then((response) => {
      if (response && response.data != null) {
        setShareTon(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const angels = [];
  const delivery = [];
  const redirect = [];
  const box = [];
  const terceira = [];
  const correio = [];
  const flash = [];
  const volume = [];
  const downloadLabels = [
    "Período",
    "Angels",
    "Delivery",
    "Redirect",
    "Box",
    "Terceira",
    "Correio",
    "Flash",
    "Volume (em K)",
  ];
  const downloadData = [];

  shareTon &&
    shareTon.map(
      (item) => (
        periodos.push(item.periodo),
        angels.push((item.angels * 100).toFixed(1)),
        delivery.push((item.delivery * 100).toFixed(1)),
        redirect.push((item.redirect * 100).toFixed(1)),
        box.push((item.box * 100).toFixed(1)),
        terceira.push((item.terceira * 100).toFixed(1)),
        correio.push((item.correio * 100).toFixed(1)),
        flash.push((item.flash * 100).toFixed(1)),
        volume.push((item.volume / 1000).toFixed(1)),
        downloadData.push([
          `${item.periodo}`,
          `${(item.angels * 100).toFixed(3)}%`,
          `${(item.delivery * 100).toFixed(3)}%`,
          `${(item.redirect * 100).toFixed(3)}%`,
          `${(item.box * 100).toFixed(3)}%`,
          `${(item.terceira * 100).toFixed(3)}%`,
          `${(item.correio * 100).toFixed(3)}%`,
          `${(item.flash * 100).toFixed(3)}%`,
          `${(item.volume / 1000).toFixed(3)}`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    datalabelsCenter: true,
    datalabelsPercentage: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          if ([0].includes(tooltipItem.datasetIndex)) {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${
              tooltipItem.yLabel
            }`;
          }
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${
            tooltipItem.yLabel
          }%`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          id: "A",
          stacked: true,
          gridBars: false,
          position: "right",
          ticks: {
            padding: 16,
            max: 100,
            callback: function (value) {
              return value;
            },
          },
        },
        {
          id: "B",
          stacked: true,
          gridBars: false,
          position: "left",
          ticks: {
            padding: 16,
            max: 100,
            callback: function (value) {
              return value + "%";
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
            min: getOneYearAgo(),
          },
          stacked: true,
          gridBars: false,
        },
      ],
    },
    data: [
      {
        label: "Volume (em K)",
        color: Colors.darkGreen,
        fill: false,
        value: volume,
        type: "line",
        yAxesID: "A",
        normalDatalabel: true,
      },
      {
        label: "Angels",
        color: Colors.blue,
        fill: false,
        value: angels,
        yAxesID: "B",
      },
      {
        label: "Box",
        color: Colors.textGreen,
        fill: false,
        value: box,
        yAxesID: "B",
      },
      {
        label: "Correio",
        color: Colors.lighterGreen,
        fill: false,
        value: correio,
        yAxesID: "B",
      },
      {
        label: "Delivery",
        color: Colors.cautionYellow,
        fill: false,
        value: delivery,
        yAxesID: "B",
      },
      {
        label: "Flash",
        color: Colors.darkBlue,
        fill: false,
        value: flash,
        yAxesID: "B",
      },
      {
        label: "Redirect",
        color: Colors.lighterGreen,
        fill: false,
        value: redirect,
        yAxesID: "B",
      },
      {
        label: "Terceira",
        color: Colors.green,
        fill: false,
        value: terceira,
        yAxesID: "B",
      },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title}
      info={[[""]]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <BarChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
