import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import { Item, Lista, Paragraph } from "../styles";

export default function Xavier(props) {
  const loading = <LoadingOutlined spin />;
  const [problemasCliente, setProblemasCliente] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const listaTextosProblemas = {
    "Conta bancária inválida": `Esse cliente está com a conta bancária Inválida. Dessa forma, está impossibilitado de receber qualquer transação. Entre em contato com o RC junto ao cliente para resolver a questão. Telefone do RC: (21) 3004-9680`,
    "OS de Troca Aberta - Cliente Onboarding": `Está aberta uma OS de troca dentro dos primeiros 30 dias do credenciamento do cliente.`,
    "Bounce no Email": `Este cliente está com problema para receber emails da Stone. Confirme o email dele ou peça para entrar em contato com o RC. Telefone do RC: (21) 3004-9680`,
    "Primeira Instalação": `Está aberta a primeira OS do cliente. Dessa forma, vamos causar uma excelente primeira impressão!`,
    "Cliente detrator": `Esse cliente é um cliente detrator. Dessa froma, tem um NPS baixo.`,
    "Elo Full,Amex ou Hipercard não habilitados": `Esse cliente possui bandeiras não habilitadas.`,
    "Vouchers de alimentação ainda não habilitados": `Esse cliente possui Vouchers ainda não habilitadas.`,
    "Cliente em churn": `Esse cliente é um possível churn. É uma boa oportunidade para tentar reverter.`,
    "Falta de uso de vouchers": `Esse cliente possui vouchers habilitados mas não está utilizando. É uma oportunidade de verificar se há algum problema ou mapear o balcão.`,
    "Cliente nao usa modalidade credito": `Esse cliente não está utilizando a modalidade crédito. É uma oportunidade de verificar se o cliente usa apenas débito ou utiliza alguma outra adquirente para crédito.`,
    "Esse cliente possui trava com seu banco.": `Essa é uma excelente oportunidade de falar sobre a Conta Stone. Caso o cliente tenha interesse, baixe com ele o App da Conta Stone!`,
    "Cliente acessou o portal há mais de duas semanas": `Faz mais de 2 semanas que o cliente não acessa o portal. É uma ótima oportunidade de lembrá-lo do portal e acessar com ele. Vamos ajudar o cliente a ter uma experiência mais completa com a gente!`,
    "Cliente nunca acessou ao portal.": `Esse cliente nunca acessou o portal. Por que não acessar com ele e mostrar a experiência completa dele com a gente? Caso o email dele para acessar o portal esteja errado, peça que entre em contato com RC para alterar. Telefone do RC: (21) 3004-9680`,
    "Oportuninade de Vender RAV": `Esse cliente já antecipou antes e é uma excelente oportunidades para ativar a antecipação automática! Angel também faz RAV!`,
    "Cliente com antecipação bloqueada": `Esse cliente está com a antecipação bloqueada. Não vale tentar vender RAV.`,
    "Problema no Xavier": `API do Xavier em lentidão, aguarde um pouco, se demorar procure o time de sistemas :)`,
  };

  useEffect(() => {
    try {
      LogisticService.getClienteXavier(props.stonecode).then((xavierInfo) => {
        if (xavierInfo && xavierInfo.data != null) {
          if (xavierInfo.data === false) {
            setProblemasCliente([{ name: "Problema no Xavier" }]);
          } else {
            LogisticService.getPix(props.stonecode).then((pixInfo) => {
              if (pixInfo && pixInfo.data != null) {
                xavierInfo.data.problems.push(pixInfo.data);
              }
              setProblemasCliente(xavierInfo.data.problems);
            });
          }
        }
        setLoaded(true);
      });
    } catch {
      setLoaded(true);
      setProblemasCliente([{ name: "Problema no Xavier" }]);
    }
  }, []);

  const renderLista = () => {
    const itens = [];
    problemasCliente.map((item) => {
      if (item.name == "Elo Full,Amex ou Hipercard não habilitados") {
        const disponiveis = [];
        item.data.map((element) => {
          disponiveis.push(
            <Paragraph>{`BANDEIRA: ${element.brand} - PRODUTO: ${element.product} - STATUS: ${element.status}`}</Paragraph>
          );
        });
        itens.push(
          <Item>
            {listaTextosProblemas[item.name]}
            {disponiveis}
          </Item>
        );
      } else if (item.name == "Vouchers de alimentação ainda não habilitados") {
        const disponiveis = [];
        const dias = 3;
        item.data.map((element) => {
          const dias_uteis = dias;
          if (element.brand in ["Alelo", "Ticket", "VR"]) {
            dias_uteis = 5;
          }
          disponiveis.push(
            <Paragraph>{`BANDEIRA: ${element.brand} - PRODUTO: ${element.product} - STATUS: ${element.status} - Prazo Médio de Habilitação: ${dias_uteis} dias úteis`}</Paragraph>
          );
        });
        itens.push(
          <Item>
            {listaTextosProblemas[item.name]}
            {disponiveis}
          </Item>
        );
      } else if (item.name == "Cliente nunca acessou ao portal.") {
        itens.push(
          <Item>
            {listaTextosProblemas[item.name]}
            <Paragraph>{`O email dele para acessar o portal é: ${item.data}`}</Paragraph>
          </Item>
        );
      } else if (item.name == "Oportuninade de Vender RAV") {
        itens.push(
          <Item>
            {listaTextosProblemas[item.name]}
            <Paragraph>{`As taxa são:`}</Paragraph>
            <Paragraph>{`Automática: ${item.data.advance_rate}`}</Paragraph>
            <Paragraph>{`Spot: ${item.data.spot_rate}`}</Paragraph>
          </Item>
        );
      } else {
        itens.push(<Item>{listaTextosProblemas[item.name]}</Item>);
      }
    });
    return <Lista>{itens}</Lista>;
  };

  return (
    <Container title={`Oportunidades do Xavier`} grid={1 / 3}>
      {loaded ? (
        <Box>{renderLista()}</Box>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
