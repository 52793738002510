import styled from "styled-components";
import Colors from "@colors";

export const Container = styled.div`
  input[type="file"] {
    display: none;
  }

  label {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid ${Colors.darkGray};
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }
`;

export const PreviewContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 4px;
    right: 4px;

    background: none;
    border: none;
  }
`;
