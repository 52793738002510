import React, { forwardRef } from "react";
import { Input } from "antd";
import { FiAlertTriangle } from "react-icons/fi";

import FloatLabel from "../FloatLabelThird";

import EyeIcon from "@assets/icons/EyeIcon";
import BipeIcon from "@assets/icons/BipeIcon";

import "./styles.css";

const CustomInput = forwardRef(
  (
    { name, control, label, hasError, bipe, checked = false, ...props },
    ref
  ) => {
    const suffix = () => {
      if (hasError) return <FiAlertTriangle size={24} color="#F38428" />;
      if (checked) return <EyeIcon size={24} color="#008E5A" />;
      if (bipe) return <BipeIcon size={24} color="#767F8D" />;
      return <BipeIcon size={24} color="transparent" />;
    };

    return (
      <FloatLabel label={label} hasError={hasError}>
        <Input
          ref={ref}
          status={hasError ? "error" : ""}
          suffix={suffix()}
          {...props}
        />
      </FloatLabel>
    );
  }
);

export default CustomInput;
