import axios from "axios";

import appConfig from "./appConfig";
import { getCookie } from "./helper";
import {
  useAuthContext,
  getAuthenticatedAxiosInstance,
} from "@stonelog/stonelog-auth-components";

export default class LogisticService {
  static request(url) {
    return axios.get(url);
  }

  static requestWithAuth(url) {
    return getAuthenticatedAxiosInstance().get(url);
  }

  static requestWithLogToken(url) {
    return getAuthenticatedAxiosInstance().get(url);
  }

  static postWithLogToken(url, data) {
    return getAuthenticatedAxiosInstance().post(url, data);
  }

  static postWithAuth(url, data) {
    return getAuthenticatedAxiosInstance().post(url, data);
  }

  static postWithAuthWithCancelOpt(url, data, cancel) {
    return getAuthenticatedAxiosInstance().post(url, data, cancel);
  }

  static deleteWithAuth(url, data) {
    return getAuthenticatedAxiosInstance().delete(url, {
      data: data,
    });
  }

  static patchWithAuth(url, data) {
    return getAuthenticatedAxiosInstance().patch(url, data);
  }

  static putWithAuth(url, data) {
    return getAuthenticatedAxiosInstance().put(url, data);
  }

  static logIn() {
    return axios.get(`${appConfig.logisticsUrl}/auth/login`);
  }

  static auth(params) {
    return axios.get(`${appConfig.logisticsUrl}/auth/callback${params}`);
  }

  static getHistoricoSla() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/bau/historico-sla`
    );
  }

  static getAberturaFechamento() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/bau/abertura-fechamento`
    );
  }

  static getAgingChamadosEmAtraso() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/bau/aging-chamados-em-atraso`
    );
  }

  static getHistoricoChamadosEmAtraso() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/bau/historico-chamados-em-atraso`
    );
  }

  static getShareOperacaoPropria() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/bau/share-operacao-propria`
    );
  }

  static getCsat() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/bau/csat`
    );
  }

  static getConfiabilidade() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/confiabilidade`
    );
  }

  static getQualidade() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/qualidade`
    );
  }

  static getVelocidade() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/velocidade`
    );
  }

  static getShareDiasAtendimento() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/share-dias-atendimento`
    );
  }

  static getEvolucaoEtapasCadeia() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/evolucao-etapas-cadeia`
    );
  }

  static getCobertura() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/cobertura`
    );
  }

  static getPerdasLiquidas() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/perdas-liquidas`
    );
  }

  static postPerdasLiquidas(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/perdas-liquidas`,
      data
    );
  }

  static getIDR() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/idr`
    );
  }

  static getShareOperacoes() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/share-operacoes`
    );
  }

  static getRotas(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/rotas/${polo}`
    );
  }

  static getRotasZeroTPV(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/rotas_0tpv/${polo}`
    );
  }
  static getRotasZeroTPVTon(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/rotas_0tpv_ton/${polo}`
    );
  }

  static getSeriais(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/seriais_0tpv/${stonecode}`
    );
  }

  static abreOsCampanha(stonecode, data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/seriais_0tpv/${stonecode}`,
      data
    );
  }

  static getAngelsDisponiveis(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/angels_disponiveis/${polo}`
    );
  }

  static getOssEuEntrego(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/oss_eu_entrego/${polo}`
    );
  }

  static postOssEuEntrego(polo, data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/oss_eu_entrego/${polo}`,
      data
    );
  }

  static postRealocaOSs(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/realoca_oss`,
      data
    );
  }

  static postRealocaOSsCancel(data, cancel) {
    return LogisticService.postWithAuthWithCancelOpt(
      `${appConfig.logisticsUrl}/atendimento/senninha/realoca_oss`,
      data,
      cancel
    );
  }

  static postTrocaRotas(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/troca_rotas`,
      data
    );
  }

  static postAdicionarOSsCampanha(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/adiciona_oss_campanha`,
      data
    );
  }

  static getConfiguracoes(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/configuracoes/${polo}`
    );
  }

  static postConfiguracoes(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/posta_configuracoes`,
      data
    );
  }

  static deleteConfiguracoes(nome) {
    return LogisticService.deleteWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/deleta_configuracoes/${nome}`
    );
  }
  static getAreasRisco(distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/areas_de_risco/${distrito}`
    );
  }

  static getAreas(distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/areas/${distrito}`
    );
  }

  static postArea(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/posta_area`,
      data
    );
  }

  static deleteArea(id) {
    return LogisticService.deleteWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/deleta_area/${id}`
    );
  }

  static getDistritoPorId(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/distrito_por_id/${id_distrito}`
    );
  }

  static getPoloPorId(id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/polo_por_id/${id_polo}`
    );
  }

  static getPolos() {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/polos`
    );
  }

  static getPolosWKF() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/polos-wkf`
    );
  }

  static getDistritos(id_regiao) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/distritos/${id_regiao}`
    );
  }
  static getTodosOsDistritos() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/distritos`
    );
  }

  static getRegioes() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/regioes`
    );
  }

  static getPolosPorDistrito(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/polos_por_distrito/${id_distrito}`
    );
  }

  static getAlertasPolo(id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/alertas_polo/${id_polo}`
    );
  }

  static getAvancoPolo(id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/avanco_polo/${id_polo}`
    );
  }

  static getEstoquePolo(id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/estoque_polo/${id_polo}`
    );
  }

  static getPendentesDistrito(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/pendentes_distrito/${id_distrito}`
    );
  }

  static getPendentesPolo(id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/pendentes_polo/${id_polo}`
    );
  }

  static getVencimentoHoje(id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/chamados-hoje-pendentes/${id_polo}`
    );
  }

  static getPendentesRegiao(id_regiao) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/pendentes_regiao/${id_regiao}`
    );
  }

  static getDetalhesOS(os) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/detalhes_os/${os}`
    );
  }

  static getSlaTon() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/sla-ton`
    );
  }

  static getTmaTon() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/tma-ton`
    );
  }

  static getShareTon() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/share-ton`
    );
  }

  static getTmaCartao() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/one-page/tma-cartao`
    );
  }

  static getGruposServicoDistrito(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/grupos_servico_distrito/${id_distrito}`
    );
  }

  static getGruposServicoDistritoTotal(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/grupos_servico_distrito_total/${id_distrito}`
    );
  }

  static getMetaRealizadoDistrito(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/meta_realizado_distrito/${id_distrito}`
    );
  }

  static getMetaRealizadoDistritoTotal(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/meta_realizado_distrito_total/${id_distrito}`
    );
  }

  static getMotivosTroca(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/motivos_troca/${id_distrito}`
    );
  }

  static getMotivosTrocaNaoTriadoGood(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/motivos_troca_nao_triado_good/${id_distrito}`
    );
  }

  static getAntecipados(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/antecipados/${id_distrito}/${id_polo}`
    );
  }

  static getAtrasados(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/atrasados/${id_distrito}/${id_polo}`
    );
  }

  static getCsatAngel(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/csat_angel/${id_distrito}/${id_polo}`
    );
  }

  static getCsatMes(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/csat_mes/${id_distrito}/${id_polo}`
    );
  }

  static getCsatDiario(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/csat_semana/${id_distrito}/${id_polo}`
    );
  }

  static getCsatServico(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/csat_servico/${id_distrito}/${id_polo}`
    );
  }

  static getMotivosAtrasoDiario(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/motivos_atraso/${id_distrito}/${id_polo}`
    );
  }

  static getSlaAngel(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/sla_angel/${id_distrito}/${id_polo}`
    );
  }

  static getSlaDiario(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/sla_diario/${id_distrito}/${id_polo}`
    );
  }

  static getSlaMes(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/sla_mes/${id_distrito}/${id_polo}`
    );
  }

  static getSlaSemana(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/sla_semana/${id_distrito}/${id_polo}`
    );
  }

  static getSlaServico(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/sla_servico/${id_distrito}/${id_polo}`
    );
  }

  static getTmaAngel(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/tma_angel/${id_distrito}/${id_polo}`
    );
  }

  static getTmaCidade(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/tma_cidade/${id_distrito}/${id_polo}`
    );
  }

  static getTmaDiario(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/tma_diario/${id_distrito}/${id_polo}`
    );
  }

  static getTmaMes(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/tma_mes/${id_distrito}/${id_polo}`
    );
  }

  static getTmaSemana(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/tma_semana/${id_distrito}/${id_polo}`
    );
  }

  static getTmaServico(id_distrito, id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/indicadores/tma_servico/${id_distrito}/${id_polo}`
    );
  }

  static getClienteEquals(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/cliente-equals/${stonecode}`
    );
  }

  static getClienteXavier(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/cliente-xavier/${stonecode}`
    );
  }

  static getInformacoesGerais(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/cliente-celebro/${stonecode}`
    );
  }

  static getMetodos(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/metodos/${stonecode}`
    );
  }

  static getUltimoPagamento(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/ultimo-pagamento/${stonecode}`
    );
  }

  static getRAV(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/rav/${stonecode}`
    );
  }

  static getUltimoAcesso(email) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/ultimo-acesso/${email}`
    );
  }

  static getUsuariosPortal(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/usuarios-portal/${stonecode}`
    );
  }

  static getVouchers(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/vouchers/${stonecode}`
    );
  }

  static getPix(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/dados-cliente/pix/${stonecode}`
    );
  }

  static getGraficoTransacoes(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/grafico_transacoes/${stonecode}`
    );
  }

  static getOutrosStonecodes(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/outros_stonecodes/${stonecode}`
    );
  }

  static postOutrosStonecodes(stonecode) {
    return LogisticService.postWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/outros_stonecodes/${stonecode}`
    );
  }

  static getEmprestimosECredito(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/emprestimos_e_credito/${stonecode}`
    );
  }

  static getMaquinasCliente(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/maquinas_cliente/${stonecode}`
    );
  }

  static getHorasAtendimento(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/horas_atendimento/${stonecode}`
    );
  }

  static getSaldoAtual(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/saldo_atual/${stonecode}`
    );
  }

  static getTravaBancaria(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/trava_bancaria/${stonecode}`
    );
  }

  static getTpvMedio(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/tpv_medio/${stonecode}`
    );
  }

  static getEnderecoCliente(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/enderecos_hunter/${stonecode}`
    );
  }

  static postEnderecoCliente(stonecode, data) {
    return LogisticService.postWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/enderecos_hunter/${stonecode}`,
      data
    );
  }

  static postAberturaOS(data) {
    return LogisticService.postWithLogToken(
      `${appConfig.hunterUrl}/atendimento/hunter/abertura_os_hunter`,
      data
    );
  }

  static getHistoricoCobranca(stonecode, id_terminal) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/historico_aluguel/${stonecode}/${id_terminal}`
    );
  }

  static getIsencaoVigente(stonecode, id_terminal) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/historico_aluguel/${stonecode}/${id_terminal}`
    );
  }

  static getEnderecoCEP(cep) {
    return LogisticService.request(
      `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`
    );
  }

  static getSalesforceTodosCasos(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.hunterUrl}/atendimento/hunter/retorna_todos_casos/${stonecode}`
    );
  }

  static getSalesforceCaso(caso) {
    return LogisticService.requestWithLogToken(
      `${appConfig.hunterUrl}/atendimento/hunter/retorna_caso/${caso}`
    );
  }

  static getArvoreCasos(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.hunterUrl}/atendimento/hunter/arvore_casos`
    );
  }

  static postAberturaCaso(data) {
    return LogisticService.postWithLogToken(
      `${appConfig.hunterUrl}/atendimento/hunter/abertura_de_caso`,
      data
    );
  }

  static postOwnerId(email, data) {
    return LogisticService.postWithLogToken(
      `${appConfig.hunterUrl}/salesforce/id_dono_caso/${email}`,
      data
    );
  }

  static getAgendamentoDisponivel(state, city, zipCode, equipmentType) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/agendamento_disponivel/${state}/${city}/${zipCode}/${equipmentType}`
    );
  }

  static postMotivoIneficiencia(data) {
    return LogisticService.postWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/motivo_ineficiencia`,
      data
    );
  }

  static getOsClienteHunter(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/hunter/historico_os_hunter/${stonecode}`
    );
  }

  static getPeriodosDiponiveisRV(tipo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/periodos-disponiveis/${tipo}`
    );
  }

  static getGreenAngelsRV(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/angels-disponiveis/${ano}/${mes}`
    );
  }

  static getGuardiansRV(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/guardians-disponiveis/${ano}/${mes}`
    );
  }

  static getSuppliersRV(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/suppliers-disponiveis/${ano}/${mes}`
    );
  }

  static getHostAngelsRV(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/hosts-disponiveis/${ano}/${mes}`
    );
  }

  static getDistritaisRV(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/distritais-disponiveis/${ano}/${mes}`
    );
  }

  static getRegionaisRV(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/regionais-disponiveis/${ano}/${mes}`
    );
  }

  static getResultadosAngel(ano, mes, nome) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/resultado/angel/${ano}/${mes}/${nome}`
    );
  }

  static getResultadosHostAngels(ano, mes, nome) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/resultado/host/${ano}/${mes}/${nome}`
    );
  }

  static getResultadosGuardian(ano, mes, nome) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/resultado/guardian/${ano}/${mes}/${nome}`
    );
  }

  static getResultadosSupplier(ano, mes, nome) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/resultado/supplier/${ano}/${mes}/${nome}`
    );
  }

  static getResultadosDistrital(ano, mes, nome) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/resultado/distrital/${ano}/${mes}/${nome}`
    );
  }

  static getResultadosRegional(ano, mes, nome) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/resultado/regional/${ano}/${mes}/${nome}`
    );
  }

  static getOrdensAngels(ano, mes, nome, polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-ordens/angel/${ano}/${mes}/${nome}/${polo}`
    );
  }

  static getPortalAngels(ano, mes, nome) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-portal/angel/${ano}/${mes}/${nome}`
    );
  }

  static getReversaAngels(ano, mes, polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-reversa/angel/${ano}/${mes}/${polo}`
    );
  }

  static getNPSAngels(ano, mes, nome) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-nps/angel/${ano}/${mes}/${nome}`
    );
  }

  static getNPSDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-nps/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getOrdensDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-ordens/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getAcidentesDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-acidentes/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getPenalidadesDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-penalidades/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getTerminaisRemovidosDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-terminais-removidos/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getPenalidadesReversasDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-penalidades-reversas/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getTriagensDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-triagens/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getEstoqueDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-estoque-fotos/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getTrocasDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-trocas/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getDesinstalacoesDistrito(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-desinstalacoes/distrito/${ano}/${mes}/${distrito}`
    );
  }

  static getRankingAngel(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/ranking/angel/${ano}/${mes}`
    );
  }

  static getRankingGuardian(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/ranking/guardian/${ano}/${mes}`
    );
  }

  static getRankingSupplier(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/ranking/supplier/${ano}/${mes}`
    );
  }

  static getRankingHost(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/ranking/host/${ano}/${mes}`
    );
  }

  static getRankingDistrital(ano, mes) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/ranking/distrito/${ano}/${mes}`
    );
  }

  // static getRankingRegional(ano, mes) {
  //     return LogisticService.requestWithAuth(`${appConfig.logisticsUrl}/rv/ranking/regional/${ano}/${mes}`)
  // }

  static getCasos() {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/classificacao-casos/tca`
    );
  }

  static postCasos(data) {
    return LogisticService.postWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/classificacao-casos`,
      data
    );
  }

  static getEmailsMembros() {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/classificacao-casos/members`
    );
  }

  static getArvoreLiga() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/classificacao-casos/arvore`
    );
  }

  static postArvoreLiga(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/classificacao-casos/arvore`,
      data
    );
  }

  static deleteArvoreLiga(data) {
    return LogisticService.deleteWithAuth(
      `${appConfig.logisticsUrl}/atendimento/classificacao-casos/arvore`,
      data
    );
  }

  static updateArvoreLiga(data) {
    return LogisticService.patchWithAuth(
      `${appConfig.logisticsUrl}/atendimento/classificacao-casos/arvore`,
      data
    );
  }

  static postMapeamentoOn(data) {
    return LogisticService.postWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/mapeamento-on`,
      data
    );
  }

  static getDemandas(data) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/mapeamento-on`
    );
  }

  static getOrigemDemanda() {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/mapeamento-on/origens`
    );
  }

  static getArvoreDemandas() {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/mapeamento-on/arvore`
    );
  }

  static postArvoreDemandas(data) {
    return LogisticService.postWithLogToken(
      `${appConfig.logisticsUrl}/atendimento/mapeamento-on/arvore`,
      data
    );
  }

  static deleteArvoreDemandas(data) {
    return LogisticService.deleteWithAuth(
      `${appConfig.logisticsUrl}/atendimento/mapeamento-on/arvore`,
      data
    );
  }

  static updateArvoreDemandas(data) {
    return LogisticService.patchWithAuth(
      `${appConfig.logisticsUrl}/atendimento/mapeamento-on/arvore`,
      data
    );
  }

  static getAngels(data) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/angels`
    );
  }

  static getNotificacaoApp() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/notificacoes-app`
    );
  }

  static getStonecodeValido(stonecode) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/stonecode_valido/${stonecode}`
    );
  }

  static getMotivosAtrasoDistrital(provider) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/motivos_distrital/${provider}`
    );
  }

  static getMotivosAtrasoDistritalAmanha(provider) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/motivos_distrital_amanha/${provider}`
    );
  }

  static getBasesParceiras() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/motivos_parceiro`
    );
  }

  static postMotivosAtrasoDistrital(polo, data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/motivos_distrital/${polo}`,
      data
    );
  }

  static postArvoreMotivosAtraso() {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/motivos_arvore`
    );
  }

  static postNotificacaoApp(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/notificacoes-app`,
      data
    );
  }

  static getRealizadosRegiao(id_regiao) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/realizados-regiao/${id_regiao}`
    );
  }

  static getRealizadosDistrito(id_distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/realizados-distrito/${id_distrito}`
    );
  }

  static getRealizadosPolo(id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/gestao_distrito_polo/realizados-polo/${id_polo}`
    );
  }

  static getDadosAnexos(os_number) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/anexos_oss/dados-anexo/${os_number}`
    );
  }

  static getDadosAnexosLogLoj(tracking_code) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/anexos_oss/dados-anexo-log-loj/${tracking_code}`
    );
  }

  static getAnexosTrucker(numero_om) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/anexos_oms/${numero_om}`
    );
  }
  static getAnexosAutonomia(numero_os) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/autonomia_anexo/${numero_os}`
    );
  }

  static getSerialData(serial) {
    return LogisticService.requestWithLogToken(
      `${appConfig.logisticsUrl}/ferramentas/busca_pos/${serial}`
    );
  }

  static getMotivosAtrasoParceiro(id_polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/motivos_parceiro_id/${id_polo}`
    );
  }

  static postMotivosAtrasoParceiro(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/motivos_parceiro`,
      data
    );
  }

  static getStatusContrato(stonecode) {
    return LogisticService.requestWithLogToken(
      `${appConfig.hunterUrl}/atendimento/hunter/status_contrato/${stonecode}`
    );
  }

  // POS na Mochila

  static getPolosComerciais() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/polo_comercial`
    );
  }

  static getComerciais(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/cadastro_comercial/${polo}`
    );
  }

  static postComercial(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/cadastro_comercial`,
      data
    );
  }

  static getComerciaisDisponiveis(polo_comercial) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/configuracoes_agentes/${polo_comercial}`
    );
  }

  static getConfiguracoesSeriais(polo_comercial) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/serial_comercial/${polo_comercial}`
    );
  }

  static postConfiguracoesSeriais(data) {
    return LogisticService.patchWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/serial_comercial`,
      data
    );
  }

  static getOpcoesAutonomia() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/autonomia/opcoes`
    );
  }

  static getMotivosAutonomia() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/autonomia/motivos-ineficiencia`
    );
  }

  static postMotivosAutonomia(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/autonomia/motivos-ineficiencia`,
      data
    );
  }

  static putMotivosAutonomia(data) {
    return LogisticService.putWithAuth(
      `${appConfig.logisticsUrl}/atendimento/autonomia/motivos-ineficiencia`,
      data
    );
  }

  static getAlertaGestaoPessoas(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/recebe_justificativa/${polo}`
    );
  }

  static postJustificativaGestaoPessoas(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/recebe_justificativa/0`,
      data
    );
  }

  static getAlocacoes(ano, mes, distrito) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/rv/detalhe-alocacoes/${distrito}/${ano}/${mes}`
    );
  }

  // Doa

  static postRegistroDoa(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/registro_doa`,
      data
    );
  }
  static postValidaSerialDoa(serial, prestador) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/valida_serial_doa/${serial}/${prestador}`
    );
  }

  static getDefeitosDoa() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/defeitos_doa`
    );
  }
  static getRegistrosDoa(info_do_registro) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/registro_doa/${info_do_registro}`
    );
  }
  static getRegistroDoaDetalhado(id_doa) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/registro_doa_detalhado/${id_doa}`
    );
  }

  // QD
  static getCoberturaEstoqueStone(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/cobertura_estoque_stone/${polo}`
    );
  }

  static getCoberturaEstoqueTon(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/cobertura_estoque_ton/${polo}`
    );
  }

  // ANGELS GREEN APP
  static getAngelsGreenApp() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/angels-green-app`
    );
  }

  static postAngelsGreenApp(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/ferramentas/angels-green-app`,
      data
    );
  }

  // Cockpit
  static getRotasCockpit(polo, tipo_parametro) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/cockpit/rotas/${polo}/${tipo_parametro}`
    );
  }

  static postSimulaRotaDistritalCockpit(data) {
    return LogisticService.postWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/cockpit/simulacao`,
      data
    );
  }

  static getListaInfosCockpit(polo) {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/atendimento/senninha/cockpit/infos_os/${polo}`
    );
  }

  // Tracking
  static getTrackingAngels() {
    return LogisticService.requestWithAuth(
      `${appConfig.logisticsUrl}/tracking/angels`
    );
  }
}
