import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button } from "antd";

export const Content = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span:nth-child(2) {
    font-size: ${defaultTheme.fontSize.m};
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${defaultTheme.colors.grey700};
    margin-top: 1.5rem;
  }

  & > span:nth-child(3) {
    display: flex;
    align-self: start;
    margin: 1.5rem 0 ${({ dontCenter }) => (dontCenter ? "0.5rem" : "-1rem")} 0;
    padding: 0.62rem 0.5rem;
    color: ${defaultTheme.colors.grey500};
    align-self: ${({ dontCenter }) => (dontCenter ? "start" : "center")};
    font-size: 0.8125rem;
    line-height: 0.975rem;
    letter-spacing: -0.00813rem;
    cursor: ${({ dontCenter }) => (dontCenter ? "pointer" : "auto")};

    svg {
      margin-left: 0.5rem;
    }
  }

  & > span:nth-child(4) {
    background-color: #f7f7fa;
    max-height: 12rem;
    width: 100%;
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;

    > div {
      height: 10rem;
      overflow-y: scroll;
      padding-right: 1rem;

      ::-webkit-scrollbar {
        width: 8px;
        border-radius: 1rem;
      }

      ::-webkit-scrollbar-thumb {
        background: #909498 !important;
        border-radius: 1rem;
        background-clip: padding-box;
        border: 0.625rem solid #909498 !important;
      }

      ::-webkit-scrollbar-track {
        background: #767f8d1a !important;
        border-radius: 1rem;
        border: 0.5625rem solid #767f8d1a !important;
      }
    }
  }

  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  font-weight: 600;
  font-size: ${defaultTheme.fontSize["heading-s"]};
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${defaultTheme.colors.grey700};
  border-bottom: 1px solid ${defaultTheme.colors.grey50} !important;

  svg {
    margin-top: 1px;
    cursor: pointer;
  }
`;

export const Senninha = styled.img`
  width: 104px;
  height: 125px;
`;

export const Map = styled.img`
  width: 267px;
  height: 163px;
`;

export const Au = styled.img`
  width: 91px;
  height: 96px;
`;

export const Warning = styled.img`
  width: 103px;
  height: 90px;
`;

export const ModalButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  padding: 0.625rem 0;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${defaultTheme.colors.green600};
  line-height: 1rem !important;
  border: none;
  font-size: ${defaultTheme.fontSize.s};
  font-weight: 600;
  font-size: 13px;
  line-height: 1rem !important;
  letter-spacing: -0.01em;
  color: ${defaultTheme.colors.white};

  &:hover,
  &:focus {
    background-color: ${defaultTheme.colors.green600};
    color: ${defaultTheme.colors.white};
  }

  &:first-child {
    margin-right: 1rem;
    background-color: ${defaultTheme.colors.grey50} !important;
    color: ${defaultTheme.colors.grey700} !important;

    &:hover {
      background-color: ${defaultTheme.colors.grey50} !important;
      color: ${defaultTheme.colors.grey700} !important;
    }
  }
`;

export const ProgressBar = styled.div`
  margin-top: 1.5rem !important;
  background-color: ${defaultTheme.colors.grey50};
  max-width: 16.6875rem;
  height: 0.375rem;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;

  & > div {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${defaultTheme.colors.green700};
    top: 0;
    bottom: 0;
    left: ${({ progress }) => progress - 100}%;
    border-radius: inherit;
    transition: all 0.5s;
  }
`;

export const ProgressText = styled.span`
  margin-top: 0.5rem !important;
  color: ${defaultTheme.colors.grey500} !important;
`;
