import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import Colors from "@colors";

export const ButtonsRelocate = styled.div`
  position: absolute;
  display: flex;
  padding: 24px 0px 0px 24px;

  .ant-btn {
    color: ${defaultTheme.colors.white};
    height: 44px;
    width: 124px;
    border-radius: 8px;
    padding: 13px 16px 13px 16px;
    background: rgba(0, 168, 104, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    border: 1px solid rgba(0, 168, 104, 1);
    margin-right: 12px;
  }

  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    background: rgba(0, 142, 90, 1);
  }
`;

export const RelocateCard = styled.div`
  position: absolute;
  background: white;
  margin: 0.5rem 0rem 0rem 0.5rem;
  padding: 1rem;
  width: 20.9374rem;
  border-radius: 8px;
`;

export const TitleRelocateCard = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(48, 55, 66, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SubtitleRelocateCard = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(118, 127, 141, 1);
  border-bottom: 1px solid rgba(118, 127, 141, 0.1);
  padding-bottom: 1rem;
`;

export const SubtitleTagRelocate = styled.div`
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(118, 127, 141, 1);
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
`;

export const ContentSelect = styled.div`
  margin-top: 0.6rem;
  margin-bottom: 1rem;
`;

export const ProgressRealocate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  .ant-progress-circle .ant-progress-text {
    color: var(--content-neutral-high, #303742);
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: capitalize;
  }
`;

export const DeallocateField = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  span {
    color: var(--content-neutral-high, #303742);
    /* Body Medium/Regular */
    font-family: Sharon Sans;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 1.35938rem */
    letter-spacing: -0.00938rem;
  }

  p {
    color: var(--content-neutral, #767f8d);
    /* Body Small/Regular */
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 1.17813rem */
    letter-spacing: -0.00813rem;
  }

  .ant-switch-checked {
    background-color: rgba(0, 168, 104, 1);
  }

  .ant-switch {
    width: 48px;
    height: 28px;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px - 2px);
  }

  .ant-switch-handle {
    width: 24px;
    height: 24px;
  }

  .ant-switch-handle::before {
    border-radius: 99.32px;
  }
`;
