import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import LineChart from "@components/LineChart";
import LoadingSpinner from "@components/LoadingSpinner";
import { intToReal } from "@helper";
import LogisticService from "@LogisticService";
import Colors from "@colors";

export default function Transacoes(props) {
  const title = "Transações";
  const [transacoes, setTransacoes] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getGraficoTransacoes(props.stonecode).then((response) => {
      if (response && response.data != null) {
        setTransacoes(response.data);
        setLoaded(true);
      }
    });
  }, []);

  const periodos = [];
  const reversalAmountInCents = [];
  const salesAmountInCents = [];
  const reversalQuantity = [];
  const salesQuantity = [];
  const downloadLabels = [
    "Período",
    "Vendas",
    "Quantidade de Vendas",
    "Estornos",
    "Quantidade de Estornos",
  ];
  const downloadData = [];

  transacoes &&
    transacoes.map(
      (item) => (
        periodos.push(item.date),
        salesAmountInCents.push(item.salesAmountInCents),
        salesQuantity.push(item.salesQuantity.toFixed(0)),
        reversalAmountInCents.push(item.reversalAmountInCents),
        reversalQuantity.push(item.reversalQuantity.toFixed(0)),
        downloadData.push([
          `${item.date}`,
          `${intToReal.format(item.salesAmountInCents)}`,
          `${item.salesQuantity.toFixed(0)}`,
          `${intToReal.format(item.reversalAmountInCents)}`,
          `${item.reversalQuantity.toFixed(0)}`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    datalabelsCurrency: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          id: "A",
          stacked: false,
          position: "left",
          ticks: {
            padding: 16,
            callback: function (value) {
              return intToReal.format(value);
            },
          },
        },
        {
          id: "B",
          stacked: false,
          gridLines: false,
          position: "right",
          ticks: {
            padding: 16,
            callback: function (value) {
              return null;
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
          },
          gridLines: false,
        },
      ],
    },
    data: [
      {
        label: "Vendas",
        color: Colors.blue,
        fill: false,
        value: salesAmountInCents,
        hidden: false,
        yAxesID: "A",
      },
      {
        label: "Quantidade de Vendas",
        color: Colors.darkGreen,
        fill: false,
        value: salesQuantity,
        hidden: true,
        yAxesID: "B",
        normalDatalabel: true,
      },
      {
        label: "Estornos",
        color: Colors.green,
        fill: false,
        value: reversalAmountInCents,
        hidden: false,
        yAxesID: "A",
      },
      {
        label: "Quantidade de Estornos",
        color: Colors.gray,
        fill: false,
        value: reversalQuantity,
        hidden: true,
        yAxesID: "B",
        normalDatalabel: true,
      },
    ],
  };

  return (
    <Container
      title={title}
      // info={[
      //     [""]
      // ]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
      grid={"0.6"}
      expandable
      renderClosed
    >
      {loaded ? <LineChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
