import {
  DownloadOutlined,
  InfoCircleOutlined,
  DownOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Popover, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useJsonToCsv } from "react-json-csv";
import Colors from "@colors";
import UpArrow from "@assets/icons/UpArrow";
import Row from "../Row";
import {
  Advice,
  BackButton,
  Card,
  Child,
  ExpandButton,
  HeaderColumn,
  InfoContent,
  Title,
} from "./styles";

const Container = ({
  advice,
  backButton,
  bottomExpandable,
  children,
  customComponent,
  description,
  downloadData,
  downloadLabels,
  expandable,
  grid,
  height,
  hide,
  info,
  legenda,
  margin,
  padding,
  renderClosed,
  rounded,
  title,
  minWidth,
  gridContainer,
  topBox,
}) => {
  const [openChild, setOpenChild] = useState(true);

  const infoContent = [];
  let adviceText = "";

  if (info) {
    info.map((item) => infoContent.push(<InfoContent>{item}</InfoContent>));
  }
  useEffect(() => {
    if (renderClosed) {
      setOpenChild(false);
    }
  }, []);

  if (advice) {
    if (advice === "zoom") {
      adviceText = "Habilite o zoom pressionando a tecla CTRL";
    } else if (advice === "imagem") {
      adviceText = "Clique na imagem para visualizar";
    }
  }

  const { saveAsCsv } = useJsonToCsv();

  const filename = title,
    fields = downloadLabels || [],
    data = downloadData || [];

  const downloadClick = (e) => {
    e.stopPropagation();
    saveAsCsv({ data, fields, filename });
  };

  return (
    <Card
      grid={grid}
      margin={margin}
      gridContainer={gridContainer}
      padding={padding}
      height={height}
      rounded={rounded}
      hidden={hide}
      description={description}
      minWidth={minWidth}
    >
      {title || legenda || downloadData || topBox ? (
        <Row
          margin={margin}
          nonResponsive
          verticalAlign={"center"}
          width={"initial"}
        >
          <HeaderColumn margin={0}>
            {title ? (
              <Title>
                {backButton ? (
                  <Tooltip title="Voltar" placement="top">
                    <BackButton onClick={backButton}>
                      <LeftOutlined style={{ fontSize: 20 }} />
                    </BackButton>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {title}
              </Title>
            ) : (
              <></>
            )}
          </HeaderColumn>
          <HeaderColumn margin={0} topBox={topBox}>
            <Space className="senninha-legenda">
              {customComponent ? customComponent : <></>}
              {downloadData ? (
                <Tooltip title="Download" placement="bottom">
                  <DownloadOutlined
                    style={{ fontSize: 22, color: Colors.deepBlue }}
                    onClick={downloadClick}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
              {info ? (
                <Popover
                  placement="topLeft"
                  title={"Info"}
                  content={infoContent}
                >
                  <InfoCircleOutlined
                    style={{ fontSize: 22, color: Colors.deepBlue }}
                  />
                </Popover>
              ) : (
                <></>
              )}
              {expandable ? (
                <ExpandButton
                  topBox={topBox && topBox}
                  noPaddingLeft={customComponent || downloadData || info}
                  onClick={() => {
                    setOpenChild(!openChild);
                  }}
                  open={openChild}
                  bottom={bottomExpandable}
                >
                  <UpArrow
                    size={20}
                    fill="#767F8D"
                    style={{
                      cursor: "pointer",
                      marginTop: 3,
                      width: "1.5rem",
                      height: "1.5rem",
                      transition: "all 0.5s ease",
                      transform: `rotate(${openChild ? "0.5turn" : "1turn"})`,
                    }}
                  />
                </ExpandButton>
              ) : (
                <></>
              )}
            </Space>
          </HeaderColumn>
        </Row>
      ) : (
        <></>
      )}
      <Child open={openChild} expandable={expandable}>
        {children}
      </Child>
      {advice ? <Advice>{adviceText}</Advice> : <></>}
    </Card>
  );
};
export default Container;
