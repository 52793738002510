import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import {
  AlertTitle,
  ImportantInfo,
  StonecodeList,
  StonecodeTag,
  Valor,
} from "../styles";
import Transacoes from "./Transacoes";
import { Alert } from "antd";

export default function InformacoesPrincipais(props) {
  const loading = <LoadingOutlined spin />;
  const [saldoAtual, setSaldoAtual] = useState("");
  const [emprestimosECredito, setEmprestimosECredito] = useState([]);
  const [travasBancarias, setTravasBancarias] = useState([]);
  const [outrosStonecodes, setOutrosStonecodes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [estadoContrato, setEstadoContrato] = useState("");
  const [ultimoPagamento, setUltimoPagamento] = useState("");

  useEffect(() => {
    setLoaded(false);
    LogisticService.getSaldoAtual(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        setSaldoAtual(response.data.current_balance.messages || " - ");
      } else {
        setSaldoAtual(" - ");
      }
      LogisticService.getEmprestimosECredito(props.stonecode).then(
        (response) => {
          if (response && response.data !== null) {
            setEmprestimosECredito(response.data.loans_and_credit);
          }
        }
      );
      LogisticService.getTravaBancaria(props.stonecode).then((response) => {
        if (response && response.data !== null) {
          setTravasBancarias(response.data.bank_lock.messages);
        }
      });
      LogisticService.getStatusContrato(props.stonecode).then((response) => {
        if (response && response.data !== null) {
          setEstadoContrato(response.data);
        }
      });
      LogisticService.getOutrosStonecodes(props.stonecode).then((response) => {
        let array = [];
        if (response && response.data !== null) {
          array = [...response.data.others_stonecode];
        }
        const index = array.indexOf(props.stonecode);
        if (index > -1) {
          array.splice(index, 1);
        }
        setOutrosStonecodes(array);
        setLoaded(true);
      });
      LogisticService.getUltimoPagamento(props.stonecode).then((response) => {
        if (response && response.data !== null) {
          if (response.data.date !== undefined) {
            setUltimoPagamento(response.data.date.substring(0, 10));
          } else {
            setUltimoPagamento(" - ");
          }
        }
      });
    });
  }, [props.stonecode]);

  const changeStonecode = (item) => props.setStonecode(item);

  return (
    <Container
      title={`Stonecode: ${props.stonecode}`}
      icon={"info-circle"}
      grid={1}
      expandable
    >
      {loaded ? (
        <Box margin={"0 8px 8px"}>
          <ImportantInfo>
            Saldo atual: <Valor>R${saldoAtual ? saldoAtual : loading}</Valor>
          </ImportantInfo>
          <ImportantInfo>
            Ultima Transação:{" "}
            <Valor>{ultimoPagamento ? ultimoPagamento : loading}</Valor>
          </ImportantInfo>
          <Row horizontalAlign={"center"} width={"100%"} padding={"8px"}>
            <Transacoes stonecode={props.stonecode} />
            <Container title={`Alertas`} grid={0.4}>
              <Box margin={"0 8px 8px"}>
                {estadoContrato.message ||
                  (estadoContrato.term_type && (
                    <Box>
                      <AlertTitle>Contrato:</AlertTitle>
                      <Alert
                        message={
                          estadoContrato.message
                            ? estadoContrato.message
                            : estadoContrato.term_type
                        }
                        type="warning"
                        showIcon
                      />
                    </Box>
                  ))}
                {emprestimosECredito && emprestimosECredito.length > 0 && (
                  <Box>
                    <AlertTitle>Empréstimos:</AlertTitle>
                    {emprestimosECredito.map((item) => (
                      <Alert message={item} type="warning" showIcon />
                    ))}
                  </Box>
                )}
                {travasBancarias && travasBancarias.length > 0 && (
                  <Box>
                    <AlertTitle>Travas bancárias:</AlertTitle>
                    {travasBancarias.map((item) => (
                      <Alert message={item} type="warning" showIcon />
                    ))}
                  </Box>
                )}
                {outrosStonecodes && outrosStonecodes.length > 0 && (
                  <Box>
                    <AlertTitle>Stonecodes:</AlertTitle>
                    <StonecodeList>
                      {outrosStonecodes.map((item) => (
                        <StonecodeTag onClick={() => changeStonecode(item)}>
                          {item}
                        </StonecodeTag>
                      ))}
                    </StonecodeList>
                  </Box>
                )}
                {!(
                  estadoContrato.message ||
                  estadoContrato.term_type ||
                  (emprestimosECredito && emprestimosECredito.length > 0) ||
                  (travasBancarias && travasBancarias.length > 0) ||
                  (outrosStonecodes && outrosStonecodes.length > 0)
                ) && <Alert message="Nenhum alerta." type="warning" showIcon />}
              </Box>
            </Container>
          </Row>
        </Box>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
