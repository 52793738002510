import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import MaterialTableComponent from "@components/MaterialTableComponent";
import LogisticService from "@LogisticService";

const AbasDeDetalhamento = (props) => {
  const { TabPane } = Tabs;
  const { nome, polo, ano, mes, tipo, resultado, distrito } = props;

  const [tabOneContent, setTabOneContent] = useState(resultado);
  const [tabTwoContent, setTabTwoContent] = useState(undefined);
  const [tabThreeContent, setTabThreeContent] = useState(undefined);
  const [tabFourContent, setTabFourContent] = useState(undefined);
  const [tabFiveContent, setTabFiveContent] = useState(undefined);
  const [tabSixContent, setTabSixContent] = useState(undefined);
  const [tabSevenContent, setTabSevenContent] = useState(undefined);
  const [tabEightContent, setTabEightContent] = useState(undefined);

  useEffect(() => {
    setTabOneContent(props.resultado);
  }, [props]);

  function formatDate(date, hours) {
    if (date !== undefined) {
      var datePart = date.slice(0, 10);

      if (hours === undefined) {
        return (
          datePart.split("-")[2] +
          "/" +
          datePart.split("-")[1] +
          "/" +
          datePart.split("-")[0]
        );
      }

      return (
        datePart.split("-")[2] +
        "/" +
        datePart.split("-")[1] +
        "/" +
        datePart.split("-")[0] +
        " " +
        date.slice(11)
      );
    } else {
      return "--/--/--";
    }
  }

  function callback(key) {
    if (key === "1" && tabOneContent === undefined) {
      setTabOneContent([]);
    }
    if (key === "2" && tabTwoContent === undefined) {
      LogisticService.getOrdensAngels(ano, mes, nome, polo).then((response) => {
        setTabTwoContent(
          response.data.data.map((order) => ({
            order_number: order.order_number,
            customer: order.customer,
            customer_name: order.customer_name,
            city: order.city,
            technician_name: order.technician_name,
            consider_in_sla_account: order.consider_in_sla_account,
            consider_in_tma_account: order.consider_in_tma_account,
            solved_with_sla_on_time: order.solved_with_sla_on_time,
            solved_with_sla_customer_on_time:
              order.solved_with_sla_customer_on_time,
            consider_in_dzero_account: order.consider_in_dzero_account,
            solved_in_dzero: order.solved_in_dzero,
            solved_with_anticipation: order.solved_with_anticipation,
            redirect_polo: order.redirect_polo,
            service_group: order.service_group,
            status: order.status,
            cancellation_reason: order.cancellation_reason,
            opening_date: formatDate(order.opening_date, true),
            arrival_date: formatDate(order.arrival_date, true),
            deadline_date: formatDate(order.deadline_date, true),
            customer_deadline_date: formatDate(
              order.customer_deadline_date,
              true
            ),
            visitReason1: order.visitReason1,
            contractor: order.contractor,
          }))
        );
      });
    }
    if (key === "3" && tabThreeContent === undefined) {
      LogisticService.getPortalAngels(ano, mes, nome, polo).then((response) => {
        setTabThreeContent(
          response.data.data.map((customer) => ({
            customer: customer.customer,
            customer_name: customer.customer_name,
            neighborhood: customer.neighborhood,
            with_access: customer.with_access ? "Sim" : "Não",
          }))
        );
      });
    }
    if (key === "5" && tabFiveContent === undefined) {
      LogisticService.getTriagensDistrito(ano, mes, distrito).then(
        (response) => {
          setTabFiveContent(
            response.data.data.map((terminal) => {
              const date = terminal.screening_date.slice(0, 10).split("-");
              return {
                serial: terminal.serial,
                status_before: terminal.status_before,
                status_after: terminal.status_after,
                screening_date: date[2] + "/" + date[1] + "/" + date[0],
                hub: terminal.provider,
                week_number: terminal.week_number,
              };
            })
          );
        }
      );
    }
    if (key === "6" && tabSixContent === undefined) {
      LogisticService.getTerminaisRemovidosDistrito(ano, mes, distrito).then(
        (response) => {
          setTabSixContent(
            response.data.data.map((terminal) => ({
              serial_uninstalled: terminal.serial_uninstalled,
              status_before: terminal.status_before,
              service_order_number: terminal.service_order_number,
              service_group: terminal.service_group,
              removal_date: formatDate(terminal.removal_date),
              week_number: terminal.week_number,
              provider: terminal.provider,
              contractor: terminal.contractor,
            }))
          );
        }
      );
    }

    if (key === "7" && tabSevenContent === undefined) {
      LogisticService.getNPSAngels(ano, mes, nome).then((response) => {
        setTabSevenContent(
          response.data.data.map((os) => ({
            angel: os.angel,
            hub: os.hub,
            district: os.district,
            rating: os.rating,
            cluster_rating: os.cluster_rating,
            answer_date: formatDate(os.answer_date),
          }))
        );
      });
    }
  }

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Resumo" key="1">
        <div style={{ padding: "0px 100px" }}>
          <MaterialTableComponent
            columns={[
              { title: "Pilar", field: "name" },
              { title: "Atingimento", field: "score" },
              { title: "Limite Máximo", field: "limit" },
            ]}
            options={{
              showTitle: false,
              search: false,
              paging: false,
              toolbar: false,
            }}
            data={
              !tabOneContent
                ? []
                : Object.values(tabOneContent).map((p) => {
                    return {
                      name: p.nome,
                      score: p.atingimento + "%", //resultado[p],
                      limit:
                        p.indicadores
                          .filter((i) => i.tipo !== "alavanca")
                          .map((i) => parseFloat(i.peso))
                          .reduce((a, b) => a + b, 0)
                          .toFixed(1) + "%",
                      indicadores: p.indicadores,
                    };
                  })
            }
            detailPanel={(rowData) => {
              return (
                <div style={{ padding: "30px 70px" }}>
                  <MaterialTableComponent
                    columns={[
                      {
                        title: "Indicador",
                        field: "indicador",
                      },
                      {
                        title: "Resultado",
                        field: "resultado",
                      },
                      {
                        title: "Atingimento Pós-Régua",
                        field: "atingimento_regua",
                      },
                      {
                        title: "Peso",
                        field: "peso",
                      },
                      {
                        title: "Impacto no Pilar",
                        field: "contribuicao_total",
                      },
                    ]}
                    data={rowData.indicadores.map((indicador) => ({
                      indicador: indicador.nome,
                      resultado: indicador.resultado,
                      atingimento_regua: indicador.atingimento + "%",
                      peso:
                        indicador.tipo === "alavanca"
                          ? indicador.atingimento >= 0
                            ? "Bônus"
                            : "Ônus"
                          : indicador.peso + "%",
                      contribuicao_total:
                        indicador.tipo === "alavanca"
                          ? indicador.atingimento + "%"
                          : (indicador.atingimento / 100) * indicador.peso +
                            "%",
                    }))}
                    addOptions={{
                      filtering: false,
                      pageSize: 5,
                      exportButton: false,
                      search: false,
                      paging: false,
                      toolbar: false,
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
      </TabPane>
      <TabPane tab="Chamados Atendidos" key="2">
        <MaterialTableComponent
          columns={[
            {
              title: "Número da OS",
              field: "order_number",
              type: "numeric",
            },
            {
              title: "Stonecode",
              field: "customer",
            },
            {
              title: "Nome do Cliente",
              field: "customer_name",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Contratante",
              field: "contractor",
            },
            {
              title: "Cidade",
              field: "city",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Angel",
              field: "technician_name",
            },
            {
              title: "Conta pro SLA",
              field: "consider_in_sla_account",
              type: "boolean",
            },
            {
              title: "Conta pro TMA",
              field: "consider_in_tma_account",
              type: "boolean",
            },
            {
              title: "SLA no Prazo",
              field: "solved_with_sla_on_time",
              type: "boolean",
            },
            {
              title: "SLA Cliente no Prazo",
              field: "solved_with_sla_customer_on_time",
              type: "boolean",
            },
            {
              title: "Conta pro D0",
              field: "consider_in_dzero_account",
              type: "boolean",
            },
            {
              title: "Resolvida em D0",
              field: "solved_in_dzero",
              type: "boolean",
            },
            {
              title: "Chamado Antecipado",
              field: "solved_with_anticipation",
              type: "boolean",
            },
            {
              title: "Grupo de Serviço",
              field: "service_group",
            },
            {
              title: "Status",
              field: "status",
            },
            {
              title: "Motivo da Primeira Visita",
              field: "visitReason1",
            },
            {
              title: "Motivo de Cancelamento",
              field: "cancellation_reason",
            },
            {
              title: "Data de Abertura",
              field: "opening_date",
            },
            {
              title: "Data de Atendimento",
              field: "arrival_date",
            },
            {
              title: "Data Limite",
              field: "deadline_date",
            },
            {
              title: "Data Limite Cliente",
              field: "customer_deadline_date",
            },
            {
              title: "Redirect",
              field: "redirect_polo",
              type: "boolean",
            },
          ]}
          data={tabTwoContent}
          title="Chamados Atendidos"
          isLoading={!tabTwoContent}
        />
      </TabPane>
      <TabPane tab="Portal" key="3">
        <MaterialTableComponent
          columns={[
            {
              title: "Stonecode",
              field: "customer",
            },
            {
              title: "Nome do Cliente",
              field: "customer_name",
            },
            {
              title: "Bairro",
              field: "neighborhood",
            },
            {
              title: "Acessou o Portal",
              field: "with_access",
            },
          ]}
          data={tabThreeContent}
          isLoading={!tabThreeContent}
          title="Acessos ao portal"
        />
      </TabPane>
      <TabPane tab="Terminais Triados" key="5">
        <MaterialTableComponent
          columns={[
            {
              title: "Serial",
              field: "serial",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Status Antes",
              field: "status_before",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Status Depois",
              field: "status_after",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Polo",
              field: "hub",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Data da Triagem",
              field: "screening_date",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Semana",
              field: "week_number",
              type: "numeric",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabFiveContent}
          isLoading={!tabFiveContent}
          title="Terminais Triados"
        />
      </TabPane>
      <TabPane tab="Terminais Retirados" key="6">
        <MaterialTableComponent
          columns={[
            {
              title: "Número da OS",
              field: "service_order_number",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Contratante",
              field: "contractor",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Grupo de Serviço",
              field: "service_group",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Serial",
              field: "serial_uninstalled",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo",
              field: "provider",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Data da Retirada",
              field: "removal_date",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Semana",
              field: "week_number",
              type: "numeric",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabSixContent}
          isLoading={!tabSixContent}
          title="Terminais Retirados"
        />
      </TabPane>
      <TabPane tab="NPS" key="7">
        <MaterialTableComponent
          columns={[
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo",
              field: "hub",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Nota",
              field: "rating",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Classificação",
              field: "cluster_rating",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Data da resposta",
              field: "answer_date",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
          ]}
          data={tabSevenContent}
          isLoading={!tabSevenContent}
          title="NPS"
        />
      </TabPane>
    </Tabs>
  );
};

export default AbasDeDetalhamento;
