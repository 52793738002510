import React from "react";
import PropTypes from "prop-types";

import "./styles.css";

const Container = ({ bordered = false, children }) => {
  const className = `card-container ${bordered ? "bordered" : ""}`;

  return <div className={className}>{children}</div>;
};

const Header = ({ title, smallPadding = false, children }) => {
  const className = `card-header ${smallPadding ? "small-padding" : ""}`;

  return (
    <header className={className}>
      <h2 className="card-header-title">{title}</h2>
      {children}
    </header>
  );
};

const Content = ({
  direction = "column",
  smallPadding = false,
  noPadding = false,
  children,
}) => {
  let className;
  className = `card-content ${direction} ${
    smallPadding ? "small-padding" : ""
  }`;
  className = `card-content ${direction} ${
    noPadding ? "no-padding" : className
  }`;

  return <div className={className}>{children}</div>;
};

Content.defaultProps = {
  direction: "column",
};

Content.propTypes = {
  direction: PropTypes.oneOf(["column", "row"]),
};

const Card = {
  Container,
  Header,
  Content,
};

export default Card;
