import { useEffect, useRef, useState } from "react";
import { Drawer } from "@stonelog/stonelog-design-system";
import FilterDrawer from "./FilterDrawer";
import LegendDrawer from "./LegendDrawer";
import SearchDrawer from "./SearchDrawer";
import { useSelector, useDispatch } from "react-redux";
import { pinsSelector, setBuildFilters } from "../../../../../../features/senninha/pinsSlice";

import * as S from "./styles";


const LeftMenuDrawers = ({
    open,
    setOpen,
    drawerSelected
}) => {

    const titleToggler = {
        'legend': 'Legenda',
        'filter': 'Filtro por',
        'search': 'Buscar por'
    }

    const { pins, leftOpenDrawer } = useSelector(pinsSelector);
    const dispatch = useDispatch();


    useEffect(() => {
        if (pins) {
            const uniqueAngels = [];
            pins.forEach(pin => {
                if (!uniqueAngels.includes(pin.angel)) {
                    uniqueAngels.push(pin.angel);
                }
            });

            // Remove "Hunter TON" and "Hunter TON (Com ineficiência)" from uniqueAngels array
            const filteredAngels = uniqueAngels.filter(
                angel => angel !== "Hunter TON" &&
                    angel !== "Hunter TON (Com ineficiência)" &&
                    angel !== "Hunter STONE" &&
                    angel !== "Hunter STONE (Com ineficiência)"
            );

            if (leftOpenDrawer === false) {
                dispatch(setBuildFilters(filteredAngels.map(angel => ({ field: 'angel', value: angel, drawer: 'legenda' }))));
            }
        }
    }, [pins]);

    return (

        <S.InsideDrawerContent>
            <Drawer.Root
                title={titleToggler[drawerSelected]}
                placement="left"
                open={open}
                getContainer={false}
                onClose={() => setOpen(false)}
                width='398px'
            >
                {
                    drawerSelected === 'legend' && (
                        <LegendDrawer />
                    )
                }
                {
                    drawerSelected === 'filter' && (
                        <FilterDrawer />
                    )
                }
                {
                    drawerSelected === 'search' && (
                        <SearchDrawer />
                    )
                }
            </Drawer.Root>
        </S.InsideDrawerContent>
    )
}

export default LeftMenuDrawers;
