import styled from "styled-components";

export const CustomDate = styled.div`
  .ant-picker {
    border: 1px solid rgba(166, 174, 186, 1);
    transition: border 0.3s, box-shadow 0.3s;
    border-radius: 50px;
    height: 44px;
    width: 150.4px;
    left: 0px;
    top: 0px;
    border-radius: 8px;
    cursor: pointer;
  }
`;
