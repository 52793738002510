import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { intervalToDuration } from "date-fns";
import { formatedOsTypesName } from "./../../helper";

import * as S from "./styles";

const AttendanceTooltip = ({
  osType,
  startTime,
  endTime,
  children,
  ...props
}) => {
  const [time, setTime] = useState("");

  useEffect(() => {
    setTime(calculateTimeDifference());
  }, []);

  const calculateTimeDifference = () => {
    const start = startTime.split(":");
    const end = endTime.split(":");
    const diff = intervalToDuration({
      start: new Date(1970, 0, 1, start[0], start[1]),
      end: new Date(1970, 0, 1, end[0], end[1]),
    });
    const hours = diff.hours !== 0 ? diff.hours + "h" : "";
    const minutes =
      diff.minutes !== 0 ? (hours ? " e " : "") + diff.minutes + "m" : "";
    return hours + minutes;
  };

  return (
    <Tooltip
      title={() => (
        <>
          <S.Title>Detalhes do atendimento</S.Title>
          <S.Row>
            <div>Tempo</div>
            <span>{time}</span>
          </S.Row>
          <S.Row>
            <div>Tipo de OS</div>
            <S.OSsArea>
              <div>
                <S.ColorBadge type={osType} />
                {formatedOsTypesName[osType]}
              </div>
            </S.OSsArea>
          </S.Row>
        </>
      )}
      placement="top"
      overlayClassName="ginga-tooltip ginga-tooltip-cockpit ginga-tooltip-top"
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default AttendanceTooltip;
