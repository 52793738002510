import { GenIcon } from "react-icons";

export function ClosedEye(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 21 20",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M4.21782 6.34223C4.0387 5.91828 3.54981 5.71981 3.12586 5.89893C2.70191 6.07805 2.50344 6.56693 2.68256 6.99088C3.27156 8.38497 4.20317 9.59953 5.37208 10.5287L4.39515 12.2208C4.16503 12.6194 4.3016 13.129 4.70017 13.3592C5.09875 13.5893 5.60841 13.4527 5.83853 13.0541L6.76949 11.4417C7.78542 11.9778 8.91682 12.3246 10.1168 12.4351V13.7499C10.1168 14.2101 10.4899 14.5832 10.9502 14.5832C11.4104 14.5832 11.7835 14.2101 11.7835 13.7499V12.4351C13.0464 12.3188 14.2333 11.9408 15.2896 11.3558L16.2702 13.0541C16.5003 13.4527 17.0099 13.5893 17.4085 13.3592C17.8071 13.129 17.9436 12.6194 17.7135 12.2208L16.6719 10.4166L16.6701 10.4136C17.7726 9.50016 18.6528 8.32811 19.2178 6.99088C19.3969 6.56693 19.1985 6.07805 18.7745 5.89893C18.3506 5.71981 17.8617 5.91828 17.6826 6.34223C16.5735 8.96714 13.9757 10.8066 10.9502 10.8066C7.92466 10.8066 5.32686 8.96714 4.21782 6.34223Z",
        },
      },
    ],
  })(props);
}
