import React, { useEffect, useState } from "react";
import { Button, Drawer } from "antd";
import CloseIcon from "@assets/icons/CloseIcon";
import { FilterIcon } from "@assets/icons/FilterIcon";
import { CloseCircleIcon } from "@assets/icons/CloseCircleIcon";
import { TagCustom } from "@components/TagCustom";
import { DatePickerCustom } from "@components/DatePickerCustom";
import { FooterButtons } from "@components/FooterButtons";
import TrashIcon from "@assets/icons/TrashIcon";
import moment from "moment";
import * as S from "./styles";

export const Filters = (props) => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [activeTags, setActiveTags] = useState([]);
  const [initialDate, setInitialDate] = useState("");
  const [initialClientDate, setInitialClientDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [finalClientDate, setFinalClientDate] = useState("");
  const [fixedPins, setFixedPins] = useState({});
  const [markers, setMarkers] = useState(null);

  const [filtersAttribute, setFiltersAttribute] = useState({
    servico: {
      campo: "servico",
      verboso: "Serviço",
      filtros: [], // {key: 'servico', value: 'TROCA'}
    },
    contratante: {
      campo: "contratante",
      verboso: "Contratante",
      filtros: [],
    },
    modelo_saida: {
      campo: "modelo_saida",
      verboso: "Tecnologia de Saída",
      filtros: [],
    },
    modelo_entrada: {
      campo: "modelo_entrada",
      verboso: "Tecnologia de Entrada",
      filtros: [],
    },
  });

  useEffect(() => {
    construirFiltros();
  }, []);

  useEffect(() => {
    if (props.unfiltered) {
      aplicarFiltros(props.pins);
      props.setUnfiltered(false);
    } else setMarkers(props.pins);
  }, [props.pins]);

  const { addDays, parseISO, format } = require("date-fns");

  function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = parseISO(startDate);

    while (format(currentDate, "yyyy-MM-dd") <= endDate) {
      dates.push(format(currentDate, "yyyy-MM-dd"));
      currentDate = addDays(currentDate, 1);
    }

    return dates;
  }

  const onCloseDrawer = () => {
    setDrawerVisibility(false);
  };

  const clearAndCloseDrawer = () => {
    setDrawerVisibility(false);
    if (Object.values(fixedPins).length > 0) {
      //Essa parte serve para limpar os pins
      props.pins.map((pin) => (pin.filtrado = true));
      props.setPins(fixedPins);
      construirFiltros();
    }
  };

  function onChangeInitialDate(date, dateString) {
    if (finalDate.length > 0) {
      const dates = getDatesBetween(dateString, finalDate);
      const index = activeTags.findIndex((tag) => tag.key === "deadline_date");

      if (index !== -1) {
        activeTags.splice(index, 1);
      }
      activeTags.push({
        key: "deadline_date",
        value: `Data limite (${dateString} à ${finalDate})`,
        arrayDates: dates,
      });
      setInitialDate(dateString);
    } else {
      setInitialDate(dateString);
    }
  }

  function onChangeFinalDate(date, dateString) {
    setFinalDate(dateString);
    if (dateString !== "" || initialDate !== "") {
      const dates = getDatesBetween(initialDate, dateString);
      const index = activeTags.findIndex((tag) => tag.key === "deadline_date");

      if (index !== -1) {
        activeTags.splice(index, 1);
      }
      activeTags.push({
        key: "deadline_date",
        value: `Data limite (${initialDate} à ${dateString})`,
        arrayDates: dates,
      });
    } else {
      setFinalDate("");
      setInitialDate("");
    }
  }

  function onChangeInitialClientDate(date, dateString) {
    if (finalClientDate.length > 0) {
      const dates = getDatesBetween(dateString, finalClientDate);
      const index = activeTags.findIndex(
        (tag) => tag.key === "customer_deadline_date"
      );

      if (index !== -1) {
        activeTags.splice(index, 1);
      }
      activeTags.push({
        key: "customer_deadline_date",
        value: `Data limite do cliente (${dateString} à ${finalClientDate})`,
        arrayClientDates: dates,
      });
      setInitialClientDate(dateString);
    } else {
      setInitialClientDate(dateString);
    }
  }

  function onChangeFinalClientDate(date, dateString) {
    setFinalClientDate(dateString);
    if (dateString !== "" || initialClientDate !== "") {
      const dates = getDatesBetween(initialClientDate, dateString);
      const index = activeTags.findIndex(
        (tag) => tag.key === "customer_deadline_date"
      );

      if (index !== -1) {
        activeTags.splice(index, 1);
      }
      activeTags.push({
        key: "customer_deadline_date",
        value: `Data limite do cliente (${initialClientDate} à ${dateString})`,
        arrayClientDates: dates,
      });
    } else {
      setFinalClientDate("");
      setInitialClientDate("");
    }
  }

  const disabledDate = (current) => {
    return current && current < moment(initialDate, "YYYY-MM-DD");
  };

  const disabledClientDate = (current) => {
    return current && current < moment(initialClientDate, "YYYY-MM-DD");
  };

  const aplicarFiltros = (markers) => {
    if (markers) {
      setFixedPins(markers);
      markers.map((marker) => {
        // setando filtrado false, para que o filtro fique correto ao clicar varias vezes no botao de filtrar
        marker.filtrado = false;
        marker.oss.map((os) => {
          let list_keys_to_filter = [];
          let filter_unique_keys = [];
          activeTags.map((activeTag) => {
            //Aqui eu valido se eu já tenho um "key" dentro do array evitando que haja valores dobrados ex: ['servico', 'servico']
            if (!list_keys_to_filter.includes(activeTag.key)) {
              list_keys_to_filter.push(activeTag.key);
            }
            if (activeTag.value === os[activeTag.key]) {
              filter_unique_keys.push(activeTag.key);
            }

            if (activeTag.key === "deadline_date") {
              activeTag.arrayDates.forEach((date) => {
                if (date === os["deadline_date"]) {
                  filter_unique_keys.push(activeTag.key);
                }
              });
            }
            if (activeTag.key === "customer_deadline_date") {
              activeTag.arrayClientDates.forEach((date) => {
                if (date === os["customer_deadline_date"]) {
                  filter_unique_keys.push(activeTag.key);
                }
              });
            }
          });
          // Aqui é onde os pins são orquestrados, se ele possuir o mesmo numero de filtros ele ira ser true
          if (list_keys_to_filter.length === filter_unique_keys.length) {
            marker.filtrado = true;
          }
        });
      });
      props.setPins([...markers]);
      setDrawerVisibility(false);
    }
  };

  const construirFiltros = () => {
    setFinalDate("");
    setInitialDate("");
    setFinalClientDate("");
    setInitialClientDate("");
    setActiveTags([]);
    props.pins.map((pin) => {
      pin.oss &&
        pin.oss.map((os) => {
          for (const key in filtersAttribute) {
            // O uso do metodo Some é para ele nao add tags se ela ja existirem
            if (
              os[key] &&
              !filtersAttribute[key]["filtros"].some(
                (elem) => elem.value === os[key]
              )
            ) {
              filtersAttribute[key]["filtros"].push({
                key: key,
                value: os[key],
              });
            }
          }
        });
    });
    setFiltersAttribute({ ...filtersAttribute });
    return true;
  };

  return (
    <>
      <Drawer
        title={"Filtros"}
        open={drawerVisibility}
        visible={drawerVisibility}
        placement={"left"}
        closeIcon={<CloseIcon size={20} style={{ marginTop: "-3px" }} />}
        onClose={onCloseDrawer}
        width={"411px"}
        {...(props.className && { className: props.className })}
      >
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          <S.AllFilterDrawerContent>
            <S.ContentDrawer>
              <S.SubtitleFilterDrawer>
                Selecione os filtros que deseja aplicar para exibição dos pins.
              </S.SubtitleFilterDrawer>

              <S.TitleFilterDrawer firstTitleFilter>
                Filtros ativos
                <TrashIcon
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    construirFiltros();
                  }}
                  title="Remover todos os filtros"
                />
              </S.TitleFilterDrawer>
              <TagCustom
                setTags={setActiveTags}
                tags={activeTags}
                closeIcon={<CloseCircleIcon size={16} />}
                closable
                activeTags={activeTags}
                onClose={(tag) => {
                  const idx = activeTags.findIndex(
                    (obj) => obj.key === tag.key && obj.value === tag.value
                  );
                  if (idx !== -1) {
                    activeTags.splice(idx, 1);
                  }
                  setActiveTags([...activeTags]);
                  if (tag.key === "deadline_date") {
                    setFinalDate("");
                    setInitialDate("");
                  }
                  if (tag.key === "customer_deadline_date") {
                    setFinalClientDate("");
                    setInitialClientDate("");
                  }
                }}
              />
              <S.TitleFilterDrawer>Data limite</S.TitleFilterDrawer>
              <div style={{ display: "flex" }}>
                <DatePickerCustom
                  label={"Início"}
                  placeholder={"AAAA/MM/DD"}
                  format={"YYYY-MM-DD"}
                  onChange={onChangeInitialDate}
                  value={initialDate ? moment(initialDate, "YYYY-MM-DD") : null}
                />
                <DatePickerCustom
                  label={"Final"}
                  placeholder={"AAAA/MM/DD"}
                  format={"YYYY-MM-DD"}
                  onChange={onChangeFinalDate}
                  disabled={!initialDate.length}
                  disabledDate={disabledDate}
                  value={finalDate ? moment(finalDate, "YYYY-MM-DD") : null}
                />
              </div>
              <S.TitleFilterDrawer>Data limite do cliente</S.TitleFilterDrawer>
              <div style={{ display: "flex" }}>
                <DatePickerCustom
                  label={"Início"}
                  placeholder={"AAAA/MM/DD"}
                  format={"YYYY-MM-DD"}
                  onChange={onChangeInitialClientDate}
                  value={
                    initialClientDate
                      ? moment(initialClientDate, "YYYY-MM-DD")
                      : null
                  }
                />
                <DatePickerCustom
                  label={"Final"}
                  placeholder={"AAAA/MM/DD"}
                  format={"YYYY-MM-DD"}
                  onChange={onChangeFinalClientDate}
                  disabled={!initialClientDate.length}
                  disabledDate={disabledClientDate}
                  value={
                    finalClientDate
                      ? moment(finalClientDate, "YYYY-MM-DD")
                      : null
                  }
                />
              </div>
              <S.TitleFilterDrawer>Serviço</S.TitleFilterDrawer>
              <div>
                <TagCustom
                  tags={filtersAttribute.servico.filtros}
                  activeTags={activeTags}
                  onClick={(tag) => {
                    const idx = activeTags.findIndex(
                      (obj) => obj.key === tag.key && obj.value === tag.value
                    );

                    if (idx === -1) {
                      setActiveTags([
                        ...activeTags,
                        ...[
                          {
                            key: filtersAttribute.servico.campo,
                            value: tag.value,
                          },
                        ],
                      ]);
                    }
                  }}
                  clickable
                />
              </div>
              <S.TitleFilterDrawer>Contratante</S.TitleFilterDrawer>
              <div>
                <TagCustom
                  tags={filtersAttribute.contratante.filtros}
                  activeTags={activeTags}
                  onClick={(tag) => {
                    const idx = activeTags.findIndex(
                      (obj) => obj.key === tag.key && obj.value === tag.value
                    );

                    if (idx === -1) {
                      setActiveTags([
                        ...activeTags,
                        ...[
                          {
                            key: filtersAttribute.contratante.campo,
                            value: tag.value,
                          },
                        ],
                      ]);
                    }
                  }}
                  clickable
                />
              </div>

              <S.TitleFilterDrawer>Tecnologia de saída</S.TitleFilterDrawer>
              <div>
                <TagCustom
                  tags={filtersAttribute.modelo_saida.filtros}
                  activeTags={activeTags}
                  onClick={(tag) => {
                    const idx = activeTags.findIndex(
                      (obj) => obj.key === tag.key && obj.value === tag.value
                    );

                    if (idx === -1) {
                      setActiveTags([
                        ...activeTags,
                        ...[
                          {
                            key: filtersAttribute.modelo_saida.campo,
                            value: tag.value,
                          },
                        ],
                      ]);
                    }
                  }}
                  clickable
                />
              </div>
              <S.TitleFilterDrawer>Tecnologia de entrada</S.TitleFilterDrawer>
              <div>
                <TagCustom
                  tags={filtersAttribute.modelo_entrada.filtros}
                  activeTags={activeTags}
                  onClick={(tag) => {
                    const idx = activeTags.findIndex(
                      (obj) => obj.key === tag.key && obj.value === tag.value
                    );

                    if (idx === -1) {
                      setActiveTags([
                        ...activeTags,
                        ...[
                          {
                            key: filtersAttribute.modelo_entrada.campo,
                            value: tag.value,
                          },
                        ],
                      ]);
                    }
                  }}
                  clickable
                />
              </div>
            </S.ContentDrawer>
            <S.BottomContentDrawer>
              <FooterButtons
                nameFirstButton={"Filtrar"}
                nameSecondButton={"Limpar filtros"}
                onClickFirstButton={() => aplicarFiltros(props.pins)}
                onClickSecondButton={() => clearAndCloseDrawer()}
              />
            </S.BottomContentDrawer>
          </S.AllFilterDrawerContent>
        </div>
      </Drawer>
      <S.FilterButton>
        <Button
          icon={<FilterIcon size={20} />}
          onClick={() => {
            setDrawerVisibility(!drawerVisibility);
          }}
        >
          Filtrar
        </Button>
      </S.FilterButton>
    </>
  );
};
