import React, { useState } from "react";
import UploadIcon from "@assets/icons/UploadIcon";
import TrashIcon from "@assets/icons/TrashIcon";
import CheckIcon from "@assets/icons/CheckIcon";
import * as S from "./styles";

export const UploadContainer = (props) => {
  const [fileList, setFileList] = useState([]);

  const acceptedFileType = ["image/png", "image/jpeg"];

  const onChange = ({ fileList: newFileList }) => {
    props.setImages(newFileList);
    setFileList(newFileList);
  };

  const dummyRequest = ({ onSuccess, onError, file }) => {
    setTimeout(() => {
      if (acceptedFileType.includes(file.type)) {
        onSuccess("ok");
      } else {
        onError("error");
      }
    }, 0);
  };

  return (
    <S.AllUploadContainer>
      <S.UploadContainerTop>
        <S.UploadContainerText>
          <S.UploadTitle>Defeito identificado</S.UploadTitle>
          <S.UploadSubTitle>{props.defectName}</S.UploadSubTitle>
        </S.UploadContainerText>
        {fileList.length === 0 ? (
          <TrashIcon
            size={20}
            color="#000000"
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={() => {
              props.onClick();
            }}
          />
        ) : (
          <CheckIcon
            size={20}
            color="#008E5A"
            style={{ marginRight: "12px" }}
          />
        )}
      </S.UploadContainerTop>

      <S.UploadContainerBottom>
        <S.UploadContainer
          listType="picture"
          defaultFileList={[...props.images]}
          onChange={onChange}
          maxCount={props.maxCount ? props.maxCount : 1}
          customRequest={dummyRequest}
          showUploadList={{
            removeIcon: (
              <TrashIcon
                size={20}
                color="#000000"
                style={{ marginRight: "12px", cursor: "pointer" }}
              />
            ),
          }}
          className="ginga-upload"
        >
          {fileList.length === props.maxCount ? (
            <></>
          ) : (
            <S.ButtonUpload type="button">
              <UploadIcon
                size={15}
                color="#000000"
                style={{ marginRight: "9.33px" }}
              />
              {props.nameUploadButton ? props.nameUploadButton : "Anexar"}
            </S.ButtonUpload>
          )}
        </S.UploadContainer>
      </S.UploadContainerBottom>
    </S.AllUploadContainer>
  );
};
