import styled from "styled-components";
import Colors from "@colors";

export const Anchor = styled.div`
  padding: 8px 24px;
  background-color: ${(props) =>
    props.active ? Colors.textGreen : Colors.lightGreen};
  border: 2px solid ${Colors.textGreen};
  border-radius: 32px;
  margin: 0 4px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  letter-spacing: 0.8px;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.textGreen};
  }
  span {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  @media (max-width: 768px) {
    padding: 8px;
    span {
      margin: 0;
    }
  }
`;

export const AnchorBox = styled.div`
  display: flex;
  margin: 0 4vw;
`;

export const Box = styled.div`
  width: 100%;
  height: ${(props) => (props.search ? "48px" : "0px")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

export const Content = styled.div`
  /* margin: ${(props) =>
    props.search
      ? props.fullScreen
        ? "112px 0 0"
        : "112px 0 64px"
      : "64px 0"};
  padding: ${(props) =>
    props.fullScreen ? "0 0" : "0 4vw"};
  height: ${(props) =>
    props.fullScreen ? "calc(100vh - 160px)" : "auto"}; */
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
`;

export const PageBox = styled.div`
  width: 100%;
  display: grid;
  flex-direction: column;
  flex: ${(props) => props.flex || 1};
  height: ${(props) => (props.height ? props.height : "auto")};
`;

export const PageHeader = styled.div`
  position: ${(props) => (props.nonFixed ? "absolute" : "")};
  z-index: 999;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.lightGreen};
`;

export const Title = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: ${Colors.textGreen};
  text-transform: uppercase;
  cursor: default;
  position: absolute;
  left: 0;
  height: 100%;
  margin-left: 4vw;
`;

export const DownloadAllButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 100%;
  margin-right: 4vw;
  svg {
    transition: 0.2s;
    fill: ${Colors.light};
  }
  &:hover {
    svg {
      fill: ${Colors.textGreen};
    }
  }
`;

export const ModalButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 100%;
  margin-right: 4vw;
  svg {
    transition: 0.2s;
    fill: ${Colors.light};
  }
`;

export const SearchBox = styled.div`
  background: ${Colors.textGreen};
  height: 48px;
  width: 100%;
  padding: 0 4vw;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const AnchorTree = styled.div`
  position: relative;
  user-select: none;
  outline: 0;
`;

export const AnchorTreeHeader = styled(Anchor)`
  cursor: default;
  @media (max-width: 768px) {
    padding-right: 16px;
  }
`;

export const Tree = styled.div`
  max-height: ${(props) => (props.open ? "auto" : 0)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  overflow: hidden;
  position: absolute;
  z-index: 1000;
  background: white;
  left: 0;
  width: calc(100% - 8px);
  margin: 4px;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 20%);
  @media (max-width: 768px) {
    width: auto;
    right: 0;
    left: unset;
  }
`;

export const TreeItem = styled.div`
  color: ${Colors.deepBlue};
  font-weight: bold;
  font-size: 14px;
  padding: 8px;
  text-align: center;
  &:hover {
    color: ${Colors.lightGreen};
  }
`;
