import Colors from "@colors";
import mapColors from "@mapColors";

export const handleGo2Cockpit = (pins, angels, riskAreas, pole, region, district, history) => {
  if (pins.length) {
      // seninha legacy code
      let latMax = pins[0].lat;
      let latMin = pins[0].lat;
      let lngMax = pins[0].lng;
      let lngMin = pins[0].lng;
      let indexCor = 1;
      let cor, icon;
      let cores = {}
      const noAngel = [
          `Não alocadas - Vencidas`,
          `Não alocadas - Vencem hoje`,
          `Não alocadas - Vencimento futuro`,
          `COORDENADAS ZERADAS`,
          `Baixadas`,
          `Área de Risco`,
          `Permitido Moto`,
          `Proibido Carro`,
      ]

      let sumLat = 0,
      sumLng = 0,
      count = 0;

      pins.map((item) => {
          if (item.lat !== 0 && item.lng !== 0) {
              sumLat += item.lat;
              sumLng += item.lng;
              count++;
          }
      });
  
      const mediaLat = sumLat / count,
          mediaLng = sumLng / count;  

      const escolheCor = (id) => {
          const length = Object.keys(mapColors).length;
          let index = id;
          while (index > length) {
              index -= length;
          }
          return mapColors[index].replace("#", "%23");
      };

      const allPins = pins.reduce((data, item, i) => {
          let isHunter = false;
          let latitude, longitude;

          const coordenadasZeradas = item.lat === 0 || item.lng === 0;
          const isNotAngel = noAngel.includes(item.angel);

          if (coordenadasZeradas) {
              latitude = mediaLat;
              longitude = mediaLng;
          } else {
              latitude = item.lat;
              longitude = item.lng;
              if (latitude > latMax) latMax = latitude;
              if (latitude < latMin) latMin = latitude;
              if (longitude > lngMax) lngMax = longitude;
              if (longitude < lngMin) lngMin = longitude;
          }

          if (coordenadasZeradas) {
              cor = Colors.deepBlue.replace("#", "%23");
          } else if (item.angel in cores) {
              cor = cores[item.angel];
          } else {
              if (item.angel === noAngel[0]) {
              cor = Colors.dangerRed.replace("#", "%23");
              } else if (item.angel === noAngel[1]) {
              cor = Colors.cautionYellow.replace("#", "%23");
              } else if (item.angel === noAngel[2]) {
              cor = Colors.lightGreen.replace("#", "%23");
              } else if (item.angel === noAngel[3]) {
              cor = Colors.darkGreen.replace("#", "%23");
              } else {
              cor = escolheCor(indexCor++);
              }
              cores[item.angel] = cor;
          }

          if (coordenadasZeradas)
              icon = {
              pin: true,
              text: "!",
              cor: cor,
              stroke: Colors.cautionYellow.replace("#", "%23"),
              };
          else if (isNotAngel)
              icon = {
                  pin: true,
                  text: item.oss[0].servico.slice(0, 2)?.toUpperCase(),
                  cor: cor,
                  stroke: cor,
                  opacity: 0.4,
              };
          else
              icon = {
                  pin: true,
                  text: item.oss[0].servico.slice(0, 2)?.toUpperCase(),
                  cor: cor,
              };

          if (
              item.angel == "Hunter STONE" ||
              item.angel == "Hunter STONE (Com Ineficiência)"
          ) {
              isHunter = true;
              
              if (item.oss[0].prioridade == "media") {
                  icon.opacity = 0.6;
              }
              if (item.oss[0].prioridade == "baixa") {
                  icon.opacity = 0.2;
              }
              if (item.oss[0].ineficiencia !== null) {
                  icon.stroke = Colors.cautionYellow.replace("#", "%23");
              }
          }

          if (
              (item.angel == "Hunter TON" ||
              item.angel == "Hunter TON (Com ineficiência)") &&
              item.oss[0].contratante === "TON"
          ) {
              isHunter = true;
              icon.cor = "%231a8e52";
              if (item.oss[0].ineficiencia !== null) {
                  icon.stroke = Colors.cautionYellow.replace("#", "%23");
              }
          }

          if (item.oss[0].servico === "CAÇA POS") {
              icon.text = "CP"
          }

          if (item.tipo?.toLowerCase() === 'adquirencia')
              return ([
                  ...data,
                  {
                      id: i,
                      stonecode: item.id_atendimento,
                      latitude,
                      longitude,
                      servico: item.servico?.toLowerCase() === '0tpv' ? 
                          undefined 
                          : item.servico,
                      volume_oss: item.servico?.toLowerCase() === '0tpv' ? 
                          item.oss.lenght 
                          : undefined,
                      oss: item.oss.map((os) => (
                          item.servico?.toLowerCase() === '0tpv' ? 
                              {
                                  stonecode: item.id_atendimento,
                                  ineficiencia: item.ineficiencia,
                                  cliente: item.cliente,
                                  angel: item.angel,
                                  servico: os.servico,
                                  tipo_servico: os.tipo_servico,
                                  endereco: os.endereco,
                                  contratante: item.contratante,
                                  horario_funcionamento: os.horario_funcionamento,
                                  prioridade: os.prioridade,
                                  paid_value: os.paid_value
                              }
                              :
                              {
                                  stonecode: item.id_atendimento,
                                  order_number: os.id_os,
                                  deadline_date: os.deadline_date,
                                  customer_deadline_date: os.customer_deadline_date,
                                  cliente: item.cliente,
                                  angel: item.angel,
                                  status: os.status,
                                  polo: pole,
                                  servico: os.servico,
                                  tipo_servico: os.tipo_servico,
                                  referencia: os.referencia,
                                  cidade: os.cidade,
                                  contratante: item.contratante,
                                  horario_funcionamento: os.horario_funcionamento,
                                  tec_saida: os.tec_saida,
                                  modelo_saida: os.modelo_saida,
                                  tec_entrada: os.tec_entrada,
                                  modelo_entrada: os.modelo_entrada,
                                  area_risco: os.area_risco,
                              }
                      )),
                      marcado: false,
                      visivel: !isHunter,
                      filtrado: true,
                      icon,
                      area_risco: item.oss.length ? item.oss[0].area_risco : false
                  }
              ])
          return data
      }, [])

      history.push({
          pathname: "/lmp/senninha/cockpit",
          state: {
              region,
              district,
              pole,
              pins: allPins,
              modo: "mapa",
              angelsDisponiveis: angels?.map((item, i) => ({ 
                  id: i,
                  name: item.nome, 
                  value: item.email, 
                  document_number: item.document_number
              })),
              areasRisco: riskAreas
          },
      });
  }
}
