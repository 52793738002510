import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import CustomModal from "@components/CustomModal";
import DetailButton from "@components/DetailButton";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { Link } from "../styles";
import DetalhesOS from "./DetalhesOS";

export default function VisitasPendentesPolo(props) {
  const title = "Visitas Pendentes";
  const [visitasPendentesPolo, setVisitasPendentesPolo] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [visibleModal, setVisibleModal] = useState(undefined);
  const [visibleDetalhesOs, setVisibleDetalhesOs] = useState(false);
  const [oSSelecionada, setOSSelecionada] = useState(undefined);

  useEffect(() => {
    LogisticService.getPendentesPolo(props.polo).then((response) => {
      const result = [];
      response.data.forEach((item) => {
        result.push({
          data: item.data,
          cidade: item.cidade,
          volume_visitas: item.volume_visitas,
          oss: item.oss,
        });
      });
      setVisitasPendentesPolo(result);
      setLoaded(true);
    });
  }, [props.polo]);

  const dados = [];
  const colunas = [{ label: "Cidade", key: "cidade", align: "start" }];
  const cidades = [];
  const datas = [];
  const downloadLabels = ["Cidade"];
  const downloadData = [];
  const modalData = [];

  visitasPendentesPolo.forEach((item) => {
    if (!cidades.includes(item.cidade)) {
      cidades.push(item.cidade);
    }
    if (!datas.includes(item.data)) {
      datas.push(item.data);
    }
  });

  cidades.forEach((cidade, id1) => {
    const row = { cidade: cidade };
    const aggregation = [];

    datas.forEach((data) => {
      const newItem = {
        cidade: cidade,
        data: data,
        volume_visitas: 0,
        oss: [],
      };
      visitasPendentesPolo.forEach((item, id) => {
        if (item.cidade === cidade && item.data === data) {
          newItem.volume_visitas += item.volume_visitas;
          newItem.oss = newItem.oss.concat(item.oss);
        }
      });
      if (newItem.volume_visitas > 0) {
        aggregation.push(newItem);
      }
    });

    aggregation.forEach((item, id2) => {
      row[item.data] = (
        <>
          {item.volume_visitas}{" "}
          <DetailButton onClick={() => setVisibleModal(`${id1}${id2}`)} />
        </>
      );
      row[item.data + "_num"] = item.volume_visitas;
      modalData.push(
        <CustomModal
          title={`OSs que vencem em ${item.data} ${item.cidade}`}
          key={`${id1}${id2}`}
          visible={visibleModal === `${id1}${id2}`}
          zIndex={1000}
          onCancel={() => setVisibleModal(undefined)}
          footer={null}
        >
          <Box maxHeight={"400px"} margin={"0"} padding={"0"} scroll>
            {item.oss.map((os) => (
              <Link
                onClick={() => {
                  setOSSelecionada(os);
                  setVisibleDetalhesOs(true);
                }}
              >
                {os}
              </Link>
            ))}
          </Box>
        </CustomModal>
      );
    });
    dados.push(row);
  });

  dados.forEach((item) => {
    const rowDownload = [item.cidade];
    datas.forEach((data) => {
      if (item[data + "_num"]) {
        rowDownload.push(item[data + "_num"]);
      } else {
        rowDownload.push("");
      }
    });
    downloadData.push(rowDownload);
  });

  datas.forEach((data) => {
    colunas.push({ label: data, key: data });
    downloadLabels.push(data);
  });

  const tableData = {
    data: dados,
    columns: colunas,
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title + " - " + props.name}
      info={[`Visitas Pendentes ${props.name}`]}
      downloadLabels={loaded ? downloadLabels : []}
      downloadData={loaded ? downloadData : []}
    >
      {loaded ? <InfoTable data={tableData} /> : <LoadingSpinner />}
      <CustomModal
        title={`Detalhes OS ${oSSelecionada}`}
        visible={visibleDetalhesOs}
        zIndex={1010}
        onCancel={() => setVisibleDetalhesOs(false)}
        footer={null}
      >
        <DetalhesOS os={oSSelecionada} />
      </CustomModal>
      {modalData}
    </Container>
  );
}
