import React, { useEffect, useState } from "react";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import Container from "@components/Container";
import Row from "@components/Row";
import Box from "@components/Box";
import { ImageBox, Info, Title } from "../styles";
import { Image, Space, message } from "antd";

export default function ImagensOm(props) {
  const [dados, setDados] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const loading = <LoadingSpinner />;

  useEffect(() => {
    LogisticService.getAnexosTrucker(props.numeroOM)
      .then((response) => {
        if (response.status === 200 && response.data != null) {
          setDados(response.data);
          setLoaded(true);
        } else {
          message.error(`${response.data.erro}`);
          props.voltarOM();
        }
      })
      .catch((err) => {
        if (err.response?.data?.erro) {
          message.error(`${err.response?.data?.erro}`);
          props.voltarOM();
        }
      });
  }, [props.numeroOM]);

  const RowOM = () => {
    return (
      <>
        {loaded ? (
          <>
            {dados.map((dado, index) => {
              return (
                <Row key={index}>
                  <Container grid={1 / 3} title={`Dados Auditoria`}>
                    <Box>
                      <Title style={{ margin: "5px", font: "menu" }}>
                        Documento: {dado.documento}
                      </Title>
                      <Title style={{ margin: "5px", font: "menu" }}>
                        Nome: {dado.nome}
                      </Title>
                    </Box>
                  </Container>
                  <Container
                    grid={1 / 3}
                    title={`Assinatura`}
                    advice={"imagem"}
                  >
                    <ImageBox>
                      <Image
                        rootClassName="ginga-image"
                        src={`data:image/jpeg;base64,${dado.assinatura}`}
                      />
                    </ImageBox>
                  </Container>
                  <Container grid={1 / 3} title={`fotos`} advice={"imagem"}>
                    <ImageBox>
                      <Space>
                        <Image src={`data:image/jpeg;base64,${dado.foto}`} />
                      </Space>
                    </ImageBox>
                  </Container>
                </Row>
              );
            })}
          </>
        ) : (
          loading
        )}
      </>
    );
  };
  return (
    <Box>
      {}
      <RowOM></RowOM>
    </Box>
  );
}
