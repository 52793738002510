import { Button, Form, InputNumber, Radio, Tabs, Input, Select } from "antd";
import styled from "styled-components";
import Colors from "@colors";
import { Link } from "react-router-dom";
import defaultTheme from "@defaultTheme";

const { TabPane } = Tabs;
const { Search } = Input;

export const InputNum = styled(InputNumber)`
  width: 100%;
  height: 40px;
  border-radius: 60px 0 0 60px;
  background: ${Colors.white};
  border: 2px solid ${Colors.textGreen};
  padding: 8px;
  div,
  input {
    height: 100%;
  }
  @media (max-width: 600px) {
    width: 50vw;
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
`;

export const CustomButton = styled(Button)`
  height: 40px;
  background-color: ${(props) =>
    props.active ? Colors.textGreen : Colors.lightGreen};
  border: 2px solid ${Colors.textGreen};
  border-left: none;
  border-radius: 0 60px 60px 0;
  font-size: 14px;
  font-weight: bold;
  color: white;
  letter-spacing: 0.8px;
  display: flex;
  cursor: pointer;
  width: auto;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${Colors.textGreen};
  }
  @media (max-width: 768px) {
    padding: 8px;
    span {
      margin: 0;
    }
  }
`;
export const Title = styled.h1`
  padding-top: 2px;
`;

export const BackButton = styled(Button)`
  margin: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 112px;
  border-radius: 24px;
  background: ${Colors.lightGreen};
  border: ${Colors.textGreen};
  color: ${Colors.white};
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: bold;
  svg path {
    transition: 1s;
  }
  &:hover {
    svg path {
      fill: ${Colors.lightGreen};
    }
  }
`;

export const ImageBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-itens: center;
  padding: 0 8px 8px;
  img {
    height: 240px;
    width: auto;
    object-fit: cover;
    object-position: center;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  margin: auto;
`;

export const Info = styled.p`
  margin: 0 0 16px;
  font-size: 14px;
  text-align: center;
`;

export const LinkMaps = styled(Link)`
  color: ${Colors.lightGreen};
  font-size: 12px;
  letter-spacing: 1.2px;
  font-weight: bold;
  padding: 5px 16px 0 16px;
  width: 100%;
  font-weight: bold;
  text-align: end;
`;

//Parte nova de anexos

export const ContainerAnexos = styled.div`
  background-color: ${defaultTheme.colors.white};
  border-radius: 12px;

  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: #767f8d;
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: 0 0 0 0px rgb(60 129 65 / 20%);
  }

  .ant-input-group {
    width: 0px;
  }

  .ant-input-group-wrapper {
    display: inline-block;
    width: unset;
    text-align: start;
    vertical-align: top;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: -1px;
    padding: 0;
    border: 0;
    bottom: 35px;
    background: transparent;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    width: 44.44px;
    color: rgba(0, 0, 0, 0.45);
    height: 52px;
    border: 0px;
    background-color: ${defaultTheme.colors.grey10};
    border-bottom: 1px solid #767f8d;
    border-radius: 0px 8px 0px 0px !important;
  }

  .anticon svg {
    display: inline-block;
    width: 21px;
    height: 21px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    height: 56px;
    width: 355px;
    padding: 0 0px;
    border: 0;
    background-color: rgba(118, 127, 141, 0.1);
    border-radius: 8px 8px 0px 0px;
    margin-top: 16px;
    border-bottom: 1px solid #a6aeba;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    position: relative;
    top: 13px;
    left: 13px;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    right: 0px;
    cursor: auto;
    bottom: -12px;
    position: relative;
  }
`;

export const ContentAnexos = styled.div`
  display: flex;
  justify-content: center;
  div {
    display: "flex";
    flex-direction: "column";
    align-items: "center";
  }
  img {
    padding: 68.5px 0px 32px 0px;
  }
`;

export const SubtitleAnexos = styled.p`
  color: rgba(48, 55, 66, 1);
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: 16px;
`;

export const TabsOptions = styled(Tabs)`
  display: flex;
  justify-content: space-between;
  color: ${defaultTheme.colors.grey400};

  .ant-table-column-sorters {
    justify-content: start;
  }

  .ant-table-column-title {
    flex: initial !important;
  }
  .ant-tabs-tab {
    color: ${defaultTheme.colors.grey400};
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 16px 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    font-family: "Sharon Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin: 0;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > .ant-tabs-nav:before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav:before {
    bottom: 0;
    border: ${defaultTheme.colors.white};
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${defaultTheme.colors.green300};
    pointer-events: none;
    padding: 0.15rem;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${defaultTheme.colors.green700} !important;
  }

  .ant-tabs-tab-btn,
  .ant-tabs-tab-remove {
    outline: none;
    transition: all 0ms;
    color: ${defaultTheme.colors.grey400};
  }

  // Faz a borda inferior ficar do tamanho do container

  .ant-tabs-nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .ant-tabs-tab {
    width: 50%;
    justify-content: center;
    display: ${(props) => (props.firstTab ? "none" : "flex")};
  }

  .ant-empty-image img {
    width: 8.7rem;
    height: 7.6rem;
  }

  .ant-empty-description {
    margin: 2.4rem;
    font-size: 1.2rem;
    font-family: "Sharon Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: ${defaultTheme.colors.grey700};
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;
export const SearchInputAnexos = styled(Search)`
  margin-bottom: ${(props) => (props.anexoDoa ? "auto" : "84px")};
  .ant-input {
    width: 310.55px;
    height: 52px !important;
    border: 0;
    background-color: rgba(118, 127, 141, 0.1);
    border-bottom: 1px solid #767f8d;
    border-radius: 8px 0px 0px 0px !important;
  }

  .ant-input-group-addon {
    bottom: 0 !important;
  }
`;

export const CheckboxAnexosContent = styled.div`
  padding: 16px 0px 64px 0px;
`;

export const SelectAnexos = styled(Select)`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    height: 56px;
    width: 355px;
    padding: 0 0px;
    border: 1px solid #3d744e3d;
    background-color: rgba(118, 127, 141, 0.1);
    border-radius: 4px 4px 0px 0px;
  }

  .ant-select-selector {
    margin-top: 0 !important;
  }
`;
