import styled from "styled-components";


export const AllLegendDrawerContainer = styled.div`

`;

export const LegendRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const LegendLeftRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  opacity:  ${(props) => props.opacity ? 1 : 0.5};

  p{
    color: #303742;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 21.75px */
    letter-spacing: -0.15px;
  }

  span{
    color: #767F8D;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 18.85px */
    letter-spacing: -0.13px;
  }

`;

export const LegendIconRow = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: ${(props) => props.background};
`;

export const LegendExtendRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const LegendExtendContainer = styled.div`
  border-radius: 4px;
  padding: 8px;
  background: rgba(118, 127, 141, 0.10);
  margin-bottom: 12px;

  display: ${(props) => (props.open ? "grid" : "none")};;
  grid-template-rows: ${(props) => (props.open ? "1fr" : "0fr")};
  opacity: 0;
  transition: all 2s ease-in-out;
  opacity: ${(props) => (props.open ? 1 : 0)};
`;

export const LegendExtendContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: #767F8D;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.12px;
  margin-bottom: 6px;
  
  span {
    color: #767F8D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.12px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  :last-child{
    margin-bottom: 0px;
  }

`;

export const LegendTextService = styled.p`
  color: #A6AEBA;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.12px;
`;