import { GenIcon } from "react-icons";

function CalendarIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M7.225 12C6.54845 12 6 12.5485 6 13.225C6 13.9015 6.54845 14.45 7.225 14.45C7.90155 14.45 8.45 13.9015 8.45 13.225C8.45 12.5485 7.90155 12 7.225 12Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M6 17.225C6 16.5485 6.54845 16 7.225 16C7.90155 16 8.45 16.5485 8.45 17.225C8.45 17.9015 7.90155 18.45 7.225 18.45C6.54845 18.45 6 17.9015 6 17.225Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M11.225 12C10.5485 12 10 12.5485 10 13.225C10 13.9015 10.5485 14.45 11.225 14.45C11.9015 14.45 12.45 13.9015 12.45 13.225C12.45 12.5485 11.9015 12 11.225 12Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M10 17.225C10 16.5485 10.5485 16 11.225 16C11.9015 16 12.45 16.5485 12.45 17.225C12.45 17.9015 11.9015 18.45 11.225 18.45C10.5485 18.45 10 17.9015 10 17.225Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M15.225 12C14.5485 12 14 12.5485 14 13.225C14 13.9015 14.5485 14.45 15.225 14.45C15.9015 14.45 16.45 13.9015 16.45 13.225C16.45 12.5485 15.9015 12 15.225 12Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M17 3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4H9V3C9 2.44772 8.55229 2 8 2C7.44772 2 7 2.44772 7 3V4.01692C6.4716 4.03241 5.99675 4.06204 5.57494 4.11875C4.64711 4.2435 3.82769 4.51547 3.17158 5.17158C2.51547 5.82768 2.2435 6.64711 2.11876 7.57494C1.99994 8.45869 1.99997 9.57529 2 10.9294V15.0706C1.99997 16.4247 1.99994 17.5413 2.11876 18.4251C2.2435 19.3529 2.51547 20.1723 3.17158 20.8284C3.82769 21.4845 4.64711 21.7565 5.57494 21.8813C6.45866 22.0001 7.57523 22 8.92929 22H15.0705C16.4246 22 17.5413 22.0001 18.4251 21.8813C19.3529 21.7565 20.1723 21.4845 20.8284 20.8284C21.4845 20.1723 21.7565 19.3529 21.8813 18.4251C22.0001 17.5413 22 16.4248 22 15.0707V10.9295C22 9.57542 22.0001 8.45866 21.8813 7.57494C21.7565 6.64711 21.4845 5.82768 20.8284 5.17158C20.1723 4.51547 19.3529 4.2435 18.4251 4.11875C18.0033 4.06204 17.5284 4.03241 17 4.01692V3ZM7 7V6.01774C6.55114 6.03168 6.17144 6.05655 5.84144 6.10092C5.13538 6.19585 4.80836 6.36322 4.58579 6.58579C4.36322 6.80836 4.19585 7.13538 4.10092 7.84143C4.05655 8.17144 4.03168 8.55114 4.01775 9H19.9823C19.9683 8.55114 19.9435 8.17144 19.8991 7.84143C19.8042 7.13538 19.6368 6.80836 19.4142 6.58579C19.1916 6.36322 18.8646 6.19585 18.1586 6.10092C17.8286 6.05655 17.4489 6.03168 17 6.01774V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7L15 6H9L9 7C9 7.55228 8.55229 8 8 8C7.44772 8 7 7.55228 7 7ZM4 15V11H20V15C20 16.4425 19.9979 17.4238 19.8991 18.1586C19.8042 18.8646 19.6368 19.1916 19.4142 19.4142C19.1916 19.6368 18.8646 19.8042 18.1586 19.8991C17.4238 19.9979 16.4425 20 15 20H9C7.55752 20 6.57626 19.9979 5.84144 19.8991C5.13538 19.8042 4.80836 19.6368 4.58579 19.4142C4.36322 19.1916 4.19585 18.8646 4.10092 18.1586C4.00213 17.4238 4 16.4425 4 15Z",
        },
      },
    ],
  })(props);
}

export default CalendarIcon;
