import styled from "styled-components";
import { Button } from "antd";
import defaultTheme from "@defaultTheme";
import SelectCustom from "@components/SelectCustom";

export const PageAll = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: ${(props) => (props.menuIsOpen ? "8px" : "0px")};
`;

export const RightPageContent = styled.div`
  display: "flex" !important;
  position: "relative" !important;
`;

export const HubSelect = styled.div`
  display: flex;
  border-radius: 0.5rem;
  position: absolute;
  margin-left: 32px;
  margin-top: 24px;
  background-color: white;
  z-index: 949;
  width: 294px;
`;

export const Content = styled.div`
  color: ${defaultTheme.colors.grey700};
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-top: 1.2px solid ${defaultTheme.colors.grey10};
  line-height: 110%;
  font-size: ${defaultTheme.fontSize.m};
  font-weight: 600;
  letter-spacing: -0.01em;
`;

export const BoxContent = styled.div`
  color: ${defaultTheme.colors.grey500};
  padding: 1rem;
  width: 100%;
`;

export const TitleBox = styled.h3`
  color: ${defaultTheme.colors.grey500};
  font-family: "Sharon Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  padding-bottom: 16px;
  border-bottom: 1.2px solid ${defaultTheme.colors.grey50};
`;

export const TitleSelect = styled.h3`
  color: ${defaultTheme.colors.grey500};
  padding-top: ${(props) => (props.isFirst ? "0.68rem" : "1.4rem")};
  font-family: "Sharon Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: ${(props) => (props.showSubtitle ? "155%" : "129%")};

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
`;

export const SelectCard = styled(SelectCustom)`
  background-color: #f2f0;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: -7px;
  height: 2rem;
`;

export const ContentSelect = styled.div`
  margin-top: 0.6rem;
`;

export const ButtonBox = styled(Button)`
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid;
  width: 100%;
  height: 44px;
  margin-top: 1rem;
  color: white;
  font-weight: 600;
  line-height: 120%;
  font-size: 15px;
  cursor: pointer;
  background-color: ${defaultTheme.colors.green600};
  &:hover {
    color: white;
    background-color: #008e5a;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    height: 44px;
    margin-top: 1rem;
    color: white;
    background: rgba(0, 168, 104, 1) !important;
    border-color: rgba(0, 168, 104, 1) !important;
    text-shadow: none;
    box-shadow: none;
  }
`;

export const BottomComponents = styled.div``;
