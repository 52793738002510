import { default as React } from "react";
import { Route, Switch } from "react-router-dom";
import { MenuNav } from "../Layout/MenuNav";
import { PageLayout } from "../Layout/PageLayout";
import OnePage from "../pages/Bau/OnePage";
import DadosCliente from "../pages/Ferramentas/DadosCliente";
import RemuneracaoVariavel from "../pages/Ferramentas/RemuneracaoVariavel";
import Dashboard from "../pages/Ferramentas/RemuneracaoVariavel/detalhes/Dashboard";
import Ranking from "../pages/Ferramentas/RemuneracaoVariavel/detalhes/Ranking";
import CasosLiga from "../pages/Ferramentas/CasosLIGA";
import ClassificacaoCasos from "../pages/Ferramentas/CasosLIGA/ClassificacaoCasos";
import InputLiga from "../pages/Ferramentas/CasosLIGA/InputArvoreLIGA";
import MapeamentoOn from "../pages/Ferramentas/CasosLIGA/MapeamentoOn";
import InputMapeamentoOn from "../pages/Ferramentas/CasosLIGA/InputMapeamentoOn";
import InputAutonomia from "../pages/Ferramentas/Autonomia";
import GestaoDistritoPolo from "../pages/LastMile/GestaoDistritoPolo";
import Indicadores from "../pages/LastMile/Indicadores";
import Senninha from "../pages/LastMile/Senninha";
import Callback from "../pages/Login/callback";
import NotificacoesApp from "../pages/LastMile/NotificacoesApp";
import AnexosOSs from "../pages/Ferramentas/AnexosOSs";
import EncontrarTerminal from "../pages/Ferramentas/EncontrarTerminal/index";
import Hunter from "../pages/Ferramentas/Hunter";
import GestaoTerceira from "../pages/Ferramentas/GestaoTerceira";
import { Filters } from "../pages/LastMile/Senninha/componentes/Filters";
import MotivosAtraso from "../pages/Ferramentas/GestaoTerceira/MotivosAtraso";
import ReportDoaTerceira from "../pages/Ferramentas/GestaoTerceira/ReportDoaTerceira";
import CoberturaEstoque from "../pages/Ferramentas/GestaoTerceira/CoberturaEstoque";
import { HistoricoDoa } from "../pages/Ferramentas/AnexosOSs/HistoricoDoa/index.js";
import AngelsGreenApp from "../pages/Ferramentas/AngelsGreenApp";
import Cockpit from "../pages/LastMile/Senninha/Cockpit";
import SimulationMap from "../pages/LastMile/Senninha/Cockpit/components/SimulationMap";
import RealTimeMap from "../pages/Ferramentas/Tracking";
import appConfig from "./../consumingApi/services/appConfig";
import DashAngelsImage from "@assets/imgs/DashAngelsImage.svg";
import DashAngelsLogo from "@assets/imgs/DashAngelsLogo.svg";
import DashAngelsCallback from "@assets/imgs/DashAngelsCallback.svg";
import SenninhaV2 from "../pages/LastMile/SenninhaV2/index.js";


import {
  PrivateRoute,
  AuthMiddleware,
  config as authConfig,
} from "@stonelog/stonelog-auth-components";

const newAuthConfig = { ...authConfig };

authConfig.authApiURL = appConfig.logisticsUrl + "/auth/{function}";
newAuthConfig.afterLoginUrl = "/senninha";
newAuthConfig.callback.path = "/auth/callback";
newAuthConfig.appBaseUrl = `${window.location.protocol}//${window.location.host}`;
newAuthConfig.useOldVersion = false;
newAuthConfig.login.img = DashAngelsImage;
newAuthConfig.login.logo = DashAngelsLogo;
newAuthConfig.login.description = "Juntos vamos colorir o brasil de verde.";
newAuthConfig.callback.logo = DashAngelsCallback;
newAuthConfig.authApiURL = appConfig.logisticsUrl + "/auth/{function}";

export default (
  <Switch>
    <AuthMiddleware authConfig={newAuthConfig}>
      <PrivateRoute exact path="/senninha" component={SenninhaV2} />
      <PrivateRoute exact path="/" component={SenninhaV2} />
      <PrivateRoute exact path="/bau/one-page" component={OnePage} />
      <PrivateRoute
        exact
        path="/lmp/gestao-distrito-polo"
        component={GestaoDistritoPolo}
      />
      <PrivateRoute exact path="/lmp/senninha" component={SenninhaV2} />
      <PrivateRoute exact path="/lmp/senninha/cockpit" component={Cockpit} />
      <PrivateRoute
        exact
        path="/lmp/app-notifications"
        component={NotificacoesApp}
      />
      <PrivateRoute
        exact
        path="/ferramentas/dados-cliente"
        component={DadosCliente}
      />
      <PrivateRoute
        exact
        path="/ferramentas/remuneracao-variavel"
        component={RemuneracaoVariavel}
      />
      <PrivateRoute
        exact
        path="/ferramentas/remuneracao-variavel/dashboard"
        component={Dashboard}
      />
      <PrivateRoute
        exact
        path="/ferramentas/remuneracao-variavel/ranking"
        component={Ranking}
      />
      <PrivateRoute
        exact
        path="/ferramentas/casos-liga"
        component={CasosLiga}
      />
      <PrivateRoute
        exact
        path="/ferramentas/classificacao-casos"
        component={ClassificacaoCasos}
      />
      <PrivateRoute
        exact
        path="/ferramentas/input-liga"
        component={InputLiga}
      />
      <PrivateRoute
        exact
        path="/ferramentas/mapeamento-on"
        component={MapeamentoOn}
      />
      <PrivateRoute
        exact
        path="/ferramentas/input-mapeamento-on"
        component={InputMapeamentoOn}
      />
      <PrivateRoute exact path="/ferramentas/anexos" component={AnexosOSs} />
      <PrivateRoute
        exact
        path="/ferramentas/input-autonomia"
        component={InputAutonomia}
      />
      <PrivateRoute
        exact
        path="/ferramentas/encontrar-terminal"
        component={EncontrarTerminal}
      />
      <PrivateRoute exact path="/ferramentas/hunter" component={Hunter} />
      <PrivateRoute
        exact
        path="/ferramentas/operacao-terceira"
        component={GestaoTerceira}
      />
      <PrivateRoute
        exact
        path="/ferramentas/operacao-terceira/motivos-atraso"
        component={MotivosAtraso}
      />
      <PrivateRoute
        exact
        path="/ferramentas/operacao-terceira/cobertura-estoque"
        component={CoberturaEstoque}
      />
      <PrivateRoute
        exact
        path="/ferramentas/operacao-terceira/cobertura-estoque?:regiao&:distrito&:polo"
        component={CoberturaEstoque}
      />
      <PrivateRoute
        exact
        path="/ferramentas/operacao-terceira/report-doa-terceira"
        component={ReportDoaTerceira}
      />
      <PrivateRoute
        exact
        path="/ferramentas/anexos/consulta-doa"
        component={AnexosOSs}
      />
      <PrivateRoute
        exact
        path="/ferramentas/anexos/consulta-doa/:consulta"
        component={HistoricoDoa}
      />
      <PrivateRoute
        exact
        path="/ferramentas/teste-tracking"
        component={RealTimeMap}
      />
      <PrivateRoute
        exact
        path="/ferramentas/angels-green-app"
        component={AngelsGreenApp}
      />
    </AuthMiddleware>
  </Switch>
);
