import { LoadingOutlined } from "@ant-design/icons";
import { Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import Row from "@components/Row";
import LogisticService from "@LogisticService";

export default function RaioX(props) {
  const loading = <LoadingOutlined spin />;
  const [raioX, setRaioX] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getClienteEquals(props.stonecode).then((response) => {
      if (response && response.data != null) {
        setRaioX(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const renderTable = (raioX) => {
    let rows = [];
    if (raioX.lenght === 0) {
      return <Box>Não há informações para carregar.</Box>;
    } else {
      raioX.forEach((element) => {
        if (element.dsStatusProcesso === "Alerta") {
          rows.push(
            <Descriptions.Item label={"Atenção!"}>
              Esse cliente tem um cadastro da Equals recente e deve ser feito
              ativação de Raio-X
            </Descriptions.Item>
          );
        } else if (element.dsOperadora != "Equals Raio-X (Digital)") {
          if (element.dsStatusProcesso === "Sem status") {
            element.dsStatusProcesso = "Pendência";
          }
          rows.push(
            <Descriptions.Item label={"Adquirente"}>
              {element.dsOperadora}
            </Descriptions.Item>
          );
          rows.push(
            <Descriptions.Item label={"Status"}>
              {element.dsStatusProcesso}
            </Descriptions.Item>
          );
        }
      });
      return (
        <Descriptions bordered column={1} size={"small"}>
          {rows}
        </Descriptions>
      );
    }
  };

  return (
    <Container title={`Raio X`} grid={1 / 3}>
      {loaded ? (
        <Box margin={"0"}>{renderTable(raioX)}</Box>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
