import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button, Collapse } from "antd";

export const AllDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-space-align-center {
    margin-top: 18px;
    flex-wrap: wrap;
  }
`;

export const ContentDrawer = styled.div`
  overflow-y: auto;
  padding: 4px 24px 24px 24px;

  .ant-tag {
    border: 1px solid ${defaultTheme.colors.grey50};
    color: #303742 !important;
    font-weight: 600;
    line-height: 145%;
    border-radius: 1rem;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: ${defaultTheme.colors.grey50};
  }
  .ant-tag:hover {
    opacity: 1 !important;
  }
  .ant-menu-root.ant-menu-inline {
    width: 100% !important;
    margin-top: 9px;
  }
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    padding-left: 3px !important;
    color: #303742;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.01em;
  }
  .ant-menu-submenu-arrow {
    right: 240px !important;
  }
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    width: 23px !important;
    height: 28px !important;
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    color: rgba(0, 0, 0, 0.85);
    transform: translateY(-50%);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px dashed ${defaultTheme.colors.grey50};
    border-radius: 2px;
  }

  .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 10.24px;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${defaultTheme.colors.grey400};
    box-shadow: 0 0 0 2px white;
    z-index: 1;
  }

  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #767f8d;
  }
`;

export const SubtitleDrawer = styled.div`
  margin-right: 0.75rem;
`;

export const SubtitleText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #767f8d;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding-bottom: ${(props) => (props.first ? "8px" : "0px")};
`;

export const SelectCustomDrawer = styled.div`
  margin-top: 1.875rem;
`;

export const AnexoSerialContentDrawer = styled.div`
  margin-top: 1.275rem;
  padding-bottom: 24px;
  border-bottom: 1px solid #767f8d1a;
`;

export const AnexoSerialSubtitleDrawer = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #303742;
`;

export const TitleText = styled.p`
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #303742;
  margin-bottom: 12px;
  margin-top: 24px;
`;

export const BottomContentDrawer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #767f8d1a;
  padding: 12px 0 24px 0;
`;

export const DrawerFooterButton = styled(Button)`
  width: 328px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Sharon Sans";
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  border-color: transparent !important;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-left: ${(props) => (props.handleButton ? "18px" : "0px")} !important;
  margin-top: 8px;
  background-color: ${(props) =>
    props.firstbutton || props.handleButton
      ? defaultTheme.colors.green600
      : defaultTheme.colors.grey50};
  color: ${(props) =>
    props.firstbutton || props.handleButton
      ? defaultTheme.colors.white
      : defaultTheme.colors.grey700} !important;
  &:hover {
    background-color: ${(props) =>
      props.firstbutton || props.handleButton
        ? defaultTheme.colors.green700
        : defaultTheme.colors.grey20} !important;
  }
`;

export const ImageDrawer = styled.div`
  display: flex;
  justify-content: center;
  padding: 142.87px 0px 32px 0px;
`;

export const ImageText = styled.div`
  text-align: center;
`;

export const Panel = styled(Collapse.Panel)`
  .ant-collapse-header {
    margin-bottom: 8px;
  }

  .ant-checkbox-wrapper {
    margin-top: 12px;
  }

  :last-child {
    border: none !important;
  }
`;
