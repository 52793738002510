import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import Colors from "@colors";
import Box from "@components/Box";
import SelectBox from "@components/SelectBox";
import LogisticService from "@LogisticService";
import { CancelOutlined, CheckCircleOutline } from "@material-ui/icons";
import { Checkbox, Form, Input, message, Space } from "antd";
import React, { useEffect, useState } from "react";
import EditableTable from "@components/EditableTable";
import { FormItem, CopyEmail, Label } from "./styles";
import CustomModal from "@components/CustomModal";
import GestaoPessoas from "../../../Senninha/componentes/GestaoPessoas"
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { useDispatch } from "react-redux";
import { setOldSenninhaWorkFlow } from "../../../../../features/senninha/oldSenninhaSlice";
import { useSelector } from "react-redux";
import { hubsSelector } from "../../../../../features/senninha/hubsSlice";


const copiaEmail = (texto) => {
  navigator.clipboard.writeText(texto);
  message.info(`Email copiado com sucesso!`);
};

const renderDiaSemana = (dia) =>
  dia === true ? (
    <CheckCircleOutline style={{ fontSize: 22, color: Colors.lightGreen }} />
  ) : (
    <CancelOutlined style={{ fontSize: 22, color: Colors.dangerRed }} />
  );

const renderEmail = (email) => (
  <CopyEmail>
    <CopyOutlined
      style={{ fontSize: 22, color: Colors.deepBlue }}
      onClick={() => copiaEmail(email)}
    />
  </CopyEmail>
);

const modalOptions = [
  { id: "Carro", name: "Carro" },
  { id: "Moto", name: "Moto" },
];

const colunas = [
  {
    title: "Email",
    dataIndex: "email",
    editable: false,
    render: renderEmail,
    align: "center",
  },
  {
    title: "Nome",
    dataIndex: "nome",
    editable: false,
    sorter: { compare: (a, b) => a.nome.localeCompare(b.nome) },
    required: true,
  },
  {
    title: "Modal",
    dataIndex: "modal",
    editable: true,
    inputType: "select",
    selectOptions: modalOptions,
    required: true,
    align: "center",
  },
  {
    title: "Partida (latitude)",
    dataIndex: "partida_lat",
    width: 140,
    editable: true,
    required: true,
    align: "center",
  },
  {
    title: "Partida (longitude)",
    dataIndex: "partida_long",
    width: 140,
    editable: true,
    required: true,
    align: "center",
  },
  {
    title: "Retorno (latitude)",
    dataIndex: "retorno_lat",
    width: 140,
    editable: true,
    required: true,
    align: "center",
  },
  {
    title: "Retorno (longitude)",
    dataIndex: "retorno_long",
    width: 140,
    editable: true,
    required: true,
    align: "center",
  },
  {
    title: "Horas trabalhadas",
    dataIndex: "horas",
    editable: true,
    width: 160,
    inputType: "number",
    required: true,
    align: "center",
  },
  {
    title: "S",
    dataIndex: "monday",
    editable: true,
    width: 20,
    inputType: "boolean",
    render: renderDiaSemana,
    align: "center",
  },
  {
    title: "T",
    dataIndex: "tuesday",
    editable: true,
    width: 20,
    inputType: "boolean",
    render: renderDiaSemana,
    align: "center",
  },
  {
    title: "Q",
    dataIndex: "wednesday",
    editable: true,
    width: 20,
    inputType: "boolean",
    render: renderDiaSemana,
    align: "center",
  },
  {
    title: "Q",
    dataIndex: "thursday",
    editable: true,
    width: 20,
    inputType: "boolean",
    render: renderDiaSemana,
    align: "center",
  },
  {
    title: "S",
    dataIndex: "friday",
    editable: true,
    width: 20,
    inputType: "boolean",
    render: renderDiaSemana,
    align: "center",
  },
  {
    title: "S",
    dataIndex: "saturday",
    editable: true,
    width: 20,
    inputType: "boolean",
    render: renderDiaSemana,
    align: "center",
  },
  {
    title: "D",
    dataIndex: "sunday",
    editable: true,
    width: 20,
    inputType: "boolean",
    render: renderDiaSemana,
    align: "center",
  },
];

const initialStates = {
  email: null,
  modal: "Moto",
  partida_lat: null,
  partida_long: null,
  retorno_lat: null,
  retorno_long: null,
  horas: null,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};

const Configuracoes = ({ configuracoes, polos }) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [angelConfiguracoes, setAngelConfiguracoes] = useState(false);

  const dispatch = useDispatch();

  const {
    selectedHubInfos
  } = useSelector(hubsSelector);
  console.log(selectedHubInfos)
  useEffect(() => {
    LogisticService.getConfiguracoes(selectedHubInfos.children).then((response) => {
      if (response !== undefined && response.data !== null) {
        setData(response.data);
        setLoaded(true);
      }
    });
  }, []);

  useEffect(() => {
    setData(configuracoes);
  }, [configuracoes]);

  const createRequest = async (data) => {
    const parametros = {
      polo: selectedHubInfos.children,
      angel: data,
      acao: "add",
    };
    const response = await LogisticService.postConfiguracoes(parametros);
    if (response.data)
      if (response.data.sucesso)
        return {
          nome: response.data.nome,
          success: true,
          message: response.data.sucesso,
        };
      else return { success: false, message: response.data.erro };
  };

  const editRequest = async (data) => {
    setAngelConfiguracoes({ nome: data.nome, email: data.email });
    const parametros = {
      polo: selectedHubInfos.children,
      angel: data,
      acao: "edit",
    };
    const response = await LogisticService.postConfiguracoes(parametros);
    if (response.data)
      if (response.data.sucesso) {
        if (
          !data.monday &&
          !data.tuesday &&
          !data.wednesday &&
          !data.thursday &&
          !data.friday &&
          !data.saturday &&
          !data.sunday
        )
          setOpenModal(true);
        return { success: true, message: response.data.sucesso };
      } else return { success: false, message: response.data.erro };
  };

  const deleteRequest = async (data) => {
    setAngelConfiguracoes({ nome: data.nome, email: data.email });
    const response = await LogisticService.deleteConfiguracoes(data.nome);
    if (response.data)
      if (response.data.sucesso) {
        setOpenModal(true);
        return { success: true, message: response.data.sucesso };
      } else return { success: false, message: response.data.erro };
  };

  const customCreateForm = (form) => (
    <Form layout={"vertical"} form={form}>
      <FormItem
        name={"email"}
        label="Email"
        rules={[{ type: "email", required: true }]}
        width={240}
      >
        <Input />
      </FormItem>
      <Space>
        <FormItem name={"modal"} label="Modal" rules={[{ required: true }]}>
          <SelectBox
            options={modalOptions}
            width={"88px"}
            margin={"0"}
            valueIsName
          />
        </FormItem>
        <FormItem
          name={"partida_lat"}
          rules={[{ required: true }]}
          label="Partida (latitude)"
          width={160}
        >
          <Input />
        </FormItem>
        <FormItem
          name={"partida_long"}
          rules={[{ required: true }]}
          label="Partida (longitude)"
          width={160}
        >
          <Input />
        </FormItem>
        <FormItem
          name={"retorno_lat"}
          rules={[{ required: true }]}
          label="Retorno (latitude)"
          width={160}
        >
          <Input />
        </FormItem>
        <FormItem
          name={"retorno_long"}
          rules={[{ required: true }]}
          label="Retorno (longitude)"
          width={160}
        >
          <Input />
        </FormItem>
        <FormItem
          name={"horas"}
          rules={[{ required: true }]}
          label="Horas trabalhadas"
        >
          <Input min={1} max={10} />
        </FormItem>
      </Space>
      <Label>Dias da semana</Label>
      <Space>
        <FormItem name="monday" valuePropName="checked">
          <Checkbox>Segunda</Checkbox>
        </FormItem>
        <FormItem name="tuesday" valuePropName="checked">
          <Checkbox>Terça</Checkbox>
        </FormItem>
        <FormItem name="wednesday" valuePropName="checked">
          <Checkbox>Quarta</Checkbox>
        </FormItem>
        <FormItem name="thursday" valuePropName="checked">
          <Checkbox>Quinta</Checkbox>
        </FormItem>
        <FormItem name="friday" valuePropName="checked">
          <Checkbox>Sexta</Checkbox>
        </FormItem>
        <FormItem name="saturday" valuePropName="checked">
          <Checkbox>Sábado</Checkbox>
        </FormItem>
        <FormItem name="sunday" valuePropName="checked">
          <Checkbox>Domingo</Checkbox>
        </FormItem>
      </Space>
    </Form>
  );

  return (
    <div
      style={{
        width: '66%',
        position: 'absolute',
        zIndex: 1,
        top: '9%',
        left: '18%',
        background: 'white',
        borderRadius: '8px',
        padding: '1rem'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div></div>
        <GingaIcon
          name="close"
          size={25} color="black"
          style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
          onClick={() => dispatch(setOldSenninhaWorkFlow(''))}
        />
      </div>
      {loaded ? (
        <EditableTable
          columns={colunas}
          data={data}
          createDefaultValues={initialStates}
          customCreateForm={customCreateForm}
          createRequest={createRequest}
          editRequest={editRequest}
          deleteRequest={(e) => deleteRequest(e)}
          createButtonTitle={"Cadastrar Angel"}
        />
      ) : (
        <LoadingOutlined />
      )}
      <CustomModal
        title={"Gestão de Pessoas"}
        visible={openModal}
        zIndex={1010}
        width={800}
        footer={false}
      >
        <GestaoPessoas
          angelConfiguracoes={angelConfiguracoes}
          setVisible={setOpenModal}
          polo_antigo={selectedHubInfos.children}
          polos={polos}
        />
      </CustomModal>
    </div>
  );
};

export default Configuracoes;
