import { GenIcon } from "react-icons";

function RetryIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 16 16",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M3.33333 8C3.33333 5.41802 5.40974 3.33333 7.96078 3.33333C9.2579 3.33333 10.4317 3.87134 11.2732 4.74127L10.4373 4.68263C10.07 4.65687 9.7514 4.93373 9.72563 5.30101C9.69987 5.6683 9.97673 5.98693 10.344 6.01269L12.6953 6.17762C13.0626 6.20339 13.3812 5.92653 13.4069 5.55924L13.5719 3.208C13.5976 2.84071 13.3208 2.52208 12.9535 2.49631C12.5862 2.47055 12.2676 2.74741 12.2418 3.1147L12.1953 3.77731C11.1162 2.68107 9.61815 2 7.96078 2C4.6641 2 2 4.69094 2 8C2 11.3091 4.6641 14 7.96078 14C10.2711 14 12.2709 12.6773 13.2598 10.7501C13.4279 10.4225 13.2986 10.0207 12.971 9.85262C12.6435 9.68452 12.2416 9.81381 12.0735 10.1414C11.3022 11.6446 9.74804 12.6667 7.96078 12.6667C5.40974 12.6667 3.33333 10.582 3.33333 8Z",
        },
      },
    ],
  })(props);
}

export default RetryIcon;
