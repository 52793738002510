import React, { useState, useEffect } from "react";
import { Cascader, Checkbox, Form, Radio, Rate } from "antd";
import "antd/dist/antd.css";
import { blue } from "@material-ui/core/colors";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import { PageLayout } from "../../../../Layout/PageLayout";
import Page from "@components/Page";
import Box from "@components/Box";
import InfoTable from "@components/InfoTable";
import Container from "@components/Container";
import Row from "@components/Row";
import Dragger from "@components/CustomDragger";
import DefaultInput from "@components/DefaultInput";
import SelectBox from "@components/SelectBox";
import SubmitButton from "@components/SubmitButton";

import LogisticService from "@LogisticService";
// import { useAuthContext } from '../../../../stonelog-auth-components';
import { useAuthContext } from "@stonelog/stonelog-auth-components";

import { Download, Exemplo, Span, TextError, TextSuccess } from "../styles";
import Colors from "@colors";

export default function MapeamentoOn(props) {
  const [emailsMembros, setEmailsMembros] = useState([]);
  const [selectedEmailMembro, setSelectedEmailMembro] = useState(1);
  const [disabledEmailMembro, setDisabledEmailMembro] = useState(true);

  const [origens, setOrigens] = useState([]);
  const [disabledOrigens, setDisabledOrigens] = useState(true);

  const [demandas, setDemandas] = useState([]);
  const [disabledDemandas, setDisabledDemandas] = useState(true);

  const [osRequired, setOsRequired] = useState(false);

  const formRef = React.createRef();
  const [form] = Form.useForm();
  const multipleFormRef = React.createRef();
  const [multipleForm] = Form.useForm();

  const [dataDragger, setDataDragger] = useState([]);
  const [dadosTabela, setDadosTabela] = useState([]);

  const [successfully, setSuccessfully] = useState("wait");
  const [successfullyMultiple, setSuccessfullyMultiple] = useState("wait");

  const auth = useAuthContext();
  const userName = auth.getUser();
  const opcoesMacro = [
    { id: 1, name: "Sim", value: "True" },
    { id: 2, name: "Não", value: "False" },
  ];

  useEffect(() => {
    LogisticService.getEmailsMembros().then((response) => {
      if (response) {
        const emails = [{ id: 1, name: userName.email }];
        response.data.data.map((item) => {
          emails.push({ id: emails.length + 1, name: item.member_mail });
          return null;
        });

        setEmailsMembros(emails);
        setDisabledEmailMembro(false);
      }
    });
  }, []);

  useEffect(() => {
    LogisticService.getOrigemDemanda().then((response) => {
      if (response) {
        setOrigens(response.data);
        setDisabledOrigens(false);
      }
    });
  }, []);

  useEffect(() => {
    LogisticService.getDemandas().then((response) => {
      if (response) {
        const data = response.data;
        const options = Object.keys(data).map((tema) => {
          return {
            value: tema,
            label: tema,
            ...(Object.keys(data[tema]).length > 0 &&
              !Object.keys(data[tema]).includes("-") && {
                children: Object.keys(data[tema]).map((cat) => {
                  return {
                    value: cat,
                    label: cat,
                    ...(data[tema][cat].length > 0 &&
                      !(
                        data[tema][cat].includes("") ||
                        data[tema][cat].includes(null) ||
                        data[tema][cat].includes("-")
                      ) && {
                        children: data[tema][cat].map((assunto) => {
                          return {
                            value: assunto,
                            label: assunto,
                          };
                        }),
                      }),
                  };
                }),
              }),
          };
        });
        setDemandas(options);
        setDisabledDemandas(false);
        setOsRequired(true);
      }
    });
  }, []);

  const postForm = async (data) => {
    if (data) {
      return LogisticService.postMapeamentoOn(data)
        .then((response) => {
          if (response) {
            return response.status;
          }
        })
        .catch((response) => {
          return response;
        });
    }
  };

  const adicionarResultado = (status, formObj) => {
    let result;
    const date = new Date();

    if (status === 200) {
      result = () => {
        return (
          <TextSuccess>
            <CheckCircleOutlined /> Sim
          </TextSuccess>
        );
      };
    } else {
      result = () => {
        return (
          <TextError>
            <StopOutlined /> Nop
          </TextError>
        );
      };
    }
    formObj = {
      inserido: result(),
      horario: date.getHours() + ":" + date.getMinutes(),
      ...formObj,
    };
    setDadosTabela((oldState) => [formObj, ...oldState]);
  };

  const onFinishMultipleCases = async (data) => {
    dataDragger.forEach(async (formObj) => {
      const status = await postForm(formObj);
      adicionarResultado(status, formObj);

      if (status === 200) {
        setSuccessfullyMultiple("ok");
        form.resetFields();
        setTimeout(() => {
          setSuccessfullyMultiple("wait");
        }, 3000);
      } else {
        setSuccessfullyMultiple("erro");
        setTimeout(() => {
          setSuccessfullyMultiple("wait");
        }, 3000);
      }
    });
    multipleForm.resetFields();
  };

  const handleOnUpload = (dataFileDragger) => {
    setDataDragger(dataFileDragger);
  };

  const colunasTabela = [
    { label: "Número da Os", key: "os_numero" },
    { label: "Macro", key: "macro" },
    { label: "Demanda", key: "demanda" },
    { label: "Origem", key: "origem" },
    { label: "E-mail Operador", key: "email_operador" },
  ];

  const tableData = {
    data: dadosTabela,
    columns: colunasTabela,
  };

  const onFinish = async (data) => {
    const formObj = {
      ...data,
      mais_de_uma: data.mais_de_uma ? true : false,
      os_numero: data.os_numero ? data.os_numero : null,
      origem: origens[data?.origem]?.name ? origens[data.origem].name : "",
      email: emailsMembros[selectedEmailMembro - 1].name,
      email_operador: data.email_operador,
      demanda: data.demanda_realizada[0],
      especificacao: data.demanda_realizada
        ? data.demanda_realizada.length > 1
          ? data.demanda_realizada[1]
          : null
        : null,
      detalhe: data.demanda_realizada
        ? data.demanda_realizada.length > 2
          ? data.demanda_realizada[2]
          : null
        : null,
    };
    const status = await postForm(formObj);
    if (status === 200) {
      setSuccessfully("ok");
      form.resetFields();
      setTimeout(() => {
        setSuccessfully("wait");
        setOsRequired(true);
      }, 3000);
    } else {
      setSuccessfully("erro");
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    }
  };

  const filtroDemanda = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  const onChangeIncidente = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        demanda_realizada: ["Incidente"],
      });
      setDisabledDemandas(true);
    } else {
      form.setFieldsValue({
        demanda_realizada: [],
      });
      setDisabledDemandas(false);
    }
  };

  const rows = [
    <Row key={"1"}>
      <Span style={{ padding: 0 }}>Quantas OSs com a Mesma Demanda?</Span>
    </Row>,
    <Row key={"2"}>
      <Span style={{ color: "GrayText", fontSize: 10 }}>
        1 a 5 OSs, LIGAÇÃO. 6 a 10 OSs, CHAT.
      </Span>
    </Row>,
    <Row horizontalAlign="left" verticalAlign="center" key={"3"}>
      <Form.Item name="os_qty">
        <Rate
          initialValues={1}
          value={1}
          allowClear={false}
          count={10}
          character={({ index }) => index + 1}
          style={{
            color: Colors.textGreen,
            fontSize: 32,
            border: "1px solid #ccc",
            borderRadius: 16,
            marginRight: "24px",
            padding: 8,
          }}
        />
      </Form.Item>
      <Form.Item name="mais_de_uma" valuePropName="checked">
        <Checkbox>
          <Span>Mais de Uma demanda no mesmo atendimento</Span>
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="macro"
        label="Operador enviou macro completa?"
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: "Selecione o Macro!" }]}
      >
        <SelectBox
          placeholder="Selecione"
          options={opcoesMacro}
          rules={[{ required: true, message: "Selecione o Macro!" }]}
        />
      </Form.Item>
    </Row>,
    <Row horizontalAlign={"left"} key={"4"}>
      <div>
        <Form.Item
          name="os_numero"
          label="Número da OS"
          labelCol={{ span: 24 }}
          rules={[{ required: osRequired, message: "Informe o Número da OS!" }]}
        >
          <DefaultInput type={"Number"} min={0} style={{ width: "180px" }} />
        </Form.Item>
        <Form.Item name="os_requirement" valuePropName="checked">
          <Checkbox
            onChange={() => {
              setOsRequired(!osRequired);
            }}
          >
            <Span>Sem OS</Span>
          </Checkbox>
        </Form.Item>
      </div>
      <Form.Item
        name="origem"
        labelCol={{ span: 24 }}
        label="Origem da Demanda"
        rules={[{ required: true, message: "Selecione a Origem da Demanda!" }]}
      >
        <Radio.Group
          optionType={"button"}
          buttonStyle={"solid"}
          size={"large"}
          disabled={disabledOrigens}
        >
          {origens.map((origem, index) => {
            return (
              <Radio.Button value={origens.indexOf(origem)} key={index}>
                {origem.name}
              </Radio.Button>
            );
          })}
        </Radio.Group>
        <Form.Item
          name="email_operador"
          label="E-mail do Operador"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: "Informe o E-mail do Operador!" }]}
        >
          <DefaultInput type={"String"} min={0} style={{ width: "180px" }} />
        </Form.Item>
      </Form.Item>
    </Row>,
    <Row horizontalAlign="left" verticalAlign="center" key={"5"}>
      <Checkbox onChange={onChangeIncidente}>
        <Span>Incidente</Span>
      </Checkbox>
    </Row>,
    <Row key={"6"}>
      <Form.Item
        name="demanda_realizada"
        label="Demanda Realizada"
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: "Selecione a Demanda Realizada!" }]}
        style={{ width: "50%" }}
      >
        <Cascader
          options={demandas}
          placeholder="Selecione"
          autoFocus={true}
          disabled={disabledDemandas}
          size="large"
          showSearch={{ filter: filtroDemanda }}
        />
      </Form.Item>
    </Row>,
    <Row key={"7"}>
      <Form.Item name="email" label="Email" labelCol={{ span: 24 }}>
        <SelectBox
          placeholder="Selecione"
          options={emailsMembros}
          defaultValue={1}
          initialValues={1}
          disabled={disabledEmailMembro}
          onChange={(key) => {
            setSelectedEmailMembro(key);
          }}
          width={"17.189rem"}
        />
      </Form.Item>
    </Row>,
    <Row margin={"0 77px 0 0"} key={"8"}>
      <Form.Item name="descricao" label="Descrição:">
        <DefaultInput
          placeholder={"Informe Detalhes Sobre o Caso"}
          type={"TextArea"}
          style={{ width: "450px" }}
        />
      </Form.Item>
      <SubmitButton
        verifyer={successfully}
        textSuccess="Registrado Com Sucesso! ( >‿◠ )"
        textError="Falha Ao Inserir! ( ˘︹˘ )"
      >
        REGISTRAR MAPEAMENTO
      </SubmitButton>
    </Row>,
  ];

  return (
    <PageLayout>
      <Box title="Mapeamento ON">
        <Row>
          <Container
            grid={2}
            title={`Olá, ${userName.name.replace(" | Stone", "")}!`}
            padding={"0 16px 16px 16px"}
            rounded={true}
            margin={"1rem"}
          >
            <Form
              onFinish={onFinish}
              form={form}
              ref={formRef}
              initialValues={{
                os_qty: 1,
                mais_de_uma: false,
                origem: -1,
                descricao: "",
                especificacao: null,
                detalhe: null,
              }}
            >
              {rows}
            </Form>
          </Container>
        </Row>
        <Row horizontalAlign={"center"}>
          <Container
            grid={1.4 / 3}
            title={"Inserção de Demanda"}
            padding={"0 16px 16px 16px"}
            rounded={true}
          >
            <Form
              onFinish={onFinishMultipleCases}
              form={multipleForm}
              ref={multipleFormRef}
            >
              <Row>
                <Form.Item
                  name="dragger"
                  rules={[
                    {
                      required: true,
                      message: "Nenhum Arquivo CSV/XLSX Selecionado!",
                    },
                  ]}
                >
                  <Dragger
                    name="dragger"
                    title="Importar Múltiplas Demandas Para a Base de Dados"
                    text="Selecione ou Arraste um Arquivo XLSX Até Esta Área (Campos Datas Formatados Com Texto e '/')"
                    handleOnUpload={handleOnUpload}
                  />
                </Form.Item>
              </Row>
              <Row verticalAlign={"center"}>
                <SubmitButton
                  verifyer={successfullyMultiple}
                  textSuccess="Registrado Com Sucesso!"
                  textError="Falha Ao Registrar!"
                  styles={{ color: blue }}
                >
                  REGISTRAR MÚLTIPLOS MOTIVOS
                </SubmitButton>
              </Row>
              <Exemplo>
                Exemplo? Clique{" "}
                <Download
                  href={"/dist/Modelo CasosMapeamentoOn.csv"}
                  target="_blank"
                >
                  aqui
                </Download>{" "}
                para baixar um modelo de exemplo.
              </Exemplo>
            </Form>
          </Container>
          <Container grid={7 / 10} rounded={true}>
            <Row>
              <Span style={{ padding: "16px" }}>
                Visualização dos Casos Importados
              </Span>
            </Row>
            <Row>
              <InfoTable
                data={tableData}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
              />
            </Row>
          </Container>
        </Row>
      </Box>
    </PageLayout>
  );
}
