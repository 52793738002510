import styled from "styled-components";

export const CustomTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: overlay;
  // height: fit-content;
  max-height: 20vh;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 15.6px */
  letter-spacing: -0.13px;

  .ant-tag {
    height: ${(props) => (props.relocateCustomTag ? "24px" : "36px")};
    left: 0px;
    top: 0px;
    border-radius: ${(props) =>
      props.relocateCustomTag ? "1rem" : "0.5rem"} !important;
    padding: ${(props) =>
      props.relocateCustomTag
        ? "0.5rem 0.5rem;"
        : "0.5rem 0.74rem 0.5rem 0.74rem"};
    background: rgba(118, 127, 141, 0.1);
    display: flex;
    margin-right: 4px !important;
    margin-bottom: 4px !important;
    cursor: ${(props) => (props.clickable ? "pointer" : "")};
    border: 1px solid transparent;
  }

  .ant-tag-close-icon {
    margin-top: 6px;
    color: ${(props) =>
      props.relocateCustomTag ? "rgba(0, 0, 0, 1)" : ""} !important;
  }
`;
