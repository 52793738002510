import { StarOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import LoadingSpinner from "@components/LoadingSpinner";
import Page from "@components/Page";
import Row from "@components/Row";
import SelectBox from "@components/SelectBox";
import LogisticService from "@LogisticService";
import AlertasPolo from "./graficos/AlertasPolo";
import AvancoPolo from "./graficos/AvancoPolo";
import EstoquePolo from "./graficos/EstoquePolo";
import OSsPendentesDistrito from "./graficos/OSsPendentesDistrito";
import OSsPendentesPolo from "./graficos/OSsPendentesPolo";
import OSsPendentesRegiao from "./graficos/OSsPendentesRegiao";
import RealizadosDistrito from "./graficos/RealizadosDistrito";
import RealizadosPolo from "./graficos/RealizadosPolo";
import RealizadosRegiao from "./graficos/RealizadosRegiao";
import VencimentoHojePolo from "./graficos/VencimentoHojePolo";
import VisitasPendentesDistrito from "./graficos/VisitasPendentesDistrito";
import VisitasPendentesPolo from "./graficos/VisitasPendentesPolo";
import VisitasPendentesRegiao from "./graficos/VisitasPendentesRegiao";
import { TabButton } from "./styles";
import Navbar from "@components/Navbar";
import { PageLayout } from "../../../Layout/PageLayout";

export default function GestaoDistritoPolo(props) {
  const [loading, setLoading] = useState(false);
  const [regioes, setRegioes] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [polos, setPolos] = useState([]);

  const [selectedRegiao, setSelectedRegiao] = useState(null);
  const [selectedDistrito, setSelectedDistrito] = useState(null);
  const [selectedPolo, setSelectedPolo] = useState(null);

  const [selectedRegiaoNome, setSelectedRegiaoNome] = useState("");
  const [selectedDistritoNome, setSelectedDistritoNome] = useState("");
  const [selectedPoloNome, setSelectedPoloNome] = useState("");

  const [disabledRegioes, setDisabledRegioes] = useState(true);
  const [disabledDistritos, setDisabledDistritos] = useState(true);
  const [disabledPolos, setDisabledPolos] = useState(true);

  const [showEstoqueTon, setShowEstoqueTon] = useState(false);

  const pageAnchors = [
    { name: "Atendimento", hash: "atendimento", icon: <StarOutlined /> },
  ];

  const [downloadAll, setDownloadAll] = useState([]);
  let downloadIndex = 0;

  useEffect(() => {
    LogisticService.getRegioes().then((response) => {
      setDisabledRegioes(false);
      setRegioes(response.data);
    });
  }, []);

  const onChangeRegiao = (value, component) => {
    setDownloadAll([]);
    setSelectedRegiao(value);
    setLoading(true);
    setSelectedDistrito(null);
    setSelectedPolo(null);
    setDisabledRegioes(true);
    setSelectedRegiaoNome("");
    setSelectedDistritoNome("");
    setSelectedPoloNome("");
    LogisticService.getDistritos(value).then((response) => {
      setSelectedRegiaoNome(component.children);
      setDisabledRegioes(false);
      setDisabledDistritos(false);
      setDisabledPolos(true);
      setDistritos(response.data);
      setLoading(false);
    });
  };

  const onChangeDistrito = (value) => {
    setDownloadAll([]);
    setSelectedDistrito(value);
    setLoading(true);
    setDisabledDistritos(true);
    setDisabledPolos(true);
    setSelectedPolo(null);
    setSelectedDistritoNome("");
    setSelectedPoloNome("");
    LogisticService.getPolosPorDistrito(value).then((polos) => {
      LogisticService.getDistritoPorId(value).then((distritos) => {
        setPolos(polos.data);
        setSelectedDistritoNome(distritos.data);
        setDisabledPolos(false);
        setDisabledDistritos(false);
        setLoading(false);
      });
    });
  };

  const onChangePolo = (value) => {
    setDownloadAll([]);
    setSelectedPolo(value);
    setLoading(true);
    setSelectedPoloNome("");
    LogisticService.getPoloPorId(value).then((response) => {
      setLoading(false);
      setSelectedPoloNome(response.data);
    });
  };

  const searchContent = [
    <SelectBox
      placeholder="Região"
      options={regioes}
      onChange={onChangeRegiao}
      disabled={disabledRegioes}
    />,
    <SelectBox
      placeholder="Distrito"
      options={distritos}
      onChange={onChangeDistrito}
      disabled={disabledDistritos}
      value={selectedDistrito || "Distrito"}
    />,
    <SelectBox
      placeholder="Polo"
      options={polos}
      onChange={onChangePolo}
      disabled={disabledPolos}
      width={400}
      value={selectedPolo || "Polo"}
    />,
  ];

  return (
    <>
      <PageLayout>
        <Page
          searchContent={searchContent}
          downloadAll={downloadAll}
          subTitle={
            selectedPoloNome || selectedDistritoNome || selectedRegiaoNome || ""
          }
        >
          {loading ? (
            <LoadingSpinner />
          ) : selectedPolo != null ? (
            <Box title="Atendimento" margin="0" id="atendimento" search>
              <>
                <Row>
                  <VisitasPendentesPolo
                    polo={selectedPolo}
                    name={selectedPoloNome}
                    downloadAll={downloadAll}
                    index={downloadIndex++}
                  />
                </Row>
                <Row>
                  <OSsPendentesPolo
                    polo={selectedPolo}
                    name={selectedPoloNome}
                    downloadAll={downloadAll}
                    index={downloadIndex++}
                  />
                </Row>
                <Row>
                  <VencimentoHojePolo
                    polo={selectedPolo}
                    name={selectedPoloNome}
                  />
                </Row>
                <Row>
                  <RealizadosPolo
                    polo={selectedPolo}
                    name={selectedPoloNome}
                    downloadAll={downloadAll}
                    index={downloadIndex++}
                  />
                </Row>
                <Row>
                  <AlertasPolo
                    polo={selectedPolo}
                    name={selectedPoloNome}
                    downloadAll={downloadAll}
                    index={downloadIndex++}
                  />
                </Row>
                <Row>
                  <EstoquePolo
                    showEstoqueTon={showEstoqueTon}
                    polo={selectedPolo}
                    name={selectedPoloNome}
                    downloadAll={downloadAll}
                    index={downloadIndex++}
                  />
                </Row>
                <Row
                  horizontalAlign={"start"}
                  margin={"0 8px 16px"}
                  smallScreenMargin={"0 0 16px"}
                  nonResponsive
                >
                  <TabButton
                    onClick={() => setShowEstoqueTon(false)}
                    type={showEstoqueTon ? true : false}
                  >
                    Stone
                  </TabButton>
                  <TabButton
                    onClick={() => setShowEstoqueTon(true)}
                    type={showEstoqueTon ? false : true}
                  >
                    TON
                  </TabButton>
                </Row>
                <Row>
                  <AvancoPolo
                    polo={selectedPolo}
                    name={selectedPoloNome}
                    downloadAll={downloadAll}
                    index={downloadIndex++}
                  />
                </Row>
              </>
            </Box>
          ) : selectedDistrito != null ? (
            <Box title="Atendimento" margin="0" id="atendimento" search>
              <Row>
                <VisitasPendentesDistrito
                  id_distrito={selectedDistrito}
                  name={selectedDistritoNome}
                  downloadAll={downloadAll}
                  index={downloadIndex++}
                />
              </Row>
              <Row>
                <OSsPendentesDistrito
                  id_distrito={selectedDistrito}
                  name={selectedDistritoNome}
                  downloadAll={downloadAll}
                  index={downloadIndex++}
                />
              </Row>
              <Row>
                <RealizadosDistrito
                  id_distrito={selectedDistrito}
                  name={selectedDistritoNome}
                  downloadAll={downloadAll}
                  index={downloadIndex++}
                />
              </Row>
            </Box>
          ) : selectedRegiao != null ? (
            <Box title="Atendimento" margin="0" id="atendimento" search>
              <Row>
                <VisitasPendentesRegiao
                  id_regiao={selectedRegiao}
                  name={selectedRegiaoNome}
                  downloadAll={downloadAll}
                  index={downloadIndex++}
                />
              </Row>
              <Row>
                <OSsPendentesRegiao
                  id_regiao={selectedRegiao}
                  name={selectedRegiaoNome}
                  downloadAll={downloadAll}
                  index={downloadIndex++}
                />
              </Row>
              <Row>
                <RealizadosRegiao
                  id_regiao={selectedRegiao}
                  name={selectedRegiaoNome}
                  downloadAll={downloadAll}
                  index={downloadIndex++}
                />
              </Row>
            </Box>
          ) : (
            <></>
          )}
        </Page>
      </PageLayout>
    </>
  );
}
