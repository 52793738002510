import styled from "styled-components";


export const BodyTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 12px 24px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
`;

