import React from "react";
import { Select } from "antd";
const { Option } = Select;

const formatarMesEAno = (mes, ano) => {
  const formattedMonth = mes < 10 ? "0".concat(mes) : mes;
  return formattedMonth + "/" + ano;
};

const PainelDeBusca = (props) => {
  const { nome, nomes, mesesDisponiveis, mes, ano } = props;

  function alterarColaborador(novoNome) {
    props.alterarColaborador(novoNome);
  }

  function alterarData(novaData) {
    const mes = novaData.split("/")[0];
    const ano = novaData.split("/")[1];

    props.alterarData({ mes, ano });
  }

  return (
    <div>
      <Select
        defaultValue={nome}
        style={{ width: 300, margin: "16px" }}
        onChange={alterarColaborador}
      >
        {nomes.map((nome) => (
          <Option key={Math.random()} value={nome}>
            {nome}
          </Option>
        ))}
      </Select>

      <Select
        defaultValue={formatarMesEAno(mes, ano)}
        style={{ width: 120, margin: "16px" }}
        onChange={alterarData}
      >
        {mesesDisponiveis
          ? mesesDisponiveis
              .sort(
                (a, b) =>
                  a.reference_year - b.reference_year ||
                  a.reference_month - b.reference_month
              )
              .map((periodo) => {
                const mes = periodo.reference_month;
                const ano = periodo.reference_year;
                const dataFormatada = formatarMesEAno(mes, ano);
                return (
                  <Option key={Math.random()} value={dataFormatada}>
                    {dataFormatada}
                  </Option>
                );
              })
          : ""}
      </Select>
    </div>
  );
};

export default PainelDeBusca;
