import React from "react";

import { RowBox } from "./styles";

const Row = (props) => {
  return (
    <RowBox
      color={props.color}
      hidden={props.hidden}
      width={props.width}
      verticalAlign={props.verticalAlign}
      horizontalAlign={props.horizontalAlign}
      horizontalAlignResponsive={props.horizontalAlignResponsive}
      horizontalScroll={props.horizontalScroll}
      nonResponsive={props.nonResponsive}
      smallScreenMargin={props.smallScreenMargin}
      margin={props.margin}
      padding={props.padding}
      relative={props.relative}
      wrap={props.wrap}
      fullSize={props.fullSize}
    >
      {props.children}
    </RowBox>
  );
};

export default Row;
