import { LoadingOutlined } from "@ant-design/icons";
import { Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import Row from "@components/Row";
import LogisticService from "@LogisticService";

export default function InformacoesGerais(props) {
  const loading = <LoadingOutlined spin />;
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [ultimoAcesso, setUltimoAcesso] = useState("");
  const [ultimoPagamento, setUltimoPagamento] = useState("");
  const [antecipacao, setAntecipacao] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getInformacoesGerais(props.stonecode).then((response) => {
      if (response && response.data != null) {
        if (response.data.status.description !== null) {
          setStatus(response.data.status.description);
        } else {
          setStatus(" - ");
        }
      }
      setLoaded(true);
    });
    LogisticService.getUsuariosPortal(props.stonecode).then((response) => {
      if (
        response &&
        response.data !== null &&
        response.data !== undefined &&
        response.data[0] !== undefined
      ) {
        setEmail(response.data[0].email);
        LogisticService.getUltimoAcesso(response.data[0].email).then(
          (ultimo_acesso) => {
            if (
              ultimo_acesso.data !== undefined &&
              ultimo_acesso.data.data !== undefined
            ) {
              if (ultimo_acesso.data.data.lastAccessDate !== undefined) {
                setUltimoAcesso(
                  ultimo_acesso.data.data.lastAccessDate.substring(0, 10)
                );
              } else {
                setUltimoAcesso("Sem acesso");
              }
            }
          }
        );
      } else {
        setEmail("Não encontrado!");
        setUltimoAcesso(" - ");
      }
    });
    LogisticService.getUltimoPagamento(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        if (response.data.date !== undefined) {
          setUltimoPagamento(response.data.date.substring(0, 10));
        } else {
          setUltimoPagamento(" - ");
        }
      }
    });

    LogisticService.getRAV(props.stonecode).then((response) => {
      if (
        response &&
        response.data != null &&
        response.data != undefined &&
        response.data[0] != undefined
      ) {
        var rav = response.data[0]["hasAutomaticReceivableAdvance"];
        if (rav == true) {
          setAntecipacao("Ativada");
        } else {
          setAntecipacao("Desativada");
        }
      } else {
        setAntecipacao(" - ");
      }
    });
  }, []);

  return (
    <Container
      title={`Stonecode: ${props.stonecode}`}
      icon={"info-circle"}
      grid={1 / 3}
    >
      {loaded ? (
        <Descriptions bordered column={1} size={"small"}>
          <Descriptions.Item label={"Status de Cadastro"}>
            {status ? status : loading}
          </Descriptions.Item>
          <Descriptions.Item label={"E-mail"}>
            {email ? email : loading}
          </Descriptions.Item>
          <Descriptions.Item label={"Último acesso"}>
            {ultimoAcesso ? ultimoAcesso : loading}
          </Descriptions.Item>
          <Descriptions.Item label={"Último pagamento"}>
            {ultimoPagamento ? ultimoPagamento : loading}
          </Descriptions.Item>
          <Descriptions.Item label={"Antecipação automática"}>
            {antecipacao ? antecipacao : loading}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
