import React, { useEffect, useState } from "react";
import LogisticService from "@LogisticService";
import { useLocation, useHistory } from "react-router-dom";
import appConfig from "@appConfig";
import Breadcrumbs from "../../../../components/Breadcumbs";
import { PageLayout } from "../../../../Layout/PageLayout";
import PageHeader from "@components/PageHeader";
import { isEqual } from "date-fns";
import FilterAltIcon from "@assets/icons/FilterAltIcon";
import PinIcon from "@assets/icons/PinIcon";
import { Space } from "antd";
import TimelineTable from "./components/TimeLine";
import RoutePickCard from "./components/RoutePickCard";
import OverviewRoute from "./components/OverviewRoute";
import FilterRoute from "./components/FilterRoute";
import OverviewMapRoute from "./components/OverviewMapRoute";
import SimulationMap from "./components/SimulationMap";
import MapRoutes from "./components/MapRoutes";
import { capitalizeAllFirstLetters } from "../../../../consumingApi/services/helper";
import { angelsColors } from "./helper";
// import { LoadScript } from "@react-google-maps/api";
import OverviewRouteScoreCard from "./components/OverviewRouteScoreCard";

import * as S from "./styles";

const GMAPS_API_KEY = appConfig.mapsKey;
const LIBRARIES = ["geometry"];

const Cockpit = () => {
  const [activeTab, setActiveTab] = useState("timeline");
  const [simulatedPins, setSimulatedPins] = useState(null);

  const [noPoligonSimulation, setNoPoligonSimulation] = useState(null);
  const [loadingNoPoligonSimulation, setLoadingNoPoligonSimulation] = useState(
    false
  );
  const [poligonSimulation, setPoligonSimulation] = useState(null);
  const [loadingPoligonSimulation, setLoadingPoligonSimulation] = useState(
    false
  );
  const [districtalSimulation, setDistrictalSimulation] = useState(null);
  const [
    loadingDistrictalSimulation,
    setLoadingDistrictalSimulation,
  ] = useState(false);
  const [
    currentDistrictalSimulation,
    setCurrentDistrictalSimulation,
  ] = useState(0);

  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(null);
  const [filteredRouteTimeLine, setFilteredRouteTimeLine] = useState(null);
  const [filteredRouteMap, setFilteredRouteMap] = useState(null);
  const [pickedRoute, setPickedRoute] = useState(null);
  const [openSimulationModal, setOpenSimulationModal] = useState(false);
  const [totalSimulationsInfo, setTotalSimulationsInfo] = useState({
    "Sem polígono": null,
    "Com polígono": null,
    Distrital: null,
  });
  const [simulationsPointsData, setSimulationsPointsData] = useState({
    "Sem polígono": null,
    "Com polígono": null,
    Distrital: null,
  });

  const simulationCache = JSON.parse(localStorage.getItem("simulation"));

  const location = useLocation();
  const history = useHistory();

  const getSimulation = async (type, setLoading, setSimulation, key) => {
    setLoading(true);
    try {
      const res = await LogisticService.getRotasCockpit(
        location.state.pole,
        type
      );

      const finalRes = applyColor(res.data.data);
      setSimulation(finalRes);
      setTotalSimulationsInfo((p) => ({ ...p, [key]: totalInfo(finalRes) }));
      setSimulationsPointsData((p) => ({ 
        ...p, 
        [key]: {
          points: res.data.points,
          alerts: res.data.alerts,
        } 
      }))
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getSimulationDistrictal = async () => {
    setLoadingDistrictalSimulation(true);
    try {
      const res = await LogisticService.getRotasCockpit(
        location.state.pole,
        "simulacao"
      );
      setCurrentDistrictalSimulation(res.data.simulation);
      if (res.data.simulation === simulationCache?.amount || !simulationCache) {
        const finalRes = applyColor(res.data.data);
        setDistrictalSimulation(finalRes);
        setTotalSimulationsInfo((p) => ({
          ...p,
          Distrital: totalInfo(finalRes),
        }));

        if (!simulationCache) {
          localStorage.setItem(
            "simulation",
            JSON.stringify({
              date: new Date().toISOString().split("T")[0],
              amount: res.data.simulation,
              pole: location.state.pole,
            })
          );
        }
      }
      setSimulationsPointsData((p) => ({ 
        ...p, 
        'Distrital': {
          points: res.data.points,
          alerts: res.data.alerts,
        } 
      }))
      setLoadingDistrictalSimulation(false);
    } catch (e) {
      setLoadingDistrictalSimulation(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (!location.state) return;

    if (!noPoligonSimulation && !loadingNoPoligonSimulation)
      getSimulation(
        "sem_poligono",
        setLoadingNoPoligonSimulation,
        setNoPoligonSimulation,
        "Sem polígono"
      );

    if (!poligonSimulation && !loadingPoligonSimulation)
      getSimulation(
        "poligono",
        setLoadingPoligonSimulation,
        setPoligonSimulation,
        "Com polígono"
      );

    if (!districtalSimulation && !loadingDistrictalSimulation) {
      const today = new Date().toISOString().split("T")[0];

      if (simulationCache) {
        if (
          isEqual(new Date(simulationCache.date), new Date(today)) &&
          simulationCache.pole === location.state.pole
        )
          getSimulationDistrictal();
        else localStorage.removeItem("simulation");
      } else {
        getSimulationDistrictal();
      }
    }
  }, [
    location,
    filteredRouteTimeLine,
    noPoligonSimulation,
    poligonSimulation,
    districtalSimulation,
  ]);

  const applyColor = (data) =>
    data.map((item, index) => ({ ...item, color: angelsColors[index] }));

  const totalInfo = (simulation) =>
    simulation.reduce(
      (accumulator, item) => {
        const rounded_distance = Math.round(item.total_distance / 1000);
        const rounded_duration = Math.round(item.total_duration / 60);
        const orders_number = item.total_orders_number;
        const amount = 1;
        return [
          accumulator[0] + rounded_distance,
          accumulator[1] + rounded_duration,
          accumulator[2] + orders_number,
          accumulator[3] + amount,
        ];
      },
      [0, 0, 0, 0]
    );

  const pickedSimulation = () =>
    ({
      "Senninha (sem polígono)": noPoligonSimulation,
      "Senninha (com polígono)": poligonSimulation,
      Distrital: districtalSimulation,
    }[pickedRoute]);

  const filteredSimulation = () => {
    if (filteredRouteMap === "Distrital") return districtalSimulation;
    if (filteredRouteMap === "Com polígono") return poligonSimulation;
    return noPoligonSimulation;
  };

  const simulateRoute = () => setOpenSimulationModal(true);

  const handleGoogleMapsLoad = () => setGoogleMapsLoaded(true);

  useEffect(() => {
    if (
      simulationCache &&
      simulationCache?.amount <= 3 &&
      currentDistrictalSimulation !== simulationCache?.amount &&
      !loadingDistrictalSimulation
    ) {
      const timer = setTimeout(() => getSimulationDistrictal(), 15000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [loadingDistrictalSimulation, simulationCache]);

  return (
    // <LoadScript
    //   id="script-loader"
    //   googleMapsApiKey={GMAPS_API_KEY}
    //   language="pt"
    //   region="br"
    //   onLoad={handleGoogleMapsLoad}
    //   libraries={LIBRARIES}
    // >
      <PageLayout showMenuNav={false}>
        {location.state && (
          <SimulationMap
            openSimulationModal={openSimulationModal}
            setOpenSimulationModal={setOpenSimulationModal}
            pins={location.state.pins}
            modo={location.state.modo}
            polo={location.state.pole}
            areasRisco={location.state.areasRisco}
            angelsDisponiveis={location.state.angelsDisponiveis}
            googleMapsLoaded
            setLoadingDistrictalSimulation={setLoadingDistrictalSimulation}
            setSimulatedPins={setSimulatedPins}
            setDistrictalSimulation={setDistrictalSimulation}
            setTotalSimulationsInfo={setTotalSimulationsInfo}
          />
        )}

        <div>
          <Breadcrumbs 
            onPathClickConfig={{
              pathSnippet: 'senninha',
              data: {
                region: location.state.region,
                district: location.state.district,
                pole: location.state.pole,
              }
            }} 
          />

          <S.MainHeader>
            <PageHeader
              title="Cockpit Distrital"
              onGoBack={() =>
                history.push({
                  pathname: "/senninha",
                  state: {
                    region: location.state.region,
                    district: location.state.district,
                    pole: location.state.pole,
                  },
                })
              }
            />
            <div>
              <FilterRoute
                {...(activeTab === "timeline"
                  ? {
                      filteredRoute: filteredRouteTimeLine,
                      setFilteredRoute: setFilteredRouteTimeLine,
                    }
                  : {
                      filteredRoute: filteredRouteMap,
                      setFilteredRoute: setFilteredRouteMap,
                      mapTab: true,
                    })}
              />
              <S.TabButton
                active={activeTab === "timeline"}
                onClick={() => setActiveTab("timeline")}
              >
                <FilterAltIcon size={16} />
                Linha do tempo
              </S.TabButton>
              <S.TabButton
                active={activeTab === "map"}
                onClick={() => setActiveTab("map")}
              >
                <PinIcon size={16} />
                Mapa
              </S.TabButton>
            </div>
          </S.MainHeader>

          {activeTab === "timeline" ? (
            <S.Container>
              <Space direction="vertical" size="large">
                <S.Header>
                  {`${capitalizeAllFirstLetters(location.state.region)} > ${
                    location.state.district
                  } > ${capitalizeAllFirstLetters(location.state.pole)}`}
                </S.Header>

                <TimelineTable
                  title="Senninha (sem polígono)"
                  data={noPoligonSimulation}
                  totalInfo={totalSimulationsInfo["Sem polígono"]}
                  loading={loadingNoPoligonSimulation}
                />

                {(!filteredRouteTimeLine ||
                  filteredRouteTimeLine === "Com polígono") && (
                  <TimelineTable
                    title="Senninha (com polígono)"
                    data={poligonSimulation}
                    totalInfo={totalSimulationsInfo["Com polígono"]}
                    loading={loadingPoligonSimulation}
                  />
                )}

                {(!filteredRouteTimeLine ||
                  filteredRouteTimeLine === "Distrital") && (
                  <TimelineTable
                    title="Distrital"
                    data={districtalSimulation}
                    totalInfo={totalSimulationsInfo["Distrital"]}
                    loading={loadingDistrictalSimulation}
                    simulateRoute={simulateRoute}
                    totalSimulations={simulationCache?.amount || 0}
                    setOpenSimulationModal={setOpenSimulationModal}
                  />
                )}
              </Space>

              <S.Grid>
                <RoutePickCard
                  pickedRoute={pickedRoute}
                  setPickedRoute={setPickedRoute}
                  routeData={pickedSimulation()}
                  pins={
                    pickedRoute === "Distrital"
                      ? simulatedPins
                      : location.state.pins
                  }
                  angelsDisponiveis={location.state.angelsDisponiveis}
                  areaInfo={{
                    region: location.state.region,
                    district: location.state.district,
                    pole: location.state.pole,
                  }}
                />
                <OverviewRouteScoreCard 
                  simulations={{
                    "Sem polígono": {
                      totalInfo: totalSimulationsInfo["Sem polígono"],
                      pointsData: simulationsPointsData["Sem polígono"],
                      loading: loadingNoPoligonSimulation,
                    },
                    "Com polígono": {
                      totalInfo: totalSimulationsInfo["Com polígono"],
                      pointsData: simulationsPointsData["Com polígono"],
                      loading: loadingPoligonSimulation,
                    },
                    Distrital: {
                      totalInfo: totalSimulationsInfo["Distrital"],
                      pointsData: simulationsPointsData["Distrital"],
                      loading: loadingDistrictalSimulation,
                    },
                  }}
                />
                {/* <OverviewRoute
                  simulations={{
                    "Sem polígono": {
                      data: totalSimulationsInfo["Sem polígono"],
                      loading: loadingNoPoligonSimulation,
                    },
                    "Com polígono": {
                      data: totalSimulationsInfo["Com polígono"],
                      loading: loadingPoligonSimulation,
                    },
                    Distrital: {
                      data: totalSimulationsInfo["Distrital"],
                      loading: loadingDistrictalSimulation,
                    },
                  }}
                /> */}
              </S.Grid>
            </S.Container>
          ) : (
            <S.ContainerBig>
              <MapRoutes
                data={filteredSimulation()}
                filteredRoute={filteredRouteMap}
                googleMapsLoaded
              />

              <S.Grid>
                <S.Header small>
                  {`${capitalizeAllFirstLetters(location.state.region)} > ${
                    location.state.district
                  } > ${capitalizeAllFirstLetters(location.state.pole)}`}
                </S.Header>
                <OverviewRouteScoreCard 
                  simulations={{
                    "Sem polígono": {
                      totalInfo: totalSimulationsInfo["Sem polígono"],
                      pointsData: simulationsPointsData["Sem polígono"],
                      loading: loadingNoPoligonSimulation,
                    },
                    "Com polígono": {
                      totalInfo: totalSimulationsInfo["Com polígono"],
                      pointsData: simulationsPointsData["Com polígono"],
                      loading: loadingPoligonSimulation,
                    },
                    Distrital: {
                      totalInfo: totalSimulationsInfo["Distrital"],
                      pointsData: simulationsPointsData["Distrital"],
                      loading: loadingDistrictalSimulation,
                    },
                  }}
                />
                {/* <OverviewMapRoute
                  filteredRoute={filteredRouteMap}
                  data={filteredSimulation()}
                /> */}
              </S.Grid>
            </S.ContainerBig>
          )}
        </div>
      </PageLayout>
    // </LoadScript>
  );
};

export default Cockpit;
