import React, { useState } from "react";
import { Form, notification } from "antd";
import LogisticService from "@LogisticService";
import Dragger from "@components/CustomDragger";
import CheckIcon from "@assets/icons/CheckIconThird";
import UploadIcon from "@assets/icons/UploadIcon";
import DownloadIcon from "@assets/icons/DownloadIcon";
import TrashIcon from "@assets/icons/TrashIcon";
import GreenBook from "@assets/icons/GreenBook.svg";

import * as S from "./styles";

export default function AnexosMotivos({ loadingLayout, searchAction }) {
  const [dataDragger, setDataDragger] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const postForm = async (data) => {
    if (data) {
      return LogisticService.postMotivosAtrasoParceiro(data)
        .then((response) => {
          if (data) {
            return response;
          }
        })
        .catch((response) => {
          return response;
        });
    }
  };

  const handleOnUpload = (dataFileDragger) => {
    setDataDragger(dataFileDragger);
  };

  const onFinishMultipleCases = async () => {
    let hasError = false;
    await new Promise((resolve) => {
      dataDragger.forEach(async (formObj, index) => {
        setIsLoading(true);
        const res = await postForm(formObj);
        if (res.status !== 200) hasError = true;

        if (index === dataDragger.length - 1) resolve();
      });
    });

    setIsLoading(false);
    messageMultipleReasons(hasError);

    form.resetFields();
    searchAction();
  };

  const messageMultipleReasons = (hasError) => {
    api.info({
      type: hasError ? "warning" : "success",
      message: hasError
        ? `Alguns motivos não foram registrados!`
        : `Motivos registrados com sucesso!`,
      placement: "bottomLeft",
      className: "notification-ginga-design",
      closeIcon: <S.CloseButton>Fechar</S.CloseButton>,
    });
  };

  return (
    <S.Container>
      {contextHolder}
      <S.FileContainer>
        <S.FormStyled form={form} name="form" onFinish={onFinishMultipleCases}>
          <S.TopInfo>
            <div>
              <S.Title>Anexar motivos</S.Title>
              <S.Text>Importe múltiplos motivos para a base de dados.</S.Text>
            </div>
            <S.ButtonCustom
              htmlType="submit"
              disabled={isEmpty}
              icon={<CheckIcon size={18} />}
              loading={isLoading}
            >
              Registrar motivos
            </S.ButtonCustom>
          </S.TopInfo>
          {loadingLayout ? (
            <S.SkeletonBox
              active={loadingLayout}
              size={"small"}
              height="100%"
              width="100%"
              style={{ marginTop: 24 }}
            />
          ) : (
            <S.DropFileContainer>
              <S.FormItem
                name="dragger"
                rules={[
                  {
                    required: true,
                    message: "Nenhum Arquivo CSV/XLSX Selecionado!",
                  },
                ]}
              >
                <Dragger
                  name="dragger"
                  title="Fazer importação da planilha"
                  text="Selecione ou arraste um arquivo XLSX até esta área."
                  handleOnUpload={handleOnUpload}
                  setIsEmpty={setIsEmpty}
                  showUploadList={{
                    removeIcon: <TrashIcon size={19} color="#303742" />,
                  }}
                >
                  <S.UploadButton icon={<UploadIcon size={16} />}>
                    Selecionar arquivo
                  </S.UploadButton>
                </Dragger>
              </S.FormItem>
            </S.DropFileContainer>
          )}
        </S.FormStyled>
      </S.FileContainer>

      <S.DownloadContainer>
        {loadingLayout ? (
          <S.SkeletonBoxArea>
            <S.SkeletonBox
              active={loadingLayout}
              height="5.8125rem"
              width="6.6875rem"
            />
            <S.SkeletonBox
              active={loadingLayout}
              height="0.625rem"
              width="14.875rem"
            />
            <S.SkeletonBox
              active={loadingLayout}
              height="0.625rem"
              width="9.5rem"
            />
          </S.SkeletonBoxArea>
        ) : (
          <>
            <img src={GreenBook} />
            <S.TextBold>
              Preparamos um modelo de planilha para te ajudar!
            </S.TextBold>
          </>
        )}

        <S.DownloadButton
          icon={<DownloadIcon size={16} />}
          onClick={() => window.open("/dist/Gestao Terceira.csv", "_blank")}
          disabled={loadingLayout}
        >
          Baixar modelo
        </S.DownloadButton>
      </S.DownloadContainer>
    </S.Container>
  );
}
