import { Space } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const FormSpace = styled(Space)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const Title = styled.h3`
  margin: 24px 8px 16px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1.2px;
  color: ${Colors.textGreen};
`;
