import { GenIcon } from "react-icons";

function CheckIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 25",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M16.1594 10.7518C16.5746 10.3876 16.616 9.75576 16.2518 9.34057C15.8876 8.92539 15.2557 8.88406 14.8406 9.24826L10.9646 12.6483L9.18627 10.9704C8.78457 10.5914 8.15167 10.6098 7.77266 11.0115C7.39364 11.4132 7.41203 12.0461 7.81373 12.4251L10.2538 14.7274C10.6289 15.0813 11.2117 15.0919 11.5995 14.7518L16.1594 10.7518Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M12 2C6.4768 2 2 6.4768 2 12C2 17.5222 6.47673 22 12 22C17.5222 22 22 17.5222 22 12C22 6.47673 17.5222 2 12 2ZM4 12C4 7.58137 7.58137 4 12 4C16.4177 4 20 7.58144 20 12C20 16.4177 16.4177 20 12 20C7.58144 20 4 16.4177 4 12Z",
        },
      },
    ],
  })(props);
}

export default CheckIcon;
