import React, { useEffect, useState } from "react";
import Colors from "@colors";
import CustomModal from "@components/CustomModal";
import LoadingMask from "@components/LoadingMask";
import SelectCustom from "@components/SelectCustom";
import { getCookie } from "@helper";
import LogisticService from "@LogisticService";
import { useLocation } from "react-router-dom";
import mapColors from "@mapColors";
import { message } from "antd";
import Ferramentas from "./componentes/Ferramentas";
import Filtros from "./componentes/Filtros";
import { Filters } from "./componentes/Filters";
import GestaoPessoas from "./componentes/GestaoPessoas";
import Legenda from "./componentes/Legenda";
import Mapa from "./componentes/Mapa";
import MotivosAtraso from "../../LastMile/Senninha/componentes/Ferramentas/MotivosAtraso";
import { PageLayout } from "../../../Layout/PageLayout";
import { SearchOutlined } from "@ant-design/icons";
// import { useAuthContext } from '../../../stonelog-auth-components';
import { useAuthContext } from "@stonelog/stonelog-auth-components";

import { useHistory } from "react-router-dom";
import axios from "axios";

import * as S from "./styles";

export const svgMarker = (
  text,
  color,
  stroke,
  opacity,
  dashed
) => `data:image/svg+xml;utf8,
    <svg width="30" height="42" ${
      dashed ? 'stroke-dasharray="4"' : ""
    } viewBox="-2 -2 34 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="${color}" stroke="${
  stroke ? stroke : color
}" stroke-width="2" fill-opacity="${
  opacity ? opacity : 1
}" d="M25 13.1472C25 20.4082 14.1892 35 12.5 35C10.8108 35 0 20.4082 0 13.1472C0 5.88621 5.59644 0 12.5 0C19.4036 0 25 5.88621 25 13.1472Z" />
        <text x="12.5" y="16" text-anchor="middle" fill="white" font-size="13" font-family="sans-serif" font-weight="800">${text}</text>
    </svg>
`;

export const svgPolygon = (color) => `data:image/svg+xml;utf8,
    <svg width="30" height="42" viewBox="-2 -2 34 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="${color}" stroke="${color}" stroke-width="2" fill-opacity="0.5" d="M4 21L22.3054 1.52978L31.0771 15L31.0771 34.0115L10 34.0115L4 21Z" />
    </svg>
`;

const inicialCenter = { lat: -18.0, lng: -54.0 };
const inicialZoom = 5;

export default function Senninha(props) {
  const [loading, setLoading] = useState(false);
  const [hunterLoading, setHunterLoading] = useState(false);
  const [regioes, setRegioes] = useState([]);
  const [seriais, setSeriais] = useState([]);
  const [hunterAtivo, setHunterAtivo] = useState(undefined);
  const [stonecodeHunter, setStonecodeHunter] = useState(undefined);
  const [distritos, setDistritos] = useState([]);
  const [polos, setPolos] = useState([]);
  const [angelsDisponiveis, setAngelsDisponiveis] = useState([]);
  const [center, setCenter] = useState(inicialCenter);
  const [zoom, setZoom] = useState(inicialZoom);
  const [pins, setPins] = useState(null);
  const [unfilteredPins, setUnfilteredPins] = useState(false);
  const [modo, setModo] = useState("mapa");
  const [stonecodesRealocacao, setStonecodesRealocacao] = useState([]);
  const [ossEE, setOSsEE] = useState(undefined);
  const [defeitos, setDefeitos] = useState([]);
  const [solucoes, setSolucoes] = useState([]);
  const [ossAtrasadas, setAtrasadas] = useState(undefined);
  const [areas, setAreas] = useState([]);
  const [areasRisco, setAreasRisco] = useState([]);
  const [visualizarAreaRisco, setVisualizarAreaRisco] = useState([]);
  const [areasAngels, setAreasAngels] = useState([]);
  const [angelRealocacao, setAngelRealocacao] = useState([]);
  const [resultadoOpcao, setResultadoOpcao] = useState(null);
  const [troca1, setTroca1] = useState([]);
  const [troca2, setTroca2] = useState([]);
  const [angelPoligono, setAngelPoligono] = useState(null);
  const [novoPoligono, setNovoPoligono] = useState(null);
  const [editando, setEditando] = useState(false);
  const [cores, setCores] = useState({});
  const [indexCorCurr, setIndexCorCurr] = useState(1);
  const [legenda, setLegenda] = useState([]);
  const [animacaoPin, setAnimacaoPin] = useState(null);
  const [centralizar, setCentralizar] = useState(false);
  const [openModalPessoas, setOpenModalPessoas] = useState(false);
  const [openModalAtrasos, setOpenModalAtrasos] = useState(false);
  const [showFerramentas, setShowFerramentas] = useState(false);
  const [showPinOptions, setShowPinOptions] = useState(false);
  const [gestaoData, setGestaoData] = useState(false);
  const [atrasosData, setAtrasosData] = useState(false);
  const [editPoloSelected, setEditPoloSelected] = useState(true);
  const [selectedDistritoNome, setSelectedDistritoNome] = useState("");

  const [selectedRegiao, setSelectedRegiao] = useState(null);
  const [selectedDistrito, setSelectedDistrito] = useState(null);
  const [selectedPolo, setSelectedPolo] = useState(null);

  const [selectedRegiaoNome, setSelectedRegiaoNome] = useState("");
  const [selectedPoloNome, setSelectedPoloNome] = useState("");

  const [disabledRegioes, setDisabledRegioes] = useState(true);
  const [disabledDistritos, setDisabledDistritos] = useState(true);
  const [disabledPolos, setDisabledPolos] = useState(true);

  const [resetOptions, setResetOptions] = useState(1);

  const [changeCount, setChangeCount] = useState(0);
  const [changeTotal, setChangeTotal] = useState(0);
  const [changeError, setChangeError] = useState(false);
  const [changeErrorText, setChangeErrorText] = useState("");

  const [ocultaFiltros, setOcultaFiltros] = useState(true);
  const [stonecodesErroEmRealocar, setStonecodesErroEmRealocar] = useState([]);
  const [nomeErroEmRealocar, setNomeErroEmRealocar] = useState({});

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [loadingPins, setLoadingPins] = useState(false);
  const [loadingAngelsDisp, setLoadingAngelsDisp] = useState(false);
  const [loadingAreaRisco, setLoadingAreaRisco] = useState(false);
  const [sourceAxios, setSourceAxios] = useState("");

  useEffect(() => setSourceAxios(axios.CancelToken.source()), []);

  const location = useLocation();
  const auth = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    if (location.state && regioes) {
      setLoading(true);
      let regiao = regioes.find((item) => item.name === location.state.region);
      if (regiao && distritos.length === 0) {
        regiao = { key: regiao.id, id: regiao.id, children: regiao.name };
        onChangeRegiao(regiao.id, regiao);
      }
      if (distritos.length > 0 && polos.length === 0) {
        let distrito = distritos.find(
          (item) => item.name === location.state.district
        );
        distrito = {
          key: distrito.id,
          id: distrito.id,
          children: distrito.name,
        };
        onChangeDistrito(distrito.id, distrito);
      }
      if (polos.length > 0) {
        let polo = polos.find((item) => item.name === location.state.pole);
        if (!polo) {
          polo = polos[0];
        }
        setSelectedPolo(polo?.id);
        onChangePolo(polo?.id);
      }
    }
  }, [location, regioes, distritos, polos]);

  const noAngel = [
    `Não alocadas - Vencidas`,
    `Não alocadas - Vencem hoje`,
    `Não alocadas - Vencimento futuro`,
    `COORDENADAS ZERADAS`,
    `Baixadas`,
    `Área de Risco`,
    `Permitido Moto`,
    `Proibido Carro`,
  ];

  let refPins = [];
  let refLegenda = [];

  useEffect(() => {
    LogisticService.getRegioes()
      .then((response) => {
        setDisabledRegioes(false);
        setRegioes(response.data);
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status == 401) {
          window.location.href = "/login";
        }
      });
  }, []);

  const onChangeRegiao = (value, component) => {
    setSelectedRegiao(value);
    setSelectedDistrito(null);
    setSelectedPolo(null);
    setDisabledRegioes(true);
    setDisabledDistritos(true);
    setDisabledPolos(true);
    setSelectedRegiaoNome("");
    setSelectedPoloNome("");
    setPins(false);
    setLegenda([]);
    setOSsEE(undefined);
    setAtrasadas(undefined);
    setDefeitos([]);
    setSolucoes([]);
    setModo("mapa");
    setShowFerramentas(false);
    setShowPinOptions(false);
    LogisticService.getDistritos(value).then((response) => {
      setSelectedRegiaoNome(
        selectedRegiaoNome ? selectedRegiaoNome : component.children
      );
      setDisabledRegioes(false);
      setDisabledDistritos(false);
      setDistritos(response.data);
    });
  };

  const onChangeDistrito = (value, component) => {
    setSelectedDistrito(value);
    setDisabledDistritos(true);
    setDisabledPolos(true);
    setSelectedPolo(null);
    setSelectedPoloNome("");
    setPins(false);
    setLegenda([]);
    setOSsEE(undefined);
    setAtrasadas(undefined);
    setDefeitos([]);
    setSolucoes([]);
    setModo("mapa");
    setShowFerramentas(false);
    setShowPinOptions(false);
    LogisticService.getPolosPorDistrito(value).then((polos) => {
      setSelectedDistritoNome(
        selectedDistritoNome ? selectedDistritoNome : component.children
      );
      setPolos(polos.data);
      setDisabledDistritos(false);
      setDisabledPolos(false);
    });
  };

  const escolheCor = (id) => {
    const length = Object.keys(mapColors).length;
    let index = id;
    while (index > length) {
      index -= length;
    }
    return mapColors[index].replace("#", "%23");
  };

  const onChangePolo = (value) => {
    setEditPoloSelected(false);
    setSelectedPolo(null);
    setSelectedPolo(value);
    setLoading(true);
    setCentralizar(true);
    setSelectedPoloNome("");
    setPins(false);
    setLegenda([]);
    setOSsEE(undefined);
    setAtrasadas(undefined);
    setDefeitos([]);
    setSolucoes([]);
    carregarAreasRisco();
    setModo("mapa");
    setAngelsDisponiveis([]);
    setShowFerramentas(false);
    setShowPinOptions(false);
    setResetOptions(resetOptions + 1);
    LogisticService.getPoloPorId(value).then((polo) => {
      setSelectedPoloNome(polo.data);
      carregarAtrasosAmanha(polo.data);
      carregarOssEE();
      LogisticService.getAlertaGestaoPessoas(polo.data).then((response) => {
        if (response.data.length === 0) {
          renderPins(polo.data);
          setShowFerramentas(true);
          setLoading(false);
        } else if (response.data.length !== 0) {
          setGestaoData(response.data);
          setOpenModalPessoas(true);
        }
      });
    });
  };

  const onFinishGestao = () => {
    renderPins(selectedPoloNome);
    setShowFerramentas(true);
    setLoading(false);
  };

  const onFinishAtrasos = () => {
    carregarAtrasosAmanha();
  };

  useEffect(() => {
    if (pins !== null && showFerramentas) setShowPinOptions(pins.length > 0);
  }, [pins, showFerramentas]);

  useEffect(() => {
    const nowDate = new Date();
    const validateHour =
      nowDate.getHours() >= 11 ||
      (nowDate.getHours() == 10 && nowDate.getMinutes() >= 30);
    if (atrasosData && atrasosData.length !== 0 && validateHour) {
      setOpenModalAtrasos(true);
    } else {
      setOpenModalAtrasos(false);
    }
  }, [atrasosData]);

  const carregarAreasRisco = () => {
    setLoadingAreaRisco(true);
    LogisticService.getAreasRisco(selectedDistrito).then((result) => {
      setVisualizarAreaRisco(result.data);
      setLoadingAreaRisco(false);
    });
  };
  const carregarAreas = () => {
    LogisticService.getAreas(selectedDistrito).then((result) => {
      renderAreas(result.data);
    });
  };

  const salvarPoligono = () => {
    setResultadoOpcao(false);
    setAreas([]);
    LogisticService.postArea({
      nome: angelPoligono,
      distrito: selectedDistrito,
      coordenadas: novoPoligono,
    }).then((result) => {
      setResultadoOpcao(result);
      LogisticService.getAreas(selectedDistrito).then((result) => {
        renderAreas(result.data);
      });
    });
  };

  const editarPoligono = (id) => {
    setResultadoOpcao(false);
    LogisticService.postArea({
      id: id,
      nome: angelPoligono,
      distrito: selectedDistrito,
      coordenadas: novoPoligono,
    }).then((result) => {
      setResultadoOpcao(result);
      LogisticService.getAreas(selectedDistrito).then((result) => {
        renderAreas(result.data);
      });
    });
  };

  const deletarPoligono = (id) => {
    setResultadoOpcao(false);
    LogisticService.deleteArea(id).then((result) => {
      setResultadoOpcao(result);
      LogisticService.getAreas(selectedDistrito).then((result) => {
        renderAreas(result.data);
      });
    });
  };

  const carregarOssEE = () => {
    LogisticService.getOssEuEntrego(selectedPoloNome).then((result) => {
      setOSsEE(result.data.oss);
      setDefeitos(result.data.defects);
      setSolucoes(result.data.solutions);
    });
  };

  const carregarAtrasos = (poloName = selectedPoloNome) => {
    LogisticService.getMotivosAtrasoDistrital(poloName).then((result) => {
      setAtrasadas(result.data);
    });
  };

  const carregarAtrasosAmanha = (poloName = selectedPoloNome) => {
    const nowDate = new Date();
    const validateHour =
      nowDate.getHours() >= 11 ||
      (nowDate.getHours() == 10 && nowDate.getMinutes() >= 30);
    if (validateHour) {
      LogisticService.getMotivosAtrasoDistritalAmanha(poloName).then(
        (result) => {
          setAtrasosData(result.data);
        }
      );
    }
  };

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  const realocarStonecodes = (stonecodes) => {
    setChangeCount(0);
    setChangeTotal(0);
    setChangeError(false);
    let stonecodesComErro = [];
    let stonecodesComOsErrosMapeados = [];
    let counter = 0;
    console.log(angelsDisponiveis);
    console.log(angelRealocacao);
    const angel = angelsDisponiveis.filter(
      (a) => a.value == angelRealocacao || a.name == angelRealocacao
    );
    const ordens = pins.filter((r) => stonecodes.indexOf(r.stonecode) > -1);
    setChangeTotal(ordens.length);
    console.log(ordens);
    ordens.forEach((element) => {
      LogisticService.postRealocaOSsCancel(
        { polo: selectedPoloNome, angel: angel[0], oss: element.oss },
        { cancelToken: sourceAxios.token }
      )
        .then((result) => {
          if (result && result.status) {
            counter = counter + 1;
            if (stonecodes.length == counter) {
              sleep(5000);
            }
            setChangeCount(counter);
          }
        })
        .catch((err) => {
          stonecodesComErro.push(element.stonecode);
          stonecodesComOsErrosMapeados.push({
            stonecode: element.stonecode,
            erro: err?.response?.data?.erro
              ? err?.response?.data?.erro
              : "Não foram alterados no mapa, tente novamente.",
          });
          counter = counter + 1;
          console.log(err);
          setChangeCount(counter);
          setChangeError(true);
          if (err?.response?.status == 400) {
            setChangeErrorText(
              `por motivo de ${err?.response?.data?.erro}` ??
                "não foram alterados no mapa."
            );
          } else {
            setChangeErrorText("não foram alterados no mapa.");
          }
        });
      setStonecodesErroEmRealocar(stonecodesComErro);
      setNomeErroEmRealocar(stonecodesComOsErrosMapeados);
    });
  };

  useEffect(() => {
    setResultadoOpcao({
      done: changeCount,
      total: changeTotal,
      error: changeError,
      errorText: changeErrorText,
    });
  }, [changeCount, changeTotal, changeError, changeErrorText]);

  const trocarRotas = () => {
    setResultadoOpcao(false);
    LogisticService.postTrocaRotas({
      polo: selectedPoloNome,
      primeiro_email: troca1,
      segundo_email: troca2,
    }).then((result) => {
      setResultadoOpcao(result);
    });
  };

  const adicionarOSsCampanha = () => {
    setResultadoOpcao(false);
    LogisticService.postAdicionarOSsCampanha({ polo: selectedPoloNome }).then(
      (result) => {
        setResultadoOpcao(result);
      }
    );
  };

  function getSeriaisHunter(stonecode) {
    setLoading(true);
    LogisticService.getSeriais(stonecode).then((result) => {
      if (result.status == 200) {
        setLoading(false);
        setSeriais(result.data);
      }
    });
  }

  function setSeriaisHunter(seriais, stonecode) {
    setLoading(true);
    LogisticService.abreOsCampanha(stonecode, {
      polo: selectedPoloNome,
      seriais: seriais,
      usuario: auth.getUser().email,
    }).then((result) => {
      if (result.data.sucesso == "Sucesso ao abrir Os de Campanha.") {
        message.success(result.data.sucesso);
        setLoading(false);
        reset();
        setStonecodeHunter(false);
        setHunterAtivo(false);
      } else {
        message.error(result.data.erro);
        setLoading(false);
        reset();
        setStonecodeHunter(false);
        setHunterAtivo(false);
      }
    });
    setStonecodeHunter(undefined);
    setHunterAtivo(undefined);
  }

  const reset = () => {
    setStonecodesRealocacao([]);
    setOSsEE(undefined);
    setAtrasadas(undefined);
    setDefeitos([]);
    setSolucoes([]);
    setLegenda([]);
    setAreas([]);
    setNovoPoligono(null);
    setResultadoOpcao(null);
    setAngelRealocacao(null);
    setAngelPoligono(null);
    setTroca1(null);
    setTroca2(null);
    setEditando(false);
    renderPins(selectedPoloNome);
    setIndexCorCurr(1);
  };

  const onEdit = () => {
    if (location.state) history.replace();
    setDisabledRegioes(false);
    setDisabledDistritos(false);
    setDisabledPolos(false);
    setSelectedRegiaoNome("");
    setSelectedPoloNome("");
    setPins(false);
    setModo("mapa");
    setShowFerramentas(false);
    setShowPinOptions(false);
    renderPins(null);
    onChangeRegiao(selectedRegiao);
    onChangeDistrito(selectedDistrito);
    setSelectedRegiao(selectedRegiao);
    setSelectedDistrito(selectedDistrito);
    setSelectedPolo(null);
    setEditPoloSelected(true);
    setStonecodesRealocacao([]);
    setOSsEE(undefined);
    setAtrasadas(undefined);
    setDefeitos([]);
    setSolucoes([]);
    setLegenda([]);
    setAreas([]);
    setNovoPoligono(null);
    setResultadoOpcao(null);
    setAngelRealocacao(null);
    setAngelPoligono(null);
    setTroca1(null);
    setTroca2(null);
    setEditando(false);
    setDistritos([]);
    setPolos([]);
    setAreasRisco([]);
    setVisualizarAreaRisco([]);
    setOcultaFiltros(!ocultaFiltros);
  };

  const toggleAreasRisco = () => {
    let array = areas;
    areasRisco.map((item) => {
      const position = array.indexOf(item);
      if (position === -1) {
        array.push(item);
      } else {
        array.splice(position, 1);
      }
    });
    setAreas([...array]);
  };

  const toggleAreasAngels = () => {
    let array = areas;
    areasAngels.map((item) => {
      const position = array.indexOf(item);
      if (position === -1) {
        array.push(item);
      } else {
        array.splice(position, 1);
      }
    });
    setAreas([...array]);
  };

  useEffect(() => {
    setResultadoOpcao(null);
    carregarAreasRisco();
    if (modo === "confirmar") {
      if (stonecodesRealocacao.length > 0) {
        realocarStonecodes(stonecodesRealocacao);
      }
      setOSsEE(undefined);
      setAtrasadas(undefined);
    } else if (modo === "confirmar_troca_rotas") {
      trocarRotas();
      setOSsEE(undefined);
    } else if (modo === "campanha") {
      adicionarOSsCampanha();
    } else if (modo === "poligonos") {
      carregarAreas();
    } else if (modo === "salvar_poligono") {
      salvarPoligono();
    } else if (modo === "oss_eu_entrego") {
      carregarOssEE();
    } else if (modo === "oss_atrasadas") {
      carregarOssEE();
      carregarAtrasos();
      setAtrasadas(undefined);
    }
  }, [modo]);

  const loadRotas = (data, index) => {
    let latMax = data[0].lat;
    let latMin = data[0].lat;
    let lngMax = data[0].lng;
    let lngMin = data[0].lng;
    let indexCor = indexCorCurr;
    let legendaData = [];
    let cor, icon;
    let isHunter = false;

    let sumLat = 0,
      sumLng = 0,
      count = 0;

    data.map((item) => {
      if (item.lat !== 0 && item.lng !== 0) {
        sumLat += item.lat;
        sumLng += item.lng;
        count++;
      }
    });

    const mediaLat = sumLat / count,
      mediaLng = sumLng / count;

    const markers = data.map((item) => {
      index += 1;

      const isNotAngel = noAngel.includes(item.oss[0].angel);
      const coordenadasZeradas = item.lat === 0 || item.lng === 0;

      let latitude, longitude;

      if (coordenadasZeradas) {
        latitude = mediaLat;
        longitude = mediaLng;
      } else {
        latitude = item.lat;
        longitude = item.lng;
        if (latitude > latMax) latMax = latitude;
        if (latitude < latMin) latMin = latitude;
        if (longitude > lngMax) lngMax = longitude;
        if (longitude < lngMin) lngMin = longitude;
      }

      if (coordenadasZeradas) {
        cor = Colors.deepBlue.replace("#", "%23");
      } else if (item.oss[0].angel in cores) {
        cor = cores[item.oss[0].angel];
      } else {
        if (item.oss[0].angel === noAngel[0]) {
          cor = Colors.dangerRed.replace("#", "%23");
        } else if (item.oss[0].angel === noAngel[1]) {
          cor = Colors.cautionYellow.replace("#", "%23");
        } else if (item.oss[0].angel === noAngel[2]) {
          cor = Colors.lightGreen.replace("#", "%23");
        } else if (item.oss[0].angel === noAngel[3]) {
          cor = Colors.darkGreen.replace("#", "%23");
        } else {
          cor = escolheCor(indexCor++);
        }
        cores[item.oss[0].angel] = cor;
      }

      if (coordenadasZeradas)
        icon = {
          pin: true,
          text: "!",
          cor: cor,
          stroke: Colors.cautionYellow.replace("#", "%23"),
        };
      else if (isNotAngel)
        icon = {
          pin: true,
          text: item.oss[0].servico.slice(0, 2).toUpperCase(),
          cor: cor,
          stroke: cor,
          opacity: 0.4,
        };
      else
        icon = {
          pin: true,
          text: item.oss[0].servico.slice(0, 2).toUpperCase(),
          cor: cor,
        };

      if (
        item.oss[0].angel == "Hunter STONE" ||
        item.oss[0].angel == "Hunter STONE (Com Ineficiência)"
      ) {
        isHunter = true;
        
        if (item.oss[0].prioridade == "media") {
          icon.opacity = 0.6;
        }
        if (item.oss[0].prioridade == "baixa") {
          icon.opacity = 0.2;
        }
        if (item.oss[0].ineficiencia !== null) {
          icon.stroke = Colors.cautionYellow.replace("#", "%23");
        }
      }

      if (
        (item.oss[0].angel == "Hunter TON" ||
        item.oss[0].angel == "Hunter TON (Com ineficiência)") &&
        item.oss[0].contratante === "TON"
        ) {
          isHunter = true;
          icon.cor = "%231a8e52";
          if (item.oss[0].ineficiencia !== null) {
            icon.stroke = Colors.cautionYellow.replace("#", "%23");
          }
        }
        if (item.oss[0].servico === "CAÇA POS") {
        // icon.cor = "%231a8e88";
        icon.text = "CP"
          console.log(icon)
      }

      const adicionaLegenda = (nome) => {
        const indexLegenda = legendaData.findIndex((el) => el.nome === nome);
        if (indexLegenda >= 0) {
          legendaData[indexLegenda].oss_count += item.oss.length;
          legendaData[indexLegenda].oss.push({
            id: index,
            stonecode: item.stonecode,
            latitude: latitude,
            longitude: longitude,
            items: item.oss,
          });
        } else {
          legendaData.push({
            icon,
            nome: nome,
            visivel: !isHunter,
            visibilidade: true,
            oss_count: item.oss.length,
            oss: [
              {
                id: index,
                stonecode: item.stonecode,
                latitude: latitude,
                longitude: longitude,
                items: item.oss,
              },
            ],
            stonecode: item.stonecode,
          });
        }
      };

      adicionaLegenda(item.oss[0].angel);
      if (coordenadasZeradas) adicionaLegenda("COORDENADAS ZERADAS");

      return {
        id: index,
        stonecode: item.stonecode,
        latitude: latitude,
        longitude: longitude,
        servico: item.oss[0].servico,
        oss: item.oss,
        marcado: false,
        visivel: !isHunter,
        filtrado: true,
        icon,
        area_risco: item.oss[0].area_risco,
      };
    });

    legendaData.sort(function (x, y) {
      return noAngel.includes(x.nome) ? -1 : noAngel.includes(y.nome) ? 1 : 0;
    });

    setIndexCorCurr(indexCor + 1);
    setLegenda([...refLegenda, ...legendaData]);
    refLegenda = [...legendaData];

    let maiorDistancia = latMax - latMin;
    if (lngMax - lngMin > maiorDistancia) {
      maiorDistancia = lngMax - lngMin;
    }
    let zoom = 12;
    if (maiorDistancia > 3.0) {
      zoom = 5;
    } else if (maiorDistancia > 0.1) {
      zoom = 11 - Math.round(maiorDistancia / 0.6);
    }
    if (centralizar && !isHunter) {
      setCenter({ lat: (latMax + latMin) / 2, lng: (lngMax + lngMin) / 2 });
      setZoom(zoom);
    }
    setUnfilteredPins(true);

    setPins([...refPins, ...markers]);
    refPins = [...markers];
  };

  const renderPins = (polo) => {
    setLoadingPins(true);
    setLoadingAngelsDisp(true);
    LogisticService.getRotas(polo).then((details) => {
      if (
        details.data &&
        details.data !== undefined &&
        details.data.length > 0
      ) {
        setHunterLoading(true);
        loadRotas(details.data, 0);
        LogisticService.getRotasZeroTPV(polo).then((d) => {
          LogisticService.getRotasZeroTPVTon(polo).then((dTon) => {
            const stone0TPV = d.data;
            const ton0TPV = dTon.data;
            const os0TPV = [...stone0TPV, ...ton0TPV];

            setHunterLoading(false);
            if (d.data && d.data !== undefined && d.data.length > 0) {
              loadRotas(os0TPV, details.data.length);
            }
          });
        });
        setLoadingPins(false);
        setCentralizar(false);
      }

      LogisticService.getAngelsDisponiveis(polo).then((angels) => {
        let list = [];
        angels.data.map((angel, index) => {
          list.push({
            id: index,
            name: angel.nome,
            value: angel.email,
            document_number: angel.document_number,
          });
        });
        setAngelsDisponiveis([...list]);
        setLoadingAngelsDisp(false);
      });
    });
  };

  const renderAreas = (data) => {
    let legendaData = [];
    let cor;
    let riscoList = [];
    let angelsList = [];
    let indexCor = indexCorCurr;
    let icon;
    const areas = data.map((item, index) => {
      if (item.name in cores) {
        cor = cores[item.name];
      } else {
        if (item.name === noAngel[4]) {
          cor = Colors.risk.replace("#", "%23");
        } else if (item.name === noAngel[5]) {
          cor = Colors.allowed.replace("#", "%23");
        } else if (item.name === noAngel[6]) {
          cor = Colors.prohibited.replace("#", "%23");
        
        } else {
          cor = escolheCor(indexCor++);
        }
        cores[item.name] = cor;
      }
      icon = { pin: false, cor: cor };
      const indexLegenda = legendaData.findIndex((el) => el.nome === item.name);
      if (indexLegenda === -1) {
        legendaData.push({ icon, nome: item.name });
      }
      const linha = { id: item.id, bounds: item.bounds, nome: item.name, cor };
      if (noAngel.includes(item.name)) {
        riscoList.push(linha);
      } else {
        angelsList.push(linha);
      }
      return linha;
    });

    legendaData.sort(function (x, y) {
      return noAngel.includes(x.nome) ? -1 : noAngel.includes(y.nome) ? 1 : 0;
    });
    setAreas(areas);
    setAreasRisco(riscoList);
    setAreasAngels(angelsList);
    setLegenda(legendaData);
  };

  return (
    <PageLayout
      titlePage={" |  Senninha"}
      showHamburguerIcon={true}
      setMenuIsOpen={setMenuIsOpen}
    >
      <S.PageAll menuIsOpen={menuIsOpen}>
        {editPoloSelected ? (
          <S.HubSelect>
            <S.BoxContent>
              <div>
                <S.TitleBox>Para começar, preencha os campos.</S.TitleBox>
              </div>
              <div>
                <S.ContentSelect>
                  <SelectCustom
                    title={"Região"}
                    placeholder="Selecione uma opção"
                    options={regioes}
                    onChange={onChangeRegiao}
                    disabled={disabledRegioes}
                    value={selectedRegiao}
                  />
                  <SelectCustom
                    title={"Distrito"}
                    placeholder="Selecione uma opção"
                    options={distritos}
                    onChange={onChangeDistrito}
                    disabled={disabledDistritos}
                    value={selectedDistrito}
                  />
                  <SelectCustom
                    title={"Polo"}
                    placeholder="Selecione uma opção"
                    options={polos}
                    onChange={(value) => setSelectedPolo(value)}
                    disabled={disabledPolos}
                  />
                </S.ContentSelect>
              </div>
              <div>
                <S.ButtonBox
                  disabled={selectedPolo ? false : true}
                  onClick={() => {
                    onChangePolo(selectedPolo);
                  }}
                  icon={<SearchOutlined />}
                >
                  Buscar
                </S.ButtonBox>
              </div>
            </S.BoxContent>
          </S.HubSelect>
        ) : (
          <></>
        )}
        {loading && <LoadingMask fullScreen />}
        <div style={{ display: "flex", position: "relative", width: "100%" }}>
          <Mapa
            center={center}
            zoom={zoom}
            pins={pins}
            setPins={setPins}
            modo={modo}
            animacaoPin={animacaoPin}
            setStonecodesRealocacao={setStonecodesRealocacao}
            stonecodesRealocacao={stonecodesRealocacao}
            areas={areas}
            setNovoPoligono={setNovoPoligono}
            editarPoligono={editarPoligono}
            deletarPoligono={deletarPoligono}
            setEditando={setEditando}
            editando={editando}
            angelsDisponiveis={angelsDisponiveis}
            setAngelPoligono={setAngelPoligono}
            getSeriais={getSeriaisHunter}
            seriais={seriais}
            hunterAtivo={hunterAtivo}
            loading={loading}
            setSeriais={(value) => {
              setSeriaisHunter(value);
            }}
            selectedDistrito={selectedDistrito}
            visualizarAreaRisco={visualizarAreaRisco}
            stonecodeHunter={stonecodeHunter}
          />

          {selectedPolo && resetOptions && !editPoloSelected && (
            <Ferramentas
              onEdit={onEdit}
              setEditPoloSelected={setEditPoloSelected}
              polo={selectedPoloNome}
              poloId={selectedPolo}
              regiaoNome={selectedRegiaoNome}
              distritoNome={selectedDistritoNome}
              angelsDisponiveis={angelsDisponiveis}
              setModo={setModo}
              modo={modo}
              stonecodesRealocacao={stonecodesRealocacao}
              angelRealocacao={angelRealocacao}
              setAngelRealocacao={setAngelRealocacao}
              setAngelPoligono={setAngelPoligono}
              novoPoligono={novoPoligono}
              reset={reset}
              resultadoOpcao={resultadoOpcao}
              setTroca1={setTroca1}
              setTroca2={setTroca2}
              editando={editando}
              toggleAreasRisco={toggleAreasRisco}
              toggleAreasAngels={toggleAreasAngels}
              ossEE={ossEE}
              defeitos={defeitos}
              solucoes={solucoes}
              ossAtrasadas={ossAtrasadas}
              carregarAtrasos={carregarAtrasos}
              polos={polos}
              resetOptions={resetOptions}
              setOcultaFiltros={setOcultaFiltros}
              ocultaFiltros={ocultaFiltros}
              pins={pins}
              setPins={setPins}
              setStonecodesRealocacao={setStonecodesRealocacao}
              stonecodesErroEmRealocar={stonecodesErroEmRealocar}
              realocarStonecodes={realocarStonecodes}
              loadingCockpit={
                loadingPins || loadingAngelsDisp || loadingAreaRisco
              } // add aq o loading area de risco
              legenda={legenda}
              sourceAxios={sourceAxios}
              setSourceAxios={setSourceAxios}
              nomeErroEmRealocar={nomeErroEmRealocar}
              visualizarAreaRisco={visualizarAreaRisco}
            />
          )}
          <S.BottomComponents>
            {/* {showPinOptions && <Filtros
                            pins={pins}
                            setPins={setPins}
                            unfiltered={unfilteredPins}
                            setUnfiltered={setUnfilteredPins}
                            ocultaFiltros={ocultaFiltros}
                            show={!(["salvar_poligono", "poligonos", "desenhar"]).includes(modo)}
                        />
                        } */}
            {showPinOptions && (
              <Filters
                pins={pins}
                setPins={setPins}
                unfiltered={unfilteredPins}
                setUnfiltered={setUnfilteredPins}
              />
            )}

            {legenda.length > 0 && (
              <Legenda
                data={legenda}
                setData={setLegenda}
                reset={reset}
                setCenter={setCenter}
                setZoom={setZoom}
                setAnimacaoPin={setAnimacaoPin}
                pins={pins}
                setPins={setPins}
                hunterLoading={hunterLoading}
              />
            )}
          </S.BottomComponents>
          <CustomModal
            title={"Gestão de Pessoas"}
            visible={openModalPessoas}
            zIndex={1010}
            width={800}
            footer={false}
          >
            <GestaoPessoas
              visible={openModalPessoas}
              onFinishGestao={onFinishGestao}
              poloAntigo={selectedPolo}
              data={gestaoData}
              setVisible={setOpenModalPessoas}
            />
          </CustomModal>
          <CustomModal
            title={"Motivos de atraso"}
            visible={openModalAtrasos}
            zIndex={1010}
            width={`98vw`}
            footer={false}
          >
            <MotivosAtraso
              onSave={onFinishAtrasos}
              getMotivos={LogisticService.getMotivosAtrasoDistritalAmanha}
              configuracoes={atrasosData}
              defeitos={defeitos}
              solucoes={solucoes}
              polo={selectedPoloNome}
              angelsDisponiveis={angelsDisponiveis}
            />
          </CustomModal>
        </div>
      </S.PageAll>
    </PageLayout>
  );
}
