/* eslint-disable */
import React, { useState } from "react";
import Container from "@components/Container";
import CustomLink from "@components/CustomLink";
import InfoBox from "@components/InfoBox";
import LoadingSpinner from "@components/LoadingSpinner";
import Row from "@components/Row";
import SearchClientPage from "@components/SearchClientPage";
import CustomModal from "@components/CustomModal";
import { Form, Input } from "antd";
import "antd/dist/antd.css";
import SubmitButton from "@components/SubmitButton";
import Contrato from "./graficos/Contrato";
import EnderecosCliente from "./graficos/EnderecosCliente";
import InformacoesContatoHunter from "./graficos/InformacoesContato";
import InformacoesPrincipais from "./graficos/InformacoesPrincipais";
import OrdensServico from "./graficos/OrdensServico";
import SalesforceCasos from "./graficos/SalesforceCasos";
import HistoricoOs from "./graficos/HistoricoOsHunter";
import { ImportantInfo, Link, ModalContainer } from "./styles.js";
import LogisticService from "../../../consumingApi/services/LogisticService";

const Hunter = () => {
  const [stonecode, setStonecode] = useState(null);
  const [isOwnerModalVisible, setIsOwnerModalVisible] = useState(false);
  const [successfully, setSuccessfully] = useState("wait");
  const [form] = Form.useForm();
  localStorage.setItem("previous_page", window.location.href);

  const postForm = async (data) => {
    if (data) {
      return LogisticService.postOwnerId(data.email, {
        id_dono: data.id_dono,
        email: data.email,
      })
        .then((res) => {
          if (res) {
            return res.status;
          }
        })
        .catch((res) => {
          return res;
        });
    }
  };

  const onFinish = async (data) => {
    const status = await postForm(data);

    if (status === 200) {
      setSuccessfully("ok");
      setTimeout(() => {
        setIsOwnerModalVisible(false);
        setSuccessfully("wait");
      }, 1200);
      form.resetFields();
    } else {
      setSuccessfully("erro");
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    }
  };

  return (
    <>
      <SearchClientPage
        setStonecode={setStonecode}
        pageTitle={"Sistema do Caça"}
        setIsOwnerModalVisible={setIsOwnerModalVisible}
      >
        {stonecode ? (
          <>
            <Row horizontalAlign={"space-between"} verticalAlign={"stretch"}>
              <InformacoesPrincipais
                stonecode={stonecode}
                setStonecode={setStonecode}
              />
            </Row>
            <Row horizontalAlign={"space-between"} verticalAlign={"stretch"}>
              <EnderecosCliente stonecode={stonecode} />
            </Row>
            <Row horizontalAlign={"space-between"} verticalAlign={"stretch"}>
              <Contrato stonecode={stonecode} />
            </Row>
            <Row horizontalAlign={"space-between"} verticalAlign={"stretch"}>
              <InformacoesContatoHunter stonecode={stonecode} />
            </Row>
            <Row horizontalAlign={"space-between"} verticalAlign={"stretch"}>
              <OrdensServico stonecode={stonecode} />
            </Row>
            <Row horizontalAlign={"space-between"} verticalAlign={"stretch"}>
              <HistoricoOs stonecode={stonecode} />
            </Row>
            <Row horizontalAlign={"space-between"} verticalAlign={"stretch"}>
              <SalesforceCasos stonecode={stonecode} />
            </Row>
          </>
        ) : (
          <Row horizontalAlign={"center"}>
            <Container grid={1 / 6}>
              <LoadingSpinner />
            </Container>
          </Row>
        )}
      </SearchClientPage>

      <CustomModal
        title="Cadastro Owner Id"
        visible={isOwnerModalVisible}
        zIndex={1010}
        onCancel={() => {
          setIsOwnerModalVisible(false);
          form.resetFields();
        }}
        hideFooter
      >
        <ModalContainer>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onClose={() => form.resetFields()}
            onFinish={onFinish}
          >
            <Form.Item
              label="Id:"
              name="id_dono"
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email:"
              name="email"
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Input />
            </Form.Item>

            <Row horizontalAlign="center">
              <SubmitButton
                verifyer={successfully}
                style={{ width: 180, justifyContent: "center" }}
                textSuccess="Sucesso!"
                textError="Falha ao processar!"
              >
                Salvar
              </SubmitButton>
            </Row>
          </Form>
        </ModalContainer>
      </CustomModal>
    </>
  );
};

export default Hunter;
