import styled, { css } from "styled-components";
import Colors from "@colors";

export const RowBox = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: row;
  align-items: ${(props) => props.verticalAlign || "start"};
  justify-content: ${(props) => props.horizontalAlign || "space-between"};
  width: ${(props) => props.width || "auto"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => (props.color ? props.color : "transparent")};
  ${(props) => (props.relative ? "position: relative;" : "")}
  ${(props) => (props.wrap ? "flex-wrap: wrap;" : "")}
  ${(props) =>
    props.horizontalScroll ? "overflow-x: scroll;" : ""}
  ${(props) =>
    props.fullSize &&
    css`
      .ant-row {
        width: 100%;
      }
    `}
  @media (max-width: 1024px) {
    align-items: ${(props) =>
      props.horizontalAlignResponsive
        ? props.horizontalAlignResponsive
        : props.verticalAlign
        ? props.verticalAlign
        : "start"};
    flex-direction: ${(props) => (props.nonResponsive ? "row" : "column")};
    ${(props) =>
      props.smallScreenMargin ? `margin: ${props.smallScreenMargin}` : ""};
  }
`;
