import { Button } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const GButton = styled(Button)`
  margin: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  background: ${Colors.lightGreen};
  border: ${Colors.textGreen} 2px solid;
  color: ${Colors.white};
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: bold;
  svg path {
    transition: 1s;
  }
  &:hover {
    svg path {
      fill: ${Colors.lightGreen};
    }
  }
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.2px;
  padding: 8px;
`;

export const TitleSuccess = styled(Title)`
  color: ${Colors.textGreen};
  background-color: ${Colors.lighterGray};
`;

export const TitleError = styled(Title)`
  background-color: ${Colors.lighterGray};
  color: ${Colors.dangerRed};
`;
