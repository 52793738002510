import LoadingSpinner from "@components/LoadingSpinner";
import { Image, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import { ImageBox, Info, Title, LinkMaps } from "../styles";

export default function ImagensOS(props) {
  const loading = <LoadingSpinner />;
  const [dados, setDados] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getDadosAnexos(props.numeroOS)
      .then((response) => {
        if (response.data["erro"]) {
          message.error(`${response.data.erro}`);
          props.voltar();
        } else {
          setDados(response.data);
          setLoaded(true);
        }
      })
      .catch((err) => {
        if (err.response?.data?.erro) {
          message.error(`${err.response?.data?.erro}`);
          props.voltar();
        }
      });
  }, [props.numeroOS]);

  const RowOs = () => {
    return (
      <>
        {loaded ? (
          <>
            <Row>
              <Container grid={1 / 3} title={`Dados Auditoria`}>
                <Box>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Documento: {dados.documento}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Uf: {dados.uf}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Nome: {dados.nome}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Latitude: {dados.latitude}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Longitude: {dados.longitude}
                  </Title>
                  <LinkMaps
                    to={{
                      pathname: `http://www.google.com/maps/place/${dados.latitude},${dados.longitude}`,
                    }}
                    target="_blank"
                  >
                    Clique aqui para ver no mapa
                  </LinkMaps>
                </Box>
              </Container>
              <Container grid={2 / 6} title={`Assinatura`} advice={"imagem"}>
                <ImageBox>
                  <Image rootClassName="ginga-image" src={dados.assinatura} />
                </ImageBox>
              </Container>
              <Container
                grid={3 / 6}
                title={`Imagens`}
                advice={dados.foto_1.length === 0 ? false : "imagem"}
              >
                <ImageBox>
                  {dados.foto_1.length === 0 ? (
                    <Info>Não há fotos disponíveis para essa OS.</Info>
                  ) : (
                    <Space>
                      <Image src={dados.foto_1} />
                      <Image src={dados.foto_2} />
                    </Space>
                  )}
                </ImageBox>
              </Container>
            </Row>
          </>
        ) : (
          loading
        )}
      </>
    );
  };
  return (
    <Box>
      {}
      <RowOs></RowOs>
    </Box>
  );
}
