import React from "react";
import { FiArrowLeft } from "react-icons/fi";

import useRouter from "../../hooks/useRouter";

import * as S from "./styles";

const PageHeader = ({ title, onGoBack }) => {
  const { history } = useRouter();

  const onClick = () => {
    if (onGoBack) {
      onGoBack();
      return;
    }
    history.goBack();
  };

  return (
    <S.Container className="page-header-content">
      <div className="row row-page-header">
        <S.BackButton size="small" onClick={onClick}>
          <FiArrowLeft size={20} stroke="#000" />
        </S.BackButton>
        <h1 className="page-title">{title}</h1>
      </div>
    </S.Container>
  );
};

export default PageHeader;
