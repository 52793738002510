import Colors from "@colors";
import { Form } from "antd";
import styled from "styled-components";

export const FormItem = styled(Form.Item)`
  margin: 0 0 8px;
  .ant-form-item-label {
    padding: 2px;
  }
`;

export const EditButton = styled.div`
  padding: 6px 8px 12px 16px;
  margin: -6px 0px -12px -16px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${(props) =>
    props.disabled
      ? `svg {
        fill: ${Colors.inactiveGray};
    }`
      : ""}
`;

export const SaveAddressBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const SugestoesTitle = styled.p`
  margin: 8px;
  font-size: 16px;
`;

export const ImportantInfo = styled.p`
  margin: 0 8px 8px;
  font-size: 16px;
`;

export const Valor = styled.span`
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : Colors.lightGreen)};
`;

export const Title = styled.span`
  padding: 16px 8px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
`;

export const Link = styled.a``;

export const CopyLine = styled.a`
  color: ${Colors.lightGreen};
  font-weight: bold;
  &:hover {
    color: ${Colors.textGreen};
  }
`;

export const Alert = styled.p`
  font-size: 16px;
  margin: 0 8px 8px ${(props) => (props.noDot ? 0 : "24px")};
  line-height: 1.2;
  color: ${(props) => (props.color ? props.color : Colors.textGreen)};
  position: relative;
  width: fit-content;
  font-weight: ${(props) => (props.link ? "bold" : "normal")};
  cursor: ${(props) => (props.link ? "pointer" : "default")};
  ${(props) =>
    !props.noDot &&
    `&::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: ${Colors.textGreen};
            border-radius: 100%;
            left: -12px;
            top: calc(50% - 3px);
        }`}
`;

export const AlertTitle = styled.p`
  font-size: 16px;
  margin: 4px 0;
`;

export const FakeRiquiredLabel = styled.p`
  font-size: 14px;
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;

export const CopyButton = styled.span`
  padding: 0 8px;
  opacity: 0.5;
  transition: 0.4s;
  &:hover {
    opacity: 1;
  }
`;

export const StonecodeList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const StonecodeTag = styled.div`
  transition: 0.2s;
  border: 1px solid ${Colors.lightGray};
  background-color: transparent;
  margin: 8px 8px 4px 0;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    background-color: ${Colors.lightShadowColor};
  }
`;

export const ModalContainer = styled.div`
  padding: 24px 12px 12px 12px;
`;
