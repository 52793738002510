import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  background-color: white;
  height: 72px;
  display: flex;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid #e3e3e3;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  h1 {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: rgba(48, 55, 66, 1);
    margin-bottom: 0;
  }
`;
export const CloseContent = styled.div`
  background-color: white;
  display: flex;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 99px;
  cursor: pointer;

  &:hover {
    background: rgba(118, 127, 141, 0.1);
  }
`;

export const ButtonsSimulation = styled.div`
  position: absolute;
  display: flex;
  padding: 24px 0px 0px 24px;

  .ant-btn {
    color: ${defaultTheme.colors.white};
    height: 44px;
    width: 124px;
    border-radius: 8px;
    padding: 13px 16px 13px 16px;
    background: rgba(0, 168, 104, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    border: 1px solid rgba(0, 168, 104, 1);
    margin-right: 12px;
  }

  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    background: rgba(0, 142, 90, 1);
  }
`;
