import React from "react";
import machineError from "@assets/imgs/machine-error.svg";
import machineSuccess from "@assets/imgs/machine-success.svg";
import * as S from "../styles";

export const ConfirmStep = ({
  handleCloseAndCleanDrawer,
  isSuccess,
  handleNewReport,
  handleSubmit,
  props,
}) => {
  return (
    <S.AllDrawerContent>
      <S.ImageText>
        <S.ImageDrawer>
          <img src={isSuccess ? machineSuccess : machineError} />
        </S.ImageDrawer>
        <p>
          {isSuccess
            ? "Maquininha reportada com sucesso!"
            : "Não foi possível reportar pois a conexão com o servidor falhou."}
        </p>
      </S.ImageText>
      <S.BottomContentDrawer>
        <S.DrawerFooterButton
          firstbutton={true}
          onClick={() => {
            isSuccess ? handleNewReport() : handleSubmit();
          }}
        >
          {isSuccess ? "Realizar novo report" : "Tentar novamente"}
        </S.DrawerFooterButton>
        <S.DrawerFooterButton
          onClick={() => {
            handleCloseAndCleanDrawer();
          }}
        >
          Fechar
        </S.DrawerFooterButton>
      </S.BottomContentDrawer>
    </S.AllDrawerContent>
  );
};
