import React from "react";

import "./styles.css";

const CellButton = ({ children, ...props }) => (
  <button type="button" className="custom-cell-button" {...props}>
    {children}
  </button>
);

export default CellButton;
