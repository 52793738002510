import React from "react";
import { Drawer, Tag, Image } from "antd";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { LoadingOutlined } from "@ant-design/icons";
import DeviceMachineIcon from "@assets/icons/device-card-machine.svg";
import { toCapitalizeFirstLetterOfEachWord } from "../../../../../utils/common";
import "./styles.css";
import * as S from "./styles";

const HistoricInfoDrawer = ({ open, onClose, clickedRowHistoric, loading }) => {
  const Header = () => {
    const now = new Date();
    const formmatedHour = format(now, "dd MMM ',' yy", {
      locale: ptBR,
    });
    let title;
    if (!clickedRowHistoric?.judging) {
      title = "Detalhes";
    } else {
      title =
        clickedRowHistoric.alreadyJudge ||
        (clickedRowHistoric.alreadyJudge === undefined &&
          clickedRowHistoric.judging)
          ? "Reavaliação"
          : "Avaliação";
    }

    return (
      <div className="header-content">
        <div className="header-row">
          <strong className="header-title">{`${title} - Serial ${clickedRowHistoric?.serial}`}</strong>
        </div>
        {!clickedRowHistoric?.judging && (
          <p className="header-description">
            Última atualização em {formmatedHour}
          </p>
        )}
        {clickedRowHistoric?.judging && (
          <p className="header-description">Revise as informacões abaixo.</p>
        )}
      </div>
    );
  };

  return (
    <Drawer title={<Header />} visible={open} onClose={onClose} closable>
      <S.AllDrawerContentHistoric>
        {loading === 200 && clickedRowHistoric ? (
          <div>
            <div style={{ marginTop: "32px" }}>
              <S.TopContentHistoric>
                <span>Situação</span>
                <Tag
                  color={
                    clickedRowHistoric.status === "aprovado"
                      ? "success"
                      : clickedRowHistoric.status === "pendente"
                      ? "default"
                      : "error"
                  }
                >
                  {clickedRowHistoric.status
                    ? toCapitalizeFirstLetterOfEachWord(
                        clickedRowHistoric.status
                      )
                    : ""}
                </Tag>
              </S.TopContentHistoric>
              <S.TopContentHistoric>
                <span>Reportado por</span>
                <p>{clickedRowHistoric.reportado_por}</p>
              </S.TopContentHistoric>
              <S.TopContentHistoric>
                <span>Reportado em</span>
                <p>{clickedRowHistoric.reportado_em}</p>
              </S.TopContentHistoric>
            </div>

            {loading === 200 &&
              clickedRowHistoric.defeitos.map((defeito, index) => {
                return (
                  <S.ContainerDefectHistoric key={index}>
                    <>
                      <Tag
                        color={
                          defeito.status === "aprovado"
                            ? "success"
                            : defeito.status === "pendente"
                            ? "default"
                            : "error"
                        }
                        style={{ marginTop: "16px" }}
                      >
                        {defeito.status
                          ? toCapitalizeFirstLetterOfEachWord(defeito.status)
                          : ""}
                      </Tag>
                      <S.DefectDetailsHistoric firstDefect>
                        <S.DefectDetailWithImageHistoric>
                          <div>
                            <span>Defeito identificado</span>
                            <h3>{defeito.nome}</h3>
                          </div>
                          <div>
                            <Image
                              width={"44px"}
                              height={"52px"}
                              src={defeito.hash_foto_1}
                            />

                            {defeito.hash_foto_2 ? (
                              <Image
                                style={{ marginLeft: "4px" }}
                                width={"44px"}
                                height={"52px"}
                                src={defeito.hash_foto_2}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </S.DefectDetailWithImageHistoric>
                      </S.DefectDetailsHistoric>
                      {defeito.justificativa && (
                        <S.DefectDetailsHistoric>
                          <span>Justificativa</span>
                          <h3>{defeito.justificativa}</h3>
                        </S.DefectDetailsHistoric>
                      )}
                    </>
                  </S.ContainerDefectHistoric>
                );
              })}

            {(clickedRowHistoric.erro_software ||
              clickedRowHistoric.observacao) && (
              <div>
                <S.DefectDetailsHistoric>
                  <span>Observações gerais</span>
                  {!clickedRowHistoric.erro_software && (
                    <div>
                      <S.DefectContentObservationHistoric>
                        <img
                          src={DeviceMachineIcon}
                          style={{ marginRight: "5.33px", marginBottom: "3px" }}
                        />
                        Máquina com erro de software
                      </S.DefectContentObservationHistoric>
                    </div>
                  )}
                  {clickedRowHistoric.observacao && (
                    <div>
                      <S.DefectContentObservationHistoric>
                        <img
                          src={DeviceMachineIcon}
                          style={{ marginRight: "5.33px" }}
                        />
                        {clickedRowHistoric.observacao}
                      </S.DefectContentObservationHistoric>
                    </div>
                  )}
                </S.DefectDetailsHistoric>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingOutlined />
          </div>
        )}

        <S.BottomContentDrawerHistoric>
          <S.DrawerFooterButtonHistoric
            onClick={() => {
              onClose();
            }}
          >
            Fechar
          </S.DrawerFooterButtonHistoric>
        </S.BottomContentDrawerHistoric>
      </S.AllDrawerContentHistoric>
    </Drawer>
  );
};

export default HistoricInfoDrawer;
