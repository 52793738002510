export const STATUS = [
  { id: 1, name: "Em Espera" },
  { id: 2, name: "Fechado Com Contato" },
  { id: 3, name: "Fechado Sem Contato" },
  { id: 4, name: "Pendente" },
  { id: 5, name: "Resolvido" },
];

export const OPERADORES = [
  { id: 0, name: "N/A" },
  { id: 1, name: "Renda Extra" },
  { id: 2, name: "C-trends" },
  { id: 3, name: "Flash" },
  { id: 4, name: "Flash - Correios" },
  { id: 5, name: "Franquia" },
  { id: 6, name: "Stone" },
  { id: 7, name: "PAX" },
  { id: 8, name: "Ógea" },
  { id: 9, name: "Box Barueri" },
  { id: 10, name: "Box Jaboatão" },
  { id: 11, name: "Fedex" },
  { id: 12, name: "Box Manaus" },
  { id: 13, name: "Base Emprasel" },
  { id: 14, name: "Base Fortaltec" },
  { id: 15, name: "Base Atual System" },
  { id: 16, name: "Base BC Santos" },
  { id: 17, name: "Base TN" },
  { id: 18, name: "Mobi" },
  { id: 19, name: "Loggi" },
  { id: 20, name: "Uber" },
  { id: 21, name: "Correios" },
];

export const ORIGENS = [
  { id: 1, name: "Caça" },
  { id: 2, name: "FRAN" },
  { id: 3, name: "GPS" },
  { id: 4, name: "Inbound" },
  { id: 5, name: "Logística" },
  { id: 6, name: "Outros" },
  { id: 7, name: "RC" },
  { id: 8, name: "Risco" },
  { id: 9, name: "Tratamento" },
  { id: 10, name: "TON" },
  { id: 11, name: "TON Bot" },
  { id: 12, name: "Yungas" },
  { id: 13, name: "OUVIDORIA" },
  { id: 14, name: "Reclama Aqui" },
  { id: 15, name: "Cliente Loja" },
  { id: 16, name: "Cliente Final" },
  { id: 17, name: "Suporte" },
  { id: 18, name: "Operador" },
];

export const CANAIS = [
  { id: 1, name: "E-mail" },
  { id: 2, name: "Chat" },
  { id: 3, name: "Comercial" },
  { id: 4, name: "Telefone" },
  { id: 5, name: "Whatsapp" },
];

export const TIPOS = [
  { value: "Caso Desnecessário", label: "Caso Desnecessário", checked: true },
  { value: "Caso Incompleto", label: "Caso Incompleto" },
  { value: "Retorno", label: "Retorno" },
];

export const FILAS = [
  { id: "Suporte Técnico", name: "Suporte Técnico" },
  { id: "Suporte de OS", name: "Suporte de OS" },
];
