/* eslint-disable no-plusplus */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
export default function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function toCapitalize(str = "") {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

export function toCapitalizeFirstLetterOfEachWord(str = "") {
  const arr = str.split(" ");

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
  }

  return arr.join(" ");
}

export const compareTableColumn = (a, b, key) =>
  `${a[key]}`.localeCompare(b[key]);

export const senninhaColors = [
  "#FF0592",
  "#E35947",
  "#A64343",
  "#B219FA",
  "#603EA8",
  "#1B4B36",
  "#0C9294",
  "#295090",
  "#CDAE40",
  "#1AD0BE",
  "#FF69B4",
  "#FF7F73",
  "#D19178",
  "#D489CC",
  "#9B77DA",
  "#538F6A",
  "#6FA5B1",
  "#4682B4",
  "#FFC700",
  "#53EBDC",
  "#FFC5CE",
  "#FFAC9A",
  "#F0C7A9",
  "#FEB6F6",
  "#9B77DA",
  "#AEBD77",
  "#B6D4DB",
  "#93CDFD",
  "#CBC9C2",
  "#9ED7D2"
];