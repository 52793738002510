import styled from "styled-components";



export const BodyTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
`;

export const BodyTopRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  p{
    color: #A6AEBA;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 12px */
    letter-spacing: -0.1px; 
  }
  `;

export const BodyMiddleContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding-bottom: 0;
`;
  
  export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  `;

//Relocate

export const RealocateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid rgba(118, 127, 141, 0.10);
  padding-bottom: 12px;
`;

export const RealocateInfos = styled.div`
  display: flex;
  justify-content: space-between;

  p{
    color: #767F8D;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 21.75px */
    letter-spacing: -0.15px;
  }

  span{
    color: #303742;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 21.75px */
    letter-spacing: -0.15px;
  }

`;

export const RealocateTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

// Swap

export const SwapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid rgba(118, 127, 141, 0.10);
  padding-bottom: 12px;
`;

export const SwapSelects = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 8px;
`;

export const SwapCampaign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-switch-handle {
    width: 24px;
    height: 24px;
    
    &::before {
      border-radius: 12px !important;
    }
  }

  .ant-switch {
    height: 28px !important;
    width: 48px !important;

    .ant-switch-handle {
      left: 3px !important;
    }
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px - 3px) !important;
  }

  span{
    color: #303742;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 21.75px */
    letter-spacing: -0.15px;
  }
  p{
    color: #767F8D;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 18.85px */
    letter-spacing: -0.13px;
  }
`;

export const TagArea = styled.div`
  svg {
    cursor: ${(props) => props.isBlocked ? 'not-allowed' : 'pointer'} !important;
  }
`;

export const DeliveriesArea = styled.div`
  padding: 0 0.75rem;
  border: 1px solid #3037421A;
  border-radius: 12px;

  .ant-checkbox-group {
    width: 100%;
  }
`;

export const DeliveryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #3037421A;

  > div:first-child {
    flex: 1 !important;
  }

  > span {
    color: #303742;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.6px;
    letter-spacing: -0.01em;
    cursor: pointer;
  }

  &:last-child {
    border: none !important;
  }
`;