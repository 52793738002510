import { Button, Form, Input } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const InputText = styled(Input)`
  width: 100%;
  height: 40px;
  border-radius: 60px 0 0 60px;
  background: ${Colors.white};
  border: 2px solid ${Colors.textGreen};
  padding: 8px 16px;
  div,
  input {
    height: 100%;
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
`;

export const SearchButton = styled(Button)`
  height: 40px;
  background-color: ${(props) =>
    props.active ? Colors.textGreen : Colors.lightGreen};
  border: 2px solid ${Colors.textGreen};
  border-left: none;
  border-radius: 0 60px 60px 0;
  font-size: 14px;
  font-weight: bold;
  color: white;
  letter-spacing: 0.8px;
  display: flex;
  cursor: pointer;
  width: auto;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${Colors.textGreen};
  }
  @media (max-width: 768px) {
    padding: 8px;
    span {
      margin: 0;
    }
  }
`;

export const BackButtonBox = styled.div`
  position: ${(props) => (props.fixed ? "fixed" : "absolute")};
  display: ${(props) => (props.hidden ? "none" : "flex")};
  top: 0;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  padding: 24px;
  z-index: 1009;
`;

export const Link = styled.a``;

export const Lista = styled.ul`
  padding-left: 32px;
`;

export const Item = styled.li``;

export const Paragraph = styled.p``;
