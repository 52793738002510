import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";

export default function EstoquePolo(props) {
  const title = "Estoque";

  const [estoquePoloStone, setEstoquePoloStone] = useState([]);
  const [estoquePoloTon, setEstoquePoloTon] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState({
    data: [],
    columns: [],
  });
  const [downloadLabels, setDownloadLabels] = useState(["Status"]);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    LogisticService.getEstoquePolo(props.polo).then((response) => {
      response.data.forEach((item) => {
        if (item.contratante === "STONE PAGAMENTOS") {
          setEstoquePoloStone((oldState) => [...oldState, item]);
        } else if (item.contratante === "TON") {
          setEstoquePoloTon((oldState) => [...oldState, item]);
        }
      });
      setLoaded(true);
    });
  }, [props.polo]);

  useEffect(() => {
    const dados = [];
    const colunas = [
      {
        label: "Status",
        key: "status",
        align: "start",
        sort: {
          compare: (a, b) => a.status.localeCompare(b.status),
        },
      },
    ];
    const status = [];
    const tecnologias = [];

    (props.showEstoqueTon ? estoquePoloTon : estoquePoloStone).forEach(
      (item) => {
        if (!status.includes(item.status)) {
          status.push(item.status);
        }
        if (!tecnologias.includes(item.tecnologia)) {
          tecnologias.push(item.tecnologia);
        }
      }
    );

    status.forEach((status) => {
      const row = { status: status };
      (props.showEstoqueTon ? estoquePoloTon : estoquePoloStone).forEach(
        (item) => {
          if (item.status === status) {
            row[item.tecnologia] = item.estoque;
          }
        }
      );
      dados.push(row);
    });

    dados.forEach((item) => {
      const rowDownload = [item.status];
      tecnologias.forEach((tecnologia) => {
        if (item[tecnologia]) {
          rowDownload.push(item[tecnologia]);
        } else {
          rowDownload.push("");
        }
      });
      setDownloadData((oldState) => [...oldState, rowDownload]);
    });

    tecnologias.forEach((tecnologia) => {
      colunas.push({ label: tecnologia, key: tecnologia });
      setDownloadLabels((oldState) => [...oldState, tecnologia]);
    });

    setTableData({
      data: dados,
      columns: colunas,
    });
  }, [estoquePoloStone, estoquePoloTon, props.showEstoqueTon]);

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [downloadData, downloadLabels, loaded, props.downloadAll, props.index]);

  return (
    <Container
      title={title + " - " + props.name}
      info={[`Estoque ${props.name}`]}
      downloadLabels={loaded ? downloadLabels : []}
      downloadData={loaded ? downloadData : []}
    >
      {loaded ? <InfoTable data={tableData} /> : <LoadingSpinner />}
    </Container>
  );
}
