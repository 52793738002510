import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import LineChart from "@components/LineChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import Colors from "@colors";

export default function SlaTon(props) {
  const title = "Confiabilidade TON (SLA)";
  const [slaTon, setSlaTon] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getSlaTon().then((response) => {
      if (response && response.data != null) {
        setSlaTon(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const slaGeral = [];
  const slaProprio = [];
  const slaTerceira = [];
  const downloadLabels = [
    "Período",
    "SLA Geral",
    "SLA Op. Própria",
    "SLA Op. Terceira",
  ];
  const downloadData = [];

  slaTon &&
    slaTon.map(
      (item) => (
        periodos.push(item.periodo),
        slaGeral.push((item.geral * 100).toFixed(1)),
        slaProprio.push((item.propria * 100).toFixed(1)),
        slaTerceira.push((item.terceira * 100).toFixed(1)),
        downloadData.push([
          `${item.periodo}`,
          `${(item.geral * 100).toFixed(3)}%`,
          `${(item.propria * 100).toFixed(3)}%`,
          `${(item.terceira * 100).toFixed(3)}%`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    datalabelsPercentage: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${
            tooltipItem.yLabel
          }%`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            max: 100,
            suggestedMin: 80,
            callback: function (value) {
              return value + "%";
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: false,
          ticks: {
            padding: 16,
          },
        },
      ],
    },
    data: [
      { label: "GERAL", color: Colors.green, value: slaGeral },
      { label: "PRÓPRIA", color: Colors.blue, value: slaProprio, hidden: true },
      {
        label: "TERCEIRA",
        color: Colors.gray,
        value: slaTerceira,
        hidden: true,
      },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title}
      info={[[""]]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <LineChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
