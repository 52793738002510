import { Input } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const TabButton = styled.div`
  border-radius: 0;
  margin-top: -8px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.2px;
  height: auto;
  padding: 8px 16px;
  background: ${(props) => (props.type ? Colors.lightGreen : Colors.light)};
  border: none;
  color: ${(props) => (props.type ? Colors.light : Colors.deepBlue)};
  cursor: pointer;
  &:hover {
    background: ${Colors.light};
    color: ${Colors.textGreen};
  }
`;

export const CustomInput = styled(Input)`
  margin: 8px;
`;
