import { DollarOutlined, HeartOutlined, StarOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import Box from "@components/Box";
import Page from "@components/Page";
import Row from "@components/Row";
import Cobertura from "./graficos/Cobertura";
import Confiabilidade from "./graficos/Confiabilidade";
import EvolucaoEtapasCadeia from "./graficos/EvolucaoEtapasCadeia";
import IDR from "./graficos/IDR";
import PerdasLiquidas from "./graficos/PerdasLiquidas";
import Qualidade from "./graficos/Qualidade";
import ShareDiasAtendimento from "./graficos/ShareDiasAtendimento";
import ShareOperacoes from "./graficos/ShareOperacoes";
import ShareTon from "./graficos/ShareTon";
import SlaTon from "./graficos/SlaTon";
import TmaCartao from "./graficos/TmaCartao";
import TmaTon from "./graficos/TmaTon";
import Velocidade from "./graficos/Velocidade";
import { TabButton } from "./styles";
import { PageLayout } from "../../../Layout/PageLayout";

export default function OnePage(props) {
  const [showSlaTon, setShowSlaTon] = useState(false);
  const [showTma, setShowTma] = useState(1);
  const [showShareTon, setShowShareTon] = useState(false);
  const pageAnchors = [
    { name: "Atendimento", hash: "atendimento", icon: <StarOutlined /> },
    { name: "Eficiência", hash: "eficiencia", icon: <DollarOutlined /> },
    {
      name: "Sustentabilidade",
      hash: "sustentabilidade",
      icon: <HeartOutlined />,
    },
  ];
  const downloadAll = [];
  let downloadIndex = 0;

  return (
    <>
      <PageLayout>
        <Page>
          <Box title="Atendimento" margin="0" id="atendimento">
            <Row hidden={showSlaTon}>
              <Confiabilidade
                downloadAll={downloadAll}
                index={downloadIndex++}
              />
            </Row>
            <Row hidden={!showSlaTon}>
              <SlaTon downloadAll={downloadAll} index={downloadIndex++} />
            </Row>
            <Row
              horizontalAlign={"start"}
              margin={"0 8px 16px"}
              smallScreenMargin={"0 0 16px"}
              nonResponsive
            >
              <TabButton
                onClick={() => setShowSlaTon(false)}
                type={showSlaTon ? true : false}
              >
                Stone
              </TabButton>
              <TabButton
                onClick={() => setShowSlaTon(true)}
                type={showSlaTon ? false : true}
              >
                TON
              </TabButton>
            </Row>
            <Row hidden={showTma !== 1}>
              <Velocidade downloadAll={downloadAll} index={downloadIndex++} />
            </Row>
            <Row hidden={showTma !== 2}>
              <TmaTon downloadAll={downloadAll} index={downloadIndex++} />
            </Row>
            <Row hidden={showTma !== 3}>
              <TmaCartao downloadAll={downloadAll} index={downloadIndex++} />
            </Row>
            <Row
              horizontalAlign={"start"}
              margin={"0 8px 16px"}
              smallScreenMargin={"0 0 16px"}
              nonResponsive
            >
              <TabButton
                onClick={() => setShowTma(1)}
                type={showTma === 1 ? false : true}
              >
                Stone
              </TabButton>
              <TabButton
                onClick={() => setShowTma(2)}
                type={showTma === 2 ? false : true}
              >
                TON
              </TabButton>
              <TabButton
                onClick={() => setShowTma(3)}
                type={showTma === 3 ? false : true}
              >
                Cartão
              </TabButton>
            </Row>
            <Row>
              <Qualidade downloadAll={downloadAll} index={downloadIndex++} />
              <ShareDiasAtendimento
                downloadAll={downloadAll}
                index={downloadIndex++}
              />
            </Row>
          </Box>
          <Box title="Eficiência" margin="0" id="eficiencia">
            <Row>
              <EvolucaoEtapasCadeia
                downloadAll={downloadAll}
                index={downloadIndex++}
              />
              <Cobertura downloadAll={downloadAll} index={downloadIndex++} />
            </Row>
            <Row>
              <PerdasLiquidas
                downloadAll={downloadAll}
                index={downloadIndex++}
              />
              <IDR downloadAll={downloadAll} index={downloadIndex++} />
            </Row>
          </Box>
          <Box title="Sustentabilidade" margin="0" id="sustentabilidade">
            <Row hidden={showShareTon}>
              <ShareOperacoes
                downloadAll={downloadAll}
                index={downloadIndex++}
              />
            </Row>
            <Row hidden={!showShareTon}>
              <ShareTon downloadAll={downloadAll} index={downloadIndex++} />
            </Row>
            <Row
              horizontalAlign={"start"}
              margin={"0 8px 16px"}
              smallScreenMargin={"0 0 16px"}
              nonResponsive
            >
              <TabButton
                onClick={() => setShowShareTon(false)}
                type={showShareTon ? true : false}
              >
                Stone
              </TabButton>
              <TabButton
                onClick={() => setShowShareTon(true)}
                type={showShareTon ? false : true}
              >
                TON
              </TabButton>
            </Row>
          </Box>
        </Page>
      </PageLayout>
    </>
  );
}
