import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React from "react";

const MaterialTableComponent = (props) => {
  const localization = {
    toolbar: {
      searchTooltip: "Pesquisar",
      searchPlaceholder: "Pesquisar",
      exportName: "Baixar CSV",
      exportTitle: "Download",
    },
    pagination: {
      labelRowsSelect: "linhas",
      labelDisplayedRows: "{from}-{to} de {count}",
      previousAriaLabel: "Página anterior",
      previousTooltip: "Página anterior",
      nextAriaLabel: "Próxima página",
      nextTooltip: "Próxima página",
      lastAriaLabel: "Última página",
      lastTooltip: "Última página",
      firstAriaLabel: "Primeira página",
      firstTooltip: "Primeira página",
    },
    body: {
      emptyDataSourceMessage: "Nada para mostrar",
      editRow: {
        deleteText: "Deseja realmente deletar esta linha?",
        cancelTooltip: "Cancelar",
        saveTooltip: "Confirmar",
      },
      editTooltip: "Editar",
      deleteTooltip: "Deletar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    ...props.addLocalization,
  };

  const options = {
    exportAllData: true,
    tableLayout: "fixed",
    pageSize: 10,
    showTitle: false,
    padding: "dense",
    exportButton: true,
    maxBodyHeight: 500,
    filtering: true,
    actionsCellStyle: {
      color: "green",
    },
    ...props.addOptions,
  };

  const icons = {
    Add: Add,
    Check: Check,
    ViewColumn: ViewColumn,
    Clear: Clear,
    DetailPanel: ChevronRight,
    Delete: DeleteOutline,
    Edit: Edit,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search: Search,
    ThirdStateCheck: Remove,
  };

  return (
    <MaterialTable
      options={options}
      localization={localization}
      columns={props.columns}
      data={props.data}
      detailPanel={props.detailPanel}
      isLoading={props.isLoading}
      title={props.title}
      icons={icons}
      {...props}
    />
  );
};

export default MaterialTableComponent;
