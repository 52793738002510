import React, { useEffect, useState } from "react";
import Input from "@components/InputThird";
import { useJsonToCsv } from "react-json-csv";
import { usePaginationConfig } from "../../../../../hooks/usePaginationConfig";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import RoundNone from "@assets/icons/roundNone.svg";
import DownloadIcon from "@assets/icons/DownloadIcon";
import CircleCloseIcon from "@assets/icons/CircleCloseIcon";
import SearchIcon from "@assets/icons/searchIcon";
import Empty from "@assets/icons/emptyBookImage.svg";
import { capitalizeAllFirstLetters } from "../../../../../consumingApi/services/helper";
import { Table } from "antd";

import * as S from "./styles";

export const AtrasoParceiro = ({
  reasons,
  selectedInfo,
  lastUpdate,
  loading,
}) => {
  const [inputText, setInputText] = useState("");
  const [oldInputText, setoldInputTextText] = useState("");
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();

  const paginationConfig = usePaginationConfig();

  const { saveAsCsv } = useJsonToCsv();

  const columns = [
    {
      title: "STONECODE",
      dataIndex: "stonecode",
      key: "stonecode",
      width: "20.19%",
      sorter: (a, b) => a.stonecode - b.stonecode,
      render: (text) =>
        loading ? (
          <S.SkeletonBox
            active={loading}
            size={"small"}
            height="24px"
            width="80px"
          />
        ) : (
          text
        ),
    },
    {
      title: "NÚMERO DA OS",
      dataIndex: "osNumber",
      key: "osNumber",
      width: "20.19%",
      sorter: (a, b) => a.osNumber - b.osNumber,
      render: (text) =>
        loading ? (
          <S.SkeletonBox
            active={loading}
            size={"small"}
            height="24px"
            width="80px"
          />
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: "MOTIVO",
      dataIndex: "reason",
      key: "reason",
      width: "20.19%",
      render: (text) =>
        loading ? (
          <S.SkeletonBox
            active={loading}
            size={"small"}
            height="24px"
            width="80px"
          />
        ) : text ? (
          <span>{text}</span>
        ) : (
          <S.Icon src={RoundNone} />
        ),
    },
    {
      title: "DETALHE",
      dataIndex: "detail",
      key: "detail",
      width: "20.19%",
      render: (text) =>
        loading ? (
          <S.SkeletonBox
            active={loading}
            size={"small"}
            height="24px"
            width="80px"
          />
        ) : text ? (
          <span>{text}</span>
        ) : (
          <S.Icon src={RoundNone} />
        ),
    },
    {
      title: "NOVO PRAZO",
      dataIndex: "newPrompt",
      key: "newPrompt",
      width: "13.49%",
      render: (text) =>
        loading ? (
          <S.SkeletonBox
            active={loading}
            size={"small"}
            height="24px"
            width="80px"
          />
        ) : text ? (
          <span>{`${format(new Date(text), "dd/MM/yyyy", {
            locale: ptBR,
          })}`}</span>
        ) : (
          <S.Icon src={RoundNone} />
        ),
    },
  ];

  useEffect(
    () =>
      setData(
        reasons
          ? reasons.map((reason, index) => ({
              key: index,
              stonecode: reason.stonecode,
              osNumber: reason.os,
              reason: reason.motivo,
              detail: reason.detalhe,
              newPrompt: reason.novo_prazo,
            }))
          : []
      ),
    [reasons]
  );

  useEffect(() => {
    if (inputText == "") {
      setFilteredData(data);
    }
  }, [inputText]);

  const filterData = () => {
    setoldInputTextText(inputText);
    if (inputText == "") {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter(
          (item) =>
            item.stonecode.indexOf(inputText) > -1 ||
            item.osNumber.toString().indexOf(inputText) > -1
        )
      );
    }
  };

  const handleDownload = () => {
    const downloadData = reasons.map((item) => ({
      stonecode: item.stonecode,
      os: item.os,
      motivo: item.motivo,
      detalhe: item.detalhe,
      novo_prazo: format(new Date(item.dead), "dd/MM/yyyy", { locale: ptBR }),
    }));

    saveAsCsv({
      fields: {
        stonecode: "stonecode",
        os: "os",
        motivo: "motivo",
        detalhe: "detalhe",
        novo_prazo: "novo_prazo",
      },
      data: downloadData,
      filename: `Motivos Atraso Gestão Terceira - ${selectedInfo.selectedProviderName}`,
    });
  };

  return (
    <S.Container>
      <header>
        <div>
          <h3>
            {loading ? (
              <S.SkeletonBox
                active={loading}
                size={"small"}
                height={28}
                width="20rem"
              />
            ) : (
              `${capitalizeAllFirstLetters(
                selectedInfo.selectedOperator
              )} ${capitalizeAllFirstLetters(
                selectedInfo.selectedProviderName
              )} `
            )}
          </h3>
          {lastUpdate && (
            <p>{`Atualizado em ${format(lastUpdate, "d MMM, yy", {
              locale: ptBR,
            })}`}</p>
          )}
        </div>
        <div>
          <Input
            label="Buscar stonecode ou nº de OS"
            placeholder="Digite aqui"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && filterData()}
            {...(inputText.length
              ? {
                  suffix: (
                    <CircleCloseIcon
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => setInputText("")}
                    />
                  ),
                }
              : {})}
          />
          <S.SearchButton
            icon={<SearchIcon size={18} color={"#000"} />}
            onClick={filterData}
          />
          <S.DownloadButton
            onClick={handleDownload}
            icon={<DownloadIcon size={18} />}
          >
            Baixar
          </S.DownloadButton>
        </div>
      </header>

      <Table
        className="ginga-table"
        columns={columns}
        dataSource={
          loading
            ? [...Array(5)].map((_, index) => ({
                key: `key${index}`,
              }))
            : filteredData ?? data
        }
        pagination={paginationConfig}
        locale={{
          emptyText: (
            <S.EmptyContainer>
              <img src={Empty} />
              <span>{`Nenhuma informação encontrada ${
                data && data.length ? `para “${oldInputText}”` : ""
              }`}</span>
            </S.EmptyContainer>
          ),
        }}
      />
    </S.Container>
  );
};
