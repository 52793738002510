import React from "react";
import { StyledButton } from "./styles";

const CustomButton = (props) => {
  return (
    <StyledButton
      styled={props.styled}
      shadow={props.shadow}
      small={props.small}
      large={props.large}
      onClick={props.onClick}
      style={props.style}
      active={props.active}
      disabled={props.disabled}
      width={props.width}
      margin={props.margin}
      htmlType={props.htmlType}
    >
      {props.children}
    </StyledButton>
  );
};

export default CustomButton;
