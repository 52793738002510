import { Button } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const OptionBox = styled.div`
  border: 2px solid ${Colors.textGreen};
  padding: 8px 16px;
  display: flex;
  background: ${Colors.lightGreen};
  border-radius: 50px;
  margin: 8px;
  cursor: pointer;
  &:hover {
    background: ${Colors.textGreen};
  }
  @media (max-width: 1024px) {
    align-self: center;
  }
`;

export const OptionText = styled.p`
  font-size: 16px;
  color: ${Colors.white};
  letter-spacing: 1px;
  white-space: nowrap;
`;

export const Manual = styled.span`
  margin: 16px 8px 0;
  font-size: 14px;
`;

export const Download = styled.a``;

export const SearchButton = styled(Button)`
  margin: 4px;
`;

export const AtualizacaoText = styled.p`
  font-size: 14px;
`;
