import React, { useState } from 'react';
import { Button, Drawer, Select, Tag } from '@stonelog/stonelog-design-system';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';
import RelocateRoutes from './Tabs/RoutePlanning/RelocateRoutes';
import RoutePlanning from './Tabs/RoutePlanning';

import * as S from './styles';


const RoutesDrawer = ({
    onClose,
    open,
    showNotification
}) => {

    return (
        <Drawer.Root
            placement='right'
            open={open}
            onClose={() => onClose()}
            mask={false}
            title={
                <Drawer.Header
                    title='Gestão de Rotas'
                    subTitle='Aqui você pode realocar, desalocar, trocar rotas e solicitar deliveries.'
                />
            }
        >
            {/* Aqui é para add o TAB na v1/v2 */}
            {/* <Drawer.Tab /> */} 
            <RoutePlanning
                onClose={onClose}
                showNotification={showNotification}
            />
        </Drawer.Root>
    )
};

export default RoutesDrawer;
