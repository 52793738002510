import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Colors from "@colors";
import Box from "@components/Box";
import CustomButton from "@components/CustomButton";
import CustomLink from "@components/CustomLink";
import CustomModal from "@components/CustomModal";
import Row from "@components/Row";
import SelectBox from "@components/SelectBox";
import {
  Checkbox,
  Form,
  Input,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useEffect, useState } from "react";
import { FormSpace, Title } from "./styles";

const EditableCell = ({
  // propriedades que ele pega do objeto columns:
  editing, // para retornar um input se True e o valor do campo se False
  dataIndex, // nome do campo (chave)
  title, // nome do campo (verboso)
  width, // width do input
  inputType, // tipo do input: boolean, select (para outros, criar dentro de inputNode, indicando o nome desejado)
  record, // objeto resultante do form preenchido
  index, // índice
  children, // o valor do campo
  required, // campo obrigatório
  selectOptions, // se o tipo do input for 'select', este campo deve conter uma lista de opções compatível ao componente 'SelectBox'
  isCreateForm, // form de create, dentro do modal
  disabled, // campo disabled
  ...restProps
}) => {
  const customWidth = width || "100%";
  const inputNode = (() => {
    if (inputType === "boolean") {
      return <Checkbox disabled={disabled} width={customWidth} />;
    } else if (inputType === "select" && selectOptions) {
      return (
        <SelectBox
          options={selectOptions}
          margin={"0"}
          disabled={disabled}
          width={customWidth}
        />
      );
    } else {
      return <Input disabled={disabled} style={{ width: customWidth }} />;
    }
  })();

  function bodyRules(required, inputType, title) {
    console.log(title, inputType);
    if (inputType == "email") {
      return [
        {
          required: required,
          message: `Precisa ser um email válido!`,
          type: "email",
        },
      ];
    } else {
      return [{ required: required, message: `${title}: campo obrigatório!` }];
    }
  }

  const body = editing ? (
    <FormItem
      label={isCreateForm ? title : false}
      name={dataIndex}
      rules={bodyRules(required, inputType, title)}
      valuePropName={inputType === "boolean" ? "checked" : "value"}
      style={{
        margin: 0,
        width: customWidth,
        alignItems: inputType === "boolean" ? "center" : "start",
      }}
    >
      {inputNode}
    </FormItem>
  ) : (
    children
  );

  return isCreateForm ? body : <td {...restProps}>{body}</td>;
};

const EditableTable = ({
  columns, // array de objetos com as chaves e propriedades das colunas da tabela
  data, // os dados vindos do banco, as colunas devem ter as mesmas chaves
  createDefaultValues, // opcional: caso queira valores iniciais para o createForm
  createRequest, // recebe a função com a request post que deve ter como parâmetro o objeto gerado pelo form
  editRequest, // recebe a função com a request post que deve ter como parâmetro o objeto gerado pelo form
  deleteRequest, // recebe a função com a request delete que deve ter como parâmetro o objeto a ser deletado
  createButtonTitle, // opcional: texto do botão e modal do botão de create
  customCreateForm, // opcional: form de create personalizado. se não houver, o form se baseia automaticamente nas columns
  title, // opcional: aparece acima da tabela
  deleteText, // campo para o texto do modal de confirmação do delete
  defaultPageSize, // campo para o tamanho da página
  customizeButton, //função para adicionar tarefa extra ao botão de criar request
  search,
}) => {
  const [form] = Form.useForm();
  const [originData, setOriginData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    form.setFieldsValue(createDefaultValues);
    let dataKeys = [];
    data.map((item, index) => dataKeys.push({ ...item, key: index }));
    setOriginData([...dataKeys]);
  }, []);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const openNotificationWithIcon = (type, title, text) => {
    console.log(title, text);
    notification[type]({
      message: title,
      description: text,
    });
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...originData];
      const index = newData.findIndex((item) => key === item.key);

      const onPost = (request, index, howmany, elements) => {
        setLoadingCreate(true);
        request(elements).then((response) => {
          setLoadingCreate(false);
          if (response.success) {
            if (response.nome) {
              // condição especial para a tabela de configurações
              elements["nome"] = response.nome;
            }
            newData.splice(index, howmany, elements); // edita ou adiciona as novas infos
            setOriginData(newData);
            setEditingKey("");
            refreshForm();
            openNotificationWithIcon("success", "Sucesso", response.message);
          } else if (response.message.respostas) {
            if (response.message.respostas.erros) {
              response.message.respostas.erros.map((messages) => {
                openNotificationWithIcon("error", "Erro", messages);
              });
              if (response.message.respostas.sucesso) {
                response.message.respostas.sucesso.map((messages) => {
                  openNotificationWithIcon("success", "Sucesso", messages);
                });
              }
            }
          } else {
            openNotificationWithIcon("error", "Erro", response.message);
          }
          setOpenModal(false);
        });
      };
      if (index > -1) {
        // edição
        const item = newData[index];
        onPost(editRequest, index, 1, { ...item, ...row });
      } else {
        // criação
        key = originData.length;
        onPost(createRequest, originData.length, 0, { key, ...row });
      }
    } catch (errInfo) {
      console.log("Erro na validação:", errInfo);
    }
  };

  const remove = (record) => {
    setLoadingDelete(true);
    const dataSource = [...originData];
    const item = dataSource[record.key];
    deleteRequest(item).then((response) => {
      setLoadingDelete(false);
      if (response.success) {
        setOriginData(dataSource.filter((item) => item.key !== record.key));
        openNotificationWithIcon("success", "Sucesso", response.message);
      } else openNotificationWithIcon("error", "Erro", response.message);
    });
  };

  const refreshForm = () => {
    if (createDefaultValues) form.setFieldsValue(createDefaultValues);
    else form.resetFields();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = !search
    ? () => {}
    : (dataIndex) => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <Box padding={"8px 0"}>
            <Input
              placeholder={`Buscar em ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ display: "block" }}
            />
            <Row horizontalAlign={"center"}>
              <Space>
                <CustomButton
                  small
                  htmlType="submit"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                >
                  Buscar
                </CustomButton>
                <CustomLink danger onClick={() => handleReset(clearFilters)}>
                  Resetar
                </CustomLink>
              </Space>
            </Row>
          </Box>
        ),
        filterIcon: () => (
          <SearchOutlined style={{ color: Colors.darkGreen }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : "",
        render: (text) => text,
      });

  const filterColumns = columns.map((column) => ({
    ...column,
    ...getColumnSearchProps(column.dataIndex),
  }));

  const editableColumns = [
    ...filterColumns,
    {
      title: "", // coluna extra para as opções
      dataIndex: "operation",
      width: "6%",
      align: "center",
      render: (_, record) => {
        const editing = isEditing(record);
        return editing ? (
          <Space>
            <Tooltip title="Salvar" placement="top">
              <SaveOutlined
                style={{ fontSize: 22, color: Colors.deepBlue }}
                onClick={() => save(record.key)}
              />
            </Tooltip>
            <Tooltip title="Cancelar" placement="top">
              <Popconfirm
                title="Isto apagará as modificações. Cancelar?"
                onConfirm={cancel}
              >
                <CloseCircleOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        ) : (
          <Space>
            {editRequest && (
              <Tooltip title="Editar" placement="top">
                <EditOutlined
                  disabled={editingKey !== ""}
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => edit(record)}
                />
              </Tooltip>
            )}
            {deleteRequest && (
              <Tooltip title="Apagar" placement="bottom">
                <Popconfirm
                  title={
                    deleteText ? deleteText : "Certeza de que deseja apagar?"
                  }
                  onConfirm={() => remove(record)}
                >
                  <DeleteOutlined
                    style={{ fontSize: 22, color: Colors.deepBlue }}
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = editableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        selectOptions: col.selectOptions || null,
      }),
    };
  });

  return (
    <Form form={form}>
      {createRequest && (
        <Row horizontalAlign={"space-between"} margin={"8px 0 16px"}>
          {title && <Title>{title}</Title>}
          <CustomButton
            onClick={() =>
              customizeButton
                ? (customizeButton(), setOpenModal(true))
                : setOpenModal(true)
            }
            type="primary"
            style={{ margin: "0" }}
          >
            {createButtonTitle || "Novo"}
          </CustomButton>
        </Row>
      )}
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={originData}
        columns={mergedColumns}
        rowClassName="editable-row"
        scroll={{ x: "80vw", y: "50vh" }}
        size={"small"}
        pagination={{
          position: ["bottomCenter"],
          // defaultCurrent: 6,
          // pageSize: defaultPageSize ? defaultPageSize : 5,
          onChange: cancel,
        }}
      />
      {createRequest && (
        <CustomModal
          title={createButtonTitle || "Novo"}
          visible={openModal}
          zIndex={1010}
          onCancel={() => setOpenModal(false)}
          footer={[
            <CustomButton
              htmlType="submit"
              onClick={() => save(-1)}
              disabled={loadingCreate}
            >
              {loadingCreate && <LoadingOutlined />}
              Cadastrar
            </CustomButton>,
          ]}
          width="100%"
          maxWidth="920px"
        >
          <Box>
            {customCreateForm ? (
              customCreateForm(form)
            ) : (
              <Form form={form} layout={"vertical"}>
                <FormSpace>
                  {columns.map((col, index) => (
                    <EditableCell {...col} key={index} editing isCreateForm />
                  ))}
                </FormSpace>
              </Form>
            )}
          </Box>
        </CustomModal>
      )}
    </Form>
  );
};

export default EditableTable;
