import React from "react";
import Box from "../Box";
import motorbike from "./motorbike.gif";
import { Image, LoadingText } from "./styles";

export default function LoadingSpinner(props) {
  const text = props.text ? props.text : "";

  return (
    <Box verticalAlign={"center"} horizontalAlign={"center"}>
      <Image src={motorbike} alt="loading" />
      <LoadingText>{text}</LoadingText>
    </Box>
  );
}
