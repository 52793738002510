import React, { useEffect, useRef, useState } from "react";
import { hubsSelector } from "../../../../features/senninha/hubsSlice";
import { useDispatch, useSelector } from "react-redux";
import OsHunter from "./OsHunter";
import { oldSenninhaSelector, setOldSenninhaWorkFlow } from "../../../../features/senninha/oldSenninhaSlice";
import Configuracoes from "./Configuracoes";
import { ReportDOA } from "./ReportarDOA";
import PosNaMochila from "./PosNaMochila";
import MotivosAtraso from "./MotivosAtraso";

// import * as S from "./styles";


const OldSenninha = () => {

    const dispatch = useDispatch();

    const {
        oldSenninhaWorkFlow
    } = useSelector(oldSenninhaSelector);

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    useEffect(() => {
        dispatch(setOldSenninhaWorkFlow(''));
    }, []);

    return (
        <>
            {oldSenninhaWorkFlow === 'pinHunter' && (
                <OsHunter />
            )}
            {oldSenninhaWorkFlow === 'configuracoes' && (
                <Configuracoes />
            )}
            {(oldSenninhaWorkFlow === 'reportDoa') && (
                <ReportDOA
                    polo={selectedHubInfos.children}
                    drawerDoaVisible={oldSenninhaWorkFlow === 'reportDoa'}
                />
            )}
            {(oldSenninhaWorkFlow === 'posNaMochila') && (
                <PosNaMochila
                    polo={selectedHubInfos.children}
                    drawerDoaVisible={oldSenninhaWorkFlow === 'reportDoa'}
                />
            )}
            {(oldSenninhaWorkFlow === 'motivosAtraso') && (
                <MotivosAtraso />
            )}
        </>
    );
};

export default OldSenninha;
