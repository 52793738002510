const Colors = {
  shadowColor: "rgba(53, 64, 82, 0.3)",
  lightShadowColor: "rgba(53, 64, 82, 0.05)",
  transparentWhite: "#ffffffad",
  light: "#F8F8F8",
  white: "#FFF",
  dark: "#000000",
  gray: "#A9AAAD",
  darkGray: "#5C6569",
  lighterGray: "#F1F1F2",
  lightGray: "#e6e6e6",
  inactiveGray: "#8C8C8E",
  green: "#0DB14B",
  lightGreen: "#5daa69",
  lighterGreen: "#82D66B",
  darkGreen: "#033921",
  blue: "#00B9B5",
  darkBlue: "#1D3C65",
  dangerRed: "#c00000",
  cautionYellow: "#ffc000",
  lightRed: "#ff8484",
  deepBlue: "#1a2025",
  textGreen: "#3d744e",
  risk: "#FF0000",
  allowed: "#32CD32",
  prohibited: "#FFFF00",
  redDot: "#e74c3c",
  yellowDot: "#f1c40f",
  greenDot: "#2ecc71",
};

export default Colors;
