import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import Clock from "@assets/icons/Clock";
import Truck from "@assets/icons/Truck";
import File from "@assets/icons/File";
import RetryIcon from "@assets/icons/RetryIcon";
import Empty from "@assets/icons/errorBookImage.svg";
import { Table } from "antd";
import { Legend } from "./legend.js";
import AttendanceTooltip from "../AttendanceTooltip/index.js";
import SimulationRouteCard from "../SimulationRouteCard/index.js";
import LunchTableGridIcon from "@assets/icons/LunchTableGridIcon.svg";
import WarningIcon from "@assets/icons/WarningIcon";

import * as S from "./styles.js";

const TimelineTable = ({
  title,
  data,
  totalInfo,
  loading,
  simulateRoute,
  totalSimulations,
  setOpenSimulationModal,
}) => {
  const [timeSlotOverTime, setTimeSlotOverTime] = useState(false);
  const [visibleToolTip, setVisibleToolTip] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    if (!data) setTimeSlotOverTime(false);
  }, [data]);

  const canSimulate = totalSimulations <= 3;
  const dataSource = data
    ? data.map(({ name }, index) => ({ key: index, name }))
    : [];

  useEffect(() => {
    if (tableRef?.current && data) {
      const minimumAttendances = data.map((item) =>
        parseInt(item.timeSlots[0].startTime.split(":")[0])
      );
      const earliestAttendance = Math.min(...minimumAttendances);
      const aux = tableRef.current.querySelector(".ant-table-body");
      if (aux) aux.scrollTo({ left: (earliestAttendance - 6) * 90, top: 0 });
    }
  }, [tableRef, loading]);

  const columns = [
    {
      title: "Green Angels",
      dataIndex: "name",
      key: "name",
      width: 137,
      fixed: "left",
      render: (text, i) => {
        const {
          total_distance,
          total_duration,
          total_orders_number,
        } = data.find((angel) => angel.name === text);
        return (
          <Tooltip
            key={i}
            title={() => (
              <div>
                <S.Row>
                  <Clock size={16} />
                  <span>
                    Total de horas:{" "}
                    <b>{`${Math.round(total_duration / 60)} h`}</b>
                  </span>
                </S.Row>
                <S.Row>
                  <Truck size={16} />
                  <span>
                    Quilometragem:
                    <b>{`${Math.round(total_distance / 1000)} km`}</b>
                  </span>
                </S.Row>
                <S.Row>
                  <File size={16} />
                  <span>
                    Total de O.S: <b>{total_orders_number}</b>
                  </span>
                </S.Row>
              </div>
            )}
            placement="topLeft"
            overlayClassName="ginga-tooltip ginga-tooltip-timelime ginga-tooltip-top-left"
          >
            {text}
          </Tooltip>
        );
      },
    },
    ...Array.from({ length: 18 }, (_, i) => ({
      title: `${i + 6}h`,
      dataIndex: `hour${i + 6}`,
      key: `hour${i + 6}`,
      width: 90,
      render: (text, record, index) => {
        const currentHour = i + 6;
        const timeSlots = data[index].timeSlots.filter((slot) => {
          if (slot.endTime.split(":")[0] === "00") setTimeSlotOverTime(true);
          const startTime = slot.startTime.split(":");
          const startHour = parseInt(startTime[0], 10);
          return startHour === currentHour;
        });

        return (
          <S.Cell>
            {timeSlots.map((timeSlot, slotIndex) => {
              const startTime = timeSlot.startTime.split(":");
              const endTime = timeSlot.endTime.split(":");
              const startHour = parseInt(startTime[0], 10);
              const startMinute = parseInt(startTime[1], 10);
              const endHour = parseInt(endTime[0], 10);
              const endMinute = parseInt(endTime[1], 10);
              const duration =
                (endHour - startHour) * 60 + (endMinute - startMinute);
              const columnWidth = 90;
              const blockWidth = (duration / 60) * columnWidth;
              const blockType = timeSlot.type;
              const blockLeftOffset = (startMinute / 60) * columnWidth;
              const uniqueId = `slot-${index}-${slotIndex}`;

              return timeSlot.type === "ALMOCO" ? (
                <S.CellContentLunchArea
                  key={uniqueId}
                  blockWidth={blockWidth}
                  blockLeftOffset={blockLeftOffset}
                >
                  <S.GridImageLunch src={LunchTableGridIcon} />
                  <span>
                    <Clock size={14} />
                    <div>Almoço</div>
                  </span>
                </S.CellContentLunchArea>
              ) : (
                <AttendanceTooltip
                  key={uniqueId}
                  osType={blockType}
                  startTime={timeSlot.startTime}
                  endTime={timeSlot.endTime}
                >
                  <S.CellContent
                    key={`${blockType}-${startTime}-${endTime}`}
                    blockWidth={blockWidth}
                    blockType={blockType}
                    blockLeftOffset={blockLeftOffset}
                    // onClick={() => alert(`Compromisso: ${blockType}`)}
                  />
                </AttendanceTooltip>
              );
            })}

            <S.ProgressBarAux />
          </S.Cell>
        );
      },
    })),
  ];

  const renderTimeline = () => (
    <>
      {timeSlotOverTime && (
        <S.AlertBalloon>
          <S.AlertBalloonContent>
            <WarningIcon color={"#F38428"} size={24} />
            <div>
              <span>Rota muito longa.</span>
              <span>O horário da rota está ultrapassando o dia corrente.</span>
            </div>
          </S.AlertBalloonContent>
        </S.AlertBalloon>
      )}

      <Legend data={data} totalInfo={totalInfo} loading={loading} />

      <div className="timeline-table" style={{ paddingBottom: 9.5 }}>
        <Table
          key={title}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowKey="name"
          scroll={{
            x: "max-content",
            y: "306px",
            scrollToFirstRowOnChange: true,
          }}
          size="small"
          className="ginga-table-timeline"
        />
      </div>
    </>
  );

  return (
    <>
      <S.Container>
        <S.Header>
          {title === "Distrital" ? (
            <>
              <div>
                {title}
                {data && canSimulate && (
                  <S.SubTitle>Você simulou {totalSimulations} de 3.</S.SubTitle>
                )}
              </div>
              {data && (
                <Tooltip
                  title={() => (
                    <S.TitleToolTip>
                      Você utilizou o limite máximo de simulaçãoes no dia. Vamos
                      manter em tela a sua última simulação.
                    </S.TitleToolTip>
                  )}
                  placement="top"
                  overlayClassName="ginga-tooltip ginga-tooltip-cockpit ginga-tooltip-top"
                  visible={visibleToolTip}
                  onMouseEnter={() => setVisibleToolTip(totalSimulations === 3)}
                  onMouseLeave={() => setVisibleToolTip(false)}
                >
                  <S.SimulationButton
                    onClick={() => setOpenSimulationModal(true)}
                    disabled={totalSimulations === 3}
                  >
                    <RetryIcon size={16} />
                    <span>Nova simulação</span>
                  </S.SimulationButton>
                </Tooltip>
              )}
            </>
          ) : (
            <div>{title}</div>
          )}
        </S.Header>

        <div ref={tableRef}>
          {title === "Distrital" ? (
            <>
              {data && renderTimeline()}

              {!data && (
                <>
                  {totalSimulations === 0 && (
                    <SimulationRouteCard
                      simulateRoute={simulateRoute}
                      canSimulate={canSimulate}
                    />
                  )}
                  {totalSimulations !== 0 && canSimulate && (
                    <S.LoadingDistrictalCard>
                      <S.Loading></S.Loading>
                      <div>Aguarde enquanto carregamos sua simulação...</div>
                    </S.LoadingDistrictalCard>
                  )}
                  {!canSimulate && (
                    <SimulationRouteCard
                      simulateRoute={simulateRoute}
                      canSimulate={!canSimulate}
                    />
                  )}
                </>
              )}
            </>
          ) : loading ? (
            <S.SkeletonTimeline active block />
          ) : data ? (
            renderTimeline()
          ) : (
            <S.NoDataCard>
              <img src={Empty} />
              <span>Nenhuma informação disponível no momento.</span>
            </S.NoDataCard>
          )}
        </div>
      </S.Container>
    </>
  );
};

export default TimelineTable;
