import Icon, { LoadingOutlined } from "@ant-design/icons";
import { Card, Statistic } from "antd";
import React from "react";

const SumarioDeResultados = (props) => {

  const { resultado, pilares, tipoAngel } = props;
  const possuiAtingimentoVariavelComPresenca = tipoAngel !== 'distrital' && resultado.atingimento_final
  const tetoDeAtingimento = ((resultado.teto_rv || 1) * 100).toFixed(1)

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      {Object.keys(pilares).map((pilar) => {
        return (
          <AtingimentoDoPilar
            key={Math.random()}
            atingimento={pilares[pilar].atingimento}
            limite={pilares[pilar].limite}
            nomeDoPilar={pilares[pilar].nome}
          />
        );
      })}
      <AtingimentoDoPilar
        atingimento={(resultado.resultado_final * 100).toFixed(1)}
        limite={tetoDeAtingimento}
        nomeDoPilar= { possuiAtingimentoVariavelComPresenca? "Atingimento Parcial": "Atingimento Final"}
      />
      { possuiAtingimentoVariavelComPresenca? <AtingimentoDoPilar
        atingimento={(resultado.atingimento_final * 100).toFixed(1)}
        limite={tetoDeAtingimento}
        nomeDoPilar="Atingimento Final"
        />: null }
    </div>
  );
};

const AtingimentoDoPilar = (props) => {
  const loading = <LoadingOutlined spin />;
  const { icon, atingimento, limite, nomeDoPilar } = props;
  return (
    <Card style={{ minWidth: 180, margin: "18px" }}>
      <Statistic
        title={<h3>{nomeDoPilar}</h3>}
        style={{ textAlign: "center" }}
        value={!isNaN(atingimento) ? atingimento + "/" + limite : " "}
        prefix={
          <Icon
            type={icon || "none"}
            theme="twoTone"
            // twoToneColor="silver"
            style={{ verticalAlign: "middle" }}
          />
        }
        suffix={isNaN(atingimento) ? loading : ""}
      />
    </Card>
  );
};

const Divider = () => (
  <div style={{ display: "flex" }}>
    <div
      style={{
        width: "2px",
        height: "100%",
        margin: "0 8px",
        backgroundColor: "#d1d0d0",
      }}
    />
  </div>
);

const CardInformacoes = (props) => {
  const { resultado, tipoAngel } = props;
  if (tipoAngel == 'distrital') {
    const { headcount_atuacao, headcount_mindsight, regional } = resultado;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <b>Headcount ativo:&nbsp;&nbsp;</b>
          <span>{headcount_atuacao}&nbsp;&nbsp;</span>
        </div>
        <div>
          <b>Headcount BP:&nbsp;&nbsp;</b>
          <span>{headcount_mindsight}&nbsp;&nbsp;</span>
        </div>
        <div>
          <b>Regional:&nbsp;&nbsp;</b>
          <span>{regional}&nbsp;&nbsp;</span>
        </div>
      </div>
    );

  }


  const { polo_mindsight, polo_atuacao, absenteismo, distrito_atuacao, distrito_mindsight, pilar_eficiencia = null, teto_rv = null } = resultado;
  const { perc_presenca = 1} = absenteismo || {};
  const presenca = `${(perc_presenca*100).toFixed(1)}%`
  const dadosInventario = pilar_eficiencia?.indicadores.find((p) => p.nome == 'INVENTARIO');
  let dataUltimoInventario = dadosInventario?.detalhes?.data_inventario_considerado || '-'
  
  if (dataUltimoInventario !== '-') {
    const [ano, mes, dia] = dataUltimoInventario.split('-')
    dataUltimoInventario = `${dia}/${mes}/${ano}`
  }

  const localUltimoInventario = dadosInventario?.detalhes?.local_mes_inventario || '-'
  const tetoAtingimento = teto_rv ? `${(teto_rv*100).toFixed(1)}%`: '100%';
  const naoBaixouOS = !polo_atuacao ? 'Não baixou OS no período': null;
  const localAtuacao = naoBaixouOS? <b>{naoBaixouOS}</b> : <span>{polo_atuacao} | <b>{distrito_atuacao}</b></span>;

  return (
    <div style={{ margin: "8px" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "max-content",
          }}
        >
          <div>
            <b>Polo/Distrito (Baixa de OS):&nbsp;&nbsp;</b>
            {localAtuacao}
          </div>
          <div>
            <b>Polo/Distrito (BP):&nbsp;&nbsp;</b>
            <span>
              {polo_mindsight} | <b>{distrito_mindsight}</b>&nbsp;&nbsp;
            </span>
          </div>
          <div>
            <b>Polo inventário ({dataUltimoInventario}):&nbsp;&nbsp;</b>
            <span>{localUltimoInventario}</span>
          </div>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "max-content",
          }}
        >
          <div>
            <b>% Presença:&nbsp;&nbsp;</b>
            <span style={{ color: perc_presenca == 1 ? "inherit" : "red" }}>
              {presenca}
            </span>
          </div>
          <div>
            <b>Teto Atingimento:&nbsp;&nbsp;</b>
            <span>{tetoAtingimento}</span>
          </div>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "max-content",
          }}
        >
          <div>
            <b>Fórmula Atingimento Parcial: &nbsp;&nbsp;</b>
            <span>Somatória dos atingimentos dos pilares</span>
          </div>
          <div>
            <b>Fórmula Atingimento Final:&nbsp;&nbsp;</b>
            <span> (AtingimentoParcial) * (%Presença) </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export { AtingimentoDoPilar, SumarioDeResultados, CardInformacoes };
