import styled, { css } from "styled-components";
import defaultTheme from "@defaultTheme";

const colorVariants = {
  default: "#767F8D",
  warning: "#D95E00",
};

export const ContainerSearchInput = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const FormSelectContainer = styled.div`
  width: 100%;
`;

export const MessageErrorText = styled.div`
  font-size: 12px;
  position: relative;
  left: 4px;
  bottom: 19px;
  color: red;
`;

export const Row = styled.div`
  display: flex;
  min-width: 433px;
`;

export const IconButton = styled.button`
  background: none;
  cursor: pointer;
`;
