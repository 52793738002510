import React from "react";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import RoundNone from "@assets/icons/roundNone.svg";
import * as S from "./styles";
import { capitalizeAllFirstLetters } from "../../../../../consumingApi/services/helper";
import { Skeleton } from "antd";

export default function Table({ title, data, loading, isStone }) {
  return (
    <S.MachinesCard>
      <S.CardBlock>
        <S.Title>
          {!loading ? (
            title
          ) : (
            <S.SkeletonBox active={loading} width="60%" height="1.3125rem" />
          )}
        </S.Title>
      </S.CardBlock>

      <S.CardBlock>
        <S.Row>
          {!loading ? (
            <>
              <S.TextLightGray>{`Previsão do ${
                !isStone ? "próx." : "próximo"
              } envio`}</S.TextLightGray>
              {data?.estoque_polo_transito_info &&
              data?.estoque_polo_transito_info?.data ? (
                <S.DateBadge>
                  {format(
                    new Date(data?.estoque_polo_transito_info.data),
                    "d 'de' MMM",
                    { locale: ptBR }
                  )}
                </S.DateBadge>
              ) : (
                <S.Icon src={RoundNone} />
              )}
            </>
          ) : (
            <>
              <S.SkeletonBox
                active={loading}
                size={"small"}
                className="ginga-ant-skeleton"
              />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
        <S.Row>
          {!loading ? (
            <>
              <S.TextLightGray>Número da OM</S.TextLightGray>
              {data?.estoque_polo_transito_info &&
              data?.estoque_polo_transito_info?.ordem ? (
                <S.TextLight>
                  {data?.estoque_polo_transito_info?.ordem}
                </S.TextLight>
              ) : (
                <S.Icon src={RoundNone} />
              )}
            </>
          ) : (
            <>
              <S.SkeletonBox
                active={loading}
                size={"small"}
                className="ginga-ant-skeleton"
                width="40%"
              />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
        <S.Row>
          {!loading ? (
            <>
              <S.TextLightGray>Status</S.TextLightGray>
              {data?.estoque_polo_transito_info &&
              data?.estoque_polo_transito_info?.status ? (
                <S.TextLight>
                  {capitalizeAllFirstLetters(
                    data?.estoque_polo_transito_info.status.replace("_", " ")
                  )}
                </S.TextLight>
              ) : (
                <S.Icon src={RoundNone} />
              )}
            </>
          ) : (
            <>
              <S.SkeletonBox
                active={loading}
                size={"small"}
                className="ginga-ant-skeleton"
                width="30%"
              />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
      </S.CardBlock>

      <S.CardBlock>
        <S.Row>
          {!loading ? (
            <>
              <S.TextBoldGreen>Cobertura suplementar</S.TextBoldGreen>
              <S.TextBoldGreen>{data?.cobertura_suplementar}</S.TextBoldGreen>
            </>
          ) : (
            <>
              <S.SkeletonBox active={loading} height="15px" />
              <Skeleton.Avatar active={loading} shape={"square"} />
            </>
          )}
        </S.Row>
      </S.CardBlock>

      <S.CardBlock>
        <S.Row>
          {!loading ? (
            <>
              <S.Text>Consumo</S.Text>
              <S.TextBold>{data?.consumo}</S.TextBold>
            </>
          ) : (
            <>
              <S.SkeletonBox active={loading} size={"small"} />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
        <S.Row>
          {!loading ? (
            <>
              <S.Text>Estoque no polo</S.Text>
              <S.TextBold>{data?.estoque_polo}</S.TextBold>
            </>
          ) : (
            <>
              <S.SkeletonBox active={loading} size={"small"} />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
        <S.Row>
          {!loading ? (
            <>
              <S.Text>Estoque em trânsito</S.Text>
              <S.TextBold>{data?.estoque_polo_transito}</S.TextBold>
            </>
          ) : (
            <>
              <S.SkeletonBox active={loading} size={"small"} />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
      </S.CardBlock>

      <S.CardBlock>
        <S.Row>
          {!loading ? (
            <>
              <S.Text>Cobertura no polo</S.Text>
              <S.TextBold>{data?.cobertura_polo}</S.TextBold>
            </>
          ) : (
            <>
              <S.SkeletonBox active={loading} size={"small"} />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
        <S.Row>
          {!loading ? (
            <>
              <S.Text>Cobertura com avanço</S.Text>
              <S.TextBold>{data?.cobertura_polo_transito}</S.TextBold>
            </>
          ) : (
            <>
              <S.SkeletonBox active={loading} size={"small"} />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
      </S.CardBlock>

      <S.CardBlock>
        <S.Row>
          {!loading ? (
            <>
              <S.Text>Leadtime</S.Text>
              {data?.estoque_polo_transito_info &&
              data?.estoque_polo_transito_info?.ordem ? (
                <S.TextBold>
                  {data?.estoque_polo_transito_info.leadtime}
                </S.TextBold>
              ) : (
                <S.Icon src={RoundNone} />
              )}
            </>
          ) : (
            <>
              <S.SkeletonBox active={loading} size={"small"} />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
        <S.Row>
          {!loading ? (
            <>
              <S.Text>Cobertura de segurança</S.Text>
              <S.TextBold>{data?.cobertura_serguranca}</S.TextBold>
            </>
          ) : (
            <>
              <S.SkeletonBox active={loading} size={"small"} />
              <Skeleton.Avatar
                active={loading}
                size={"small"}
                shape={"square"}
              />
            </>
          )}
        </S.Row>
      </S.CardBlock>
    </S.MachinesCard>
  );
}
