import { Modal } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px !important;
    width: 458px;
    background: #ffffff;

    .ant-modal-header {
      padding: 24px 24px 16px 24px;
      border: none;
      border-radius: 12px;
      .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }
    .ant-modal-footer {
      border: none;
      display: ${(props) => (props.hideFooter ? "none" : "flex")};
      justify-content: center;
      padding: 0px 24px 24px 24px;
    }
    svg {
      fill: ${Colors.deepBlue};
    }
    .ant-modal-body {
      padding: 24px 24px 18px 24px;
      border-top: 1px solid rgba(118, 127, 141, 0.1);
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
    }
    .ant-modal-close {
      top: 10px;
      right: 5px;
    }
  }

  .ant-btn {
    font-weight: 400;
    text-align: center;
    border: 1px solid transparent;
    height: 36px;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.65);
    border-color: #d9d9d9;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.01em;
  }
`;
