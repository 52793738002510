import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import InfoTable from "@components/InfoTable";
import Row from "@components/Row";
import LogisticService from "@LogisticService";

export default function Contatos(props) {
  const loading = <LoadingOutlined spin />;
  const [contatos, setContatos] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getInformacoesGerais(props.stonecode).then((response) => {
      if (response && response.data != null) {
        setContatos(response.data.contacts);
      }
      setLoaded(true);
    });
  }, []);

  const dados = [];
  const colunas = [
    {
      label: "Nome",
      key: "nome",
    },
    {
      label: "Email",
      key: "email",
      sort: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
    },
    {
      label: "Telefone",
      key: "telefone",
    },
  ];

  contatos.map((item) => {
    const row = {};
    row["nome"] = item.name;
    row["email"] = item.email;
    row["telefone"] = item.mobilePhone;
    dados.push(row);
  });

  const tableData = {
    data: dados,
    columns: colunas,
  };

  return (
    <Container grid={1 / 2} title={`Contatos do Cliente`}>
      {loaded ? (
        <InfoTable
          data={tableData}
          pagination={{ defaultPageSize: 4, showSizeChanger: true }}
          search
        />
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
