import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import LogisticService from "@LogisticService";

import Container from "@components/Container";
import InfoTable from "@components/InfoTable";
import Box from "@components/Box";
import Row from "@components/Row";

export default function HistoricoOs(props) {
  const [historicoDaOs, setHistoricoDaOs] = useState([]);
  const loading = <LoadingOutlined spin />;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    LogisticService.getOsClienteHunter(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        setHistoricoDaOs(response.data);
      }
      setLoaded(true);
    });
  }, [props.stonecode]);

  const dadosHistorico = [];
  const colunasHistorico = [
    {
      label: "Ordem de Serviço",
      key: "order_number",
      align: "start",
    },
    {
      label: "Status",
      key: "status",
      align: "start",
      sort: {
        compare: (a, b) => a.service_group.localeCompare(b.service_group),
      },
    },
    {
      label: "Grupo de Serviço",
      key: "service_group",
      sort: {
        compare: (a, b) => a.service_group.localeCompare(b.service_group),
      },
    },
    {
      label: "Tipo de Serviço",
      key: "service_type",
      sort: {
        compare: (a, b) => a.service_type.localeCompare(b.service_type),
      },
    },
    {
      label: "Serial Instalado",
      key: "installed_terminal_serial_number",
      sort: {
        compare: (a, b) =>
          a.installed_terminal_serial_number.localeCompare(
            b.installed_terminal_serial_number
          ),
      },
    },
    {
      label: "Serial Desinstalado",
      key: "uninstalled_terminal_serial_number",
      sort: {
        compare: (a, b) =>
          a.uninstalled_terminal_serial_number.localeCompare(
            b.uninstalled_terminal_serial_number
          ),
      },
    },
    {
      label: "Data de Abertua",
      key: "opening_date",
    },
    {
      label: "Data de Vencimento",
      key: "deadline_date",
    },
    {
      label: "Data de Atendimento",
      key: "arrival_date",
    },
    {
      label: "Motivo de Cancelamento",
      key: "cancellation_reason",
    },
  ];
  const downloadLabels = [
    "Ordem de Serviço",
    "Status",
    "Grupo de Serviço",
    "Tipo de Serviço",
    "Serial Instalado",
    "Serial Desinstalado",
    "Data de Abertua",
    "Data de Vencimento",
    "Data de Atendimento",
    "Motivo de Cancelamento",
  ];
  const downloadData = [];

  historicoDaOs.forEach((item, index) => {
    const row = {};
    row["key"] = index;
    row["order_number"] = item.order_number || "";
    row["status"] = item.status || "";
    row["service_group"] = item.service_group || "";
    row["service_type"] = item.service_type || "";
    row["installed_terminal_serial_number"] =
      item.installed_terminal_serial_number || "";
    row["uninstalled_terminal_serial_number"] =
      item.uninstalled_terminal_serial_number || "";
    row["opening_date"] = item.opening_date || "";
    row["deadline_date"] = item.deadline_date || "";
    row["arrival_date"] = item.arrival_date || "";
    row["cancellation_reason"] = item.cancellation_reason || "";
    dadosHistorico.push(row);

    const rowDownload = [];
    rowDownload.push(item.order_number);
    rowDownload.push(item.status);
    rowDownload.push(item.service_group);
    rowDownload.push(item.service_type);
    rowDownload.push(item.installed_terminal_serial_number);
    rowDownload.push(item.uninstalled_terminal_serial_number);
    rowDownload.push(item.opening_date);
    rowDownload.push(item.deadline_date);
    rowDownload.push(item.arrival_date);
    rowDownload.push(item.cancellation_reason);
    downloadData.push(rowDownload);
  });

  const tableData = {
    data: dadosHistorico,
    columns: colunasHistorico,
  };

  return (
    <Container
      title={`HISTÓRICO ORDENS DE SERVIÇO`}
      icon={"info-circle"}
      grid={1}
      expandable
      renderClosed
      downloadLabels={downloadLabels}
      downloadData={downloadData}
    >
      {loaded ? (
        <Box margin={"0 8px 8px"}>
          <InfoTable
            data={tableData}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            search
          />
        </Box>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
