import { GenIcon } from "react-icons";

function BottomArrow(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M5.4572 9.32218C5.88677 8.8926 6.58326 8.89261 7.01283 9.32219L11.9981 14.3075L16.9833 9.32221C17.4129 8.89263 18.1094 8.89263 18.539 9.32221C18.9685 9.75179 18.9685 10.4483 18.539 10.8778L12.7759 16.6409C12.5696 16.8472 12.2898 16.9631 11.9981 16.9631C11.7063 16.9631 11.4265 16.8472 11.2203 16.6409L5.45719 10.8778C5.02761 10.4482 5.02762 9.75175 5.4572 9.32218Z",
        },
      },
    ],
  })(props);
}

export default BottomArrow;
