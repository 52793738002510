import React from "react";
import App from "./App";
import store, { persistor } from "./app/store";
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux'


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} >
            <App />
        </PersistGate>
    </Provider>
);
