import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "@components/Container/styles";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MaterialTableComponent from "@components/MaterialTableComponent";
import Page from "@components/Page";
import LogisticService from "@LogisticService";
import { PageLayout } from "../../../../Layout/PageLayout";

const { TabPane } = Tabs;

const Ranking = (props) => {
  const location = useLocation();
  const [greenAngels, setGreenAngels] = useState(undefined);
  const [guardiansAngels, setGuardiansAngels] = useState(undefined);
  const [suppliersAngels, setSuppliersAngels] = useState(undefined);
  const [hostAngels, setHostAngels] = useState(undefined);
  const [distrital, setDistrital] = useState(undefined);
  const [regional, setRegional] = useState(undefined);

  const mes = location.state.month;
  const ano = location.state.year;

  useEffect(() => {
    fetchRankingData("greenAngel");
  }, []);

  const fetchRankingData = (cadeira) => {
    const rankingFactory = (tipo) =>
      ({
        greenAngel: LogisticService.getRankingAngel,
        guardianAngel: LogisticService.getRankingGuardian,
        supplierAngel: LogisticService.getRankingSupplier,
        hostAngel: LogisticService.getRankingHost,
        distrital: LogisticService.getRankingDistrital,
        // regional: LogisticService.getRankingRegional,
      }[tipo]);

    const ranking = rankingFactory(cadeira);

    ranking(ano, mes).then((response) => {
      if (cadeira === "greenAngel") {
        setGreenAngels(response.data);
      } else if (cadeira === "guardianAngel") {
        setGuardiansAngels(response.data);
      } else if (cadeira === "supplierAngel") {
        setSuppliersAngels(response.data);
      } else if (cadeira === "hostAngel") {
        setHostAngels(response.data);
      } else if (cadeira === "distrital") {
        setDistrital(response.data);
      } else if (cadeira === "regional") {
        setRegional(response.data);
      }
    });
  };

  function callback(key) {
    if (key === "greenAngel" && !greenAngels) {
      fetchRankingData(key);
    } else if (key === "guardianAngel" && !guardiansAngels) {
      fetchRankingData(key);
    } else if (key === "supplierAngel" && !suppliersAngels) {
      fetchRankingData(key);
    } else if (key === "hostAngel" && !hostAngels) {
      fetchRankingData(key);
    } else if (key === "distrital" && !distrital) {
      fetchRankingData(key);
    } else if (key === "regional" && !regional) {
      fetchRankingData(key);
    }
  }

  const prepararColunas = (data) => {
    const template = data;
    const colunasParaExibir = Object.keys(template[0]);

    let colunas = colunasParaExibir.map((coluna) => ({
      title: coluna,
      dataIndex: coluna,
      key: coluna,
      field: coluna,
      width: ["Nome", "Polo"].includes(coluna) ? 300 : 200,
    }));

    colunas.unshift({
      title: "#",
      dataIndex: "position",
      key: "position",
      field: "position",
      filtering: false,
      align: "center",
      width: 100,
      render: (rowData) => {
        if (rowData.position === 1) {
          return (
            <span className="icon">
              <FontAwesomeIcon icon={faTrophy} color="gold" />
              {rowData.position}
            </span>
          );
        }
        if (rowData.position === 2) {
          return (
            <span className="icon">
              <FontAwesomeIcon icon={faTrophy} color="silver" />
              {rowData.position}
            </span>
          );
        }
        if (rowData.position === 3) {
          return (
            <span className="icon">
              <FontAwesomeIcon icon={faTrophy} color="orange" />
              {rowData.position}
            </span>
          );
        }

        return rowData.position;
      },
    });

    return colunas;
  };

  const prepararLinhas = (data) => {
    const dadosFormatados = data.map((obj) => {
      const position = data.indexOf(obj) + 1;

      const objetoFormatado = {};

      Object.keys(obj).forEach((key) => {
        const result = obj[key];
        if (Number(result) === result && result % 1 !== 0) {
          objetoFormatado[key] = result.toLocaleString("pt-BR", {
            minimumFractionDigits: 1,
            style: "percent",
          });
        } else if (key.includes("Indicadores")) {
          const indicadores = JSON.parse(result) || [];
          indicadores.forEach((indicador) => {
            objetoFormatado[indicador.nome] = indicador.atingimento
              ? indicador.atingimento.toLocaleString("pt-BR", {
                  minimumFractionDigits: 1,
                  style: "percent",
                })
              : "-";
          });
        } else {
          objetoFormatado[key] = result;
        }
      });

      objetoFormatado.position = position;
      return objetoFormatado;
    });

    return dadosFormatados;
  };

  const RankingTable = (props) => {
    const { innerHeight } = window;

    return (
      <MaterialTableComponent
        columns={props.data ? prepararColunas(props.data.data) : []}
        data={props.data ? prepararLinhas(props.data.data) : []}
        isLoading={!props.data}
        title={props.title}
        options={{
          exportAllData: true,
          pageSize: 20,
          showTitle: true,
          padding: "dense",
          filtering: true,
          exportButton: true,
          maxBodyHeight: 0.6 * innerHeight,
          tableLayout: "fixed",
          columns: {
            align: "center",
          },
          // fixedColumns: {
          //   left: 5,
          //   right: 0,
          // },
        }}
      />
    );
  };

  return (
    <PageLayout>
      <Card>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Green Angel" key="greenAngel">
            <RankingTable data={greenAngels} title="Green Angels" />
          </TabPane>
          <TabPane tab="Guardian Angel" key="guardianAngel">
            <RankingTable data={guardiansAngels} title="Guardian Angels" />
          </TabPane>
          <TabPane tab="Supplier Angel" key="supplierAngel">
            <RankingTable data={suppliersAngels} title="Supplier Angels" />
          </TabPane>
          <TabPane tab="Host Angel" key="hostAngel">
            <RankingTable data={hostAngels} title="Host Angels" />
          </TabPane>
          <TabPane tab="Distritos" key="distrital">
            <RankingTable data={distrital} title="Distritos" />
          </TabPane>
        </Tabs>
      </Card>
    </PageLayout>
  );
};

export default Ranking;
