import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const Container = styled.div`
  background-color: ${defaultTheme.colors.white};
  border-radius: 0.75rem;
  max-height: calc(100vh - 362.2px);
  overflow-y: auto;
`;

export const Content = styled.div`
  padding: 1.5rem;
  
  > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  > div:last-child {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  padding: 1.5rem;
  font-size: ${defaultTheme.fontSize.g};
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${defaultTheme.colors.grey700};
  border-bottom: 1px solid ${defaultTheme.colors.grey70};
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${defaultTheme.colors.grey50};
  margin: 1.5rem 0;
`;

export const SubTitle = styled.div`
  color: ${defaultTheme.colors.grey700};
  font-size: ${defaultTheme.fontSize.m};
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.3px;
`;

export const Row = styled.div`
  display: flex; 
  > div:first-child {
    width: 30px;
  }

  > div:last-child {
    color: ${defaultTheme.colors.grey500};
    font-size: ${defaultTheme.fontSize.m};
    line-height: 145%;
    letter-spacing: -0.15px;
    margin-left: 0.2rem;
  }
`;
