import { GenIcon } from "react-icons";

function CloseIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 20 20",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M5.58928 4.4107C5.26384 4.08527 4.7362 4.08527 4.41076 4.4107C4.08533 4.73614 4.08533 5.26378 4.41076 5.58921L8.82151 9.99996L4.41076 14.4107C4.08533 14.7361 4.08533 15.2638 4.41076 15.5892C4.7362 15.9147 5.26384 15.9147 5.58928 15.5892L10 11.1785L14.4108 15.5892C14.7362 15.9147 15.2638 15.9147 15.5893 15.5892C15.9147 15.2638 15.9147 14.7361 15.5893 14.4107L11.1785 9.99996L15.5893 5.58921C15.9147 5.26378 15.9147 4.73614 15.5893 4.4107C15.2638 4.08527 14.7362 4.08527 14.4108 4.4107L10 8.82145L5.58928 4.4107Z",
        },
      },
    ],
  })(props);
}
export default CloseIcon;
