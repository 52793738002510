import React, { memo } from "react";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";

import { Container, PreviewContainer } from "./styles";

const InputFile = ({ base64image, handleChange, clear }) => (
  <>
    {base64image ? (
      <PreviewContainer>
        <img src={base64image} alt="Preview" />
        <button onClick={clear}>
          <DeleteOutlined />
        </button>
      </PreviewContainer>
    ) : (
      <Container>
        <label for="arquivo">
          <UploadOutlined />
          Enviar arquivo
        </label>
        <input
          type="file"
          id="arquivo"
          name="arquivo"
          accept="image/png, image/jpeg"
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </Container>
    )}
  </>
);

export default memo(InputFile);
