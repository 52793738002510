import React, { useState } from "react";
import LogisticService from "@LogisticService";
import { PageLayout } from "../../../../Layout/PageLayout";
import { AtrasoParceiro } from "./AtrasoParceiro";
import AnexosMotivos from "./AnexosMotivos";
import PageHeader from "@components/PageHeader";
import Breadcrumbs from "../../../../components/Breadcumbs";
import HeaderProvider from "../../../../components/HeaderProvider";

export default function MotivosAtraso() {
  const [loading, setLoading] = useState(false);
  const [delayReasons, setdelayReasons] = useState();
  const [lastUpdate, setLastUpdate] = useState();

  const [selectedInfo, setSelectedInfo] = useState();

  const searchAction = async (selectedProvider) => {
    let provider = selectedProvider;
    if (selectedInfo && selectedInfo.selectedProvider)
      provider = selectedInfo.selectedProvider;

    setLoading(true);
    await LogisticService.getMotivosAtrasoParceiro(provider).then(
      async (response) => {
        setLoading(false);
        setdelayReasons(response.data);
        setLastUpdate(new Date());
      }
    );
  };

  return (
    <PageLayout>
      <div>
        <Breadcrumbs />
        <PageHeader title="Motivos de atraso" />
        <HeaderProvider
          searchButtonAction={searchAction}
          setSelectedInfo={setSelectedInfo}
        />

        {((selectedInfo && selectedInfo.canEdit) || loading) && (
          <>
            <AtrasoParceiro
              reasons={delayReasons}
              selectedInfo={selectedInfo}
              lastUpdate={lastUpdate}
              loading={loading}
            />
            <AnexosMotivos
              loadingLayout={loading}
              searchAction={searchAction}
            />
          </>
        )}
      </div>
    </PageLayout>
  );
}
