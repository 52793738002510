import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const ProgressArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: 1rem;

  &:first-child {
    margin-left: 0;
  }

  span {
    width: 55px;
    text-align: center;
    color: ${defaultTheme.colors.grey700};
    font-size: ${defaultTheme.fontSize.s};
    line-height: 145%;
    letter-spacing: -0.13px;
  }

  
  .ginga-progress .ant-progress-circle {
    width: 100% !important;
  }

  .ginga-progress .ant-progress-inner {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const Score = styled.div`
  color: ${defaultTheme.colors.grey700};
  font-size: ${defaultTheme.fontSize.g};
  font-weight: 900;
  text-transform: capitalize;

  > div {
    color: ${defaultTheme.colors.grey500};
    font-size: ${defaultTheme.fontSize.xs};
    line-height: 120%;
    font-weight: 400;
    letter-spacing: -0.12px;
  }
`;

export const ColorBadge = styled.div`
  width: 1rem;
  height: 0.5rem;
  background: ${({ color }) => color};
  border-radius: 100px;
  margin: 1rem 0 0.25rem 0;
`;

export const RowTooltip = styled.div`
  display: flex;
  align-items: center;
  font-size: ${defaultTheme.fontSize.s};
  color: #fff;
  margin-top: 0.5rem;
  line-height: 0.9rem;
  letter-spacing: -0.01em;
  line-height: 1.178125rem;

  &:first-child {
    margin-top: 0;
  }

  & > span {
    margin-left: 0.25rem;
  }
`;