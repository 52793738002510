import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button } from "antd";

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${defaultTheme.colors.grey50};
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.s};
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${defaultTheme.colors.grey700};
  cursor: pointer;

  svg:first-child {
    margin-right: 0.5rem;
    margin-top: -1.5px;
  }

  & > span {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.25rem;
      margin-top: -0.24px;
    }
  }

  &:hover {
    background-color: ${defaultTheme.colors.grey60};
  }
`;

export const Content = styled.div`
  & > div:first-child {
    font-size: ${defaultTheme.fontSize["heading-s"]};
    line-height: 24px;
    letter-spacing: -0.02em;
    font-weight: 700;
    color: ${defaultTheme.colors.grey700};
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    svg {
      margin-top: 1px;
    }
  }

  & > span {
    font-weight: 600;
    font-size: ${defaultTheme.fontSize.m};
    line-height: 22px;
    letter-spacing: -0.01em;
    color: ${defaultTheme.colors.grey500};
  }
`;

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
`;

export const FilterOption = styled.div`
  padding: 0.625rem 0.75rem;
  border-radius: 0.5rem;
  background-color: ${({ active }) =>
    active ? defaultTheme.colors.green60 : defaultTheme.colors.grey50};
  color: ${({ active }) =>
    active ? defaultTheme.colors.green700 : defaultTheme.colors.grey700};
  border: 1.5px solid
    ${({ active }) => (active ? defaultTheme.colors.green600 : "transparent")};
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.s};
  line-height: 16px;
  letter-spacing: -0.01em;
  cursor: pointer;
  margin: 0 0.25rem 0.25rem 0;

  &:last-child {
    margin-right: 0;
  }
`;

export const FilterButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  padding: 0;
  margin-top: 1.25rem;
  border-radius: 0.5rem;
  background-color: ${defaultTheme.colors.green600} !important;
  font-size: ${defaultTheme.fontSize.s};
  line-height: 1rem !important;
  border: none;

  & > span {
    font-weight: 600;
    font-size: 13px;
    line-height: 1rem !important;
    letter-spacing: -0.01em;
    color: ${defaultTheme.colors.white};
    margin-top: 2px;
  }

  & > svg {
    fill: #000 !important;
    &:hover {
      fill: #000 !important;
    }
  }

  &:hover {
    background-color: ${defaultTheme.colors.green700} !important;
  }
`;
