import styled from "styled-components";
import Colors from "@colors";

export const TableBox = styled.div`
  svg {
    transition: 0.2s;
    &:hover {
      fill: ${Colors.lightGreen};
    }
  }
`;

export const TableArea = styled.div`
  max-height: calc(100vh - 300px);
  position: absolute;
    position: absolute;
    max-width: 94%;
    z-index: 1;
    left: 68px;
    top: 10rem;
    padding: 1rem;
`;
