import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Space,
  Checkbox,
} from "antd";
import { default as React, useEffect, useState } from "react";
import Box from "@components/Box";
import CustomButton from "@components/CustomButton";
import CustomLink from "@components/CustomLink";
import LoadingSpinner from "@components/LoadingSpinner";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import { FormItem, SugestoesTitle } from "../styles";
import { useAuthContext } from "@stonelog/stonelog-auth-components";

const { Option } = Select;

export default function AberturaCaso(props) {
  const auth = useAuthContext();
  const userName = auth.getUser();
  const [form] = Form.useForm();
  const reasonFineCheck = Form.useWatch("reasonFineCheck", form);
  const [loaded, setLoaded] = useState(false);
  const [loadedCaso, setLoadedCaso] = useState(true);
  const [opcoesMapeamento, setOpcoesMapeamento] = useState(null);
  const [listaSugestoes, setListaSugestoes] = useState([]);
  const [fineChecked, setFineChecked] = useState(false);
  const [feeChecked, setFeeChecked] = useState(false);
  const sugestoes = [];

  useEffect(() => {
    let defaultValues = {
      stonecode: props.stonecode,
      email: `|${userName.email}|`,
      status: "Resolvido",
      nome_conta: "teste123",
      email: "mariana.brito@stone.com.br",
    };
    form.setFieldsValue(defaultValues);
    LogisticService.getInformacoesGerais(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        const data = response.data;
        defaultValues = {
          ...defaultValues,
          nome_conta: `${data.information.fantasyName}|${props.stonecode}`,
        };
        form.setFieldsValue(defaultValues);
      }
      LogisticService.getArvoreCasos().then((response) => {
        if (response && response.data !== null) {
          const data = response.data;
          const sugestoes_temp = [];
          const options = Object.keys(data).map((tema) => {
            return {
              value: tema,
              label: tema,
              children: Object.keys(data[tema]).map((categoria) => {
                return {
                  value: categoria,
                  label: categoria,
                  children: data[tema][categoria].map((assunto) => {
                    sugestoes_temp.push(
                      `${tema || ""};${categoria || ""};${assunto || ""}`
                    );
                    return {
                      value: assunto,
                      label: assunto,
                    };
                  }),
                };
              }),
            };
          });
          setOpcoesMapeamento(options);
          const primeria_sug = [
            "CaçaPOS - Stone",
            "Inatividade",
            "Faltou endereço",
          ];
          const segunda_sug = sugestoes_temp
            .slice(9, 10)
            .map((item) => item.split(";"));
          const terceira_sug = ["CaçaPOS - Stone", "Recuperação"];

          sugestoes.push(primeria_sug, segunda_sug, terceira_sug);
          setListaSugestoes(sugestoes);
          setLoaded(true);
        }
      });
    });
  }, []);

  const abrirCaso = (values) => {
    const request = {
      status: values.status,
      description: values.descricao,
      subject:
        (values.demanda_realizada[0] || "") +
        ">" +
        (values.demanda_realizada[1] || "") +
        ">" +
        (values.demanda_realizada[2] || ""),
      retornar_cliente: values.retornar_cliente,
      accountid: props.stonecode,
      email: userName.email,
      canal: values.canal,
      tema_cobranca: values.demanda_realizada[0] || "",
      categoria_cobranca: values.demanda_realizada[1] || "",
      assunto_cobranca: values.demanda_realizada[2] || "",
      qntd_de_pos: values.qntd_de_pos,
      acao_tomada: values.acao_tomada || "",
      reasonFineCheck: values.reasonFineCheck,
    };

    setLoadedCaso(false);

    LogisticService.postAberturaCaso(request).then((response) => {
      setLoadedCaso(true);
      if (response && response.status == 200 && response.data != null) {
        message.success(response.data);
        if (feeChecked) {
          const caseNumber = response.data.split(" ")[1];
          window.open(
            `https://docs.google.com/forms/d/e/1FAIpQLSdsXeORgkw85HtCy3ZVnOvxLz5jz3hl7cC_F3XHo278c_Szbw/viewform?entry.256123952=${caseNumber}&entry.1354380736=${props.stonecode}`,
            "_blank"
          );
        }
        props.visible(false);
        setFeeChecked(false);
        setFineChecked(false);
        form.resetFields([
          "assunto",
          "retornar_cliente",
          "descricao",
          "canal",
          "demanda_realizada",
          "qntd_de_pos",
          "acao_tomada",
          "reasonFineCheck",
        ]);
      } else if (response.title) {
        message.error(response.title);
      } else {
        message.error(`Erro ao abrir caso.`);
      }
    });
  };

  const filtroDemanda = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  return loaded ? (
    <>
      <Form
        form={form}
        layout={"vertical"}
        onValuesChange={(_, allValues) => {
          form.setFieldsValue(allValues);
        }}
        onFinish={abrirCaso}
      >
        <Row horizontalAlignResponsive={"center"} wrap>
          <Box
            noFlex
            width={"47.5%"}
            maxWidth={"600px"}
            widthMediumScreenSize={"100%"}
            title="Registro do caso"
          >
            <FormItem
              label="Nome da conta"
              name={"nome_conta"}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              label="Usuário criador"
              name={"email"}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              label="Descrição do Caso"
              name={"descricao"}
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </FormItem>
            <Row nonResponsive>
              <FormItem
                label="Status"
                name={"status"}
                style={{ marginRight: "8px", width: "100%" }}
              >
                <Select defaultValue={"Whatsapp"}>
                  <Option value={"Novo"}>Novo</Option>
                  <Option value={"Aberto"}>Aberto</Option>
                  <Option value={"Pendente"}>Pendente</Option>
                  <Option value={"Em espera"}>Em espera</Option>
                  <Option value={"Resolvido"}>Resolvido</Option>
                  <Option value={"Fechado"}>Fechado</Option>
                </Select>
              </FormItem>
              <FormItem
                label="Canal"
                name={"canal"}
                style={{ marginLeft: "8px", width: "100%" }}
                rules={[{ required: true }]}
              >
                <Select placeholder="Selecione">
                  <Option value={"Whatsapp"}>Whatsapp</Option>
                  <Option value={"Email Stone"}>E-mail</Option>
                  <Option value={"Telefone Stone"}>Telefone</Option>
                  <Option value={"Discador"}>Discador</Option>
                </Select>
              </FormItem>
            </Row>
            {/* <Row nonResponsive>
                                <FormItem label="Nome do contato" name={'nome_contato'} style={{ marginRight: '8px', width: '100%' }}>
                                    <Input />
                                </FormItem>
                                <FormItem label="Telefone do caso" name={'telefone'} style={{ marginLeft: '8px', width: '100%' }}>
                                    <Input />
                                </FormItem>
                            </Row> */}
            <FormItem
              label="Quantidade POS"
              name={"qntd_de_pos"}
              rules={[{ required: true }]}
            >
              <Input type={"number"} />
            </FormItem>
            <FormItem
              label="Ação tomada"
              name={"acao_tomada"}
              rules={[{ required: false }]}
            >
              <Input.TextArea />
            </FormItem>
          </Box>
          <Box
            noFlex
            width={"47.5%"}
            maxWidth={"600px"}
            widthMediumScreenSize={"100%"}
          >
            <Box noFlex title="Mapear árvore do caça">
              <Form.Item
                name="demanda_realizada"
                label="Mapeamento de árvore"
                labelCol={{ span: 24 }}
                rules={[
                  { required: true, message: "Selecione a Demanda Realizada!" },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Cascader
                  placeholder={"Selecione"}
                  options={opcoesMapeamento}
                  showSearch={{ filter: filtroDemanda }}
                />
              </Form.Item>
              <Box verticalAlign="center">
                <SugestoesTitle>Sugestões:</SugestoesTitle>
                {listaSugestoes.map((sugestao) => {
                  const str = sugestao.join(" / ");
                  const option =
                    str.length > 70
                      ? str.substr(0, 70 - 1).replace(/,/g, " / ") + "..."
                      : str.replace(/,/g, " / ");
                  return (
                    <Button
                      onClick={() =>
                        form.setFieldsValue({ demanda_realizada: sugestao })
                      }
                      style={{ marginBottom: 8, borderRadius: "50px" }}
                    >
                      {option}
                    </Button>
                  );
                })}
              </Box>
            </Box>
            <Box noFlex title="Retornar Cliente">
              <FormItem name={"retornar_cliente"}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  style={{ width: "100%" }}
                />
              </FormItem>
            </Box>
            <Box noFlex title="Perdões de dívida">
              <Checkbox
                checked={fineChecked}
                onChange={() => setFineChecked(!fineChecked)}
              >
                Perdão de multa
              </Checkbox>
              {fineChecked ? (
                <>
                  <FormItem
                    label="Motivo"
                    name={"reasonFineCheck"}
                    style={{ marginLeft: "8px", width: "100%" }}
                    rules={[{ required: fineChecked }]}
                  >
                    <Select placeholder="Selecione o motivo">
                      <Option value={"fechamento/falência do EC"}>
                        fechamento/falência do EC
                      </Option>
                      <Option
                        value={
                          "se negou a devolver a máquina por causa da multa"
                        }
                      >
                        se negou a devolver a máquina por causa da multa
                      </Option>
                      <Option value={"outros"}>outros</Option>
                    </Select>
                  </FormItem>
                  {reasonFineCheck == "outros" ? (
                    <FormItem
                      style={{ marginLeft: "10%" }}
                      label="justificativa"
                      name={"other_reason"}
                      rules={[{ required: reasonFineCheck == "outros" }]}
                    >
                      <Input />
                    </FormItem>
                  ) : (
                    <></>
                  )}{" "}
                </>
              ) : (
                <></>
              )}

              <Checkbox
                style={{ margin: "0" }}
                checked={feeChecked}
                onChange={() => setFeeChecked(!feeChecked)}
              >
                Perdão de mensalidade
              </Checkbox>
            </Box>
          </Box>
        </Row>
        <Row horizontalAlign={"flex-end"} margin={"24px 0 0"} nonResponsive>
          <Space>
            <CustomButton htmlType="submit" disabled={!loadedCaso}>
              {loadedCaso ? <></> : <LoadingOutlined spin />}
              Cadastrar
            </CustomButton>
            <CustomLink
              danger
              onClick={() => {
                props.visible(false);
              }}
            >
              Cancelar
            </CustomLink>
          </Space>
        </Row>
      </Form>
    </>
  ) : (
    <LoadingSpinner />
  );
}
