import styled from "styled-components";


export const InsideDrawerContent = styled.div`
  position: absolute;
  left: 68px;
  bottom: 0;
  height: calc(100% - 48px);
  z-index: 1;
`;
