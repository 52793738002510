import React, { useState } from "react";
import { PageLayout } from "../../../../Layout/PageLayout";
import PageHeader from "@components/PageHeader";
import Breadcrumbs from "@components/Breadcumbs";
import HeaderProvider from "@components/HeaderProvider";
import { ReportDOA } from "../../../LastMile/Senninha/componentes/Ferramentas/ReportarDOA";

export default function ReportDoaTerceira() {
  const [loading, setLoading] = useState(false);
  const [drawerDoaVisible, setDrawerDoaVisible] = useState(false);

  const [selectedInfo, setSelectedInfo] = useState("");

  const searchAction = async (selectedProvider) => {
    let provider = selectedProvider;
    if (selectedInfo && selectedInfo.selectedProvider)
      provider = selectedInfo.selectedProvider;
  };

  return (
    <PageLayout>
      <div>
        <Breadcrumbs />
        <PageHeader title="Reportar DOA" />
        <HeaderProvider
          searchButtonAction={searchAction}
          setSelectedInfo={setSelectedInfo}
          setDrawerDoaVisible={setDrawerDoaVisible}
        />

        {((selectedInfo && selectedInfo.canEdit) || loading) && (
          <>
            {selectedInfo.selectedProviderName.length > 0 && (
              <ReportDOA
                polo={selectedInfo.selectedProviderName}
                drawerDoaVisible={drawerDoaVisible}
                setDrawerDoaVisible={setDrawerDoaVisible}
              />
            )}
          </>
        )}
      </div>
    </PageLayout>
  );
}
