import { LoadingOutlined } from "@ant-design/icons";
import { Descriptions, message, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import CustomModal from "@components/CustomModal";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import { Alert, CopyLine } from "../styles";

export default function Contrato(props) {
  const loading = <LoadingOutlined spin />;
  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    LogisticService.getInformacoesGerais(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        setData(response.data);
      }
      setOpenModal(false);
      setLoaded(true);
    });
  }, [props.stonecode]);

  const copiaNoClick = (title, texto) => {
    navigator.clipboard.writeText(texto);
    message.info(`${title} copiado com sucesso!`);
  };

  return (
    <Container
      title={`Informações de Contrato`}
      icon={"info-circle"}
      grid={1}
      expandable
    >
      {loaded ? (
        <Box margin={"0 8px 8px"}>
          {data ? (
            <Descriptions bordered column={1} size={"small"}>
              <Descriptions.Item
                label={data.information.documentType === 1 ? "CNPJ" : "CPF"}
              >
                <Tooltip title="Copiar" placement="right">
                  <CopyLine
                    onClick={() =>
                      copiaNoClick(
                        data.information.documentType === 1 ? "CNPJ" : "CPF",
                        data.information.document
                      )
                    }
                  >
                    {data.information.document}
                  </CopyLine>
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label={"Status de credenciamento"}>
                <Alert noDot>{data.status.description}</Alert>
              </Descriptions.Item>
              <Descriptions.Item label={"Data de credenciamento"}>
                {data.information.affiliationDate}
              </Descriptions.Item>
              <Descriptions.Item label={"Nome fantasia"}>
                {data.information.fantasyName}
              </Descriptions.Item>
              <Descriptions.Item label={"E-mail"}>
                <Tooltip title="Copiar" placement="right">
                  <CopyLine
                    onClick={() =>
                      copiaNoClick("E-mail", data.information.email)
                    }
                  >
                    {data.information.email}
                  </CopyLine>
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label={"Razão Social"}>
                {data.information.legalName}
              </Descriptions.Item>
              <Descriptions.Item label={"Ramo de Atividade"}>
                {data.information.merchantCategory}
              </Descriptions.Item>
              {/* {data.information.code == 6 ?
                                <CustomButton style={{ margin: "0 16px" }} onClick={() => setOpenModal(true)}>Desativar</CustomButton>
                                : <></>
                            } */}
            </Descriptions>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
      <CustomModal
        title={`Desativação`}
        visible={openModal}
        zIndex={1010}
        onCancel={() => setOpenModal(false)}
        onOk={() => {}}
      >
        <Row>Form desativação</Row>
      </CustomModal>
    </Container>
  );
}
