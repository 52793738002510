import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import { StyledButton } from "./styles";

const DetailButton = (props) => {
  return (
    <StyledButton onClick={props.onClick}>
      {props.icon ? props.icon : <SearchOutlined />}
    </StyledButton>
  );
};

export default DetailButton;
