import styled from "styled-components";
import Colors from "@colors";

export const FiltrosBox = styled.div`
  width: 20vw;
  min-width: 400px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
`;

export const FilterGroup = styled.div`
  margin-bottom: 16px;
`;

export const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Tag = styled.div`
  transition: 0.2s;
  border: 1px solid ${Colors.lightGray};
  background-color: ${(props) =>
    props.all ? Colors.lightShadowColor : "transparent"};
  margin: 8px 8px 4px 0;
  cursor: ${(props) => (props.add ? "pointer" : "default")};
  border-radius: 4px;
  padding: 2px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    background-color: ${Colors.lightShadowColor};
  }
`;

export const TagTitle = styled.p`
  font-size: 12px;
  ${(props) =>
    props.marginLeft
      ? "margin-left: 8px"
      : props.marginRight
      ? "margin-right: 8px"
      : ""};
`;

export const FilterTitle = styled.p`
  margin-bottom: 4px;
  font-size: 14px;
  color: ${Colors.textGreen};
`;

export const Title = styled.p`
  margin: 8px 0;
  font-size: 16px;
  color: ${Colors.deepBlue};
`;

export const Info = styled.p`
  margin: 0 0 16px;
  font-size: 14px;
  text-align: left;
`;

export const RemoveAll = styled.div`
  margin-left: 24px;
  svg {
    transition: 0.2s;
    &:hover {
      fill: ${Colors.lightGreen};
    }
  }
`;

export const ExtraInfo = styled.span`
  margin-right: 4px;
  margin-left: -4px;
  font-size: 11px;
  text-transform: lowercase;
`;
