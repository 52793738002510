import React, { useEffect, useState } from "react";
import LogisticService from "@LogisticService";
import { PageLayout } from "../../../../Layout/PageLayout";
import PageHeader from "@components/PageHeader";
import HeaderProvider from "@components/HeaderProvider";
import Breadcrumbs from "../../../../components/Breadcumbs";
import TableStone from "./TableStone";
import TableTon from "./TableTon";
import { toCapitalizeFirstLetterOfEachWord } from "../../../../consumingApi/services/helper";
import InfoIcon from "@assets/icons/InfoIcon";
import { Tooltip } from "antd";
import useRouter from "../../../../hooks/useRouter";

import * as S from "./styles";

export default function CoberturaEstoque() {
  const [dataStone, setDataStone] = useState(null);
  const [loadingStone, setLoadingStone] = useState(false);
  const [lastUpdateStone, setLastUpdateStone] = useState();
  const [dataTon, setDataTon] = useState(null);
  const [loadingTon, setLoadingTon] = useState(false);
  const [lastUpdateTon, setLastUpdateTon] = useState();
  const [selectedInfo, setSelectedInfo] = useState(null);

  const {
    history,
    query: locationData,
  } = useRouter();

  const region = locationData?.regiao?.split('=').length > 1 ? 
    locationData.regiao.split('=')[1] 
    : (locationData?.regiao || '')
  const district = locationData?.distrito?.split('=').length > 1 ? 
    locationData.distrito.split('=')[1] 
    : (locationData?.distrito || '')
  const pole = locationData?.polo?.split('=').length > 1 ? 
    locationData.polo.split('=')[1] 
    : (locationData?.polo || '')

  const getAllData = (selectedProviderName) => {
    setLoadingStone(true);
    setLoadingTon(true);

    LogisticService.getCoberturaEstoqueStone(selectedProviderName)
      .then(async (response) => {
        setDataStone(
          Object.keys(response.data).length === 0 ? undefined : response.data
        );
        setLoadingStone(false);
        setLastUpdateStone(new Date());
      })
      .catch((e) => {
        setLoadingStone(false);
      });

    LogisticService.getCoberturaEstoqueTon(selectedProviderName)
      .then(async (response) => {
        setDataTon(
          Object.keys(response.data).length === 0 ? undefined : response.data
        );
        setLoadingTon(false);
        setLastUpdateTon(new Date());
      })
      .catch((e) => {
        setLoadingTon(false);
      });
  };

  useEffect(() => {
    if (pole) 
      getAllData(pole)
  }, [pole]);

  const searchAction = async (_, selectedProviderName) =>
    getAllData(selectedProviderName);

  const handleGoBack = () => {
    console.log('voltando', region, district, pole)
    history.push({
      pathname: "/senninha",
      state: {
        region,
        district,
        pole,
      },
    })
  }

  return (
    <PageLayout>
      <div>
        <Breadcrumbs />
        <PageHeader
        title="Cobertura de estoque"
        onGoBack={handleGoBack}
        />

        {JSON.stringify(locationData) !== JSON.stringify({}) ? (
          <S.HeaderSeninha>
            <S.TitleText>
              {`${region} > ${district} > ${toCapitalizeFirstLetterOfEachWord(pole)}`}
            </S.TitleText>
            <Tooltip
              title={() => (
                <S.ToolTipBox>
                  Para visualizar informações de outros polos, volte ao Senninha
                  e redefina as informações.
                </S.ToolTipBox>
              )}
              placement="topRight"
              overlayClassName="ginga-tooltip ginga-tooltip-top-right"
              overlayInnerStyle={{ marginRight: -18, marginLeft: 18 }}
            >
              <InfoIcon size={24} />
            </Tooltip>
          </S.HeaderSeninha>
        ) : (
          <HeaderProvider
            searchButtonAction={searchAction}
            setSelectedInfo={setSelectedInfo}
          />
        )}

        {((selectedInfo && selectedInfo.canEdit) ||
          (loadingStone && loadingTon) ||
          locationData?.polo) && (
          <>
            <TableStone
              lastUpdate={lastUpdateStone}
              data={dataStone}
              loading={loadingStone}
            />
            <TableTon
              lastUpdate={lastUpdateTon}
              data={dataTon}
              loading={loadingTon}
            />
          </>
        )}
      </div>
    </PageLayout>
  );
}
