import { Input, InputNumber } from "antd";
import styled from "styled-components";
import Colors from "@colors";

const style = `
    width: 100%;
    height: 40px;
    border-radius: 30px;
    background: ${Colors.white};
    border: 2px solid ${Colors.textGreen};
    padding: 8px;
    min-width: 334px;
    div, input {
        height: 100%;
    }
    @media (max-width: 600px) {
        width: 50vw;
    }
`;

export const DefaultInput = styled(Input)`
  ${style}
`;

export const DefaultInputNumber = styled(InputNumber)`
  ${style}
`;
