import styled from "styled-components";
import Colors from "@colors";

export const LegendaBox = styled.div`
  width: 34.4375rem;
  min-width: 400px;
  position: absolute;
  bottom: 16px;
  right: 24px;
  display: flex;
  @media (max-width: 700px) {
    position: absolute;
    left: 2rem;
    bottom: 5rem;
    min-width: 91vw;
  }
`;

export const LegendaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const Text = styled.p`
  margin-left: 16px;
  font-size: 16px;
  color: ${(props) => (props.disabled ? Colors.gray : Colors.deepBlue)};
`;

export const ExpandButton = styled.div`
  padding: 8px;
  margin-right: 8px;
  position: relative;
  height: 24px;
  width: 24px;
  cursor: pointer;
  &::before {
    content: "";
    width: 16px;
    height: 2px;
    background-color: ${(props) =>
      props.disabled ? Colors.gray : Colors.deepBlue};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    content: "";
    width: 2px;
    height: 16px;
    background-color: ${(props) =>
      props.disabled ? Colors.gray : Colors.deepBlue};
    position: absolute;
    transition: 0.2s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
      ${(props) => (props.open ? "rotate(90deg)" : "rotate(0deg)")};
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 16px 0px 8px;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  img {
    width: 28px;
  }
`;

export const Detalhes = styled.div`
  max-height: ${(props) => (props.open ? "100%" : "0")};
  width: 93%;
  background-color: ${Colors.lighterGray};
  padding: ${(props) => (props.open ? "8px" : "0")} 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 0.2s;
  &::before {
    content: "";
    width: 2px;
    height: calc(100% - 16px);
    background: ${Colors.gray};
    position: absolute;
    top: 0;
    left: 2.7%;
    @media (max-width: 1700px) {
      left: 3.5%;
    }
  }
`;

export const DetalheOS = styled.p`
  padding-left: 40px;
  position: relative;
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    background: ${Colors.gray};
    position: absolute;
    left: 0.5%;
    border-radius: 100%;
    top: 10px;
    transform: translateY(-50%);
  }
`;

export const DetalheOSNumber = styled.span`
  font-weight: bold;
  color: ${Colors.lightGreen};
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    color: ${Colors.textGreen};
  }
`;

export const DetalheStonecode = styled.span`
  font-size: 10px;
`;

export const OSsVisiveisButton = styled.span`
  padding: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OSCount = styled.span`
  font-size: 14px;
  margin: 0 8px;
  color: ${Colors.textGreen};
`;

export const LegendTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 16.1px 0px;

  .ant-select {
    width: 100% !important;
  }
  .ant-space-item {
    width: 100% !important;
  }
`;

export const LegendLeft = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-right: 12.83px;
  }
`;
export const LegendRight = styled.div`
  width: 60%;
`;
