import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import CustomModal from "@components/CustomModal";
import DetailButton from "@components/DetailButton";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { Link } from "../styles";
import DetalhesOS from "./DetalhesOS";

export default function OSsPendentesDistrito(props) {
  const title = "OSs Pendentes";
  const [oSsPendentesDistrito, setOSsPendentesDistrito] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [visibleModal, setVisibleModal] = useState(undefined);
  const [visibleDetalhesOs, setVisibleDetalhesOs] = useState(false);
  const [oSSelecionada, setOSSelecionada] = useState(undefined);

  useEffect(() => {
    LogisticService.getPendentesDistrito(props.id_distrito).then((response) => {
      const result = [];
      response.data.forEach((item) => {
        result.push({
          data: item.data,
          polo: item.polo,
          volume_oss: item.volume_oss,
          oss: item.oss,
        });
      });
      setOSsPendentesDistrito(result);
      setLoaded(true);
    });
  }, [props.id_distrito]);

  const dados = [];
  const colunas = [{ label: "Polo", key: "polo", align: "start" }];
  const polos = [];
  const datas = [];
  const downloadLabels = ["Polo"];
  const downloadData = [];
  const modalData = [];

  oSsPendentesDistrito.forEach((item) => {
    if (!polos.includes(item.polo)) {
      polos.push(item.polo);
    }
    if (!datas.includes(item.data)) {
      datas.push(item.data);
    }
  });

  polos.forEach((polo) => {
    const row = { polo: polo };
    oSsPendentesDistrito.forEach((item, id) => {
      if (item.polo === polo) {
        row[item.data] = (
          <>
            {item.volume_oss}{" "}
            <DetailButton onClick={() => setVisibleModal(id)} />
          </>
        );
        row[item.data + "_num"] = item.volume_oss;
        modalData.push(
          <CustomModal
            title={`OSs que vencem em ${item.data} ${item.polo}`}
            key={id}
            visible={visibleModal === id}
            zIndex={1000}
            onCancel={() => setVisibleModal(undefined)}
            footer={null}
          >
            <Box maxHeight={"400px"} margin={"0"} padding={"0"} scroll>
              {item.oss.map((os) => (
                <Link
                  onClick={() => {
                    setOSSelecionada(os);
                    setVisibleDetalhesOs(true);
                  }}
                >
                  {os}
                </Link>
              ))}
            </Box>
          </CustomModal>
        );
      }
    });
    dados.push(row);
  });

  dados.forEach((item) => {
    const rowDownload = [item.polo];
    datas.forEach((data) => {
      if (item[data + "_num"]) {
        rowDownload.push(item[data + "_num"]);
      } else {
        rowDownload.push("");
      }
    });
    downloadData.push(rowDownload);
  });

  datas.forEach((data) => {
    colunas.push({ label: data, key: data });
    downloadLabels.push(data);
  });

  const tableData = {
    data: dados,
    columns: colunas,
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title + " - " + props.name}
      info={[`OSs Pendentes ${props.name}`]}
      downloadLabels={loaded ? downloadLabels : []}
      downloadData={loaded ? downloadData : []}
    >
      {loaded ? <InfoTable data={tableData} /> : <LoadingSpinner />}
      <CustomModal
        title={`Detalhes OS ${oSSelecionada}`}
        visible={visibleDetalhesOs}
        zIndex={1010}
        onCancel={() => setVisibleDetalhesOs(false)}
        footer={null}
      >
        <DetalhesOS os={oSSelecionada} />
      </CustomModal>
      {modalData}
    </Container>
  );
}
