import React from "react";
import Mapcockpit from "@assets/imgs/Mapcockpit.gif";
import axios from "axios";

import * as S from "./styles";

const SecondStep = ({
  setIsModalOpen,
  setStep,
  totalAmount,
  progress,
  changeCount,
  sourceAxios,
  resetRequestsData,
  setSourceAxios,
}) => (
  <>
    <S.Header style={{ justifyContent: "center" }}>
      Definindo roteirização...
    </S.Header>
    <S.Content>
      <S.Map src={Mapcockpit} />

      <S.ProgressBar progress={progress}>
        <div />
      </S.ProgressBar>

      <S.ProgressText>
        {`${changeCount} de ${totalAmount} concluído (${progress}%)`}
      </S.ProgressText>

      <div>
        <S.ModalButton
          onClick={() => {
            sourceAxios.cancel("Operation canceled by the user");
            resetRequestsData();
            setIsModalOpen(false);
            setStep("firstStep");
            setSourceAxios(axios.CancelToken.source());
          }}
        >
          Cancelar
        </S.ModalButton>
      </div>
    </S.Content>
  </>
);

export default SecondStep;
