const mapColors = {
  1: "#FF1493",
  2: "#1595ee",
  3: "#FF6347",
  4: "#00008B",
  5: "#FF00FF",
  6: "#8B0000",
  7: "#FF8C00",
  8: "#0e7eb3",
  9: "#DC143C",
  10: "#9ACD32",
  11: "#A0522D",
  12: "#cbd05e",
  13: "#BA55D3",
  14: "#00CED1",
  15: "#DEB887",
  16: "#4B0082",
  17: "#FFD700",
  18: "#DB7093",
  19: "#0000FF",
  20: "#FFB6C1",
  21: "#778899",
  22: "#E2725B",
  23: "#A2006D",
  24: "#0014A8",
  25: "#78866B",
  26: "#008B8B",
  27: "#00DDFF",
  28: "#808000",
  29: "#2F4F4F",
};

export default mapColors;
