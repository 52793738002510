import { GenIcon } from "react-icons";

function PinIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 25",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M12 7.4375C10.9475 7.4375 9.93305 7.84439 9.18099 8.57677C8.42803 9.31002 7.99998 10.3103 7.99998 11.359C7.99998 12.4077 8.42803 13.408 9.18099 14.1412C9.93305 14.8736 10.9475 15.2805 12 15.2805C13.0524 15.2805 14.0669 14.8736 14.819 14.1412C15.5719 13.408 16 12.4077 16 11.359C16 10.3103 15.5719 9.31002 14.819 8.57677C14.0669 7.84439 13.0524 7.4375 12 7.4375ZM10.5763 10.0096C10.9495 9.64621 11.4611 9.4375 12 9.4375C12.5388 9.4375 13.0505 9.64621 13.4236 10.0096C13.7959 10.3721 14 10.8581 14 11.359C14 11.8599 13.7959 12.3459 13.4236 12.7084C13.0505 13.0718 12.5388 13.2805 12 13.2805C11.4611 13.2805 10.9495 13.0718 10.5763 12.7084C10.2041 12.3459 9.99998 11.8599 9.99998 11.359C9.99998 10.8581 10.2041 10.3721 10.5763 10.0096Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M20.8261 13.0791C20.4768 14.7894 19.6152 16.3567 18.3547 17.5843L14.112 21.716C13.8324 21.9885 13.5019 22.2033 13.1399 22.3495C12.7779 22.4956 12.3908 22.5705 12.0005 22.5705C11.6102 22.5705 11.2231 22.4956 10.8611 22.3495C10.4991 22.2033 10.1686 21.9885 9.88898 21.716L5.64532 17.5843C4.38478 16.3567 3.52323 14.7894 3.17392 13.0791C2.82457 11.3686 3.00417 9.59591 3.6888 7.98638C4.37329 6.37718 5.53055 5.00607 7.01009 4.04335C8.48936 3.08082 10.2258 2.5686 12 2.5686C13.7742 2.5686 15.5106 3.08082 16.9899 4.04335C18.4695 5.00607 19.6267 6.37718 20.3112 7.98638C20.9958 9.59591 21.1754 11.3686 20.8261 13.0791ZM8.10088 5.71972C9.25278 4.97019 10.6097 4.5686 12 4.5686C13.3903 4.5686 14.7472 4.97019 15.8991 5.71972C17.0508 6.46906 17.9443 7.53137 18.4708 8.76923C18.9972 10.0068 19.1345 11.3669 18.8665 12.6789C18.5985 13.991 17.9363 15.2 16.9593 16.1515L12.716 20.2838C12.6244 20.373 12.5143 20.4452 12.3912 20.4949C12.2681 20.5446 12.1353 20.5705 12.0005 20.5705C11.8657 20.5705 11.7329 20.5446 11.6098 20.4949C11.4867 20.4452 11.3761 20.3726 11.2846 20.2834L7.04059 16.1514C6.06363 15.1999 5.40144 13.991 5.13347 12.6789C4.86552 11.3669 5.00282 10.0068 5.52922 8.76923C6.05575 7.53137 6.94925 6.46906 8.10088 5.71972Z",
        },
      },
    ],
  })(props);
}

export default PinIcon;
