import { GenIcon } from "react-icons";

function CoinIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 25",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M12.9 7.55017C12.9 7.05311 12.497 6.65017 12 6.65017C11.5029 6.65017 11.1 7.05311 11.1 7.55017V8.00017C11.1 8.02446 11.1009 8.04853 11.1028 8.07233C10.6419 8.1451 10.1773 8.27894 9.76351 8.50047C9.01445 8.90148 8.39996 9.62184 8.39996 10.7002C8.39996 11.9604 9.4222 12.581 10.0857 12.8639C10.45 13.0192 10.8211 13.1251 11.1157 13.201C11.2639 13.2393 11.3765 13.2665 11.4649 13.288C11.5819 13.3163 11.6567 13.3344 11.7154 13.354C11.8416 13.3961 12.0413 13.445 12.2233 13.4897C12.299 13.5082 12.3718 13.5261 12.4348 13.5423C12.6993 13.6105 12.968 13.6898 13.2082 13.7922C13.7539 14.0249 13.8 14.2104 13.8 14.3002C13.8 14.6099 13.6809 14.7805 13.4287 14.9233C13.1217 15.0971 12.6354 15.2002 12.0509 15.2002C10.9032 15.2002 10.1783 14.8057 9.93635 14.5638C9.58488 14.2123 9.01503 14.2123 8.66356 14.5638C8.31209 14.9152 8.31209 15.4851 8.66356 15.8366C9.17678 16.3498 10.0367 16.7703 11.1026 16.9305C11.1009 16.9535 11.1 16.9767 11.1 17.0002V17.4502C11.1 17.9472 11.5029 18.3502 12 18.3502C12.497 18.3502 12.9 17.9472 12.9 17.4502V17.0002C12.9 16.981 12.8994 16.9619 12.8982 16.943C13.3892 16.8738 13.8826 16.7348 14.3156 16.4896C15.0568 16.0699 15.6 15.3405 15.6 14.3002C15.6 13.04 14.5777 12.4194 13.9142 12.1365C13.5499 11.9811 13.1788 11.8753 12.8843 11.7993C12.7359 11.7611 12.6233 11.7338 12.5349 11.7123C12.4179 11.684 12.3432 11.6659 12.2846 11.6464C12.1583 11.6043 11.9585 11.5553 11.7764 11.5106C11.7008 11.4921 11.628 11.4743 11.5651 11.458C11.3006 11.3898 11.0319 11.3105 10.7917 11.2081C10.246 10.9755 10.2 10.79 10.2 10.7002C10.2 10.4285 10.3114 10.2489 10.6131 10.0874C10.9588 9.90226 11.4819 9.80017 12.0509 9.80017C13.1505 9.80017 13.8082 10.1812 14.0636 10.4366C14.415 10.788 14.9849 10.788 15.3364 10.4366C15.6878 10.0851 15.6878 9.51525 15.3364 9.16377C14.8193 8.64677 13.9823 8.21063 12.8981 8.05817C12.8993 8.03899 12.9 8.01965 12.9 8.00017V7.55017Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5ZM4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5Z",
        },
      },
    ],
  })(props);
}

export default CoinIcon;
