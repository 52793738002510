import styled from "styled-components";
import Colors from "@colors";

export const Button = styled.a`
  color: ${Colors.lightGreen};
  font-weight: bold;
  margin: 0 16px 8px;
  &:hover {
    color: ${Colors.textGreen};
  }
`;

export const CustomInfoTable = styled.div`
  .ant-pagination > .ant-table-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 16px;
  }

  .ant-pagination .ant-table-pagination {
    display: flex !important;
    flex-direction: row !important;
    position: relative !important;
    width: 100% !important;
  }

  .ant-pagination ul,
  .ant-pagination ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .ant-table-pagination.ant-pagination {
    margin: 1.5rem !important;
    position: relative !important;
  }

  .ant-pagination-options {
    display: inline-flex !important;
    position: absolute !important;
    left: 0 !important;
  }

  .ant-pagination-options::before {
    content: "Linhas por página";
    color: #767f8d !important;
    font-size: 13px !important ;
    letter-spacing: -0.01em !important ;
    margin-right: 0.5rem !important;
  }

  .ant-pagination-total-text {
    position: absolute;
    inset: auto;
    display: inline-block;
    font-weight: 400;
    font-size: 13px;
    color: #767f8d;
    line-height: 145%;
    letter-spacing: -0.01em;
    height: 2.25rem !important;
    text-align: center;
    margin-right: 0.5rem;
    line-height: 1.875rem;
    vertical-align: middle;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    height: 2.25rem !important;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: rgba(118, 127, 141, 0.1) !important;
    border: 0;
    border-radius: 0.5rem !important;
  }

  .ant-pagination-item {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 2.25rem !important;
    margin-right: 0.5rem !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.875rem !important;
    text-align: center !important;
    vertical-align: middle !important;
    list-style: none;
    background-color: rgba(118, 127, 141, 0.1) !important;
    border: 0;
    border-radius: 0.5rem !important;
    outline: 0;
    cursor: pointer !important;
    user-select: none;
  }

  .ant-pagination-item > a {
    color: #303742 !important;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.01em;
  }

  .ant-pagination-item > a:hover {
    color: #303742 !important;
  }

  .ant-pagination-item-active {
    border: 1.5px solid #303742 !important;
  }

  .ant-pagination-item-active > a {
    color: #303742 !important;
  }

  .ant-pagination-item-active > a:hover > a {
    color: #303742 !important;
  }

  .ant-pagination-prev {
    margin-left: auto !important;
  }

  .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow
    > .ant-select-selector {
    height: 27px !important;
    border: 0;
    border-radius: 4px !important;
    background-color: rgba(118, 127, 141, 0.1) !important;
  }

  .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow
    > .ant-select-selector
    > .ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.01em;
    color: #303742 !important;
  }

  .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow
    > .ant-select-arrow
    .anticon
    > svg {
    color: #303742 !important;
  }

  .ant-pagination-item a {
    display: block;
    padding: 0 13px !important;
    color: rgba(0, 0, 0, 0.65);
    transition: none;
  }

  .ant-pagination-item-active:focus-visible,
  .ant-pagination-item-active:hover {
    border-color: #5a8f5c !important;
  }

  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #3c8141 !important;
  }
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #3c8141 !important;
  }
`;
