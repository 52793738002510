import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import mapBackground from "@assets/imgs/mapBackground.png";
import { Button } from "antd";

export const Container = styled.div`
  background-image: url(${mapBackground});
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: -2px;
  height: 391px;
  padding: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const Card = styled.div`
  width: 28.625rem;
  background-color: ${defaultTheme.colors.white};
  border-radius: 0.75rem;

  & > div:last-child {
    padding: 1.5rem;
    text-align: center;
  }
`;

export const Title = styled.div`
  font-size: ${defaultTheme.fontSize["heading-s"]};
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: 600;
  color: ${defaultTheme.colors.grey700};
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${defaultTheme.colors.grey50};
`;

export const SimulationText = styled.span`
  font-size: 15px !important;
  line-height: 145%;
  letter-spacing: -0.15px;
  color: #303742;
`;

export const SimulateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  padding: 0;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  background-color: ${defaultTheme.colors.green600} !important;
  font-size: ${defaultTheme.fontSize.s};
  line-height: 1rem !important;
  border: none;

  & > span {
    font-weight: 600;
    font-size: 13px;
    line-height: 1rem !important;
    letter-spacing: -0.01em;
    color: ${defaultTheme.colors.white};
    margin-top: 2px;
  }

  & > svg {
    fill: #000 !important;
    &:hover {
      fill: #000 !important;
    }
  }

  &:hover {
    background-color: ${defaultTheme.colors.green700} !important;
  }
`;
