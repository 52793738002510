import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Skeleton } from "antd";

export const MachinesCard = styled.div`
  border-radius: 0.75rem;
  border: 1px solid ${defaultTheme.colors.grey70};
`;

export const CardBlock = styled.div`
  display: grid;
  border-bottom: 1px solid ${defaultTheme.colors.grey70};

  :nth-child(1) {
    padding: 1rem;
  }

  :nth-child(2) {
    padding: 0.75rem 1rem;
    grid-gap: 0.5rem;
  }

  :nth-child(3) {
    padding: 1.375rem 1rem;
    grid-gap: 0.5rem;
  }

  :nth-child(4) {
    padding: 0.875rem 1rem 1.125rem 1rem;
    grid-gap: 1.25rem;
  }

  :nth-child(5),
  :nth-child(6) {
    padding: 1.125rem 1rem;
    grid-gap: 1.25rem;
  }

  :last-child {
    border: none;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-skeleton-avatar {
    border-radius: 2px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.g};
  line-height: 145%;
  letter-spacing: -0.01em;
  color: ${defaultTheme.colors.grey700};
`;

export const DateBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 0.125rem 0.5rem;
  background-color: ${defaultTheme.colors.green300};
  color: ${defaultTheme.colors.white};
  font-size: ${defaultTheme.fontSize.s};
  font-weight: 600;
  border-radius: 1rem;
  white-space: nowrap;
`;

export const TextLight = styled.div`
  font-size: ${defaultTheme.fontSize.s};
  color: ${defaultTheme.colors.grey700};
  line-height: 145%;
  letter-spacing: -0.01em;
  white-space: nowrap;
`;

export const TextLightGray = styled(TextLight)`
  color: ${defaultTheme.colors.grey500};
  white-space: normal;
`;

export const Text = styled.div`
  font-size: ${defaultTheme.fontSize.m};
  color: ${defaultTheme.colors.grey700};
  line-height: 145%;
  letter-spacing: -0.02em;
`;

export const TextBoldGreen = styled(Text)`
  font-weight: 600;
  color: ${defaultTheme.colors.green700};
`;

export const TextBold = styled(Text)`
  font-weight: 600;
  letter-spacing: -0.01em;
`;

export const SkeletonBox = styled(Skeleton.Input)`
  display: flex;
  align-items: center;
  border-radius: 2px;

  .ant-skeleton-input {
    width: ${(props) => (props.width ? `${props.width}` : "50%")} !important;
    min-width: ${(props) =>
      props.width ? `${props.width}` : "160px"} !important;
    height: ${(props) =>
      props.height ? `${props.height}` : "10px"} !important;
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: -4px;
`;
