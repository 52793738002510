import React from "react";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { capitalizeAllFirstLetters } from "../../consumingApi/services/helper";

import * as S from "./styles";


const Chip = ({
  text,
  onClick
}) => (
  <S.ChipContainer>
    {capitalizeAllFirstLetters(text)}
    <GingaIcon
      name="solid-error-round"
      color="#767F8D"
      size={20}
      style={{ cursor: 'pointer' }}
      onClick={() => onClick()}
    />
  </S.ChipContainer>
);

export default Chip;
