import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import MaterialTableComponent from "@components/MaterialTableComponent";
import LogisticService from "@LogisticService";

const AbasDeDetalhamento = (props) => {
  const { TabPane } = Tabs;
  const { nome, polo, ano, mes, tipo, resultado, distrito } = props;

  const [tabOneContent, setTabOneContent] = useState(resultado);
  const [tabTwoContent, setTabTwoContent] = useState(undefined);
  const [tabThreeContent, setTabThreeContent] = useState(undefined);
  const [tabFiveContent, setTabFiveContent] = useState(undefined);
  const [tabSixContent, setTabSixContent] = useState(undefined);
  const [tabSevenContent, setTabSevenContent] = useState(undefined);
  const [tabEightContent, setTabEightContent] = useState(undefined);
  const [tabNineContent, setTabNineContent] = useState(undefined);

  useEffect(() => {
    setTabOneContent(props.resultado);
  }, [props]);

  function formatDate(date, hours) {
    if (date !== undefined) {
      var datePart = date.slice(0, 10);

      if (hours === undefined) {
        return (
          datePart.split("-")[2] +
          "/" +
          datePart.split("-")[1] +
          "/" +
          datePart.split("-")[0]
        );
      }

      return (
        datePart.split("-")[2] +
        "/" +
        datePart.split("-")[1] +
        "/" +
        datePart.split("-")[0] +
        " " +
        date.slice(11)
      );
    } else {
      return "--/--/--";
    }
  }

  function callback(key) {
    if (key === "1" && tabOneContent === undefined) {
      setTabOneContent([]);
    }
    if (key === "2" && tabTwoContent === undefined) {
      LogisticService.getOrdensDistrito(ano, mes, distrito).then((response) => {
        setTabTwoContent(
          response.data.data.map((order) => ({
            provider: order.provider,
            days_to_complete: order.days_to_complete,
            order_number: order.order_number,
            contractor: order.contractor,
            customer: order.customer,
            customer_name: order.customer_name,
            city: order.city,
            technician_name: order.technician_name,
            consider_in_sla_account: order.consider_in_sla_account,
            consider_in_tma_account: order.consider_in_tma_account,
            solved_with_sla_on_time: order.solved_with_sla_on_time,
            solved_with_sla_customer_on_time:
              order.solved_with_sla_customer_on_time,
            consider_in_dzero_account: order.consider_in_dzero_account,
            solved_in_dzero: order.solved_in_dzero,
            solved_with_anticipation: order.solved_with_anticipation,
            redirect_polo: order.redirect_polo,
            service_group: order.service_group,
            status: order.status,
            cancellation_reason: order.cancellation_reason,
            opening_date: formatDate(order.opening_date, true),
            arrival_date: formatDate(order.arrival_date, true),
            deadline_date: formatDate(order.deadline_date, true),
            customer_deadline_date: formatDate(
              order.customer_deadline_date,
              true
            ),
            visitReason1: order.visitReason1,
          }))
        );
      });
    }
    if (key === "3" && tabThreeContent === undefined) {
      LogisticService.getEstoqueDistrito(ano, mes, distrito).then(
        (response) => {
          setTabThreeContent(
            response.data.data.map((terminal) => ({
              status: terminal.status,
              contractor: terminal.contractor,
              model: terminal.model,
              polo: terminal.polo,
              distrito: terminal.distrito,
              data_foto: formatDate(terminal.data_foto),
              serial: terminal.serial,
            }))
          );
        }
      );
    }

    if (key === "5" && tabFiveContent === undefined) {
      LogisticService.getDesinstalacoesDistrito(ano, mes, distrito).then(
        (response) => {
          setTabFiveContent(
            response.data.data.map((terminal) => ({
              serial_uninstalled: terminal.serial_uninstalled,
              status_before: terminal.status_before,
              service_order_number: terminal.service_order_number,
              contractor: terminal.contractor,
              angel: terminal.technician_name,
              provider: terminal.provider,
              removal_date: formatDate(terminal.removal_date),
              week_number: terminal.week_number,
            }))
          );
        }
      );
    }

    if (key === "6" && tabSixContent === undefined) {
      LogisticService.getNPSDistrito(ano, mes, distrito).then((response) => {
        setTabSixContent(
          response.data.data.map((os) => ({
            angel: os.angel,
            hub: os.hub,
            district: os.district,
            rating: os.rating,
            cluster_rating: os.cluster_rating,
            answer_date: formatDate(os.answer_date),
          }))
        );
      });
    }

    if (key === "7" && tabSevenContent === undefined) {
      LogisticService.getAcidentesDistrito(ano, mes, distrito).then(
        (response) => {
          setTabSevenContent(
            response.data.data.map((accident) => ({
              district: accident.district,
              angel: accident.angel,
              date: accident.date,
              type: accident.type,
            }))
          );
        }
      );
    }

    if (key === "8" && tabEightContent === undefined) {
      LogisticService.getPenalidadesDistrito(ano, mes, distrito).then(
        (response) => {
          setTabEightContent(
            response.data.data.map((os) => {
              const date = `${os.arrival_date.split("-")[2]}/${
                os.arrival_date.split("-")[1]
              }/${os.arrival_date.split("-")[0]}`;
              return {
                driver: os.driver,
                district: os.district_name,
                arrival_date: date,
                severity: os.severity,
              };
            })
          );
        }
      );
    }

    if (key === "9" && tabNineContent === undefined) {
      LogisticService.getAlocacoes(ano, mes, distrito).then((response) => {
        setTabNineContent(
          response.data.data.map((angel) => {
            debugger;
            return {
              email: angel.email,
              nome: angel.nome,
              cadeira: angel.cadeira,
              distrito_os: angel.distrito_os,
              polo_os: angel.polo_os,
              angel_m0: angel.angel_m0,
              status: angel.status,
              afastado: angel.afastado,
              ferias: angel.ferias,
              possui_rv: angel.possui_rv,
            };
          })
        );
      });
    }
  }

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Resumo" key="1">
        <div style={{ padding: "0px 100px" }}>
          <MaterialTableComponent
            columns={[
              { title: "Pilar", field: "name" },
              { title: "Atingimento", field: "score" },
              { title: "Limite Máximo", field: "limit" },
            ]}
            options={{
              showTitle: false,
              search: false,
              paging: false,
              toolbar: false,
            }}
            data={
              !tabOneContent
                ? []
                : Object.values(tabOneContent).map((p) => ({
                    name: p.nome,
                    score: `${p.atingimento}%`, // resultado[p],
                    limit: `${p.indicadores
                      .filter((i) => i.tipo !== "alavanca")
                      .map((i) => parseFloat(i.peso))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(1)}%`,
                    indicadores: p.indicadores,
                  }))
            }
            detailPanel={(rowData) => (
              <div style={{ padding: "30px 70px" }}>
                <MaterialTableComponent
                  columns={[
                    {
                      title: "Indicador",
                      field: "indicador",
                    },
                    {
                      title: "Resultado",
                      field: "resultado",
                    },
                    {
                      title: "Atingimento Pós-Régua",
                      field: "atingimento_regua",
                    },
                    {
                      title: "Peso",
                      field: "peso",
                    },
                    {
                      title: "Impacto no Pilar",
                      field: "contribuicao_total",
                    },
                  ]}
                  data={rowData.indicadores.map((indicador) => ({
                    indicador: indicador.nome,
                    resultado: indicador.resultado,
                    atingimento_regua: `${indicador.atingimento}%`,
                    peso:
                      indicador.tipo === "alavanca"
                        ? indicador.atingimento >= 0 &&
                          indicador.nome !== "mix_acidentes"
                          ? "Bônus"
                          : "Ônus"
                        : `${indicador.peso}%`,
                    contribuicao_total:
                      indicador.tipo === "alavanca"
                        ? `${indicador.atingimento}%`
                        : `${(indicador.atingimento / 100) * indicador.peso}%`,
                  }))}
                  addOptions={{
                    filtering: false,
                    pageSize: 5,
                    exportButton: false,
                    search: false,
                    paging: false,
                    toolbar: false,
                  }}
                />
              </div>
            )}
          />
        </div>
      </TabPane>
      <TabPane tab="Chamados Atendidos" key="2">
        <MaterialTableComponent
          columns={[
            {
              title: "Número da OS",
              field: "order_number",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Stonecode",
              field: "customer",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Nome do Cliente",
              field: "customer_name",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Contratante",
              field: "contractor",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Cidade",
              field: "city",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Angel",
              field: "technician_name",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Polo",
              field: "provider",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Conta pro SLA",
              field: "consider_in_sla_account",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Conta pro TMA",
              field: "consider_in_tma_account",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Tempo de Atendimento (dias úteis)",
              field: "days_to_complete",
              cellStyle: { fontSize: "0.8em", width: "5em" },
            },
            {
              title: "SLA no Prazo",
              field: "solved_with_sla_on_time",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "5em" },
            },
            {
              title: "SLA Cliente no Prazo",
              field: "solved_with_sla_customer_on_time",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Conta pro D0",
              field: "consider_in_dzero_account",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Resolvida em D0",
              field: "solved_in_dzero",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Chamado Antecipado",
              field: "solved_with_anticipation",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Grupo de Serviço",
              field: "service_group",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Status",
              field: "status",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Motivo da Primeira Visita",
              field: "visitReason1",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Motivo de Cancelamento",
              field: "cancellation_reason",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Data de Abertura",
              field: "opening_date",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Data de Atendimento",
              field: "arrival_date",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Data Limite",
              field: "deadline_date",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Data Limite Cliente",
              field: "customer_deadline_date",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
            {
              title: "Redirect",
              field: "redirect_polo",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "25em" },
            },
          ]}
          data={tabTwoContent}
          title="Chamados Atendidos"
          isLoading={!tabTwoContent}
        />
      </TabPane>
      <TabPane tab="Ciclo Triagem" key="3">
        <MaterialTableComponent
          columns={[
            {
              title: "Serial",
              field: "serial",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Modelo",
              field: "model",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Contratante",
              field: "contractor",
              cellStyle: { fontSize: "0.8em", width: "3em" },
            },
            {
              title: "Polo",
              field: "polo",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Distrito",
              field: "distrito",
              cellStyle: { fontSize: "0.8em", width: "5em" },
            },
            {
              title: "Data da Foto",
              field: "data_foto",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabThreeContent}
          isLoading={!tabThreeContent}
          title="Ciclo Triagem"
        />
      </TabPane>
      <TabPane tab="Entrantes no CT" key="5">
        <MaterialTableComponent
          columns={[
            {
              title: "Número da OS",
              field: "service_order_number",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Contratante",
              field: "contractor",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Serial",
              field: "serial_uninstalled",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo",
              field: "provider",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Data da Retirada",
              field: "removal_date",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Semana",
              field: "week_number",
              type: "numeric",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabFiveContent}
          isLoading={!tabFiveContent}
          title="Entrantes no CT"
        />
      </TabPane>
      <TabPane tab="NPS" key="6">
        <MaterialTableComponent
          columns={[
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo",
              field: "hub",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Nota",
              field: "rating",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Classificação",
              field: "cluster_rating",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Data da resposta",
              field: "answer_date",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
          ]}
          data={tabSixContent}
          isLoading={!tabSixContent}
          title="CSAT"
        />
      </TabPane>
      <TabPane tab="Acidentes" key="7">
        <MaterialTableComponent
          columns={[
            {
              title: "Tipo",
              field: "type",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Data",
              field: "date",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Angel",
              field: "angel",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabSevenContent}
          isLoading={!tabSevenContent}
          title="Acidentes"
        />
      </TabPane>
      <TabPane tab="Multas" key="8">
        <MaterialTableComponent
          columns={[
            {
              title: "Motorista",
              field: "driver",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Distrito",
              field: "district",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Data de Recimento",
              field: "arrival_date",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Gravidade",
              field: "severity",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
          ]}
          data={tabEightContent}
          isLoading={!tabEightContent}
          title="Multas"
        />
      </TabPane>
      <TabPane tab="Headcount" key="9">
        <MaterialTableComponent
          columns={[
            {
              title: "E-mail",
              field: "email",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Nome",
              field: "nome",
              cellStyle: { fontSize: "0.8em", width: "10em" },
            },
            {
              title: "Cadeira",
              field: "cadeira",
              cellStyle: { fontSize: "0.8em", width: "8em" },
            },
            {
              title: "Polo de Atuação",
              field: "polo_os",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Distrito de Atuação",
              field: "distrito_os",
              cellStyle: { fontSize: "0.8em", width: "15em" },
            },
            {
              title: "Status",
              field: "status",
              cellStyle: { fontSize: "0.8em", width: "2em" },
            },
            {
              title: "M0",
              field: "angel_m0",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "2em" },
            },
            {
              title: "Férias",
              field: "ferias",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "2em" },
            },
            {
              title: "Afastado",
              field: "afastado",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "2em" },
            },
            {
              title: "Possui RV",
              field: "possui_rv",
              type: "boolean",
              cellStyle: { fontSize: "0.8em", width: "2em" },
            },
          ]}
          data={tabNineContent}
          isLoading={!tabNineContent}
          title="Headcount"
        />
      </TabPane>
    </Tabs>
  );
};

export default AbasDeDetalhamento;
