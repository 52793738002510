import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button } from "antd";

export const Container = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
`;

export const ContainerFilled = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
`;

export const Text = styled.div`
  font-size: ${defaultTheme.fontSize.m};
  color: ${defaultTheme.colors.grey500};
  line-height: 145%;
  letter-spacing: -0.01em;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${defaultTheme.colors.grey50};
`;

export const InputsArea = styled.div`
  display: grid;
  align-items: end;
  grid-gap: 1rem;
  grid-template-columns: 2fr 2fr 1fr;
  margin-top: 0.5rem;
`;

export const TitleText = styled.div`
  font-size: ${defaultTheme.fontSize.g};
  color: ${defaultTheme.colors.grey700};
  line-height: 145%;
  letter-spacing: -0.01em;
  font-weight: 600;
`;

export const SearchButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  background-color: ${defaultTheme.colors.green600};
  border-radius: 0.5rem;
  color: ${defaultTheme.colors.white};
  font-size: ${defaultTheme.fontSize.g};
  font-weight: 600;
  border: none;

  span {
    margin: 0.15rem 0 0 0.59375rem;
  }
  .ant-btn-loading-icon {
    margin: 0;
  }
  :hover,
  :focus {
    background-color: ${defaultTheme.colors.green600} !important;
    color: ${defaultTheme.colors.white} !important;
  }
`;

export const EditButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${defaultTheme.colors.grey50};
  padding: 0.75rem;
  border-radius: 50%;
  cursor: pointer;
`;
