import React from "react";
import BottomArrow from "@assets/icons/BottomArrow";
import { Select } from "antd";
import * as S from "./styles";

const SelectCustom = (props) => {
  const { Option } = Select;

  return (
    <S.ContentSelect>
      <S.SelectLabel>{props.title}</S.SelectLabel>
      <S.SelectField
        title={props.title}
        allowClear={props.allowClear}
        clearIcon={props.clearIcon}
        value={props.value}
        disabled={props.disabled}
        hidden={props.hidden ? true : false}
        showSearch
        suffixIcon={
          <BottomArrow
            color={"#767F8D"}
            size={24}
            style={{ margin: "6.83px 0 0 3px" }}
          />
        }
        style={{ borderRadius: "8px", color: "#767F8D" }}
        placeholder={props.placeholder}
        optionFilterProp="children"
        onChange={props.onChange}
        defaultValue={
          props.defaultValue !== undefined ? props.defaultValue : null
        }
        mode={props.multiple ? "multiple" : null}
        filterOption={(input, option) =>
          option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        getPopupContainer={(node) => node.parentNode}
      >
        {!props.options
          ? []
          : props.options.map((content, index) => (
              <Option
                key={props.valueIsName ? index : content.id}
                value={
                  props.valueIsName
                    ? content.name
                    : props.customValue
                    ? content.value
                    : content.id
                }
              >
                {content.name}
              </Option>
            ))}
      </S.SelectField>
    </S.ContentSelect>
  );
};

export default SelectCustom;
