import React, { useEffect, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import SelectCustom from "@components/SelectCustom";
import AngelPolyline from "../AngelPolyline";
import CalendarCloseIcon from "@assets/icons/CalendarCloseIcon";

import * as S from "./styles";

const MapRoutes = ({ data, filteredRoute, googleMapsLoaded }) => {
  const [mapa, setMapa] = useState(null);
  const [selectedAngel, setSelectedAngel] = useState(null);
  const [filteredData, setFiltededData] = useState(data);

  const initialCenter = { lat: -18.0, lng: -54.0 };
  const initialZoom = 5;

  const angels = data?.map((item) => ({
    id: item.name,
    name: item.name,
  }));

  const defaultBounds = new window.google.maps.LatLngBounds(
    new window.google.maps.LatLng(-33.75, -74.0),
    new window.google.maps.LatLng(5.266, -34.8)
  );

  useEffect(() => {
    setSelectedAngel(null);
  }, [filteredRoute]);

  useEffect(() => {
    const aux = data?.find((item) => item.name === selectedAngel);
    setFiltededData(aux ? [aux] : data);
  }, [selectedAngel, filteredRoute]);

  const realocateMap = () => {
    if (filteredData && filteredData.length > 0 && mapa) {
      if (!data) return mapa.fitBounds(defaultBounds);

      const bounds = new window.google.maps.LatLngBounds();

      const elementos = filteredData
        .map((item) => item.mapsRoutes.markers)
        .flatMap((items) => items.map(({ lat, lng }) => ({ lat, lng })));

      elementos.forEach((elemento) => {
        bounds.extend(elemento);
      });

      mapa.fitBounds(bounds);
    }
  };

  const handleLoad = (map) => {
    setMapa(map);
    realocateMap();
  };

  useEffect(() => {
    if (mapa) realocateMap();
  }, [selectedAngel, filteredRoute, filteredData, mapa]);

  return googleMapsLoaded ? (
    <S.Container>
      <GoogleMap
        mapContainerClassName="App-map"
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          borderRadius: "0.75rem",
        }}
        on
        center={initialCenter}
        zoom={initialZoom}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          clickableIcons: false,
        }}
        onLoad={handleLoad}
      >
        <S.SelectArea hideArrowIcon={!!selectedAngel}>
          <SelectCustom
            title="Filtrar por Angel"
            placeholder="Selecione um nome"
            options={angels}
            value={selectedAngel}
            onChange={setSelectedAngel}
            {...(!!selectedAngel
              ? {
                  clearIcon: <CalendarCloseIcon fill="#767F8D" size={20} />,
                  allowClear: true,
                }
              : {})}
          />
        </S.SelectArea>

        {data && filteredData && <AngelPolyline routes={filteredData} />}
      </GoogleMap>
    </S.Container>
  ) : (
    <S.SkeletonMap active block />
  );
};

export default MapRoutes;
