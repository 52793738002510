import React from "react";
import Table from "../Table";
import Empty from "@assets/icons/emptyBookImage.svg";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import * as S from "../styles";

export default function TableTon({ data, lastUpdate, loading }) {
  return (
    <S.Card>
      <header>
        <div>
          <S.Title>Estoque Ton</S.Title>
          {lastUpdate && (
            <p>{`Atualizado em ${format(lastUpdate, "d 'de' MMM 'às' H:mm", {
              locale: ptBR,
            })}`}</p>
          )}
        </div>
        {data && (
          <S.RequestQDButton
            to={{ pathname: "http://bit.ly/formsangels22-2" }}
            target="_blank"
          >
            Solicitar QD
          </S.RequestQDButton>
        )}
      </header>

      {data || loading ? (
        <S.TonContainer>
          <Table
            title="T1 - Bluetooth"
            data={data ? data["T1"] : null}
            loading={loading}
          />
          <Table
            title="T1 Chip - Bluetooth - GPRS"
            data={data ? data["T1 chip"] : null}
            loading={loading}
          />
          <Table
            title="T2 - MPOS e MPOS Touch"
            data={data ? data["T2"] : null}
            loading={loading}
          />
          <Table
            title="T3 - GPRS - Wifi"
            data={data ? data["T3"] : null}
            loading={loading}
          />
        </S.TonContainer>
      ) : (
        <S.EmptyContainer>
          <img src={Empty} />
          <span>Nenhum dado encontrado</span>
        </S.EmptyContainer>
      )}
    </S.Card>
  );
}
