import styled from "styled-components";
import Colors from "@colors";

export const Exemplo = styled.span`
  margin: 16px 8px 0;
  font-size: 14px;
`;

export const Download = styled.a``;

export const Paragraph = styled.p``;

export const Span = styled.span`
  flex: auto;
  padding: 0 0 8px;
  font-weight: bold;
  color: ${(props) => props.color || "rgba(0, 0, 0, 0.85)"};
  cursor: default;
  white-space: nowrap;
  margin-right: 8px;
`;

export const TextSuccess = styled(Span)`
  color: ${Colors.textGreen};
  max-width: 120px;
`;

export const TextError = styled(Span)`
  color: ${Colors.dangerRed};
  max-width: 120px;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.2px;
  padding: 8px;
`;

export const BlockRow = styled.div`
  display: block;
`;
