import styled from "styled-components";
import Colors from "@colors";

export const TableBox = styled.div`
  svg {
    transition: 0.2s;
    &:hover {
      fill: ${Colors.lightGreen};
    }
  }
`;
