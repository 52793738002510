import React from "react";
import { Form, notification } from "antd";

import Box from "@components/Box";
import Container from "@components/Container";
import Input from "@components/InputThird";
import Row from "@components/Row";
import LogisticService from "@LogisticService";

import Table from "./table";
import { SubmitButton } from "./styles";
import { PageLayout } from "../../../Layout/PageLayout";

import { CloseButton } from "../GestaoTerceira/MotivosAtraso/AnexosMotivos/styles";

export default function AngelsGreenApp(props) {
  const formRef = React.createRef();
  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const postForm = async (data) => {
    if (data) {
      return LogisticService.postAngelsGreenApp(data)
        .then((response) => {
          if (response) {
            return response;
          }
        })
        .catch((err) => {
          return err;
        });
    }
  };

  const messageMultipleReasons = (hasError, message) => {
    api.info({
      type: hasError ? "warning" : "success",
      message,
      placement: "bottomLeft",
      className: "notification-ginga-design",
      closeIcon: <CloseButton>Fechar</CloseButton>,
    });
  };

  const onFinish = async (data) => {
    const response = await postForm(data);

    if (response.status === 201) {
      form.resetFields();
      messageMultipleReasons(false, "Novo Angel cadastrado com sucesso!");
    } else {
      messageMultipleReasons(true, response.response.data.error);
    }
  };

  const rows = [
    <Form.Item
      name="email"
      style={{ margin: 0, marginTop: 12 }}
      rules={[
        { required: true, message: "Necessário inserir email do Angel." },
      ]}
    >
      <Input
        label="Email"
        placeholder="Digite aqui"
        width="300"
        onKeyDown={(e) => e.key === "Enter"}
      />
    </Form.Item>,
    <Form.Item name="genero" style={{ margin: 0 }}>
      <Input label="Genêro" placeholder="Digite aqui" />
    </Form.Item>,
    <Form.Item name="userAngel" style={{ margin: 0 }}>
      <Input
        label="Nome de usuário - Angel"
        placeholder="Digite aqui"
        onKeyDown={(e) => e.key === "Enter"}
      />
    </Form.Item>,
    <Form.Item name="passAngel" style={{ margin: 0 }}>
      <Input label="Senha do usuário - Angel" placeholder="Digite aqui" />
    </Form.Item>,
    <Row horizontalAlign="center" verticalAlign="center">
      <SubmitButton htmlType="submit">Cadastrar</SubmitButton>
    </Row>,
  ];

  return (
    <PageLayout>
      <Box title="Acesso dos angels ao Green App">
        {contextHolder}
        <Row>
          <Container rounded minWidth="72%">
            <Table />
          </Container>
          <Container
            title={"Novo Angel"}
            padding={"16px"}
            minWidth="310px"
            rounded
          >
            <Form
              onFinish={onFinish}
              form={form}
              ref={formRef}
              initialValues={{ genero: "Masculino" }}
            >
              {rows}
            </Form>
          </Container>
        </Row>
      </Box>
    </PageLayout>
  );
}
