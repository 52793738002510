import { Form } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const FormItem = styled(Form.Item)`
  margin: 0 0 8px;
  .ant-form-item-label {
    padding: 2px;
  }
  display: flex;
`;

export const Text = styled.span`
  font-size: 16px;
  color: ${Colors.deepBlue};
  margin: 8px 0;
`;

export const Bold = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.textGreen};
`;
