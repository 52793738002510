import React, { useState } from "react";
import { Radio, Space } from "antd";
import ModalsRoute from "../ModalsRoute";

import * as S from "./styles";

const RoutePickCard = ({
  pickedRoute,
  setPickedRoute,
  routeData,
  pins,
  angelsDisponiveis,
  areaInfo,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ModalsRoute
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        routeType={pickedRoute}
        routeData={routeData}
        pins={pins}
        areaInfo={areaInfo}
        angelsDisponiveis={angelsDisponiveis}
      />
      <S.Container>
        <div>
          <S.Title>
            Qual roteirização você vai escolher para o dia de hoje?
          </S.Title>
        </div>

        <div>
          <Radio.Group
            value={pickedRoute}
            onChange={(e) => setPickedRoute(e.target.value)}
            className="ginga-radio"
          >
            <Space direction="vertical" size="middle">
              <Radio value="Senninha (sem polígono)">
                <S.RadioString>Senninha (sem polígono)</S.RadioString>
              </Radio>
              <Radio value="Senninha (com polígono)">
                <S.RadioString>Senninha (com polígono)</S.RadioString>
              </Radio>
              <Radio value="Distrital">
                <S.RadioString>Distrital</S.RadioString>
              </Radio>
            </Space>
          </Radio.Group>
        </div>

        <S.RouteButton
          disabled={!pickedRoute || !routeData}
          onClick={() => setIsModalOpen(true)}
        >
          Definir roteirização
        </S.RouteButton>
      </S.Container>
    </>
  );
};

export default RoutePickCard;
