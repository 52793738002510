import React, { useEffect, useState } from "react";
import LogisticService from "@LogisticService";
import SelectCustom from "@components/SelectCustom";
import SearchIcon from "@assets/icons/searchIcon";
import EditIcon from "@assets/icons/EditIcon";
import { capitalizeAllFirstLetters } from "../../consumingApi/services/helper";

import * as S from "./styles";

export default function HeaderProvider({
  searchButtonAction,
  setSelectedInfo,
  setDrawerDoaVisible,
}) {
  const [basesParceiras, setBasesParceiras] = useState(null);
  const [loadingBaseParceiras, setLoadingBaseParceiras] = useState(true);
  const [operatorOptions, setoperatorOptions] = useState([]);
  const [providerOptions, setproviderOptions] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedProviderName, setSelectedProviderName] = useState("");
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    LogisticService.getBasesParceiras().then((response) => {
      setBasesParceiras(response.data);
      const logOps = [];
      Object.entries(response.data)
        .sort()
        .forEach((item) => {
          logOps.push({
            id: item[0],
            name: item[0],
          });
        });
      setoperatorOptions(logOps);
      setLoadingBaseParceiras(false);
    });
  }, []);

  const handleOnChangeProvider = (value) => {
    setSelectedProvider(value);
    setSelectedProviderName(
      providerOptions.find((item) => item.id == value).name
    );
  };

  const handleOnChangeOperator = (value) => {
    setproviderOptions(
      basesParceiras[`${value}`].sort((a, b) => a.name.localeCompare(b.name))
    );
    setSelectedOperator(value);
    setSelectedProvider();
  };

  const handleSearchButton = async () => {
    await searchButtonAction(selectedProvider, selectedProviderName);
    setCanEdit(false);
    setSelectedInfo({
      selectedProviderName,
      selectedOperator,
      canEdit,
      selectedProvider,
    });
    if (setDrawerDoaVisible) {
      setDrawerDoaVisible(true);
    }
  };

  const handleNewSearch = () => {
    setCanEdit(true);
    setSelectedOperator(null);
    setSelectedProvider(null);
    setSelectedInfo({
      selectedProviderName,
      selectedOperator,
      canEdit,
      selectedProvider: null,
    });
  };

  return (
    <>
      {canEdit ? (
        <S.Container>
          <S.Text>Para começar, preencha os campos.</S.Text>

          <S.InputsArea>
            <SelectCustom
              title="Operador"
              placeholder="Selecione uma opção"
              options={operatorOptions}
              onChange={handleOnChangeOperator}
              disabled={loadingBaseParceiras}
            />
            <SelectCustom
              title="Prestador"
              placeholder="Selecione uma opção"
              options={providerOptions}
              onChange={handleOnChangeProvider}
              value={selectedProvider}
            />
            <S.SearchButton
              disabled={!(selectedOperator && selectedProvider)}
              onClick={handleSearchButton}
              icon={<SearchIcon />}
            >
              Buscar
            </S.SearchButton>
          </S.InputsArea>
        </S.Container>
      ) : (
        <S.ContainerFilled>
          <S.TitleText>{`${capitalizeAllFirstLetters(
            selectedProviderName
          )} > ${capitalizeAllFirstLetters(selectedOperator)}`}</S.TitleText>
          <S.EditButton onClick={handleNewSearch}>
            <EditIcon size={20} />
          </S.EditButton>
        </S.ContainerFilled>
      )}
    </>
  );
}
