import styled from "styled-components";


export const ChipContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: auto;
  padding: 8px 12px;
  border-radius: 8px;
  background: rgba(118, 127, 141, 0.10);

  color: #303742;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  letter-spacing: -0.01em;
  text-align: left;
  text-wrap: nowrap;
`;
