import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import LineChart from "@components/LineChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { getTwoWeeksAgo } from "@helper";
import Colors from "@colors";

export default function Cobertura(props) {
  const title = "Cobertura";
  const [cobertura, setCobertura] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getCobertura().then((response) => {
      if (response && response.data != null) {
        setCobertura(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const coberturaCd = [];
  const ponta = [];
  const transito = [];
  const total = [];
  const downloadLabels = [
    "Período",
    "Cobertura CD",
    "Ponta",
    "Trânsito",
    "Total",
  ];
  const downloadData = [];

  cobertura &&
    cobertura.map(
      (item) => (
        periodos.push(item.periodo),
        coberturaCd.push(item.cobertura_cd.toFixed(1)),
        ponta.push(item.ponta.toFixed(1)),
        transito.push(item.transito.toFixed(1)),
        total.push(item.total.toFixed(1)),
        downloadData.push([
          `${item.periodo}`,
          `${item.cobertura_cd.toFixed(3)}`,
          `${item.ponta.toFixed(3)}`,
          `${item.transito.toFixed(3)}`,
          `${item.total.toFixed(3)}`,
        ])
      )
    );

  const chartData = {
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            max: 20,
          },
        },
      ],
      xAxes: [
        {
          gridLines: false,
          ticks: {
            padding: 16,
            min: getTwoWeeksAgo(),
          },
        },
      ],
    },
    data: [
      {
        label: "Cobertura CD",
        color: Colors.green,
        fill: false,
        value: coberturaCd,
      },
      { label: "Ponta", color: Colors.blue, fill: false, value: ponta },
      {
        label: "Trânsito",
        color: Colors.darkGreen,
        fill: false,
        value: transito,
      },
      { label: "Total", color: Colors.gray, fill: false, value: total },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      grid={1 / 2}
      title={title}
      info={[[""]]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <LineChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
