import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const PageLayout = styled.div`
  background-color: ${defaultTheme.colors.grey100};
  width: 100%;
  display: grid;
`;

export const PageContent = styled.div`
  display: ${(props) =>
    props.showHamburguer
      ? "inline-grid"
      : props.showHamburguerIcon == null
      ? "grid"
      : ""};
  grid-template-columns: ${(props) =>
    props.showMenuNav ? "minmax(auto, 210px) 1fr" : "auto"};
  gap: 2.1875rem;
  padding: ${(props) =>
    props.showHamburguer
      ? "1.5rem 2rem 1.5rem 1.5rem"
      : props.showHamburguerIcon == null
      ? "1.5rem 2.225rem 2.375rem;"
      : ""};
  align-items: start;
  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`;
