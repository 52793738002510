import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const Container = styled.div`
  background-color: ${defaultTheme.colors.white};
  border-radius: 0.75rem;
  max-height: calc(100vh - 362.2px);
  overflow-y: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: auto;
`;

export const Title = styled.div`
  font-weight: 600;
  padding: 1.5rem;
  font-size: ${defaultTheme.fontSize.g};
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${defaultTheme.colors.grey700};
  border-bottom: 1px solid ${defaultTheme.colors.grey70};
`;

export const Route = styled.div`
  padding: 1rem 0;
  margin: 0 1.5rem;
  border-bottom: 1px solid ${defaultTheme.colors.grey70};

  &:last-child {
    border: none;
  }

  & > div {
    margin-bottom: 4px;
  }

  & > div:first-child {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: ${defaultTheme.fontSize.m};
    line-height: 22px;
    letter-spacing: -0.01em;

    & > span {
      color: ${defaultTheme.colors.grey700};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${defaultTheme.colors.grey500};
  font-size: ${defaultTheme.fontSize.s};
  line-height: 22px;
  letter-spacing: -0.01em;

  &:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  span:last-child {
    color: ${defaultTheme.colors.grey700};
    font-weight: 600;
  }
`;

export const ColorBadge = styled.div`
  width: 1rem;
  height: 0.5rem;
  background: ${({ color }) => color};
  border-radius: 100px;
  margin-right: 0.25rem !important;
  margin-bottom: 8px;
`;

export const NoDataCard = styled.div`
  height: 214px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;

  img {
    width: 58.5px;
  }

  & > span {
    color: ${defaultTheme.colors.grey700};
    font-size: ${defaultTheme.fontSize.m};
    line-height: 21.75px;
    letter-spacing: -0.01em;
    margin-top: 1rem;
    text-align: center;
  }
`;
