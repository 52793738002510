import Colors from "@colors";
import { Form, Input } from "antd";
import styled from "styled-components";

export const FormItem = styled(Form.Item)`
  margin: 0 0 8px;
  .ant-form-item-label {
    padding: 2px;
  }
`;

export const TableBox = styled.div`
  svg {
    transition: 0.2s;
    &:hover {
      fill: ${Colors.lightGreen};
    }
  }
`;

export const TabButton = styled.div`
  border-radius: 0;
  margin-top: -8px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.2px;
  height: auto;
  padding: 8px 16px;
  background: ${(props) => (props.type ? Colors.lightGreen : Colors.light)};
  border: none;
  color: ${(props) => (props.type ? Colors.light : Colors.deepBlue)};
  cursor: pointer;
  &:hover {
    background: ${Colors.light};
    color: ${Colors.textGreen};
  }
`;

export const InputWithTags = styled(Input)`
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;

  .ant-input-prefix {
    display: ${(props) => (props.isEmpty ? "none" : "flex")};
    flex-wrap: wrap !important;
    width: fit-content;

    > div {
      margin: 0 8px 8px 0 !important;
    }
  }
`;
