import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import Colors from "@colors";
import { ImportantInfo, Valor } from "../styles";

export default function HistoricoCobranca(props) {
  const [loaded, setLoaded] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [isencaoVigente, setIsencaoVigente] = useState([]);

  useEffect(() => {
    LogisticService.getIsencaoVigente(props.stonecode, props.id_terminal).then(
      (response) => {
        if (response !== undefined && response.data !== null) {
          setIsencaoVigente(response.data ? "Sim" : "Não");
        } else {
          setIsencaoVigente(" - ");
        }
      }
    );
    LogisticService.getHistoricoCobranca(
      props.stonecode,
      props.id_terminal
    ).then((response) => {
      if (response !== undefined && response.data !== null) {
        setHistorico(response.data);
      }
      setLoaded(true);
    });
  }, [props.stonecode, props.id_terminal]);

  const dados = [];
  const colunas = [
    {
      label: "Valor",
      key: "valor",
      align: "start",
      sort: {
        compare: (a, b) => b.valor - a.valor,
      },
    },
    {
      label: "Desconto",
      key: "desconto",
      sort: {
        compare: (a, b) => b.desconto - a.desconto,
      },
    },
    {
      label: "Data",
      key: "data",
    },
    {
      label: "Período",
      key: "periodo",
    },
  ];

  historico &&
    historico.forEach((item, index) => {
      const row = {};
      row["key"] = index;
      row["valor"] = item.valor;
      row["desconto"] = item.desconto;
      row["data"] = item.data;
      row["periodo"] = item.periodo;
      dados.push(row);
    });

  const tableData = {
    data: dados,
    columns: colunas,
  };

  return (
    <Box maxHeight={"600px"} margin={"0"} padding={"0"} scroll>
      <ImportantInfo>
        Isenção vigente:{" "}
        <Valor
          color={isencaoVigente == "Não" ? Colors.dangerRed : Colors.lightGreen}
        >
          {isencaoVigente}
        </Valor>
      </ImportantInfo>
      {loaded ? (
        <InfoTable
          data={tableData}
          search
          pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          footer={false}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Box>
  );
}
