import React, { useEffect, useState } from "react";
import { Drawer, Input } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { useSelector } from "react-redux";
import { pinsSelector, setBouncePin, setCenter, setZoom } from "../../../../../../../features/senninha/pinsSlice";
import { useDispatch } from "react-redux";

import * as S from "./styles";


const SearchDrawer = () => {

    const [foundSearchResult, setFoundSearchResult] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [typedValue, setTypedValue] = useState(null)

    const dispatch = useDispatch();

    const {
        pins,
    } = useSelector(pinsSelector);

    const handlePressEnter = (e) => {
        const value = e.target.value.trim();

        const foundPin = pins.find(pin => {
            return pin.id_atendimento === value ||
                pin.oss.some(os => os.id_os?.toString() === value);
        });

        setFoundSearchResult(foundPin);
        setTypedValue(value);
    }

    useEffect(() => {
        if (foundSearchResult !== undefined && foundSearchResult !== null) {
            dispatch(setCenter({
                center: {
                    lat: parseFloat(foundSearchResult.lat),
                    lng: parseFloat(foundSearchResult.lng)
                }
            }))
            dispatch(setBouncePin(foundSearchResult.id))
            dispatch(setZoom(30))
            setFoundSearchResult(null)
        }
    }, [typedValue, foundSearchResult]);

    useEffect(() => {
        if (!searchValue) {
            setTypedValue('')
            setFoundSearchResult(null)
        }
    }, [searchValue])

    return (
        <Drawer.Body>
            <S.AllSearchDrawerContent>
                <Input
                    label='Pesquisar'
                    placeholder='Stonecode, ID ou cód. rastreio'
                    size="small"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    suffixIcon={<GingaIcon name='search' color='#767F8D' size='18px' />}
                    onPressEnter={(e) => handlePressEnter(e)}
                    allowClear
                />
                {
                    foundSearchResult === undefined && (
                        <Drawer.EmptySearch message={`Nenhum resultado para “${typedValue}”.`} style={{ height: '90%' }} />
                    )
                }
            </S.AllSearchDrawerContent>
        </Drawer.Body>
    )
}

export default SearchDrawer;
