import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import LineChart from "@components/LineChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import Colors from "@colors";

export default function TmaTon(props) {
  const title = "Velocidade TON (TMA)";
  const [tmaTon, setTmaTon] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getTmaTon().then((response) => {
      if (response && response.data != null) {
        setTmaTon(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const tmaGeral = [];
  const tmaProprio = [];
  const tmaTerceira = [];
  const downloadLabels = [
    "Período",
    "TMA Geral",
    "TMA Op. Própria",
    "TMA Op. Terceira",
  ];
  const downloadData = [];

  tmaTon &&
    tmaTon.map(
      (item) => (
        periodos.push(item.periodo),
        tmaGeral.push(item.geral ? item.geral.toFixed(2) : 0),
        tmaProprio.push(item.propria ? item.propria.toFixed(2) : 0),
        tmaTerceira.push(item.terceira ? item.terceira.toFixed(2) : 0),
        downloadData.push([
          `${item.periodo}`,
          `${item.geral ? item.geral.toFixed(3) : 0}`,
          `${item.propria ? item.propria.toFixed(3) : 0}`,
          `${item.terceira ? item.terceira.toFixed(3) : 0}`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            suggestedMax: 1.2,
            min: 0.6,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
          },
          gridLines: false,
        },
      ],
    },
    data: [
      {
        label: "GERAL",
        color: Colors.green,
        fill: false,
        value: tmaGeral,
        hidden: true,
      },
      { label: "PRÓPRIA", color: Colors.blue, fill: false, value: tmaProprio },
      {
        label: "TERCEIRA",
        color: Colors.gray,
        fill: false,
        value: tmaTerceira,
        hidden: true,
      },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title}
      info={[[""]]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <LineChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
