import React, { useState, useEffect } from "react";
import Breadcrumbs from "@components/Breadcumbs";
import LogisticService from "@LogisticService";
import PageHeader from "@components/PageHeader";
import useRouter from "../../../../hooks/useRouter";
import { PageLayout } from "../../../../Layout/PageLayout";
import Card from "@components/Card";
import HistoricTable from "./componentes/HistoricTable";
import HistoricInfoDrawer from "./componentes/HistoricInfoDrawer";

export const HistoricoDoa = () => {
  const [tipoConsulta, setTipoConsulta] = useState("");
  const [drawerInfoOpened, setDrawerInfoOpened] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(undefined);
  const [judgedRegister, setJudgedRegister] = useState({});
  const [idDoa, setIdDoa] = useState("");
  const [loading, setLoading] = useState("pending");

  const [clickedRowHistoric, setClickedRowHistoric] = useState("");

  const {
    query: { consulta },
  } = useRouter();

  useEffect(() => {
    setTipoConsulta(consulta);
  }, []);

  useEffect(() => {
    setIdDoa("");
  }, [drawerInfoOpened]);

  useEffect(() => {
    LogisticService.getRegistroDoaDetalhado(idDoa).then((response) => {
      if (response.data.data) {
        setClickedRowHistoric(response.data.data);
        setLoading(response.status);
      }
    });
  }, [idDoa]);

  const selectHistoricRow = (item) => {
    setIdDoa(item.id_doa);
    setDrawerInfoOpened(true);
  };

  const onCloseHistoricDrawer = () => {
    setDrawerInfoOpened(false);
    setSelectedRowKey(undefined);
    setJudgedRegister(undefined);
    setClickedRowHistoric("");
  };

  return (
    <>
      <PageLayout>
        <div>
          <Breadcrumbs />
          <PageHeader title={tipoConsulta} />
          <main className="espaco-doa-main">
            <HistoricInfoDrawer
              onClose={onCloseHistoricDrawer}
              open={drawerInfoOpened}
              clickedRowHistoric={clickedRowHistoric}
              loading={loading}
            />
            <Card.Container>
              <Card.Content classname="contentDOA" noPadding>
                <div className="pending-table">
                  <HistoricTable
                    tipoConsulta={tipoConsulta}
                    HandleRowClick={(key) => selectHistoricRow(key)}
                  />
                </div>
              </Card.Content>
            </Card.Container>
          </main>
        </div>
      </PageLayout>
    </>
  );
};
