import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconBox } from "./styles";
import Colors from "@colors";

const Icon = (props) => {
  return (
    <IconBox margin={props.margin} size={props.size}>
      <FontAwesomeIcon
        icon={props.name}
        size={"1x"}
        color={props.color ? props.color : Colors.gray}
      />
    </IconBox>
  );
};

export default Icon;
