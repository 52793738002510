import React from "react";
import { Polyline, Marker } from "@react-google-maps/api";

const AngelPolyline = React.memo(({ routes }) => {
  const svgMarker = (color, text) =>
    "data:image/svg+xml;utf8," +
    encodeURIComponent(
      `<?xml version="1.0"?>
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle fill="${color}" cx="12" cy="12" r="12"/>
            <text x="49%" y="51%" font-size="13px" text-anchor="middle" alignment-baseline="middle" font-weight="bold" fill="#fff" font-family="Helvetica">${text}</text>
        </svg>`
    );

  return (
    routes &&
    routes[0] &&
    routes.map((route, i) => (
      <span key={i}>
        <Polyline
          path={window.google.maps.geometry.encoding.decodePath(
            route.mapsRoutes.polyline
          )}
          options={{ strokeColor: route.color, strokeWeight: 6 }}
        />
        {route.mapsRoutes.markers.map(({ lat, lng, number }, index) => (
          <Marker
            key={index}
            position={{ lat, lng }}
            icon={{
              url: svgMarker(route.color, number),
              anchor: { x: 12, y: 12 },
            }}
          />
        ))}
      </span>
    ))
  );
});

export default AngelPolyline;
