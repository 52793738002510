export default function tradutor_estados_uf(value) {
  const estado = value.toUpperCase();
  if (estado === "RIO DE JANEIRO") {
    return "RJ";
  }
  if (estado === "MINAS GERAIS") {
    return "MG";
  }
  if (estado === "SÃO PAULO") {
    return "SP";
  }
  if (estado === "ESPÍRITO SANTO") {
    return "ES";
  }
  if (estado === "RIO GRANDE DO SUL") {
    return "RS";
  }
  if (estado === "PARANÁ") {
    return "PR";
  }
  if (estado === "SANTA CATARINA") {
    return "SC";
  }
  if (estado === "GOIÁS") {
    return "GO";
  }
  if (estado === "MATO GROSSO") {
    return "MO";
  }
  if (estado === "MATO GROSSO DO SUL") {
    return "MS";
  }
  if (estado === "BAHIA") {
    return "BA";
  }
  if (estado === "PIAUÍ") {
    return "PI";
  }
  if (estado === "TOCANTINS") {
    return "TO";
  }
  if (estado === "PERNAMBUCO") {
    return "PE";
  }
  if (estado === "PARAÍBA") {
    return "PR";
  }
  if (estado === "RIO GRANDE DO NORTE") {
    return "RN";
  }
  if (estado === "CEARÁ") {
    return "CE";
  }
  if (estado === "ACRE") {
    return "AC";
  }
  if (estado === "AMAZONAS") {
    return "AM";
  }
  if (estado === "ALAGOAS") {
    return "AL";
  }
  if (estado === "AMAPÁ") {
    return "AP";
  }
  if (estado === "DISTRITO FEDERAL") {
    return "DF";
  }
  if (estado === "MARANHÃO") {
    return "MA";
  }
  if (estado === "PARÁ") {
    return "PA";
  }
  if (estado === "RONDÔNIA") {
    return "RO";
  }
  if (estado === "RORAIMA") {
    return "RR";
  }
  if (estado === "SERGIPE") {
    return "SE";
  } else {
    return estado;
  }
}
