import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button } from "antd";

export const AllDrawerContentHistoric = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-space-align-center {
    margin-top: 18px;
    flex-wrap: wrap;
  }
`;

export const TopContentHistoric = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;

  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(118, 127, 141, 1);
  }

  p {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: right;
    color: rgba(48, 55, 66, 1);
  }
`;

export const ContainerDefectHistoric = styled.div`
  border-top: 1px solid rgba(118, 127, 141, 0.1);

  .ant-tag {
    margin-top: 16px !important;
  }
`;

export const DefectDetailsHistoric = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${(props) => (props.firstDefect ? "16px" : "8px")};
  align-items: stretch;

  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(118, 127, 141, 1);
  }

  h3 {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: right;
    color: rgba(48, 55, 66, 1);
    display: flex;
  }
`;

export const DefectDetailWithImageHistoric = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DefectContentObservationHistoric = styled.div`
  width: fit-content;
  background-color: rgba(118, 127, 141, 0.1);
  padding: 5px 8px 5px 9.33px;
  border-radius: 999px;
  margin-top: 16px;
  display: flex;
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.xs};
  color: ${defaultTheme.colors.grey700};
`;

export const BottomContentDrawerHistoric = styled.div`
  border-top: 1px solid #767f8d1a;
  padding-top: 12px;
`;

export const DrawerFooterButtonHistoric = styled(Button)`
  width: 328px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Sharon Sans";
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  border-color: transparent !important;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-left: ${(props) => (props.handleButton ? "18px" : "0px")} !important;
  margin-top: 8px;
  background-color: ${(props) =>
    props.firstbutton || props.handleButton
      ? defaultTheme.colors.green600
      : defaultTheme.colors.grey50};
  color: ${(props) =>
    props.firstbutton || props.handleButton
      ? defaultTheme.colors.white
      : defaultTheme.colors.grey700} !important;
  &:hover {
    background-color: ${(props) =>
      props.firstbutton || props.handleButton
        ? defaultTheme.colors.green700
        : defaultTheme.colors.grey20} !important;
  }
`;
