import { LoadingOutlined } from "@ant-design/icons";
import Box from "@components/Box";
import Container from "@components/Container";
import CustomButton from "@components/CustomButton";
import CustomModal from "@components/CustomModal";
import DetailButton from "@components/DetailButton";
import InfoTable from "@components/InfoTable";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import React, { useEffect, useState } from "react";
import AberturaCaso from "./AberturaCaso";
import DetalhesCaso from "./DetalhesCaso";

export default function SalesforceCasos(props) {
  const loading = <LoadingOutlined spin />;
  const [casosAntigos, setCasosAntigos] = useState([]);
  const [casoSelecionado, setCasoSelecionado] = useState(null);
  const [visibleAbrirNovoCaso, setVisibleAbrirNovoCaso] = useState(false);
  const [visibleDetalhesCaso, setVisibleDetalhesCaso] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    LogisticService.getSalesforceTodosCasos(props.stonecode).then(
      (response) => {
        if (response && response.data !== null) {
          setCasosAntigos(response.data);
        }
        setLoaded(true);
      }
    );
  }, [props.stonecode]);

  const dadosCasos = [];
  const colunasCasos = [
    {
      label: "Número",
      key: "numero_caso",
      align: "start",
      sort: {
        compare: (a, b) => a.numero_caso.localeCompare(b.numero_caso),
      },
    },
    {
      label: "Assunto",
      key: "assunto",
      sort: {
        compare: (a, b) => a.assunto.localeCompare(b.assunto),
      },
    },
    {
      label: "Data",
      key: "retornar_cliente",
      sort: {
        compare: (a, b) => a.retornar_cliente.localeCompare(b.retornar_cliente),
      },
    },
    {
      label: "Status",
      key: "status",
      sort: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
    },
    {
      label: "Detalhes",
      key: "detalhes",
    },
  ];
  const downloadLabels = ["Número", "Assunto", "Data", "Status"];
  const downloadData = [];

  casosAntigos &&
    casosAntigos.forEach((item, index) => {
      const row = {};
      row["key"] = index;
      row["numero_caso"] = item.numero_caso || "";
      row["assunto"] = item.assunto || "";
      row["data"] = item.retornar_cliente || "";
      row["status"] = item.status || "";
      row["detalhes"] = (
        <DetailButton
          onClick={() => {
            setCasoSelecionado(item.numero_caso);
            setVisibleDetalhesCaso(true);
          }}
        />
      );
      dadosCasos.push(row);
      const rowDownload = [];
      rowDownload.push(item.numero_caso || "");
      rowDownload.push(item.assunto || "");
      rowDownload.push(item.retornar_cliente || "");
      rowDownload.push(item.status || "");
      downloadData.push(rowDownload);
    });

  const tableData = {
    data: dadosCasos,
    columns: colunasCasos,
  };

  const customFooter = () => (
    <CustomButton
      onClick={() => {
        setVisibleAbrirNovoCaso(true);
      }}
    >
      Abrir Novo Caso
    </CustomButton>
  );

  return (
    <Container
      title={`Salesforce - Casos`}
      icon={"info-circle"}
      grid={1}
      expandable
    >
      {loaded ? (
        <Box margin={"0 8px 8px"}>
          <Container
            title={"Histórico de Casos"}
            downloadLabels={downloadLabels}
            downloadData={downloadData}
          >
            <InfoTable
              data={tableData}
              pagination={{ defaultPageSize: 6, showSizeChanger: true }}
              footer={customFooter}
            />
          </Container>
          <CustomModal
            title={`Detalhes Caso ${casoSelecionado ? casoSelecionado : ""}`}
            visible={visibleDetalhesCaso}
            onCancel={() => setVisibleDetalhesCaso(false)}
            zIndex={1010}
            width={1200}
            footer={false}
            margin={0}
          >
            <DetalhesCaso data={casoSelecionado} />
          </CustomModal>
          <CustomModal
            title={"Abrir Novo Caso"}
            visible={visibleAbrirNovoCaso}
            onCancel={() => setVisibleAbrirNovoCaso(false)}
            zIndex={1010}
            width={1200}
            footer={false}
          >
            <AberturaCaso
              stonecode={props.stonecode}
              visible={setVisibleAbrirNovoCaso}
            />
          </CustomModal>
        </Box>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
