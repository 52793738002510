import {
  DownloadOutlined,
  DownOutlined,
  RightOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import XLSX from "xlsx";
import Colors from "@colors";
import {
  Anchor,
  AnchorBox,
  AnchorTree,
  AnchorTreeHeader,
  Box,
  Content,
  DownloadAllButton,
  PageBox,
  PageHeader,
  SearchBox,
  Title,
  Tree,
  TreeItem,
  ModalButton,
} from "./styles";

const Page = (props) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const openDropdown = () => {
    setOpen(!open);
  };

  let width = window.innerWidth;

  const exportToXlsx = () => {
    const data = props.downloadAll.sort((a, b) => (a.index > b.index ? 1 : -1));
    const wb = XLSX.utils.book_new();

    data.map((item) => {
      let data = [];
      data.push(item.labels);
      data.push.apply(data, item.data);
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, item.title.slice(0, 31));
    });

    if (data.length > 0) {
      XLSX.writeFile(
        wb,
        `${props.title}${props.subTitle ? " - " + props.subTitle : ""}.xlsx`
      );
    }
  };

  return (
    <PageBox id={props.id}>
      <PageHeader nonFixed={window.location.pathname == "/ferramentas/hunter"}>
        <Box>
          {props.title ? (
            <Title>
              {props.previous ? (
                <>
                  {props.previous}
                  <RightOutlined style={{ margin: "0 8px" }} />
                </>
              ) : (
                <></>
              )}
              {props.title}
              {props.showSubTitle && props.subTitle ? (
                <>
                  <RightOutlined style={{ margin: "0 8px" }} />
                  {props.subTitle}
                </>
              ) : (
                <></>
              )}
            </Title>
          ) : (
            <></>
          )}
          {props.anchors ? (
            <AnchorBox>
              {props.anchors.map((item) => {
                if (item.children != undefined) {
                  return (
                    <AnchorTree>
                      <AnchorTreeHeader onClick={openDropdown}>
                        {item.icon ? item.icon : ""}
                        {width > 768 ? item.name : ""}
                        <DownOutlined style={{ margin: "0 -8px 0 8px" }} />
                      </AnchorTreeHeader>
                      <Tree open={open}>
                        {item.children.map((child) => (
                          <HashLink to={`${location.pathname}#${child.hash}`}>
                            <TreeItem>{child.name}</TreeItem>
                          </HashLink>
                        ))}
                      </Tree>
                    </AnchorTree>
                  );
                } else {
                  return (
                    <HashLink to={`${location.pathname}#${item.hash}`}>
                      <Anchor>
                        {item.icon ? item.icon : ""}
                        {width > 768 ? item.name : ""}
                      </Anchor>
                    </HashLink>
                  );
                }
              })}
            </AnchorBox>
          ) : (
            <></>
          )}
          {props.downloadAll ? (
            <Tooltip title="Download de toda a página" placement="left">
              <DownloadAllButton
                onClick={() => {
                  exportToXlsx();
                }}
              >
                <DownloadOutlined />
              </DownloadAllButton>
            </Tooltip>
          ) : (
            <></>
          )}
          {props.ownerModal ? (
            <ModalButton
              style={{}}
              onClick={() => props.setIsOwnerModalVisible(true)}
            >
              <Anchor>
                <UserAddOutlined />
                Cadastro Owner ID
              </Anchor>
            </ModalButton>
          ) : (
            <></>
          )}
        </Box>
        {props.searchContent ? (
          <Box search>
            <SearchBox>{props.searchContent}</SearchBox>
          </Box>
        ) : (
          <></>
        )}
      </PageHeader>
      <Content search={props.searchContent} fullScreen={props.fullScreen}>
        {props.children}
      </Content>
    </PageBox>
  );
};

export default Page;
