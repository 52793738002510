import React, { useState, useEffect } from "react";
import { Empty, Table, Tag, message } from "antd";
import { useForm } from "react-hook-form";
import { FiChevronRight } from "react-icons/fi";
import LogisticService from "@LogisticService";
import filesGrey from "@assets/imgs/files-grey.png";
import CellButton from "@components/CellButton";
import FormSearchBox from "@components/Form/FormSearchBox";
import Card from "@components/Card";
import useRouter from "../../../../../hooks/useRouter";

import { usePaginationConfig } from "@hooks/usePaginationConfig";
import {
  toCapitalizeFirstLetterOfEachWord,
  compareTableColumn,
} from "../../../../../utils/common";

import "./styles.css";

const HistoricTable = ({ HandleRowClick, tipoConsulta }) => {
  const { control, handleSubmit, resetField } = useForm({
    defaultValues: {
      searchSerialOrEmail: "",
      searchDistrict: null,
    },
  });

  const paginationConfig = usePaginationConfig();
  const [filteredData, setFilteredData] = useState([]);
  const [historicRegisters, setHistoricRegisters] = useState([]);

  const { push } = useRouter();

  const voltaParaAnexos = () => {
    push(`/ferramentas/anexos`);
  };

  useEffect(() => {
    if (tipoConsulta.length > 0) {
      LogisticService.getRegistrosDoa(tipoConsulta).then((response) => {
        if (response.status === 200) {
          setHistoricRegisters(response);
          setFilteredData(response.data.data);
        } else {
          voltaParaAnexos();
          message.error(response.data.erro);
        }
      });
    }
  }, [tipoConsulta]);

  const handleFilter = ({ searchSerialOrEmail }) => {
    const newData = filteredData.filter((item) => {
      return (
        item.serial.split(searchSerialOrEmail).length > 1 ||
        item.reporte_por.split(searchSerialOrEmail).length > 1
      );
    });
    setFilteredData(newData);
  };

  const onClean = () => {
    resetField("searchSerialOrEmail");
    setFilteredData(historicRegisters.data.data);
  };

  useEffect(() => {
    if (historicRegisters.data) {
      setFilteredData(historicRegisters.data.data);
    }
  }, [historicRegisters]);

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <CellButton
          style={{ color: "#000" }}
          onClick={() => HandleRowClick(record)}
          type="button"
        >
          <Tag
            color={
              record.status === "aprovado"
                ? "success"
                : record.status === "pendente"
                ? "default"
                : "error"
            }
          >
            {toCapitalizeFirstLetterOfEachWord(text)}
          </Tag>
        </CellButton>
      ),
      sorter: {
        compare: (a, b) => compareTableColumn(a, b, "status"),
      },
    },
    {
      title: "Serial",
      dataIndex: "serial",
      key: "serial",
      render: (text, record) => (
        <CellButton onClick={() => HandleRowClick(record)} type="button">
          <p className="pending-info">{text}</p>
        </CellButton>
      ),
    },
    {
      title: "Defeito",
      dataIndex: "defeito",
      key: "defeito",
      render: (text, record) => (
        <CellButton onClick={() => HandleRowClick(record)} type="button">
          <p className="pending-info">
            {toCapitalizeFirstLetterOfEachWord(text)}
          </p>
        </CellButton>
      ),
    },
    {
      title: "Reportado por",
      dataIndex: "reporte_por",
      key: "reporte_por",
      render: (text, record) => (
        <CellButton onClick={() => HandleRowClick(record)} type="button">
          <p className="pending-info">
            {toCapitalizeFirstLetterOfEachWord(text)}
          </p>
        </CellButton>
      ),
    },
    {
      title: "Avaliado por",
      dataIndex: "avaliado_por",
      key: "avaliado_por",
      render: (text, record) => (
        <CellButton onClick={() => HandleRowClick(record)} type="button">
          <p className="pending-info">{text}</p>
        </CellButton>
      ),
      sorter: {
        compare: (a, b) => compareTableColumn(a, b, "avaliado_por"),
      },
    },
    {
      title: "Realizado Em",
      dataIndex: "reporte_em",
      key: "reporte_em",
      render: (text, record) => (
        <CellButton onClick={() => HandleRowClick(record)} type="button">
          <p className="pending-info">{text}</p>
        </CellButton>
      ),
      sorter: {
        compare: (a, b) => compareTableColumn(a, b, "reporte_em"),
      },
    },
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      align: "right",
      width: "52px",
      rowSpan: 2,
      render: (_, record) => {
        const obj = {
          children: (
            <CellButton onClick={() => HandleRowClick(record)} type="button">
              <FiChevronRight size={24} color="#A6AEBA" />
            </CellButton>
          ),
          props: {},
        };
        return obj;
      },
    },
  ];

  return (
    <Card.Container>
      <Card.Header>
        <div className="row-margin-bottom">
          <div className="row-justify-between-align-center">
            <div className="card-content-gap-1">
              <strong className="heading-bold-lg">Resultado da busca</strong>
              <p className="body-regular-sm">
                Lista com reports dos últimos 90 dias.
              </p>
            </div>

            <div className="forms-filters">
              <FormSearchBox
                name="searchSerialOrEmail"
                control={control}
                label="Buscar por serial ou e-mail"
                placeholder="Digite o serial ou e-mail"
                onClean={onClean}
                onSearch={handleSubmit(handleFilter)}
                size="small"
              />
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Content>
        <Table
          columns={columns}
          dataSource={filteredData}
          loading={historicRegisters.status !== 200}
          pagination={paginationConfig}
          locale={{
            emptyText: (
              <Empty
                style={{ margin: "5rem" }}
                image={filesGrey}
                description="Histórico de reports DOA vazio."
              />
            ),
          }}
        />
      </Card.Content>
    </Card.Container>
  );
};

export default HistoricTable;
