import styled from "styled-components";
import Colors from "@colors";

export const Header = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4vw;
  background-color: ${Colors.light};
`;

export const Image = styled.img`
  height: 24px;
  margin: 0 0 4px;
`;

export const Box = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Menu = styled.div`
  background-color: ${Colors.deepBlue};
  z-index: 1000;
  position: fixed;
  height: 100%;
  width: 320px;
  left: 0;
  top: 0;
  box-shadow: 2px 0 8px 2px rgb(0 0 0 / 10%);
  transition: 0.2s;
  transform: translate(${(props) => (props.open ? "0" : "-100%")}, 0);
`;

export const List = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 10vh 0 0;
`;

export const Item = styled.li`
  list-style: none;
  padding: 16px 24px;
`;

export const ItemText = styled.p`
  font-size: 14px;
  color: ${Colors.textGreen};
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;
  a {
    color: ${Colors.textGreen};
    &:hover {
      color: ${Colors.light};
    }
  }
`;

export const Child = styled.p`
  margin: 8px 16px;
  a {
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1px;
    color: ${(props) => (props.active ? Colors.light : Colors.lightGreen)};
    &:hover {
      color: ${Colors.light};
    }
  }
`;

export const CloseButton = styled.span`
  height: 48px;
  width: 48px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  &::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 2px;
    background: ${Colors.textGreen};
    transform: rotate(45deg);
  }
  &::after {
    content: "";
    position: absolute;
    height: 20px;
    width: 2px;
    background: ${Colors.textGreen};
    transform: rotate(-45deg);
  }
  &:hover {
    transform: rotate(-90deg);
  }
`;

export const MenuButton = styled.div`
  height: 48px;
  width: 48px;
  position: absolute;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  margin: 8px;
  &:hover {
    span {
      width: 24px;
      &::before {
        width: 20px;
      }
      &::after {
        width: 20px;
      }
    }
  }
`;

export const Hamburguer = styled.span`
  height: 2px;
  width: 20px;
  background: ${Colors.textGreen};
  transition: 0.2s linear;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 24px;
    background: ${Colors.textGreen};
    transition: 0.2s linear;
  }
  &::before {
    top: 16px;
  }
  &::after {
    bottom: 16px;
  }
`;

export const LogoutButton = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;
  a {
    color: ${Colors.textGreen};
    &:hover {
      color: ${Colors.lighterGreen};
    }
  }
`;
