import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const AutoDivComplete = styled.div`
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    pointer-events: none;
    padding: 5px;
    position: absolute;
    left: ${({ iconOnRight }) => (iconOnRight ? "10px" : "38px")};
    top: 5%;
    font-size: ${defaultTheme.fontSize.m};
    letter-spacing: -0.01em;
    color: ${defaultTheme.colors.grey500};
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    height: 44px;
    border: 1px solid rgba(166, 174, 186, 1);
  }

  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    cursor: auto;
    position: absolute;
    left: ${({ iconOnRight }) => (iconOnRight ? "6px" : "34px")};
    top: 16%;
    width: 83.2019%;
  }
`;

export const IconSearchBox = styled.div`
  position: absolute;
  z-index: 1000;
  left: ${({ iconOnRight }) => (iconOnRight ? "auto" : "14px")};
  right: ${({ iconOnRight }) => (iconOnRight ? "14px" : "auto")};
  top: 24%;
`;
