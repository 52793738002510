import styled from "styled-components";
import Colors from "@colors";

export const Span = styled.span`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: inline-block;
  background: ${(props) => {
    if (props.red) return Colors.redDot;
    else if (props.yellow) return Colors.yellowDot;
    else if (props.green) return Colors.greenDot;
    else return Colors.inactiveGray;
  }};
`;
