import { Form, Input, message, Select, Space } from "antd";
import React, { useState } from "react";
import CustomButton from "@components/CustomButton";
import CustomLink from "@components/CustomLink";
import LogisticService from "@LogisticService";
import Row from "@components/Row";
import SelectBox from "@components/SelectBox";
import UFs from "@ufs";
import { FakeRiquiredLabel, FormItem } from "../styles";

const { Option } = Select;

export default function NovoEndereco(props) {
  const [form] = Form.useForm();

  const [endereco, setEndereco] = useState({ nome_cliente: props.cliente });
  const [selectedUF, setSelectedUF] = useState(null);

  const onChangeUF = (uf) => {
    setSelectedUF(UFs.find((x) => x.id === uf).name);
    form.setFieldsValue({ estado: uf });
  };

  const validoCEP = (cep) => {
    return /^[0-9]{8}$/.test(cep);
  };

  const consultaCEP = (codigo) => {
    if (codigo.length === 8 && validoCEP(codigo)) {
      LogisticService.getEnderecoCEP(codigo).then((response) => {
        const resp = response.data;
        if (!resp.erro) {
          form.setFieldsValue({
            cep: codigo,
            logradouro: resp.logradouro,
            cidade: resp.localidade,
            bairro: resp.bairro,
          });
          onChangeUF(resp.uf);
        }
      });
    }
  };

  const cadastrarEndereco = () => {
    LogisticService.postEnderecoCliente(props.stonecode, endereco).then(
      (response) => {
        if (response.data.sucesso) {
          message.success("Endereço cadastrado com sucesso");
          form.resetFields();
          props.trocaEndereco(endereco);
          props.visible(false);
        } else {
          message.error(response.data.erro);
        }
      }
    );
  };

  const selectTipoLogradouro = (
    <Select defaultValue="Rua">
      <Option value="Rua">Rua</Option>
      <Option value="Avenida">Avenida</Option>
      <Option value="Travessa">Travessa</Option>
    </Select>
  );

  return (
    <Form
      form={form}
      layout={"vertical"}
      onValuesChange={(_, allValues) => {
        form.setFieldsValue(allValues);
        setEndereco({ ...endereco, ...allValues });
      }}
    >
      <FormItem
        label="CEP"
        name={"cep"}
        rules={[{ required: true, message: "Digite o cep!" }]}
      >
        <Input
          onChange={(event) =>
            consultaCEP(event.target.value.toString().replace("-", ""))
          }
        />
      </FormItem>
      <FormItem
        label="Logradouro"
        name={"logradouro"}
        rules={[{ required: true }]}
      >
        <Input addonBefore={selectTipoLogradouro} />
      </FormItem>
      <Row nonResponsive>
        <FormItem
          label="Número"
          name={"numero"}
          rules={[{ required: true }]}
          style={{ width: "25%" }}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Complemento"
          name={"complemento"}
          rules={[{ required: true }]}
          style={{ width: "100%", marginLeft: "16px" }}
        >
          <Input />
        </FormItem>
      </Row>
      <Row nonResponsive>
        <FormItem
          label="Bairro"
          name={"bairro"}
          rules={[{ required: true }]}
          style={{ width: "100%", marginRight: "8px" }}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Cidade"
          name={"cidade"}
          rules={[{ required: true }]}
          style={{ width: "100%", marginLeft: "8px" }}
        >
          <Input />
        </FormItem>
      </Row>
      <FakeRiquiredLabel>Estado</FakeRiquiredLabel>
      <SelectBox
        margin={"0 0 8px"}
        options={UFs}
        onChange={onChangeUF}
        value={selectedUF || null}
      />
      <FormItem hidden name={"estado"} rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem label="Referência" name={"referencia"}>
        <Input />
      </FormItem>
      <Row horizontalAlign={"flex-end"} margin={"24px 0 0"}>
        <Space>
          <CustomButton htmlType="submit" onClick={() => cadastrarEndereco()}>
            Cadastrar
          </CustomButton>
          <CustomLink danger onClick={() => props.visible(false)}>
            Cancelar
          </CustomLink>
        </Space>
      </Row>
    </Form>
  );
}
