import styled from "styled-components";
import Colors from "@colors";

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.noFlex ? "unset" : 1)};
  margin: ${(props) => props.margin || "8px"};
  padding: ${(props) => props.padding || "0"};
  align-items: ${(props) => props.verticalAlign || "unset"};
  justify-content: ${(props) => props.horizontalAlign || "unset"};
  background-color: ${(props) => props.color || "transparent"};
  height: ${(props) => (props.height ? `${props.height}px` : "auto")};
  max-height: ${(props) => props.maxHeight || "auto"};
  width: ${(props) => (props.width ? props.width : "auto")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "auto")};
  overflow-y: ${(props) => (props.scroll ? "auto" : "unset")};
  scroll-margin-top: ${(props) =>
    props.scrollWithSearchMenuSize ? "168px" : "112px"};
  @media (max-width: 1024px) {
    width: ${(props) =>
      props.widthMediumScreenSize ? props.widthMediumScreenSize : "auto"};
  }
  @media (max-width: 768px) {
    width: ${(props) =>
      props.widthSmallScreenSize
        ? props.widthSmallScreenSize
        : props.widthMediumScreenSize
        ? props.widthMediumScreenSize
        : "auto"};
  }
  a:last-child {
    border-bottom: none;
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1.2px;
  color: ${Colors.textGreen};
  margin: ${(props) =>
    props.hasDownload ? "-10px 8px 16px" : "24px 8px 16px"};
  display: ${(props) => (props.hasDownload ? "flex" : "block")};
  flex-direction: ${(props) => (props.hasDownload ? "row-reverse" : "start")};
`;
