import React, { useEffect, useRef, useState } from "react";
import AreaDeRisco from "./AreaDeRisco";
import AreaPoligono from "./AreaPoligono";
import { useSelector } from "react-redux";
import { oldSenninhaSelector } from "../../../../../features/senninha/oldSenninhaSlice";

import * as S from "./styles";


const Poligonos = ({
    map,
}) => {

    const {
        oldSenninhaWorkFlow
    } = useSelector(oldSenninhaSelector);

    return (
        <>
            {
                oldSenninhaWorkFlow === 'poligonos' ? (
                    <AreaPoligono map={map} />
                ) : (
                    <AreaDeRisco map={map} />
                )
            }
        </>
    );
};

export default Poligonos;
