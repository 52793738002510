import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import InfoTable from "@components/InfoTable";
import Row from "@components/Row";
import LogisticService from "@LogisticService";

export default function POS(props) {
  const loading = <LoadingOutlined spin />;
  const [pOS, setPOS] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getMetodos(props.stonecode).then((response) => {
      if (response && response.data != null) {
        setPOS(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const dados = [];
  const colunas = [
    {
      label: "Serial",
      key: "serial",
    },
    {
      label: "Modelo",
      key: "modelo",
      sort: {
        compare: (a, b) => a.modelo.localeCompare(b.modelo),
      },
    },
    {
      label: "Status",
      key: "status",
    },
  ];

  pOS.map((item) => {
    const row = {};
    row["serial"] = item.serialNumber;
    row["modelo"] = item.model.name;
    row["status"] = item.status.name;
    dados.push(row);
  });

  const tableData = {
    data: dados,
    columns: colunas,
  };

  return (
    <Container grid={1 / 2} title={`Máquinas do Cliente`}>
      {loaded ? (
        <InfoTable
          data={tableData}
          pagination={{ defaultPageSize: 4, showSizeChanger: true }}
          search
        />
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
