import React, { useState } from "react";
import { Image } from "antd";
import * as S from "./styles";

export const ViewerLabel = ({ prefix, src, title, content, ...props }) => {
  return (
    <S.ViewerContainer prefix={prefix}>
      <S.Viewer>
        <S.ViewerAll>
          <S.ViewerPrefix>
            {prefix && src && (
              <Image
                src={src ? src[0].thumbUrl : ""}
                preview={false}
                width={"44px"}
                height={"44px"}
                style={{
                  borderRadius: "8.23px",
                }}
              />
            )}
            <S.ViewerContent prefix={prefix}>
              <S.ViewerTitle>{title}</S.ViewerTitle>
              <S.ViewerSubTitle>{content}</S.ViewerSubTitle>
            </S.ViewerContent>
          </S.ViewerPrefix>
        </S.ViewerAll>
      </S.Viewer>
    </S.ViewerContainer>
  );
};
