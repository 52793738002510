import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Colors from "@colors";
import Box from "@components/Box";
import CustomButton from "@components/CustomButton";
import CustomLink from "@components/CustomLink";
import CustomModal from "@components/CustomModal";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import Row from "@components/Row";
import SelectBox from "@components/SelectBox";
import WeekPicker from "@components/WeekPicker";
import LogisticService from "@LogisticService";
import { DatePicker, Form, Input, message, Select, Space, Tooltip } from "antd";
import moment from "moment";
import { default as React, useEffect, useState } from "react";
import { FormItem } from "../styles";
import NovoEndereco from "./NovoEndereco";
import tradutor_tipo_equipamento from "./TradutorEquipamentos";
import tradutor_estados_uf from "./TradutorEstados";
// import { useAuthContext } from '../../../../stonelog-auth-components';
import { useAuthContext } from "@stonelog/stonelog-auth-components";

const { Option } = Select;

export default function AberturaOSs(props) {
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(false);
  const [nomeCliente, setNomeCliente] = useState("");
  const [visibleNovoEndereco, setVisibleNovoEndereco] = useState(false);
  const [horariosDeFuncionamento, setHorariosDeFuncionamento] = useState([]);
  const [enderecos, setEnderecos] = useState([]);
  const [opcoesEnderecos, setOpcoesEnderecos] = useState([]);
  const [selectedEndereco, setSelectedEndereco] = useState(null);
  const [diasDisponiveis, setDiasDisponiveis] = useState(false);
  const [changeAll, setChangeAll] = useState(false);
  const [loadedOS, setLoadedOS] = useState(true);
  const [loadedEnderecos, setLoadedEnderecos] = useState(false);
  const [loadedDatasAgendamento, setLoadedDatasAgendamento] = useState(null);
  const auth = useAuthContext();
  const userName = auth.getUser();

  function enableDate(current) {
    return diasDisponiveis.every(
      (date) => date !== moment(current).format("YYYY-MM-DD")
    );
  }

  useEffect(() => {
    let defaultValues = {
      stonecode: props.stonecode,
      grupo: "DESINSTALAÇÃO",
      tipo: "CAÇA POS",
      criado: `Sistema Caça POS: |${userName.email}|`,
      tipo_cliente: "Pessoa Juridica",
    };
    form.setFieldsValue(defaultValues);
    LogisticService.getInformacoesGerais(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        const data = response.data;
        setNomeCliente(data.information.fantasyName);
        defaultValues = {
          ...defaultValues,
          tipo_documento: data.information.documentType,
          nome_fantasia: data.information.fantasyName
            ? data.information.fantasyName
            : "Indisponivel",
          documento: data.information.document
            ? data.information.document
            : "Indisponivel",
          nome: data.contacts[0].name,
          telefone: data.contacts[0].phone,
          celular: data.contacts[0].mobilePhone,
          email: data.contacts[0].email,
        };
      }
      setLoaded(true);
      form.setFieldsValue(defaultValues);
      LogisticService.getEnderecoCliente(props.stonecode).then((response) => {
        if (response && response.data !== null) {
          let list = [];
          response.data.map((item, index) => {
            item.estado = tradutor_estados_uf(item.estado);
            list.push({
              id: index,
              name: `${item.logradouro} ${item.numero}`,
            });
          });
          defaultValues = {
            ...defaultValues,
            ...response.data[0],
          };
          setEnderecos(response.data);
          setOpcoesEnderecos(list);
          setSelectedEndereco(list[0].name);
        }
        setLoadedEnderecos(true);
        form.setFieldsValue(defaultValues);
      });
    });
  }, []);

  useEffect(() => {
    let horario = {};
    Object.keys(horariosDeFuncionamento).forEach((key) => {
      if (horariosDeFuncionamento[key].state === true) {
        horario[
          key
        ] = `de ${horariosDeFuncionamento[key].open} às ${horariosDeFuncionamento[key].closed}`;
      }
    });
    form.resetFields(["horario_funcionamento"]);
    form.setFieldsValue({ horario_funcionamento: horario });
  }, [horariosDeFuncionamento]);

  const colunasMaquinasDesinstalacao = [
    {
      label: "ID",
      key: "terminal_id",
      align: "start",
      sort: {
        compare: (a, b) => a.id.localeCompare(b.id),
      },
    },
    {
      label: "Modelo",
      key: "model",
      sort: {
        compare: (a, b) => a.model.localeCompare(b.model),
      },
    },
    {
      label: "Serial",
      key: "serial_number",
      sort: {
        compare: (a, b) => a.serial_number.localeCompare(b.serial_number),
      },
    },

    {
      label: "Tipo",
      key: "type",
      sort: {
        compare: (a, b) => a.type.localeCompare(b.type),
      },
    },
  ];

  const maquinasDesinstalacaoTableData = {
    data: props.maquinasDesinstalacao,
    columns: colunasMaquinasDesinstalacao,
  };
  const trocaEndereco = (endereco) => {
    const selected = `${endereco.logradouro} ${endereco.numero}`;
    setEnderecos([...enderecos, endereco]);
    setSelectedEndereco(selected);
    setOpcoesEnderecos([
      ...opcoesEnderecos,
      { id: enderecos.length, name: selected },
    ]);
    form.setFieldsValue(endereco);
  };

  const carregarDatasAgendamento = () => {
    setLoadedDatasAgendamento(false);
    LogisticService.getAgendamentoDisponivel(
      tradutor_estados_uf(form.getFieldValue("estado")),
      form.getFieldValue("cidade"),
      form.getFieldValue("cep"),
      tradutor_tipo_equipamento(
        maquinasDesinstalacaoTableData.data[0].technology_type
      )
    ).then((response) => {
      if (response && response.data !== null) {
        setDiasDisponiveis(response.data);
      } else {
        setDiasDisponiveis([]);
      }
      setLoadedDatasAgendamento(true);
    });
  };

  const abrirOSs = (values) => {
    props.maquinasDesinstalacao.map((maquina) => {
      const request = {
        agendamento: values.agendamento
          ? values.agendamento.toISOString().slice(0, 10)
          : null,
        grupoDeServico: values.grupo,
        tipoDeServico: values.tipo,
        observacaoAbertura: `Canal de atendimento: ${values.canal_atendimento}${
          values.observacoes ? "; Obs: " + values.observacoes : ""
        }${
          values.horario_funcionamento.almoço
            ? "; Almoço: " + values.horario_funcionamento.almoço
            : ""
        }`,
        canalDeVenda: values.canal_atendimento,
        criadaPor: values.criado,
        terminalASerDesinstalado: {
          id: maquina.terminal_id,
          modelo: tradutor_tipo_equipamento(maquina.model),
          serial: maquina.serial_number,
          tipo: tradutor_tipo_equipamento(maquina.technology_type),
        },
        cliente: {
          stoneCode: props.stonecode,
          nomeFantasia: values.nome_fantasia,
          tipoDeDocumento: 2,
          numeroDoDocumento: values.documento,
          tipo: "Pessoa Juridica",
          grupoDoCliente: values.grupo_cliente,
        },
        contato: {
          nome: values.nome,
          telefone: values.telefone,
          celular: values.celular,
          email: values.email,
        },
        endereco: {
          cidade: values.cidade,
          bairro: values.bairro,
          cep: values.cep,
          numero: values.numero,
          logradouro: values.logradouro,
          tipoDoLogradouro: "Rua",
          estado: tradutor_estados_uf(values.estado),
          complemento: values.complemento,
          referencia: values.referencia,
          pais: "Brasil",
        },
        horarioDeFuncionamento: values.horario_funcionamento,
      };

      setLoadedOS(false);

      LogisticService.postAberturaOS(request).then((response) => {
        if (response.status == 200) {
          message.success(
            "OS aberta com sucesso" +
              `Os:${response.data.info.service_order_number} Vencimento:${response.data.info.limit_date}`
          );
          props.visible(false);
          form.resetFields();
        } else if (
          response.status === 408 ||
          response.status == 502 ||
          response.status == 503 ||
          response.status == 504
        ) {
          message.warning(
            "O tempo parece ter estourado pra essa requisição, verifique se a OS jã foi aberta e atualiza sua página"
          );
        } else {
          message.error(
            `Erro ao abrir OS da máquina ${maquina.serial_number}: ${response.data}`
          );
        }
        setLoadedOS(true);
      });
    });
  };

  const onChangeEnderecoField = () => {
    setDiasDisponiveis([]);
    setLoadedDatasAgendamento(null);
    form.resetFields(["agendamento"]);
  };

  const onChangeEndereco = (id) => {
    onChangeEnderecoField();
    setSelectedEndereco(opcoesEnderecos[id].name);
    form.setFieldsValue(enderecos[id]);
  };

  const selectTipoDoc = (
    <Select defaultValue={1} disabled>
      <Option value={1}>CPNJ</Option>
      <Option value={2}>CPF</Option>
    </Select>
  );

  const selectTipoLogradouro = (
    <Select defaultValue="Rua" disabled>
      <Option value="Rua">Rua</Option>
      <Option value="Avenida">Avenida</Option>
      <Option value="Travessa">Travessa</Option>
    </Select>
  );
  return loaded ? (
    <>
      <Form
        form={form}
        layout={"vertical"}
        onValuesChange={(_, allValues) => {
          form.setFieldsValue(allValues);
        }}
        onFinish={abrirOSs}
      >
        <Row horizontalAlignResponsive={"center"} margin={"0 8px"} wrap>
          <Box
            noFlex
            width={"47.5%"}
            maxWidth={"600px"}
            widthMediumScreenSize={"100%"}
            title={`Máquinas Selecionadas (${props.maquinasDesinstalacao.length})`}
          >
            <InfoTable
              data={maquinasDesinstalacaoTableData}
              pagination={{ defaultPageSize: 5, showSizeChanger: false }}
            />
          </Box>
          <Box
            noFlex
            width={"47.5%"}
            maxWidth={"600px"}
            widthMediumScreenSize={"100%"}
            title="Ordem de Serviço"
          >
            <Row nonResponsive>
              <FormItem
                label="Grupo"
                name={"grupo"}
                rules={[{ required: true }]}
                style={{ marginRight: "8px", width: "100%" }}
              >
                <Input disabled />
              </FormItem>
              <FormItem
                label="Tipo"
                name={"tipo"}
                rules={[{ required: true }]}
                style={{ marginLeft: "8px", width: "100%" }}
              >
                <Input disabled />
              </FormItem>
            </Row>
            <Row nonResponsive>
              <FormItem
                label="Canal de Atendimento"
                name={"canal_atendimento"}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Select placeholder="Selecione">
                  <Option value={"Telefone Stone"}>Telefone Stone</Option>
                  <Option value={"E-mail Stone"}>E-mail Stone</Option>
                  <Option value={"Whatsapp"}>Whatsapp</Option>
                  <Option value={"Discador"}>Discador</Option>
                </Select>
              </FormItem>
            </Row>
            <FormItem
              label="Criado por"
              name={"criado"}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </FormItem>
          </Box>
          <Box
            noFlex
            width={"47.5%"}
            maxWidth={"600px"}
            widthMediumScreenSize={"100%"}
            title="Cliente"
          >
            <FormItem
              label="Nome Fantasia"
              name={"nome_fantasia"}
              rules={[{ required: true }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Documento"
              name={"documento"}
              rules={[{ required: true }]}
            >
              <Input addonBefore={selectTipoDoc} />
            </FormItem>
          </Box>
          <Box
            noFlex
            width={"47.5%"}
            maxWidth={"600px"}
            widthMediumScreenSize={"100%"}
            title="Contato"
          >
            <FormItem label="Nome" name={"nome"}>
              <Input />
            </FormItem>
            <FormItem label="Telefone" name={"telefone"}>
              <Input maxLength={20} />
            </FormItem>
            <FormItem label="Celular" name={"celular"}>
              <Input maxLength={20} />
            </FormItem>
            <FormItem label="E-mail" name={"email"}>
              <Input />
            </FormItem>
          </Box>
          <Box
            noFlex
            width={"47.5%"}
            maxWidth={"600px"}
            widthMediumScreenSize={"100%"}
            title="Endereço"
          >
            {loadedEnderecos ? (
              <>
                <Row horizontalAlign="center" verticalAlign="center">
                  <Space>
                    <SelectBox
                      margin={"0 0 8px"}
                      options={opcoesEnderecos}
                      onChange={onChangeEndereco}
                      value={selectedEndereco}
                    />
                    <Tooltip title="Novo endereço" placement="top">
                      <PlusCircleOutlined
                        style={{
                          fontSize: 20,
                          marginBottom: "8px",
                          color: Colors.lightGreen,
                        }}
                        onClick={() => setVisibleNovoEndereco(true)}
                      />
                    </Tooltip>
                  </Space>
                </Row>
                <FormItem label="CEP" name={"cep"} rules={[{ required: true }]}>
                  <Input disabled onChange={() => onChangeEnderecoField()} />
                </FormItem>
                <FormItem
                  label="Logradouro"
                  name={"logradouro"}
                  rules={[{ required: true }]}
                >
                  <Input addonBefore={selectTipoLogradouro} disabled />
                </FormItem>
                <Row nonResponsive>
                  <FormItem
                    label="Número"
                    name={"numero"}
                    rules={[{ required: true }]}
                    style={{ width: "25%" }}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    label="Complemento"
                    name={"complemento"}
                    style={{ width: "100%", marginLeft: "16px" }}
                  >
                    <Input />
                  </FormItem>
                </Row>
                <Row nonResponsive>
                  <FormItem
                    label="Bairro"
                    name={"bairro"}
                    rules={[{ required: true }]}
                    style={{ marginRight: "8px", width: "100%" }}
                  >
                    <Input />
                  </FormItem>
                  <FormItem
                    label="Cidade"
                    name={"cidade"}
                    rules={[{ required: true }]}
                    style={{ marginLeft: "8px", width: "100%" }}
                  >
                    <Input onChange={() => onChangeEnderecoField()} />
                  </FormItem>
                </Row>
                <FormItem
                  label="Estado"
                  name={"estado"}
                  rules={[{ required: true }]}
                >
                  <Input onChange={() => onChangeEnderecoField()} />
                </FormItem>
                <FormItem label="Referência" name={"referencia"}>
                  <Input />
                </FormItem>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </Box>
          <Box
            noFlex
            width={"47.5%"}
            maxWidth={"600px"}
            widthMediumScreenSize={"100%"}
          >
            <Box noFlex title="Horário de Funcionamento">
              <WeekPicker
                replicavel
                setHorariosDeFuncionamento={setHorariosDeFuncionamento}
                changeAll={changeAll}
                defaultValues={{
                  almoço: { state: false, open: "12:00", closed: "13:00" },
                  segunda: { state: true, open: "10:00", closed: "17:00" },
                  terça: { state: true, open: "10:00", closed: "17:00" },
                  quarta: { state: true, open: "10:00", closed: "17:00" },
                  quinta: { state: true, open: "10:00", closed: "17:00" },
                  sexta: { state: true, open: "10:00", closed: "17:00" },
                  sábado: { state: false, open: "10:00", closed: "17:00" },
                  domingo: { state: false, open: "10:00", closed: "17:00" },
                }}
              />
              <FormItem hidden name={"horario_funcionamento"}>
                <Input />
              </FormItem>
            </Box>
            <Box noFlex title="Agendamento hunter" verticalAlign="center">
              <CustomButton
                onClick={carregarDatasAgendamento}
                disabled={!loadedEnderecos || loadedDatasAgendamento !== null}
              >
                {loadedDatasAgendamento === null || loadedDatasAgendamento ? (
                  <></>
                ) : (
                  <LoadingOutlined spin />
                )}
                Carregar datas
              </CustomButton>
              {loadedDatasAgendamento ? (
                diasDisponiveis.length > 0 ? (
                  <FormItem name={"agendamento"} style={{ width: "100%" }}>
                    <DatePicker
                      disabledDate={enableDate}
                      format={"YYYY-MM-DD"}
                      style={{ width: "100%" }}
                    />
                  </FormItem>
                ) : (
                  "Sem agendamento disponível pra esse local."
                )
              ) : (
                <></>
              )}
            </Box>
            <Box noFlex title="Observações">
              <FormItem name={"observacoes"}>
                <Input.TextArea />
              </FormItem>
            </Box>
          </Box>
        </Row>
        <Row horizontalAlign={"flex-end"} margin={"24px 0 0"} nonResponsive>
          <Space>
            <CustomButton htmlType="submit" disabled={!loadedOS}>
              {loadedOS ? <></> : <LoadingOutlined spin />}
              Abrir OS
            </CustomButton>
            <CustomLink danger onClick={() => props.visible(false)}>
              Cancelar
            </CustomLink>
          </Space>
        </Row>
      </Form>
      <CustomModal
        title={"Cadastrar Novo Endereço"}
        visible={visibleNovoEndereco}
        onCancel={() => setVisibleNovoEndereco(false)}
        zIndex={1011}
        footer={false}
      >
        <NovoEndereco
          stonecode={props.stonecode}
          cliente={nomeCliente}
          visible={setVisibleNovoEndereco}
          trocaEndereco={trocaEndereco}
        />
      </CustomModal>
    </>
  ) : (
    <LoadingSpinner />
  );
}
