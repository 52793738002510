import styled from "styled-components";


export const TopContainer = styled.div`
  height: 48px;
  width: 100%;
  background-color: white;
  position: absolute;
  z-index: 13;
  top: 0;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px -1px rgba(0, 0, 0, 0.04); 
`;

export const TopContent = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
`;

export const RightContent = styled.div`
  overflow: hidden;
  display: flex;
  gap: 8px;
`;

export const HiddenMenu = styled.div`
  position: absolute;
  right: 16px;
  top: 48px;
  padding: 4px 0;
  z-index: 9;
  display: ${(props) => (props.menuState ? "flex" : 'none')};;
  flex-direction: column;
  border-radius: 4px;
  background: var(--Content-On-Color-High, #FFF);
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.16);

  button {
    background-color: white !important;
    border-radius: 0 !important;
  }
`;
