import React, { useEffect, useState } from "react";
import CloseIcon from "@assets/icons/CloseIcon";
import SelectCustom from "@components/SelectCustom";
import PaperAirplane from "@assets/icons/PaperAirplane.svg";
import { FooterButtons } from "@components/FooterButtons";

import * as S from "./styles";

const SwapRoutes = ({
  step,
  setStep,
  pins,
  setPins,
  angelsDisponiveis,
  toggleMessage,
  recreateLegendThroughPins,
}) => {
  const [stepSwap, setStepSwap] = useState("swapAngelsStep");
  const [angels, setAngels] = useState([]);
  const [selectedAngel1, setSelectedAngel1] = useState(null);
  const [selectedAngel2, setSelectedAngel2] = useState(null);

  useEffect(
    () => setAngels(angelsDisponiveis.map(({ name }) => ({ id: name, name }))),
    [angelsDisponiveis]
  );

  const swapRoutes = (message) => {
    const angel1 = pins.find((item) => item.oss[0].angel === selectedAngel1);
    const angel2 = pins.find((item) => item.oss[0].angel === selectedAngel2);
    let newPins;

    if (!angel1 && !angel2) {
      setStepSwap("swapErrorStep");
      return;
    }
    if (!angel1 || !angel2) {
      const [selectedAngel1Aux, selectedAngel2Aux] = angel1
        ? [selectedAngel2, selectedAngel1]
        : [selectedAngel1, selectedAngel2];

      newPins = pins.map((item) => {
        if (item.oss[0].angel === selectedAngel2Aux) {
          return {
            ...item,
            oss: item.oss.map((os) => ({ ...os, angel: selectedAngel1Aux })),
          };
        }
        return item;
      });
    } else {
      newPins = pins.map((item) => {
        if (item.oss[0].angel === selectedAngel1) {
          return {
            ...item,
            oss: item.oss.map((os) => ({ ...os, angel: selectedAngel2 })),
            icon: angel2.icon,
          };
        }
        if (item.oss[0].angel === selectedAngel2) {
          return {
            ...item,
            oss: item.oss.map((os) => ({ ...os, angel: selectedAngel1 })),
            icon: angel1.icon,
          };
        }
        return item;
      });
    }

    setStep("initialStep");
    toggleMessage(message);
    recreateLegendThroughPins(newPins);
    setPins(newPins);
  };

  return (
    <>
      {step === "swapAngelsStep" && stepSwap === "swapAngelsStep" && (
        <S.RelocateCard>
          <S.TitleSimulationCard>
            Trocar rotas
            <CloseIcon
              size={20}
              style={{ cursor: "pointer" }}
              onClick={() => setStep("initialStep")}
            />
          </S.TitleSimulationCard>
          <S.SubtitleSimulationCard>
            Selecione os Angels para troca de rotas.
          </S.SubtitleSimulationCard>

          <S.ContentSelect>
            <SelectCustom
              title="Angel 1"
              placeholder="Selecione uma opção"
              options={
                selectedAngel2
                  ? angels.filter((item) => item.id !== selectedAngel2)
                  : angels
              }
              value={selectedAngel1}
              onChange={setSelectedAngel1}
            />
          </S.ContentSelect>
          <S.ContentSelect>
            <SelectCustom
              title="Angel 2"
              placeholder="Selecione uma opção"
              options={
                selectedAngel1
                  ? angels.filter((item) => item.id !== selectedAngel1)
                  : angels
              }
              value={selectedAngel2}
              onChange={setSelectedAngel2}
            />
          </S.ContentSelect>

          <FooterButtons
            nameFirstButton={"Trocar"}
            customWidth={"100%"}
            nameSecondButton={"Fechar"}
            onClickFirstButton={() => {
              swapRoutes("Rotas trocadas com sucesso!");
            }}
            onClickSecondButton={() => setStep("initialStep")}
            firstbuttonDisabled={!(selectedAngel1 && selectedAngel2)}
          />
        </S.RelocateCard>
      )}

      {stepSwap === "swapErrorStep" && (
        <S.RelocateCard>
          <S.TitleSimulationCard>
            Realocar OSs
            <CloseIcon size={20} style={{ cursor: "pointer" }} />
          </S.TitleSimulationCard>
          <S.SubtitleSimulationCard></S.SubtitleSimulationCard>

          <S.ContentImage>
            <img src={PaperAirplane} />
          </S.ContentImage>
          <S.ErrorText>
            Encontramos falhas ao trocar rotas, ambos angels não possuem OS.
          </S.ErrorText>
          <FooterButtons
            nameFirstButton={"Tentar novamente"}
            customWidth={"100%"}
            onClickFirstButton={() => setStepSwap("swapAngelsStep")}
          />
        </S.RelocateCard>
      )}
    </>
  );
};

export default SwapRoutes;
