import React from "react";
import { Link } from "react-router-dom";
import { PageLayout } from "../../../Layout/PageLayout";
import Page from "@components/Page";
import Row from "@components/Row";
import Box from "@components/Box";
import SubmitButton from "@components/SubmitButton";
import Container from "@components/Container";
import { Title } from "./styles";

const CasosLiga = () => {
  const renderCard = (title, textButton, path) => {
    return (
      <Container grid={1 / 3} padding={"32px 16px"} rounded>
        <Row horizontalAlign={"center"} verticalAlign={"center"}>
          <Title>{title}</Title>
        </Row>
        <Row horizontalAlign={"center"}>
          <Link to={{ pathname: path }}>
            <SubmitButton>{textButton}</SubmitButton>
          </Link>
        </Row>
      </Container>
    );
  };

  return (
    <PageLayout>
      <Box title="Casos da Liga">
        <Row horizontalAlign={"center"}>
          {renderCard(
            "CASOS SALEFORCE",
            "Vamos Nessa?",
            "/ferramentas/classificacao-casos"
          )}
          {renderCard(
            "MAPEAMENTO ON",
            "Bora Entrar em Campo?",
            "/ferramentas/mapeamento-on"
          )}
        </Row>
        {/* <Row horizontalAlign={"center"}>
          {renderCard("INPUT DE CASOS SALEFORCE", "Precisa gerenciar?", "/ferramentas/input-liga")}
          {renderCard("INPUT PARA MAPEAMANETO ON", "Precisa gerenciar?", "/ferramentas/input-mapeamento-on")}
        </Row> */}
      </Box>
    </PageLayout>
  );
};

export default CasosLiga;
