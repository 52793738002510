import React, { useEffect, useState } from "react";
import { Polygon } from "@react-google-maps/api";
import LogisticService from "../../../../../consumingApi/services/LogisticService";
import { useSelector } from "react-redux";
import { hubsSelector } from "../../../../../features/senninha/hubsSlice";


const AreaDeRisco = ({
    map,
}) => {
    const [poligonoAreaDeRisco, setPoligonoAreaDeRisco] = useState([]);

    const {
        selectedDistrictInfos
    } = useSelector(hubsSelector);

    useEffect(() => {
        LogisticService.getAreasRisco(selectedDistrictInfos.value).then((result) => {
            setPoligonoAreaDeRisco(result.data);
        })
    }, [])

    return (
        <>
            {poligonoAreaDeRisco && poligonoAreaDeRisco.map((coords, idx) => {
                return (
                    <Polygon
                        key={idx}
                        paths={coords["bounds"]}
                        options={{
                            fillColor: "#D21404",
                            strokeColor: "#D21404",
                            fillOpacity: 0.4,
                            strokeWeight: 2,
                        }}
                        map={map}
                    />
                )
            })
            }
        </>
    );
};

export default AreaDeRisco;
