import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';

import LogisticService from '@LogisticService';

const REDUCER_NAME = 'hubsSenninha';

const initialState = {
    oldSenninhaWorkFlow: '',
    hunterPinClicked: {}
};


export const oldSenninhaSlice = createSlice({
    name: REDUCER_NAME,
    initialState,
    reducers: {
        setOldSenninhaWorkFlow: (state, action) => {
            state.oldSenninhaWorkFlow = action.payload;
        },
        setHunterPinClicked: (state, action) => {
            state.hunterPinClicked = action.payload
        }
    },
});

export const {
    setOldSenninhaWorkFlow, setHunterPinClicked
} = oldSenninhaSlice.actions;
export const oldSenninhaSelector = (state) => state.oldSenninhaSlice;

export default oldSenninhaSlice.reducer;
