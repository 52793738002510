import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import Endereco from "./Endereco";

export default function Enderecos(props) {
  const loading = <LoadingOutlined spin />;
  const [enderecos, setEnderecos] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    LogisticService.getEnderecoCliente(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        setEnderecos(response.data);
        setLoaded(true);
      }
    });
  }, [props.stonecode]);
  return (
    <Container
      title={`Endereços do Cliente`}
      icon={"info-circle"}
      grid={1}
      expandable
    >
      {loaded ? (
        <Row margin={"0 8px 8px"} horizontalScroll>
          {enderecos.map((info, index) => {
            return <Endereco info={info} index={index + 1} />;
          })}
        </Row>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
