import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'senninhaAngels';
const GET_ANGELS = 'senninhaAngels/getAngels';

const initialState = {
  angels: [],
  status: 'idle',
  error: null,
};

export const getAngels = createAsyncThunk(GET_ANGELS, async (polo, { rejectWithValue }) => {
  try {
    const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/angels_disponiveis/${polo}`);

    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data || err.response);
  }
});

export const angelsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    resetAngelsSlice: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAngels.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(getAngels.fulfilled, (state, action) => {
        state.angels = action.payload;
        state.status = 'fulfilled';
      })
      .addCase(getAngels.rejected, (state, action) => {
        state.angels = [];
        state.error = action.payload;
        state.status = 'rejected';
      });
  },
});

export const {
  resetAngelsSlice
} = angelsSlice.actions

export const angelsSelector = (state) => state.angelsSlice;

export default angelsSlice.reducer;
