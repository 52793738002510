import React, { useState, useEffect } from "react";
import { DrawerCard } from "@components/DrawerCard";
import { useForm } from "react-hook-form";
import { schemaDOA } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { InitialStep } from "./componentes/InitialStep";
import { EvidenceStep } from "./componentes/EvidenceStep";
import { ConfirmStep } from "./componentes/ConfirmStep";
import { FormProvider } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import LogisticService from "@LogisticService";
import { useHistory } from "react-router-dom";
import useRouter from "../../../../../../hooks/useRouter";

import * as S from "./styles";
// import { useAuthContext } from '../../../../../../stonelog-auth-components';
import { useAuthContext } from "@stonelog/stonelog-auth-components";

export const ReportDOA = (props) => {
  const [selectedDefects, setSelectedDefects] = useState([]);
  const [serialImage, setSerialImage] = useState([]);
  const [isSuccess, setIsSuccess] = useState("");
  const [step, setStep] = useState("initialStep");
  const [menuDefects, setMenuDefects] = useState([]);
  const [defectsList, setDefectsList] = useState([]);
  const [loadingPost, setLoadingPost] = useState(false);
  const auth = useAuthContext();
  const userName = auth.getUser();

  const { location } = useRouter();
  const history = useHistory();

  const methods = useForm({
    resolver: yupResolver(schemaDOA),
    mode: "onBlur",
    defaultValues: {
      email_angel: userName.email,
      serial: "",
    },
  });
  const { handleSubmit, watch, errors, reset, resetField } = methods;

  useEffect(() => {
    LogisticService.getDefeitosDoa().then((response) => {
      const list = [];
      let tempDefectList = [];
      response.data.data.map((defects) => {
        let formattedDefects = {
          title: Object.keys(defects)[0],
          defectsList: Object.values(defects)[0],
        };
        list.push(formattedDefects);
        tempDefectList = tempDefectList.concat(formattedDefects.defectsList);
      });
      setDefectsList(tempDefectList);
      setMenuDefects(list);
    });
  }, []);

  const getDefectIdByName = (nameDefect) => {
    const defect = defectsList.find((defectFind) => {
      return defectFind.nome === nameDefect;
    });
    if (defect !== undefined) {
      return defect.id;
    }
    return console.error(`error defect not found ${nameDefect}`);
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      var index = selectedDefects.findIndex((x) => x.name === e.target.value);
      if (index === -1) {
        selectedDefects.push({
          defectName: e.target.value,
          defectImages: [],
        });
        setSelectedDefects([...selectedDefects]);
      }
    } else {
      var indexDefect = selectedDefects.findIndex(
        (x) => x.name === e.target.value
      );
      selectedDefects.splice(indexDefect, 1);
      setSelectedDefects([...selectedDefects]);
    }
  };

  const handleDeleteUploadContainer = (defect) => {
    if (selectedDefects.length > 1) {
      const index = selectedDefects.indexOf(defect);
      selectedDefects.splice(index, 1);
      setSelectedDefects([...selectedDefects]);
    } else {
      setSerialImage([]);
      setSelectedDefects([]);
      setStep("initialStep");
    }
  };

  const handleCloseAndCleanDrawer = () => {
    setStep("initialStep");
    props.setDrawerDoaVisible(false);
    setSerialImage([]);
    setSelectedDefects([]);
    resetField("serial");
    resetField("email_angel");

    const lastPath = location.pathname.split("/").pop();
    if (lastPath === "") history.push(location.pathname.slice(0, -1));
    else if (lastPath === "report-doa-terceira")
      history.push(location.pathname.split("/").slice(0, -1).join("/"));
  };

  const handleBackAndCleanDrawer = (clearImage = true) => {
    setStep("initialStep");
    if (clearImage) setSerialImage([]);
    setSelectedDefects([]);
  };

  const handleNewReport = () => {
    setStep("initialStep");
    resetField("serial");
    setSerialImage([]);
    setSelectedDefects([]);
  };

  const onKeyDown = (e) => {
    //previne o comportamento do enter
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const postForm = async (data) => {
    if (data) {
      setLoadingPost(true);
      return LogisticService.postRegistroDoa(data)
        .then((response) => {
          if (response) {
            setLoadingPost(false);
            return response;
          }
        })
        .catch((response) => {
          setLoadingPost(false);
          return response;
        });
    }
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const formattedPayloadImages = async (payloadImage) => {
    console.log(payloadImage);
    const imagem_uuid = uuidv4();
    const [file_name, file_extension] = payloadImage.name.split(".");
    const base64_hash = await convertBase64(payloadImage.originFileObj);
    return {
      hash_name: `${imagem_uuid}.${file_extension}`,
      file_name: imagem_uuid,
      file_extension: file_extension,
      content_type: payloadImage.type,
      base64_hash: base64_hash.split(",")[1],
    };
  };

  const onSubmit = async (data) => {
    const defeitosList = [];
    selectedDefects.map(async (defect) => {
      defeitosList.push({
        id_defeito: getDefectIdByName(defect.defectName),
        hash_foto_1: await formattedPayloadImages(defect.defectImages[0]),
        hash_foto_2:
          defect.defectImages.length === 2
            ? await formattedPayloadImages(defect.defectImages[1])
            : {},
      });
    });
    const formObj = {
      serial: data.serial,
      email_angel: data.email_angel,
      hash_serial: await formattedPayloadImages(serialImage[0]),
      prestador: props.polo,
      defeitos: defeitosList,
    };

    const response = await postForm(formObj);
    if (response.status === 200) {
      setStep("confirmStep");
      setIsSuccess(true);
    } else {
      setStep("confirmStep");
      setIsSuccess(false);
    }
  };

  const Header = () => (
    <>
      Reportar DOA
      {step !== "confirmStep" && (
        <S.SubtitleText>
          Todos os campos abaixo são obrigatórios, incluindo os anexos.
        </S.SubtitleText>
      )}
    </>
  );

  return (
    <>
      {props.drawerDoaVisible && (
        <DrawerCard
          title={<Header />}
          visible
          onClose={handleCloseAndCleanDrawer}
          placement={"right"}
          setIsVisible={props.setDrawerDoaVisible}
          bodyStyle={{ padding: 0 }}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {step === "initialStep" && (
                <InitialStep
                  polo={props.polo}
                  menuDefects={menuDefects}
                  serialImage={serialImage}
                  setSerialImage={setSerialImage}
                  selectedDefects={selectedDefects}
                  onChangeCheckbox={onChangeCheckbox}
                  setStep={setStep}
                  handleCloseAndCleanDrawer={handleCloseAndCleanDrawer}
                  onKeyDown={onKeyDown}
                />
              )}
              {step === "evidenceStep" && (
                <EvidenceStep
                  setStep={setStep}
                  handleDeleteUploadContainer={handleDeleteUploadContainer}
                  handleSubmit={handleSubmit(onSubmit)}
                  handleBackAndCleanDrawer={handleBackAndCleanDrawer}
                  onKeyDown={onKeyDown}
                  serialImage={serialImage}
                  setSerialImage={setSerialImage}
                  selectedDefects={selectedDefects}
                  setSelectedDefects={setSelectedDefects}
                  loading={loadingPost}
                />
              )}
              {step === "confirmStep" && (
                <ConfirmStep
                  isSuccess={isSuccess}
                  handleNewReport={handleNewReport}
                  handleCloseAndCleanDrawer={handleCloseAndCleanDrawer}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              )}
            </form>
          </FormProvider>
        </DrawerCard>
      )}
    </>
  );
};
