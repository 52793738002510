import React from "react";
import * as S from "./styles";

export const ConfirmModal = (props) => {
  return (
    <S.StyledModal
      title={props.title}
      visible={props.visible}
      zIndex={props.zIndex}
      onCancel={props.onCancel}
      closable={props.onCancel ? true : false}
      width={props.width}
      maxWidth={props.maxWidth}
      footer={props.footer}
      onOk={props.onOk}
      mask={props.mask}
      hideFooter={props.hideFooter}
    >
      {props.children}
    </S.StyledModal>
  );
};
