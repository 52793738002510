import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import BarChart from "@components/BarChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { getOneYearAgo } from "@helper";
import Colors from "@colors";

export default function ShareDiasAtendimento(props) {
  const title = "Share Dias de Atendimento";
  const [shareDiasAtendimento, setShareDiasAtendimento] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getShareDiasAtendimento().then((response) => {
      if (response && response.data != null) {
        setShareDiasAtendimento(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const zero = [];
  const um = [];
  const dois = [];
  const tresOuMais = [];
  const downloadLabels = ["Período", "0", "1", "2", "3 ou +"];
  const downloadData = [];

  shareDiasAtendimento &&
    shareDiasAtendimento.map(
      (item) => (
        periodos.push(item.periodo),
        zero.push((item.zero * 100).toFixed(1)),
        um.push((item.one * 100).toFixed(1)),
        dois.push((item.two * 100).toFixed(1)),
        tresOuMais.push((item.three_or_more * 100).toFixed(1)),
        downloadData.push([
          `${item.periodo}`,
          `${(item.zero * 100).toFixed(3)}%`,
          `${(item.one * 100).toFixed(3)}%`,
          `${(item.two * 100).toFixed(3)}%`,
          `${(item.three_or_more * 100).toFixed(3)}%`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    datalabelsCenter: true,
    datalabelsPercentage: true,
    zoom: true,
    labels: periodos,
    color: "white",
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${
            tooltipItem.yLabel
          }%`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            max: 100,
            callback: function (value) {
              return value + "%";
            },
          },
          stacked: true,
          gridLines: false,
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
            min: getOneYearAgo(),
          },
          stacked: true,
          gridLines: false,
        },
      ],
    },
    data: [
      { label: "0", color: Colors.gray, fill: false, value: zero },
      { label: "1", color: Colors.lightGreen, fill: false, value: um },
      { label: "2", color: Colors.green, fill: false, value: dois },
      {
        label: "3 ou +",
        color: Colors.darkGreen,
        fill: false,
        value: tresOuMais,
      },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      grid={1 / 2}
      title={title}
      info={[
        ["1 - Considera as mesmas premissas do indicador de TMA;"],
        [
          "2 - Considera os chamados dos Operadores Logísticos Stone Pagamentos, Stone Franquia e Paytec;",
        ],
      ]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <BarChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
