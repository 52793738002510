import Container from "@components/Container";
import { Descriptions, message, Tooltip } from "antd";
import React from "react";
import { CopyLine } from "../styles";

function copiaNoClick(texto, title) {
  navigator.clipboard.writeText(texto);
  message.info(`${title} copiado com sucesso!`);
}

const Contato = (props) => {
  return (
    <Container minWidth={"400px"}>
      <Descriptions bordered column={1} size={"small"}>
        <Descriptions.Item label={"Descrição"}>
          {props.dados.typeName}
        </Descriptions.Item>
        <Descriptions.Item label={"Nome"}>{props.dados.name}</Descriptions.Item>
        <Descriptions.Item label={"CPF"}>
          <Tooltip title="Copiar" placement="right">
            <CopyLine onClick={() => copiaNoClick(props.dados.cpf, "CPF")}>
              {props.dados.cpf}
            </CopyLine>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label={"E-mail"}>
          <Tooltip title="Copiar" placement="right">
            <CopyLine onClick={() => copiaNoClick(props.dados.email, "E-mail")}>
              {props.dados.email}
            </CopyLine>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label={"Telefone"}>
          <Tooltip title="Copiar" placement="right">
            <CopyLine
              onClick={() => copiaNoClick(props.dados.phone, "Telefone")}
            >
              {props.dados.phone}
            </CopyLine>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label={"Celular"}>
          <Tooltip title="Copiar" placement="right">
            <CopyLine
              onClick={() => copiaNoClick(props.dados.mobilePhone, "Celular")}
            >
              {props.dados.mobilePhone}
            </CopyLine>
          </Tooltip>
        </Descriptions.Item>
      </Descriptions>
    </Container>
  );
};
export default Contato;
