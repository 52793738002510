import React from "react";

import { Select } from "antd";

const SelectBox = (props) => {
  const { Option } = Select;

  const onBlur = () => {};

  const onFocus = () => {};

  const onSearch = (val) => {};

  return (
    <Select
      allowClear={props.allowClear}
      value={props.value}
      disabled={props.disabled ? true : false}
      hidden={props.hidden ? true : false}
      showSearch
      style={{
        width: props.width || 200,
        margin: props.margin ? props.margin : "0 8px",
      }}
      placeholder={props.placeholder}
      optionFilterProp="children"
      onChange={props.onChange}
      defaultValue={
        props.defaultValue !== undefined ? props.defaultValue : null
      }
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      mode={props.multiple ? "multiple" : null}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {!props.options
        ? []
        : props.options.map((content, index) => (
            <Option
              key={props.valueIsName ? index : content.id}
              value={
                props.valueIsName
                  ? content.name
                  : props.customValue
                  ? content.value
                  : content.id
              }
            >
              {content.name}
            </Option>
          ))}
    </Select>
  );
};

export default SelectBox;
