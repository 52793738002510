import React from "react";
import { PageLayout } from "../../../Layout/PageLayout";
import useRouter from "../../../hooks/useRouter";
import PageHeader from "@components/PageHeader";
import clock from "@assets/icons/clock.svg";
import cardMachine from "@assets/icons/cardMachine.svg";
import starBox from "@assets/icons/starBox.svg";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

export default function GestaoTerceira() {
  const { location } = useRouter();
  const history = useHistory();

  const cardsInfo = [
    {
      icon: clock,
      title: "Motivos de atraso",
      text:
        "Aqui você registra os motivos de atrasos que ocorreram ao longo do dia na operação.",
      buttonText: "Acessar",
      onClick: () => history.push(`${location.pathname}/motivos-atraso`),
    },
    {
      icon: cardMachine,
      title: "Report DOA",
      text: "Aqui você consegue reportar máquinas que possuem algum defeito.",
      buttonText: "Reportar",
      onClick: () => history.push(`${location.pathname}/report-doa-terceira`),
    },
    {
      icon: starBox,
      title: "Cobertura de estoque",
      text:
        "Aqui você tem visibilidade de estoque dos polos e previsões de recebimento.",
      buttonText: "Acessar",
      onClick: () => history.push(`${location.pathname}/cobertura-estoque`),
    },
  ];

  return (
    <>
      <PageLayout>
        <div>
          <PageHeader title="Gestão terceira" />
          <S.Container>
            {cardsInfo.map((card, index) => (
              <S.Card key={index}>
                <div>
                  <S.Icon src={card.icon} />
                  <S.TitleText>{card.title}</S.TitleText>
                  <S.Text>{card.text}</S.Text>
                </div>
                <S.AccessButton onClick={card.onClick}>
                  {card.buttonText}
                </S.AccessButton>
              </S.Card>
            ))}
          </S.Container>
        </div>
      </PageLayout>
    </>
  );
}
