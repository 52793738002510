import styled from "styled-components";
import { Button, Upload } from "antd";
import defaultTheme from "@defaultTheme";

export const FloatLabelContent = styled.div`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "1.4rem"};
  width: 100%;
  position: relative;

  .ant-input-affix-wrapper > input.ant-input {
    padding: 11px 3px !important;
  }

  .ant-input-affix-wrapper {
    border: 1px solid #a6aeba;
    box-shadow: 0 0 0 2px white;
  }

  .custom-select {
    width: 100%;
    position: relative;
  }

  .select-float-label {
    position: absolute;
    top: -9px;
    left: 10px;
    z-index: 1000;
    background-color: white;
    padding: 0 4px;
    color: #a6aeba;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.01em;
  }

  .select-float-label-error {
    color: #d95e00;
  }
`;

export const LableContent = styled.label`
  position: absolute;
  top: -9px;
  left: 10px;
  z-index: 1000;
  background-color: white;
  padding: 0 4px;
  color: #a6aeba;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.01em;
`;
