import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { gradientOsColors } from "./../../helper.js";

export const Title = styled.div`
  color: #fff;
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.m};
  line-height: 145%;
  letter-spacing: -0.01em;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${defaultTheme.fontSize.xs};
  color: #fff;
  margin-top: 0.5rem;
  line-height: 0.9rem;

  &:last-child {
    align-items: flex-start;
  }

  & > span {
    font-weight: 600;
  }
`;

export const OSsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & div {
    display: flex;
    align-items: center;
    line-height: 0.9rem;
    margin-top: 0.5rem;

    &:first-child {
      margin: 0;
    }
  }
`;

export const ColorBadge = styled.div`
  width: 0.75rem;
  height: 0.5rem;
  background: ${(props) => gradientOsColors[props.type]};
  border-radius: 100px;
  margin-right: 0.25rem !important;
`;
