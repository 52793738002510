export const gradientOsColors = {
  INSTALAÇÃO: "linear-gradient(90deg, #4FC879 0%, #A3E2B9 100%)",
  TROCA: "linear-gradient(90deg, #54A3FD 0%, #A0CCFE 100%)",
  DESINSTALAÇÃO: "linear-gradient(90deg, #FD6B65 0%, #FEA3A0 100%)",
  TON: "linear-gradient(90deg, #7EE899 0%, #B3F2C3 100%)",
  MANUTENÇÃO: "linear-gradient(90deg, #767F8D 0%, #ABB1BA 98.44%)",
  "ENVIO DE SUPRIMENTOS":
    "linear-gradient(90deg, #FDA954 1.39%, #FECC99 97.22%)",
  EVENTO: "linear-gradient(90deg, #B898FD 0%, #D3BEFE 100%)",
};

export const formatedOsTypesName = {
  INSTALAÇÃO: "Instalação",
  TROCA: "Troca",
  DESINSTALAÇÃO: "Desinstalção",
  TON: "Ton",
  MANUTENÇÃO: "Manutenção",
  "ENVIO DE SUPRIMENTOS": "Suprimento",
  EVENTO: "Evento",
};

export const angelsColors = [
  "#1B4B36",
  "#FA7E0A",
  "#44679F",
  "#71397C",
  "#2B2726",
  "#B30753",
  "#A59354",
  "#8F0E0E",
  "#724330",
  "#538F6A",
  "#B0B8B4",
  "#A1583E",
  "#ECAB69",
  "#A64942",
  "#018790",
  "#B2AB24",
  "#75597D",
  "#FF0592",
  "#FF0592",
  "#D19178",
  "#E36161",
  "#6FA5B1",
  "#F6C667",
  "#9B77DA",
  "#FC5BB6",
  "#87E5DA",
  "#FFDF5A",
  "#C582D5",
  "#FFBEE3",
  "#F4ADAD",
];
