import styled from "styled-components";
import senninhaBackground from "@assets/imgs/senninhaBackground.png";

export const HubContainerBackground = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${senninhaBackground});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const HubSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 24px;
  left: 32px;
  border-radius: 12px;
  border: 1px solid rgba(48, 55, 66, 0.10);
  background: #FFF;
  width: 328.5px; 

  p {
    color: var(--Content-Neutral-High, #303742);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 
    letter-spacing: -0.34px;
  }
  span {
    color: var(--Content-Neutral, #767F8D);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.13px;
  }
`;

export const HubSelectionHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid rgba(48, 55, 66, 0.10);
`;

export const HubSelectionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0px 16px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector span {
    margin-top: -2px !important;
  }
`;

export const HubSelectionFooter = styled.div`
  padding: 0px 16px 16px 16px;
`;
