import React, { useState, useEffect } from "react";
import { useJsonToCsv } from "react-json-csv";

import DownloadIcon from "@assets/icons/DownloadIcon";
import SearchIcon from "@assets/icons/searchIcon";
import Empty from "@assets/icons/emptyBookImage.svg";
import LogisticService from "@LogisticService";
import Input from "@components/InputThird";
import { usePaginationConfig } from "../../../hooks/usePaginationConfig";

import {
  Container,
  DownloadButton,
  EmptyContainer,
  SearchButton,
  SkeletonBox,
} from "../GestaoTerceira/MotivosAtraso/AtrasoParceiro/styles";

import { Table as CustomTable } from "antd";

const Table = (props) => {
  const [angels, setAngels] = useState([]);
  const [filteredAngels, setFilteredAngels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputText, setInputText] = useState("");

  const lastUpdate = new Date();
  const { saveAsCsv } = useJsonToCsv();
  const paginationConfig = usePaginationConfig();

  const CustomSkeletonBox = () => (
    <SkeletonBox active={isLoading} size={"small"} height="24px" width="80px" />
  );

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: '10%',
    //   sorter: (a, b) => a.id - b.id,
    //   render: (text) => isLoading ? <CustomSkeletonBox /> : text
    // },
    {
      title: "ID Workfinity",
      dataIndex: "id_wkf",
      key: "id_wkf",
      width: "10%",
      sorter: (a, b) => a.id_wkf - b.id_wkf,
      render: (text) =>
        isLoading ? <CustomSkeletonBox /> : <span>{text}</span>,
    },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      width: "20%",
      sorter: (a, b) => a.nome - b.nome,
      render: (text) => (isLoading ? <CustomSkeletonBox /> : text),
    },
    {
      title: "Ativo",
      dataIndex: "ativo",
      key: "ativo",
      width: "10%",
      sorter: (a, b) => a.ativo - b.ativo,
      render: (text) => (isLoading ? <CustomSkeletonBox /> : text),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
      sorter: (a, b) => a.email - b.email,
      render: (text) => (isLoading ? <CustomSkeletonBox /> : text),
    },
    {
      title: "Usuário Workfinity",
      dataIndex: "wkfusername",
      key: "wkfusername",
      width: "20.19%",
      sorter: (a, b) => a.wkfusername - b.wkfusername,
      render: (text) => (isLoading ? <CustomSkeletonBox /> : text),
    },
    {
      title: "CPF",
      dataIndex: "cpf",
      key: "cpf",
      width: "20.19%",
      sorter: (a, b) => a.cpf - b.cpf,
      render: (text) => (isLoading ? <CustomSkeletonBox /> : text),
    },
    // {
    //   title: 'Polos',
    //   dataIndex: 'polos',
    //   key: 'polos',
    //   width: '20%',
    //   sorter: (a, b) => a.polos - b.polos,
    //   render: (text) => isLoading ? <CustomSkeletonBox /> : text
    // }
  ];

  const filterData = () => {
    console.log(angels);
    if (!inputText) {
      setFilteredAngels(angels);
    } else {
      setFilteredAngels(
        angels.filter(
          (item) =>
            item.email.indexOf(inputText) > -1 ||
            item.id_wkf.toString().indexOf(inputText) > -1
        )
      );
    }
  };

  const handleDownload = () => {
    const downloadData = angels.map((item) => ({
      id_wkf: item.id_wkf,
      nome: item.nome,
      email: item.email,
      wkfusername: item.wkfusername,
      cpf: item.cpf,
    }));

    saveAsCsv({
      fields: {
        id_wkf: "id_wkf",
        nome: "nome",
        email: "email",
        wkfusername: "wkfusername",
        cpf: "cpf",
      },
      data: downloadData,
      filename: `angels-green-app`,
    });
  };

  useEffect(() => {
    LogisticService.getAngelsGreenApp().then((response) => {
      if (response) {
        const data = response.data.map((angel, index) => ({
          ...angel,
          key: index,
          polo: "",
        }));
        setAngels(data);
        setFilteredAngels(data);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <Container>
      <header>
        <div>
          <h3>Green Angels</h3>
          {lastUpdate && (
            <p>{`Atualizado em ${lastUpdate.getDate()}/ ${lastUpdate.getMonth()}/ ${lastUpdate.getFullYear()}`}</p>
          )}
        </div>
        <div>
          <Input
            label="Buscar Email ou ID Workfinity"
            placeholder="Digite aqui"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && filterData()}
          />
          <SearchButton
            icon={<SearchIcon size={18} color={"#000"} />}
            onClick={filterData}
          />
          <DownloadButton
            onClick={handleDownload}
            icon={<DownloadIcon size={18} />}
          >
            Baixar
          </DownloadButton>
        </div>
      </header>

      <CustomTable
        className="ginga-table"
        columns={columns}
        dataSource={filteredAngels}
        pagination={paginationConfig}
        locale={{
          emptyText: (
            <EmptyContainer>
              <img src={Empty} alt="" />
              <span>Tabela vazia</span>
            </EmptyContainer>
          ),
        }}
      />
    </Container>
  );
};

export default Table;
