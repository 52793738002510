import { Line } from "@reactchartjs/react-chart.js";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-zoom";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import { intToReal } from "@helper";

const LineChart = (props) => {
  const [zoomEnable, setZoomEnable] = useState(false);

  const data = {
    labels: props.data.labels,
    url: props.data.url,
    type: JSON,
    datasets: props.data.data.map((content) => ({
      lineTension: 0,
      label: content.label,
      hidden: content.hidden ? true : false,
      fill: content.fill ? true : false,
      borderColor: content.borderColor ? content.borderColor : content.color,
      backgroundColor: content.color,
      borderWidth: 1.5,
      pointRadius: 2,
      yAxesID: content.yAxesID ? content.yAxesID : "",
      data: content.value,
      datalabels: {
        labels: {
          title: content.hideDatalabels ? null : {},
        },
        display: props.data.datalabels || false,
        anchor: "end",
        align: "end",
        font: {
          size: 11,
        },
        color: "black",
        formatter: (value) => {
          if (content.normalDatalabel) {
            return value;
          } else if (props.data.datalabelsPercentage) {
            return value + "%";
          } else if (props.data.datalabelsCurrency) {
            return intToReal.format(value);
          }
          return value;
        },
      },
    })),
  };

  const options = {
    legend: {
      position: "bottom",
      display: props.data.data.length > 1 ? true : false,
    },
    maintainAspectRatio: false,
    tooltips: props.data.tooltips ? props.data.tooltips : {},
    title: {
      display: false,
      fontColor: "black",
      fontSize: 20,
      text: props.data.title || "",
    },
    scales: props.data.scales ? props.data.scales : {},
    plugins: {
      zoom: {
        zoom: {
          enabled: zoomEnable,
          mode: "x",
        },
      },
    },
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "Control") {
        setZoomEnable(true);
      }
    });
    document.addEventListener("keyup", function (event) {
      if (event.key === "Control") {
        setZoomEnable(false);
      }
    });
  }, []);

  return (
    <Box height={400}>
      <Line data={data} options={options} />
    </Box>
  );
};

export default LineChart;
