import styled from "styled-components";


export const AllSearchDrawerContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const TitleFilterDrawer = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(48, 55, 66, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${(props) =>
    props.firstTitleFilter ? "37.3px 0px 16px 0px" : "0px 0px 16px 0px"};
`;

export const BodyContentNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.415px;
  padding: 16px 24px;
  margin: 5.585px;
`;
