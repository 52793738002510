import { GenIcon } from "react-icons";

function WarningIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M13.235 16.9654C13.235 17.642 12.6866 18.1904 12.01 18.1904H11.9872C11.3106 18.1904 10.7622 17.642 10.7622 16.9654C10.7622 16.2889 11.3106 15.7404 11.9872 15.7404H12.01C12.6866 15.7404 13.235 16.2889 13.235 16.9654Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M10.9972 12.9653C10.9972 13.5176 11.4449 13.9653 11.9972 13.9653C12.5495 13.9653 12.9972 13.5176 12.9972 12.9653V8.96533C12.9972 8.41305 12.5495 7.96533 11.9972 7.96533C11.4449 7.96533 10.9972 8.41305 10.9972 8.96533V12.9653Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M9.40198 4.5C10.5567 2.5 13.4434 2.5 14.5981 4.5L21.5263 16.5C22.681 18.5 21.2377 21 18.9283 21H5.07186C2.76246 21 1.31908 18.5 2.47378 16.5L9.40198 4.5ZM12.8661 5.5C12.4812 4.83333 11.5189 4.83333 11.134 5.5L4.20583 17.5C3.82093 18.1667 4.30206 19 5.07186 19H18.9283C19.6981 19 20.1792 18.1667 19.7943 17.5L12.8661 5.5Z",
        },
      },
    ],
  })(props);
}

export default WarningIcon;
