import { GenIcon } from "react-icons";

function CloseIconFilled(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 20 20",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M9.99984 1.66663C7.7897 1.66663 5.67008 2.5446 4.10728 4.1074C2.54448 5.67021 1.6665 7.78982 1.6665 9.99996C1.6665 11.0943 1.88205 12.1779 2.30084 13.189C2.71963 14.2 3.33346 15.1187 4.10728 15.8925C4.8811 16.6663 5.79976 17.2802 6.81081 17.699C7.82186 18.1177 8.90549 18.3333 9.99984 18.3333C11.0942 18.3333 12.1778 18.1177 13.1889 17.699C14.1999 17.2802 15.1186 16.6663 15.8924 15.8925C16.6662 15.1187 17.28 14.2 17.6988 13.189C18.1176 12.1779 18.3332 11.0943 18.3332 9.99996C18.3332 7.78982 17.4552 5.67021 15.8924 4.1074C14.3296 2.5446 12.21 1.66663 9.99984 1.66663ZM6.91058 6.9107C7.23602 6.58527 7.76366 6.58527 8.08909 6.9107L10.0023 8.82385L11.9127 6.91345C12.2382 6.58801 12.7658 6.58801 13.0912 6.91345C13.4167 7.23889 13.4167 7.76653 13.0912 8.09196L11.1809 10.0024L13.0863 11.908C13.4118 12.2334 13.4118 12.761 13.0863 13.0865C12.7609 13.4119 12.2333 13.4119 11.9078 13.0865L10.0023 11.1809L8.09398 13.0892C7.76854 13.4147 7.2409 13.4147 6.91546 13.0892C6.59003 12.7638 6.59003 12.2361 6.91546 11.9107L8.82383 10.0024L6.91058 8.08922C6.58514 7.76378 6.58514 7.23614 6.91058 6.9107Z",
        },
      },
    ],
  })(props);
}

export default CloseIconFilled;
