import styled from "styled-components";

export const Link = styled.a``;

export const Lista = styled.ul`
  padding-left: 32px;
`;

export const Item = styled.li``;

export const Paragraph = styled.p``;
