import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'senninhaRiskAreas';
const GET_RISK_AREAS = 'senninhaRiskAreas/getRiskAreas';

const initialState = {
  riskAreas: null,
  status: 'idle',
  error: null,
};

export const getRiskAreas = createAsyncThunk(GET_RISK_AREAS, async (district, { rejectWithValue }) => {
  try {
    const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/areas_de_risco/${district}`);

    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data || err.response);
  }
});

export const riskAreasSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    resetRiskAreaSlice: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getRiskAreas.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(getRiskAreas.fulfilled, (state, action) => {
        state.riskAreas = action.payload;
        state.status = 'fulfilled';
      })
      .addCase(getRiskAreas.rejected, (state, action) => {
        state.riskAreas = null;
        state.error = action.payload;
        state.status = 'rejected';
      });
  },
});

export const {
  resetRiskAreaSlice
} = riskAreasSlice.actions

export const riskAreasSelector = (state) => state.riskAreasSlice;

export default riskAreasSlice.reducer;
