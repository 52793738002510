import styled, { css } from "styled-components";
import Colors from "@colors";

export const Card = styled.div`
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 2px 8px 0 ${Colors.shadowColor};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "8px"};
  height: ${(props) => props.height || "auto"};
  width: ${(props) => `calc(${props.grid ? props.grid * 100 : 100}% - 16px)`};
  border-radius: 8px;
  display: ${(props) =>
    props.hidden ? "none" : props.gridContainer ? "grid" : "block"};
  ${(props) => props.minWidth && `min-width: ${props.minWidth};`}
  ${(props) =>
    props.description &&
    css`
      .ant-descriptions-item-label {
        width: 33%;
      }
    `};
  @media (max-width: 1024px) {
    width: 100%;
    flex: 1;
    margin: 8px 0;
  }

  .ant-space-align-center {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

export const HeaderColumn = styled.div`
  svg {
    transition: 0.2s;
    &:hover {
      fill: ${Colors.lightGreen};
    }
  }
  width: ${(props) => (props.topBox ? "100%" : "3.5%")};
`;

export const BackButton = styled.div`
  padding: 6px 8px 12px 16px;
  margin: -6px 0px -12px -16px;
  cursor: pointer;
`;

export const ExpandButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: ${(props) => (props.noPaddingLeft ? "4px" : "24px")};
  padding-right: ${(props) => (props.topBox ? "16.14px" : "0px")};
  span {
    transform: ${(props) =>
      props.bottom
        ? props.open
          ? "rotate(0deg)"
          : "rotate(-180deg)"
        : props.open
        ? "rotate(-180deg)"
        : "rotate(0deg)"};
    transition: 0.5s;
  }
`;

export const Child = styled.div`
  max-height: ${(props) =>
    props.expandable ? (props.open ? "100vh" : "0") : "100%"};
  overflow-x: hidden;
  transition: 0.5s;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: ${Colors.deepBlue};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
  white-space: nowrap;
`;

export const Advice = styled.p`
  color: ${Colors.lightGreen};
  font-size: 12px;
  letter-spacing: 1.2px;
  font-weight: bold;
  padding: 0 16px 8px;
  width: 100%;
  font-weight: bold;
  text-align: end;
`;

export const InfoContent = styled.p`
  width: 350px;
`;
