import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 300px);
  align-items: start;
  grid-gap: 1.5rem;
`;

export const ContainerBig = styled(Container)`
  height: calc(100vh - 231.7px);
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: start;
  width: 100%;
  .ant-radio-inner {
    border: 2.321px solid #a6aeba;
  }
  .ant-radio-inner::after {
    top: 15.5px;
    left: 15.5px;
  }
`;

export const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div:last-child {
    display: flex;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: ${defaultTheme.colors.white};
  padding: 1.5rem;
  border-radius: 0.75rem;
  line-height: 1.54rem;
  font-weight: 600;
  font-size: ${({ small }) =>
    small ? defaultTheme.fontSize.m : defaultTheme.fontSize.g};
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #303742;
`;

export const TabButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ active }) =>
    active ? defaultTheme.colors.green600 : defaultTheme.colors.grey50};
  padding: 0.625rem 1rem;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${defaultTheme.colors.grey700};
  color: ${({ active }) =>
    active ? defaultTheme.colors.white : defaultTheme.colors.grey700};
  margin-left: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  cursor: pointer;

  svg {
    margin-right: 0.5rem;
    margin-top: -1.5px;
  }

  &:last-child {
    margin: 0;
    border-radius: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  &:hover {
    background: ${({ active }) =>
      active ? defaultTheme.colors.green700 : defaultTheme.colors.grey60};
  }
`;
