import styled from "styled-components";


export const AllFilterDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  .ant-space-align-center {
    margin-top: 18px;
    flex-wrap: wrap;
  }
`;

export const TitleFilterDrawer = styled.div`
  color: #303742;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 18.85px */
  letter-spacing: -0.13px;
  margin-bottom: 8px;
`;

export const ContentDrawer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const DatePickersArea = styled.div`
  display: flex;
  gap: 8px;

  .ant-picker {
    width: 100% !important;
  }
`;