import React, { useState, useEffect } from "react";
import CloseIcon from "@assets/icons/CloseIcon";
import SelectCustom from "@components/SelectCustom";
import { CloseCircleIcon } from "@assets/icons/CloseCircleIcon";
import { FooterButtons } from "@components/FooterButtons";
import { TagCustom } from "@components/TagCustom";
import mapColors from "@mapColors";
import { Switch } from "antd";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";

import * as S from "./styles";

const RelocateRoutes = ({
  step,
  setStep,
  stonecodesRealocacao,
  setModo,
  pins,
  setPins,
  setStonecodesRealocacao,
  angelsDisponiveis,
  toggleMessage,
  recreateLegendThroughPins,
}) => {
  const [stepRelocate, setStepRelocate] = useState("selectingOSsStep");
  const [selectedPins, setSelectedPins] = useState([]);
  const [angelsDisponiveisOptions, setAgelsDisponiveisOptions] = useState([]);
  const [angelRealocacaoSelecionado, setAngelRealocacaoSelecionado] = useState(
    null
  );
  const [deallocate, setDeallocate] = useState(false);

  useEffect(() => {
    setSelectedPins(stonecodesRealocacao.map((item) => ({ value: item })));
  }, [stonecodesRealocacao]);

  useEffect(() => {
    setAgelsDisponiveisOptions(
      angelsDisponiveis.map((item) => ({
        id: item.name,
        name: capitalizeAllFirstLetters(item.name),
      }))
    );
  }, [angelsDisponiveis]);

  const resetCard = () => {
    setStep("initialStep");
    setModo("mapa");
    setStonecodesRealocacao([]);
    setSelectedPins([]);
    resetMarkedPins();
  };

  const resetMarkedPins = () => {
    setPins((prev) =>
      prev.map((pin) => (pin.marcado ? { ...pin, marcado: !pin.marcado } : pin))
    );
  };
  const realocarStonecodes = () => {
    const ordens = pins.filter(
      (r) => stonecodesRealocacao.indexOf(r.stonecode) > -1
    );
    let newPins;

    const segundoArrayMap = ordens.reduce((map, item) => {
      map[item.id] = item;
      return map;
    }, {});

    if (angelRealocacaoSelecionado.length > 0) {
      const angel = angelsDisponiveis.find(
        (a) => a.name == angelRealocacaoSelecionado
      );

      const selectedAngelOs = pins.find(
        (item) => item.oss[0].angel === angel.name
      );

      if (selectedAngelOs) {
        newPins = pins.map((item) =>
          segundoArrayMap[item.id]
            ? {
                ...item,
                oss: item.oss.map((os) => ({ ...os, angel: angel.name })),
                marcado: !item.marcado,
                icon: {
                  pin: selectedAngelOs.icon.pin,
                  text: item.icon.text,
                  cor: selectedAngelOs.icon.cor,
                },
              }
            : item
        );
      } else {
        const usedColors = new Set(
          pins.map((pin) => pin.icon.cor.replace("%23", "#").toUpperCase())
        );
        const availableColors = Object.values(mapColors).filter(
          (color) => !usedColors.has(color.toUpperCase())
        );

        newPins = pins.map((item) =>
          segundoArrayMap[item.id]
            ? {
                ...item,
                oss: item.oss.map((os) => ({ ...os, angel: angel.name })),
                marcado: item.marcado,
                icon: {
                  pin: item.icon.pin,
                  text: item.icon.text,
                  cor: availableColors[0].replace("#", "%23"),
                },
              }
            : item
        );
      }
    } else {
      newPins = pins.map((item) =>
        segundoArrayMap[item.id]
          ? {
              ...item,
              oss: item.oss.map((os) => ({
                ...os,
                angel: "Não alocadas - Vencimento futuro",
              })),
              marcado: !item.marcado,
              icon: {
                cor: "%235daa69",
                opacity: 0.4,
                pin: true,
                stroke: "%235daa69",
                text: item.icon.text,
              },
            }
          : item
      );
    }
    setStonecodesRealocacao([]);
    setSelectedPins([]);
    recreateLegendThroughPins(newPins);
    setPins(newPins);
    resetCard();
  };

  return (
    <>
      {step === "selectingOSsStep" && stepRelocate === "selectingOSsStep" && (
        <S.RelocateCard>
          <S.TitleSimulationCard>
            Realocar OSs
            <CloseIcon
              size={20}
              style={{ cursor: "pointer" }}
              onClick={resetCard}
            />
          </S.TitleSimulationCard>
          <S.SubtitleSimulationCard>
            Selecione no mapa as OSs que deseja realocar.
          </S.SubtitleSimulationCard>

          <S.SubtitleTagSimulation>
            {selectedPins.length !== 0 ? (
              <>
                <p>Stonecodes selecionados:</p>

                <TagCustom
                  tags={selectedPins}
                  setTags={setSelectedPins}
                  closeIcon={<CloseCircleIcon size={16} />}
                  closable
                  relocateCustomTag
                  onClose={(tag) => {
                    const idx = selectedPins.findIndex(
                      (obj) => obj.key === tag.key && obj.value === tag.value
                    );
                    if (idx !== -1) {
                      selectedPins.splice(idx, 1);
                    }
                    setSelectedPins([...selectedPins]);

                    setStonecodesRealocacao(
                      selectedPins.map((item) => item.value)
                    );
                    setPins(
                      pins.map((pin) =>
                        pin.stonecode === tag.value
                          ? { ...pin, marcado: !pin.marcado }
                          : pin
                      )
                    );
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </S.SubtitleTagSimulation>
          <FooterButtons
            nameFirstButton={"Continuar"}
            customWidth={"100%"}
            nameSecondButton={"Fechar"}
            onClickFirstButton={() => {
              setStepRelocate("relocateConfirmStep");
              setModo("realocar");
            }}
            onClickSecondButton={resetCard}
            firstbuttonDisabled={selectedPins.length === 0}
          />
        </S.RelocateCard>
      )}
      {stepRelocate === "relocateConfirmStep" && (
        <S.RelocateCard>
          <S.TitleSimulationCard>
            Realocar OSs
            <CloseIcon
              size={20}
              style={{ cursor: "pointer" }}
              onClick={resetCard}
            />
          </S.TitleSimulationCard>
          <S.SubtitleSimulationCard>
            Selecione o Angels destino.
          </S.SubtitleSimulationCard>
          <S.SubtitleTagSimulation>
            <p>Stonecodes selecionados:</p>
            <TagCustom
              tags={selectedPins}
              setTags={setSelectedPins}
              closeIcon={<CloseCircleIcon size={16} />}
              relocateCustomTag
            />
          </S.SubtitleTagSimulation>
          <S.ContentSelect>
            <SelectCustom
              title={"Angel"}
              placeholder="Selecione uma opção"
              options={angelsDisponiveisOptions}
              value={angelRealocacaoSelecionado}
              onChange={setAngelRealocacaoSelecionado}
              disabled={deallocate}
            />
          </S.ContentSelect>

          <S.DeallocateField>
            <div>
              <span>Desalocar OSs</span>
              <p>
                Todas as OSs dos pins selecionados ficarão sem Angels
                responsável.
              </p>
            </div>
            <div>
              <Switch
                onChange={() => {
                  setDeallocate(!deallocate);
                  setAngelRealocacaoSelecionado("");
                }}
              />
            </div>
          </S.DeallocateField>

          <FooterButtons
            nameFirstButton={deallocate ? "Desalocar" : "Realocar"}
            customWidth={"100%"}
            nameSecondButton={"Voltar"}
            onClickFirstButton={() => {
              realocarStonecodes();
              toggleMessage(
                deallocate
                  ? "Oss desalocadas com sucesso!"
                  : "OSs realocadas com sucesso!"
              );
              setStep("initialStep");
            }}
            onClickSecondButton={() => {
              setStepRelocate("selectingOSsStep");
              setModo("selecionar_realoca");
            }}
            firstbuttonDisabled={!deallocate && !angelRealocacaoSelecionado}
          />
        </S.RelocateCard>
      )}
    </>
  );
};

export default RelocateRoutes;
