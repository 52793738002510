import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import LineChart from "@components/LineChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import Colors from "@colors";

export default function Velocidade(props) {
  const title = "Velocidade (TMA)";
  const [velocidade, setVelocidade] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getVelocidade().then((response) => {
      if (response && response.data != null) {
        setVelocidade(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const tmaGeral = [];
  const tmaProprio = [];
  const tmaTerceira = [];
  const downloadLabels = [
    "Período",
    "TMA Geral",
    "TMA Op. Própria",
    "TMA Op. Terceira",
  ];
  const downloadData = [];

  velocidade &&
    velocidade.map(
      (item) => (
        periodos.push(item.periodo),
        tmaGeral.push(item.geral.toFixed(2)),
        tmaProprio.push(item.propria.toFixed(2)),
        tmaTerceira.push(item.terceira.toFixed(2)),
        downloadData.push([
          `${item.periodo}`,
          `${item.geral.toFixed(3)}`,
          `${item.propria.toFixed(3)}`,
          `${item.terceira.toFixed(3)}`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            suggestedMax: 1.2,
            min: 0.6,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
          },
          gridLines: false,
        },
      ],
    },
    data: [
      {
        label: "GERAL",
        color: Colors.green,
        fill: false,
        value: tmaGeral,
        hidden: true,
      },
      { label: "PRÓPRIA", color: Colors.blue, fill: false, value: tmaProprio },
      {
        label: "TERCEIRA",
        color: Colors.gray,
        fill: false,
        value: tmaTerceira,
        hidden: true,
      },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title}
      info={[
        [
          "1 - Velocidade se resume ao tempo médio de atendimento dos chamados, da data de abertura a data de conclusão.Acompanhamos nossa velocidade com o indicador de TMA;",
        ],
        ["2 - São considerados os serviços de Instalação, Troca e Manutenção;"],
        [
          "3 - Para o indicador principal acompanhamos o TMA da operação própria;",
        ],
        [
          "4 - São considerados os contratantes Stone Pagamentos e Elavon Brasil;",
        ],
        ["5 - São considerados os chamados que tiveram pelo menos uma visita;"],
        [
          "6 - O indicador é medido em dias úteis, desconsiderando apenas feriados nacionais;",
        ],
      ]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <LineChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
