import styled from "styled-components";

export const WeekDay = styled.p`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const ReplicarButton = styled.span`
  position: absolute;
  right: 0;
  opacity: 0.4;
  transition: 0.4s;
  &:hover {
    opacity: 1;
  }
`;
