import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";

export default function AvancoPolo(props) {
  const title = "Avanço";
  const [avancoPolo, setAvancoPolo] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getAvancoPolo(props.polo).then((response) => {
      setAvancoPolo(response.data);
      setLoaded(true);
    });
  }, [props.polo]);

  const dados = [];
  const colunas = [
    {
      label: "Tecnologia",
      key: "tecnologia",
      align: "start",
      sort: {
        compare: (a, b) => a.tecnologia.localeCompare(b.tecnologia),
      },
    },
    { label: "Contratante", key: "contratante" },
    { label: "Quantidade", key: "quantidade" },
    { label: "Data de expedicao", key: "data_expedicao" },
    {
      label: "OM",
      key: "om",
      sort: {
        compare: (a, b) => b.om - a.om,
      },
    },
  ];
  const downloadLabels = [
    "Tecnologia",
    "Contratante",
    "Quantidade",
    "Data de expedicao",
    "OM",
  ];
  const downloadData = [];

  avancoPolo.forEach((item) => {
    const row = {};
    row["tecnologia"] = item.tecnologia;
    row["contratante"] = item.contratante;
    row["quantidade"] = item.quantidade;
    row["data_expedicao"] = item.data_expedicao;
    row["om"] = item.om;
    dados.push(row);
    const rowDownload = [];
    rowDownload.push(item.tecnologia);
    rowDownload.push(item.contratante);
    rowDownload.push(item.quantidade);
    rowDownload.push(item.data_expedicao);
    rowDownload.push(item.om);
    downloadData.push(rowDownload);
  });

  const tableData = {
    data: dados,
    columns: colunas,
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title + " - " + props.name}
      info={[`Avanço ${props.name}`]}
      downloadLabels={loaded ? downloadLabels : []}
      downloadData={loaded ? downloadData : []}
    >
      {loaded ? <InfoTable data={tableData} pagination /> : <LoadingSpinner />}
    </Container>
  );
}
