import styled from "styled-components";


export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const ScreenMask = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(48, 55, 66, 0.2); /* Define a cor de fundo com transparência */
  position: absolute;
  z-index: 10;
`;

export const TextContent = styled.h1`
  color: #303742;
  text-align: center !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 21.75px */
  letter-spacing: -0.15px;
`;
