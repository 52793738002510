import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import Colors from "@colors";

export const ButtonsSimulation = styled.div`
  position: absolute;
  display: flex;
  padding: 24px 0px 0px 24px;

  .ant-btn {
    color: ${defaultTheme.colors.white};
    height: 44px;
    width: 124px;
    border-radius: 8px;
    padding: 13px 16px 13px 16px;
    background: rgba(0, 168, 104, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    border: 1px solid rgba(0, 168, 104, 1);
    margin-right: 12px;
  }

  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    background: rgba(0, 142, 90, 1);
  }
`;

export const RelocateCard = styled.div`
  position: absolute;
  background: white;
  margin: 1.5rem 0rem 0rem 1.5rem;
  padding: 1rem;
  width: 20.9374rem;
  border-radius: 8px;
`;

export const TitleSimulationCard = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(48, 55, 66, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SubtitleSimulationCard = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(118, 127, 141, 1);
  border-bottom: 1px solid rgba(118, 127, 141, 0.1);
  padding-bottom: 1rem;
`;

export const SubtitleTagSimulation = styled.div`
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(118, 127, 141, 1);
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
`;

export const ContentSelect = styled.div`
  margin-top: 0.6rem;
  margin-bottom: 1rem;
`;

export const ContentImage = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0rem;
`;
export const ErrorText = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
  color: rgba(118, 127, 141, 1);
  margin-bottom: 1rem;
`;

export const CloseButton = styled.div`
  background-color: rgba(48, 55, 66, 1);
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
  color: #42ec9a;
  font-size: ${defaultTheme.fontSize.s};
  letter-spacing: -0.01em;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 120%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const Text = styled.div`
  color: ${defaultTheme.colors.grey700};
  font-size: ${defaultTheme.fontSize.m};
  line-height: 22px;
  letter-spacing: -0.01em;
`;

export const OSCount = styled.span`
  color: ${({ overLimitPins }) =>
    overLimitPins ? defaultTheme.colors.red500 : defaultTheme.colors.green700};
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.s};
  line-height: 19px;
  letter-spacing: -0.01em;
`;

export const DetalheStonecode = styled.span`
  color: ${defaultTheme.colors.grey500};
  font-size: ${defaultTheme.fontSize.s};
  line-height: 19px;
  letter-spacing: -0.01em;
`;

export const LegendCard = styled.div`
  background: rgba(255, 255, 255, 1);
  position: absolute;
  height: 100%;
  width: 31%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    height: calc(100% - 89px);
  }
`;
export const LegendTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: rgba(48, 55, 66, 1);
  }
`;

export const OSsVisiveisButton = styled.span`
  padding: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
`;

export const LegendSubtitle = styled.div`
  color: rgba(118, 127, 141, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 11.5px 0px 32px 0px;
`;

export const LegendSearchContent = styled.div`
  border-bottom: 1px solid rgba(118, 127, 141, 0.1);
  padding-bottom: 24px;

  .ant-select {
    width: 100% !important;
  }
`;

export const BottomLegendContent = styled.div`
  height: auto !important;
  border-top: 1px solid #767f8d1a;
  padding: 12px 24px 24px 24px;

  button {
    background-color: ${(props) =>
      props.firstButtonGray ? "#767F8D1A" : "#00A868"};
    color: ${(props) =>
      props.firstButtonGray ? "#303742" : "#fff"} !important;
  }
`;

export const LegendaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-bottom: 16px;

  &:last-child {
    margin: 0 !important;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;

  img {
    width: 21px;
    margin-top: 2.5px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
`;

export const MainHeader = styled.div`
  padding: 24px 24px 0 24px;
`;

export const Items = styled.div`
  padding: 16px 24px 24px 24px;
  height: calc(100% - 200.5px);
  overflow-y: auto;
`;

export const Detalhes = styled.div`
  max-height: ${(props) => (props.open ? "100%" : "0")};
  width: 100%;
  background-color: #f2f4f7b2;
  padding: ${(props) => (props.open ? "8px" : "0")} 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 0.2s;
  border-radius: 8px;

  &::before {
    content: "";
    width: 1px;
    height: calc(100% - 16px);
    background: rgba(48, 55, 66, 0.2);
    position: absolute;
    top: 0;
    left: 15px;
    margin-top: 6px;
  }
`;

export const DetalheOS = styled.p`
  padding-left: 18px;
  position: relative;
  margin-top: 4px;

  &::before {
    content: "";
    width: 11px;
    height: 11px;
    background: ${defaultTheme.colors.grey400};
    position: absolute;
    left: 2px;
    border-radius: 100%;
    top: 10px;
    border: 2px solid ${Colors.lighterGray};
    transform: translateY(-50%);
  }
`;

export const DetalheOSNumber = styled.span`
  color: ${defaultTheme.colors.green600};
  font-size: ${defaultTheme.fontSize.s};
  cursor: pointer;
  transition: 0.2s;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.01em;

  &:hover {
    color: ${Colors.textGreen};
  }
`;

export const OverLimitPinsArea = styled.div`
  margin: 8px 0 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #767f8d1a;
`;

export const AlertBalloon = styled.div`
  display: flex;
  background-color: ${defaultTheme.colors.orange10};
  border: 1.5px solid ${defaultTheme.colors.orange10};
  border-radius: 0.75rem;
  padding: 1rem;
  margin-bottom: 1.5rem;

  & > div:first-child {
    width: 1.5rem;
    margin-right: 0.75rem;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;

    & > span {
      color: ${defaultTheme.colors.grey700};
      font-size: ${defaultTheme.fontSize.s};
      line-height: 18px;
      letter-spacing: -0.01em;

      &:first-child {
        font-size: ${defaultTheme.fontSize.m};
        font-weight: 600;
        margin-bottom: 4px;
        letter-spacing: -0.02em;
      }
    }
  }
`;

export const DeallocateField = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  span {
    color: var(--content-neutral-high, #303742);
    /* Body Medium/Regular */
    font-family: Sharon Sans;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 1.35938rem */
    letter-spacing: -0.00938rem;
  }

  p {
    color: var(--content-neutral, #767f8d);
    /* Body Small/Regular */
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 1.17813rem */
    letter-spacing: -0.00813rem;
  }

  .ant-switch-checked {
    background-color: rgba(0, 168, 104, 1);
  }

  .ant-switch {
    width: 48px;
    height: 28px;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px - 2px);
  }

  .ant-switch-handle {
    width: 24px;
    height: 24px;
  }

  .ant-switch-handle::before {
    border-radius: 99.32px;
  }
`;
