import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import DetailButton from "@components/DetailButton";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import moment from "moment";

export default function VencimentoHojePolo(props) {
  const title = "Chamados atrasados e com vencimento hoje";
  const [vencimentoHojePolo, setVencimentoHojePolo] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getVencimentoHoje(props.polo).then((response) => {
      setVencimentoHojePolo(response.data);
      setLoaded(true);
    });
  }, [props.polo]);

  const dados = [];
  const colunas = [
    {
      label: "Número da OS",
      key: "orderNumber",
      type: "numeric",
      cellStyle: { fontSize: "0.8em" },
    },
    {
      label: "Contratante",
      key: "contractor",
      cellStyle: { fontSize: "0.8em" },
    },
    {
      label: "Stonecode",
      key: "customer",
      type: "numeric",
      cellStyle: { fontSize: "0.8em" },
    },
    {
      label: "Status",
      key: "status",
      type: "string",
      cellStyle: { fontSize: "0.8em" },
    },
    {
      label: "Nome do Cliente",
      key: "customerName",
      cellStyle: { fontSize: "0.8em" },
    },
    {
      label: "Grupo de Serviço",
      key: "serviceGroup",
      cellStyle: { fontSize: "0.8em" },
    },
    {
      label: "Tecnologia",
      key: "installedTerminalGroup",
      type: "string",
      cellStyle: { fontSize: "0.8em" },
    },
    {
      label: "Bairro",
      key: "neighborhood",
      cellStyle: { fontSize: "0.8em" },
    },
    { label: "Cidade", key: "city", cellStyle: { fontSize: "0.8em" } },
    {
      label: "Data Limite",
      key: "deadlineDate",
      type: "date",
      cellStyle: { fontSize: "0.8em" },
    },
    {
      label: "Reponsável",
      key: "technicianName",
      cellStyle: { fontSize: "0.8em" },
    },
  ];
  const [downloadLabels, setDownloadLabels] = useState([
    "Número da OS",
    "Contratante",
    "Stonecode",
    "Status",
    "Nome do Cliente",
    "Grupo de Serviço",
    "Tecnologia",
    "Bairro",
    "Cidade",
    "Data Limite",
    "Reponsável",
  ]);
  const [downloadData, setDownloadData] = useState([]);
  //Construtor
  vencimentoHojePolo.forEach((item) => {
    let row = {};
    row["orderNumber"] = item.orderNumber;
    row["contractor"] = item.contractor;
    row["customer"] = item.customer;
    row["status"] = item.status;
    row["customerName"] = item.customerName;
    row["serviceGroup"] = item.serviceGroup;
    row["installedTerminalGroup"] = item.installedTerminalGroup;
    row["neighborhood"] = item.neighborhood;
    row["city"] = item.city;
    row["deadlineDate"] = moment(item.deadlineDate).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    row["technicianName"] = item.technicianName;
    dados.push(row);
    const rowDownload = [];
    rowDownload.push(item.orderNumber);
    rowDownload.push(item.contractor);
    rowDownload.push(item.customer);
    rowDownload.push(item.status);
    rowDownload.push(item.customerName);
    rowDownload.push(item.serviceGroup);
    rowDownload.push(item.installedTerminalGroup);
    rowDownload.push(item.neighborhood);
    rowDownload.push(item.city);
    rowDownload.push(item.deadlineDate);
    rowDownload.push(item.technicianName);
    downloadData.push(rowDownload);
  });
  const tableData = {
    data: dados,
    columns: colunas,
  };
  return (
    <Container
      title={title + " - " + props.name}
      info={[`Alertas ${props.name}`]}
      downloadLabels={loaded ? downloadLabels : []}
      downloadData={loaded ? downloadData : []}
    >
      {loaded ? (
        <InfoTable data={tableData} pagination search />
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
}
