import { LoadingOutlined } from "@ant-design/icons";
import InfoTable from "@components/InfoTable";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import React, { useEffect, useState } from "react";

export default function DetalesCaso(props) {
  const loading = <LoadingOutlined spin />;
  const [detalheCaso, setDetalheCaso] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    LogisticService.getSalesforceCaso(props.data).then((response) => {
      if (response && response.data !== null) {
        setDetalheCaso(response.data);
      }
      setLoaded(true);
    });
  }, [props.data]);

  let detalhes = [];

  if (detalheCaso) {
    const row = {};
    row["key"] = 0;
    row["detalhe"] = detalheCaso.descricao || "";
    row["status"] = detalheCaso.status || "";
    row["assunto"] = detalheCaso.assunto || "";
    detalhes.push(row);
  }

  const tableData = {
    data: detalhes,
    columns: [
      {
        label: "Status",
        key: "status",
        align: "start",
      },
      {
        label: "Assunto",
        key: "assunto",
        align: "start",
      },
      {
        label: "Detalhes",
        key: "detalhe",
        align: "start",
      },
    ],
  };

  return (
    <>
      {loaded ? (
        <InfoTable
          data={tableData}
          pagination={{ defaultPageSize: 8 }}
          footer={false}
        />
      ) : (
        <Row
          horizontalAlign={"center"}
          verticalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"16px"}
        >
          {loading}
        </Row>
      )}
    </>
  );
}
