import { LoadingOutlined } from "@ant-design/icons";
import Box from "@components/Box";
import Container from "@components/Container";
import CustomButton from "@components/CustomButton";
import CustomModal from "@components/CustomModal";
import DetailButton from "@components/DetailButton";
import InfoTable from "@components/InfoTable";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import AberturaOSs from "./AberturaOSs";
import HistoricoCobranca from "./HistoricoCobranca";

export default function OrdensServico(props) {
  const loading = <LoadingOutlined spin />;
  const [maquinasCliente, setMaquinasCliente] = useState([]);
  const [maquinasDesinstalacao, setMaquinasDesinstalacao] = useState([]);
  const [maquinaSelecionada, setMaquinaSelecionada] = useState(null);
  const [visibleAberturaOSs, setVisibleAberturaOSs] = useState(false);
  const [visibleHistoricoCobranca, setVisibleHistoricoCobranca] = useState(
    false
  );
  const [loaded, setLoaded] = useState(false);
  let numeroMaquinasInstaladas = [];
  let numeroDeMaquinas = maquinasCliente.map(
    (item) =>
      item["status"]["name"] == "Instalado" &&
      numeroMaquinasInstaladas.push(item)
  );

  useEffect(() => {
    setLoaded(false);
    LogisticService.getMaquinasCliente(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        setMaquinasCliente(response.data);
      }
      setLoaded(true);
    });
  }, [props.stonecode]);

  const dadosMaquinas = [];
  const colunasMaquinas = [
    {
      label: "Status",
      key: "status",
      sort: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
    },
    {
      label: "Serial",
      key: "serial_number",
      sort: {
        compare: (a, b) => a.serial_number.localeCompare(b.serial_number),
      },
    },
    {
      label: "Modelo",
      key: "model",
      sort: {
        compare: (a, b) => a.model.localeCompare(b.model),
      },
    },
    {
      label: "Tipo",
      key: "type",
      sort: {
        compare: (a, b) => a.type.localeCompare(b.type),
      },
    },
    {
      label: "Aluguel",
      key: "charge_amount",
      sort: {
        compare: (a, b) =>
          a.charge_amount.localeCompare(b.charge_amount, undefined, {
            numeric: true,
          }),
      },
    },
    {
      label: "Isenção",
      key: "exemption",
    },
    {
      label: "Data de Criação",
      key: "creationDate",
    },
  ];
  const downloadLabels = [
    "ID",
    "Modelo",
    "Serial",
    "ID terminal",
    "Tipo",
    "Status",
    "Tecnologia",
    "Aluguel",
    "Data de Criação",
  ];
  const downloadData = [];

  maquinasCliente &&
    maquinasCliente.forEach((item, index) => {
      const row = {};
      row["key"] = index;
      row["model"] = item.model.name || "";
      row["terminal_id"] = item.terminalId || "";
      row["technology_type"] = item.technologyType.name || "";
      row["id"] = item.id || "";
      row["serial_number"] = item.serialNumber || "";
      row["type"] = item.type.name || "";
      row["status"] = item.status.name || "";
      row["charge_amount"] =
        (item.chargeAmount && `R$${item.chargeAmount.toFixed(2)}`) || "";
      row["exemption"] = (
        <DetailButton
          onClick={() => {
            setMaquinaSelecionada(item.terminalId);
            setVisibleHistoricoCobranca(true);
          }}
        />
      );
      row["creationDate"] = item.creationDate || "";
      dadosMaquinas.push(row);
      const rowDownload = [];
      rowDownload.push(item.model.name || "");
      rowDownload.push(item.serialNumber || "");
      rowDownload.push(item.type.name || "");
      rowDownload.push(item.status.name || "");
      rowDownload.push(
        (item.chargeAmount && `R$${item.chargeAmount.toFixed(2)}`) || ""
      );
      rowDownload.push(item.creationDate || "");
      downloadData.push(rowDownload);
    });
  const tableData = {
    data: dadosMaquinas,
    columns: colunasMaquinas,
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setMaquinasDesinstalacao([...selectedRows]);
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled:
        record.status === "Desinstalado" || record.status === "OS aberta",
      name: record.id,
    }),
  };

  const customFooter = () => (
    <CustomButton
      onClick={() => {
        setVisibleAberturaOSs(true);
      }}
      disabled={maquinasDesinstalacao.length === 0}
    >
      Abrir Ordens de Serviço
    </CustomButton>
  );

  return (
    <Container
      title={`Ordens de Serviço`}
      icon={"info-circle"}
      grid={1}
      expandable
    >
      {loaded ? (
        <Box margin={"0 8px 8px"}>
          <Container
            title={
              numeroDeMaquinas == 1
                ? `Máquinas do Cliente (${numeroMaquinasInstaladas.length} instalada)`
                : `Máquinas do Cliente (${numeroMaquinasInstaladas.length} instaladas)`
            }
            downloadLabels={downloadLabels}
            downloadData={downloadData}
          >
            <InfoTable
              data={tableData}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              search
              pagination={{ defaultPageSize: 6, showSizeChanger: true }}
              footer={customFooter}
            />
          </Container>
          <CustomModal
            title={`Isenção e Histórico de Cobrança (${maquinaSelecionada})`}
            visible={visibleHistoricoCobranca}
            onCancel={() => setVisibleHistoricoCobranca(false)}
            zIndex={1010}
            footer={false}
          >
            <HistoricoCobranca
              stonecode={props.stonecode}
              id_terminal={maquinaSelecionada}
              setVisible={setVisibleHistoricoCobranca}
            />
          </CustomModal>
          <CustomModal
            title={"Abrir Ordens de Serviço"}
            visible={visibleAberturaOSs}
            onCancel={() => setVisibleAberturaOSs(false)}
            zIndex={1010}
            width={1200}
            footer={false}
          >
            <AberturaOSs
              stonecode={props.stonecode}
              maquinasDesinstalacao={maquinasDesinstalacao}
              visible={setVisibleAberturaOSs}
            />
          </CustomModal>
        </Box>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
