import { GenIcon } from "react-icons";

function OutlinedPlusIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 21 21",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M10.3089 3.83341C8.54081 3.83341 6.84512 4.53579 5.59487 5.78604C4.34463 7.03628 3.64225 8.73197 3.64225 10.5001C3.64225 11.3756 3.81469 12.2425 4.14972 13.0513C4.48475 13.8601 4.97582 14.5951 5.59487 15.2141C6.21393 15.8332 6.94886 16.3242 7.7577 16.6593C8.56653 16.9943 9.43344 17.1667 10.3089 17.1667C11.1844 17.1667 12.0513 16.9943 12.8601 16.6593C13.669 16.3242 14.4039 15.8332 15.023 15.2141C15.642 14.5951 16.1331 13.8601 16.4681 13.0513C16.8031 12.2425 16.9756 11.3756 16.9756 10.5001C16.9756 8.73197 16.2732 7.03628 15.023 5.78604C13.7727 4.53579 12.077 3.83341 10.3089 3.83341ZM4.41636 4.60752C5.97917 3.04472 8.09878 2.16675 10.3089 2.16675C12.5191 2.16675 14.6387 3.04472 16.2015 4.60752C17.7643 6.17033 18.6423 8.28994 18.6423 10.5001C18.6423 11.5944 18.4267 12.6781 18.0079 13.6891C17.5891 14.7002 16.9753 15.6188 16.2015 16.3926C15.4277 17.1665 14.509 17.7803 13.4979 18.1991C12.4869 18.6179 11.4033 18.8334 10.3089 18.8334C9.21457 18.8334 8.13094 18.6179 7.11989 18.1991C6.10884 17.7803 5.19018 17.1665 4.41636 16.3926C3.64254 15.6188 3.02871 14.7002 2.60992 13.6891C2.19113 12.6781 1.97559 11.5944 1.97559 10.5001C1.97559 8.28994 2.85356 6.17033 4.41636 4.60752ZM10.3089 7.16675C10.7692 7.16675 11.1423 7.53984 11.1423 8.00008V9.66675H12.8089C13.2692 9.66675 13.6423 10.0398 13.6423 10.5001C13.6423 10.9603 13.2692 11.3334 12.8089 11.3334H11.1423V13.0001C11.1423 13.4603 10.7692 13.8334 10.3089 13.8334C9.84868 13.8334 9.47559 13.4603 9.47559 13.0001V11.3334H7.80892C7.34868 11.3334 6.97559 10.9603 6.97559 10.5001C6.97559 10.0398 7.34868 9.66675 7.80892 9.66675H9.47559V8.00008C9.47559 7.53984 9.84868 7.16675 10.3089 7.16675Z",
        },
      },
    ],
  })(props);
}

export default OutlinedPlusIcon;
