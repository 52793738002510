import { SearchOutlined } from "@ant-design/icons";
import { Input, Space, Table, DatePicker } from "antd";
import React, { useState } from "react";
import Colors from "@colors";
import Box from "../Box";
import CustomButton from "../CustomButton";
import CustomLink from "../CustomLink";
import Row from "../Row";
import { CustomInfoTable } from "./styles";

const InfoTable = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const getColumnSearchProps =
    props.search === undefined
      ? () => {}
      : (dataIndex, type, title) => ({
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <Box padding={"8px 0"}>
              {type == "date" ? (
                <DatePicker
                  placeholder={`Buscar em ${title}`}
                  onPressEnter={() =>
                    handleSearch(selectedKeys, confirm, dataIndex)
                  }
                  onChange={(e) =>
                    setSelectedKeys(e !== null ? [e.format("YYYY-MM-DD")] : [])
                  }
                  style={{ display: "block" }}
                ></DatePicker>
              ) : (
                <Input
                  placeholder={`Buscar em ${title}`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() =>
                    handleSearch(selectedKeys, confirm, dataIndex)
                  }
                  style={{ display: "block" }}
                />
              )}
              <Row horizontalAlign={"center"}>
                <Space>
                  <CustomButton
                    small
                    htmlType="submit"
                    onClick={() =>
                      handleSearch(selectedKeys, confirm, dataIndex)
                    }
                  >
                    Buscar
                  </CustomButton>
                  <CustomLink
                    danger
                    onClick={() =>
                      clearFilters && handleReset(clearFilters, confirm)
                    }
                  >
                    Resetar
                  </CustomLink>
                </Space>
              </Row>
            </Box>
          ),
          filterIcon: () => (
            <SearchOutlined style={{ color: Colors.darkGreen }} />
          ),
          onFilter: (value, record) =>
            record[dataIndex]
              ? record[dataIndex]
                  .toString()
                  .toLowerCase()
                  .startsWith(value.toLowerCase())
              : "",
          render: (text) => text,
        });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    setSearchText("");
  };

  const data = {
    data: props.data.data.map((content) => content),
    columns: props.data.columns.map((content) => ({
      title: content.label,
      dataIndex: content.key,
      key: content.key,
      sorter: content.sort,
      align: content.align || "center",
      ...getColumnSearchProps(content.key, content.type, content.title),
      render(text) {
        return {
          props: {
            style: {
              background:
                content.color && text === content.color[0]
                  ? content.color[1]
                  : "inherit",
              color:
                text === "CANCELADA" ||
                text === "OS aberta" ||
                text === "Desinstalado"
                  ? "indianred"
                  : text === "Instalado"
                  ? "#5daa69"
                  : "rgba(0, 0, 0, 0.85)",
            },
          },
          children: <div>{text}</div>,
        };
      },
    })),
  };

  const title = props.title ? () => props.title : false;

  return (
    <Box color={Colors.light}>
      <CustomInfoTable>
        <Table
          dataSource={data.data}
          columns={data.columns}
          pagination={props.pagination || false}
          title={title}
          size="small"
          scroll={{ x: "400" }}
          rowSelection={props.rowSelection || false}
          footer={props.footer || false}
          style={{ whiteSpace: "pre-line" }}
        />
      </CustomInfoTable>
    </Box>
  );
};

export default InfoTable;
