import React from "react";
import CloseIcon from "@assets/icons/CloseIcon";
import senninha from "@assets/icons/senninha.svg";

import * as S from "./styles";

const FirstStep = ({ setIsModalOpen, routeType, setStep, defineRoute }) => (
  <>
    <S.Header>
      Definir roteirização
      <CloseIcon
        size={20}
        fill="#303742"
        onClick={() => setIsModalOpen(false)}
      />
    </S.Header>
    <S.Content>
      <S.Senninha src={senninha} />

      <span>
        Você selecionou a opção
        <b>{` ${routeType}`}</b>. Ao confirmar sua escolha a roteirização do
        senninha será alterada. Confirmar?
      </span>

      <div>
        <S.ModalButton onClick={() => setIsModalOpen(false)}>
          Cancelar
        </S.ModalButton>
        <S.ModalButton
          onClick={() => {
            setStep("secondStep");
            defineRoute();
          }}
        >
          Continuar
        </S.ModalButton>
      </div>
    </S.Content>
  </>
);

export default FirstStep;
