import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button } from "antd";

export const Container = styled.div`
  background-color: ${defaultTheme.colors.white};
  padding: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & > div {
    margin-bottom: 1rem;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 1.375rem;
  letter-spacing: -0.01em;
  color: ${defaultTheme.colors.grey700};
`;

export const RadioString = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${defaultTheme.colors.grey700};
`;

export const RouteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  padding: 0;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${defaultTheme.colors.green600} !important;
  line-height: 1rem !important;
  border: none;
  font-size: ${defaultTheme.fontSize.s};

  & > span {
    font-weight: 600;
    font-size: 13px;
    line-height: 1rem !important;
    letter-spacing: -0.01em;
    color: ${defaultTheme.colors.white};
    margin: 2px 0 0 8px;
  }

  & > svg {
    fill: #000 !important;
    &:hover {
      fill: #000 !important;
    }
  }

  &:hover {
    background-color: ${defaultTheme.colors.green700} !important;
  }
`;
