import React from "react";
import Clock from "@assets/icons/Clock";
import Truck from "@assets/icons/Truck";
import File from "@assets/icons/File";
import NeutralPersonIcon from "@assets/icons/NeutralPersonIcon";
import { formatedOsTypesName } from "./../../helper";

import * as S from "./styles";

export const Legend = ({ data, totalInfo }) => {
  const types = data.reduce((acc, { timeSlots }) => {
    timeSlots.forEach(({ type }) => {
      if (!acc.includes(type) && type !== "ALMOCO") {
        acc.push(type);
      }
    });
    return acc;
  }, []);

  return (
    data && (
      <S.LegendContainer>
        <div>
          {types.map((type, i) => (
            <S.Legend key={i}>
              <S.ColorBadge key={type} type={type} />
              <span>{formatedOsTypesName[type]}</span>
            </S.Legend>
          ))}
        </div>
        <div>
          <S.InfoBadge>
            <Clock size={16} />
            <span>{`${totalInfo ? totalInfo[1] : ""} h`}</span>
          </S.InfoBadge>
          <S.InfoBadge>
            <Truck size={16} />
            <span>{`${totalInfo ? totalInfo[0] : ""} km`}</span>
          </S.InfoBadge>
          <S.InfoBadge>
            <File size={16} />
            <span>{`${totalInfo ? totalInfo[2] : ""} OS`}</span>
          </S.InfoBadge>
          <S.InfoBadge>
            <NeutralPersonIcon size={16} />
            <span>{`${totalInfo ? totalInfo[3] : ""}`}</span>
          </S.InfoBadge>
        </div>
      </S.LegendContainer>
    )
  );
};
