import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import LineChart from "@components/LineChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import Colors from "@colors";

export default function TmaCartao(props) {
  const title = "Velocidade Cartão (TMA)";
  const [tmaCartao, setTmaCartao] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getTmaCartao().then((response) => {
      if (response && response.data != null) {
        setTmaCartao(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const tmaGeral = [];
  const downloadLabels = ["Período", "TMA Geral"];
  const downloadData = [];

  tmaCartao &&
    tmaCartao.map(
      (item) => (
        periodos.push(item.periodo),
        tmaGeral.push(item.geral.toFixed(2)),
        downloadData.push([`${item.periodo}`, `${item.geral.toFixed(3)}`])
      )
    );

  const chartData = {
    datalabels: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            suggestedMax: 20,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
          },
          gridLines: false,
        },
      ],
    },
    data: [
      { label: "GERAL", color: Colors.green, fill: false, value: tmaGeral },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title}
      info={[[""]]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <LineChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
