import { GenIcon } from "react-icons";

function BipeIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M10 3C10 3.55229 9.55229 4 9 4C7.55752 4 6.57626 4.00213 5.84144 4.10092C5.13538 4.19585 4.80836 4.36322 4.58579 4.58579C4.36322 4.80836 4.19585 5.13538 4.10092 5.84144C4.00213 6.57626 4 7.55752 4 9C4 9.55229 3.55229 10 3 10C2.44772 10 2 9.55229 2 9L2 8.92943C1.99997 7.57532 1.99994 6.45869 2.11876 5.57494C2.24297 4.65108 2.51314 3.83471 3.16317 3.18001L3.17801 3.16516C3.83304 2.51369 4.65014 2.24309 5.57494 2.11876C6.45869 1.99994 7.57529 1.99997 8.9294 2L9 2C9.55229 2 10 2.44772 10 3Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M4 15C4 14.4477 3.55229 14 3 14C2.44772 14 2 14.4477 2 15L2 15.0706C1.99997 16.4247 1.99994 17.5413 2.11876 18.4251C2.2435 19.3529 2.51547 20.1723 3.17158 20.8284C3.82769 21.4845 4.64711 21.7565 5.57494 21.8813C6.4587 22.0001 7.57531 22 8.92943 22H9C9.55229 22 10 21.5523 10 21C10 20.4477 9.55229 20 9 20C7.55752 20 6.57626 19.9979 5.84144 19.8991C5.13538 19.8042 4.80836 19.6368 4.58579 19.4142C4.36322 19.1916 4.19585 18.8646 4.10092 18.1586C4.00213 17.4238 4 16.4425 4 15Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M20 15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15L22 15.0706C22 16.4247 22.0001 17.5413 21.8813 18.4251C21.7565 19.3529 21.4845 20.1723 20.8284 20.8284C20.1723 21.4845 19.3529 21.7565 18.4251 21.8813C17.5413 22.0001 16.4247 22 15.0706 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20C16.4425 20 17.4238 19.9979 18.1586 19.8991C18.8646 19.8042 19.1916 19.6368 19.4142 19.4142C19.6368 19.1916 19.8042 18.8646 19.8991 18.1586C19.9979 17.4238 20 16.4425 20 15Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M15 4C14.4477 4 14 3.55229 14 3C14 2.44772 14.4477 2 15 2L15.0706 2C16.4247 1.99997 17.5413 1.99994 18.4251 2.11876C19.3499 2.24309 20.167 2.51369 20.822 3.16516L20.8368 3.18001C21.4869 3.83471 21.757 4.65108 21.8813 5.57494C22.0001 6.4587 22 7.57531 22 8.92943L22 9C22 9.55229 21.5523 10 21 10C20.4477 10 20 9.55229 20 9C20 7.55752 19.9979 6.57626 19.8991 5.84144C19.8042 5.13538 19.6368 4.80836 19.4142 4.58579C19.1916 4.36322 18.8646 4.19585 18.1586 4.10092C17.4238 4.00213 16.4425 4 15 4Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M6.92308 7C6.41328 7 6 7.41328 6 7.92308V16.0769C6 16.5867 6.41328 17 6.92308 17C7.43288 17 7.84616 16.5867 7.84616 16.0769V7.92308C7.84616 7.41328 7.43288 7 6.92308 7Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M13.3846 7C12.8748 7 12.4616 7.41328 12.4616 7.92308V16.0769C12.4616 16.5867 12.8748 17 13.3846 17C13.8944 17 14.3077 16.5867 14.3077 16.0769V7.92308C14.3077 7.41328 13.8944 7 13.3846 7Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M8.76935 8.38462C8.76935 8.02674 8.76935 7.84781 8.81463 7.7025C8.9124 7.38874 9.15809 7.14305 9.47185 7.04528C9.61715 7 9.79609 7 10.154 7C10.5118 7 10.6908 7 10.8361 7.04528C11.1498 7.14305 11.3955 7.38874 11.4933 7.7025C11.5386 7.84781 11.5386 8.02674 11.5386 8.38462V15.6154C11.5386 15.9733 11.5386 16.1522 11.4933 16.2975C11.3955 16.6113 11.1498 16.857 10.8361 16.9547C10.6908 17 10.5118 17 10.154 17C9.79609 17 9.61715 17 9.47185 16.9547C9.15809 16.857 8.9124 16.6113 8.81463 16.2975C8.76935 16.1522 8.76935 15.9733 8.76935 15.6154V8.38462Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M15.2759 7.7025C15.2307 7.84781 15.2307 8.02674 15.2307 8.38462V15.6154C15.2307 15.9733 15.2307 16.1522 15.2759 16.2975C15.3737 16.6113 15.6194 16.857 15.9332 16.9547C16.0785 17 16.2574 17 16.6153 17C16.9731 17 17.1521 17 17.2974 16.9547C17.6111 16.857 17.8568 16.6113 17.9546 16.2975C17.9999 16.1522 17.9999 15.9733 17.9999 15.6154V8.38462C17.9999 8.02674 17.9999 7.84781 17.9546 7.7025C17.8568 7.38874 17.6111 7.14305 17.2974 7.04528C17.1521 7 16.9731 7 16.6153 7C16.2574 7 16.0785 7 15.9332 7.04528C15.6194 7.14305 15.3737 7.38874 15.2759 7.7025Z",
        },
      },
    ],
  })(props);
}

export default BipeIcon;
