import React, { useState } from "react";
import { FiSearch, FiXCircle } from "react-icons/fi";

import FloatLabel from "../FloatLabel";

import "./styles.css";
import { Input } from "antd";

const SearchCustomBox = ({ onSearch, label, hasError, onClean, ...props }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(e);
      setHasSubmitted(true);
    }
  };

  const handleClean = () => {
    if (onClean) {
      onClean();
      setHasSubmitted(false);
    }
  };

  return (
    <>
      {!hasSubmitted ? (
        <form onSubmit={onSubmit} className="search-box-form">
          <FloatLabel label={label} hasError={hasError} searchDoa={true}>
            <div className="search-box">
              <Input {...props} />
              <button
                type="submit"
                aria-label="Submit"
                className={`search-box-button ${
                  props.size === "small" ? "search-box-button-sm" : ""
                }`}
              >
                <FiSearch size={20} color="#303742" />
              </button>
            </div>
          </FloatLabel>
        </form>
      ) : (
        <div
          className={`searched-box ${
            props.size === "small" ? "searched-box-sm" : ""
          }`}
        >
          <FiSearch size={24} color="#A6AEBA" />
          <Input disabled searchDoa {...props} />
          <FiXCircle size={24} onClick={handleClean} />
        </div>
      )}
    </>
  );
};

export default SearchCustomBox;
