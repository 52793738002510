import React, { useState } from "react";
import { useWindowSize } from "./useWindowSize";

export const usePaginationConfig = () => {
  const { width } = useWindowSize();
  const [pageSize, setPageSize] = useState(6);

  const equalOrBiggerThan900 = !!width && width >= 900;

  return {
    showSizeChanger: equalOrBiggerThan900,
    showTotal: (total, range) =>
      equalOrBiggerThan900 ? `${range[0]}-${range[1]} de ${total}` : undefined,
    position: ["bottomCenter"],
    pageSize,
    pageSizeOptions: [6, 12],
    onShowSizeChange: (_, size) => {
      setPageSize(size);
    },
    locale: { items_per_page: "" },
    showLessItems: true,
  };
};
