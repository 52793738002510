import styled from "styled-components";

export const AcaoText = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  word-wrap: break-word;
`;

export const TableTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 16px 12px;
  text-transform: uppercase;
`;

export const SimpleRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ModalContainer = styled.div`
  padding: 0 12px;
`;
