import styled from "styled-components";
import Colors from "@colors";
import defaultTheme from "@defaultTheme";
import EditIcon from "@assets/icons/EditIcon";
import { Button } from "antd";

export const AllConfigs = styled.div`
  margin-top: 1rem;
`;

export const ToolsBox = styled.div`
  min-width: 100px;
  position: absolute;
  top: 12px;
  left: 33px;
  display: flex;
  max-width: calc(100vw - 16px);
`;

export const Page = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
`;

export const Info = styled.p`
  margin: ${(props) => (props.margin ? props.margin : "0 8px 8px")};
  font-size: ${(props) => (props.size ? props.size : "14px")};
  max-width: 400px;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const InfoSmall = styled.p`
  font-size: 11px;
  max-width: 400px;
  text-align: ${(props) => (props.center ? "center" : "left")};
  margin-bottom: 8px;
  color: ${(props) => (props.warning ? Colors.dangerRed : "inherited")};
  white-space: pre-line;
`;

export const VoltarButton = styled.a`
  color: ${Colors.lightGreen};
  font-weight: bold;
  &:hover {
    color: ${Colors.textGreen};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px 16px;
`;

export const ColumnTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const OS = styled.p`
  font-size: 14px;
  color: ${Colors.textGreen};
  font-weight: bold;
  padding: 4px;
  border-bottom: 1px solid ${Colors.lighterGray};
`;

export const AngelsList = styled.div``;

export const ToolsSelect = styled.div`
  border-radius: 0.5rem;
  z-index: 949;
  height: 4.25rem;
  @media (max-width: 980px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const LeftToolsBox = styled.div`
  background-color: ${defaultTheme.colors.white};
  position: absolute;
  left: 32px;
  top: 24px;
  display: flex;
  height: 4.25rem;
  width: 18.375rem;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-right: 45px;
  align-items: center;

  p {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #303742;
  }
`;
export const LeftEditIconContent = styled.div`
  background-color: ${defaultTheme.colors.grey50};
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 99px;
  padding: 8px;
  cursor: pointer;
  margin-left: 8px;

  &:hover {
    background-color: ${defaultTheme.colors.grey60};
    cursor: loading;
  }
`;

export const RightToolsBox = styled.div`
  background-color: ${defaultTheme.colors.white};
  position: absolute;
  display: flex;
  right: 32px;
  top: 24px;
  height: 68px;
  width: 52vw;
  padding: 12px;
  border-radius: 0.5rem;
  @media (max-width: 1380px) {
    top: 96px;
    left: 32px;
    width: 58vw;
  }
`;

export const RightToolsContent = styled(Button)`
  display: flex;
  height: 44px;
  border-radius: 0.5rem;
  background-color: ${defaultTheme.colors.grey50};
  border: none;
  padding: 13px 16px;
  flex: none;
  order: 5;
  flex-grow: 0;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #303742;
  margin: ${(props) =>
    props.isFirst ? "0px 0px 0px 0px" : "0px 0px 0px 12px"};
  cursor: pointer;
  &:hover {
    background-color: ${defaultTheme.colors.grey60};
    cursor: loading;
  }
`;

export const ConfigOptionsSide = styled.span`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ConfigOptionsContent = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin-top: 13px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
  li {
    background: ${defaultTheme.colors.grey20};
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    margin: 0 4px;
    white-space: nowrap;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: rgba(48, 55, 66, 1) !important;
  }
`;

export const ConfigOptionsButtonWay = styled.button`
  background: ${defaultTheme.colors.grey10};
  color: #000;
  display: flex;
  padding: 9px;
  border-radius: 8px;
  margin: ${(props) =>
    props.isFirst ? "0px 12px 0px 0px" : "0px 0px 0px 12px"};
  white-space: nowrap;
  border: 0px solid;
  cursor: pointer;
  &:hover {
    background-color: ${defaultTheme.colors.grey20};
  }
`;
