import styled, { keyframes } from "styled-components";
import defaultTheme from "@defaultTheme";
import { gradientOsColors } from "./../../helper";
import { Button, Skeleton } from "antd";

const rodando = keyframes`
    0%{
        transform: rotate(0)
    }
    100%{
        transform: rotate(360deg)
    }
`;

export const Container = styled.div`
  background-color: ${defaultTheme.colors.white};
  border-radius: 0.75rem;
  width: 100%;
`;

export const Header = styled.div`
  max-height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${defaultTheme.colors.white};
  padding: 1.5rem;
  border-radius: 0.75rem;
  line-height: 24.65px;
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.g};
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #303742;
`;

export const SimulationButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 36px;
  padding: 0;
  border-radius: 8px;
  background-color: ${defaultTheme.colors.grey50} !important;
  line-height: 1rem !important;
  border: none;
  margin: -6px 0;

  & > span {
    font-weight: 600;
    font-size: 13px;
    line-height: 1rem !important;
    letter-spacing: -0.01em;
    color: ${defaultTheme.colors.grey700};
    margin: 2px 0 0 8px;
  }

  & > svg {
    fill: #000 !important;
    &:hover {
      fill: #000 !important;
    }
  }

  &:hover {
    background-color: ${defaultTheme.colors.grey60} !important;
  }
`;

export const ColorBadge = styled.div`
  width: 0.75rem;
  height: 0.5rem;
  background: ${(props) => gradientOsColors[props.type]};
  border-radius: 100px;
  margin-right: 0.25rem !important;
`;

export const InfoBadge = styled.div`
  background-color: ${defaultTheme.colors.grey50};
  padding: 0.25rem 0.5rem 0.25rem 0.375rem;
  border-radius: 1rem;
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.s};
  letter-spacing: -0.01em;
  color: #303742;
  margin-left: 0.5rem;

  & > span {
    margin-left: 0.25rem;
    margin-top: 1.5px;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.859rem 1.15625rem;
  /* border-top: 1px solid #3037421A; descomentar apos MOVE*/
  border-bottom: 1px solid #3037421a;

  & div {
    display: flex;
    align-items: center;
  }
  /* REMOVER APOS MOVE EVENT */
  @media (max-width: 900px) {
    flex-direction: column;

    & > div:nth-child(1) {
      padding-bottom: 1rem;
      flex-wrap: wrap;
    }

    & > div:nth-child(2) {
      border-top: 1px solid #767f8d1a;
      padding-top: 1rem;
    }
  }
  /* REMOVER APOS MOVE EVENT */
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.75rem;

  & span {
    font-size: ${defaultTheme.fontSize.s};
    line-height: 18.85px;
    letter-spacing: -0.01em;
    color: #303742;
  }
`;

export const Cell = styled.div`
  position: relative;
  height: 52px;
  border-left: 1px solid #3037421a;
`;

export const CellContent = styled.div`
  min-width: ${({ blockWidth }) => blockWidth}px;
  width: ${({ blockWidth }) => blockWidth}px;
  height: 12px;
  position: absolute;
  left: ${({ blockLeftOffset }) => blockLeftOffset}px;
  top: 20px;
  display: inline-block;
  overflow: visible;
  background: ${(props) => gradientOsColors[props.blockType]};
  color: white;
  padding: 0.25rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  z-index: 999999;
`;

export const CellContentLunchArea = styled.div`
  height: 53px;
  min-width: ${({ blockWidth }) => blockWidth}px;
  width: ${({ blockWidth }) => blockWidth}px;
  position: absolute;
  left: ${({ blockLeftOffset }) => blockLeftOffset}px;
  overflow: visible;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 12px;
    left: 23px;
    color: ${defaultTheme.colors.grey400};
    font-size: ${defaultTheme.fontSize.xs};
    letter-spacing: -0.01em;
    line-height: 14px;

    svg {
      fill: #a6aeba;
    }
  }
`;

export const GridImageLunch = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProgressBarAux = styled.div`
  width: calc(100% + 25px);
  height: 4px;
  position: absolute;
  left: 0;
  top: 24px;
  display: inline-block;
  overflow: visible;
  background: #767f8d1a;
  color: white;
  white-space: nowrap;
  z-index: 99999 !important;
  margin-left: -25px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: ${defaultTheme.fontSize.s};
  color: #fff;
  margin-top: 0.5rem;
  line-height: 0.9rem;
  letter-spacing: -0.01em;
  line-height: 1.178125rem;

  &:first-child {
    margin-top: 0;
  }

  & > span {
    margin-left: 0.25rem;
  }
`;

export const Title = styled.div`
  margin: -11px 0;
`;

export const SubTitle = styled.div`
  color: ${defaultTheme.colors.grey500};
  font-size: ${defaultTheme.fontSize.m};
  line-height: 22px;
  letter-spacing: -0.01em;
  font-weight: 400;
`;

export const SkeletonTimeline = styled(Skeleton.Button)`
  min-height: 395.33px !important;
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
`;

export const AlertBalloon = styled.div`
  padding: 1rem 1.5rem;
  border-top: 1px solid rgba(48, 55, 66, 0.1);
`;

export const AlertBalloonContent = styled.div`
  display: flex;
  background-color: ${defaultTheme.colors.orange10};
  border: 1.5px solid ${defaultTheme.colors.orange10};
  border-radius: 0.75rem;
  padding: 1rem;
  /* margin-bottom: 1.5rem; */

  & > svg {
    margin-right: 0.75rem;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;

    & > span {
      color: ${defaultTheme.colors.grey700};
      font-size: ${defaultTheme.fontSize.s};
      line-height: 18px;
      letter-spacing: -0.01em;

      &:first-child {
        font-size: ${defaultTheme.fontSize.m};
        font-weight: 600;
        margin-bottom: 4px;
        letter-spacing: -0.02em;
      }
    }
  }
`;

export const NoDataCard = styled.div`
  height: 395.34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
  border-top: 1px solid ${defaultTheme.colors.grey70};

  & > span {
    color: ${defaultTheme.colors.grey700};
    font-size: ${defaultTheme.fontSize.m};
    line-height: 21.75px;
    letter-spacing: -0.01em;
    margin-top: 1.5rem;
    text-align: center;
  }
`;

export const TitleToolTip = styled.div`
  color: #fff;
  font-size: ${defaultTheme.fontSize.s};
  line-height: 19px;
  letter-spacing: -0.01em;
`;

export const LoadingDistrictalCard = styled.div`
  height: 395.34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${defaultTheme.colors.grey70};

  & > div:last-child {
    margin-top: 1.5rem;
    font-size: ${defaultTheme.fontSize.m};
    color: #303742;
    text-align: center;
    font-size: 0.9375rem;
    line-height: 145%;
    letter-spacing: -0.00938rem;
  }
`;

export const SpinBg = styled.div`
  width: 66px;
  height: 66px;
  position: absolute;
  background: transparent;
  border: 12.5px solid #e3e3e3;
  border-radius: 50%;
  margin-bottom: 46px;
`;

export const Loading = styled.div`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;

  border: 9px solid #008e5a;
  border-left: 9px solid #e3e3e3;

  animation-name: ${rodando};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;
