import React, { useState } from "react";
import CloseIcon from "@assets/icons/CloseIcon";
import Warning from "@assets/icons/Warning.svg";
import BottomArrow from "@assets/icons/BottomArrow";

import * as S from "./styles";

const ErrorStep = ({
  setIsModalOpen,
  setStep,
  defineRoute,
  totalOss,
  errorText,
  setErrorsOss,
}) => {
  const [isMessageOpen, setIsMessageOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setStep("firstStep");
    setErrorsOss([]);
  };

  return (
    <>
      <S.Header>
        Erro
        <CloseIcon size={20} fill="#303742" onClick={closeModal} />
      </S.Header>
      <S.Content dontCenter>
        <S.Warning src={Warning} />

        <span>
          {`Não foi possível concluir a simulação completa pois encontramos erros em ${errorText.length} de ${totalOss} OSs.`}
        </span>

        <span onClick={() => setIsMessageOpen((p) => !p)}>
          Mostrar erros
          <BottomArrow size={16} fill="#767F8D" />
        </span>

        {isMessageOpen && (
          <span>
            <div>
              {errorText.map((msg, i) => (
                <p key={i}>{msg}</p>
              ))}
            </div>
          </span>
        )}

        <div>
          <S.ModalButton onClick={closeModal}>Cancelar</S.ModalButton>
          <S.ModalButton
            onClick={() => {
              setStep("secondStep");
              defineRoute();
            }}
          >
            Tentar novamente
          </S.ModalButton>
        </div>
      </S.Content>
    </>
  );
};

export default ErrorStep;
