import { Button } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const StyledButton = styled(Button)`
  margin: ${(props) => (props.margin ? props.margin : "8px 0")};
  height: ${(props) => (props.large ? "40px" : props.small ? "32px" : "36px")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "auto")};
  border-radius: 24px;
  background: ${(props) =>
    props.styled
      ? Colors.white
      : props.active
      ? Colors.textGreen
      : Colors.lightGreen};
  text-transform: ${(props) => (props.large ? "uppercase" : "none")};
  letter-spacing: ${(props) => (props.large ? "1.2px" : ".8px")};
  font-weight: ${(props) => (props.large ? "800" : "600")};
  box-shadow: ${(props) =>
    props.shadow ? "2px 2px 8px 0 rgb(0 0 0 / 20%);" : "none"};
  cursor: pointer;
  svg path {
    transition: 1s;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: ${Colors.textGreen};

    color: ${Colors.white};
    ${(props) => props.styled && `border: 2px solid ${Colors.lightGreen};`}
    svg path {
      fill: ${Colors.lightGreen};
    }
  }
  &:focus {
    background: ${(props) =>
      props.styled ? Colors.lightGreen : Colors.textGreen} !important;
    border-color: ${Colors.lightGreen} !important;
    color: ${Colors.white} !important;
  }
`;
