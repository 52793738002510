import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import Colors from "@colors";

export default function AlertasPolo(props) {
  const title = "Alertas";
  const [alertasPolo, setAlertasPolo] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getAlertasPolo(props.polo).then((response) => {
      setAlertasPolo(response.data);
      setLoaded(true);
    });
  }, [props.polo]);

  const dados = [];
  const colunas = [
    {
      label: "Motivo",
      key: "motivo",
      color: ["Risco de perda", Colors.lightRed],
      align: "start",
      sort: {
        compare: (a, b) => a.motivo.localeCompare(b.motivo),
      },
    },
    {
      label: "Contratante",
      key: "contratante",
      sort: {
        compare: (a, b) => a.contratante.localeCompare(b.contratante),
      },
    },
    {
      label: "Serial",
      key: "serial",
      sort: {
        compare: (a, b) => a.serial.localeCompare(b.serial),
      },
    },
    {
      label: "Tecnologia",
      key: "tecnologia",
      sort: {
        compare: (a, b) => a.tecnologia.localeCompare(b.tecnologia),
      },
    },
    {
      label: "Status",
      key: "status",
      sort: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
    },
    {
      label: "Data de modificação",
      key: "data_modificacao",
    },
  ];
  const downloadLabels = [
    "Motivo",
    "Contratante",
    "Serial",
    "Tecnologia",
    "Status",
    "Data de modificação",
  ];
  const downloadData = [];

  alertasPolo.forEach((item) => {
    const row = {};
    row["motivo"] = item.motivo;
    row["contratante"] = item.contratante;
    row["serial"] = item.serial;
    row["tecnologia"] = item.tecnologia;
    row["status"] = item.status;
    row["data_modificacao"] = item.data_modificacao;
    dados.push(row);
    const rowDownload = [];
    rowDownload.push(item.motivo);
    rowDownload.push(item.contratante);
    rowDownload.push(item.serial);
    rowDownload.push(item.tecnologia);
    rowDownload.push(item.status);
    rowDownload.push(item.data_modificacao);
    downloadData.push(rowDownload);
  });

  const tableData = {
    data: dados,
    columns: colunas,
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title + " - " + props.name}
      info={[`Alertas ${props.name}`]}
      downloadLabels={loaded ? downloadLabels : []}
      downloadData={loaded ? downloadData : []}
    >
      {loaded ? (
        <InfoTable data={tableData} pagination search />
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
}
