import React, { useEffect, useState } from "react";
import { DatePicker, message, Form, Button } from "antd";
import moment from "moment";
import Container from "@components/Container";
import Row from "@components/Row";
import InfoTable from "@components/InfoTable";
import { usePaginationConfig } from "@hooks/usePaginationConfig";
import SelectBox from "@components/SelectBox";
import CustomButton from "@components/CustomButton";
import LogisticService from "@LogisticService";
import LoadingSpinner from "@components/LoadingSpinner";
import { TableArea } from "./styles";
import { hubsSelector } from "../../../../../features/senninha/hubsSlice";
import { useSelector, useDispatch } from "react-redux";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { setOldSenninhaWorkFlow } from "../../../../../features/senninha/oldSenninhaSlice";

export default function MotivosAtraso() {

  const {
    selectedHubInfos
  } = useSelector(hubsSelector);

  const dispatch = useDispatch()

  const getMotivos = (polo) => {
    return LogisticService.getMotivosAtrasoDistrital(polo)
  }

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [defectOptions, setDefects] = useState([]);
  const [solutionOptions, setSolutions] = useState([]);
  const [availableAngels, setAngels] = useState([]);
  const [tabelaDistrital, setTabelaDistrital] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [toggleDetalhe, setToggleDetalhe] = useState(true);
  const [detalhesTeste, setDetalhesTeste] = useState([]);
  const [downloadLabels, setDownloadLabels] = useState([
    "OS",
    "Stonecode",
    "Nova Data",
    "Motivo Atraso",
    "Detalhe",
    "tecnologia",
    "Estabelecimento",
    "Contratante",
    "Cidade",
    "UF",
    "Data Limite",
    "Data Limite Cliente",
    "Grupo de Serviço",
  ]);
  const [downloadData, setDownloadData] = useState([]);
  const [dadosSelecionado, setDadosSelecionado] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const recarregarAtrasos = React.useCallback(() => {
    getMotivos(selectedHubInfos.children);
  }, []);
  const paginationConfig = usePaginationConfig();

  const onChangeMotivo = (value, name) => {
    let motivo = name["children"];
    setToggleDetalhe(false);
    setDetalhesTeste(detalhes[motivo]);
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current < moment().subtract(1, "days").endOf("day");
  }

  function onChangeDate(date, dateString) {
    var data = new Date(dateString);
    var dataFormatada =
      data.getFullYear() + "/" + (data.getMonth() + 1) + "/" + data.getDate();
    return moment(dataFormatada, "YYYY/MM/DD"), "novo_prazo";
  }

  const motivos = [
    { name: "Erro em planejar", id: 0 },
    { name: "Erro em executar", id: 1 },
    { name: "Outros", id: 2 },
  ];

  const detalhes = {
    "Erro em planejar": [
      { name: "Stockout de equipamentos" },
      { name: "Demanda acima da capacidade" },
      { name: "Absenteísmo de Angel" },
    ],
    "Erro em executar": [
      { name: "Erro de alocação de técnico" },
      { name: "Não registrou ineficiência" },
      { name: "Desorganização na rotina" },
      { name: "Má interpretação da OS" },
    ],
    Outros: [
      { name: "Envio de forms para LIGA" },
      { name: "Chuvas/Alagamentos" },
      { name: "Acidente" },
      { name: "Correios" },
      { name: "Outros" },
    ],
  };

  useEffect(() => {
    getMotivos(selectedHubInfos.children).then((response) => {
      setTabelaDistrital(response.data);
      setLoaded(true);
    });
  }, []);

  const orderWithPossibleObjects = (a, b) =>
    typeof a == "string" && typeof b == "string"
      ? +(a > b) || -(a < b)
      : -(a > b) || +(a < b);

  const dados = [];
  const colunas = [
    {
      title: "OS",
      dataIndex: "os",
      width: "7%",
      label: "OS",
      key: "os",
      type: "numeric",
      editable: false,
      align: "center",
      cellStyle: { fontSize: "0.8em" },
      sort: { compare: (a, b) => a.os - b.os },
    },
    {
      title: "Stonecode",
      dataIndex: "stonecode",
      label: "Stonecode",
      key: "stonecode",
      width: "8%",
      editable: false,
      align: "center",
      sort: { compare: (a, b) => a.stonecode.localeCompare(b.stonecode) },
    },
    {
      title: "Nova Data",
      dataIndex: "novo_prazo",
      label: "Nova Data",
      key: "novo_prazo",
      type: "date",
      width: "8%",
      editable: true,
      align: "center",
      sort: {
        compare: (a, b) => orderWithPossibleObjects(a.novo_prazo, b.novo_prazo),
      },
    },
    {
      title: "Motivo Atraso",
      dataIndex: "motivo",
      label: "Motivo Atraso",
      key: "motivo",
      width: "10%",
      inputType: "select",
      selectOptions: motivos,
      editable: true,
      align: "center",
      sort: { compare: (a, b) => orderWithPossibleObjects(a.motivo, b.motivo) },
    },
    {
      title: "Detalhe",
      dataIndex: "detalhe",
      label: "Detalhe",
      key: "detalhe",
      width: "10%",
      editable: true,
      inputType: "select",
      selectOptions: detalhesTeste,
      editable: true,
      align: "center",
      sort: {
        compare: (a, b) => orderWithPossibleObjects(a.detalhe, b.detalhe),
      },
    },
    {
      title: "Tecnologia",
      dataIndex: "tecnologia",
      label: "Tecnologia",
      key: "tecnologia",
      width: "8%",
      editable: false,
      sort: {
        compare: (a, b) => orderWithPossibleObjects(a.tecnologia, b.tecnologia),
      },
    },
    {
      title: "Estabelecimento",
      dataIndex: "estabelecimento",
      label: " Estabelecimento",
      key: "estabelecimento",
      width: "8%",
      editable: false,
      sort: {
        compare: (a, b) => a.estabelecimento.localeCompare(b.estabelecimento),
      },
    },
    {
      title: "Contratante",
      dataIndex: "contratante",
      label: "Contratante",
      key: "contratante",
      width: "7%",
      editable: false,
      sort: { compare: (a, b) => a.contratante.localeCompare(b.contratante) },
    },
    {
      title: "Cidade",
      dataIndex: "cidade",
      label: "Cidade",
      key: "cidade",
      width: "7%",
      editable: false,
      sort: { compare: (a, b) => a.cidade.localeCompare(b.cidade) },
    },
    {
      title: "UF",
      dataIndex: "uf",
      label: "UF",
      key: "uf",
      width: "7%",
      editable: false,
      align: "center",
      sort: { compare: (a, b) => a.uf.localeCompare(b.uf) },
    },
    {
      title: "Bairro",
      dataIndex: "bairro",
      label: "Bairro",
      key: "bairro",
      width: "7%",
      editable: false,
      align: "center",
      sort: { compare: (a, b) => a.bairro.localeCompare(b.bairro) },
    },
    {
      title: "Data Limite",
      dataIndex: "data_limite",
      label: "Data Limite",
      key: "data_limite",
      type: "date",
      width: "7%",
      editable: false,
      sort: { compare: (a, b) => a.data_limite.localeCompare(b.data_limite) },
    },
    {
      title: "Data Limite Cliente",
      dataIndex: "data_limite_c",
      label: "Data Limite Cliente",
      key: "data_limite_c",
      type: "date",
      width: "7%",
      editable: false,
      sort: {
        compare: (a, b) => a.data_limite_c.localeCompare(b.data_limite_c),
      },
    },
    {
      title: "Grupo de Serviço",
      dataIndex: "grupo_servico",
      label: "Grupo de Serviço",
      key: "grupo_servico",
      width: "7%",
      editable: false,
      sort: {
        compare: (a, b) => a.grupo_servico.localeCompare(b.grupo_servico),
      },
    },
  ];

  tabelaDistrital.forEach((item, index) => {
    let row = {};
    row["key"] = index;
    row["os"] = item.os;
    row["stonecode"] = item.stonecode;
    row["novo_prazo"] = item.novo_prazo;
    row["motivo"] = item.motivo;
    row["detalhe"] = item.detalhe;
    row["tecnologia"] = item.tecnologia;
    row["estabelecimento"] = item.estabelecimento;
    row["contratante"] = item.contratante;
    row["cidade"] = item.cidade;
    row["uf"] = item.uf;
    row["bairro"] = item.bairro;
    row["data_limite"] = item.data_limite;
    row["data_limite_c"] = item.data_limite_c;
    row["grupo_servico"] = item.grupo_servico;
    dados.push(row);
    const rowDownload = [];
    rowDownload.push(item.os);
    rowDownload.push(item.stonecode);
    rowDownload.push(item.novo_prazo);
    rowDownload.push(item.motivo);
    rowDownload.push(item.detalhe);
    rowDownload.push(item.tecnologia);
    rowDownload.push(item.estabelecimento);
    rowDownload.push(item.contratante);
    rowDownload.push(item.cidade);
    rowDownload.push(item.uf);
    rowDownload.push(item.bairro);
    rowDownload.push(item.data_limite);
    rowDownload.push(item.data_limite_c);
    rowDownload.push(item.grupo_servico);
    downloadData.push(rowDownload);
  });

  console.log(selectedHubInfos.children)
  const justificarMotivo = (values) => {
    dadosSelecionado.map((item, index) => {
      item["detalhe"] = values.detalhe;
      item["motivo"] = values.motivo;
      item["novo_prazo"] = moment(values.novo_prazo).format("YYYY/MM/DD");
      item["usuario"] = window.localStorage["log_email"];
    });

    LogisticService.postMotivosAtrasoDistrital("", dadosSelecionado).then(
      () => {
        const validador = dadosSelecionado[0]["detalhe"];
        if (validador) {
          getMotivos(selectedHubInfos.children).then((response) => {
            console.log(response)
            setTabelaDistrital(response.data);
            setLoaded(true);
            setDadosSelecionado([]);
            recarregarAtrasos();
          });
          setSelectedRowKeys([]);
          message.success("Novo Prazo determinado com sucesso.");
        } else {
          message.error({ erro: "Erro ao inserir, tente novamente." });
        }
      }
    );
  };

  const selects = [
    <Form form={form} onFinish={justificarMotivo}>
      <Row verticalAlign="center" horizontalAlign="start">
        <Form.Item
          name="motivo"
          rules={[{ required: true, message: "O Motivo é obrigatório!" }]}
        >
          <SelectBox
            placeholder="Motivo Atraso"
            width={400}
            options={motivos}
            onChange={onChangeMotivo}
            valueIsName
          />
        </Form.Item>
        <Form.Item
          name="detalhe"
          rules={[{ required: true, message: "O Detalhe é obrigatório!" }]}
        >
          <SelectBox
            placeholder="Detalhe"
            width={400}
            options={detalhesTeste}
            disabled={toggleDetalhe}
            valueIsName
          />
        </Form.Item>
        <Form.Item
          name="novo_prazo"
          rules={[{ required: true, message: "A Nova Data é obrigatório!" }]}
        >
          <DatePicker
            style={{ width: 400, marginLeft: 10 }}
            onChange={onChangeDate}
            disabledDate={disabledDate}
            format={"YYYY/MM/DD"}
            valueIsName
          />
        </Form.Item>
        <CustomButton
          style={{ marginLeft: "auto" }}
          htmlType="submit"
          disabled={dadosSelecionado?.length === 0}
        >
          Registrar
        </CustomButton>
      </Row>
    </Form>,
  ];

  const tableData = {
    data: dados,
    columns: colunas,
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setDadosSelecionado([...selectedRows]);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (

    <TableArea>
      <Container
        downloadLabels={loaded ? downloadLabels : []}
        downloadData={loaded ? downloadData : []}
        rounded
      >
        <GingaIcon
          name="close"
          color="#303742"
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(setOldSenninhaWorkFlow(''))}
        />
        {selects}
        {loaded ? (
          <InfoTable
            data={tableData}
            rowSelection={{ type: "checkbox", ...rowSelection }}
            search
            pagination={paginationConfig}
            scroll={{ x: "95vw", y: "46vh" }}
            size={"small"}
          />
        ) : (
          <LoadingSpinner />
        )}
      </Container>
    </TableArea>
  );
}
