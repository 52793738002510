import { Select } from "antd";
import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const ContentSelect = styled.div`
  position: relative;
  height: 3.8rem;
  width: 100%;
  
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector span {
    color: ${defaultTheme.colors.grey700};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .ant-select {
    margin: 0rem !important;
    width: 100% !important;
    display: flex;
  }

  .ant-select-selector {
    padding: 0.85rem 0.625rem 0.625rem 0.625rem !important;
    height: 100% !important;
    width: 100% !important;
    right: 0 !important;
    border: 0px !important;
    background-color: #ffffff !important;
  }

  .ant-select-selection-search-input {
    height: 100%;
  }

  .ant-select-selection-item {
    padding-left: 10px !important;
    letter-spacing: -0.01em;
    font-weight: 400 !important;
    font-size: ${defaultTheme.fontSize.m};
  }

  .ant-select-suffix {
    margin-top: 0.75rem;
  }

  .ant-select-arrow {
    margin: 0rem !important;
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 2.2px !important;
    right: 29px !important;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    font-size: 17px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
  }

  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    margin: 6px 0px 0px 0px;
  }

  .ant-select-selection-search {
    margin-left: 10px !important;
  }

  .ant-select-selection-placeholder {
    padding: 0 !important;
    padding-left: 10px !important;
    color: ${defaultTheme.colors.grey500} !important;
    font-size: ${defaultTheme.fontSize.m} !important;
    letter-spacing: -0.01em;
    margin-top: 0 !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #fff0;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #fff0;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 7.7px;
    top: 2px;
  }
`;

export const SelectField = styled(Select)`
  border-radius: 0.5rem;
  border: 1px solid ${defaultTheme.colors.grey400} !important;
  font-size: ${defaultTheme.fontSize.m};
  height: 44px;
  padding: 0 !important;
  min-width: 9.375rem;
  width: 100%;
`;

export const SelectLabel = styled.span`
  background-color: ${defaultTheme.colors.white};
  color: ${defaultTheme.colors.grey400};
  font-weight: 600;
  position: absolute;
  z-index: 999;
  left: 1rem;
  top: -0.55rem;
  padding: 0 0.25rem;
  white-space: nowrap;
`;
