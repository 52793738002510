import React, { useState, useEffect } from "react";
import { Tooltip, Space } from "antd";
import EyeIcon from "@assets/icons/EyeIcon";
import BottomArrow from "@assets/icons/BottomArrow";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import OutlinedPlusIcon from "@assets/icons/OutlinedPlusIcon";
import { ClosedEye } from "@assets/icons/ClosedEye";
import FloatLabel from "@components/FloatLabel";
import SearchBox from "@components/SearchBox";
import { FooterButtons } from "@components/FooterButtons";
import Colors from "@colors";
import WarningIcon from "@assets/icons/WarningIcon";
import { svgMarker, svgPolygon } from "../../../..";
import { useWindowSize } from "../../../../../../../hooks/useWindowSize"; // remover apos move

import * as S from "./styles";

const SimulationLegend = (props) => {
  const [legendData, setLegendData] = useState(null);
  const [filteredLegendData, setFilteredLegendData] = useState(props.data);
  const [legendDataOverPins, setLegendDataOverPins] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [todasOSsVisiveis, setTodasOSsVisiveis] = useState(true);
  const [searchOptions, setSearchOptions] = useState([]);

  const { width } = useWindowSize(); // remover apos move

  useEffect(() => {
    const filteredData = props.data.filter(
      (item) =>
        item.oss.length > 25 &&
        props.angelsDisponiveis
          .filter((item) => item.name !== "DELIVERY")
          .some((angel) => angel.name === item.nome)
    );

    setLegendDataOverPins(filteredData.length > 0 ? filteredData : null);
    setFilteredLegendData(
      props.data.filter((item) => !filteredData.includes(item))
    );
  }, [props.data, legendData]);

  useEffect(() => setLegendData(props.data), [props.data]);

  useEffect(() => {
    if (legendData) {
      setMarkers(props.pins);
      let options = [];
      legendData.map(
        (angel) =>
          angel.oss &&
          angel.oss.map((osGroup) =>
            options.push({ value: osGroup.stonecode, content: osGroup })
          )
      );
      setSearchOptions(options);
    }
  }, [props.pins, legendData]);

  const toggleDetalhes = (index) =>
    clicked === index ? setClicked(null) : setClicked(index);

  const toggleOSsVisiveis = (data, index) => {
    data[index].visivel = !data[index].visivel;
    const angel = data[index].nome;
    markers.map((marker) => {
      if (marker.oss[0].angel === angel) {
        marker.visivel = !marker.visivel;
      }
    });
    props.setPins([...markers]);
  };

  const toggleTodasOSsVisiveis = (bool) => {
    let angelsMap = [];
    legendData.map((legendaItem) => {
      legendaItem.visivel = bool;
      angelsMap.push(legendaItem.nome);
    });
    markers.map((marker) => {
      if (angelsMap.includes(marker.oss[0].angel)) {
        marker.visivel = bool;
      }
    });
    props.setPins([...markers]);
  };

  const centralizarMapa = (selectedOnSearch) => {
    const stonecodeInfo = searchOptions.find(
      (obj) => obj.value === selectedOnSearch
    );
    if (stonecodeInfo !== undefined) {
      props.setCenter({
        lat: stonecodeInfo.content.latitude,
        lng: stonecodeInfo.content.longitude,
      });
      props.setZoom(18);
      props.setAnimacaoPin(stonecodeInfo.content.id);
    }
  };

  useEffect(() => {
    if (props.animacaoPin) {
      const timer = setTimeout(() => props.setAnimacaoPin(null), 1400);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [props.animacaoPin]);

  const toggle = () => {
    toggleTodasOSsVisiveis(!todasOSsVisiveis);
    setTodasOSsVisiveis(!todasOSsVisiveis);
  };

  const renderLegend = (data, overLimitPins = false) =>
    data &&
    data.map((angel, index) => (
      <S.LegendaItem key={index}>
        <S.Header>
          <S.Title>
            <img
              src={
                angel.icon.pin
                  ? svgMarker(
                      "",
                      angel.icon.cor,
                      angel.icon.stroke,
                      angel.icon.opacity
                    )
                  : svgPolygon(angel.icon.cor)
              }
            />
            <div>
              <S.Text>{angel.nome}</S.Text>
              {angel.oss && (
                <S.OSCount overLimitPins={overLimitPins}>
                  {`(${angel.oss.length} visitas - ${angel.oss_count} OS)`}
                </S.OSCount>
              )}
            </div>
          </S.Title>
          {angel.visibilidade && (
            <>
              <Space key={index}>
                <S.OSsVisiveisButton
                  onClick={() => toggleOSsVisiveis(data, index)}
                >
                  {angel.visivel ? (
                    <EyeIcon
                      size={20}
                      style={{ cursor: "pointer", marginTop: "1px" }}
                      fill="#000"
                    />
                  ) : (
                    <ClosedEye
                      style={{ cursor: "pointer", marginTop: "1px" }}
                      size={20}
                      fill="rgba(48, 55, 66, 1)"
                    />
                  )}
                </S.OSsVisiveisButton>
                <BottomArrow
                  key={index}
                  size={20}
                  fill="#000"
                  onClick={() => toggleDetalhes(index)}
                  open={clicked == index}
                  style={{
                    cursor: "pointer",
                    marginTop: 3,
                    transition: "all 0.5s ease",
                    transform: `rotate(${
                      clicked === index ? "0.5turn" : "1turn"
                    })`,
                  }}
                />
              </Space>
            </>
          )}
        </S.Header>
        {angel.oss && (
          <S.Detalhes open={clicked === index} key={index}>
            {angel.oss.map((osGroup, osIdx) =>
              osGroup.items.map((item, itemIdx) => (
                <S.DetalheOS key={`${osIdx}-${itemIdx}`}>
                  <S.DetalheOSNumber
                    onClick={() => {
                      centralizarMapa(osGroup.stonecode);
                      if (props.setIsVisibleLegend)
                        props.setIsVisibleLegend(false); // remover apos move
                    }}
                  >
                    {!item.order_number ||
                    ["", null, undefined].includes(item.order_number)
                      ? "ver local"
                      : item.order_number}
                  </S.DetalheOSNumber>
                  <S.DetalheStonecode>{` - ${item.cliente} (${item.servico})`}</S.DetalheStonecode>
                </S.DetalheOS>
              ))
            )}
          </S.Detalhes>
        )}
      </S.LegendaItem>
    ));

  return (
    <S.LegendCard>
      <div>
        <S.MainHeader>
          <S.LegendTitle>
            <p>Legenda</p>
            <S.OSsVisiveisButton
              visivel={todasOSsVisiveis}
              onClick={() => toggle()}
            >
              {todasOSsVisiveis ? (
                <Tooltip title="Ocultar todas" placement="top">
                  <EyeIcon
                    style={{ fontSize: "20px", color: Colors.deepBlue }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Ver todas" placement="top">
                  <ClosedEye
                    style={{ cursor: "pointer" }}
                    size={20}
                    fill="rgba(48, 55, 66, 1)"
                  />
                </Tooltip>
              )}
            </S.OSsVisiveisButton>
          </S.LegendTitle>
          <S.LegendSubtitle>
            Visualize os detalhes e informações dos pins mostrados no mapa.
          </S.LegendSubtitle>
          <S.LegendSearchContent>
            <FloatLabel label={"Stonecode"} marginBottom={"0rem"}>
              <SearchBox
                placeholder={"Pesquise aqui"}
                options={searchOptions}
                onSelect={centralizarMapa}
                iconOnRight
              />
            </FloatLabel>
          </S.LegendSearchContent>
        </S.MainHeader>

        <S.Items>
          {legendDataOverPins && (
            <S.OverLimitPinsArea>
              <S.AlertBalloon>
                <div>
                  <WarningIcon color={"#F38428"} size={24} />
                </div>
                <div>
                  <span>
                    {`${
                      legendDataOverPins.length > 1
                        ? `Existem ${legendDataOverPins.length} pins`
                        : `Existe ${legendDataOverPins.length} pin`
                    } 
                                        com visitas excedidas`}
                  </span>
                  <span>
                    Para realizar uma simulação, cada pin deve possuir no máximo
                    25 visitas. Realoque as visitas dos pins abaixo para
                    conseguir simular.
                  </span>
                </div>
              </S.AlertBalloon>

              {renderLegend(legendDataOverPins, true)}
            </S.OverLimitPinsArea>
          )}

          {renderLegend(filteredLegendData)}
        </S.Items>
      </div>
      {width > 900 ? ( // remover apos move, deixar só o de cima
        <S.BottomLegendContent>
          <FooterButtons
            nameFirstButton={"Simular"}
            customWidth={"100%"}
            onClickFirstButton={props.postSimulation}
            firstbuttonDisabled={legendDataOverPins}
            firstbuttonLoading={props.loading}
          />
        </S.BottomLegendContent>
      ) : (
        <S.BottomLegendContent firstButtonGray={width < 900}>
          <FooterButtons
            nameFirstButton={"Ocultar Legenda"}
            customWidth={"100%"}
            onClickFirstButton={() => props.setIsVisibleLegend(false)}
          />
        </S.BottomLegendContent>
      )}
    </S.LegendCard>
  );
};

export default SimulationLegend;
