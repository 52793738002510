import { GenIcon } from "react-icons";

function FilterAltIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 16 16",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M13.2495 4C12.9535 2.84985 11.9094 2 10.6668 2C9.42244 2 8.37713 2.85235 8.08287 4.00508C8.05577 4.00173 8.02817 4 8.00016 4H2.00016C1.63197 4 1.3335 4.29848 1.3335 4.66667C1.3335 5.03486 1.63197 5.33333 2.00016 5.33333H8.00016C8.02817 5.33333 8.05577 5.3316 8.08287 5.32825C8.37713 6.48098 9.42244 7.33333 10.6668 7.33333C11.9094 7.33333 12.9535 6.48349 13.2495 5.33333H14.0002C14.3684 5.33333 14.6668 5.03486 14.6668 4.66667C14.6668 4.29848 14.3684 4 14.0002 4H13.2495ZM9.3335 4.66667C9.3335 3.93029 9.93045 3.33333 10.6668 3.33333C11.4032 3.33333 12.0002 3.93029 12.0002 4.66667C12.0002 5.40305 11.4032 6 10.6668 6C9.93045 6 9.3335 5.40305 9.3335 4.66667Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M14.6668 11.3333C14.6668 10.9651 14.3684 10.6667 14.0002 10.6667H8.00016C7.97216 10.6667 7.94455 10.6684 7.91745 10.6717C7.62319 9.51902 6.57789 8.66667 5.3335 8.66667C4.09094 8.66667 3.04687 9.51652 2.75084 10.6667H2.00016C1.63197 10.6667 1.3335 10.9651 1.3335 11.3333C1.3335 11.7015 1.63197 12 2.00016 12H2.75084C3.04687 13.1502 4.09094 14 5.3335 14C6.57789 14 7.62319 13.1476 7.91745 11.9949C7.94455 11.9983 7.97216 12 8.00016 12H14.0002C14.3684 12 14.6668 11.7015 14.6668 11.3333ZM4.00016 11.3333C4.00016 12.0697 4.59712 12.6667 5.3335 12.6667C6.06988 12.6667 6.66683 12.0697 6.66683 11.3333C6.66683 10.597 6.06988 10 5.3335 10C4.59712 10 4.00016 10.597 4.00016 11.3333Z",
        },
      },
    ],
  })(props);
}

export default FilterAltIcon;
