import React, { useEffect, useState } from "react";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import { LoadingOutlined } from "@ant-design/icons";
import Container from "@components/Container";
import Contato from "./Contato";

export default function InformacoesContatoHunter(props) {
  const loading = <LoadingOutlined spin />;
  const [loaded, setLoaded] = useState(false);
  const [contatos, setContatos] = useState("");

  useEffect(() => {
    setLoaded(false);
    LogisticService.getInformacoesGerais(props.stonecode).then((response) => {
      if (response && response.data !== null) {
        setContatos(response.data.contacts);
        setLoaded(true);
      }
    });
  }, [props.stonecode]);

  return (
    <Container
      title={`Informações de Contato`}
      icon={"info-circle"}
      grid={1}
      expandable
    >
      {loaded ? (
        <Row margin={"0 8px 8px"} horizontalScroll>
          {contatos.map((dados, index) => {
            return <Contato dados={dados} index={index + 1}></Contato>;
          })}
        </Row>
      ) : (
        <Row
          horizontalAlign={"center"}
          width={"100%"}
          padding={"8px"}
          margin={"0 16px 16px"}
        >
          {loading}
        </Row>
      )}
    </Container>
  );
}
