export const breadcrumbNameMap = {
  ferramentas: "Ferramentas",
  "operacao-terceira": "Gestão terceira",
  "motivos-atraso": "Motivos de atraso",
  "cobertura-estoque": "Cobertura de estoque",
  anexos: "Anexos OS/OM",
  "consulta-doa": "Consulta de report DOA",
  "report-doa-terceira": "Reportar DOA",
  senninha: "Senninha",
  cockpit: "Cockpit Distrital",
};

export const breadcrumbRouteMap = {
  "/ferramentas": "/",
  "/lmp": "/",
  "/lmp/senninha": "/senninha",
};

export const breadcrumbBlackList = ["lmp"];
