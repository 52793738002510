import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const Container = styled.div`
  margin-bottom: 24px;
  .page-header-content {
    display: flex;
    flex-direction: column;
  }

  .page-title {
    color: ${defaultTheme.colors.grey700};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.02em;
    margin: 0;
  }

  .row.row-page-header {
    display: flex;
    justify-content: flex-start;
  }
`;

export const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: ${defaultTheme.colors.grey50};
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${defaultTheme.colors.grey60};
  }
`;
