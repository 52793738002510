import { Descriptions } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";

export default function DetalhesOS(props) {
  const [detalhesOS, setDetalhesOS] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const fieldList = {
    arrival_date: "Data de atendimento",
    cancellation_reason: "Motivo de cancelamento",
    city: "Cidade",
    class: "Classe",
    contractor: "Contratante",
    country: "País",
    country_state: "Estado",
    customer: "Codigo do cliente",
    customer_name: "Nome do cliente",
    customer_deadline_date: "Data limite cliente",
    deadline_date: "Data limite",
    defect: "Defeito",
    installed_terminal_model: "Modelo do terminal instalado",
    installed_terminal_sak: "ID do terminal instalado",
    installed_terminal_serial_number: "Numero de serie do terminal instalado",
    installed_terminal_type: "Tipo de equipamento instalado",
    kit_name: "Tipo de Kit",
    kit_quantity: "Quantidade de Kits",
    last_modified_date: "Data de modificação",
    logistic_operator: "Operador logístico",
    opening_date: "Data de abertura",
    order_number: "Número da OS",
    project: "Projeto",
    neighborhood: "Bairro",
    provider: "Prestador",
    service_group: "Grupo de serviço",
    service_type: "Tipo de serviço",
    status: "Status",
    solution: "Solução",
    technician_name: "Técnico",
    total_visits: "Numero de visitas",
    uninstalled_terminal_model: "Modelo do terminal desinstalado",
    uninstalled_terminal_sak: "ID do terminal desinstalado",
    uninstalled_terminal_serial_number:
      "Número de serie do terminal desinstalado",
    uninstalled_terminal_type: "Tipo de equipamento desinstalado",
    visit_reason_1: "Motivo de cancelamento da 1ª visita",
    visit_reason_2: "Motivo de cancelamento da 2ª visita",
    rav_status: "Antecipação Automática",
  };

  useEffect(() => {
    LogisticService.getDetalhesOS(props.os).then((response) => {
      let detalhes = [];
      for (const [key, value] of Object.entries(fieldList)) {
        if (response.data[key]) {
          detalhes.push(
            <Descriptions.Item label={value}>
              {response.data[key]}
            </Descriptions.Item>
          );
        }
      }
      setDetalhesOS(detalhes);
      setLoaded(true);
    });
  }, [props.os]);

  return (
    <Box maxHeight={"600px"} margin={"0"} padding={"0"} scroll>
      {loaded ? (
        <Descriptions bordered column={1} size={"small"}>
          {detalhesOS}
        </Descriptions>
      ) : (
        <LoadingSpinner />
      )}
    </Box>
  );
}
