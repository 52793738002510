import React from "react";
import { BoxContainer, Title } from "./styles";
import { useJsonToCsv } from "react-json-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import Colors from "@colors";

const Box = (props) => {
  const { saveAsCsv } = useJsonToCsv();

  const filename = props.title,
    fields = props.downloadLabels || [],
    data = props.downloadData || [];

  const downloadClick = (e) => {
    e.stopPropagation();
    saveAsCsv({ data, fields, filename });
  };

  return (
    <BoxContainer
      color={props.color}
      verticalAlign={props.verticalAlign}
      horizontalAlign={props.horizontalAlign}
      height={props.height}
      maxHeight={props.maxHeight}
      maxWidth={props.maxWidth}
      downloadData={props.downloadData}
      downloadLabels={props.downloadLabels}
      margin={props.margin}
      padding={props.padding}
      scroll={props.scroll}
      width={props.width}
      widthMediumScreenSize={props.widthMediumScreenSize}
      widthSmallScreenSize={props.widthSmallScreenSize}
      noFlex={props.noFlex}
      scrollWithSearchMenuSize={props.search}
      id={props.id}
    >
      {props.title || props.downloadData ? (
        <Title hasDownload={props.hasDownload}>
          <Space>
            {props.title}
            {props.posfix ? props.posfix : <></>}
            {props.downloadData ? (
              <Tooltip title="Download" placement="bottom">
                <DownloadOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={downloadClick}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </Space>
        </Title>
      ) : (
        ""
      )}
      {props.children}
    </BoxContainer>
  );
};

export default Box;
