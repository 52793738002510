import { GenIcon } from "react-icons";

function NeutralPersonIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 17 21",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M7.98957 2.5C6.33272 2.5 4.98957 3.84315 4.98957 5.5C4.98957 7.15685 6.33272 8.5 7.98957 8.5C9.64643 8.5 10.9896 7.15685 10.9896 5.5C10.9896 3.84315 9.64643 2.5 7.98957 2.5ZM2.98957 5.5C2.98957 2.73858 5.22815 0.5 7.98957 0.5C10.751 0.5 12.9896 2.73858 12.9896 5.5C12.9896 8.26142 10.751 10.5 7.98957 10.5C5.22815 10.5 2.98957 8.26142 2.98957 5.5Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M0 17.2636C0 14.6565 2.11351 12.543 4.72067 12.543H11.2933C13.9005 12.543 16.014 14.6565 16.014 17.2636C16.014 19.0488 14.5668 20.496 12.7816 20.496H3.2324C1.44719 20.496 0 19.0488 0 17.2636ZM4.72067 14.543C3.21808 14.543 2 15.7611 2 17.2636C2 17.9443 2.55176 18.496 3.2324 18.496H12.7816C13.4622 18.496 14.014 17.9443 14.014 17.2636C14.014 15.7611 12.7959 14.543 11.2933 14.543H4.72067Z",
        },
      },
    ],
  })(props);
}

export default NeutralPersonIcon;
