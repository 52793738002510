import React, { useEffect, useState } from "react";
import { Button, Drawer } from "@stonelog/stonelog-design-system";
import { TagCustom } from "@components/TagCustom";
import { DatePickerCustom } from "@components/DatePickerCustom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { pinsSelector, applyFilter, clearFilter, setBuildFilters, clearFilterDate } from "../../../../../../../features/senninha/pinsSlice";

import * as S from "./styles";


const FilterDrawer = () => {

    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate] = useState("");
    const [initialClientDate, setInitialClientDate] = useState("");
    const [finalClientDate, setFinalClientDate] = useState("");
    const [isMounted, setIsMounted] = useState(false);

    const [key, setKey] = useState(Date.now());

    const { pins, filters } = useSelector(pinsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(applyFilter(filters))
    }, [filters]);

    // UseEffect pra preencher o valor do datepicker quando altera entre funcionalidades
    useEffect(() => {
        const deadlineFilter = filters.find(filter => filter.field === "deadline_date");
        if (deadlineFilter && deadlineFilter.arrayDates && deadlineFilter.arrayDates.length > 0) {
            setInitialDate(deadlineFilter.arrayDates[0]);
            setFinalDate(deadlineFilter.arrayDates[deadlineFilter.arrayDates.length - 1]);
        }
    }, [filters]);

    useEffect(() => {
        const customerDeadlineFilter = filters.find(filter => filter.field === "customer_deadline_date");
        if (customerDeadlineFilter && customerDeadlineFilter.arrayClientDates && customerDeadlineFilter.arrayClientDates.length > 0) {
            setInitialClientDate(customerDeadlineFilter.arrayClientDates[0]);
            setFinalClientDate(customerDeadlineFilter.arrayClientDates[customerDeadlineFilter.arrayClientDates.length - 1]);
        }
    }, [filters]);
    //
    useEffect(() => {
        if (isMounted) {
            if (initialDate === '' && finalDate === '') {
                dispatch(clearFilterDate('deadline_date'));
            }
            if (initialDate === '') {
                setFinalDate('');
            }
        }
    }, [initialDate, isMounted]);

    useEffect(() => {
        if (isMounted) {
            if (initialClientDate === '' && finalClientDate === '') {
                dispatch(clearFilterDate('customer_deadline_date'));
            }
            if (initialClientDate === '') {
                setFinalClientDate('');
            }
        }
    }, [initialClientDate, isMounted]);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const { addDays, parseISO, format } = require("date-fns");

    function ClearAllDates() {
        setInitialDate('')
        setFinalDate('')
        setInitialClientDate('')
        setFinalClientDate('')
        setKey(Date.now())
    }

    function getDatesBetween(startDate, endDate) {
        const dates = [];
        let currentDate = parseISO(startDate);

        while (format(currentDate, "yyyy-MM-dd") <= endDate) {
            dates.push(format(currentDate, "yyyy-MM-dd"));
            currentDate = addDays(currentDate, 1);
        }

        return dates;
    }

    function onChangeInitialDate(date, dateString) {
        if (finalDate.length > 0 && dateString !== '') {
            const dates = getDatesBetween(dateString, finalDate);
            dispatch(setBuildFilters([
                ...filters,
                {
                    field: 'deadline_date',
                    value: `Data limite (${dateString} à ${finalDate})`,
                    drawer: 'filtro',
                    arrayDates: dates
                }
            ]))
        }
        if (initialDate === '') {
            setFinalDate("");
        }
        setInitialDate(dateString);
    }

    function onChangeFinalDate(date, dateString) {
        setFinalDate(dateString);
        if (dateString !== "" || initialDate !== "") {
            const dates = getDatesBetween(initialDate, dateString);
            dispatch(setBuildFilters([
                ...filters,
                {
                    field: 'deadline_date',
                    value: `Data limite (${initialDate} à ${dateString})`,
                    drawer: 'filtro',
                    arrayDates: dates
                }
            ]))
        } else {
            setFinalDate("");
            setInitialDate("");
        }
    }

    function onChangeInitialClientDate(date, dateString) {
        if (finalClientDate.length > 0 && dateString !== '') {
            const dates = getDatesBetween(dateString, finalClientDate);
            dispatch(setBuildFilters([...filters,
            {
                field: "customer_deadline_date",
                value: `Data limite do cliente (${dateString} à ${finalClientDate})`,
                drawer: 'filtro',
                arrayClientDates: dates
            }
            ]));
        }
        setInitialClientDate(dateString);
    }

    function onChangeFinalClientDate(date, dateString) {
        setFinalClientDate(dateString);
        if (dateString !== "" || initialClientDate !== "") {
            const dates = getDatesBetween(initialClientDate, dateString);
            dispatch(setBuildFilters([...filters,
            {
                field: "customer_deadline_date",
                value: `Data limite do cliente (${initialClientDate} à ${dateString})`,
                drawer: 'filtro',
                arrayClientDates: dates
            }
            ]));
        } else {
            setFinalClientDate("");
            setInitialClientDate("");
        }
    }

    const disabledDate = (current) => {
        return current && current < moment(initialDate, "YYYY-MM-DD");
    };

    const disabledClientDate = (current) => {
        return current && current < moment(initialClientDate, "YYYY-MM-DD");
    };

    const FilterSection = ({ title, attribute, data }) => {
        return (
            <div>
                <S.TitleFilterDrawer>{title}</S.TitleFilterDrawer>
                <div>
                    <TagCustom
                        tags={data.map(tag => ({ field: attribute, value: tag }))}
                        customIsSelected={(tag) => {
                            return filters.some(item => item.value === tag)
                        }}
                        activeTags={filters}
                        onClick={(tag) => {
                            const idx = filters.findIndex(
                                (obj) => obj.field === tag.field && obj.value === tag.value
                            );
                            let legendsFilters = []
                            if (idx === -1) {
                                legendsFilters = [
                                    ...filters,
                                    { field: tag.field, value: tag.value, drawer: 'filtro' },
                                ]
                            } else {
                                legendsFilters = [...filters];
                                legendsFilters.splice(idx, 1);
                            }
                            dispatch(setBuildFilters(legendsFilters))
                        }}
                        clickable
                    />
                </div>
            </div>
        );
    };

    const filterSectionsConfig = [
        {
            title: "Serviço",
            attribute: "servico",
            data: [...new Set(pins.map(pin => pin.servico))].filter(Boolean)
        },
        {
            title: "Contratante",
            attribute: "contratante",
            data: [...new Set(pins.map(pin => pin.contratante))].filter(Boolean)
        },
        {
            title: "Tecnologia",
            attribute: "tecnologias",
            data: [...new Set(pins.flatMap(pin => pin.tecnologias))].filter(Boolean)
        }
    ];

    return (
        <>
            <Drawer.Body>
                <S.AllFilterDrawerContent key={key}>
                    <div>
                        <S.TitleFilterDrawer>Data limite</S.TitleFilterDrawer>
                        <S.DatePickersArea>
                            <DatePickerCustom
                                label={"Início"}
                                placeholder={"AAAA/MM/DD"}
                                format={"YYYY-MM-DD"}
                                onChange={onChangeInitialDate}
                                value={initialDate ? moment(initialDate, "YYYY-MM-DD") : null}
                                allowClear
                            />
                            <DatePickerCustom
                                label={"Final"}
                                placeholder={"AAAA/MM/DD"}
                                format={"YYYY-MM-DD"}
                                onChange={onChangeFinalDate}
                                disabled={!initialDate.length}
                                disabledDate={disabledDate}
                                value={finalDate ? moment(finalDate, "YYYY-MM-DD") : null}
                                allowClear
                            />
                        </S.DatePickersArea>
                    </div>
                    <div>

                        <S.TitleFilterDrawer>Data limite cliente</S.TitleFilterDrawer>
                        <S.DatePickersArea>
                            <DatePickerCustom
                                label={"Início"}
                                placeholder={"AAAA/MM/DD"}
                                format={"YYYY-MM-DD"}
                                onChange={onChangeInitialClientDate}
                                value={
                                    initialClientDate
                                        ? moment(initialClientDate, "YYYY-MM-DD")
                                        : null
                                }
                                allowClear
                            />
                            <DatePickerCustom
                                label={"Final"}
                                placeholder={"AAAA/MM/DD"}
                                format={"YYYY-MM-DD"}
                                onChange={onChangeFinalClientDate}
                                disabled={!initialClientDate.length}
                                disabledDate={disabledClientDate}
                                value={
                                    finalClientDate
                                        ? moment(finalClientDate, "YYYY-MM-DD")
                                        : null
                                }
                                allowClear
                            />
                        </S.DatePickersArea>
                    </div>
                    {filterSectionsConfig.map(({ title, attribute, data }) => (
                        <FilterSection
                            key={attribute}
                            title={title}
                            attribute={attribute}
                            data={data}
                        />
                    ))}
                </S.AllFilterDrawerContent>
            </Drawer.Body>
            <Drawer.Footer>
                <Button
                    width="100%"
                    size="medium"
                    onClick={() => {
                        dispatch(clearFilter('filtro'))
                        ClearAllDates()
                    }}
                >
                    {`Limpar filtro (${filters.filter(value => value.drawer === 'filtro').length})`}
                </Button>
            </Drawer.Footer>
        </>
    )
}

export default FilterDrawer;
