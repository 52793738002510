import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const AllPage = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  background-color: #f2f4f7 !important;

  .ant-collapse-ghost {
    background-color: transparent;
    border: 0;
  }

  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 0px 8px !important;
  }

  .ant-collapse-ghost > .ant-collapse-item {
    padding-top: 0px !important;
    border-bottom: 0px !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
    background-color: transparent;
    border-top: 0;
    padding-top: 15px;
  }
`;

export const NavLinkMenu = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 8px;
  color: #767f8d;
  &:hover {
    color: #00a868;
  }
  p {
    font-size: 17px;
    letter-spacing: -0.01em;
    margin: 0;
  }
`;

export const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #767f8d;
  font-weight: 500;
  span {
    font-size: 17px;
    letter-spacing: -0.01em;
  }
`;
