import React, { useState } from "react";
import UploadIcon from "@assets/icons/UploadIcon";
import TrashIcon from "@assets/icons/TrashIcon";
import * as S from "./styles";

export const UploadButton = (props) => {
  const [fileList, setFileList] = useState([]);

  const acceptedFileType = ["image/png", "image/jpeg"];

  const onChange = ({ fileList: newFileList }) => {
    props.setImages(newFileList);
    setFileList(newFileList);
  };

  const dummyRequest = ({ onSuccess, onError, file }) => {
    setTimeout(() => {
      if (acceptedFileType.includes(file.type)) {
        onSuccess("ok");
      } else {
        onError("error");
      }
    }, 0);
  };

  return (
    <S.UploadB
      listType="picture"
      defaultFileList={[...props.images]}
      onChange={onChange}
      maxCount={props.maxCount}
      customRequest={dummyRequest}
      showUploadList={{
        removeIcon: (
          <TrashIcon
            size={20}
            color="#000000"
            style={{ marginRight: "12px", cursor: "pointer" }}
          />
        ),
      }}
      className="ginga-upload"
    >
      {fileList.length === props.maxCount ? (
        <></>
      ) : (
        <S.ButtonUpload type="button">
          <UploadIcon /> {props.nameUploadButton}
        </S.ButtonUpload>
      )}
    </S.UploadB>
  );
};
