import styled from "styled-components";
import Colors from "@colors";
import { Input, Button } from "antd";

export const CustomInput = styled(Input)`
  margin: 8px;
`;

export const InfoWindowContent = styled.div`
  max-height: 320px;
  svg {
    transition: 0.2s;
    &:hover {
      fill: ${Colors.lightGreen};
    }
  }
`;

export const InfoLine = styled.p`
  font-size: 14px;
  margin: 2px 0;
  color: ${Colors.darkGray};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

export const Field = styled.span`
  font-size: 12px;
  text-transform: uppercase;
`;

export const SearchButton = styled(Button)`
  margin: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 112px;
  border-radius: 24px;
  background: ${Colors.lightGreen};
  border: ${Colors.textGreen};
  color: ${Colors.white};
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: bold;
  svg path {
    transition: 1s;
  }
  &:hover {
    svg path {
      fill: ${Colors.lightGreen};
    }
  }
`;
