import React, { useEffect, useState } from "react";

import { Form, Switch } from "antd";
import "antd/dist/antd.css";

import Page from "@components/Page";
import Row from "@components/Row";
import Box from "@components/Box";
import Container from "@components/Container";
import DefaultInput from "@components/DefaultInput";
import SubmitButton from "@components/SubmitButton";
import SelectBox from "@components/SelectBox";
import CustomModal from "@components/CustomModal";
import { PageLayout } from "../../../Layout/PageLayout";
import LogisticService from "@LogisticService";

import Table from "./table";
import { SimpleRow, ModalContainer } from "./styles";

export default function InputAutonomia(props) {
  const formRef = React.createRef();
  const [form] = Form.useForm();

  const [opcoes, setOpcoes] = useState();
  const [postStatusIsInvalid, setPostStatusIsInvalid] = useState(false);
  const [motivosWkf, setMotivosWkf] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [successfully, setSuccessfully] = useState("wait");
  const [openModal, setOpenModal] = useState(false);
  const [editingId, setEditingId] = useState();
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const postForm = async (data) => {
    if (data) {
      data.ativo = data.ativo !== undefined ? data.ativo : true;
      return LogisticService.postMotivosAutonomia({ data: [data] })
        .then((response) => {
          if (response) {
            return response.status;
          }
        })
        .catch((response) => {
          return response;
        });
    }
  };

  const putForm = async (data) => {
    if (data) {
      data.ativo = data.ativo !== undefined ? data.ativo : true;
      return LogisticService.putMotivosAutonomia({ data: data })
        .then((response) => {
          if (response) {
            return response.status;
          }
        })
        .catch((response) => {
          return response;
        });
    }
  };

  const arrayToObjArray = (array) =>
    array.map((item, index) => ({ id: index, name: item }));

  const onFill = (data) => {
    setEditingId(data.id);
    setMotivosWkf(opcoes?.motivos_wkf_tree[data.post_status] ?? []);
    setServicos(opcoes?.servicos_tree[data.grupo_servico] ?? []);
    form.setFieldsValue({
      acao: data.acao,
      motivo: data.motivo,
      ativo: data.ativo,
      post_status: data.post_status,
      motivo_workfinity: data.motivo_workfinity,
      contratante: data.contratante,
      grupo_servico: data.grupo_servico,
      servico: data.servico,
    });
    setOpenModal(true);
  };

  const onSuccess = () => {
    setSuccessfully("ok");
    setTimeout(() => {
      setShouldUpdate(true);
      setOpenModal(false);
      setSuccessfully("wait");
    }, 1200);
  };

  const onFinish = async (data) => {
    const status =
      editingId === undefined
        ? await postForm(data)
        : await putForm({ ...data, id: editingId });
    // adicionarResultado(status, data);

    if (status === 201) {
      onSuccess();
      form.resetFields();
    } else if (status === 200) {
      onSuccess();
      form.resetFields();
    } else {
      setSuccessfully("erro");
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    }
  };

  useEffect(() => {
    LogisticService.getOpcoesAutonomia().then((response) => {
      if (response) {
        const objOpcoes = {
          acoes: arrayToObjArray(response.data.acoes),
          post_status: arrayToObjArray(response.data.post_status),
          contratante: arrayToObjArray(response.data.contratante),
          servicos_tree: response.data.servicos_tree,
          motivos_wkf_tree: response.data.motivos_wkf_tree,
        };
        setOpcoes(objOpcoes);
      }
    });
  }, []);

  // const postStatusIsInvalid = form.getFieldValue('acao')
  //   && form.getFieldValue('acao').includes('contato_liga');

  return (
    <PageLayout>
      <Box title="INPUT Motivos de Ineficiência na OS">
        <Row>
          <Container grid={1} rounded={true}>
            <Row>
              <Table
                openModal={() => setOpenModal(true)}
                onRowClick={(data) => onFill(data)}
                shouldUpdate={shouldUpdate}
                setShouldUpdate={setShouldUpdate}
              />
            </Row>
          </Container>
          <CustomModal
            title={
              editingId === undefined
                ? "Inserção de Novo Motivo"
                : "Atualizar Motivo"
            }
            visible={openModal}
            zIndex={1010}
            onCancel={() => {
              setOpenModal(false);
              setEditingId(undefined);
              form.resetFields();
            }}
            hideFooter
          >
            <ModalContainer>
              <Form
                onFinish={onFinish}
                onClose={() => form.resetFields()}
                form={form}
                ref={formRef}
                initialValues={{
                  acao: [],
                }}
              >
                <Form.Item
                  name="motivo"
                  label="Motivo"
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: "Informe o Motivo!" }]}
                >
                  <DefaultInput style={{ width: "180px" }} />
                </Form.Item>

                <Form.Item
                  name="ativo"
                  valuePropName="checked"
                  label="Está Ativo?"
                  labelCol={{ span: 24 }}
                >
                  <Switch defaultChecked />
                </Form.Item>

                <Form.Item
                  name="acao"
                  label="Ações de Validação"
                  labelCol={{ span: 24 }}
                >
                  <SelectBox
                    placeholder="Selecione"
                    multiple
                    allowClear
                    valueIsName
                    options={opcoes?.acoes ?? []}
                    onChange={(value) => {
                      if (
                        value.includes("print") &&
                        value.includes("print_opcional")
                      )
                        value.splice(value.indexOf("print_opcional"), 1);
                      if (
                        value.includes("contato_liga") ||
                        value.includes("stockout_tecnologia")
                      ) {
                        form.setFieldsValue({
                          post_status: "SEM STATUS",
                          motivo_workfinity: "SEM MOTIVO",
                        });
                        setPostStatusIsInvalid(true);
                      } else {
                        form.setFieldsValue({
                          post_status: null,
                          motivo_workfinity: null,
                        });
                        setPostStatusIsInvalid(false);
                      }
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="contratante"
                  label="Contratante"
                  labelCol={{ span: 24 }}
                  rules={[
                    { required: true, message: "Informe o Contratante!" },
                  ]}
                >
                  <SelectBox
                    placeholder="Selecione"
                    options={opcoes?.contratante ?? []}
                    valueIsName
                  />
                </Form.Item>

                <SimpleRow>
                  <Form.Item
                    name="post_status"
                    label="Status Posterior"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Informe o Status Posterior!",
                      },
                    ]}
                  >
                    <SelectBox
                      placeholder="Selecione"
                      options={opcoes?.post_status ?? []}
                      onChange={(newValue) =>
                        setMotivosWkf(
                          newValue === "REPIQUE"
                            ? opcoes?.motivos_wkf_tree["EM TRATAMENTO N1"]
                            : opcoes?.motivos_wkf_tree[newValue] ?? []
                        )
                      }
                      valueIsName
                      disabled={postStatusIsInvalid}
                    />
                  </Form.Item>

                  <Form.Item
                    name="motivo_workfinity"
                    label="Motivo Workfinity"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Informe o Motivo Workfinity!",
                      },
                    ]}
                  >
                    <SelectBox
                      placeholder="Selecione"
                      options={arrayToObjArray(motivosWkf)}
                      disabled={motivosWkf.length === 0 || postStatusIsInvalid}
                      valueIsName
                    />
                  </Form.Item>
                </SimpleRow>

                <SimpleRow>
                  <Form.Item
                    name="grupo_servico"
                    label="Grupo de Serviço"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Informe o Grupo de Serviço!",
                      },
                    ]}
                  >
                    <SelectBox
                      placeholder="Selecione"
                      options={arrayToObjArray(
                        Object.keys(opcoes?.servicos_tree ?? [])
                      )}
                      onChange={(newValue) =>
                        setServicos(opcoes?.servicos_tree[newValue] ?? [])
                      }
                      valueIsName
                    />
                  </Form.Item>

                  <Form.Item
                    name="servico"
                    label="Serviço"
                    labelCol={{ span: 24 }}
                  >
                    <SelectBox
                      placeholder="Selecione"
                      options={arrayToObjArray(servicos)}
                      disabled={servicos.length === 0}
                      valueIsName
                      allowClear
                    />
                  </Form.Item>
                </SimpleRow>

                <Row horizontalAlign="center">
                  <SubmitButton
                    verifyer={successfully}
                    textSuccess="Sucesso!"
                    textError="Falha Ao Processar!"
                  >
                    {editingId === undefined
                      ? "Registrar"
                      : "Concluir alteração"}
                  </SubmitButton>
                </Row>
              </Form>
            </ModalContainer>
          </CustomModal>
        </Row>
      </Box>
    </PageLayout>
  );
}
