import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const FilterButton = styled.div`
  position: absolute;
  left: 2rem;
  bottom: 1.5rem;

  .ant-btn {
    color: ${defaultTheme.colors.white};
    height: 44px;
    width: 102px;
    border-radius: 8px;
    padding: 13px 16px 13px 16px;
    background: rgba(0, 168, 104, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    border: 1px solid rgba(0, 168, 104, 1);
  }

  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    background: rgba(0, 142, 90, 1);
  }
`;

export const AllFilterDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-space-align-center {
    margin-top: 18px;
    flex-wrap: wrap;
  }
`;
export const SubtitleFilterDrawer = styled.div`
  color: rgba(118, 127, 141, 1);
`;
export const TitleFilterDrawer = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(48, 55, 66, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${(props) =>
    props.firstTitleFilter ? "37.3px 0px 16px 0px" : "24.3px 0px 16px 0px"};
`;
export const ContentDrawer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const BottomContentDrawer = styled.div`
  border-top: 1px solid #767f8d1a;
  padding: 12px 0 0 0;
`;
