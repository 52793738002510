import React from "react";
import Au from "@assets/icons/Au.svg";

import * as S from "./styles";

const SuccessStep = () => (
  <>
    <S.Header style={{ justifyContent: "center" }}>
      Definindo roteirização...
    </S.Header>
    <S.Content>
      <S.Au src={Au} />

      <span>Estamos te direcionando para tela do Senninha...</span>
    </S.Content>
  </>
);

export default SuccessStep;
