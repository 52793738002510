const defaultTheme = {
  fontSize: {
    "heading-g": "2rem", // 32px
    "heading-m": "1.5rem", // 24px
    "heading-s": "1.25rem", // 20px
    g: "1.063rem", // 17px
    m: "0.938rem", // 15px
    s: "0.815rem", // 13px
    xs: "0.75rem", // 12px
  },
  colors: {
    grey700: "#303742",
    grey500: "#767F8D",
    grey400: "#A6AEBA",
    grey200: "#F7F7FA",
    grey100: "#F2F4F7",
    grey70: "rgba(48, 55, 66, 0.1)",
    grey60: "rgba(118, 127, 141, 0.2)",
    grey50: "rgba(118, 127, 141, 0.1)",
    grey20: "rgba(48, 55, 66, 0.2)",
    grey10: "rgba(48, 55, 66, 0.1)",
    white: "#FFFFFF",
    white80: "rgba(255, 255, 255, 0.8)",
    white50: "rgba(255, 255, 255, 0.5)",
    white20: "rgba(255, 255, 255, 0.2)",
    white10: "rgba(255, 255, 255, 0.1)",
    green700: "#008E5A",
    green600: "#00A868",
    green500: "#008738",
    green300: "#0BA749",
    green60: "#00A8681A",
    green20: "rgba(0, 168, 104, 0.2)",
    green10: "rgba(11, 167, 73, 0.1)",
    blue700: "#0057D9",
    blue500: "#287DF3",
    blue20: "rgba(40, 125, 243, 0.2)",
    blue10: "rgba(40, 125, 243, 0.1)",
    orange700: "#D95E00",
    orange500: "#F38428",
    orange20: "rgba(243, 132, 40, 0.2)",
    orange10: "rgba(243, 132, 40, 0.1)",
    red700: "#E6171E",
    red500: "#F73E41",
    red20: "rgba(247, 62, 65, 0.2)",
    red10: "rgba(247, 62, 65, 0.1)",
    purple700: "#7757D9",
    purple500: "#9A79F3",
    purple20: "rgba(154, 121, 243, 0.1)",
    purple10: "rgba(154, 121, 243, 0.2)",
    black: "#000",
  },
};

export default defaultTheme;
