/* eslint-disable */
import { LoadingOutlined } from "@ant-design/icons";
import { default as React, useEffect, useState } from "react";
import Container from "@components/Container";
import LoadingSpinner from "@components/LoadingSpinner";
import Row from "@components/Row";
import SearchClientPage from "@components/SearchClientPage";
import Contatos from "./graficos/Contatos";
import InformacoesGerais from "./graficos/InformacoesGerais";
import Links from "./graficos/Links";
import POS from "./graficos/POS";
import RaioX from "./graficos/RaioX";
import Xavier from "./graficos/Xavier";
import Navbar from "@components/Navbar";
import PageHeader from "@components/PageHeader";
import { PageLayout } from "../../../Layout/PageLayout";

const DadosCliente = () => {
  const [stonecode, setStonecode] = useState(null);

  useEffect(() => {
    console.log(stonecode);
  }, [stonecode]);

  return (
    <>
      <PageLayout>
        <div>
          <PageHeader title="Dados do cliente" />
          <SearchClientPage setStonecode={setStonecode} ownerModal={false}>
            {stonecode ? (
              <>
                <Row
                  horizontalAlign={"space-between"}
                  verticalAlign={"stretch"}
                >
                  <InformacoesGerais stonecode={stonecode} />
                  <Xavier stonecode={stonecode} />
                  <RaioX stonecode={stonecode} />
                </Row>
                <Row
                  horizontalAlign={"space-between"}
                  verticalAlign={"stretch"}
                >
                  <POS stonecode={stonecode} />
                  <Contatos stonecode={stonecode} />
                </Row>
                <Row
                  horizontalAlign={"space-between"}
                  verticalAlign={"stretch"}
                >
                  <Links />
                </Row>
              </>
            ) : (
              <Row horizontalAlign={"center"}>
                <Container grid={1 / 6}>
                  <LoadingSpinner />
                </Container>
              </Row>
            )}
          </SearchClientPage>
        </div>
      </PageLayout>
    </>
  );
};

export default DadosCliente;
