import React from "react";

import * as S from "./styles";

const SimulationRouteCard = ({ simulateRoute, canSimulate }) => (
  <S.Container>
    <S.Card>
      <S.Title>
        {canSimulate ? "Simular roteirização" : "Limite Atingido!"}
      </S.Title>
      <div>
        <S.SimulationText>
          {canSimulate
            ? "Existe um limite de 3 simulações por dia nesta tela. Você simulou 0 de 3."
            : "Você utilizou o limite máximo de consultas no dia. Volte amanhã para realizar novas consultas."}
        </S.SimulationText>
        <S.SimulateButton onClick={simulateRoute} disabled={!canSimulate}>
          Simular
        </S.SimulateButton>
      </div>
    </S.Card>
  </S.Container>
);

export default SimulationRouteCard;
