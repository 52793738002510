import React, { useEffect, useState } from "react";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import Container from "@components/Container";
import Row from "@components/Row";
import Box from "@components/Box";
import { ImageBox, Info, Title } from "../styles";
import { Image, Space, message } from "antd";

export default function ImagensAutonomia(props) {
  const [dados, setDados] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const loading = <LoadingSpinner />;

  useEffect(() => {
    LogisticService.getAnexosAutonomia(props.numeroAutonomia)
      .then((response) => {
        if (response.data.motivo) {
          setDados(response.data);
          setLoaded(true);
        } else {
          message.error(`${response.data.erro}`);
          props.voltarAutonomia();
        }
      })
      .catch((err) => {
        if (err.response?.data?.erro) {
          message.error(`${err.response?.data?.erro}`);
          props.voltarAutonomia();
        }
      });
  }, [props.numeroAutonomia]);

  const RowAutonomia = () => {
    return (
      <>
        {loaded ? (
          <>
            <Row>
              <Container grid={1 / 3} title={`Dados Auditoria`}>
                <Box>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Motivo: {dados.motivo}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Data: {dados.horario}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Latitude: {dados.latitude}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Longitude: {dados.longitude}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Stockout de tecnologia: {dados.stockout_tecnologia}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Stockout de Modelo: {dados.stockout_modelo}
                  </Title>
                  <Title style={{ margin: "5px", font: "menu" }}>
                    Stockout de Chip: {dados.stockout_chip}
                  </Title>
                </Box>
              </Container>
              <Container grid={1 / 3} title={`foto`} advice={"imagem"}>
                <ImageBox>
                  <Space>
                    <Image src={dados.foto} />
                  </Space>
                </ImageBox>
              </Container>
              <Container grid={1 / 3} title={`print`} advice={"imagem"}>
                <ImageBox>
                  <Space>
                    <Image src={dados.print} />
                  </Space>
                </ImageBox>
              </Container>
            </Row>
          </>
        ) : (
          loading
        )}
      </>
    );
  };
  return (
    <Box>
      {}
      <RowAutonomia></RowAutonomia>
    </Box>
  );
}
