import styled from "styled-components";
import Colors from "@colors";

export const StyledLink = styled.a`
  color: ${(props) => (props.danger ? Colors.gray : Colors.lightGreen)};
  font-weight: bold;
  letter-spacing: 1px;
  margin: ${(props) => (props.margin ? props.margin : "0 16px 8px")};
  &:hover {
    color: ${(props) => (props.danger ? Colors.dangerRed : Colors.textGreen)};
  }
`;
