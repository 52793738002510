import { Bar } from "@reactchartjs/react-chart.js";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-zoom";
import React, { useEffect, useState } from "react";
import { intToReal } from "@helper";
import Box from "../Box";

const BarChart = (props) => {
  const [zoomEnable, setZoomEnable] = useState(false);

  const data = {
    labels: props.data.labels,
    url: props.data.url,
    type: JSON,
    datasets: props.data.data.map((content) => ({
      type: content.type,
      label: content.label,
      hidden: content.hidden ? true : false,
      fill: content.fill ? true : false,
      yAxesID: content.yAxesID ? content.yAxesID : "",
      borderColor: content.borderColor ? content.borderColor : content.color,
      backgroundColor: content.color,
      data: content.value,
      datalabels: {
        labels: {
          title: content.hideDatalabels ? null : {},
        },
        align:
          content.type === "line"
            ? "end"
            : props.data.datalabelsCenter
            ? "center"
            : "end",
        display: props.data.datalabels ? "auto" : false,
        anchor: props.data.datalabelsCenter ? "center" : "end",
        font: {
          size: 13,
        },
        formatter: (value) => {
          if (content.normalDatalabel) {
            return value;
          } else if (props.data.datalabelsPercentage) {
            return value + "%";
          } else if (props.data.datalabelsCurrency) {
            return intToReal.format(value);
          }
          return value;
        },
        color: props.data.color ? props.data.color : "black",
      },
    })),
  };
  const options = {
    legend: {
      position: "bottom",
      display: props.data.data.length > 1 ? true : false,
    },
    maintainAspectRatio: false,
    tooltips: props.data.tooltips ? props.data.tooltips : {},
    scales: props.data.scales ? props.data.scales : {},
    layout: {
      padding: {
        top: 24,
      },
    },
    plugins: {
      zoom: {
        zoom: {
          enabled: zoomEnable,
          mode: "x",
          speed: 200,
        },
      },
    },
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "Control") {
        setZoomEnable(true);
      }
    });
    document.addEventListener("keyup", function (event) {
      if (event.key === "Control") {
        setZoomEnable(false);
      }
    });
  }, []);

  return (
    <Box height={400}>
      <Bar data={data} options={options} />
    </Box>
  );
};

export default BarChart;
