import React, { useEffect, useState } from "react";
/* global google */
import Box from "@components/Box";
import CustomButton from "@components/CustomButton";
import CustomModal from "@components/CustomModal";
import LoadingMask from "@components/LoadingMask";
import SelectBox from "@components/SelectBox";
import { Select, Space, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { oldSenninhaSelector, setOldSenninhaWorkFlow } from "../../../../features/senninha/oldSenninhaSlice";
import LogisticService from "../../../../consumingApi/services/LogisticService";
import { useAuthContext } from "@stonelog/stonelog-auth-components";
import { hubsSelector } from "../../../../features/senninha/hubsSlice";

// import * as S from "./styles";


const OsHunter = () => {

  const [loading, setLoading] = useState(false);
  const [seriais, setSeriais] = useState([]);
  const [seriaisSelecionados, setSeriaisSelecionados] = useState([]);
  const [openOSCampanha, setOpenOSCampanha] = useState(null);
  const [motivoIneficiencia, setMotivoIneficiencia] = useState(null);

  const auth = useAuthContext();
  const userName = auth.getUser();

  const {
    hunterPinClicked
  } = useSelector(oldSenninhaSelector);

  const {
    selectedHubInfos
  } = useSelector(hubsSelector);

  const dispatch = useDispatch()

  const opcoesIneficiencia = [
    { id: 1, name: "Estabelecimento fechado permanente" },
    { id: 2, name: "Estabelecimento fechado no momento" },
    { id: 3, name: "Equipamento/Responsável ausente" },
    { id: 4, name: "Endereço incorreto" },
    { id: 5, name: "Não deseja retirada" },
    { id: 6, name: "Não possui equipamento" },
    { id: 7, name: "Cliente relata compra de POS" },
  ];

  const registraMotivo = async (motivo, stonecode) => {
    try {
      const result = await LogisticService.postMotivoIneficiencia({
        stonecode: stonecode,
        motivo: motivo,
        usuario: userName.email,
      });

      if (result.data.success) {
        dispatch(setOldSenninhaWorkFlow(''));
        message.success(result.data.success);
      }
    } catch (error) {
      message.error('Erro ao registrar motivo de ineficiência.');
    }
  };


  useEffect(() => {
    setLoading(true);
    LogisticService.getSeriais(hunterPinClicked?.id_atendimento).then((result) => {
      if (result.status == 200) {
        setLoading(false);
        setSeriais(result.data);
      }
    });
  }, [])

  const abrirOsCampanha = async (seriais, stonecode) => {
    setLoading(true);
    try {
      const result = await LogisticService.abreOsCampanha(stonecode, {
        polo: selectedHubInfos.children,
        seriais: seriais,
        usuario: auth.getUser().email,
      })
      console.log(result)
      if (result.data.sucesso) {
        message.success(result.data.sucesso);
        setSeriais([]);
        setOpenOSCampanha(false)
        dispatch(setOldSenninhaWorkFlow(''));
      }
    } catch (error) {
      message.error('Erro ao abrir os de campanha!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModal
      title={
        openOSCampanha === null
          ? "0TPV"
          : openOSCampanha
            ? "Abrir OS de Campanha"
            : "Registrar Motivo Ineficiência"
      }
      visible={true}
      open={true}
      onCancel={() => {
        setSeriais([]);
        setLoading(false);
        dispatch(setOldSenninhaWorkFlow(''))
      }}
      zIndex={1011}
      footer={false}
      mask={false}
    >
      <Box verticalAlign={"center"} margin={"0 8px 8px"}>
        <Space>
          <CustomButton
            active={openOSCampanha}
            onClick={() => setOpenOSCampanha(true)}
          >
            Abrir OS de Campanha
          </CustomButton>
          <CustomButton
            active={!openOSCampanha && openOSCampanha !== null}
            onClick={() => setOpenOSCampanha(false)}
          >
            Registrar Motivo Ineficiência
          </CustomButton>
        </Space>
        {openOSCampanha !== null &&
          (openOSCampanha ? (
            <Box
              noFlex
              width={"100%"}
              verticalAlign={"center"}
              margin={"16px 0 8px"}
            >
              {!loading && hunterPinClicked?.id_atendimento ? <></> : <LoadingMask />}
              {seriais?.length > 0 ? (
                <>
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    onChange={(value) => setSeriaisSelecionados(value)}
                    tokenSeparators={[","]}
                  >
                    {seriais.map((serial) => {
                      if (serial?.contractor == "TON") {
                        return (
                          <Option key={serial.serial_number}>
                            {serial.serial_number} - valor: R$
                            {serial.paid_value}
                          </Option>
                        );
                      } else {
                        return (
                          <Option key={serial.serial_number}>
                            {serial.serial_number}
                          </Option>
                        );
                      }
                    })}
                  </Select>
                  <CustomButton
                    disabled={seriaisSelecionados.length == 0}
                    onClick={() => {
                      abrirOsCampanha(
                        seriaisSelecionados,
                        hunterPinClicked?.id_atendimento
                      );
                    }}
                  >
                    Abrir OS para os seriais
                  </CustomButton>
                </>
              ) : (
                <>Não há seriais disponíveis</>
              )}
            </Box>
          ) : (
            <Box
              noFlex
              width={"100%"}
              verticalAlign={"center"}
              margin={"16px 0 8px"}
            >
              <SelectBox
                placeholder="Motivo Ineficiência"
                options={opcoesIneficiencia}
                valueIsName
                defaultValue={""}
                onChange={(value) => {
                  setMotivoIneficiencia(value);
                }}
                disabled={false}
                width={"300px"}
              />
              <CustomButton
                disabled={!motivoIneficiencia}
                onClick={() => {
                  registraMotivo(motivoIneficiencia, hunterPinClicked?.id_atendimento);
                }}
              >
                Registrar
              </CustomButton>
            </Box>
          ))}
      </Box>
    </CustomModal>
  )
}
export default OsHunter