import React, { useEffect, useState } from "react";
import ProgressScore from "../ProgressScore";
import CheckIcon from "@assets/icons/CheckIconThird";
import { formatTextWithBold } from "../../../../../../utils/formatter";

import * as S from "./styles";

const OverviewRouteScoreCard = ({ simulations }) => {
  const [bestRouteData, setBestRouteData] = useState();

  const getSimulationData = (key, color) => {
    const simAux = simulations[key];
    console.log('simAux', simAux)
    const points = simAux?.pointsData?.points !== undefined
      ? Number(simAux.pointsData.points.toFixed(3)) 
      : -1;

    return {
      total_distance: simAux?.totalInfo ? simAux.totalInfo[0] : -1,
      total_duration: simAux?.totalInfo ? simAux.totalInfo[1] : -1,
      total_orders_number: simAux?.totalInfo ? simAux.totalInfo[2] : -1,
      points: points !== 0 ? points : 0,
      alerts: simAux?.pointsData?.alerts,
      loading: simAux?.loading,
      color,
    };
  };

  const data = {
    "Sem polígono": getSimulationData("Sem polígono", "#4FC879"),
    "Com polígono": getSimulationData("Com polígono", "#80C8FF"),
    Distrital: getSimulationData("Distrital", "#FDA954"),
  };

  const bestPointsRoute = () => {
    let maxPointsItem = null;
    let maxPoints = -Infinity;

    Object.entries(data).forEach(([key, value]) => {
      if (value.points && value.points > maxPoints) {
        maxPoints = value.points;
        maxPointsItem = { key, ...value };
      }
    });
    setBestRouteData({
      title: maxPointsItem?.key,
      alerts: maxPointsItem?.alerts,
      points: maxPoints,
    });
  };

  useEffect(() => {
    bestPointsRoute();
  }, [simulations]);

  return (
    <S.Container>
      <S.Title>Pontuação final</S.Title>

      <S.Content>
        <div>
          {Object.entries(data).map(
            (item, i) =>
              item[1].points !== -1 && (
                <ProgressScore
                  key={i}
                  {...{
                    title: item[0],
                    ...item[1],
                    points: Math.round(
                      (item[1].points * 100) / bestRouteData?.points
                    ),
                  }}
                />
              )
          )}
        </div>

        {/* <S.Separator /> */}
        {/* <div>
          <S.SubTitle>
            Com o Senninha{" "}
            {bestRouteData?.title ? `(${bestRouteData.title})` : ""}, você vai:
          </S.SubTitle>
          {bestRouteData?.alerts?.map((item, i) => (
            <S.Row key={i}>
              <div>
                <CheckIcon size={20} fill="#00A868" />
              </div>
              <div>{formatTextWithBold(item)}</div>
            </S.Row>
          ))}
        </div> */}
      </S.Content>
    </S.Container>
  );
};

export default OverviewRouteScoreCard;
