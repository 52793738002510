import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import BarChart from "@components/BarChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { getOneYearAgo } from "@helper";
import Colors from "@colors";

export default function IDR(props) {
  const title = "IDR (Parque Ativo)";
  const [iDR, setIDR] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getIDR().then((response) => {
      if (response && response.data != null) {
        setIDR(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const idr = [];
  const idt = [];
  const idm = [];
  const sparePart = [];
  const preferencia = [];
  const downloadLabels = [
    "Período",
    "IDR",
    "IDM",
    "Spare Parts",
    "Pref. Modelo",
    "IDT",
  ];
  const downloadData = [];

  iDR &&
    iDR.map(
      (item) => (
        periodos.push(item.periodo),
        idr.push((item.idr * 100).toFixed(1)),
        idm.push((item.idm * 100).toFixed(1)),
        sparePart.push((item.spare_part * 100).toFixed(1)),
        preferencia.push((item.preferencia * 100).toFixed(1)),
        idt.push((item.idt * 100).toFixed(1)),
        downloadData.push([
          `${item.periodo}`,
          `${(item.idr * 100).toFixed(3)}%`,
          `${(item.idm * 100).toFixed(3)}%`,
          `${(item.spare_part * 100).toFixed(3)}%`,
          `${(item.preferencia * 100).toFixed(3)}%`,
          `${(item.idt * 100).toFixed(3)}%`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    datalabelsCenter: true,
    datalabelsPercentage: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${
            tooltipItem.yLabel
          }%`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            suggestedMax: 6,
            callback: function (value) {
              return value + "%";
            },
          },
          stacked: true,
          gridBars: false,
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
            min: getOneYearAgo(),
          },
          stacked: true,
          gridBars: false,
        },
      ],
    },
    data: [
      {
        label: "IDR",
        color: Colors.darkGreen,
        fill: false,
        value: idr,
        type: "line",
      },
      { label: "IDT", color: Colors.blue, fill: false, value: idt },
      {
        label: "Pref. Modelo",
        color: Colors.green,
        fill: false,
        value: preferencia,
      },
      { label: "IDM", color: Colors.lighterGreen, fill: false, value: idm },
      {
        label: "Spare Parts",
        color: Colors.gray,
        fill: false,
        value: sparePart,
      },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      grid={1 / 2}
      title={title}
      info={[[""]]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <BarChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
