import { Collapse } from "antd";
import styled from "styled-components";


export const AllBodyContainer = styled.div`
  width: 100%;
  height: 100%;

  p {
    color: var(--Content-Neutral, #767F8D);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.15px;
  }
  span {
    color: var(--Content-Neutral-High, #303742);
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.15px;
  }

`;

export const BodyTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BodyTopContent = styled.div`
  display: flex;
  justify-content: space-between;
  
  :last-child{
    border-bottom: 1px solid rgba(118, 127, 141, 0.10);
    padding-bottom: 12px;
  }
`;

export const BodyMiddleContainer = styled.div`
  border-bottom: 1px solid rgba(118, 127, 141, 0.10);
  margin-bottom: 12px;
  
  :last-child{
    border-bottom: 0;
  }
`;

export const BodyMiddleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const BodyMiddleRightHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const BodyMiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 12px;

  display: ${(props) => (props.open ? "grid" : "none")};;
  grid-template-rows: ${(props) => (props.open ? "1fr" : "0fr")};
  opacity: 0;
  transition: all 2s ease-in-out;
  opacity: ${(props) => (props.open ? 1 : 0)};

`;
