import React from "react";
import { StyledModal } from "./styles";

const CustomModal = (props) => {
  return (
    <StyledModal
      title={props.title}
      visible={props.visible}
      open={props.open}
      zIndex={props.zIndex}
      onCancel={props.onCancel}
      closable={props.onCancel ? true : false}
      width={props.width}
      maxWidth={props.maxWidth}
      footer={props.footer}
      onOk={props.onOk}
      mask={props.mask}
      hideFooter={props.hideFooter}
    >
      {props.children}
    </StyledModal>
  );
};

export default CustomModal;
