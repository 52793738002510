import React, { useState } from "react";
import { Button, notification } from "antd";
import RelocateRoutes from "./RelocateRoutes";
import SwapRoutes from "./SwapRoutes";

import * as S from "./styles";

const ModifyRoutes = ({
  setModo,
  stonecodesRealocacao,
  pins,
  setPins,
  setStonecodesRealocacao,
  angelsDisponiveis,
  recreateLegendThroughPins,
}) => {
  const [step, setStep] = useState("initialStep");
  const [api, contextHolder] = notification.useNotification();

  const messageMultipleReasons = (message) => {
    api.info({
      type: "success",
      message,
      placement: "bottomLeft",
      className: "notification-ginga-design",
      closeIcon: <S.CloseButton>Fechar</S.CloseButton>,
    });
  };

  const toggle = (message) => {
    messageMultipleReasons(message);
  };

  return (
    <>
      {contextHolder}
      {step === "initialStep" && (
        <S.ButtonsSimulation>
          <Button
            onClick={() => {
              setStep("selectingOSsStep");
              setModo("selecionar_realoca");
            }}
          >
            Realocar OSs
          </Button>
          <Button
            onClick={() => {
              setStep("swapAngelsStep");
            }}
          >
            Trocar Rotas
          </Button>
        </S.ButtonsSimulation>
      )}
      {step === "selectingOSsStep" && (
        <RelocateRoutes
          setStep={setStep}
          step={step}
          setModo={setModo}
          stonecodesRealocacao={stonecodesRealocacao}
          pins={pins}
          setPins={setPins}
          setStonecodesRealocacao={setStonecodesRealocacao}
          angelsDisponiveis={angelsDisponiveis}
          recreateLegendThroughPins={recreateLegendThroughPins}
          toggleMessage={toggle}
        />
      )}
      {step === "swapAngelsStep" && (
        <SwapRoutes
          setStep={setStep}
          step={step}
          angelsDisponiveis={angelsDisponiveis}
          pins={pins}
          setPins={setPins}
          recreateLegendThroughPins={recreateLegendThroughPins}
          toggleMessage={toggle}
        />
      )}
    </>
  );
};

export default ModifyRoutes;
