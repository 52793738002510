import React from "react";
import { Breadcrumb } from "antd";
import useRouter from "../../hooks/useRouter";
import {
  breadcrumbNameMap,
  breadcrumbRouteMap,
  breadcrumbBlackList,
} from "../../routes/breadcrumbsRoutes";
import { formatPath } from "../../utils/formatter";
import * as S from "./styles";

const Breadcrumbs = ({ onPathClickConfig }) => {
  const { pathname } = useRouter();

  const pathSnippets = pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((path, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    if (breadcrumbBlackList.includes(path)) return;
    return (
      <Breadcrumb.Item
        key={url}
      >
        <S.CustomLink to={{ 
          pathname: breadcrumbRouteMap[url] || url, 
          ...(onPathClickConfig && onPathClickConfig.pathSnippet === path ? {
            state: onPathClickConfig.data
          } : {})
        }}>
          {breadcrumbNameMap[path] || formatPath(path)}
        </S.CustomLink>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <S.CustomLink to="/">Home</S.CustomLink>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumb style={{ marginBottom: 16 }}>{breadcrumbItems}</Breadcrumb>
  );
};

export default Breadcrumbs;
