import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Paragraph } from "./styles";

const Dragger = (props) => {
  const [fileList, setFileList] = useState([]);

  const acceptedFileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];

  const beforeUpload = (file) => {
    setFileList([file]);
  };

  const onRemoveUpload = () => {
    setFileList([]);
  };

  const dummyRequest = ({ onSuccess, onError, file }) => {
    setTimeout(() => {
      if (acceptedFileType.includes(file.type)) {
        onSuccess("ok");
      } else {
        onError("error");
      }
    }, 0);
  };

  const organize_data = (data) => {
    let cols = [];
    let rows = [];

    data[0].forEach(function (col) {
      cols.push({ field: col.trim(), title: col.trim() });
    });

    data.shift();

    data.forEach(function (row) {
      let apartedRows = {};
      for (let i = 0; i < row.length; i++) {
        apartedRows[cols[i].field] =
          typeof row[i] === "string" ? row[i].trim() : row[i];
      }
      rows.push(apartedRows);
    });

    return { rows: rows, cols: cols };
  };

  const handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        codepage: 65001,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        range: 0,
        // defval: "null",
        blankrows: false,
      });

      const organized_data = organize_data(data);

      if (organized_data.rows.length > 300) {
        setFileList([]);
        props.multipleFormRef.setFields([
          {
            name: "dragger",
            errors: [
              "Arquivo muito grande, por favor selecione um arquivo com máximo de 300 linhas",
            ],
          },
        ]);
      } else {
        props.handleOnUpload(organized_data.rows);
      }
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (fileList.length === 1) handleFile(fileList[0]);
    if (props.setIsEmpty) props.setIsEmpty(fileList.length === 0);
  }, [fileList]);

  return (
    <Upload.Dragger
      name={props.name}
      style={props.style ? props.style : { padding: "8px" }}
      beforeUpload={beforeUpload}
      onRemove={onRemoveUpload}
      fileList={fileList}
      customRequest={dummyRequest}
      {...props}
    >
      <Paragraph className="ant-upload-drag-icon">
        <InboxOutlined />
      </Paragraph>
      <Paragraph className="ant-upload-text">{props.title}</Paragraph>
      <Paragraph className="ant-upload-hint">{props.text}</Paragraph>
      {props.children ?? <></>}
    </Upload.Dragger>
  );
};

export default Dragger;
