import React from "react";
import { StyledLink } from "./styles";

const CustomLink = (props) => {
  return (
    <StyledLink
      onClick={props.onClick}
      disabled={props.disabled}
      margin={props.margin}
      danger={props.danger}
    >
      {props.children}
    </StyledLink>
  );
};

export default CustomLink;
