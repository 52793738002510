import { GenIcon } from "react-icons";

function DownloadIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 18 18",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M9.0185 0.749908C9.43271 0.749908 9.7685 1.08569 9.7685 1.49991V9.42096L12.2197 6.9696C12.5125 6.6767 12.9874 6.67668 13.2803 6.96956C13.5732 7.26244 13.5732 7.73731 13.2804 8.03022L9.53067 11.7802C9.23778 12.0731 8.76291 12.0731 8.47001 11.7803L4.71969 8.03026C4.42679 7.73738 4.42677 7.26251 4.71965 6.9696C5.01253 6.6767 5.48741 6.67668 5.78031 6.96956L8.2685 9.45754V1.49991C8.2685 1.08569 8.60428 0.749908 9.0185 0.749908Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M2.25 9.375C2.66422 9.375 3 9.71079 3 10.125V10.875C3 11.9569 3.0016 12.6928 3.07569 13.2439C3.14689 13.7735 3.27241 14.0187 3.43934 14.1857C3.60627 14.3526 3.85154 14.4781 4.38108 14.5493C4.93219 14.6234 5.66814 14.625 6.75 14.625H11.25C12.3319 14.625 13.0678 14.6234 13.6189 14.5493C14.1485 14.4781 14.3937 14.3526 14.5607 14.1857C14.7276 14.0187 14.8531 13.7735 14.9243 13.2439C14.9984 12.6928 15 11.9569 15 10.875V10.125C15 9.71079 15.3358 9.375 15.75 9.375C16.1642 9.375 16.5 9.71079 16.5 10.125V10.9279C16.5 11.9435 16.5001 12.781 16.4109 13.4438C16.3174 14.1397 16.1134 14.7542 15.6213 15.2463C15.1292 15.7384 14.5147 15.9424 13.8188 16.0359C13.156 16.125 12.3185 16.125 11.3029 16.125H6.69707C5.68148 16.125 4.84402 16.125 4.18121 16.0359C3.48533 15.9424 2.87076 15.7384 2.37868 15.2463C1.8866 14.7542 1.68262 14.1397 1.58907 13.4438C1.49995 12.781 1.49998 11.9435 1.5 10.928L1.5 10.125C1.5 9.71079 1.83579 9.375 2.25 9.375Z",
        },
      },
    ],
  })(props);
}

export default DownloadIcon;
