import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.75rem;
  justify-content: space-between;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const TitleText = styled.div`
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.g};
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${defaultTheme.colors.grey700};
  margin: 1.142rem 0 0.5rem 0;
`;

export const Text = styled.div`
  font-size: ${defaultTheme.fontSize.s};
  line-height: 145%;
  letter-spacing: -0.01em;
  color: ${defaultTheme.colors.grey500};
  margin-bottom: 1.5rem;
`;

export const AccessButton = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: ${defaultTheme.fontSize.m};
  line-height: 120%;
  color: ${defaultTheme.colors.grey700};
  background-color: ${defaultTheme.colors.grey50};
  padding: 0.8125rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
`;
