import React from "react";
import "antd/dist/antd.css";
import { Alert } from "antd";

const CustomAlert = (props) => {
  return (
    <>
      {props.visible ? (
        <Alert
          banner={props.banner}
          closable={props.closeText ? false : props.closable}
          closeText={props.closeText}
          description={props.description}
          message={props.message}
          showIcon={!props.hiddenIcon}
          type={props.type || "info"}
          style={{
            position: "fixed",
            bottom: "5%",
            right: "4%",
            width: "25%",
          }}
          {...props}
        />
      ) : null}
    </>
  );
};

export default CustomAlert;
