import { GenIcon } from "react-icons";

export function FilterIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 18 18",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M1.5 3.4428C1.5 2.38194 2.35018 1.5 3.42388 1.5H14.5769C15.6513 1.5 16.5 2.38261 16.5 3.4428V4.14483C16.5 4.82205 16.2427 5.47462 15.7808 5.96697L12.1458 9.83206L12.1378 9.84046C11.5799 10.4156 11.2651 11.1945 11.2651 12.0085V13.7238C11.2651 14.3539 10.924 14.9401 10.3671 15.2441L8.29595 16.3728C7.57867 16.7623 6.74929 16.223 6.74929 15.4464V12.0175C6.74929 11.2197 6.44568 10.4554 5.90728 9.88254C5.88913 9.86665 5.87167 9.84981 5.85498 9.83206L2.22067 5.96764C1.75744 5.47506 1.5 4.82214 1.5 4.14483V3.4428ZM6.90256 8.75646C6.91521 8.76776 6.92751 8.77952 6.93944 8.79174C7.77878 9.65102 8.24929 10.8105 8.24929 12.0175V14.69L9.64819 13.9277C9.64814 13.9277 9.64824 13.9276 9.64819 13.9277C9.71617 13.8905 9.76509 13.8135 9.76509 13.7238V12.0085C9.76509 10.8111 10.227 9.65815 11.0569 8.80038L14.6868 4.94068C14.6868 4.9407 14.6868 4.94067 14.6868 4.94068C14.8865 4.72776 15 4.44263 15 4.14483V3.4428C15 3.18669 14.7987 3 14.5769 3H3.42388C3.20143 3 3 3.18736 3 3.4428V4.14483C3 4.44256 3.11335 4.72732 3.31336 4.94001C3.31336 4.94001 3.31337 4.94002 3.31336 4.94001L6.90256 8.75646Z",
        },
      },
    ],
  })(props);
}
