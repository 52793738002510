import React from "react";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";

import { GButton, TitleSuccess, TitleError } from "./styles";

const SubmitButton = (props) => {
  const sharedProps = {
    value: props.value,
    style: props.style,
    htmlType: props.htmlType || "submit",
  };

  const verifyer = props.verifyer || "wait";
  const textSuccess = props.textSuccess || "Sucesso!";
  const textError = props.textError || "Falha!";

  return verifyer === "ok" ? (
    <TitleSuccess>
      <CheckCircleOutlined /> {textSuccess}{" "}
    </TitleSuccess>
  ) : verifyer === "erro" ? (
    <TitleError>
      <StopOutlined /> {textError}{" "}
    </TitleError>
  ) : (
    <GButton type="submit" {...sharedProps} {...props}>
      {props.children}
    </GButton>
  );
};

export default SubmitButton;
