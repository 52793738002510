import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import CustomModal from "@components/CustomModal";
import DetailButton from "@components/DetailButton";
import InfoTable from "@components/InfoTable";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { Link } from "../styles";
import DetalhesOS from "./DetalhesOS";

export default function RealizadosPolo(props) {
  const title = "OSs Realizadas";
  const [ossRealizadasPolo, setOssRealizadasPolo] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [visibleModal, setVisibleModal] = useState(undefined);
  const [visibleDetalhesOs, setVisibleDetalhesOs] = useState(false);
  const [oSSelecionada, setOSSelecionada] = useState(undefined);

  useEffect(() => {
    LogisticService.getRealizadosPolo(props.polo).then((response) => {
      setOssRealizadasPolo(response.data);
      setLoaded(true);
    });
  }, [props.polo]);

  const colunas = [
    {
      label: "Cidade",
      key: "cidade",
      align: "start",
      sort: {
        compare: (a, b) => a.cidade.localeCompare(b.cidade),
      },
    },
    { label: "Feitas Hoje", key: "feitas_hoje" },
    { label: "Pendentes em atraso", key: "pendentes_em_atraso" },
    { label: "Novas", key: "NOVA" },
    { label: "Recebidas", key: "RECEBIDA" },
    { label: "Em Campo", key: "EM CAMPO" },
    { label: "Repiques", key: "REPIQUE" },
  ];

  const labelsModal = {
    feitas_hoje: "Feitas Hoje",
    pendentes_em_atraso: "Pendentes Em Atraso",
    NOVA: "Novas",
    RECEBIDA: "Recebidas",
    "EM CAMPO": "em Campo",
    REPIQUE: "em Repique",
  };

  const rows = [];
  const downloadLabels = colunas.map((item) => item.label);
  const downloadData = [];
  const modalData = [];

  for (let cidade in ossRealizadasPolo) {
    const row = { cidade: cidade };
    const rowDownload = [cidade];
    let count = 0;

    for (let status in ossRealizadasPolo[cidade]) {
      const id = cidade.split(" - ")[1] + count;
      const numOss = Object.keys(ossRealizadasPolo[cidade][status]).length;

      if (numOss > 0) {
        row[status] = (
          <>
            {numOss}
            <DetailButton onClick={() => setVisibleModal(id)} />
          </>
        );
      }
      rowDownload.push(numOss);

      modalData.push(
        <CustomModal
          title={`OSs ${labelsModal[status]} em ${cidade}`}
          key={id}
          visible={visibleModal === id}
          zIndex={1000}
          onCancel={() => setVisibleModal(undefined)}
          footer={null}
        >
          <Box maxHeight={"400px"} margin={"0"} padding={"0"} scroll>
            {ossRealizadasPolo[cidade][status].map((os) => (
              <Link
                onClick={() => {
                  setOSSelecionada(os);
                  setVisibleDetalhesOs(true);
                }}
              >
                {os}
              </Link>
            ))}
          </Box>
        </CustomModal>
      );

      count += 1;
    }
    downloadData.push(rowDownload);
    rows.push(row);
  }

  const tableData = {
    data: rows,
    columns: colunas,
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      title={title + " - " + props.name}
      info={[`OSs Realizadas ${props.name}`]}
      downloadLabels={loaded ? downloadLabels : []}
      downloadData={loaded ? downloadData : []}
    >
      {loaded ? <InfoTable data={tableData} /> : <LoadingSpinner />}
      <CustomModal
        title={`Detalhes OS ${oSSelecionada}`}
        visible={visibleDetalhesOs}
        zIndex={1010}
        onCancel={() => setVisibleDetalhesOs(false)}
        footer={null}
      >
        <DetalhesOS os={oSSelecionada} />
      </CustomModal>
      {modalData}
    </Container>
  );
}
