import { Form } from "antd";
import styled from "styled-components";

export const Label = styled.p`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-top: 16px;
`;

export const CopyEmail = styled.span`
  opacity: 0.5;
  transition: 0.4s;
  &:hover {
    opacity: 1;
  }
`;

export const FormItem = styled(Form.Item)`
  margin: 0 0 8px;
  .ant-form-item-label {
    padding: 2px;
  }
`;
