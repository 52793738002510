import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Link } from "react-router-dom";

export const HeaderSeninha = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${defaultTheme.colors.white};
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0.75rem;
`;

export const TitleText = styled.div`
  font-size: ${defaultTheme.fontSize.g};
  color: ${defaultTheme.colors.grey700};
  line-height: 145%;
  letter-spacing: -0.01em;
  font-weight: 600;
`;

export const ToolTipBox = styled.span`
  font-size: ${defaultTheme.fontSize.s};
  line-height: 145%;
  letter-spacing: -0.01em;
`;

export const Card = styled.div`
  padding: 1.5rem 0;
  background-color: #fff;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;

  header {
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    p {
      font-size: ${defaultTheme.fontSize.xs};
      color: ${defaultTheme.colors.grey500};
      letter-spacing: -0.01em;
    }
  }

  :last-child {
    margin: 0;
  }
`;

export const Title = styled.div`
  color: ${defaultTheme.colors.grey700};
  font-size: ${defaultTheme.fontSize["heading-s"]};
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 0.1rem;
`;

export const RequestQDButton = styled(Link)`
  display: flex;
  align-items: center;
  height: 44px;
  background-color: ${defaultTheme.colors.green600};
  padding: 0 1rem;
  color: ${defaultTheme.colors.white};
  font-size: ${defaultTheme.fontSize.m};
  font-weight: 600;
  letter-spacing: -0.01em;
  border-radius: 0.5rem;
  border: none;

  :hover,
  :focus {
    background-color: ${defaultTheme.colors.green600} !important;
    color: ${defaultTheme.colors.white} !important;
  }
`;

export const EmptyContent = styled.div`
  padding: 0 1rem;
`;

export const StoneContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  padding: 0 1.5rem;
`;

export const TonContainer = styled(StoneContainer)`
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${defaultTheme.colors.grey50};
  padding: 3rem 0;

  span {
    color: ${defaultTheme.colors.grey700};
    font-size: ${defaultTheme.fontSize["heading-s"]};
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.02em;
    margin-top: 0.625rem;
  }
`;
