import React, { useEffect, useState } from "react";
import { Header } from "../Header";
import { MenuNav } from "../MenuNav";
import * as S from "./styles";

export const PageLayout = ({
  children,
  setMenuIsOpen,
  titlePage,
  showHamburguerIcon,
  loginScreen,
  showMenuNav = true,
  ...props
}) => {
  const [showHamburguer, setShowHamburguer] = useState(false);

  useEffect(() => {
    if (setMenuIsOpen) {
      setMenuIsOpen(showHamburguer);
    }
  }, [showHamburguer]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "68px 1fr",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Header
        titlePage={titlePage ? titlePage : ""}
        showHamburguerIcon={showHamburguerIcon}
        setShowHamburguer={setShowHamburguer}
        showHamburguer={showHamburguer}
        loginScreen={loginScreen}
      />
      <S.PageLayout showHamburguer={showHamburguer}>
        {showHamburguerIcon ? (
          <S.PageContent
            showHamburguer={showHamburguer}
            showHamburguerIcon={showHamburguerIcon}
            showMenuNav={showMenuNav}
          >
            {showHamburguer ? <MenuNav /> : <></>}
            {children}
          </S.PageContent>
        ) : (
          <S.PageContent
            showHamburguer={showHamburguer}
            showHamburguerIcon={showHamburguerIcon}
            showMenuNav={showMenuNav}
          >
            {showMenuNav && <MenuNav />}
            {children}
          </S.PageContent>
        )}
      </S.PageLayout>
    </div>
  );
};
