import styled from "styled-components";
import { Button } from "antd";

// export const Row = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

export const SubmitButton = styled(Button)`
  border-radius: 8px;
  height: 44px;
  width: 100%;
  background-color: #00a868;
  color: white;
  font-size: 15px;
  margin-top: 24px;
`;
