import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import BarChart from "@components/BarChart";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { getOneYearAgo } from "@helper";
import Colors from "@colors";

export default function EvolucaoEtapasCadeia(props) {
  const title = "Evolucao etapas da Cadeia";
  const [evolucaoEtapasCadeia, setEvolucaoEtapasCadeia] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getEvolucaoEtapasCadeia().then((response) => {
      if (response && response.data != null) {
        setEvolucaoEtapasCadeia(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const obsoletos = [];
  const cicloGood = [];
  const cicloBad = [];
  const parque0TPV = [];
  const parqueAtivo = [];
  const downloadLabels = [
    "Período",
    "Parque Ativo",
    "Parque 0 TPV",
    "Ciclo Good",
    "Ciclo Bad",
    "Obsoletos",
  ];
  const downloadData = [];

  evolucaoEtapasCadeia &&
    evolucaoEtapasCadeia.map(
      (item) => (
        periodos.push(item.periodo),
        parqueAtivo.push((item.parque_ativo * 100).toFixed(1)),
        parque0TPV.push((item.parque_0tpv * 100).toFixed(1)),
        cicloGood.push((item.ciclo_good * 100).toFixed(1)),
        cicloBad.push((item.ciclo_bad * 100).toFixed(1)),
        obsoletos.push((item.obsoletos * 100).toFixed(1)),
        downloadData.push([
          `${item.periodo}`,
          `${(item.parque_ativo * 100).toFixed(3)}%`,
          `${(item.parque_0tpv * 100).toFixed(3)}%`,
          `${(item.ciclo_good * 100).toFixed(3)}%`,
          `${(item.ciclo_bad * 100).toFixed(3)}%`,
          `${(item.obsoletos * 100).toFixed(3)}%`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    datalabelsCenter: true,
    datalabelsPercentage: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${
            tooltipItem.yLabel
          }%`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            max: 100,
            callback: function (value) {
              return value + "%";
            },
          },
          stacked: true,
          gridBars: false,
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
            min: getOneYearAgo(),
          },
          stacked: true,
          gridBars: false,
        },
      ],
    },
    data: [
      {
        label: "Parque Ativo",
        color: Colors.green,
        fill: false,
        value: parqueAtivo,
      },
      {
        label: "Parque 0 TPV",
        color: Colors.dangerRed,
        fill: false,
        value: parque0TPV,
      },
      {
        label: "Ciclo Good",
        color: Colors.blue,
        fill: false,
        value: cicloGood,
      },
      {
        label: "Ciclo Bad",
        color: Colors.cautionYellow,
        fill: false,
        value: cicloBad,
      },
      { label: "Obsoletos", color: Colors.gray, fill: false, value: obsoletos },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      grid={1 / 2}
      title={title}
      info={[[""]]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <BarChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
