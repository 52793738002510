import { Modal } from "antd";
import styled from "styled-components";
import Colors from "@colors";

export const StyledModal = styled(Modal)`
  top: 10rem;
  max-width: ${(props) =>
    props.maxWidth ? props.maxWidth : "calc(100vw - 32px)"} !important;
  .ant-modal-content {
    border-radius: 0;
    .ant-modal-header {
      padding: 16px;
      border: none;
      .ant-modal-title {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }
    }
    .ant-modal-footer {
      border: none;
      display: ${(props) => (props.hideFooter ? "none" : "flex")};
      justify-content: flex-end;
    }
    svg {
      fill: ${Colors.deepBlue};
    }
    .ant-modal-body {
      padding: 0 8px 8px;
    }
  }
`;
