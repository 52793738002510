import React, { useState } from "react";
import { Form, Switch } from "antd";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import { PageLayout } from "../../../../Layout/PageLayout";
import Box from "@components/Box";
import Container from "@components/Container";
import DefaultInput from "@components/DefaultInput";
import Dragger from "@components/CustomDragger";
import Row from "@components/Row";
import SubmitButton from "@components/SubmitButton";
import InfoTable from "@components/InfoTable";
import CustomAlert from "@components/CustomAlert";

import LogisticService from "@LogisticService";

import Table from "./table";

import {
  Download,
  Exemplo,
  Paragraph,
  Span,
  TextError,
  TextSuccess,
} from "../styles";

export default function ClassificacaoCasos(props) {
  const [dataDragger, setDataDragger] = useState([]);
  const [dadosTabela, setDadosTabela] = useState([]);

  const formRef = React.createRef();
  const [form] = Form.useForm();

  const [successfully, setSuccessfully] = useState("wait");
  const [alertOptions, setAlertOptions] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const multipleFormRef = React.createRef();
  const [multipleForm] = Form.useForm();
  const [successfullyMultiple, setSuccessfullyMultiple] = useState("wait");

  const postForm = async (data) => {
    if (data) {
      return LogisticService.postArvoreDemandas(data)
        .then((response) => {
          if (response) {
            return response.status;
          }
        })
        .catch((response) => {
          return response;
        });
    }
  };

  const handleOnUpload = (dataFileDragger) => {
    setDataDragger(dataFileDragger);
  };

  const adicionarResultado = (status, formObj) => {
    let result;
    const date = new Date();

    if (status === 200) {
      result = () => {
        return (
          <TextSuccess>
            <CheckCircleOutlined /> Sim
          </TextSuccess>
        );
      };
    } else {
      result = () => {
        return (
          <TextError>
            <StopOutlined /> Nop
          </TextError>
        );
      };
    }
    formObj = {
      inserido: result(),
      horario: date.getHours() + ":" + date.getMinutes(),
      ...formObj,
      ativo: formObj.ativo ? "Sim" : "Não",
    };
    setDadosTabela((oldState) => [formObj, ...oldState]);
  };

  const onFinishMultipleCases = async (data) => {
    dataDragger.forEach(async (formObj) => {
      const status = await postForm(formObj);
      adicionarResultado(status, formObj);

      if (status === 200) {
        setSuccessfullyMultiple("ok");
        form.resetFields();
        setTimeout(() => {
          setSuccessfullyMultiple("wait");
        }, 3000);
      } else {
        setSuccessfullyMultiple("erro");
        setTimeout(() => {
          setSuccessfullyMultiple("wait");
        }, 3000);
      }
    });
    multipleForm.resetFields();
  };

  const onFinish = async (data) => {
    const status = await postForm(data);
    adicionarResultado(status, data);

    if (status === 200) {
      setSuccessfully("ok");
      form.resetFields();
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    } else {
      setSuccessfully("erro");
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    }
  };

  const colunasTabela = [
    { label: "Inserido", key: "inserido" },
    { label: "Horário", key: "horario" },
    { label: "Demanda", key: "demanda" },
    { label: "Especificação", key: "especificacao" },
    { label: "Detalhe", key: "detalhe" },
    { label: "Ativo", key: "ativo" },
  ];

  const tableData = {
    data: dadosTabela,
    columns: colunasTabela,
  };

  const rows = [
    <Row>
      <Form.Item name="demanda" label="Demanda" labelCol={{ span: 24 }}>
        <DefaultInput autoFocus={true} />
      </Form.Item>
    </Row>,
    <Row>
      <Form.Item
        name="especificacao"
        label="Especificação"
        labelCol={{ span: 24 }}
      >
        <DefaultInput />
      </Form.Item>
    </Row>,
    <Row>
      <Form.Item name="detalhe" label="Detalhe" labelCol={{ span: 24 }}>
        <DefaultInput autoFocus={true} />
      </Form.Item>
    </Row>,
    <Row>
      <Form.Item name="ativo" label="Está Ativo?" labelCol={{ span: 24 }}>
        <Switch defaultChecked={true} />
      </Form.Item>
    </Row>,
    <Row horizontalAlign="center" verticalAlign="center">
      <SubmitButton
        verifyer={successfully}
        textSuccess="Registrado Com Sucesso! (˘◡˘ )"
        textError="Falha Ao Registrar! ( ˘︹˘ )"
      >
        importar
      </SubmitButton>
    </Row>,
  ];

  return (
    <PageLayout>
      <Box title="INPUT Mapeamento ON">
        <Container
          title={"Inserção de Demanda"}
          padding={"16px"}
          rounded={true}
        >
          <Form
            onFinish={onFinish}
            form={form}
            ref={formRef}
            initialValues={{ ativo: true }}
          >
            {rows}
          </Form>
        </Container>
        <Container rounded={true}>
          <Table setAlertOptions={setAlertOptions} />
        </Container>
        <Container
          title={"Inserção de Demanda"}
          padding={"16px"}
          rounded={true}
        >
          <Form
            onFinish={onFinishMultipleCases}
            form={multipleForm}
            ref={multipleFormRef}
          >
            <Paragraph style={{ fontSize: 16, marginTop: 16 }}>
              Importação de Múltiplas Demandas
            </Paragraph>
            <Form.Item
              name="dragger"
              rules={[
                {
                  required: true,
                  message: "Nenhum Arquivo CSV/XLSX Selecionado!",
                },
              ]}
            >
              <Dragger
                name="dragger"
                title="Importar Múltiplas Demandas Para a Base de Dados"
                text="Selecione ou Arraste um Arquivo CSV/XLSX Até Esta Área"
                handleOnUpload={handleOnUpload}
              />
            </Form.Item>
            <SubmitButton
              verifyer={successfullyMultiple}
              textSuccess="Registrado Com Sucesso! (^▿^ )"
              textError="Falha Ao Registrar! ( ˘︹˘ )"
            >
              REGISTRAR MÚLTIPLAS DEMANDAS
            </SubmitButton>
            <Exemplo>
              Dúvidas? Clique{" "}
              <Download
                href={"/dist/Modelo Input Mapeamento On.xlsx"}
                target="_blank"
              >
                aqui
              </Download>{" "}
              para baixar um modelo de exemplo.
            </Exemplo>
          </Form>
        </Container>
        <Container rounded={true}>
          <Span style={{ padding: "16px" }}>
            Visualização das Demandas Importadas
          </Span>
          <InfoTable
            data={tableData}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </Container>
        <CustomAlert
          message={alertOptions.message}
          type={alertOptions.type}
          visible={alertOptions.visible}
          closable
        />
      </Box>
    </PageLayout>
  );
}
