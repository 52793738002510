import styled from "styled-components";


export const PageAll = styled.div`
  display: flex;
  height: ${(props) => (props.menuIsOpen ? "calc(100vh - 68px)" : "100%")} !important;
  border-radius: ${(props) => (props.menuIsOpen ? "8px" : "0px")};
  position: relative;
  margin: ${(props) => (props.menuIsOpen ? "-24px -32px -24px 0" : "0px")};
`;
