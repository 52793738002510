import React from "react";
import { Collapse } from "antd";
import NeutralPersonIcon from "@assets/icons/NeutralPersonIcon";
import NeutralGroupIcon from "@assets/icons/NeutralGroupIcon";
import AdjustmentIcon from "@assets/icons/AdjustmentIcon";
import LaptopIcon from "@assets/icons/LaptopIcon";
import NavItemIcon from "@assets/icons/NavItemIcon";
import CoinIcon from "@assets/icons/CoinIcon";
import PinIcon from "@assets/icons/PinIcon";
import DeviceMachineIcon from "@assets/icons/DeviceMachineIcon";
import EyeIcon from "@assets/icons/EyeIcon";
import BottomArrow from "@assets/icons/BottomArrow";
import * as S from "./styles";

const generateRouteForLayout = (name, route, icon) => ({
  name,
  route,
  icon,
});

const menuLinks = [
  {
    name: "Green Angels",
    icon: <NeutralPersonIcon size={24} />,
    childrens: [
      generateRouteForLayout(
        "Gestão distr./polo",
        "/lmp/gestao-distrito-polo",
        <NeutralGroupIcon size={24} />
      ),
      generateRouteForLayout(
        "Senninha",
        "/lmp/senninha",
        <PinIcon size={24} />
      ),
    ],
  },
  {
    name: "Ferramentas",
    icon: <NeutralPersonIcon size={24} />,
    childrens: [
      generateRouteForLayout(
        "Dados do cliente",
        "/ferramentas/dados-cliente",
        <DeviceMachineIcon size={24} />
      ),
      generateRouteForLayout(
        "Remun. variável",
        "/ferramentas/remuneracao-variavel",
        <CoinIcon size={24} />
      ),
      generateRouteForLayout(
        "Casos da liga",
        "/ferramentas/casos-liga",
        <EyeIcon size={24} />
      ),
      generateRouteForLayout(
        "Anexos OS/OM",
        "/ferramentas/anexos",
        <NavItemIcon size={24} />
      ),
      generateRouteForLayout(
        "Gestão terceira",
        "/ferramentas/operacao-terceira",
        <LaptopIcon size={24} />
      ),
    ],
  },
];

const Header = ({ icon, title }) => (
  <S.CollapseHeader className="link-collapse-header">
    {icon}
    <span>{title}</span>
  </S.CollapseHeader>
);

export const MenuNav = () => {
  const { Panel } = Collapse;

  return (
    <S.AllPage>
      <Collapse
        defaultActiveKey={["0", "1"]}
        ghost
        expandIconPosition="end"
        expandIcon={() => <BottomArrow color={"#767F8D"} size={24} />}
      >
        {menuLinks.map(({ name, icon, childrens }, index) => (
          <Panel
            header={<Header title={name} icon={icon} />}
            key={index.toString()}
          >
            {childrens.map((nav) => (
              <S.NavLinkMenu
                to={nav.route}
                key={nav.name}
                className="ginga-nav-link-item"
              >
                {nav.icon}
                <p>{nav.name}</p>
              </S.NavLinkMenu>
            ))}
          </Panel>
        ))}
      </Collapse>
    </S.AllPage>
  );
};
