import React, { useState, useEffect } from "react";
import MaterialTableComponent from "@components/MaterialTableComponent";

import LogisticService from "@LogisticService";

const colunas = [
  { title: "ID", field: "id", editable: "never", width: 60 },
  { title: "Tema", field: "tema", width: 80 },
  { title: "Categoria", field: "categoria", width: 80 },
  { title: "Assunto", field: "assunto", width: 80 },
  { title: "Criado Em", field: "date", editable: "never", width: 80 },
  { title: "Ativo", field: "ativo", type: "boolean", width: 40 },
];

const Table = (props) => {
  const { innerHeight } = window;
  const [arvore, setArvore] = useState([]);
  const [isLoadingTree, setIsLoadingTree] = useState(true);

  useEffect(() => {
    LogisticService.getArvoreLiga().then((response) => {
      if (response) {
        setArvore(response.data);
        setIsLoadingTree(false);
      }
    });
  }, [props.successfully]);

  const displayAlert = (message, type) => {
    props.setAlertOptions({
      message: message,
      type: type,
      visible: true,
    });
    setTimeout(() => {
      props.setAlertOptions({
        visible: false,
      });
    }, 10000);
  };

  return (
    <MaterialTableComponent
      columns={colunas}
      data={arvore}
      isLoading={isLoadingTree}
      title={"Árvore da LIGA"}
      bodyStyle={{
        overflow: "none",
      }}
      addOptions={{
        actionsColumnIndex: -1,
        pageSize: 20,
        showTitle: true,
        maxBodyHeight: 0.6 * innerHeight,
        tableLayout: "fixed",
      }}
      editable={{
        onRowUpdate: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              LogisticService.updateArvoreLiga(newData).then((response) => {
                if (response && response.status === 200) {
                  LogisticService.getArvoreLiga().then((response) => {
                    setArvore(response.data);
                    displayAlert("Linha Editada Com Sucesso!", "success");
                  });
                } else {
                  displayAlert("Falha ao Editar Esta Linha.", "error");
                }
              });
            }, 600);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              LogisticService.deleteArvoreLiga({ id: oldData.id }).then(
                (response) => {
                  if (response && response.status === 200) {
                    LogisticService.getArvoreLiga().then((response) => {
                      setArvore(response.data);
                      displayAlert("Linha Deletada Com Sucesso!", "success");
                    });
                  } else {
                    displayAlert("Falha ao Deletar Esta Linha.", "error");
                  }
                }
              );
            }, 600);
          }),
      }}
    />
  );
};

export default Table;
