import { GenIcon } from "react-icons";

function EditIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 18 18",
    },
    child: [
      {
        tag: "g",
        fill: "url(#mask0_118_5433)",
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M14.4196 2.39855C15.6601 3.19017 16.0427 4.83083 15.2592 6.08802C15.2512 6.10053 15.24 6.11745 15.2357 6.12363L15.2258 6.13756L15.2164 6.15034L15.2059 6.16416L15.1844 6.19184L15.1147 6.28095C15.0553 6.35665 14.9694 6.4659 14.8608 6.6037C14.6436 6.87944 14.3348 7.27094 13.9645 7.74022C13.2237 8.67884 12.2359 9.92938 11.2407 11.1889C9.2507 13.7077 7.22986 16.264 7.09491 16.4342C6.66949 16.9709 6.02448 17.2865 5.33787 17.2941C5.12078 17.2965 4.34816 17.3063 3.62849 17.3155L2.24529 17.3333C1.85408 17.3384 1.51153 17.0707 1.42252 16.6897L0.725238 13.7051C0.570439 13.04 0.724786 12.3391 1.15095 11.8002L2.30993 10.329C3.00945 9.44133 3.94905 8.24937 4.90929 7.03246C6.82386 4.60613 8.83612 2.06072 9.17401 1.65234C10.1246 0.503478 11.8283 0.335389 12.987 1.27264L12.9904 1.27527L14.4196 2.39855ZM11.9406 2.56984C11.4901 2.20402 10.8256 2.27071 10.4581 2.71479C10.3326 2.86654 9.95242 3.34307 9.42103 4.01284L12.7435 6.597C13.0751 6.1769 13.3521 5.82558 13.5515 5.57244C13.6599 5.43484 13.745 5.32661 13.8034 5.25221L13.8629 5.17615C14.1383 4.69913 13.9864 4.08657 13.5051 3.79234C13.4773 3.77535 13.4506 3.75675 13.425 3.73664L12.7047 3.17068C12.3444 2.88759 11.9718 2.59475 11.9466 2.57466L11.9406 2.56984ZM11.7107 7.90516L8.38623 5.31944C7.72079 6.16074 6.96419 7.11884 6.21768 8.06488C5.25774 9.28141 4.31839 10.4731 3.619 11.3606L2.46062 12.831L1.80557 12.3159C2.46029 12.8314 2.46062 12.831 2.46062 12.831L2.45863 12.8335C2.34832 12.9727 2.30802 13.153 2.34831 13.3264L2.89308 15.6582L3.60711 15.649C4.32576 15.6398 5.1005 15.6299 5.31957 15.6275C5.50529 15.6255 5.67651 15.5406 5.7888 15.3989C5.9227 15.23 7.94241 12.6751 9.93298 10.1557C10.5472 9.37827 11.1586 8.60436 11.7107 7.90516ZM13.8839 5.14916C13.8887 5.14273 13.892 5.13851 13.892 5.13851C13.892 5.13851 13.8898 5.14156 13.8839 5.14916Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M9.83304 16.0834C9.83304 15.6232 10.2061 15.2501 10.6664 15.2501H16.4997C16.9599 15.2501 17.333 15.6232 17.333 16.0834C17.333 16.5437 16.9599 16.9167 16.4997 16.9167H10.6664C10.2061 16.9167 9.83304 16.5437 9.83304 16.0834Z",
        },
      },
    ],
  })(props);
}

export default EditIcon;
