import { CopyOutlined } from "@ant-design/icons";
import Colors from "@colors";
import Box from "@components/Box";
import Container from "@components/Container";
import { Descriptions, message, Tooltip } from "antd";
import React from "react";
import { CopyButton, Title } from "../styles";
import tradutor_estados_uf from "./TradutorEstados";

const Endereco = (props) => {
  const copiaNoClick = () => {
    const texto = `Rua ${props.info.logradouro}, ${props.info.numero} (${
      props.info.complemento
    }) - ${props.info.bairro}, ${props.info.cidade} - ${tradutor_estados_uf(
      props.info.estado
    )}. CEP ${props.info.cep}`;
    navigator.clipboard.writeText(texto);
    message.info(`Endereço ${props.index} copiado com sucesso!`);
  };
  const title = (
    <>
      {"Endereço " + props.index}
      <CopyButton>
        <Tooltip title="Copiar" placement="right">
          <CopyOutlined
            style={{ fontSize: 20, color: Colors.deepBlue }}
            onClick={() => copiaNoClick()}
          />
        </Tooltip>
      </CopyButton>
    </>
  );

  return (
    <Container title={title} description minWidth={"400px"}>
      <Box margin={"0 8px 8px"}>
        <Descriptions bordered column={1} size={"small"}>
          <Descriptions.Item label={"Cidade"}>
            {props.info.cidade}
          </Descriptions.Item>
          <Descriptions.Item label={"UF"}>
            {tradutor_estados_uf(props.info.estado)}
          </Descriptions.Item>
          <Descriptions.Item label={"Logradouro"}>
            {props.info.logradouro}
          </Descriptions.Item>
          <Descriptions.Item label={"CEP"}>{props.info.cep}</Descriptions.Item>
          <Descriptions.Item label={"Número"}>
            {props.info.numero}
          </Descriptions.Item>
          <Descriptions.Item label={"Complemento"}>
            {props.info.complemento}
          </Descriptions.Item>
          <Descriptions.Item label={"Bairro"}>
            {props.info.bairro}
          </Descriptions.Item>
        </Descriptions>
      </Box>
    </Container>
  );
};
export default Endereco;
