import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Skeleton } from "antd";

export const Container = styled.div`
  height: 100%;
  border-radius: 0.75rem;
`;

export const SelectArea = styled.div`
  width: 30%;
  height: 68px;
  min-width: 15.75rem;
  padding: 0.75rem;
  background-color: ${defaultTheme.colors.white};
  border-radius: 0.75rem;
  z-index: 9999;
  position: absolute;
  top: 24px;
  left: 24px;

  & > div {
    margin-top: 0 !important;
    height: inherit;

    & > span {
      top: -0.45rem !important;
    }
  }

  .ant-select {
    height: 44px !important;
  }

  .ant-select-selector {
    height: 39px !important;
    padding: 7px !important;
  }

  .ant-select-selection-search {
    height: 38px;
  }

  .ant-select-arrow svg {
    display: ${({ hideArrowIcon }) => (hideArrowIcon ? "none" : "block")};
    margin-top: 4.5px !important;
  }

  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    margin-top: 4px !important;
  }

  .ant-select-clear {
    opacity: 1;
    top: 18px !important;
    right: 23px !important;
  }

  .ant-select-selection-placeholder {
    margin-top: 4px !important;
  }
`;

export const SkeletonMap = styled(Skeleton.Button)`
  height: 100% !important;
  border-radius: 0.75rem !important;
`;
