import { GenIcon } from "react-icons";

function EyeIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 25",
    },
    child: [
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M5.53392 8.67123C3.83543 9.96178 3 11.4523 3 12.5C3 13.5477 3.83543 15.0382 5.53392 16.3288C7.18575 17.5839 9.47655 18.5 12 18.5C14.5234 18.5 16.8143 17.5839 18.4661 16.3288C20.1646 15.0382 21 13.5477 21 12.5C21 11.4523 20.1646 9.96178 18.4661 8.67123C16.8143 7.41615 14.5234 6.5 12 6.5C9.47655 6.5 7.18575 7.41615 5.53392 8.67123ZM4.32394 7.07877C6.2914 5.58385 9.0006 4.5 12 4.5C14.9994 4.5 17.7086 5.58385 19.6761 7.07877C21.5969 8.53822 23 10.5477 23 12.5C23 14.4523 21.5969 16.4618 19.6761 17.9212C17.7086 19.4161 14.9994 20.5 12 20.5C9.0006 20.5 6.2914 19.4161 4.32394 17.9212C2.40315 16.4618 1 14.4523 1 12.5C1 10.5477 2.40315 8.53822 4.32394 7.07877Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M12 10.5C10.8954 10.5 10 11.3954 10 12.5C10 13.6046 10.8954 14.5 12 14.5C13.1046 14.5 14 13.6046 14 12.5C14 11.3954 13.1046 10.5 12 10.5ZM8 12.5C8 10.2909 9.79086 8.5 12 8.5C14.2091 8.5 16 10.2909 16 12.5C16 14.7091 14.2091 16.5 12 16.5C9.79086 16.5 8 14.7091 8 12.5Z",
        },
      },
    ],
  })(props);
}

export default EyeIcon;
