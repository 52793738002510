import { GenIcon } from "react-icons";

function Clock(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 16 16",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M7.99967 2.66671C5.05416 2.66671 2.66634 5.05452 2.66634 8.00004C2.66634 10.9456 5.05416 13.3334 7.99967 13.3334C10.9452 13.3334 13.333 10.9456 13.333 8.00004C13.333 5.05452 10.9452 2.66671 7.99967 2.66671ZM1.33301 8.00004C1.33301 4.31814 4.31778 1.33337 7.99967 1.33337C11.6816 1.33337 14.6663 4.31814 14.6663 8.00004C14.6663 11.6819 11.6816 14.6667 7.99967 14.6667C4.31778 14.6667 1.33301 11.6819 1.33301 8.00004Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M7.99967 4.66671C8.36786 4.66671 8.66634 4.96518 8.66634 5.33337V7.69907L10.3566 8.77027C10.6675 8.96737 10.7599 9.37926 10.5628 9.69025C10.3657 10.0012 9.95379 10.0936 9.6428 9.89648L7.6428 8.62893C7.4499 8.50668 7.33301 8.2942 7.33301 8.06583V5.33337C7.33301 4.96518 7.63148 4.66671 7.99967 4.66671Z",
        },
      },
    ],
  })(props);
}

export default Clock;
