import React, { useState, useEffect } from "react";
import { Tag, Space, Collapse, Checkbox } from "antd";
import { UploadButton } from "@components/UploadButton";
import { DisplayError } from "@components/DisplayError";
import { useFormContext } from "react-hook-form";
import { ConfirmModal } from "@components/ConfirmModal";
import { FormInput } from "@components/Form";
import BottomArrow from "@assets/icons/BottomArrow";
import LogisticService from "@LogisticService";
import * as S from "../styles";

export const InitialStep = ({
  handleCloseAndCleanDrawer,
  setStep,
  onChangeCheckbox,
  serialImage,
  setSerialImage,
  onKeyDown,
  selectedDefects,
  menuDefects,
  polo,
  props,
}) => {
  const { getValues, watch, control } = useFormContext();

  const [serialNumber, setSerialNumber] = useState("");
  const [serialNameError, setSerialNameError] = useState("");
  const [errorShowSerial, setShowErrorSerial] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (serialNumber !== "") {
      LogisticService.postValidaSerialDoa(serialNumber, polo)
        .then((response) => {
          console.log(response);
          if (response.status === 200 || response.data.sucesso) {
            setSerialNameError(response.data.sucesso.detail);
            setShowErrorSerial(false);
          } else {
            setSerialNameError(response.data.erro.detail);
            setShowErrorSerial(true);
          }
        })
        .catch((err) => {
          setSerialNameError(
            err?.response?.data?.erro?.detail ?? "Erro na validação de serial."
          );
          setShowErrorSerial(true);
        });
    }
  }, [setSerialNumber, serialNumber]);

  const handleBlur = () => {
    setSerialNumber(getValues("serial"));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleContinue = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    handleCloseAndCleanDrawer();
  };

  const footerModal = [
    <>
      <S.DrawerFooterButton onClick={handleContinue}>
        Continuar report
      </S.DrawerFooterButton>
      <S.DrawerFooterButton handleButton={true} onClick={() => handleCancel()}>
        Cancelar
      </S.DrawerFooterButton>
    </>,
  ];

  return (
    <>
      <ConfirmModal
        visible={isModalOpen}
        title="Você perderá todos os dados..."
        onOk={handleContinue}
        footer={footerModal}
      >
        <p>
          Se você fechar, todos os dados inseridos serão perdidos e não poderão
          ser recuperados. Tem certeza que deseja fechar?
        </p>
      </ConfirmModal>

      <S.AllDrawerContent>
        <S.ContentDrawer>
          <S.SelectCustomDrawer>
            <FormInput
              control={control}
              onKeyDown={onKeyDown}
              name="email_angel"
              label="E-mail corporativo"
              placeholder="Digite seu e-mail aqui"
              disabled={false}
            />
            <FormInput
              control={control}
              onKeyDown={onKeyDown}
              name="serial"
              label="Serial"
              placeholder="Digite ou bipe o código de barras"
              onBlur={handleBlur}
              bipe={true}
              disabled={false}
            />
          </S.SelectCustomDrawer>
          {errorShowSerial ? <DisplayError title={serialNameError} /> : <></>}

          <S.AnexoSerialContentDrawer>
            <S.AnexoSerialSubtitleDrawer>
              Você precisa anexar uma foto do serial:{" "}
            </S.AnexoSerialSubtitleDrawer>
            <UploadButton
              maxCount={1}
              nameUploadButton={"Anexar foto"}
              setImages={setSerialImage}
              images={serialImage}
              showButton={true}
            />
          </S.AnexoSerialContentDrawer>

          <S.TitleText>Defeitos identificados</S.TitleText>
          <S.SubtitleText>
            Selecione abaixo todos os defeitos identificados na maquininha.
          </S.SubtitleText>
          <Space>
            {selectedDefects.map((defect, index) => {
              return (
                <Tag key={index} style={{ border: "none" }}>
                  {defect.defectName}
                </Tag>
              );
            })}
          </Space>
          <Collapse
            ghost
            expandIconPosition={"end"}
            expandIcon={() => <BottomArrow color={"#767F8D"} size={24} />}
          >
            {menuDefects.map((defects, index) => {
              return (
                <S.Panel header={defects.title} key={index}>
                  {defects.defectsList.map((def) => {
                    return (
                      <Checkbox
                        value={def.nome}
                        key={def.id}
                        onChange={onChangeCheckbox}
                      >
                        {def.nome}
                      </Checkbox>
                    );
                  })}
                </S.Panel>
              );
            })}
          </Collapse>
        </S.ContentDrawer>
        <S.BottomContentDrawer>
          <S.DrawerFooterButton
            firstbutton={true}
            onClick={() => {
              setStep("evidenceStep");
            }}
            disabled={
              !(
                serialImage.length > 0 &&
                watch("email_angel") &&
                watch("serial") &&
                selectedDefects.length > 0 &&
                errorShowSerial === false
              )
            }
          >
            Próximo
          </S.DrawerFooterButton>
          <S.DrawerFooterButton
            onClick={() => {
              serialImage.length > 0 ||
              watch("email_angel") ||
              watch("serial") ||
              selectedDefects.length > 0
                ? showModal()
                : handleCloseAndCleanDrawer();
            }}
          >
            Fechar
          </S.DrawerFooterButton>
        </S.BottomContentDrawer>
      </S.AllDrawerContent>
    </>
  );
};
