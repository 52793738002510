import styled from "styled-components";

export const MarkerContainer = styled.div`
    max-width: 66px;
    height: 24px;
    display: inline-flex;
    gap: 2px;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    border: ${(props) => props.isSelected ? '1.5px dashed #0ca33f' : 'none'};
    background: ${(props) => props.isSelected ? '#15e254' : props.background};

    ::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7.3px solid ${(props) => props.isSelected ? '#15e254' : props.background};
    }
`;
export const MarkerLeftContent = styled.div`
    color: #FFF;
`;

export const MarkerRightContent = styled.div`
    color: #FFF;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-size: 10px;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.1px;
`;

export const HoverContainer = styled.div`
    position: absolute;
    bottom: 2rem;
    left: -8rem;
`;

export const HoverHeader = styled.div`
    width: 318px;
    height: 43px;
    padding: 12px;
    gap: 10px;
    border-radius: 12px 12px 0px 0px;
    opacity: 0px;
    background-color: ${(props) => props.background};

    p{
        font-size: 13px;
        font-weight: 600;
        line-height: 18.85px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #FFFFFF;
    }
`;

export const HoverContent = styled.div`
    width: 318px;
    border-radius: 0px 0px 12px 12px;
    padding: 8px 12px 12px 12px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const HoverContentTextTagged = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span{
        color: var(--Content-Neutral, #767F8D);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%; /* 18.85px */
        letter-spacing: -0.13px;
    }

    p{
        color: var(--Content-Neutral-High, #303742);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 145%; /* 18.85px */
        letter-spacing: -0.13px;
    }
    
`;

export const HoverContentText = styled.div`
    display: flex;
    gap: 16px;
    
    span{
        color: var(--Content-Neutral, #767F8D);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%; /* 18.85px */
        letter-spacing: -0.13px;
    }

    p{
        color: var(--Content-Neutral-High, #303742);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 145%; /* 18.85px */
        letter-spacing: -0.13px;
    }
`;
