import React from "react";
import { Controller } from "react-hook-form";
import SearchCustomBox from "../SearchCustomBox";

const FormSearchBox = ({
  name,
  control,
  label,
  onClean,
  onSearch,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <SearchCustomBox
        label={label}
        value={value}
        onSearch={onSearch}
        onClean={onClean}
        onChange={onChange}
        hasError={!!error?.message}
        {...props}
      />
    )}
  />
);

export default FormSearchBox;
