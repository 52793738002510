import { Space, Switch, TimePicker, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Box from "../Box";
import Row from "../Row";
import moment from "moment";
import { WeekDay, ReplicarButton } from "./styles";
import { SnippetsOutlined } from "@ant-design/icons";
import Colors from "@colors";

const WeekPicker = (props) => {
  const [horariosDeFuncionamento, setHorariosDeFuncionamento] = useState(
    props.defaultValues || {
      segunda: { state: true, open: "9:00", closed: "18:00" },
      terça: { state: true, open: "9:00", closed: "18:00" },
      quarta: { state: true, open: "9:00", closed: "18:00" },
      quinta: { state: true, open: "9:00", closed: "18:00" },
      sexta: { state: true, open: "9:00", closed: "18:00" },
      sábado: { state: false, open: null, closed: null },
      domingo: { state: false, open: null, closed: null },
    }
  );
  const format = "HH:mm";

  const onChangeState = (changed, weekday) => {
    let dict = horariosDeFuncionamento;
    dict[weekday]["state"] = changed;
    setHorariosDeFuncionamento({ ...dict });
  };

  const onChange = (changed, weekday, key) => {
    let dict = horariosDeFuncionamento;
    dict[weekday][key] = changed ? changed.format(format) : changed;
    setHorariosDeFuncionamento({ ...dict });
  };

  const replicar = (weekday) => {
    let dict = horariosDeFuncionamento;
    const base = dict[weekday];
    Object.keys(horariosDeFuncionamento).map((key) => {
      if (horariosDeFuncionamento[key]["state"]) {
        horariosDeFuncionamento[key]["open"] = base["open"];
        horariosDeFuncionamento[key]["closed"] = base["closed"];
      }
    });
    setHorariosDeFuncionamento({ ...dict });
  };

  useEffect(() => {
    props.setHorariosDeFuncionamento(horariosDeFuncionamento);
  }, [horariosDeFuncionamento]);

  return (
    <Box>
      {Object.keys(horariosDeFuncionamento).map((weekday, index) => (
        <Row
          verticalAlign={"center"}
          margin={"4px 0"}
          padding={props.replicavel ? "0 32px 0 0" : 0}
          relative
          nonResponsive
        >
          <Space>
            <Switch
              checked={horariosDeFuncionamento[weekday]["state"]}
              onChange={(changed) => onChangeState(changed, weekday)}
            />
            <WeekDay>{weekday}</WeekDay>
          </Space>
          <Space>
            <TimePicker
              style={{ width: 90 }}
              onChange={(changed) => onChange(changed, weekday, ["open"])}
              value={
                horariosDeFuncionamento[weekday]["open"]
                  ? moment(horariosDeFuncionamento[weekday]["open"], format)
                  : null
              }
              format={format}
              disabled={!horariosDeFuncionamento[weekday]["state"]}
            />
            até
            <TimePicker
              style={{ width: 90 }}
              onChange={(changed) => onChange(changed, weekday, ["closed"])}
              value={
                horariosDeFuncionamento[weekday]["closed"]
                  ? moment(horariosDeFuncionamento[weekday]["closed"], format)
                  : null
              }
              format={format}
              disabled={!horariosDeFuncionamento[weekday]["state"]}
            />
          </Space>
          {props.replicavel ? (
            <ReplicarButton>
              <Tooltip title="Replicar" placement="top">
                <SnippetsOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => replicar(weekday)}
                />
              </Tooltip>
            </ReplicarButton>
          ) : (
            <></>
          )}
        </Row>
      ))}
    </Box>
  );
};

export default WeekPicker;
