import React, { forwardRef } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import FloatLabel from "../FloatLabel";
import EyeIcon from "@assets/icons/EyeIcon";
import BipeIcon from "@assets/icons/BipeIcon";
import * as S from "./styles";

export const Input = forwardRef(
  ({ label, hasError, bipe, editing, checked = false, ...props }, ref) => {
    const suffix = () => {
      if (checked) return <EyeIcon size={24} color="#008E5A" />;
      if (bipe) return <BipeIcon size={24} color="#767F8D" />;
      return <BipeIcon size={24} color="transparent" />;
    };

    return (
      <FloatLabel label={label} hasError={hasError} editing={editing}>
        <S.InputBox
          disabled={props.disabled ? props.disabled : false}
          ref={ref}
          status={hasError ? "error" : ""}
          suffix={suffix()}
          defaultValue={props.defaultValue ? props.defaultValue : null}
          className="ginga-input"
          {...props}
        />
      </FloatLabel>
    );
  }
);
