import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import appConfig from "../../consumingApi/services/appConfig";
import LogisticService from "@LogisticService";
import { manuallyAlocatePin } from "./pinsSlice";

const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = "senninhaManageRoutes";
const POST_MANAGEALLROUTES = "senninhaManageRoutes/postManageAllRoutes";

const initialState = {
  manageAllRoutesProgress: {
    successes: [],
    errors: [],
    errorsMessages: [],
    retryPayload: [],
  },
  unassociatedPinAnyData: {
    unassociatedPin: null,
    deliveriesPins2BeAssociated: null,
  },
  statusManageAllRoutes: "idle",
  errorManageAllRoutes: null,
};
// TODO: remover consoles
export const postManageAllRoutes = createAsyncThunk(
  POST_MANAGEALLROUTES,
  async ({ data, batchSize = 3 }, { rejectWithValue, dispatch }) => {
    // const numBatches = Math.ceil(data.length / batchSize);
    const dataLength = data.reduce(
      (total, item) => total + item.pins.length,
      0
    );
    const numBatches = Math.ceil(dataLength / batchSize);
    let changesCount = 0;
    let successes = [];
    let errors = [];
    let errorsMessages = [];
    let errorsAux = [];

    console.log("data", data);
    for (const routeData of data) {
      // console.log('routeData', routeData);
      for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
        const batchData = routeData.pins.slice(
          batchIndex * batchSize,
          (batchIndex + 1) * batchSize
        );

        if (batchIndex > 0)
          await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log("batchData", batchData);
        await Promise.all(
          batchData.map(async (item, i) => {
            if (item.servico.toLowerCase() !== "coleta") {
              const payload = {
                id_atendimento:
                  item.tipo === "ANY" ? item.oss[0] : item.id_atendimento,
                contratante: item.contratante,
                angel:
                  routeData.type === "deallocation"
                    ? ""
                    : routeData.angel.email,
                documento:
                  routeData.type === "deallocation"
                    ? ""
                    : routeData.angel.document_number,
                polo: routeData.polo,
                campanha: routeData.campanha,
                oss: item.oss,
              };

              const today = new Date();
              const yesterday = new Date(today);
              yesterday.setDate(yesterday.getDate() - 1);

              const customerDeadlineDate = new Date(
                item.oss[0].customer_deadline_date
              );

              try {
                // TODO: remover mock
                // await new Promise((resolve) => setTimeout(resolve, 1000));
                // if (i === 1 || i === 2) console.log(asd.asd)
                await LogisticService.postWithAuth(
                  `${BASE_URL}/atendimento/senninha/rotas/${routeData.polo}`,
                  payload
                );
                // console.log('mocked req ->', routeData.type, payload, 'res');
                console.log(routeData);

                successes = [...successes, item];
                dispatch(
                  addProgressState({
                    type: "success",
                    data: item,
                  })
                );
                dispatch(
                  manuallyAlocatePin({
                    id: item.id,
                    angel:
                      routeData.type === "deallocation"
                        ? customerDeadlineDate < yesterday
                          ? "Vencidas (Não alocadas)"
                          : customerDeadlineDate > today
                          ? "Vencimento futuros (Não alocadas)"
                          : "Vencem hoje (Não alocadas)"
                        : routeData.angel.nome,
                  })
                );
              } catch (err) {
                console.log("err", err);
                const error = err?.response?.data?.erro || "error";
                errorsMessages.push(`${item.id_atendimento} - ${error}`);
                errors = [...errors, item];
                errorsAux = [...errorsAux, item];
                dispatch(
                  addProgressState({
                    type: "error",
                    data: item,
                  })
                );
              } finally {
                changesCount += 1;
              }
            }
          })
        );

        if (errorsAux.length) {
          dispatch(
            addProgressState({
              type: "retry",
              data: {
                ...routeData,
                ossAmount: errorsAux.reduce(
                  (total, item) => total + item.oss.length,
                  0
                ),
                pins: errorsAux,
              },
            })
          );
          errorsAux = [];
        }
      }
    }

    if (errors.length) {
      return rejectWithValue({ errors, errorsMessages });
    }

    return { successes, errors, errorsMessages };
  }
);

export const routesManagementSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    addProgressState: (state, action) => {
      const { type, data } = action.payload;
      console.log("addProgressState", type, data);
      if (type === "success") {
        state.manageAllRoutesProgress = {
          ...state.manageAllRoutesProgress,
          successes: [...state.manageAllRoutesProgress.successes, data],
        };
      } else if (type === "error") {
        state.manageAllRoutesProgress = {
          ...state.manageAllRoutesProgress,
          errors: [...state.manageAllRoutesProgress.errors, data],
        };
      } else {
        state.manageAllRoutesProgress = {
          ...state.manageAllRoutesProgress,
          retryPayload: [...state.manageAllRoutesProgress.retryPayload, data],
        };
      }
    },
    resetRoutesManagementStates: (state) => {
      (state.manageAllRoutesProgress = {
        successes: [],
        errors: [],
        errorsMessages: [],
        retryPayload: [],
      }),
        (state.statusManageAllRoutes = "idle");
      state.errorManageAllRoutes = null;
    },
    resetRoutesManagementStatus: (state) => {
      state.statusManageAllRoutes = "idle";
    },
    resetRoutesManagementSlice: () => {
      state = initialState;
    },
    setUnassociatedPinAnyData: (state, action) => {
      state.unassociatedPinAnyData = action.payload;
    },
    resetUnassociatedPinAnyData: (state) => {
      state.unassociatedPinAnyData = {
        unassociatedPin: null,
        deliveriesPins2BeAssociated: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postManageAllRoutes.pending, (state) => {
        state.statusManageAllRoutes = "pending";
        state.errorManageAllRoutes = null;
      })
      .addCase(postManageAllRoutes.fulfilled, (state, action) => {
        state.manageAllRoutesProgress = action.payload;
        state.statusManageAllRoutes = "fulfilled";
      })
      .addCase(postManageAllRoutes.rejected, (state, action) => {
        const { errors, errorsMessages } = action.payload;
        state.manageAllRoutesProgress = {
          ...state.manageAllRoutesProgress,
          errors,
          errorsMessages,
        };
        state.errorManageAllRoutes = action.payload;
        state.statusManageAllRoutes = "rejected";
      });
  },
});

export const {
  addProgressState,
  resetRoutesManagementSlice,
  resetRoutesManagementStates,
  resetRoutesManagementStatus,
  setUnassociatedPinAnyData,
  resetUnassociatedPinAnyData,
} = routesManagementSlice.actions;

export const routesManagementSelector = (state) => state.routesManagementSlice;

export default routesManagementSlice.reducer;
