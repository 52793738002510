import styled from "styled-components";

export const RelocateCard = styled.div`
  position: absolute;
  background: white;
  margin: 0.5rem 0rem 0rem 0.5rem;
  padding: 1rem;
  width: 20.9374rem;
  border-radius: 8px;
`;

export const TitleRelocateCard = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(48, 55, 66, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SubtitleRelocateCard = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(118, 127, 141, 1);
  border-bottom: 1px solid rgba(118, 127, 141, 0.1);
  padding-bottom: 1rem;
`;
export const SubtitleImgRelocate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  p {
    color: var(--content-neutral-high, #303742);
    font-family: Sharon Sans;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.00938rem;
  }
  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(118, 127, 141, 1);
    padding-top: 0.5rem;
  }
`;

export const ErrorTitle = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    p {
        color: #767F8D
        text-align: center;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.00813rem;
    }
    `;
export const ErrorCard = styled.div`
  height: 12rem;
  display: flex;
  padding: 1rem 0.5rem;
  align-items: flex-start;
  margin-top: 0.5rem;
  gap: 0.5rem;
  align-self: stretch;
  background: rgba(247, 247, 250, 1);
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 0.5rem;
  max-height: 12rem;
  height: fit-content;
`;

export const ErrorContent = styled.div`
  color: #303742;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.00813rem;
`;
