import React, { useState } from "react";
import { Button, Select } from "@stonelog/stonelog-design-system";
import { Switch } from "antd";
import { useSelector } from "react-redux";
import { angelsSelector } from "../../../../../../../../../features/senninha/angelsSlice";
import { hubsSelector } from "../../../../../../../../../features/senninha/hubsSlice";
import { pinsSelector } from "../../../../../../../../../features/senninha/pinsSlice";

import * as S from "./styles";

const SwapRoutes = ({ setNewWorkFlow, setRoutes }) => {
  const [selectedAngel1, setSelectedAngel1] = useState();
  const [selectedAngel2, setSelectedAngel2] = useState();
  const [shouldConsiderCampaign, setShouldConsiderCampaign] = useState(false);

  const { selectedHubInfos } = useSelector(hubsSelector);

  const { angels, status } = useSelector(angelsSelector);

  const { pins } = useSelector(pinsSelector);

  const getAngelsOptions = () =>
    angels?.map(({ nome }) => ({
      value: nome,
      label: nome,
    }));

  const handleSaveClick = () => {
    setRoutes((p) => [
      ...p,
      {
        type: "exchange",
        angel1: angels?.find(({ nome }) => nome === selectedAngel1),
        pins1: pins.filter(
          (item) =>
            item.angel === selectedAngel1 &&
            (shouldConsiderCampaign
              ? item.servico?.toLowerCase() === "campanha"
              : item.servico?.toLowerCase() !== "campanha")
        ),
        angel2: angels?.find(({ nome }) => nome === selectedAngel2),
        pins2: pins.filter(
          (item) =>
            item.angel === selectedAngel2 &&
            (shouldConsiderCampaign
              ? item.servico?.toLowerCase() === "campanha"
              : item.servico?.toLowerCase() !== "campanha")
        ),
        polo: selectedHubInfos.children,
        campanha: shouldConsiderCampaign,
      },
    ]);
    // resetData()
    setNewWorkFlow(false);
  };

  return (
    <S.SwapContainer>
      <S.SwapSelects>
        <Select
          label="Angel 1"
          placeholder="Selecione um Angel"
          options={getAngelsOptions().filter(
            ({ value }) => value !== selectedAngel2
          )}
          value={selectedAngel1}
          onChange={(v) => setSelectedAngel1(v)}
          loading={status === "pending"}
          size="small"
        />
        <Select
          label="Angel 2"
          placeholder="Selecione um Angel"
          options={getAngelsOptions().filter(
            ({ value }) => value !== selectedAngel1
          )}
          value={selectedAngel2}
          onChange={(v) => setSelectedAngel2(v)}
          loading={status === "pending"}
          size="small"
        />
      </S.SwapSelects>
      <S.SwapCampaign>
        <div>
          <span>OS de campanha</span>
          <p>Incluir OS de Campanha na troca de rotas.</p>
        </div>
        <Switch
          value={shouldConsiderCampaign}
          onChange={(v) => setShouldConsiderCampaign(v)}
        />
      </S.SwapCampaign>
      <S.Footer>
        <Button
          size="small"
          onClick={() => {
            setNewWorkFlow(false);
            dispatch(resetSelectedRelocatePins());
          }}
        >
          Cancelar
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={handleSaveClick}
          disabled={!selectedAngel1 || !selectedAngel2}
        >
          Salvar
        </Button>
      </S.Footer>
    </S.SwapContainer>
  );
};

export default SwapRoutes;
