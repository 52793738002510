import React from "react";

import { DefaultInput, DefaultInputNumber } from "./styles";

const Input = (props) => {
  const sharedProps = {
    focus: { cursor: props.focus ? props.focus : "end" },
    autoFocus: props.autoFocus || false,
  };

  return props.type === "TextArea" ? (
    <DefaultInput.TextArea {...sharedProps} {...props} />
  ) : props.type === "Number" ? (
    <DefaultInputNumber {...sharedProps} {...props} />
  ) : props.type === "Password" ? (
    <DefaultInput.Password {...sharedProps} {...props} />
  ) : (
    <DefaultInput {...sharedProps} {...props} />
  );
};

export default Input;
