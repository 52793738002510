import { GenIcon } from "react-icons";

function DeviceMachineIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 16 21",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M6 4.5C6 3.94772 6.44772 3.5 7 3.5H9C9.55229 3.5 10 3.94772 10 4.5C10 5.05229 9.55229 5.5 9 5.5H7C6.44772 5.5 6 5.05229 6 4.5Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M6.92943 0.500001L10.5343 0.500003C11.2041 0.499994 11.7569 0.499987 12.2095 0.530868C12.6788 0.562887 13.1129 0.631421 13.5307 0.804485C14.5108 1.21046 15.2895 1.98916 15.6955 2.96927C15.8686 3.38708 15.9371 3.82118 15.9691 4.29047C16 4.74307 16 5.29594 16 5.96573L16 13.5706C16 14.9247 16.0001 16.0413 15.8813 16.9251C15.7565 17.8529 15.4845 18.6723 14.8284 19.3284C14.1723 19.9845 13.3529 20.2565 12.4251 20.3813C11.5413 20.5001 10.4247 20.5 9.07059 20.5H6.92946C5.57535 20.5 4.45869 20.5001 3.57494 20.3813C2.64711 20.2565 1.82769 19.9845 1.17158 19.3284C0.515468 18.6723 0.243499 17.8529 0.118756 16.9251C-6.12736e-05 16.0413 -3.30577e-05 14.9247 1.15588e-06 13.5706V7.42943C-3.30577e-05 6.07533 -6.12736e-05 4.95869 0.118756 4.07494C0.243499 3.14711 0.515468 2.32769 1.17158 1.67158C1.82769 1.01547 2.64711 0.743499 3.57494 0.618756C4.45869 0.499939 5.57533 0.499967 6.92943 0.500001ZM3.84144 2.60092C3.13538 2.69585 2.80836 2.86322 2.58579 3.08579C2.36322 3.30836 2.19585 3.63538 2.10092 4.34144C2.00213 5.07626 2 6.05752 2 7.5V13.5C2 13.8611 2.00014 14.1933 2.00189 14.5H13.9981C13.9999 14.1933 14 13.8611 14 13.5V6C14 5.28741 13.9995 4.80304 13.9738 4.42661C13.9487 4.05915 13.9031 3.86814 13.8478 3.73464C13.6448 3.24458 13.2554 2.85523 12.7654 2.65224C12.6319 2.59695 12.4409 2.5513 12.0734 2.52623C11.697 2.50055 11.2126 2.5 10.5 2.5H7C5.55752 2.5 4.57626 2.50213 3.84144 2.60092ZM13.9185 16.5H2.0815C2.08752 16.5541 2.09398 16.607 2.10092 16.6586C2.19585 17.3646 2.36322 17.6916 2.58579 17.9142C2.80836 18.1368 3.13538 18.3042 3.84144 18.3991C4.57626 18.4979 5.55752 18.5 7 18.5H9C10.4425 18.5 11.4238 18.4979 12.1586 18.3991C12.8646 18.3042 13.1916 18.1368 13.4142 17.9142C13.6368 17.6916 13.8042 17.3646 13.8991 16.6586C13.906 16.607 13.9125 16.5541 13.9185 16.5Z",
        },
      },
    ],
  })(props);
}

export default DeviceMachineIcon;
