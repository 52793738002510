import { GenIcon } from "react-icons";

function UploadIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 18 18",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M12.2197 5.78035C12.5126 6.07323 12.9875 6.07321 13.2804 5.78031C13.5732 5.4874 13.5732 5.01253 13.2803 4.71965L9.53 0.969648C9.23709 0.676767 8.76222 0.676787 8.46934 0.969692L4.71965 4.71969C4.42677 5.0126 4.42679 5.48747 4.71969 5.78035C5.0126 6.07323 5.48747 6.07321 5.78036 5.78031L8.23151 3.32895L8.23151 11.25C8.23151 11.6642 8.5673 12 8.98151 12C9.39572 12 9.73151 11.6642 9.73151 11.25L9.73151 3.29237L12.2197 5.78035Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M2.25 9.375C2.66422 9.375 3 9.71079 3 10.125V10.875C3 11.9569 3.0016 12.6928 3.07569 13.2439C3.14689 13.7735 3.27241 14.0187 3.43934 14.1857C3.60627 14.3526 3.85154 14.4781 4.38108 14.5493C4.93219 14.6234 5.66814 14.625 6.75 14.625H11.25C12.3319 14.625 13.0678 14.6234 13.6189 14.5493C14.1485 14.4781 14.3937 14.3526 14.5607 14.1857C14.7276 14.0187 14.8531 13.7735 14.9243 13.2439C14.9984 12.6928 15 11.9569 15 10.875V10.125C15 9.71079 15.3358 9.375 15.75 9.375C16.1642 9.375 16.5 9.71079 16.5 10.125V10.9279C16.5 11.9435 16.5001 12.781 16.4109 13.4438C16.3174 14.1397 16.1134 14.7542 15.6213 15.2463C15.1292 15.7384 14.5147 15.9424 13.8188 16.0359C13.156 16.125 12.3185 16.125 11.3029 16.125H6.69707C5.68148 16.125 4.84402 16.125 4.18121 16.0359C3.48533 15.9424 2.87076 15.7384 2.37868 15.2463C1.8866 14.7542 1.68262 14.1397 1.58907 13.4438C1.49995 12.781 1.49998 11.9435 1.5 10.928L1.5 10.125C1.5 9.71079 1.83579 9.375 2.25 9.375Z",
        },
      },
    ],
  })(props);
}

export default UploadIcon;
