export default function tradutor_tipo_equipamento(value) {
  if (value === "Bluetooth | Wi-Fi | 3G HSPA+") {
    return "GPRS-WIFI";
  }
  if (value === "Smart POS") {
    return "SMART POS";
  }
  if (value === "Ethernet") {
    return "ETHERNET";
  }
  if (value === "SUNMI P2-B") {
    return "P2";
  }
  if (value === "Verifone V240M") {
    return "V240M";
  }
  if (value === "Ingenico iPP320") {
    return "IPP320 DUAL";
  }
  if (value === "Gertec PPC920 DUAL") {
    return "PPC 920";
  }
  if (value === "Gertec PPC920 USB") {
    return "PPC 920";
  }
  if (value === "Gertec - PPC 930") {
    return "PPC 930 USB";
  }
  if (value === "Gertec MP35P") {
    return "MP35P";
  }
  if (value === "Positivo L3") {
    return "L3";
  }
  if (value === "Ingenico APOS A8") {
    return "A8 VERDE";
  }
  if (value === "Verifone VX680") {
    return "VX 685 GPRS";
  }
  if (value === "Ingenico IWL280") {
    return "D210 GPRS";
  }
  if (value === "D200") {
    return "D200 BLUETOOTH";
  }
  if (value === "D210") {
    return "D210 GPRS";
  }
  if (value === "Mobile") {
    return "A920";
  }
  if (value === "Verifone VX685") {
    return "VX 685 GPRS";
  }
  if (value === "Ingenico iCT250") {
    return "ICT 250";
  }
  if (value === "Gertec MOBI PIN 10") {
    return "MOBPIN 10";
  }
  if (value === "D180") {
    return "D180 BLUETOOTH";
  }
  if (value === "Gertec PPC920 DUAL") {
    return "PPC 920";
  }
  if (value === "Ingenico iWL250") {
    return "IWL250G";
  }
  if (value === "Gertec PPC920 USB") {
    return "PPC 920 USB";
  }
  if (value === "Verifone VX520") {
    return "VX 520";
  }
  if (value === "Verifone VX690") {
    return "VX 690";
  }
  if (value === "Perto Veloh") {
    return "VELOH W (3G AUDIO)";
  }
  if (value === "Ingenico Move 5000") {
    return "MOVE 5000";
  }
  if (value === "Verifone C680") {
    return "C680";
  }
  if (value === "Gertec GPOS700X") {
    return "GPOS700X";
  }
  if (value === "Ingenico LANE 3000") {
    return "LANE 3000";
  } else {
    return value;
  }
}
