import React from "react";
import CalendarCloseIcon from "@assets/icons/CalendarCloseIcon";
import CalendarIcon from "@assets/icons/CalendarIcon";
import { DatePicker } from "antd";
import FloatLabel from "@components/FloatLabel";
import * as S from "./styles";

export const DatePickerCustom = ({
  label,
  placeholder,
  format,
  disabled,
  onChange,
  disabledDate,
  value,
  allowClear,
}) => {
  return (
    <FloatLabel label={label} marginBottom={'0px'}>
      <S.CustomDate>
        <DatePicker
          suffixIcon={value !== '' && value !== null ? <CalendarCloseIcon size={24} color={'#767F8D'} /> : <CalendarIcon size={24} color={'#767F8D'}/>}
          clearIcon={<CalendarCloseIcon size={24} />}
          placeholder={placeholder}
          format={format}
          disabled={disabled}
          onChange={onChange}
          disabledDate={disabledDate}
          value={value}
          allowClear={allowClear}
          className="ginga-date-picker"
        />
      </S.CustomDate>
    </FloatLabel>
  );
};
