import React from "react";
import { MessageErrorText } from "./styles";

export const FormMessageError = ({ message, color = "#F38428" }) => {
  return (
    <>
      {message && <MessageErrorText color={color}>{message}</MessageErrorText>}
    </>
  );
};
