import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const ErrorViewer = styled.div`
  border: 1px solid ${defaultTheme.colors.grey10};
  margin-top: -10px;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  background: ${(props) =>
    props.errorType === "Error"
      ? "rgba(247, 62, 65, 0.1)"
      : "rgba(243, 132, 40, 0.1)"};
  border: ${(props) =>
    props.errorType === "Error"
      ? "1.5px solid rgba(247, 62, 65, 0.1)"
      : "1.5px solid rgba(243, 132, 40, 0.1)"};
`;

export const ErrorViewerContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -3px;
  p {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: rgba(48, 55, 66, 1);
  }
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: rgba(48, 55, 66, 1);
  }
`;
