import React from "react";
import InfoDrawer from "./InfoDrawer";
import RoutesDrawer from "./RoutesDrawer";
import { clearServiceDetails, pinsSelector, setMapWorkflow, setOpenDrawer } from "../../../../../../features/senninha/pinsSlice";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";


const Drawers = () => {
    const dispatch = useDispatch();

    const {
        openDrawer,
        mapWorkflow
    } = useSelector(pinsSelector);

    const handleOnClose = () => {
        dispatch(setOpenDrawer(false))
        dispatch(setMapWorkflow('infoDrawer'))
        dispatch(clearServiceDetails())
    }

    const showNotification = (message, onClose) => {
        notification.info({
          type: 'success',
          message,
          placement: 'bottomLeft',
          className: 'notification-ginga-design notification-ginga-design-any',
          onClose,
          closeIcon: 
            <div style={{
                backgroundColor: '#303742',
                borderRadius: '0.5rem',
                padding: '0.625rem 1rem',
                color: '#42EC9A',
                fontSize: 13,
                letterSpacing: '-0.01em',
                marginBottom: '1rem',
                fontWeight: 600,
            }}>
                Fechar
            </div>,
        });
    };

    return (
        <>
            {mapWorkflow.includes('infoDrawer') && (
                <InfoDrawer
                    open={openDrawer}
                    onClose={() => handleOnClose()} 
                />
            )}
            {mapWorkflow.includes('routesDrawer') && (
                <RoutesDrawer
                    open={openDrawer}
                    onClose={() => handleOnClose()}
                    showNotification={showNotification}
                />
            )}

        </>
    )
};

export default Drawers;
