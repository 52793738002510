import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Upload } from "antd";

export const AllUploadContainer = styled.div`
  border: 1px solid ${defaultTheme.colors.grey10};
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
  padding: 12px;

  .ant-upload.ant-upload-select {
    width: 100%;
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    border: 1px solid #3037421a !important;
    border-radius: 8px !important;
  }
`;

export const UploadContainerTop = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid ${defaultTheme.colors.grey50};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UploadContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UploadTitle = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #767f8d;
`;

export const UploadSubTitle = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #303742;
`;

export const UploadContainerBottom = styled.div`
  & > span {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const UploadContainer = styled(Upload)``;

export const ButtonUpload = styled.button`
  border-radius: 8px;
  width: 100%;
  border: none !important;
  padding: 10px 16px 10px 16px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(118, 127, 141, 0.1);
  color: #303742;
  letter-spacing: -0.01em;
  font-size: 13px;
  font-weight: 600;
  line-height: 120%;
  cursor: pointer;
  &:hover {
    background-color: ${defaultTheme.colors.grey10};
  }
`;
