import React from "react";
import * as S from "./styles";

const FloatLabel = ({
  label,
  editing,
  hasError = false,
  htmlFor,
  children,
  marginBottom,
}) => {
  return (
    <S.FloatLabelContent marginBottom={marginBottom}>
      <S.LableContent htmlFor={htmlFor} editing={editing}>
        {label}
      </S.LableContent>
      {children}
    </S.FloatLabelContent>
  );
};

export default FloatLabel;
