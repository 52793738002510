import React, { useEffect, useRef, useState } from "react";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { Button } from "@stonelog/stonelog-design-system";
import { useDispatch, useSelector} from "react-redux";
import { setOldSenninhaWorkFlow } from "../../../../../features/senninha/oldSenninhaSlice";
import Comerciais from "../../../Senninha/componentes/Ferramentas/POSNaMochila/componentes/Comerciais";
import Seriais from "../../../Senninha/componentes/Ferramentas/POSNaMochila/componentes/Seriais";
import { hubsSelector } from "../../../../../features/senninha/hubsSlice";

import * as S from "./styles";


const PosNaMochila = () => {

    const dispatch = useDispatch();

    const [mochilaWorkflow, setMochilaWorkflow] = useState('')

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    return (
        <>
            {
                mochilaWorkflow === '' && (
                    <S.MochilaContainer>
                        <S.MochilaHeader>
                            Pos na Mochila
                            <GingaIcon
                                name="close"
                                color="#303742"
                                style={{ cursor: 'pointer' }}
                                onClick={() => dispatch(setOldSenninhaWorkFlow(''))}
                            />
                        </S.MochilaHeader>
                        <S.MochilaBody>
                            <Button
                                type="primary"
                                width="100%"
                                onClick={() => setMochilaWorkflow('comercial')}
                            >
                                Comerciais
                            </Button>
                            <Button
                                type="primary"
                                width="100%"
                                onClick={() => setMochilaWorkflow('serial')}
                            >
                                Seriais
                            </Button>
                        </S.MochilaBody>
                    </S.MochilaContainer>
                )
            }
            {
                mochilaWorkflow === 'comercial' && (
                    <S.MochilaComerciais>
                        <GingaIcon
                                name="chevron-left"
                                color="#303742"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setMochilaWorkflow('')}
                            />
                        <Comerciais polo={selectedHubInfos.value} />
                    </S.MochilaComerciais>
                )
            }
            {
                mochilaWorkflow === 'serial' && (
                    <S.MochilaComerciais>
                        <GingaIcon
                                name="chevron-left"
                                color="#303742"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setMochilaWorkflow('')}
                            />
                        <Seriais polo={selectedHubInfos.children} />
                    </S.MochilaComerciais>
                )
            }
        </>
    );
};

export default PosNaMochila;
