import { GenIcon } from "react-icons";

function InfoIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M10.7621 7.9999C10.7621 7.32335 11.3106 6.7749 11.9871 6.7749H12.01C12.6865 6.7749 13.235 7.32335 13.235 7.9999C13.235 8.67645 12.6865 9.2249 12.01 9.2249H11.9871C11.3106 9.2249 10.7621 8.67645 10.7621 7.9999Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M2 12C2 6.4768 6.4768 2 12 2C17.5222 2 22 6.47673 22 12C22 17.5222 17.5222 22 12 22C6.47673 22 2 17.5222 2 12ZM12 4C7.58137 4 4 7.58137 4 12C4 16.4177 7.58144 20 12 20C16.4177 20 20 16.4177 20 12C20 7.58144 16.4177 4 12 4Z",
        },
      },
    ],
  })(props);
}

export default InfoIcon;
