import { LoadingOutlined } from "@ant-design/icons";
import Box from "@components/Box";
import CustomButton from "@components/CustomButton";
import LoadingSpinner from "@components/LoadingSpinner";
import Row from "@components/Row";
import moment from "moment";
import SelectBox from "@components/SelectBox";
import {
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Checkbox,
  message,
} from "antd";
import { default as React, useEffect, useState } from "react";
import LogisticService from "../../../../../consumingApi/services/LogisticService";
import { Bold, FormItem, Text } from "./styles";
import { useAuthContext } from "@stonelog/stonelog-auth-components";

const { Option } = Select;

export default function GestaoPessoas({
  angelConfiguracoes,
  data,
  poloAntigo,
  onFinishGestao,
  setVisible,
}) {
  const [form] = Form.useForm();
  const [saved, setSaved] = useState(true);
  const [angel, setAngel] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [polosTemporarios, setPolosTemporarios] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [poloSelecionado, setPoloSelecionado] = useState(null);
  const [poloInicial, setPoloInicial] = useState(null);
  const [justificativa, setJustificativa] = useState(null);
  const [retornoObrigatorio, setRetornoObrigatorio] = useState(false);
  const [disabledExceptTurnover, setDisabledExceptTurnover] = useState(null);
  const [disabledExceptArea, setDisabledExceptArea] = useState(null);
  const [angelInativo, setAngelInativo] = useState(false);
  const [justificativaObrigatorio, setJustificativaObrigatorio] = useState(
    false
  );
  const [todosPolos, setTodosPolos] = useState([]);
  const auth = useAuthContext();
  const userName = auth.getUser();

  const defaultValues = {
    permanente: false,
    email_angel: angel,
    justificativa: null,
    modificado_por: `|${userName.email}|`,
    polo_atual: poloInicial,
  };

  useEffect(() => {
    LogisticService.getPolos().then((response) => {
      setTodosPolos(response.data);

      if (angelConfiguracoes) {
        setAngel(angelConfiguracoes.email);
        form.setFieldsValue({
          ...defaultValues,
          email: angelConfiguracoes.email,
        });
        setLoaded(true);
      } else if (data) {
        let list = [];
        let listPolos = [];
        response.data.map((item) => {
          listPolos.push(item.name);
        });
        data.map((item) => {
          list.push({
            angel: item.angel,
            email: item.email_angel,
            polos_temporarios: listPolos,
            polo_atual: item.polo_atual,
          });
        });
        setSortedData(list);
        setRetornoObrigatorio(true);
      }
    });
  }, []);

  const onChangeTurnover = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        justificativa: "Turnover",
      });
      setDisabledExceptTurnover(true);
      setRetornoObrigatorio(false);
    } else {
      form.setFieldsValue({
        justificativa: [],
      });
      setDisabledExceptTurnover(false);
    }
  };
  const onChangeMudancaDeArea = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        justificativa: "Mudou de área",
      });
      setDisabledExceptArea(true);
      setRetornoObrigatorio(false);
    } else {
      form.setFieldsValue({
        justificativa: [],
      });
      setDisabledExceptArea(false);
    }
  };

  useEffect(() => {
    if (sortedData.length > 0) showTriggeredAlert(sortedData[0]);
  }, [sortedData]);

  useEffect(() => {
    setAngel(angelConfiguracoes?.email);
  }, [angelConfiguracoes]);

  const showTriggeredAlert = (item) => {
    setLoaded(false);
    let polo_atual = item.polo_atual;
    setPoloSelecionado(polo_atual);
    setPoloInicial(polo_atual);
    setAngel(item.email);
    setAngelInativo(polo_atual ? false : true);
    let list = [];
    todosPolos.map((item) => {
      list.push({
        id: item.id,
        name: item.name,
      });
    });
    setPolosTemporarios([...list]);
    setAngel(item.angel ? item.angel : item.email);
    setSaved(true);
    setJustificativa(null);
    form.setFieldsValue({
      ...defaultValues,
      email: item.email,
      polo_atual: polo_atual,
    });
    setTimeout(() => {
      setLoaded(true);
    }, 800);
  };

  const onChangePolo = (polo, value) => {
    setSaved(true);
    setPoloSelecionado(value.children);
    form.setFieldsValue({
      polo_atual: value.children,
      polo_antigo: poloAntigo,
      permanente: false,
      modificado_por: `|${userName.email}|`,
    });
  };

  const onChangeReason = (motivo) => {
    setSaved(true);
    form.setFieldsValue({ justificativa: motivo });
    setRetornoObrigatorio(true);
  };

  const reasons = [
    { name: "Cobertura de Férias" },
    { name: "Cobertura de Operação por Afastamento de Saúde" },
    { name: "Cobertura de Operação por Afastamento de Acidente" },
    { name: "Cobertura de Operação por Gravidez" },
    { name: "Cobertura de Operação por Desligamento" },
    { name: "Cobertura de Operação por Alta Demanda" },
  ];

  const reasonsInactiveAngel = [
    { name: "Férias" },
    { name: "Folga" },
    { name: "Atestado médio" },
    { name: "Acidente de trabalho" },
    { name: "Rotina interna" },
    { name: "Treinamento prático" },
    { name: "Feriado municipal" },
    { name: "Licença CLT" },
    { name: "Deslocamento cobertura de operação" },
    { name: "Manutenção da frota" },
    { name: "Falta não justificada" },
    { name: "Afastamento INSS" },
    { name: "Rota Sombra" },
  ];

  function onChangeDate(date, dateString) {
    var data = new Date(dateString);
    var dataFormatada =
      data.getFullYear() + "/" + (data.getMonth() + 1) + "/" + data.getDate();
    form.setFieldsValue({ data_retorno: moment(dataFormatada, "YYYY/MM/DD") });
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return (
      current < moment().subtract(1, "days").endOf("day") ||
      current > moment().add(30, "days").endOf("day")
    );
  }

  const salvarJustificativa = (values) => {
    data = {
      permanente: values.permanente,
      email_angel: angel,
      motivo: values.justificativa ? values.justificativa : null,
      mudado_por: `|${userName.email}|`,
      polo_atual: values.polo_atual ? values.polo_atual : null,
      data_retorno: values.data_retorno ? values.data_retorno : null,
    };
    setSaved(false);
    form.setFieldsValue(defaultValues);
    setPoloSelecionado(null);
    LogisticService.postJustificativaGestaoPessoas(data)
      .then((response) => {
        setDisabledExceptTurnover(false);
        setDisabledExceptArea(false);
        setSaved(true);
        setJustificativa(null);
        if (sortedData.length > 1) {
          form.resetFields();
          setSortedData([...sortedData.slice(1)]);
        } else {
          setVisible(false);
          if (onFinishGestao) onFinishGestao(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status == 400) {
          setDisabledExceptTurnover(false);
          setDisabledExceptArea(false);
          setSaved(true);
          message.error(`${err.response?.data?.erro}`);
        }
      });
  };

  useEffect(() => setJustificativaObrigatorio(justificativa ? true : false), [
    justificativa,
  ]);

  return loaded ? (
    <Form
      form={form}
      layout={"vertical"}
      onFinish={(values) => salvarJustificativa(values)}
      initialValues={defaultValues}
    >
      <Box verticalAlign={"center"} margin={"0 8px 8px"}>
        {angelConfiguracoes ? (
          <Text>
            O angel <Bold>{angel ? angel : angelConfiguracoes.email}</Bold>{" "}
            mudou de forma permanente para outro polo?
          </Text>
        ) : (
          <Text>
            {angelInativo ? (
              <>
                Notamos que o angel <Bold>{angel.split(";")[0]}</Bold> não
                atendeu nenhuma OS no último dia útil, Podemos inativa-lo??
              </>
            ) : (
              <>
                <>
                  O angel <Bold>{angel.split(";")[0]}</Bold> mudou de forma
                  permanente para o polo
                </>
                <FormItem
                  name={"polo_atual"}
                  label="Novo Polo"
                  style={{ width: "80%" }}
                >
                  <SelectBox
                    margin={"0 4px"}
                    width={"280px"}
                    options={todosPolos}
                    onChange={(e, value) => onChangePolo(e, value)}
                    defaultValue={poloInicial}
                  />
                </FormItem>
              </>
            )}
          </Text>
        )}
        <Space>
          <FormItem name={"permanente"} label="" style={{ width: "100%" }}>
            <Space>
              <CustomButton styled onClick={() => setJustificativa(false)}>
                Sim
              </CustomButton>
              <CustomButton styled onClick={() => setJustificativa(true)}>
                Não
              </CustomButton>
            </Space>
          </FormItem>
        </Space>
        {angelInativo && !justificativa && justificativa != null && (
          <Box noFlex width={"100%"} verticalAlign={"center"} margin={"8px 0"}>
            <Checkbox
              style={{ width: "80%" }}
              value={!retornoObrigatorio}
              onChange={onChangeTurnover}
              disabled={disabledExceptArea}
            >
              Turnover
            </Checkbox>
            <Checkbox
              style={{ width: "80%" }}
              value={!retornoObrigatorio}
              onChange={onChangeMudancaDeArea}
              disabled={disabledExceptTurnover}
            >
              Mudou de Área
            </Checkbox>
          </Box>
        )}
        {angelInativo && justificativa && (
          <Box noFlex width={"100%"} verticalAlign={"center"} margin={"8px 0"}>
            <FormItem
              label="Motivo"
              name={"justificativa"}
              rules={[{ required: justificativaObrigatorio }]}
              style={{ width: "80%" }}
            >
              <SelectBox
                margin={"0 0 8px"}
                width={"100%"}
                options={reasonsInactiveAngel}
                onChange={onChangeReason}
                disabled={disabledExceptTurnover || disabledExceptArea}
                valueIsName
                placeholder={"Selecione o Motivo"}
              />
            </FormItem>
            <FormItem
              label={"Previsão de Retorno"}
              name={"data_retorno"}
              rules={[{ required: justificativaObrigatorio }]}
              style={{ width: "80%" }}
            >
              <DatePicker
                disabledDate={disabledDate}
                onChange={onChangeDate}
                format={"YYYY/MM/DD"}
              />
            </FormItem>
            <FormItem
              label={"Modificado por"}
              name={"modificado_por"}
              rules={[{ required: true }]}
              style={{ width: "80%" }}
            >
              <Input disabled />
            </FormItem>
            <FormItem hidden name={"email"} rules={[{ required: true }]}>
              <Input disabled />
            </FormItem>
          </Box>
        )}
        {justificativa != null &&
          !angelInativo &&
          (justificativa ? (
            <Box
              noFlex
              width={"100%"}
              verticalAlign={"center"}
              margin={"8px 0"}
            >
              <Checkbox
                style={{ width: "80%" }}
                value={!retornoObrigatorio}
                onChange={onChangeTurnover}
                disabled={disabledExceptArea}
                checked={disabledExceptTurnover}
              >
                Turnover
              </Checkbox>
              <Checkbox
                style={{ width: "80%" }}
                value={!retornoObrigatorio}
                onChange={onChangeMudancaDeArea}
                disabled={disabledExceptTurnover}
                checked={disabledExceptArea}
              >
                Mudou de Área
              </Checkbox>
              <FormItem
                label="Motivo"
                name={"justificativa"}
                rules={[{ required: justificativaObrigatorio }]}
                style={{ width: "80%" }}
              >
                <SelectBox
                  margin={"0 0 8px"}
                  width={"100%"}
                  options={reasons}
                  onChange={onChangeReason}
                  disabled={disabledExceptTurnover || disabledExceptArea}
                  valueIsName
                  placeholder={"Selecione o Motivo"}
                />
              </FormItem>
              <FormItem
                label={"Previsão de Retorno"}
                name={"data_retorno"}
                rules={[
                  { required: !(disabledExceptTurnover || disabledExceptArea) },
                ]}
                style={{ width: "80%" }}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={onChangeDate}
                  format={"YYYY/MM/DD"}
                  disabled={disabledExceptTurnover || disabledExceptArea}
                />
              </FormItem>
              <FormItem
                label={"Modificado por"}
                name={"modificado_por"}
                rules={[{ required: true }]}
                style={{ width: "80%" }}
              >
                <Input disabled />
              </FormItem>
              <FormItem hidden name={"email"} rules={[{ required: true }]}>
                <Input disabled />
              </FormItem>
            </Box>
          ) : (
            <Box
              noFlex
              width={"100%"}
              verticalAlign={"center"}
              margin={"8px 0"}
            >
              {angelConfiguracoes && (
                <FormItem
                  name={"polo_atual"}
                  label="Novo Polo"
                  style={{ width: "80%" }}
                >
                  <SelectBox
                    margin={"0 0 8px"}
                    width={"100%"}
                    options={todosPolos}
                    defaultValue={poloInicial}
                    onChange={(e, value) => onChangePolo(e, value)}
                    valueIsName
                    placeholder={"Selecione"}
                  />
                </FormItem>
              )}
              {poloSelecionado ? (
                <Text>
                  <Bold>{angel.split(";")[0]}</Bold> será movido para{" "}
                  <Bold>{poloSelecionado}</Bold>.
                </Text>
              ) : (
                <Text>
                  <Bold>{angel.split(";")[0]}</Bold> será inativado
                  permanentemente, confirmar?
                </Text>
              )}
            </Box>
          ))}
      </Box>
      {justificativa != null &&
        (poloInicial ||
          angelInativo ||
          (angelConfiguracoes && (justificativa || poloSelecionado))) && (
          <Row horizontalAlign={"center"} nonResponsive>
            <Space>
              <CustomButton htmlType="submit" disabled={!saved}>
                {saved ? <></> : <LoadingOutlined spin />}
                Confirmar
              </CustomButton>
            </Space>
          </Row>
        )}
    </Form>
  ) : (
    <LoadingSpinner />
  );
}
