import styled from "styled-components";
import defaultTheme from "@defaultTheme";

export const ViewerContainer = styled.div`
  padding-bottom: ${(props) => (props.prefix ? "24px" : "14px")};
`;

export const Viewer = styled.div`
  border: 1px solid ${defaultTheme.colors.grey10};
  border-radius: 8px;
  height: 60px;
  padding: 8px;
`;

export const ViewerAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ViewerPrefix = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewerContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.prefix ? "8px" : "0px")};
`;

export const ViewerTitle = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #767f8d;
`;

export const ViewerSubTitle = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #303742;
`;
