import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@components/Box";
import Container from "@components/Container";
import Icon from "@components/Icon";
import LoadingSpinner from "@components/LoadingSpinner";
import Page from "@components/Page";
import Row from "@components/Row";
import SelectBox from "@components/SelectBox";
import Colors from "@colors";
import LogisticService from "@LogisticService";
import { PageLayout } from "../../../Layout/PageLayout";
import {
  Download,
  Manual,
  OptionBox,
  OptionText,
  SearchButton,
} from "./styles";

const RemuneracaoVariavel = () => {
  const [loading, setLoading] = useState(false);
  const [tipoAngel, setTipoAngel] = useState(null);
  const [path, setPath] = useState("");
  const [ranking, setRanking] = useState(false);
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [distritosDisponiveis, setDistritosDisponiveis] = useState([]);
  const [angelsDisponiveis, setAngelsDisponiveis] = useState([]);
  const [greenAngelsDistrito, setGreenAngelsDistrito] = useState([]);
  const [periodoSelecionado, setPeriodoSelecionado] = useState(null);
  const [distritoSelecionado, setDistritoSelecionado] = useState(null);
  const [angelSelecionado, setAngelSelecionado] = useState(null);
  const [mes, setMes] = useState(null);
  const [ano, setAno] = useState(null);

  const opcoes = [
    { title: "Green Angel", icon: "motorcycle", key: "greenAngel" },
    { title: "Host Angel", icon: "star", key: "hostAngel" },
    { title: "Supplier Angel", icon: "box-open", key: "supplierAngel" },
    { title: "Guardian Angel", icon: "shield-alt", key: "guardianAngel" },
    { title: "Distrital", icon: "map-marked-alt", key: "distrital" },
    // { title: "Regional", icon: 'globe-americas', key: 'regional' }
  ];

  useEffect(() => {
    setMes(periodoSelecionado ? periodoSelecionado.split("/")[0] : null);
    setAno(periodoSelecionado ? periodoSelecionado.split("/")[1] : null);
  }, [periodoSelecionado]);

  const compareMonth = (a, b) => {
    if (a.reference_month > b.reference_month) {
      return -1;
    }
    if (a.reference_month < b.reference_month) {
      return 1;
    }
    return 0;
  };

  const compareYear = (a, b) => {
    if (a.reference_year > b.reference_year) {
      return -1;
    }
    if (a.reference_year < b.reference_year) {
      return 1;
    }
    return 0;
  };

  const carregarMesesApuracao = (tipo) => {
    if (tipoAngel !== tipo) {
      setLoading(true);
      setPeriodosDisponiveis([]);
      setDistritosDisponiveis([]);
      setAngelsDisponiveis([]);
      setGreenAngelsDistrito([]);
      setDistritoSelecionado(null);
      setPeriodoSelecionado(null);
      setAngelSelecionado(null);

      LogisticService.getPeriodosDiponiveisRV(tipo)
        .then((response) => {
          let periodos = [];
          response.data
            .sort(compareMonth)
            .sort(compareYear)
            .forEach((item) => {
              const periodo = `${item.reference_month}/${item.reference_year}`;
              periodos.push({
                id: Math.random(),
                name: periodo,
              });
            });
          setPeriodosDisponiveis(periodos);
          setTipoAngel(tipo);
          setLoading(false);
          if (tipo === "ranking") {
            setRanking(true);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return;
  };

  const onChangeMes = (value) => {
    setLoading(true);
    setPeriodoSelecionado(value);
    setDistritosDisponiveis([]);
    setAngelsDisponiveis([]);
    setGreenAngelsDistrito([]);
    setDistritoSelecionado(null);
    setAngelSelecionado(null);

    const mes = value.split("/")[0];
    const ano = value.split("/")[1];

    if (tipoAngel === "ranking") {
      setPath("/ferramentas/remuneracao-variavel/ranking");
      setLoading(false);
      return;
    }

    const buscarColaboradoresFactory = (tipo) =>
      ({
        greenAngel: LogisticService.getGreenAngelsRV,
        guardianAngel: LogisticService.getGuardiansRV,
        supplierAngel: LogisticService.getSuppliersRV,
        hostAngel: LogisticService.getHostAngelsRV,
        distrital: LogisticService.getDistritaisRV,
        regional: LogisticService.getRegionaisRV,
      }[tipo]);

    const buscarColaboradores = buscarColaboradoresFactory(tipoAngel);

    buscarColaboradores(ano, mes).then((response) => {
      if (tipoAngel === "greenAngel") {
        const distritos = [];
        const angels = [];
        response.data.forEach((item, index) => {
          if (!distritos.includes(item.district)) {
            distritos.push({
              id: index,
              name: item.district,
            });
          }
          angels.push({
            id: index,
            name: item.name,
            district: item.district,
          });
        });
        setDistritosDisponiveis(distritos);
        setAngelsDisponiveis(angels);
        setGreenAngelsDistrito(angels);
      } else {
        const angels = [];
        response.data.forEach((item, index) => {
          angels.push({
            id: index,
            name: item.name,
          });
        });
        setAngelsDisponiveis(angels);
      }
      setLoading(false);
    });
    return;
  };

  const onChangeDistrito = (value) => {
    setLoading(true);
    setDistritoSelecionado(value);
    setAngelSelecionado(null);

    const angels = [];

    angelsDisponiveis
      .filter((obj) => obj.district === value)
      .forEach((item) => {
        angels.push(item);
      });

    setGreenAngelsDistrito(angels);
    setLoading(false);
    return;
  };

  const onChangeAngels = (value) => {
    setLoading(true);
    setAngelSelecionado(value);
    setPath("/ferramentas/remuneracao-variavel/dashboard");

    setLoading(false);
    return;
  };

  const selecionarTipoAngel = () => {
    return (
      <Box>
        <Row horizontalAlign={"center"}>
          {renderOption("ranking", "Ranking", "trophy")}
        </Row>
        <Row horizontalAlign={"space-around"}>
          {opcoes.map((item) => renderOption(item.key, item.title, item.icon))}
        </Row>
        <Row>
          {loading ? (
            <LoadingSpinner />
          ) : tipoAngel ? (
            <Row margin={"4px"} horizontalAlign={"start"}>
              <SelectBox
                placeholder="Mês"
                options={periodosDisponiveis}
                onChange={onChangeMes}
                disabled={periodosDisponiveis.length === 0}
                value={periodoSelecionado || null}
                valueIsName
              />
              <SelectBox
                placeholder="Distrito"
                options={distritosDisponiveis}
                onChange={onChangeDistrito}
                disabled={distritosDisponiveis.length === 0}
                hidden={tipoAngel !== "greenAngel" || tipoAngel === "ranking"}
                value={distritoSelecionado || null}
                valueIsName
              />
              <SelectBox
                placeholder={
                  tipoAngel === "distrital"
                    ? "Distrito"
                    : tipoAngel === "Regional"
                    ? "Regional"
                    : "Angels"
                }
                options={
                  tipoAngel === "greenAngel"
                    ? greenAngelsDistrito
                    : angelsDisponiveis
                }
                onChange={onChangeAngels}
                disabled={angelsDisponiveis.length === 0}
                hidden={tipoAngel === "ranking"}
                value={angelSelecionado || null}
                valueIsName
              />
              {angelSelecionado || ranking ? renderLink() : ""}
            </Row>
          ) : (
            <></>
          )}
        </Row>
      </Box>
    );
  };

  const renderLink = () => {
    return (
      <Link
        to={{
          pathname: path,
          state: {
            name: angelSelecionado,
            year: ano,
            month: mes,
            tipo: tipoAngel,
            distrito: distritoSelecionado,
          },
        }}
      >
        <SearchButton type="primary">Pesquisar</SearchButton>
      </Link>
    );
  };

  const renderOption = (tipo, titulo, icone) => {
    return (
      <OptionBox
        onClick={() => {
          if (tipo === "regional") {
            return setTipoAngel(null);
          } else {
            return carregarMesesApuracao(tipo);
          }
        }}
      >
        <Icon
          name={icone}
          color={Colors.white}
          margin={"0 8px 0 0"}
          size={"18px"}
        />
        <OptionText>{titulo}</OptionText>
      </OptionBox>
    );
  };

  return (
    <PageLayout>
      <Box title="Remuneração Variável" width={"74.9vw"}>
        <Row horizontalAlign={"center"}>
          <Container>
            {selecionarTipoAngel()}
            <Row horizontalAlign={"center"}>
              <Manual>
                Dúvidas? Clique{" "}
                <Download href={"/dist/manual_rv.pdf"} target="_blank">
                  aqui
                </Download>{" "}
                para baixar a última versão do manual, ou{" "}
                <Download
                  href={"/dist/manual_rv_suppliers.pdf"}
                  target="_blank"
                >
                  aqui
                </Download>{" "}
                para o manual dos Suppliers.
              </Manual>
              <Manual></Manual>
            </Row>
          </Container>
        </Row>
      </Box>
    </PageLayout>
  );
};

export default RemuneracaoVariavel;
