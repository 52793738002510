/* eslint-disable */
import Colors from "@colors";
import LogisticService from "@LogisticService";
import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import Box from "../Box";
import Container from "../Container";
import CustomButton from "../CustomButton";
import Icon from "../Icon";
import Page from "../Page";
import Row from "../Row";
import { BackButtonBox, FormItem, InputText, SearchButton } from "./styles";

const SearchClientPage = (props) => {
  const [stonecode, setStonecode] = useState(null);
  const [fixedBackButton, setFixedBackButton] = useState(false);

  const voltar = () => setStonecode(null);

  useEffect(() => {
    props.setStonecode(stonecode);
  }, [stonecode]);

  document.onscroll = () => {
    setFixedBackButton(document.documentElement.scrollTop > 62);
  };

  const SearchBox = () => {
    const onFinish = (values) => {
      if (values.stonecode.toString().length == 9) {
        props.setStonecode(values.stonecode);
        setStonecode(values.stonecode);
      } else {
        LogisticService.postOutrosStonecodes(
          values.stonecode.toString().replace(".", "").replace("/", "")
        ).then((response) => {
          if (
            response &&
            response.data &&
            response.data.others_stonecode &&
            response.data.others_stonecode.length > 0
          ) {
            setStonecode(response.data.others_stonecode[0]);
          } else {
            message.error("Documento inválido!");
          }
        });
      }
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    return (
      <Container title="Buscar Cliente" grid={1 / 3} margin={"16px"}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row
            margin={"16px 16px 24px"}
            nonResponsive
            horizontalAlign={"center"}
          >
            <FormItem
              name="stonecode"
              rules={[
                { required: true, message: "Digite o Stonecode ou Documento!" },
              ]}
              style={{ width: "100%" }}
            >
              <InputText type="textarea" placeholder="STONECODE / CNPJ / CPF" />
            </FormItem>
            <FormItem>
              <SearchButton type="text" htmlType="submit">
                Buscar
              </SearchButton>
            </FormItem>
          </Row>
        </Form>
      </Container>
    );
  };

  return (
    <Page
      id={"customer-data-container"}
      title={props.pageTitle}
      ownerModal={props.ownerModal}
      setIsOwnerModalVisible={props.setIsOwnerModalVisible}
    >
      <Box title={props.pageTitle} margin="0" id="search-page">
        <BackButtonBox hidden={!stonecode} fixed={fixedBackButton}>
          <CustomButton
            shadow
            large
            width={"112px"}
            margin={"0"}
            onClick={voltar}
          >
            <Icon margin={"0 8px 0 0"} color={Colors.white} name="angle-left" />{" "}
            voltar
          </CustomButton>
        </BackButtonBox>
        {stonecode ? (
          <>{props.children}</>
        ) : (
          <Row horizontalAlign={"center"}>
            <SearchBox />
          </Row>
        )}
      </Box>
    </Page>
  );
};

export default SearchClientPage;
