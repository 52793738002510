import { GenIcon } from "react-icons";

function SearchIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 20 20",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M9.16663 1.66675C5.02449 1.66675 1.66663 5.02461 1.66663 9.16675C1.66663 13.3089 5.02449 16.6667 9.16663 16.6667C10.8438 16.6667 12.3925 16.1162 13.6417 15.186L16.9107 18.4551C17.2361 18.7805 17.7637 18.7805 18.0892 18.4551C18.4146 18.1297 18.4146 17.602 18.0892 17.2766L14.8609 14.0481C15.9865 12.7362 16.6666 11.0309 16.6666 9.16675C16.6666 5.02461 13.3088 1.66675 9.16663 1.66675ZM3.33329 9.16675C3.33329 5.94509 5.94497 3.33341 9.16663 3.33341C12.3883 3.33341 15 5.94509 15 9.16675C15 12.3884 12.3883 15.0001 9.16663 15.0001C5.94497 15.0001 3.33329 12.3884 3.33329 9.16675Z",
        },
      },
    ],
  })(props);
}

export default SearchIcon;
