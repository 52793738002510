import React, { useState, useMemo, useEffect } from "react";
import { FilterIcon } from "@assets/icons/FilterIcon";
import CloseIconFilled from "@assets/icons/CloseIconFilled";
import CloseIcon from "@assets/icons/CloseIcon";
import { Popover } from "antd";

import * as S from "./styles";

const FilterRoute = ({ filteredRoute, setFilteredRoute, mapTab }) => {
  const [filter, setFilter] = useState(null);
  const [visible, setIsVisible] = useState(false);

  const optionsTimeLine = useMemo(
    () => [{ label: "Com polígono" }, { label: "Distrital" }],
    []
  );

  const optionsMap = useMemo(
    () => [{ label: "Sem polígono" }, ...optionsTimeLine],
    []
  );

  const options = mapTab ? optionsMap : optionsTimeLine;

  useEffect(() => {
    const newFilter = filteredRoute
      ? filteredRoute
      : mapTab
      ? "Sem polígono"
      : null;
    setFilter(newFilter);
    setFilteredRoute(newFilter);
  }, [mapTab, filteredRoute]);

  const togglePopover = () => setIsVisible(!visible);

  const content = () => (
    <S.Content>
      <div>
        <span>Filtrar roteirização</span>
        <CloseIcon
          size={24}
          onClick={togglePopover}
          style={{ cursor: "pointer" }}
        />
      </div>
      <span>Escolha uma opção</span>

      <S.Options>
        {options.map((option) => (
          <S.FilterOption
            key={option.label}
            active={filter === option.label}
            onClick={() =>
              setFilter((prev) => (prev === option.label ? null : option.label))
            }
          >
            {option.label}
          </S.FilterOption>
        ))}
      </S.Options>

      <S.FilterButton
        onClick={() => {
          togglePopover();
          setFilteredRoute(filter);
        }}
      >
        Filtrar
      </S.FilterButton>
    </S.Content>
  );

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      content={content}
      overlayClassName="ginga-popover"
      open={visible}
      onOpenChange={() => togglePopover()}
    >
      <S.Container>
        <FilterIcon size={16} />
        {filteredRoute ? (
          <span>
            <span>{filteredRoute}</span>
            {!(filteredRoute === "Sem polígono" && mapTab) && (
              <CloseIconFilled
                size={18}
                fill="#767F8D"
                onClick={(event) => {
                  event.stopPropagation();
                  setFilteredRoute(mapTab ? "Sem polígono" : null);
                  setFilter(null);
                }}
              />
            )}
          </span>
        ) : (
          <span>Filtrar roteirização</span>
        )}
      </S.Container>
    </Popover>
  );
};

export default FilterRoute;
