/* eslint-disable */
import React, { useState, useEffect } from "react";
import Breadcrumbs from "@components/Breadcumbs";
import LogisticService from "@LogisticService";
import { Radio, Tabs } from "antd";
import OsAnexos from "@assets/imgs/OsAnexos.svg";
import OmAnexos from "@assets/imgs/OmAnexos.svg";
import AutonomiaAnexos from "@assets/imgs/AutonomiaAnexos.svg";
import BottomArrow from "@assets/icons/BottomArrow";
import ReportAnexos from "@assets/imgs/ReportAnexos.svg";
import Icon from "@components/Icon";
import Row from "@components/Row";
import Colors from "@colors";
import ImagensOm from "./detalhes/imagensOM";
import ImagensOS from "./detalhes/ImagensOS";
import ImagensAutonomia from "./detalhes/imagensAutonomia";
import ImagensLogLoj from "./detalhes/ImagensLogLoj";
import { BackButton } from "./styles";
import { PageLayout } from "../../../Layout/PageLayout";
import PageHeader from "@components/PageHeader";
import * as S from "./styles";
import useRouter from "../../../hooks/useRouter";

const AnexosOSs = () => {
  const { TabPane } = Tabs;
  const [numeroOS, setNumeroOS] = useState(null);
  const [numeroOM, setNumeroOM] = useState(null);
  const [numeroAutonomia, setNumeroAutonomia] = useState(null);
  const [trackingCode, setTrackingCode] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState("Serial ou E-mail");
  const [nomeDistritos, setNomeDistritos] = useState("");
  const { push } = useRouter();

  const voltar = () => setNumeroOS(null);
  const voltarOM = () => setNumeroOM(null);
  const voltarAutonomia = () => setNumeroAutonomia(null);
  const voltarLogLoj = () => setTrackingCode(null);

  const category = [
    { label: "Serial ou E-mail", value: "Serial ou E-mail" },
    { label: "distrito", value: "Distrito" },
  ];

  useEffect(() => {
    LogisticService.getTodosOsDistritos().then((response) => {
      if (response) {
        setNomeDistritos(response.data);
      }
    });
  }, []);

  function handleChangeCheckbox(checkedValues) {
    setCheckboxValue(checkedValues.target.value);
  }

  const searchHistoricDOA = (e) => {
    push(`/ferramentas/anexos/consulta-doa/${e}`);
  };

  const handleChangeDistrito = (value) => {
    searchHistoricDOA(value);
  };

  return (
    <PageLayout>
      <div>
        <Breadcrumbs />
        <PageHeader title="Anexos OS/OM" />
        <S.ContainerAnexos>
          <S.TabsOptions centered className="ginga-tab">
            <TabPane tab="ORDEM DE SERVIÇO" key="1">
              {numeroOS ? (
                <>
                  <Row hidden={!numeroOS}>
                    <BackButton onClick={voltar}>
                      <Icon color={Colors.white} name="angle-left" /> voltar
                    </BackButton>
                  </Row>
                  <ImagensOS numeroOS={numeroOS} voltar={voltar} />
                </>
              ) : (
                <S.ContentAnexos>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={OsAnexos} />
                    <S.SubtitleAnexos>
                      Consultar OS - Green App
                    </S.SubtitleAnexos>
                    <S.SearchInputAnexos
                      onSearch={(e) => {
                        setNumeroOS(e);
                      }}
                      placeholder="Insira o número da OS"
                    />
                  </div>
                </S.ContentAnexos>
              )}
            </TabPane>

            <TabPane tab="LOG LOJISTA" key="2">
              {trackingCode ? (
                <>
                  <Row hidden={!trackingCode}>
                    <BackButton onClick={voltar}>
                      <Icon color={Colors.white} name="angle-left" /> voltar
                    </BackButton>
                  </Row>
                  <ImagensLogLoj
                    numeroOS={trackingCode}
                    voltar={voltarLogLoj}
                  />
                </>
              ) : (
                <S.ContentAnexos>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={OsAnexos} />
                    <S.SubtitleAnexos>
                      Consultar OS - Logística Lojista
                    </S.SubtitleAnexos>
                    <S.SearchInputAnexos
                      onSearch={(e) => {
                        setTrackingCode(e);
                      }}
                      placeholder="Insira o número do Tracking Code"
                    />
                  </div>
                </S.ContentAnexos>
              )}
            </TabPane>

            <TabPane tab="ORDEM DE MOVIMENTAÇÃO" key="3">
              {numeroOM ? (
                <>
                  <Row hidden={!numeroOM}>
                    <BackButton onClick={voltarOM}>
                      <Icon color={Colors.white} name="angle-left" />
                      voltar
                    </BackButton>
                  </Row>
                  <ImagensOm numeroOM={numeroOM} voltarOM={voltarOM} />
                </>
              ) : (
                <S.ContentAnexos>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={OmAnexos} />
                    <S.SubtitleAnexos>
                      Consultar OM - Trucker App
                    </S.SubtitleAnexos>
                    <S.SearchInputAnexos
                      onSearch={(e) => {
                        setNumeroOM(e);
                      }}
                      placeholder="Insira o número da OM"
                    />
                  </div>
                </S.ContentAnexos>
              )}
            </TabPane>

            <TabPane tab="ORDEM DE SERVIÇO AUTONOMIA" key="4">
              {numeroAutonomia ? (
                <>
                  <Row hidden={!numeroAutonomia}>
                    <BackButton onClick={voltarAutonomia}>
                      <Icon color={Colors.white} name="angle-left" />
                      voltar
                    </BackButton>
                  </Row>
                  <ImagensAutonomia
                    numeroAutonomia={numeroAutonomia}
                    voltarAutonomia={voltarAutonomia}
                  />
                </>
              ) : (
                <S.ContentAnexos>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={AutonomiaAnexos} />
                    <S.SubtitleAnexos>
                      Consultar OS - Green App
                    </S.SubtitleAnexos>
                    <S.SearchInputAnexos
                      onSearch={(e) => {
                        setNumeroAutonomia(e);
                      }}
                      placeholder="Insira o número da OS"
                    />
                  </div>
                </S.ContentAnexos>
              )}
            </TabPane>

            <TabPane tab="CONSULTA DE REPORT DOA" key="5">
              <S.ContentAnexos>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={ReportAnexos} />
                  <S.SubtitleAnexos>Consultar OS - Green App</S.SubtitleAnexos>
                  {checkboxValue === "Serial ou E-mail" ? (
                    <S.SearchInputAnexos
                      anexoDoa
                      onSearch={searchHistoricDOA}
                      placeholder="Insira o serial ou e-mail"
                    />
                  ) : (
                    <S.SelectAnexos
                      placeholder="Selecione um distrito"
                      suffixIcon={
                        <BottomArrow
                          color={"#767F8D"}
                          size={24}
                          style={{ marginLeft: "-14px", marginTop: "-6px" }}
                        />
                      }
                      onChange={handleChangeDistrito}
                      showSearch
                    >
                      {nomeDistritos.map((option) => (
                        <Option key={option.id} value={option.name}>
                          {option.name}
                        </Option>
                      ))}
                    </S.SelectAnexos>
                  )}
                  <S.CheckboxAnexosContent>
                    {category.map((item) => {
                      return (
                        <Radio
                          key={item.label}
                          onChange={handleChangeCheckbox}
                          checked={item.value == checkboxValue}
                          value={item.value}
                          className="ginga-radio"
                        >
                          {item.value}
                        </Radio>
                      );
                    })}
                  </S.CheckboxAnexosContent>
                </div>
              </S.ContentAnexos>
            </TabPane>
          </S.TabsOptions>
        </S.ContainerAnexos>
      </div>
    </PageLayout>
  );
};

export default AnexosOSs;
