import React, { useState, useEffect } from "react";

import MaterialTableComponent from "@components/MaterialTableComponent";
import CustomButton from "@components/CustomButton";

import LogisticService from "@LogisticService";

import { AcaoText, TableTitle } from "./styles";

const renderAcao = (rowData) => <AcaoText>{rowData.acao.join(" - ")}</AcaoText>;

const colunas = [
  { title: "ID", field: "id", editable: "never", width: 60 },
  { title: "Motivo", field: "motivo", width: 80 },
  { title: "Ações", field: "acao", width: 80, render: renderAcao },
  { title: "Status Posterior", field: "post_status", width: 80 },
  { title: "Motivo WKF", field: "motivo_workfinity", width: 80 },
  {
    title: "Ativo",
    field: "ativo",
    type: "boolean",
    width: 40,
    defaultFilter: "checked",
  },
  { title: "Contratante", field: "contratante", width: 80 },
  { title: "Grupo de Serviço", field: "grupo_servico", width: 80 },
  { title: "Serviço", field: "servico", width: 80 },
];

const Table = (props) => {
  const { innerHeight } = window;
  const [arvore, setArvore] = useState([]);
  const [isLoadingTree, setIsLoadingTree] = useState(true);

  useEffect(() => {
    if (props.shouldUpdate) {
      LogisticService.getMotivosAutonomia().then((response) => {
        if (response) {
          setArvore(response.data);
          setIsLoadingTree(false);
          props.setShouldUpdate(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldUpdate]);

  const Title = () => (
    <>
      <TableTitle>Motivos de Ineficiência das OSs no Green App</TableTitle>
      <CustomButton
        style={{ margin: "0 12px" }}
        onClick={() => props.openModal()}
      >
        Adicionar Motivo +
      </CustomButton>
    </>
  );

  return (
    <MaterialTableComponent
      columns={colunas}
      data={arvore}
      isLoading={isLoadingTree}
      title={<Title />}
      onRowClick={(evento, data) => props.onRowClick(data)}
      bodyStyle={{
        overflow: "none",
      }}
      addOptions={{
        actionsColumnIndex: -1,
        pageSize: 20,
        showTitle: true,
        toolbar: true,
        maxBodyHeight: 0.6 * innerHeight,
        tableLayout: "fixed",
      }}
    />
  );
};

export default Table;
