export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getOneYearAgo() {
  const date = new Date();
  const formatted_date = `${("0" + (date.getMonth() + 1)).slice(-2)}-${
    date.getFullYear() - 1
  }`;
  return formatted_date;
}

export function getTwoWeeksAgo() {
  const date = new Date();
  date.setDate(date.getDate() - 14);
  const formatted_date = `${("0" + date.getDay()).slice(-2)}-${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)}-${date.getFullYear()}`;
  return formatted_date;
}

export const intToReal = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 0,
});

export const capitalizeAllFirstLetters = (title) => {
  if (!title || typeof title !== 'string') return '';
  return title
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const  toCapitalizeFirstLetterOfEachWord = (str = '') => {
  if (!str || typeof str !== 'string') return '';
  const arr = str.split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
  }

  return arr.join(' ');
}
