import styled from "styled-components";
import Colors from "@colors";

export const Mask = styled.div`
  position: absolute;
  width: ${(props) => (props.fullScreen ? "100%" : "calc(100% - 16px)")};
  height: ${(props) => (props.fullScreen ? "100%" : "calc(100% - 16px)")};
  top: 0;
  left: 0;
  margin: ${(props) => (props.fullScreen ? "0" : "8px")};
  background: ${Colors.transparentWhite};
  z-index: 1009;
  display: flex;
  justify-content: center;
  align-items: center;
`;
