import { Space } from "antd";
import React, { useEffect, useState } from "react";
import BarChart from "@components/BarChart";
import Container from "@components/Container";
import CustomButton from "@components/CustomButton";
import CustomLink from "@components/CustomLink";
import CustomModal from "@components/CustomModal";
import LoadingSpinner from "@components/LoadingSpinner";
import Row from "@components/Row";
import { getOneYearAgo } from "@helper";
import LogisticService from "@LogisticService";
import Colors from "@colors";
import { CustomInput } from "../styles";

export default function PerdasLiquidas(props) {
  const title = "Perdas Líquidas (em milhares)";
  const hoje = new Date();
  const [perdasLiquidas, setPerdasLiquidas] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [anoInput, setAnoInput] = useState(hoje.getFullYear());
  const [mesInput, setMesInput] = useState(hoje.getMonth() + 1);
  const [perdaAtivaInput, setPerdaAtivaInput] = useState(null);
  const [reativacoesInput, setReativacoesInput] = useState(null);
  const [irreparaveisInput, setIrreparaveisInput] = useState(null);
  const [outrasPerdasInput, setOutrasPerdasInput] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    LogisticService.getPerdasLiquidas().then((response) => {
      if (response && response.data != null) {
        setPerdasLiquidas(response.data);
      }
      setLoaded(true);
    });
  }, []);
  const periodos = [];
  const perdaAtiva = [];
  const reativacoes = [];
  const irreparaveis = [];
  const outrasPerdas = [];
  const downloadLabels = [
    "Período",
    "Reativações",
    "Outras Perdas",
    "Irreparáveis",
    "Perda Ativa",
  ];
  const downloadData = [];

  perdasLiquidas &&
    perdasLiquidas.map(
      (item) => (
        periodos.push(item.periodo),
        reativacoes.push(item.reativacoes.toFixed(1)),
        outrasPerdas.push(item.outras_perdas.toFixed(1)),
        irreparaveis.push(item.irreparaveis.toFixed(1)),
        perdaAtiva.push(item.perda_ativa.toFixed(1)),
        downloadData.push([
          `${item.periodo}`,
          `${item.reativacoes.toFixed(3)}`,
          `${item.outras_perdas.toFixed(3)}`,
          `${item.irreparaveis.toFixed(3)}`,
          `${item.perda_ativa.toFixed(3)}`,
        ])
      )
    );

  const cadastraPerdaLiquida = () => {
    const data = {
      ano: anoInput,
      mes: mesInput,
      perda_ativa: perdaAtivaInput,
      reativacoes: reativacoesInput,
      irreparaveis: irreparaveisInput,
      outras_perdas: outrasPerdasInput,
    };
    LogisticService.postPerdasLiquidas(data).then((response) => {
      setOpenModal(false);
      setAnoInput(hoje.getFullYear());
      setMesInput(hoje.getMonth() + 1);
      setPerdaAtivaInput(null);
      setReativacoesInput(null);
      setIrreparaveisInput(null);
      setOutrasPerdasInput(null);
    });
  };

  const chartData = {
    datalabels: true,
    datalabelsCenter: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
          },
          stacked: true,
          gridBars: false,
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 16,
            min: getOneYearAgo(),
          },
          stacked: true,
          gridBars: false,
        },
      ],
    },
    data: [
      {
        label: "Reativações",
        color: Colors.green,
        fill: false,
        value: reativacoes,
      },
      {
        label: "Outras Perdas",
        color: Colors.gray,
        fill: false,
        value: outrasPerdas,
      },
      {
        label: "Irreparáveis",
        color: Colors.cautionYellow,
        fill: false,
        value: irreparaveis,
      },
      {
        label: "Perda Ativa",
        color: Colors.dangerRed,
        fill: false,
        value: perdaAtiva,
      },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      grid={1 / 2}
      title={title}
      info={[[""]]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      <CustomButton
        style={{ margin: "0 16px" }}
        onClick={() => setOpenModal(true)}
      >
        Cadastrar perdas líquidas
      </CustomButton>
      {loaded ? <BarChart data={chartData} /> : <LoadingSpinner />}
      <CustomModal
        title={`Cadastrar perdas líquidas`}
        visible={openModal}
        zIndex={1010}
        footer={
          <Space>
            <CustomButton
              htmlType="submit"
              onClick={() => cadastraPerdaLiquida()}
            >
              Cadastrar
            </CustomButton>
            <CustomLink danger onClick={() => setOpenModal(false)}>
              Cancelar
            </CustomLink>
          </Space>
        }
        onCancel={() => setOpenModal(false)}
      >
        <Row>
          <CustomInput
            onChange={(e) => setAnoInput(e.target.value)}
            placeholder="Ano"
            value={anoInput || null}
            type={"number"}
          />
          <CustomInput
            onChange={(e) => setMesInput(e.target.value)}
            placeholder="Mes"
            value={mesInput || null}
            type={"number"}
          />
        </Row>
        <Row>
          <CustomInput
            onChange={(e) => setPerdaAtivaInput(e.target.value)}
            placeholder="Perda Ativa"
            value={perdaAtivaInput || null}
            type={"number"}
          />
          <CustomInput
            onChange={(e) => setReativacoesInput(e.target.value)}
            placeholder="Reativações"
            value={reativacoesInput || null}
            type={"number"}
          />
          <CustomInput
            onChange={(e) => setIrreparaveisInput(e.target.value)}
            placeholder="Irreparáveis"
            value={irreparaveisInput || null}
            type={"number"}
          />
          <CustomInput
            onChange={(e) => setOutrasPerdasInput(e.target.value)}
            placeholder="Outras Perdas"
            value={outrasPerdasInput || null}
            type={"number"}
          />
        </Row>
      </CustomModal>
    </Container>
  );
}
