import React, { useEffect, useState } from "react";
import ErrorIcon from "@assets/icons/ErrorIcon";
import WarningIcon from "@assets/icons/WarningIcon";
// import {}
import * as S from "./styles";

export const DisplayError = ({ title, ...props }) => {
  useEffect(() => {
    if (title === "Serial não encontrado.") {
      setErrorType("Error");
    } else {
      setErrorType("Warning");
    }
  }, [title]);

  const [errorType, setErrorType] = useState("");

  return (
    <>
      <S.ErrorViewer errorType={errorType}>
        {errorType === "Error" ? (
          <ErrorIcon
            color={"#F73E41"}
            style={{
              width: "30px",
              height: "20px",
              marginRight: "8.93px",
            }}
          />
        ) : (
          <WarningIcon
            color={"#F38428"}
            style={{
              width: "30px",
              height: "20px",
              marginRight: "8.93px",
            }}
          />
        )}
        <S.ErrorViewerContent>
          <p>{title.replace(".", "")}</p>
          <span>
            {title === "Serial não encontrado."
              ? "Você não vai poder reportar DOA pois o serial não consta no estoque."
              : "Você não vai poder reportar DOA pois o serial pertence ao prestador STONE."}
          </span>
        </S.ErrorViewerContent>
      </S.ErrorViewer>
    </>
  );
};
