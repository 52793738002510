import React from "react";

import "./styles.css";

const FloatLabel = ({ label, hasError = false, htmlFor, children }) => {
  const className = `select-float-label ${
    hasError ? "select-float-label-error" : ""
  }`;

  return (
    <div className="custom-select">
      <label className={className} htmlFor={htmlFor}>
        {label}
      </label>
      {children}
    </div>
  );
};

export default FloatLabel;
