import styled from "styled-components";
import { Menu } from "antd";
import defaultTheme from "@defaultTheme";
import Colors from "@colors";

export const HeaderTopo = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: ${(props) => (props.loginScreen ? "15px 42px" : "10px 32px")};
  background-color: ${defaultTheme.colors.green700};
  a {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.02em;
  }

  img {
    margin: 0 auto;
  }
`;

export const HeaderTitulo = styled.p``;

export const HeaderLeft = styled.div`
  display: flex;
`;

export const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LogoutContainer = styled(Menu)`
  li {
    padding: 1rem;
    &:hover {
      background-color: transparent;
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 0.5rem;
  }

  span {
    font-weight: 600;
    font-size: ${defaultTheme.fontSize.g};
    color: ${defaultTheme.colors.grey700};
  }
`;

export const ButtonUser = styled.button`
  position: absolute;
  top: 100%;
  right: 0;
  height: 62px;
  width: 100%;
  min-width: 220px;
  z-index: 100000000;
  display: none;
  align-items: center;
  gap: 8px;
  padding: 0px 8px;
  background-color: #fff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
    0px 3px 4px -1px rgba(0, 0, 0, 0.04);

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    letter-spacing: -0.01em;
  }
`;

export const BoxUser = styled.div`
  margin-left: auto;
  padding: 8px 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  &:hover ${ButtonUser} {
    display: flex;
    cursor: pointer;
    border-color: transparent;
  }

  span {
    color: white;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.02em;
  }
  strong {
    text-transform: uppercase;
    color: white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #3f47cc;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }
`;

export const MenuHeader = styled.div`
  background-color: ${Colors.deepBlue};
  z-index: 1000;
  position: fixed;
  height: 100%;
  width: 320px;
  left: 0;
  top: 0;
  box-shadow: 2px 0 8px 2px rgb(0 0 0 / 10%);
  transition: 0.2s;
  transform: translate(${(props) => (props.open ? "0" : "-100%")}, 0);
`;

export const Hamburguer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
`;
