import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import Colors from "@colors";
import { Mask } from "./styles";

export default function LoadingMask({ fullScreen }) {
  return (
    <Mask fullScreen={fullScreen}>
      <LoadingOutlined style={{ fontSize: 22, color: Colors.deepBlue }} spin />
    </Mask>
  );
}
