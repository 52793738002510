import { GenIcon } from "react-icons";

function CheckIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 18 19",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M12.1196 8.56375C12.431 8.2906 12.462 7.81674 12.1888 7.50535C11.9157 7.19397 11.4418 7.16297 11.1304 7.43612L8.22342 9.98614L6.8897 8.72775C6.58843 8.44349 6.11375 8.45728 5.82949 8.75856C5.54523 9.05983 5.55902 9.53451 5.8603 9.81877L7.69033 11.5454C7.97169 11.8109 8.40881 11.8188 8.69961 11.5638L12.1196 8.56375Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M9 1.99992C4.8576 1.99992 1.5 5.35752 1.5 9.49992C1.5 13.6415 4.85754 16.9999 9 16.9999C13.1417 16.9999 16.5 13.6416 16.5 9.49992C16.5 5.35747 13.1416 1.99992 9 1.99992ZM3 9.49992C3 6.18595 5.68602 3.49992 9 3.49992C12.3133 3.49992 15 6.186 15 9.49992C15 12.8132 12.3132 15.4999 9 15.4999C5.68608 15.4999 3 12.8132 3 9.49992Z",
        },
      },
    ],
  })(props);
}

export default CheckIcon;
