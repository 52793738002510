import React, { useEffect, useState } from 'react';
import { Button, } from '@stonelog/stonelog-design-system';
import SelectCustom from "@components/SelectCustom";
import { useDispatch, useSelector } from 'react-redux';
import {
    getDistricts, getHubs, getRegions,
    hubsSelector, resetDistrict, resetHub,
    setSelectedRegion, setSelectedDistrict, setSelectedHub
} from '../../../../features/senninha/hubsSlice';
import { clearFilter, clearHunterPin, clearStatus, setLeftOpenDrawer } from '../../../../features/senninha/pinsSlice';

import * as S from './styles';


const HubCardSelection = ({
    setStartWorkflow
}) => {

    //ids
    const [selectedRegionId, setSelectedRegionId] = useState(null)
    const [selectedDistrictId, setSelectedDistrictId] = useState(null)
    const [selectedHubId, setSelectedHubId] = useState(null)

    //desativar os select
    const [disableDistricts, setDisableDistricts] = useState(true)
    const [disableHubs, setDisableHub] = useState(true)

    const {
        regions,
        districts,
        hubs,
        districtsStatus,
        hubsStatus,
        selectedRegionInfos,
        selectedDistrictInfos
    } = useSelector(hubsSelector);

    const dispatch = useDispatch();

    const onChangeRegion = (value, component) => {
        dispatch(
            resetDistrict(),
            resetHub()
        )
        dispatch(
            getDistricts({ //preenche o distritos da regiao selecionada
                id_regiao: value
            })
        )
        dispatch(
            setSelectedRegion({
                selectedRegionInfos: component
            })
        );

        setSelectedRegionId(value);
        setDisableDistricts(false);

        //limpar o valor dos select caso mude a regiao
        setSelectedDistrictId(null)
        setSelectedHubId(null)

        //desabilita a seleção do polo
        setDisableHub(true)
    }

    const onChangeDistrict = (value, component) => {
        dispatch(getHubs({
            id_distrito: value
        }))

        dispatch(
            setSelectedDistrict({
                selectedDistrictInfos: component
            })
        )

        setSelectedDistrictId(value);

        setSelectedHubId(null)
        setDisableHub(false)
    }

    const onChangeHub = (value, component) => {
        dispatch(
            setSelectedHub({
                selectedHubInfos: component
            })
        )
        setSelectedHubId(value);
    }

    useEffect(() => {
        dispatch(
            getRegions(),
            resetHub()
        )
        dispatch(clearStatus())
        dispatch(clearFilter('filtro'))
    }, []);

    useEffect(() => {
        dispatch(clearHunterPin())
        dispatch(setLeftOpenDrawer(false))
    }, []);

    useEffect(() => { // caso a regiao e o distrito ja esteja no slice preenchido
        if (selectedRegionInfos) {
            setDisableDistricts(false)
        }
        if (selectedDistrictInfos) {
            setDisableHub(false)
        }
        if (districts === null) {
            dispatch(
                getDistricts({ //preenche o distritos da regiao selecionada caso a regiao venha null(isso acontece quando o slice vem sem valor)
                    id_regiao: selectedRegionId
                })
            )
        }
        if (hubs === null) {
            dispatch(getHubs({
                id_distrito: selectedDistrictId
            }))
        }
    }, []);

    return (
        <S.HubContainerBackground>
            <S.HubSelectionContainer>
                <S.HubSelectionHeader>
                    <p>
                        Vamos lá?
                    </p>
                    <span>
                        Preencha as informações abaixo para começar.
                    </span>
                </S.HubSelectionHeader>
                <S.HubSelectionContent>
                    <SelectCustom
                        title='Região'
                        placeholder='Selecione uma opção'
                        size='small'
                        options={regions}
                        onChange={onChangeRegion}
                        value={selectedRegionInfos?.value ?? null}
                    />
                    <SelectCustom
                        title='Distrito'
                        placeholder='Selecione uma opção'
                        size='small'
                        options={districts}
                        onChange={onChangeDistrict}
                        disabled={disableDistricts || districtsStatus === 'pending'}
                        value={selectedDistrictInfos?.value ?? selectedDistrictId}
                    />
                    <SelectCustom
                        title='Polo'
                        placeholder='Selecione uma opção'
                        size='small'
                        options={hubs}
                        onChange={onChangeHub}
                        disabled={disableHubs || hubsStatus === 'pending'}
                        value={selectedHubId}
                    />
                </S.HubSelectionContent>
                <S.HubSelectionFooter>
                    <Button
                        type='primary'
                        width='100%'
                        size='medium'
                        disabled={!selectedHubId}
                        onClick={() => setStartWorkflow(true)}
                    >
                        Começar
                    </Button>
                </S.HubSelectionFooter>
            </S.HubSelectionContainer>
        </S.HubContainerBackground>
    )
}

export default HubCardSelection;
