import { GenIcon } from "react-icons";

function UpArrow(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 20 20",
    },
    child: [
      {
        tag: "g",
        fill: "url(#mask0_118_5433)",
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M4.54766 12.2315C4.90564 12.5895 5.48605 12.5895 5.84403 12.2315L9.9984 8.07711L14.1528 12.2315C14.5108 12.5895 15.0912 12.5895 15.4491 12.2315C15.8071 11.8735 15.8071 11.2931 15.4491 10.9351L10.6466 6.13256C10.4747 5.96066 10.2415 5.86408 9.99839 5.86408C9.75528 5.86408 9.52212 5.96066 9.35021 6.13257L4.54766 10.9352C4.18968 11.2931 4.18968 11.8735 4.54766 12.2315Z",
        },
      },
    ],
  })(props);
}

export default UpArrow;
