import React, { useEffect, useState } from "react";
import LogisticService from "@LogisticService";
import { Modal } from "antd";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import SuccessStep from "./SuccessStep";
import ErrorStep from "./ErrorStep";
import { useHistory } from "react-router-dom";
import axios from "axios";

const ModalsRoute = ({
  isModalOpen,
  setIsModalOpen,
  routeType,
  routeData,
  pins,
  areaInfo,
  angelsDisponiveis,
}) => {
  const [step, setStep] = useState("firstStep");
  const [totalOss, setTotalOss] = useState(-1);
  const [changeCount, setChangeCount] = useState(0);
  const [changeError, setChangeError] = useState(false);
  const [changeErrorText, setChangeErrorText] = useState([]);
  const [sourceAxios, setSourceAxios] = useState();
  const [errorsOss, setErrorsOss] = useState([]);

  const history = useHistory();

  useEffect(() => setSourceAxios(axios.CancelToken.source()), []);

  const resetRequestsData = () => {
    setTotalOss(-1);
    setChangeCount(0);
    setChangeError(false);
    setChangeErrorText([]);
  };

  const defineRoute = async () => {
    resetRequestsData();
    const result = await LogisticService.getListaInfosCockpit(areaInfo.pole)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
      });

    const scsToDealocate = [];

    const groupedStonecodesByAngelPins = pins.reduce((groupedByAngel, item) => {
      const angel = angelsDisponiveis.find(
        (angel) => angel.name === item.oss[0].angel
      )?.value;
      if (!angel) return groupedByAngel;
      if (!groupedByAngel[angel]) groupedByAngel[angel] = [];
      groupedByAngel[angel] = [...groupedByAngel[angel], item.stonecode];
      return groupedByAngel;
    }, {});

    const groupedStonecodesByAngelRoute = routeData.reduce(
      (groupedByAngel, item) => {
        const angel = item.email;
        if (!angelsDisponiveis.find((item) => item.value === angel))
          return groupedByAngel;
        if (!groupedByAngel[angel]) groupedByAngel[angel] = [];
        groupedByAngel[angel] = [
          ...groupedByAngel[angel],
          item.clients_sequence.filter(
            (sc) => !(groupedStonecodesByAngelPins[angel] || []).includes(sc)
          ),
        ];
        scsToDealocate.push(
          (groupedStonecodesByAngelPins[angel] || []).filter(
            (sc) => !item.clients_sequence.includes(sc)
          )
        );
        return groupedByAngel;
      },
      {}
    );

    const groupedStonecodesInfo = result.reduce((groupedBySCInfo, item) => {
      const stonecode = item.stonecode;
      if (!groupedBySCInfo[stonecode]) groupedBySCInfo[stonecode] = [];
      groupedBySCInfo[stonecode] = [...groupedBySCInfo[stonecode], item];
      return groupedBySCInfo;
    }, {});

    // const groupedStonecodesInfo = pins.reduce((groupedBySC, item) => {
    //     const stonecode = item.stonecode
    //     if (!groupedBySC[stonecode]) groupedBySC[stonecode] = []
    //     groupedBySC[stonecode] = [...groupedBySC[stonecode], ...item.oss]
    //     return groupedBySC
    // }, {})

    const payloadRealocate = routeData.reduce(
      (payload, item) =>
        !angelsDisponiveis.find((angel) => angel.value === item.email)
          ? payload
          : [
              ...payload,
              {
                polo: areaInfo.pole,
                angel: angelsDisponiveis.find(
                  (angel) => angel.value === item.email
                ),
                oss: groupedStonecodesByAngelRoute[item.email][0]
                  .flatMap((stonecode) => groupedStonecodesInfo[stonecode])
                  .filter((os) => os),
              },
            ],
      []
    );

    const payloadDealocate = {
      polo: areaInfo.pole,
      angel: "",
      oss: scsToDealocate.flat().flatMap((item) => groupedStonecodesInfo[item]),
    };

    payloadRealocate.push(payloadDealocate);
    const fullPayload = payloadRealocate.filter((item) => item.oss.length > 0);
    realocateStonecodes(fullPayload);
  };

  const realocateStonecodes = async (data) => {
    setChangeError(false);
    const batchSize = 20;
    let successCount = 0;
    let errors = [];

    const allPayloads = data.reduce(
      (groupedByPayload, { polo, angel, oss }) => [
        ...groupedByPayload,
        ...(errorsOss.length > 0
          ? oss
              .filter((os1) => errorsOss.includes(os1.order_number))
              .map((os2) => ({ polo, angel, oss: [os2] }))
          : oss.map((os2) => ({ polo, angel, oss: [os2] }))),
      ],
      []
    );

    setErrorsOss([]);
    setTotalOss(allPayloads.length);

    const numBatches = Math.ceil(allPayloads.length / batchSize);

    const makeRequest = async ({ polo, angel, oss }) => {
      try {
        const result = await LogisticService.postRealocaOSsCancel(
          { polo, angel, oss },
          { cancelToken: sourceAxios.token }
        );
        if (result && result.status) {
          setChangeCount((p) => p + 1);
          return true;
        }
      } catch (err) {
        setChangeCount((p) => p + 1);
        errors.push(
          `OS ${oss[0]?.order_number} - ${
            err.response?.status === 400
              ? err.response.data.erro
              : "Erro interno do servidor (500)"
          }`
        );
        setChangeErrorText(errors);
        setErrorsOss((p) => [...p, oss[0]?.order_number]);
        setChangeError(true);
      }
      return false;
    };

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      const batchData = allPayloads.slice(
        batchIndex * batchSize,
        (batchIndex + 1) * batchSize
      );
      if (batchIndex > 0)
        await new Promise((resolve) => setTimeout(resolve, 5000));

      const results = await Promise.all(batchData.map(makeRequest));
      successCount += results.filter((result) => result === true).length;
    }
  };

  useEffect(() => {
    if (
      changeCount === totalOss &&
      !changeError &&
      errorsOss.length === 0 &&
      step === "secondStep"
    ) {
      setStep("successStep");
      const timeout = setTimeout(() => {
        history.push("/", areaInfo);
      }, 1800);

      return () => clearTimeout(timeout);
    } else if (changeCount === totalOss && changeError) {
      setStep("errorStep");
      setChangeCount(0);
    }
  }, [totalOss, changeError, changeCount]);

  return (
    <Modal
      visible={isModalOpen}
      centered
      width={458}
      closable={false}
      footer={null}
      wrapClassName="ginga-modal"
    >
      {step === "firstStep" && (
        <FirstStep
          setIsModalOpen={setIsModalOpen}
          routeType={routeType}
          setStep={setStep}
          defineRoute={defineRoute}
        />
      )}

      {step === "secondStep" && (
        <SecondStep
          setIsModalOpen={setIsModalOpen}
          setStep={setStep}
          totalAmount={totalOss}
          changeCount={changeCount}
          progress={Math.round((changeCount / totalOss) * 100)}
          resetRequestsData={resetRequestsData}
          sourceAxios={sourceAxios}
          setSourceAxios={setSourceAxios}
        />
      )}

      {step === "successStep" && <SuccessStep setStep={setStep} />}

      {step === "errorStep" && (
        <ErrorStep
          setIsModalOpen={setIsModalOpen}
          setStep={setStep}
          totalOss={totalOss}
          errorText={changeErrorText}
          defineRoute={defineRoute}
          setErrorsOss={setErrorsOss}
        />
      )}
    </Modal>
  );
};

export default ModalsRoute;
