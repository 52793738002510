import React, { useEffect, useState, useRef } from "react";
import { Button, Drawer } from "@stonelog/stonelog-design-system";
import { useDispatch, useSelector } from "react-redux";
import {
    applyFilter, setBuildFilters, pinsSelector,
    setCenter, setZoom, clearFilter, setLeftOpenDrawer,
    setBouncePin
} from "../../../../../../../features/senninha/pinsSlice";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";

import * as S from "./styles";


const LegendDrawer = () => {

    const [openIndex, setOpenIndex] = useState(null);
    const openCard = (index) => {
        setOpenIndex(index === openIndex ? null : index);
    };
    const legendDrawer = useRef();

    const dispatch = useDispatch();

    const { pins, filters, leftOpenDrawer } = useSelector(pinsSelector);

    useEffect(() => {
        if (leftOpenDrawer === false) {
            dispatch(setLeftOpenDrawer(true))
        }
    }, [legendDrawer]);

    useEffect(() => {
        dispatch(applyFilter(filters))
    }, [filters]);

    const handleOnClick = (id, lat, lng) => {
        dispatch(setCenter({
            center: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            }
        }))
        dispatch(setBouncePin(
            id
        ))
        dispatch(setZoom(20))
    }

    const handleFilterClick = (pin) => {
        const existingIndex = filters.findIndex(item => item.value === pin);
        if (existingIndex !== -1) {
            // se o valor já existe no array
            const updatedLegends = [...filters];
            updatedLegends.splice(existingIndex, 1);
            dispatch(setBuildFilters(updatedLegends));
        } else {
            // se o valor não existe no array
            const newClickedButtons = [...filters, { field: 'angel', value: pin, drawer: 'legenda' }];
            dispatch(setBuildFilters(newClickedButtons));
        }
    };

    const toggleVisibility = () => {
        if (filters.length === 0) {
            // add todos os itens de volta ao array legends
            dispatch(setBuildFilters(pins.map(pin => ({ field: 'angel', value: pin.angel, drawer: 'legenda' }))));
        } else {
            dispatch(clearFilter('legenda'))
        }
    };

    const calculateAngelVisits = (angelName) => {
        // para calcular o angel em questão
        const angelPins = pins.filter(pin => pin.angel === angelName);
        
        //verifica os atendimento de campanha
        const campaignPins = angelPins.filter((pin) => pin.servico === 'CAMPANHA')
        const totalCampignPins = campaignPins.length

        // verifica quantas visitas
        const totalVisits = angelPins.length - totalCampignPins;

        // verifica quantas oss no total
        let totalOss = 0;
        angelPins.forEach(pin => {
            if (pin?.servico !== 'CAMPANHA') {
                totalOss += pin.oss.length; // add a quantidade de oss para cada pin
            }
        });

        return { totalVisits, totalOss, totalCampignPins};
    };

    const ordemDesejada = [ "Baixadas", "Vencimento futuros (Não alocadas)",  "Vencidas (Não alocadas)", "Vencem hoje (Não alocadas)"];

    return (
        <>
            <Drawer.Body>
                <S.AllLegendDrawerContainer>
                    {
                        Object.values(pins.reduce((groupedPins, currentPin) => {
                            if (!groupedPins[currentPin.angel]) {
                                groupedPins[currentPin.angel] = currentPin;
                            }
                            return groupedPins;
                        }, {}))
                        .sort((pinA, pinB) => {
                            const indexA = ordemDesejada.indexOf(pinA.angel);
                            const indexB = ordemDesejada.indexOf(pinB.angel);
                        
                            if (indexA !== -1 && indexB !== -1) {
                                return indexA - indexB;
                            }
                        
                            if (indexA !== -1) return -1;
                            if (indexB !== -1) return 1;

                            return 0;
                        }).map((pin, idx) => {
                            const activatedLegend = filters.some(item => item.value === pin.angel);
                            const angelCountVisits = calculateAngelVisits(pin.angel)
                            return (
                                <>
                                    <S.LegendRow key={idx}>
                                        <S.LegendLeftRow opacity={activatedLegend}>
                                            <S.LegendIconRow background={pin?.stylePin.color}>
                                                <GingaIcon name={pin?.stylePin.name} size="16px" color="#FFFFFF" />
                                            </S.LegendIconRow>
                                            <div>
                                                <p>
                                                    {pin.angel}
                                                </p>
                                                <S.LegendExtendRow
                                                    onClick={() => openCard(idx)}
                                                >
                                                    <span>
                                                        {angelCountVisits.totalVisits} visitas, {angelCountVisits.totalOss} OS{ angelCountVisits.totalCampignPins ? `, ${angelCountVisits.totalCampignPins} Camp.` : ''}
                                                    </span>
                                                    <GingaIcon
                                                        name="chevron-down"
                                                        size="16px"
                                                        color="#767F8D"
                                                    />
                                                </S.LegendExtendRow>
                                            </div>
                                        </S.LegendLeftRow>
                                        <Button
                                            width="33px"
                                            solid
                                            size="small"
                                            onClick={() => handleFilterClick(pin.angel)}
                                        >
                                            <GingaIcon
                                                name={activatedLegend ? 'eye-open' : 'eye-close'}
                                                color='#303742'
                                                size='18px'
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Button>
                                    </S.LegendRow>
                                    <S.LegendExtendContainer open={openIndex === idx}>
                                        {pins.map((sidePin) => { // seguindo map para add oss ao pin do angel
                                            if (sidePin.angel === pin.angel) {
                                                return sidePin.oss.map((os, idx) => (
                                                    <S.LegendExtendContent key={idx}>
                                                        <div>
                                                            <span onClick={() => { handleOnClick(sidePin?.id, sidePin?.lat, sidePin?.lng) }}>
                                                                {sidePin.servico !== '0TPV' ? os.id_os : 'Mostrar no mapa'}
                                                            </span> - {sidePin?.cliente}
                                                        </div>
                                                        <div>
                                                            <S.LegendTextService>
                                                                {`(${capitalizeAllFirstLetters(os?.servico)})`}
                                                            </S.LegendTextService>
                                                        </div>
                                                    </S.LegendExtendContent>
                                                ));
                                            }
                                        })}
                                    </S.LegendExtendContainer>

                                </>
                            )
                        })
                    }
                </S.AllLegendDrawerContainer>

            </Drawer.Body >
            <Drawer.Footer style={{padding: '16px 24px'}}>
                <Button
                    width="100%"
                    size="medium"
                    onClick={toggleVisibility}
                >
                    <GingaIcon name={filters.length === 0 ? 'eye-open' : 'eye-close'} color='#303742' size='18px' />
                    {filters.length === 0 ? `Mostrar tudo` : `Ocultar tudo`}
                </Button>
            </Drawer.Footer>
        </>
    )
}

export default LegendDrawer;
