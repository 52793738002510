import { GenIcon } from "react-icons";

function TrashIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 20 20",
    },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M8.33333 9.1665C8.79357 9.1665 9.16667 9.5396 9.16667 9.99984V13.3332C9.16667 13.7934 8.79357 14.1665 8.33333 14.1665C7.8731 14.1665 7.5 13.7934 7.5 13.3332V9.99984C7.5 9.5396 7.8731 9.1665 8.33333 9.1665Z",
        },
      },
      {
        tag: "path",
        attr: {
          d:
            "M12.5 9.99984C12.5 9.5396 12.1269 9.1665 11.6667 9.1665C11.2064 9.1665 10.8333 9.5396 10.8333 9.99984V13.3332C10.8333 13.7934 11.2064 14.1665 11.6667 14.1665C12.1269 14.1665 12.5 13.7934 12.5 13.3332V9.99984Z",
        },
      },
      {
        tag: "path",
        attr: {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d:
            "M8.33333 1.6665C6.95262 1.6665 5.83333 2.78579 5.83333 4.1665V4.99984H3.33333C2.8731 4.99984 2.5 5.37293 2.5 5.83317C2.5 6.29341 2.8731 6.6665 3.33333 6.6665H4.16667V12.4998C4.16667 14.8406 4.16667 16.011 4.72843 16.8517C4.97163 17.2157 5.28413 17.5282 5.6481 17.7714C6.48884 18.3332 7.65923 18.3332 10 18.3332C12.3408 18.3332 13.5112 18.3332 14.3519 17.7714C14.7159 17.5282 15.0284 17.2157 15.2716 16.8517C15.8333 16.011 15.8333 14.8406 15.8333 12.4998V6.6665H16.6667C17.1269 6.6665 17.5 6.29341 17.5 5.83317C17.5 5.37293 17.1269 4.99984 16.6667 4.99984H14.1667V4.1665C14.1667 2.78579 13.0474 1.6665 11.6667 1.6665H8.33333ZM12.5 4.99984V4.1665C12.5 3.70627 12.1269 3.33317 11.6667 3.33317H8.33333C7.8731 3.33317 7.5 3.70627 7.5 4.1665V4.99984H12.5ZM5.83333 6.6665H14.1667V12.4998C14.1667 13.7049 14.1647 14.4833 14.105 15.0696C14.0482 15.6281 13.9532 15.8249 13.8858 15.9258C13.7642 16.1078 13.6079 16.264 13.426 16.3856C13.3251 16.453 13.1283 16.548 12.5698 16.6048C11.9834 16.6645 11.2051 16.6665 10 16.6665C8.79493 16.6665 8.01657 16.6645 7.43022 16.6048C6.87171 16.548 6.67493 16.453 6.57405 16.3856C6.39207 16.264 6.23581 16.1078 6.11422 15.9258C6.04681 15.8249 5.95181 15.6281 5.895 15.0696C5.83535 14.4833 5.83333 13.7049 5.83333 12.4998V6.6665Z",
        },
      },
    ],
  })(props);
}

export default TrashIcon;
