import React from "react";
import { Drawer } from "antd";
import CloseIcon from "@assets/icons/CloseIcon";

export const DrawerCard = (props, ...rest) => {
  const toggleDrawer = () => {
    props.setIsVisible(!props.visible);
  };

  return (
    <Drawer
      title={props.title}
      placement={props.placement}
      onClose={toggleDrawer && props.onClose}
      visible={props.visible}
      headerStyle={{ borderBottomWidth: "0" }}
      closable={props.closable}
      bodyStyle={props.bodyStyle}
      closeIcon={<CloseIcon size={24} />}
      {...rest}
    >
      {props.children}
    </Drawer>
  );
};
