import Colors from "@colors";
import { Form, Input } from "antd";
import styled from "styled-components";


export const InfoWindowContent = styled.div`
  max-height: 320px;
  svg {
    transition: 0.2s;
    &:hover {
      fill: ${Colors.lightGreen};
    }
  }
`;

export const AngelName = styled.p`
  font-size: 16px;
  color: ${Colors.deepBlue};
  margin-bottom: 8px;
`;

export const InfoLine = styled.p`
  font-size: 14px;
  margin: 2px 0;
  color: ${Colors.darkGray};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

export const Field = styled.span`
  font-size: 12px;
  text-transform: uppercase;
`;

export const Line = styled.div`
  display: ${(props) => (props.hidden ? "none" : "block")};
  width: 100%;
  height: 1px;
  background: ${Colors.lighterGray};
  margin: 8px 0;
`;

export const OSsTitle = styled.p`
  color: ${Colors.deepBlue};
  margin: 16px 0 8px;
  font-size: 14px;
`;

export const OSNumber = styled.p`
  font-weight: bold;
  margin: 2px 0;
  color: ${Colors.lightGreen};
`;

export const OSsList = styled.div`
  padding-left: 8px;
`;

export const Cliente = styled.span`
  color: ${Colors.textGreen};
  font-weight: bold;
`;

export const ContainerStyle = {
  width: "100vw",
  // height: '100vh'
};

export const Text = styled.span`
  font-size: 16px;
  color: ${Colors.deepBlue};
  margin: 8px 0;
`;

export const MapStyles = {
  default: [],
  silver: [
    // {
    //     elementType: "geometry",
    //     stylers: [{ color: "#f5f5f5" }],
    // },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    // {
    //     featureType: "road",
    //     elementType: "geometry",
    //     stylers: [{ color: "#ffffff" }],
    // },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    // {
    //     featureType: "water",
    //     elementType: "geometry",
    //     stylers: [{ color: "#c9c9c9" }],
    // },
    // {
    //     featureType: "water",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#9e9e9e" }],
    // },
  ],
};

export const PoligonoContainer = styled.div`
  background-color: white;
  position: absolute;
  z-index: 100;
  left: 88.4px;
  top: 68px;
  border-radius: 8px;
  padding: 16px;
`;

export const Info = styled.p`
  margin: ${(props) => (props.margin ? props.margin : "0 8px 8px")};
  font-size: ${(props) => (props.size ? props.size : "14px")};
  max-width: 400px;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px 16px;
`;

export const ColumnTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
`;