import styled from "styled-components";
import defaultTheme from "@defaultTheme";
import { Button } from "antd";

export const FooterButton = styled(Button)`
  width: ${(props) => (props.customWidth ? props.customWidth : "100%")};
  height: 44px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-family: "Sharon Sans";
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  border-color: transparent !important;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-left: ${(props) => (props.handleButton ? "18px" : "0px")} !important;
  margin-top: 8px;
  background-color: ${(props) =>
    props.firstbutton || props.handleButton
      ? defaultTheme.colors.green600
      : defaultTheme.colors.grey50};
  color: ${(props) =>
    props.firstbutton || props.handleButton
      ? defaultTheme.colors.white
      : defaultTheme.colors.grey700} !important;
  &:hover {
    background-color: ${(props) =>
      props.firstbutton || props.handleButton
        ? defaultTheme.colors.green700
        : defaultTheme.colors.grey20} !important;
  }
`;

export const ContainerButtons = styled.div`
  width: ${(props) => (props.customWidth ? props.customWidth : "auto")};
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    :first-child {
      background: ${defaultTheme.colors.green600} !important;
    }
    background: ${defaultTheme.colors.grey50} !important;
  }
`;
