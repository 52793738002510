import LoadingSpinner from "@components/LoadingSpinner";
import { Image, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import Box from "@components/Box";
import Container from "@components/Container";
import Row from "@components/Row";
import LogisticService from "@LogisticService";
import { ImageBox, Info, Title, LinkMaps } from "../styles";

export default function ImagensLogLoj(props) {
  const loading = <LoadingSpinner />;
  const [dados, setDados] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getDadosAnexosLogLoj(props.numeroOS)
      .then((response) => {
        if (response.data["erro"]) {
          message.error(`${response.data.erro}`);
          props.voltar();
        } else {
          setDados(response.data?.data);
          setLoaded(true);
        }
      })
      .catch((err) => {
        if (err.response?.data?.erro) {
          message.error(`${err.response?.data?.erro}`);
          props.voltar();
        }
      });
  }, [props.numeroOS]);

  const RowOs = () => {
    return (
      <>
        {loaded && dados ? (
          <>
            {dados.map((dado) => (
              <Row>
                <Container grid={1 / 2} title={`Dados Auditoria`}>
                  <Box>
                    <Title style={{ margin: "5px", font: "menu" }}>
                      Documento: {dado.documento}
                    </Title>
                    <Title style={{ margin: "5px", font: "menu" }}>
                      Nome: {dado.nome}
                    </Title>
                    <Title style={{ margin: "5px", font: "menu" }}>
                      Tecnico: {dado.technician_email}
                    </Title>
                    <Title style={{ margin: "5px", font: "menu" }}>
                      Status: {dado.status}
                    </Title>
                    <Title style={{ margin: "5px", font: "menu" }}>
                      Latitude: {dado.latitude}
                    </Title>
                    <Title style={{ margin: "5px", font: "menu" }}>
                      Longitude: {dado.longitude}
                    </Title>
                    <LinkMaps
                      to={{
                        pathname: `http://www.google.com/maps/place/${dado.latitude},${dado.longitude}`,
                      }}
                      target="_blank"
                    >
                      Clique aqui para ver no mapa
                    </LinkMaps>
                  </Box>
                </Container>
                <Container grid={1 / 2} title={`Assinatura`} advice={"imagem"}>
                  <ImageBox>
                    <Image rootClassName="ginga-image" src={dado.assinatura} />
                  </ImageBox>
                </Container>
              </Row>
            ))}
          </>
        ) : (
          loading
        )}
      </>
    );
  };
  return (
    <Box>
      {}
      <RowOs></RowOs>
    </Box>
  );
}
