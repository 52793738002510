import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Form, message } from "antd";

import Box from "@components/Box";
import Container from "@components/Container";
import DefaultInput from "@components/DefaultInput";
import MaterialTableComponent from "@components/MaterialTableComponent";
import Page from "@components/Page";
import Row from "@components/Row";
import SelectBox from "@components/SelectBox";
import SubmitButton from "@components/SubmitButton";
import InputFile from "@components/InputFile";
// import useFileInput from "@hooks/useFileInput";

import LogisticService from "@LogisticService";

import { Span } from "./styles";
import { PageLayout } from "../../../Layout/PageLayout";

const NotificacoesApp = () => {
  const [angels, setAngels] = useState([]);
  const [polos, setPolos] = useState([]);
  const [arvore, setArvore] = useState([]);

  const [base64image, setBase64image] = useState(null);
  // const { handleChange, clear } = useFileInput(base64image, setBase64image);

  const [disabledTree, setDisabledTree] = useState(true);
  const [disabledAngels, setDisabledAngels] = useState(true);
  const [disabledPolos, setDisabledPolos] = useState(true);

  const formRef = React.createRef();
  const [form] = Form.useForm();

  const [successfully, setSuccessfully] = useState("wait");

  useEffect(() => {
    LogisticService.getAngels().then((response) => {
      if (response) {
        setAngels(response.data);
        setDisabledAngels(false);
      }
    });
  }, []);

  useEffect(() => {
    LogisticService.getPolosWKF().then((response) => {
      if (response) {
        setPolos(response.data);
        setDisabledPolos(false);
      }
    });
  }, []);

  useEffect(() => {
    LogisticService.getNotificacaoApp().then((response) => {
      if (response) {
        setArvore(response.data);
        setDisabledTree(false);
      }
    });
  }, []);

  const postForm = async (data) => {
    if (data) {
      return LogisticService.postNotificacaoApp(data)
        .then((response) => {
          if (response) {
            return response.status;
          }
        })
        .catch((response) => {
          return response;
        });
    }
  };

  const onFinish = useCallback(
    async (image, data) => {
      const formObj = {
        destinatarios: data.polos,
        titulo: data.titulo,
        texto: data.texto,
        link: data.link,
        imagem: image,
      };
      if (data.todos_polos && polos) {
        formObj.destinatarios = polos.map((polo) => polo.id);
      }
      console.log(formObj);

      const status = await postForm(formObj);

      if (status === 200) {
        setSuccessfully("ok");
        form.resetFields();
        setDisabledPolos(false);
        setDisabledAngels(false);
        setTimeout(() => {
          setSuccessfully("wait");
        }, 3000);
      } else {
        setSuccessfully("ok");
        setTimeout(() => {
          setSuccessfully("wait");
        }, 3000);
      }
    },
    [polos]
  );

  const onChangeCheckBox = (e) => {
    if (form.getFieldValue("polos").length > 0)
      setDisabledPolos(e.target.checked);
    else if (form.getFieldValue("angels").length > 0)
      setDisabledAngels(e.target.checked);
    else {
      setDisabledPolos(e.target.checked);
      setDisabledAngels(e.target.checked);
    }
  };

  const onChangePolos = (e) => {
    if (Object.keys(e).length > 0) {
      setDisabledAngels(true);
    } else {
      setDisabledAngels(false);
    }
  };

  const onChangeAngels = (e) => {
    if (Object.keys(e).length > 0) {
      setDisabledPolos(true);
    } else {
      setDisabledPolos(false);
    }
  };

  const alert = () => {
    message.warning(
      "Aguarde, em processamento, aparecerá na lista abaixo ao finalizar!"
    );
  };

  const rows = [
    <Row key="0" fullSize>
      <Form.Item name="titulo" label="Título" labelCol={{ span: 24 }}>
        <DefaultInput placeholder="Título da Notificação" />
      </Form.Item>
    </Row>,
    <Row key="1" fullSize>
      <Form.Item name="texto" label="Texto" labelCol={{ span: 24 }}>
        <DefaultInput placeholder="Texto da Notificação" type="TextArea" />
      </Form.Item>
    </Row>,
    <Row key="2" fullSize>
      <Form.Item name="link" label="Link" labelCol={{ span: 24 }}>
        <DefaultInput placeholder="Link" type="link" />
      </Form.Item>
    </Row>,
    <Row horizontalAlign="left" verticalAlign="center" key="3" fullSize>
      <Form.Item name="todos_polos" valuePropName="checked">
        <Checkbox onChange={onChangeCheckBox}>
          <Span>Selecionar Todos os Polos Stone</Span>
        </Checkbox>
      </Form.Item>
    </Row>,
    <Row key="5" fullSize>
      <Form.Item name="image" label="Imagem" labelCol={{ span: 24 }}>
        <InputFile
          base64image={base64image}
          handleChange={(e) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              setBase64image(reader.result);
            };
          }}
          clear={() => setBase64image(null)}
        />
      </Form.Item>
    </Row>,
    <Row key="5" fullSize>
      <Form.Item name="polos" label="Polos" labelCol={{ span: 24 }}>
        <SelectBox
          onChange={onChangePolos}
          placeholder="Polos"
          width="100%"
          options={polos}
          disabled={disabledPolos}
          multiple
        />
      </Form.Item>
    </Row>,
    // <Row key="6" fullSize>
    //   <Form.Item name="angels" label="Angels" labelCol={{ span: 24 }}>
    //     <SelectBox
    //       onChange={onChangeAngels}
    //       placeholder="Angels"
    //       width="100%"
    //       options={angels}
    //       disabled={disabledAngels}
    //       multiple
    //     />
    //   </Form.Item>
    // </Row>,
  ];

  const colunas = [
    {
      title: "Data",
      field: "data_envio",
      editable: "never",
      type: "datetime",
      width: 60,
    },
    { title: "Título", field: "titulo", width: 60 },
    // { title: 'Polos/Angels', field: 'polos', width: 80 },
    { title: "Texto", field: "texto", width: 100 },
  ];

  const Table = () => {
    return (
      <MaterialTableComponent
        columns={colunas}
        data={arvore}
        isLoading={disabledTree}
        title={"Notificações Enviadas"}
        bodyStyle={{
          overflow: "none",
        }}
        addOptions={{
          actionsColumnIndex: -1,
          pageSize: 20,
          showTitle: true,
          tableLayout: "fixed",
        }}
      />
    );
  };

  return (
    <PageLayout>
      <Box>
        <Row>
          <Container
            grid={1 / 3}
            title={`Enviar Notificações App Green Angels`}
            padding={"0 16px 16px 16px"}
            rounded={true}
            horizontalAlign="center"
          >
            <Form
              onFinish={(data) => onFinish(base64image, data)}
              form={form}
              ref={formRef}
              initialValues={{
                titulo: "",
                texto: "",
                todos_polos: false,
                polos: [],
                angels: [],
              }}
            >
              {rows}
              <Row horizontalAlign={"center"}>
                {polos && (
                  <SubmitButton
                    verifyer={successfully}
                    textSuccess="Enviado Com Sucesso! ( ˘◡˘)"
                    textError="Falha Ao Enviar! ( ˘︹˘ )"
                    onClick={alert}
                    disabled={polos.length === 0}
                  >
                    Enviar
                  </SubmitButton>
                )}
              </Row>
            </Form>
          </Container>
          <Container grid={2 / 3} rounded={true}>
            <Table />
          </Container>
        </Row>
      </Box>
    </PageLayout>
  );
};

export default NotificacoesApp;
