import React, { useState, useEffect } from "react";
import LogisticService from "@LogisticService";
import CloseIcon from "@assets/icons/CloseIcon";
import ModifyRoutes from "./components/index";
import SimulationLegend from "./components/SimulationLegend";
import {
  AngelName,
  Cliente,
  Field,
  InfoLine,
  InfoWindowContent,
  Line,
  OSNumber,
  OSsList,
  OSsTitle,
} from "../../../componentes/Mapa/styles";
import { GoogleMap, Marker, InfoWindow, Polygon } from "@react-google-maps/api";
import { Modal } from "antd";
import { Filters } from "../../../componentes/Filters";

import * as S from "./styles";

const inicialCenter = { lat: -18.0, lng: -54.0 };
const inicialZoom = 5;

const SimulationMap = (props) => {
  const [pins, setPins] = useState(null);
  const [legenda, setLegenda] = useState(null);
  const [center, setCenter] = useState(inicialCenter);
  const [modo, setModo] = useState(null);
  const [animacaoPin, setAnimacaoPin] = useState(null);
  const [zoom, setZoom] = useState(inicialZoom);
  const [infoWindowAtivo, setInfoWindowAtivo] = useState(null);
  const [stonecodesRealocacao, setStonecodesRealocacao] = useState([]);
  const [angelsDisponiveis, setAngelsDisponiveis] = useState(null);
  const [unfilteredPins, setUnfilteredPins] = useState(false); // ??
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPins(props.pins);
    setModo(props.modo);
    setAngelsDisponiveis(props.angelsDisponiveis);
    if (props.pins) recreateLegendThroughPins(props.pins);
  }, [props.pins, props.modo, props.angelsDisponiveis]);

  useEffect(() => props.setSimulatedPins(pins), [pins]);

  const recreateLegendThroughPins = (pins) => {
    const result = [];

    pins.forEach((item) => {
      const existingItem = result.find(
        (resultItem) =>
          resultItem.icon.cor === item.icon.cor &&
          resultItem.icon.stroke === item.icon.stroke &&
          resultItem.icon.opacity === item.icon.opacity
      );

      if (existingItem) {
        const ossItem = existingItem.oss.find(
          (oss) => oss.stonecode === item.stonecode
        );
        if (ossItem) {
          ossItem.items.push(item.oss[0]);
        } else {
          existingItem.oss.push({
            id: item.id,
            stonecode: item.stonecode,
            latitude: item.latitude,
            longitude: item.longitude,
            items: [...item.oss],
          });
        }
        existingItem.oss_count = existingItem.oss.reduce(
          (count, oss) => count + oss.items.length,
          0
        );
      } else {
        result.push({
          icon: { ...item.icon },
          nome: item.oss[0].angel,
          visivel: !item.oss[0].angel.toLowerCase().includes("hunter"),
          visibilidade: true,
          oss_count: item.oss.length,
          oss: [
            {
              id: item.id,
              stonecode: item.stonecode,
              latitude: item.latitude,
              longitude: item.longitude,
              items: [...item.oss],
            },
          ],
          stonecode: item.stonecode,
        });
      }
    });

    setLegenda(result);
  };

  // funcoes temporarias do evento MOVE desafio cockpit
  const manageAmountSimulationsDoneChallenge = () => {
    const simulation = JSON.parse(localStorage.getItem("simulationChallenge"));
    let amount = 1;
    if (simulation) amount = simulation.amount + 1;

    localStorage.setItem(
      "simulationChallenge",
      JSON.stringify({
        date: new Date().toISOString().split("T")[0],
        amount,
      })
    );
  };

  const postSimulationChallenge = async () => {
    const angelsNomes = angelsDisponiveis.map((item) => item.name);
    const angelsInfo = legenda.filter((item) =>
      angelsNomes.includes(item.nome)
    );

    const payload = {
      rotas: angelsInfo.map((item) => ({
        nome: item.nome,
        email:
          angelsDisponiveis.find((angel) => angel.name === item.nome)?.value ||
          "",
        documento:
          angelsDisponiveis.find((angel) => angel.name === item.nome)
            ?.document_number || "",
        provider: "SP - MOVE CHALLENGE",
        ordens: item.oss
          .flatMap((os) => os.items)
          .map((osItem) => osItem.order_number),
      })),
    };

    try {
      console.log(payload);
      manageAmountSimulationsDoneChallenge();
      props.setDistrictalSimulation(null);
      props.setTotalSimulationsInfo((p) => ({ ...p, Distrital: null }));

      LogisticService.postSimulaRotaDistritalCockpit(payload).then(
        (response) => {
          props.getSimulationDistrictalChallenge();
        }
      );
    } catch (e) {
      if (e.response.status === 403) {
        localStorage.setItem(
          "simulationChallenge",
          JSON.stringify({
            date: new Date().toISOString().split("T")[0],
            amount: 3,
          })
        );
      }
    }
    props.setOpenSimulationModal(false);
  };

  useEffect(() => {
    setPins(props.pins);
    recreateLegendThroughPins(props.pins);
  }, [props.resetPinsOnExitChallenge]);

  // fim

  const manageAmountSimulationsDone = () => {
    const simulation = JSON.parse(localStorage.getItem("simulation"));
    let amount = 1;
    if (simulation) amount = simulation.amount + 1;

    localStorage.setItem(
      "simulation",
      JSON.stringify({
        date: new Date().toISOString().split("T")[0],
        amount,
        pole: props.polo,
      })
    );
  };

  const postSimulation = async () => {
    const angelsNomes = angelsDisponiveis.map((item) => item.name);
    const angelsInfo = legenda.filter((item) =>
      angelsNomes.includes(item.nome)
    );

    const payload = {
      rotas: angelsInfo.map((item) => ({
        nome: item.nome,
        email:
          angelsDisponiveis.find((angel) => angel.name === item.nome)?.value ||
          "",
        documento:
          angelsDisponiveis.find((angel) => angel.name === item.nome)
            ?.document_number || "",
        provider: props.polo,
        ordens: item.oss
          .flatMap((os) => os.items)
          .map((osItem) => osItem.order_number),
      })),
    };

    setLoading(true);
    LogisticService.postSimulaRotaDistritalCockpit(payload)
      .then(() => {
        manageAmountSimulationsDone();
        props.setDistrictalSimulation(null);
        props.setTotalSimulationsInfo((p) => ({ ...p, Distrital: null }));
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 403) {
          localStorage.setItem(
            "simulation",
            JSON.stringify({
              date: new Date().toISOString().split("T")[0],
              amount: 3,
              pole: props.polo,
            })
          );
        }
      })
      .finally(() => {
        props.setOpenSimulationModal(false);
        setLoading(false);
      });
  };

  const toggleInfoWindow = (index, editando = false) => {
    if (infoWindowAtivo === index) return setInfoWindowAtivo(null);
    setInfoWindowAtivo(index);
    return;
  };

  const selectMarker = (stonecode) => {
    let pinsCopia = [...pins];
    let pinsSelecionados = [...stonecodesRealocacao];

    const position = pinsSelecionados.indexOf(stonecode);
    if (position === -1) {
      pinsSelecionados.push(stonecode);
    } else {
      pinsSelecionados.splice(position, 1);
    }
    pinsCopia.map((marker) => {
      if (marker.stonecode === stonecode) {
        marker.marcado = !marker.marcado;
      }
    });

    setPins([...pinsCopia]);
    setStonecodesRealocacao(pinsSelecionados);
  };

  // parte inferior roxa, ?
  // ${opacity && '<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8985 19.2739C19.4656 22.1637 15.8212 23.9998 11.748 23.9998C7.86246 23.9998 4.36718 22.329 1.9414 19.6666C2.24707 20.271 2.57764 20.8629 2.93231 21.4406C3.64979 22.633 4.43547 23.78 5.24895 24.9067C6.1003 26.0904 6.98446 27.2495 7.86818 28.408C7.96523 28.5352 8.06227 28.6624 8.15926 28.7897C8.27516 28.942 8.39019 29.0949 8.50517 29.2478C8.78119 29.6149 9.05698 29.9816 9.34411 30.3383C9.88895 31.0177 10.483 31.6545 11.0794 32.294C11.3901 32.627 11.7014 32.9608 12.0068 33.3017L12.1028 33.1956C12.1835 33.1004 12.2643 33.0052 12.3451 32.91C12.6439 32.5579 12.9434 32.205 13.2397 31.849C13.3934 31.6657 13.5485 31.4838 13.7035 31.3019C14.104 30.8321 14.5041 30.3627 14.8793 29.8709C16.1045 28.2667 17.3273 26.6474 18.4742 24.9825C19.5241 23.4791 20.4889 21.9178 21.3643 20.3063C21.5502 19.9661 21.7283 19.6219 21.8985 19.2739Z" fill="#7757D9"/>'}
  const svgMarker = (text, color, stroke, opacity, dashed) =>
    "data:image/svg+xml;utf8," +
    encodeURIComponent(
      `<?xml version="1.0"?>
        <svg width="30" height="42" viewBox="-2 -2 34 46" version="1.1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="${color}" stroke="${
        stroke ? stroke.replace("%23", "#") : color
      }" stroke-width="2" fill-opacity="${
        opacity ? opacity : 1
      }" d="M12.0062 33.3017C11.1144 32.3063 10.1721 31.3716 9.34348 30.3383C8.93674 29.8331 8.55274 29.3076 8.15864 28.7897C7.17843 27.5038 6.19316 26.2204 5.24832 24.9067C4.43485 23.78 3.64916 22.6331 2.93169 21.4406C2.16319 20.1888 1.50786 18.8706 0.973796 17.5021C0.610259 16.5603 0.341964 15.5846 0.172954 14.5893C0.0693748 13.9807 0.0160696 13.3648 0.0137959 12.7476C0.0137959 12.4419 0.0137959 12.1362 0.00116432 11.8305C-0.0392567 8.99954 0.974048 6.25192 2.84327 4.12527C4.80243 1.90059 7.51796 0.478782 10.4626 0.136214C11.1614 0.0495614 11.8668 0.0283403 12.5696 0.0730561C15.4291 0.218572 18.1419 1.39356 20.2041 3.38C20.9863 4.11213 21.6868 4.92712 22.2934 5.81032C23.0985 6.99794 23.6341 8.3475 23.8622 9.764C23.9817 10.5156 24.0231 11.2775 23.986 12.0377C23.9125 13.3691 23.6636 14.6848 23.2458 15.951C22.7537 17.4576 22.1236 18.9156 21.3637 20.3063C20.4883 21.9179 19.5235 23.4791 18.4736 24.9825C17.3266 26.6474 16.1039 28.2667 14.8786 29.871C14.3582 30.5531 13.7898 31.1922 13.2391 31.8491C12.8626 32.3013 12.4812 32.7484 12.1022 33.1956L12.0062 33.3017Z"/>            
            <text x="36%" y="28%" font-size="13px" text-anchor="middle" alignment-baseline="middle" font-weight="bold" fill="${
              stroke === "#00A868" ? "#00A868" : "#fff"
            }" font-family="Helvetica">${text}</text>
        </svg>`
    );

  const renderPins = () => {
    if (!props.googleMapsLoaded || !window.google || !window.google.maps)
      return null;

    let usedCoords = []; // para separar dois pins com mesmas coordenadas, mas diferentes stonecodes
    if (pins)
      return pins.map((marker) => {
        const stonecode = marker.stonecode;
        const id = marker.id;
        if (marker.visivel && marker.filtrado) {
          let markerLongitude = marker.longitude;
          let encontrado = usedCoords.find(
            (el) =>
              el.latitude === marker.latitude &&
              el.longitude === markerLongitude
          );

          if (!encontrado) {
            usedCoords.push({
              latitude: marker.latitude,
              longitude: markerLongitude,
            });
          } else {
            while (encontrado) {
              markerLongitude -= 0.00004;
              encontrado = usedCoords.find(
                (el) =>
                  el.latitude === marker.latitude &&
                  el.longitude === markerLongitude
              );
            }
            usedCoords.push({
              latitude: marker.latitude,
              longitude: markerLongitude,
            });
          }
          function teste(id) {
            toggleInfoWindow(id);
          }
          const pinStyle = svgMarker(
            marker.icon.text,
            marker.icon.cor.replace("%23", "#"),
            marker.icon.stroke,
            marker.icon.opacity
          );
          const markedPinStyle = svgMarker(
            marker.servico?.toUpperCase() === "CAÇA POS" ? 'CP' : marker.servico?.slice(0, 2).toUpperCase(),
            "#42EC9A",
            "#00A868",
            null,
            true
          );
          const coordenadas = { lat: marker.latitude, lng: markerLongitude };
          const icon = {
            url:
              marker.marcado &&
              (modo === "selecionar_realoca" || modo === "realocar")
                ? markedPinStyle
                : pinStyle,
            scaledSize: new window.google.maps.Size(30, 42),
            labelOrigin: new window.google.maps.Point(13, 14),
          };
          const onClickFunction = () => {
            if (modo === "mapa" || modo === "realocar") {
              return teste(id, stonecode);
            } else if (modo === "selecionar_realoca") {
              return selectMarker(stonecode);
            }
          };
          return (
            <Marker
              id={id}
              key={id}
              zIndex={id}
              noRedraw={true}
              optimized={true}
              position={coordenadas}
              icon={icon}
              animation={animacaoPin === id ? 1 : null}
              onClick={onClickFunction}
            >
              {infoWindowAtivo !== null && infoWindowAtivo === id ? (
                <InfoWindow position={coordenadas}>
                  <InfoWindowContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <AngelName>{marker.oss[0].angel}</AngelName>
                      <CloseIcon
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          toggleInfoWindow(null);
                        }}
                      />
                    </div>
                    <InfoLine>
                      <Field>Stonecode:</Field> <Cliente>{stonecode}</Cliente>
                    </InfoLine>
                    <InfoLine>
                      <Field>Cliente:</Field>{" "}
                      <Cliente>{marker.oss[0].cliente}</Cliente>
                    </InfoLine>
                    {marker.oss[0].angel == "Hunter STONE" ||
                    marker.oss[0].angel == "Hunter STONE (Com Ineficiência)" ||
                    marker.oss[0].angel == "Hunter TON" ||
                    marker.oss[0].angel == "Hunter TON (Com ineficiência)" ? (
                      <>
                        <InfoLine>
                          <Field>Ineficiencia: </Field>
                          {marker.oss[0].ineficiencia}
                        </InfoLine>
                        <InfoLine>
                          <Field>Endereço: </Field>
                          {marker.oss[0].endereco}
                        </InfoLine>
                        <InfoLine>
                          <Field>Contratante: </Field>
                          {marker.oss[0].contratante}
                        </InfoLine>
                        <InfoLine>
                          <Field>Horário de Funcionamento:</Field>{" "}
                          {marker.oss[0].horario_funcionamento}
                        </InfoLine>
                      </>
                    ) : (
                      <>
                        <InfoLine>
                          <Field>Cidade: </Field>
                          {marker.oss[0].cidade}
                        </InfoLine>
                        <InfoLine>
                          <Field>Referência: </Field>
                          {marker.oss[0].referencia}
                        </InfoLine>
                        <InfoLine>
                          <Field>Polo:</Field> {marker.oss[0].polo}
                        </InfoLine>
                        <OSsTitle>OSs neste local:</OSsTitle>
                        {marker.oss.map((os, index) => {
                          return (
                            <OSsList key={index}>
                              <OSNumber>{os.order_number}</OSNumber>
                              <InfoLine bold>{os.servico}</InfoLine>
                              <InfoLine>
                                <Field>Status:</Field> {os.status}
                              </InfoLine>
                              <InfoLine>
                                <Field>Data limite:</Field> {os.deadline_date}
                              </InfoLine>
                              <InfoLine>
                                <Field>Data limite do cliente:</Field>{" "}
                                {os.customer_deadline_date}
                              </InfoLine>
                              <InfoLine>
                                <Field>Contratante:</Field> {os.contratante}
                              </InfoLine>
                              <InfoLine>
                                <Field>Horário de Funcionamento:</Field>
                                {os.horario_funcionamento}
                              </InfoLine>
                              {os.modelo_entrada || os.tec_entrada ? (
                                <InfoLine>
                                  <Field>Entrada:</Field>
                                  {`${os.tec_entrada} - ${os.modelo_entrada}`}
                                </InfoLine>
                              ) : (
                                <></>
                              )}
                              {os.modelo_saida || os.tec_saida ? (
                                <InfoLine>
                                  <Field>Saída:</Field>
                                  {`${os.tec_saida} - ${os.modelo_saida}`}
                                </InfoLine>
                              ) : (
                                <></>
                              )}
                              <Line hidden={index === marker.oss.length - 1} />
                            </OSsList>
                          );
                        })}
                      </>
                    )}
                  </InfoWindowContent>
                </InfoWindow>
              ) : (
                <></>
              )}
            </Marker>
          );
        }
      });
  };

  const renderAreaDeRisco = () => (
    <>
      {props.areasRisco.map((area, index) => (
        <Polygon
          options={{
            fillColor: "#D21404",
            strokeColor: "#D21404",
            fillOpacity: 0.4,
            strokeWeight: 2,
          }}
          visible={true}
          key={index}
          path={area["bounds"]}
          editable={false}
        />
      ))}
    </>
  );

  return (
    <Modal
      visible={props.openSimulationModal}
      footer={null}
      closable={false}
      wrapClassName="ginga-modal ginga-modal-simulation"
    >
      <S.Container>
        <S.Header>
          <h1>Simulador</h1>
          <S.CloseContent onClick={() => props.setOpenSimulationModal(false)}>
            <CloseIcon size={20} fill="#000" />
          </S.CloseContent>
        </S.Header>
        {props.googleMapsLoaded && (
          <GoogleMap
            mapContainerClassName="App-map"
            mapContainerStyle={{
              width: "100%",
              height: "100%",
              borderRadius: "0rem 0rem 0.75rem 0.75rem",
            }}
            center={center}
            zoom={zoom}
            on
            options={{
              disableDefaultUI: true,
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              gestureHandling: "greedy",
            }}
            clickableIcons={false}
          >
            {pins && renderPins()}

            <ModifyRoutes
              setModo={setModo}
              stonecodesRealocacao={stonecodesRealocacao}
              pins={pins}
              setPins={setPins}
              setStonecodesRealocacao={setStonecodesRealocacao}
              angelsDisponiveis={angelsDisponiveis}
              recreateLegendThroughPins={recreateLegendThroughPins}
            />

            <SimulationLegend
              data={legenda}
              setData={setLegenda}
              setCenter={setCenter}
              setZoom={setZoom}
              animacaoPin={animacaoPin}
              setAnimacaoPin={setAnimacaoPin}
              pins={pins}
              setPins={setPins}
              postSimulation={
                props.isChallenge ? postSimulationChallenge : postSimulation
              }
              angelsDisponiveis={angelsDisponiveis}
              loading={loading}
            />

            <Filters
              pins={pins}
              setPins={setPins}
              unfiltered={unfilteredPins}
              setUnfiltered={setUnfilteredPins}
              className="filters-cockpit"
              fadeAnimation
            />

            {renderAreaDeRisco()}
          </GoogleMap>
        )}
      </S.Container>
    </Modal>
  );
};

export default SimulationMap;
