import React from "react";
import { Controller } from "react-hook-form";
import { FormMessageError } from "./FormMessageError";
import { Input } from "@components/Input";

export const FormInput = ({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  placeholder,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div>
          <Input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            hasError={!!error?.message}
            {...rest}
          />
          <FormMessageError message={error?.message} />
        </div>
      )}
    />
  );
};
