
import React from "react";
import { Progress, Tooltip } from "antd";
import Clock from "@assets/icons/Clock";
import Truck from "@assets/icons/Truck";
import File from "@assets/icons/File";

import * as S from "./styles";


const ProgressScore = ({
  title,
  color,
  total_duration,
  total_distance,
  total_orders_number,
  points
}) => (
  <Tooltip
    title={() => (
      <div>
        <S.RowTooltip>
          <Clock size={16} />
          <span>
            Total de horas:
            <b>{`${Math.round(total_duration)} h`}</b>
          </span>
        </S.RowTooltip>
        <S.RowTooltip>
          <Truck size={16} />
          <span>
            Quilometragem:
            <b>{`${Math.round(total_distance)} km`}</b>
          </span>
        </S.RowTooltip>
        <S.RowTooltip>
          <File size={16} />
          <span>
            Total de O.S: <b>{total_orders_number}</b>
          </span>
        </S.RowTooltip>
      </div>
    )}
    placement="top"
    overlayClassName="ginga-tooltip ginga-tooltip-timelime ginga-tooltip-top"
  >
    <S.ProgressArea color={color}>
      <Progress
        type="circle"
        percent={points}
        format={(p) => 
          <S.Score>
            {p}
            <div>pontos</div>
          </S.Score>
        }
        strokeWidth={9}
        success={{
          percent: points,
          strokeColor: color
        }}
        className="ginga-progress"
      />

      <S.ColorBadge color={color} />
      <span>{title}</span>
    </S.ProgressArea>
  </Tooltip>
)

export default ProgressScore