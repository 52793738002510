import React, { useEffect, useState } from "react";
import Container from "@components/Container";
import LoadingSpinner from "@components/LoadingSpinner";
import LogisticService from "@LogisticService";
import { getOneYearAgo } from "@helper";
import Colors from "@colors";
import LineChart from "@components/LineChart";

export default function Qualidade(props) {
  const title = "Qualidade (CSAT)";
  const [qualidade, setQualidade] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    LogisticService.getQualidade().then((response) => {
      if (response && response.data != null) {
        setQualidade(response.data);
      }
      setLoaded(true);
    });
  }, []);

  const periodos = [];
  const csatGeral = [];
  const csatProprio = [];
  const csatTerceira = [];
  const downloadLabels = [
    "Período",
    "CSAT Geral",
    "CSAT Op. Própria",
    "CSAT Op. Terceira",
  ];
  const downloadData = [];

  qualidade &&
    qualidade.map(
      (item) => (
        periodos.push(item.periodo),
        csatGeral.push((item.geral * 100).toFixed(1)),
        csatProprio.push((item.propria * 100).toFixed(1)),
        csatTerceira.push((item.terceira * 100).toFixed(1)),
        downloadData.push([
          `${item.periodo}`,
          `${(item.geral * 100).toFixed(3)}%`,
          `${(item.propria * 100).toFixed(3)}%`,
          `${(item.terceira * 100).toFixed(3)}%`,
        ])
      )
    );

  const chartData = {
    datalabels: true,
    datalabelsPercentage: true,
    zoom: true,
    labels: periodos,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${
            tooltipItem.yLabel
          }%`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 16,
            max: 100,
            suggestedMin: 70,
            callback: function (value) {
              return value + "%";
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: false,
          ticks: {
            padding: 16,
            min: getOneYearAgo(),
          },
        },
      ],
    },
    data: [
      {
        label: "GERAL",
        color: Colors.green,
        fill: false,
        value: csatGeral,
        hidden: true,
      },
      { label: "PRÓPRIA", color: Colors.blue, fill: false, value: csatProprio },
      {
        label: "TERCEIRA",
        color: Colors.gray,
        fill: false,
        value: csatTerceira,
        hidden: true,
      },
    ],
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      props.downloadAll.push({
        index: props.index,
        title: title,
        labels: downloadLabels,
        data: downloadData,
      });
    }
  }, [loaded]);

  return (
    <Container
      grid={1 / 2}
      title={title}
      info={[
        [
          "1 - Qualidade se resume as avaliação positivas (Incrível e Feliz) da nossa pesquisa de satisfação sobre o total de respostas.Acompanhamos nossa qualidade com o indicador de CSAT;",
        ],
        [
          "2 - São considerados os serviços de Instalação, Troca, Desinstalação, Manutenção e Envio de Suprimentos;",
        ],
        [
          "3 - Para o indicador principal acompanhamos o CSAT da operação própria;",
        ],
        [
          "4 - São considerados os contratantes Stone Pagamentos e Elavon Brasil;",
        ],
        [
          "5 - Na pesquisa de satisfação existem 5 níveis de resposta: Incrível, Feliz, Razoável, Triste e Péssimo.",
        ],
      ]}
      downloadLabels={downloadLabels}
      downloadData={downloadData}
      advice={"zoom"}
    >
      {loaded ? <LineChart data={chartData} /> : <LoadingSpinner />}
    </Container>
  );
}
